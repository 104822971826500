<template>
	<div style="position: relative">
		<v-fade-transition>
			<div
				v-show="
					isLoading === false &&
					selectedTutor !== null &&
					selectedMoment !== null &&
					showTooltip === true
				"
				class="text-caption text-center book-tooltip"
			>
				<span
					v-html="$t('예약 완료 후 <br />이어서 프로그램을 선택해요!')"
				></span>
				<v-btn
					style="position: absolute; right: 4px; top: 4px"
					color="white"
					x-small
					icon
					text
					@click="closeTooltip()"
					><v-icon>mdi-window-close</v-icon></v-btn
				>
				<div class="chat-tail"></div>
			</div>
		</v-fade-transition>

		<v-btn
			height="55px"
			:disabled="
				isLoading === true || selectedTutor === null || selectedMoment === null
			"
			:loading="isBooking"
			large
			tile
			block
			color="primary"
			@click="book"
			>{{ $t('예약 완료') }}</v-btn
		>
	</div>
</template>

<script>
// import TutorAvatar from '@/components/common/TutorAvatar'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('book')

export default {
	// components: {
	// 	TutorAvatar,
	// },
	props: {
		isLoading: {
			required: true,
			type: Boolean,
		},
	},
	data: () => ({
		isBooking: false,
		lessonId: false,
		dialog: false,
		showTooltip: false,
	}),
	computed: {
		...mapGetters(['selectedType', 'selectedTutor', 'selectedMoment']),
		language() {
			return this.$store.getters['member/language']
		},
		dateformat() {
			return this.$store.getters['member/dateformat']
		},
		tooltipStorageName() {
			return `book-button-tooltip-${this.$route.name}`
		},
		permanetlyNotShow() {
			return (
				localStorage.getItem(this.tooltipStorageName) === 'true' ||
				localStorage.getItem(this.tooltipStorageName) === true
			)
		},
	},
	created() {
		if (this.permanetlyNotShow !== true) this.showTooltip = true
	},
	methods: {
		async book() {
			try {
				this.isBooking = true
				this.lessonId = await this.$store.dispatch('book/book')
				await this.$store.dispatch('point/get')
				// eslint-disable-next-line no-undef
				amplitude.getInstance().logEvent('book_complete', {
					lessonId: this.lessonId,
					lessonType:
						this.$store.getters['book/selectedType'] === 0 ? 'talk' : 'call',
				})
				this.$router.push({
					name: 'LessonProgramBooked',
					params: { lessonId: this.lessonId },
				})
			} catch (err) {
				switch (err.response.data.message) {
					case 'ALREADY REGISTERED':
						this.errMsg = this.$t(
							'이미 같은 시간에 해당 튜터와 수업이 예약되었습니다.',
						)
						break
					case 'NO AVAILABLE POINTS':
						this.errMsg = this.$t(
							'사용가능한 수강권이 없습니다. 수강권을 다시 확인해주세요.',
						)
						break
					case 'TUTOR IS OFF':
						this.errMsg = this.$t(
							'해당 시간에 튜터의 슬랏이 열려있지 않습니다. 다시 확인해주세요.',
						)
						break
					case 'ALREADY FULLED':
						this.errMsg = this.$t(
							'이미 수업이 꽉 찬 슬랏입니다. 다시 확인해주세요.',
						)
						break
					default:
						this.errMsg = this.$t(
							'수업 예약에 에러가 발생했습니다. 다시 확인해주세요.\nERROR CODE: %{errorCode}',
							{ errorCode: err.response.data.code },
						)
				}
				alert(this.errMsg)
			} finally {
				this.isBooking = false
			}
		},
		closeTooltip() {
			localStorage.setItem(this.tooltipStorageName, true)
			this.showTooltip = false
		},
	},
}
</script>

<style lang="scss" scoped>
.book-tooltip {
	z-index: 3;
	color: #fff;
	line-height: 1.1em;
	background: #353535;
	border-radius: 5px;
	width: 220px;
	position: absolute;
	padding-top: 18px;
	padding-bottom: 15px;
	top: -50px;
	left: 50%;
	margin-left: -110px;

	.chat-tail {
		width: 0;
		height: 0;
		position: absolute;
		bottom: -5px;
		left: 50%;
		margin-left: 2.5px;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;

		border-top: 5px solid #353535;
	}
}

.selected-tutor-wrap {
	padding: 5px 7px;
	background: #ffffff;
	border: 0.5px dashed #1e78ff;
	box-sizing: border-box;
	border-radius: 10px;
}
</style>
