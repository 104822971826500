// import { createNamespacedHelpers } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
	isLoading: false,
	keyword: null,
	tutors: [],
	sortBy: 'recent',
	category: 'all',
	rowsPerPage: 10,
	totalCount: 0,
	pastTutorIds: [],
	pastTutorIdsForMonth: [],
}

// const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE)

// export {
//   mapGetters,
//   mapMutations,
//   mapActions
// }

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
