<template>
	<div>
		<v-dialog v-model="dialog" width="350" content-class="rounded-xl">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					small
					dark
					rounded
					depressed
					color="grey darken-3"
					>{{ $t('코드 등록') }}</v-btn
				>
			</template>

			<v-card rounded="xl" style="overflow: hidden">
				<section class="px-5 pt-5 pb-12">
					<p class="text-body-1 mb-3">
						{{ $t('수강권 코드를 입력해주세요.') }}
					</p>
					<v-text-field
						class="mb-3"
						solo
						flat
						hide-details
						v-model="code"
						background-color="grey lighten-3"
						:placeholder="$t('코드 입력')"
					></v-text-field>
				</section>
				<v-divider></v-divider>
				<div class="d-flex align-center px-5 py-3">
					<v-btn
						:loading="isLoading"
						text
						color="black"
						@click="dialog = false"
						>{{ $t('취소') }}</v-btn
					>
					<v-spacer></v-spacer>
					<v-btn :loading="isLoading" text color="primary" @click="register">{{
						$t('등록')
					}}</v-btn>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	data: () => ({
		dialog: false,
		code: null,
		isLoading: false,
	}),
	watch: {
		dialog(val) {
			if (val === false) this.code = null
		},
	},
	methods: {
		async register() {
			try {
				this.isLoading = true
				await Api.post(`pass-coupons/${encodeURIComponent(this.code)}`)
				this.code = null
				this.dialog = false
				alert(this.$t('수강권이 등록되었습니다.'))
				this.$emit('registered')
			} catch (err) {
				let errMsg = this.$t(
					'알 수 없는 에러가 발생했습니다. 고객센터로 문의주세요.\nERROR CODE: VO2N0S',
				)
				if (err.response.data.code) {
					const code = err.response.data.code.toString()
					if (code === '80000')
						errMsg = this.$t(
							'유효한 쿠폰 번호가 아닙니다. 쿠폰 번호를 확인하세요.',
						)
					if (code === '80001') errMsg = this.$t('이미 등록된 쿠폰입니다.')
				}
				alert(errMsg)
			} finally {
				this.isLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
