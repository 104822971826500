<template>
	<div>
		<v-row align="center" no-gutters class="pa-3">
			<v-col cols="2">
				<v-btn @click="goBack" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
			</v-col>
			<v-col cols="8">
				<h1 class="text-center text-body-1 font-weight-regular">
					{{ $t('비밀번호 변경') }}
				</h1>
			</v-col>
		</v-row>
		<section class="pa-5">
			<h2 class="text-h6 mb-1 font-weight-bold">{{ email }}</h2>
			<p class="text-body-2 mb-8 grey--text text--darken-3">
				{{ $t('위 이메일로 로그인 시 사용할 비밀번호를 설정해주세요.') }}
			</p>
			<v-form ref="form" v-model="valid" style="width: 100%">
				<v-card flat class="pa-3">
					<h3 class="text-body-1 mb-2">{{ $t('비밀번호') }}</h3>
					<v-text-field
						single-line
						v-model.trim="password"
						:placeholder="$t('6~20자 영문, 숫자, 특수문자 사용')"
						type="password"
						class="mb-5 tella-form"
						filled
						hide-details="auto"
						:rules="passwordRules"
						autocomplete="on"
						:disabled="isLoading"
					></v-text-field>
					<h3 class="text-body-1 mb-2">{{ $t('비밀번호 재확인') }}</h3>
					<v-text-field
						:placeholder="$t('입력한 비밀번호와 동일')"
						v-model.trim="confirm"
						type="password"
						class="mb-12 tella-form"
						filled
						hide-details="auto"
						:rules="passwordRules"
						:error-messages="
							isPasswordMatch === false
								? $t('비밀번호가 일치하지 않습니다.')
								: ''
						"
						autocomplete="on"
						:disabled="isLoading"
					></v-text-field>
					<v-btn
						height="51"
						:disabled="valid === false || isLoading"
						:loading="isLoading"
						color="grey darken-3 white--text"
						depressed
						x-large
						block
						@click="changePassword"
					>
						<p class="font-weight-bold">{{ $t('비밀번호 변경') }}</p>
					</v-btn>
				</v-card>
			</v-form>
		</section>
	</div>
</template>

<script>
// import _ from 'lodash'
import Api from '@/services/index'
var crypto = require('crypto')

export default {
	data: function () {
		return {
			valid: false,
			publicKey: null,
			isLoading: false,
			password: '',
			passwordRules: [
				v => !!v || this.$t('패스워드를 입력해주세요.'),
				v =>
					(v && /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(v) === false) ||
					this.$t('비밀번호는 영어, 숫자, 특수기호만 사용하실 수 있습니다.'),
				v =>
					(v && v.length <= 20) ||
					this.$t('비밀번호는 6자 이상, 20자 이하로 입력해주세요.'),
				v =>
					(v && v.length >= 6) ||
					this.$t('비밀번호는 6자 이상, 20자 이하로 입력해주세요.'),
			],
			confirm: '',
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		email() {
			return this.$store.getters['member/email']
		},
		isPasswordMatch() {
			let isMatch = true
			if (
				this.password !== null &&
				this.confirm !== null &&
				this.password !== this.confirm
			)
				isMatch = false
			return isMatch
		},
		validation() {
			let result = true
			if (this.valid === false) result = false
			return result
		},
		encryptedPW() {
			let encryptedPW = null
			if (this.password) {
				const hashPassword = crypto
					.createHash('md5')
					.update(this.password)
					.digest('hex')
				encryptedPW = crypto
					.publicEncrypt(this.publicKey, Buffer.from(hashPassword, 'utf8'))
					.toString('base64')
			}
			return encryptedPW
		},
	},
	created() {
		this.getPublicKey()
	},
	methods: {
		validate() {
			this.$refs.form.validate()
		},
		async getPublicKey() {
			this.isLoading = true
			const res = await Api.get('/auth/pulic-key')
			this.publicKey = res.data
			this.isLoading = false
		},
		async changePassword() {
			try {
				this.isLoading = true
				const params = {
					encryptedPassword: this.encryptedPW,
				}
				await Api.patch(`members/${this.memberId}`, params)
				alert(this.$t('비밀번호 변경이 완료되었습니다.'))
				this.$router.push({ name: 'Setting' })
			} catch (err) {
				alert(this.$t('비밀번호 변경이 실패하였습니다. 다시 시도해주세요.'))
			} finally {
				this.isLoading = false
			}
		},
		goBack() {
			this.$router.push({ name: 'Setting' })
		},
	},
}
</script>

<style scoped></style>
