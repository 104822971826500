<template>
	<div>
		<v-row align="center" no-gutters class="pa-3">
			<v-col cols="2">
				<v-btn @click="goBack" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
			</v-col>
			<v-col cols="8">
				<h1 class="text-center text-body-1 font-weight-regular">
					{{ $t('타임존 변경') }}
				</h1>
			</v-col>
		</v-row>
		<section class="pa-5">
			<h2
				class="text-h6 mb-1 font-weight-bold"
				v-html="$t('수업을 수강할<br />타임존을 알려주세요.')"
			></h2>
			<p
				class="text-body-2 mb-8 grey--text text--darken-3"
				v-html="
					$t('	선택하신 타임존에 따라<br />수업 예약 / 안내가 이루어집니다.')
				"
			></p>
		</section>
		<v-container style="max-width: 350px">
			<!-- <v-select
				outlined
				v-model="locale"
				:items="items"
				:label="$t('언어')"
			></v-select> -->
			<v-autocomplete
				outlined
				v-model="timezone"
				:items="allTimezones"
				:label="$t('타임존')"
			></v-autocomplete>
			<v-btn
				block
				x-large
				type="submit"
				@click="changeLocale"
				color="grey darken-3 white--text"
				depressed
				:disabled="isLoading"
				>{{ $t('변경') }}</v-btn
			>
		</v-container>
	</div>
</template>

<script>
import Api from '@/services/index'
import allTimezones from '@/assets/time-zone'

export default {
	data: function () {
		return {
			isLoading: false,
			dialog: false,
			timezone: null,
			allTimezones: [],
			items: [
				{ text: '한국어', value: 'ko' },
				{ text: '日本語', value: 'ja' },
			],
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
	},
	created() {
		this.timezone = this.$store.getters['member/timezone']
		this.allTimezones = allTimezones
	},
	methods: {
		async changeLocale() {
			try {
				this.isLoading = true
				await Api.patch(`members/${this.memberId}`, {
					// language: this.locale,
					timezone: this.timezone,
				})
				alert(this.$t('언어/타임존이 변경 되었습니다.'))
				this.$i18n.locale = this.locale
				this.$router.push({ name: 'Setting' })
			} catch (err) {
				alert(this.$t('회원 정보 변경이 실패하였습니다. 다시 시도해주세요.'))
			} finally {
				this.isLoading = false
			}
		},
		goBack() {
			this.$store.getters['device/fromRouter'].name === null
				? this.$router.push({ name: 'Setting' })
				: this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped></style>
