<template>
	<div>
		<v-btn fab icon text small @click="sheet = true">
			<v-icon>mdi-tune-vertical-variant</v-icon>
		</v-btn>
		<v-bottom-sheet v-model="sheet" scrollable inset width="400">
			<v-sheet rounded="xl" class="pt-3" height="550px">
				<div>
					<v-row no-gutters align="center">
						<v-col cols="8" offset="2">
							<h1 class="text-body-1 my-3 text-center">
								{{ $t('맞춤형 출석증') }}
							</h1>
						</v-col>

						<v-col cols="2" class="text-center">
							<v-btn fab text small @click="sheet = false"
								><v-icon>mdi-window-close</v-icon></v-btn
							>
						</v-col>
					</v-row>
					<section
						class="px-5"
						style="overflow-y: auto; height: calc(var(--vh, 1vh) * 100 - 100px)"
					>
						<section class="my-5">
							<h2 class="text-body-1 font-weight-bold mb-2">
								{{ $t('수업 유형별 수강권') }}
							</h2>
							<v-select
								hide-details
								outlined
								:items="pass"
								item-text="title"
								return-object
								v-model="selectedPoint"
							>
								<template slot="selection" slot-scope="{ item }">
									<div class="selection">
										<div class="text-body-2">
											{{ item.title }}
										</div>
										<div class="text-caption grey--text text--darken-1">
											{{ $t('유효기간') }}:
											{{ item.startMoment.format('YYYY. MM. DD.') }} ~
											{{ item.expiryMoment.format('YYYY. MM. DD.') }}
										</div>
									</div>
								</template>
								<template slot="item" slot-scope="{ item }">
									<div class="select-item">
										<div class="text-body-2">
											{{ item.title }}
										</div>
										<div class="text-caption grey--text text--darken-1">
											{{ $t('유효기간') }}:
											{{ item.startMoment.format('YYYY. MM. DD.') }} ~
											{{ item.expiryMoment.format('YYYY. MM. DD.') }}
										</div>
									</div>
									<v-divider class="mb-2"></v-divider>
								</template>
							</v-select>
						</section>
						<section class="my-5">
							<h2 class="text-body-1 font-weight-bold mb-2">
								{{ $t('수강 기간') }}
							</h2>
							<v-row no-gutters class="mb-3">
								<v-col cols="6" class="pr-1">
									<div
										class="button text-body-2"
										:class="{ active: includeTerm === true }"
										@click="includeTerm = true"
									>
										{{ $t('수강기간 포함') }}
									</div>
								</v-col>
								<v-col cols="6" class="pl-1">
									<div
										class="button text-body-2"
										:class="{ active: includeTerm === false }"
										@click="includeTerm = false"
									>
										{{ $t('수강기간 미포함') }}
									</div>
								</v-col>
							</v-row>
							<div class="d-flex align-center">
								<v-menu
									v-model="menu1"
									:disabled="includeTerm === false"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											hide-details
											dense
											outlined
											:height="42"
											:disabled="includeTerm === false"
											v-model="startDate"
											append-icon="mdi-calendar"
											readonly
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										no-title
										v-model="startDate"
										:allowed-dates="allowedDates"
										@input="menu1 = false"
									></v-date-picker>
								</v-menu>
								<div class="px-2">~</div>
								<v-menu
									v-model="menu2"
									:disabled="includeTerm === false"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									left
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											hide-details
											dense
											outlined
											:height="42"
											v-model="endDate"
											:disabled="includeTerm === false"
											append-icon="mdi-calendar"
											readonly
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										no-title
										v-model="endDate"
										:allowed-dates="allowedDates"
										@input="menu2 = false"
									></v-date-picker>
								</v-menu>
							</div>
						</section>
						<section class="my-5">
							<h2 class="mb-2 text-body-1 font-weight-bold">
								{{ $t('언어') }}
							</h2>
							<v-row no-gutters class="mb-3">
								<v-col cols="6" class="pr-1">
									<div
										class="button text-body-2"
										:class="{ active: certificateLanguage === 'kr' }"
										@click="certificateLanguage = 'kr'"
									>
										{{ $t('한글 증명서') }}
									</div>
								</v-col>
								<v-col cols="6" class="pl-1">
									<div
										class="button text-body-2"
										:class="{ active: certificateLanguage === 'en' }"
										@click="certificateLanguage = 'en'"
									>
										{{ $t('영문 증명서') }}
									</div>
								</v-col>
							</v-row>
						</section>
					</section>
				</div>
				<section class="fixed-button-wrap">
					<v-btn
						@click="issueCertificate"
						tile
						height="56"
						block
						color="primary"
						>{{ $t('발급하기') }}</v-btn
					>
				</section>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
import moment from 'moment-timezone'
const R = require('ramda')
export default {
	props: {
		points: {
			required: true,
		},
		query: {
			default: {},
		},
	},
	data: () => ({
		sheet: false,
		menu1: false,
		menu2: false,
		startDate: null,
		endDate: null,
		selectedPoint: null,
		includeTerm: true,
		certificateLanguage: 'kr',
	}),
	computed: {
		talkAssigned() {
			return R.pipe(
				R.filter(p => p.type === 0 && p.is_bonus === 0),
				R.map(p => p.assigned_points),
				R.sum(),
			)(this.points)
		},
		callAssigned() {
			return R.pipe(
				R.filter(p => p.type === 1 && p.is_bonus === 0),
				R.map(p => p.assigned_points),
				R.sum(),
			)(this.points)
		},
		allStartMoment() {
			const startDate = R.pipe(
				R.sortWith([R.ascend(R.prop('start_date'))]),
				R.map(p => p.start_date),
			)(this.points)

			return startDate.length > 0
				? moment.tz(startDate[0], 'Asia/Seoul')
				: undefined
		},
		allExpiryMoment() {
			const expiryDate = R.pipe(
				R.sortWith([R.descend(R.prop('expiry_date'))]),
				R.map(p => p.expiry_date),
			)(this.points)

			return expiryDate.length > 0
				? moment.tz(expiryDate[0], 'Asia/Seoul')
				: undefined
		},
		allName() {
			return this.talkAssigned > 0 && this.callAssigned > 0
				? this.$t(`[전체] 톡 %{talkAssigned}회 & 콜 %{callAssigned}회 수강권`, {
						talkAssigned: this.talkAssigned,
						callAssigned: this.callAssigned,
				  })
				: this.talkAssigned > 0
				? this.$t(`25분톡 %{talkAssigned}회 수강권`, {
						talkAssigned: this.talkAssigned,
				  })
				: this.callAssigned > 0
				? this.$t(`15분콜 %{callAssigned}회 수강권`, {
						callAssigned: this.callAssigned,
				  })
				: this.$t('보너스 수강권')
		},
		pass() {
			const pass = [
				{
					title: this.allName,
					id: 0,
					startMoment: this.allStartMoment,
					expiryMoment: this.allExpiryMoment,
				},
			]
			if (this.points.length > 1) {
				this.points.forEach(p => {
					const title = this.makePointTitle(p)

					pass.push({
						title,
						id: p.id,
						startMoment: moment.tz(p.start_date, 'Asia/Seoul'),
						expiryMoment: moment.tz(p.expiry_date, 'Asia/Seoul'),
					})
				})
			}
			return pass
		},
	},
	watch: {
		sheet(val) {
			if (val === true) this.readQuery()
		},
		selectedPoint(val) {
			if (this.startDate < val.startMoment.format('YYYY-MM-DD'))
				this.startDate = val.startMoment.format('YYYY-MM-DD')
			if (this.endDate > val.expiryMoment.format('YYYY-MM-DD'))
				this.endDate = val.expiryMoment.format('YYYY-MM-DD')
		},
	},
	created() {
		this.readQuery()
		this.issueCertificate()
	},
	methods: {
		makePointTitle(point) {
			return point.type === 0
				? point.is_bonus === 1
					? this.$t(`25분톡 %{points}회 보너스 수강권`, {
							points: point.assigned_points,
					  })
					: this.$t(`25분톡 %{points}회 수강권`, {
							points: point.assigned_points,
					  })
				: point.is_bonus === 1
				? this.$t(`15분콜 %{points}회 보너스 수강권`, {
						points: point.assigned_points,
				  })
				: this.$t(`15분콜 %{points}회 수강권`, {
						points: point.assigned_points,
				  })
		},
		readQuery() {
			// 선택한 pointId가 가지고 있는 point 중에 있는 지 확인한다.
			// 가지고 있다면 selectedPoint 한다.
			// 없다면? 전체
			this.selectedPoint = this.pass[0]
			if (this.query['selected-point-id']) {
				const selectedPointId = Number(this.query['selected-point-id'])
				const selectedPoint = R.find(R.propEq('id', selectedPointId))(this.pass)
				if (selectedPoint) this.selectedPoint = selectedPoint
			}

			// startDate,  endDate 선택한 point에 따라 설정
			this.startDate = this.selectedPoint.startMoment.format('YYYY-MM-DD')
			const startDateOfQeury = this.query['start-date']
			if (
				startDateOfQeury &&
				startDateOfQeury >= this.selectedPoint.startMoment.format('YYYY-MM-DD')
			) {
				this.startDate = startDateOfQeury
			}

			this.endDate = this.selectedPoint.expiryMoment.format('YYYY-MM-DD')
			const endDateOfQuery = this.query['end-date']
			if (
				endDateOfQuery &&
				endDateOfQuery <= this.selectedPoint.expiryMoment.format('YYYY-MM-DD')
			) {
				this.endDate = endDateOfQuery
			}

			// 수강기간 포함 미포함 설정
			const includeTermQuery = this.query['include-term']
			this.includeTerm =
				includeTermQuery == true || includeTermQuery == false
					? (this.includeTerm = includeTermQuery == true)
					: true

			// 한글/영문 선택
			const certLangQuery = this.query['certificate-language']
			this.certificateLanguage =
				certLangQuery === 'kr' || certLangQuery === 'en' ? certLangQuery : 'kr'
		},
		allowedDates(val) {
			return (
				val >= this.selectedPoint.startMoment.format('YYYY-MM-DD') &&
				val <= this.selectedPoint.expiryMoment.format('YYYY-MM-DD')
			)
		},
		issueCertificate() {
			this.sheet = false
			this.$emit('issueCertificate', {
				selectedPointId: this.selectedPoint.id,
				startDate: this.startDate,
				endDate: this.endDate,
				passName: this.selectedPoint.title,
				includeTerm: this.includeTerm,
				certificateLanguage: this.certificateLanguage,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.button {
	background: #ffffff;
	border: 0.8px solid #e7e7e7;
	box-sizing: border-box;
	border-radius: 10px;
	text-align: center;
	padding: 12px 0px;
}
.button.active {
	font-family: Noto Sans;
	font-weight: bold;
	color: #1e78ff;
	background: rgba(30, 120, 255, 0.05);
	border: 0.8px solid #1e78ff;
}

.selection,
.select-item {
	width: 100%;
}
.selection {
	padding: 5px 0;
}
.select-item {
	padding: 10px 0;
	border-bottom: 1px solid #e5e5e5;
}
.select-item:last-child {
	border-bottom: unset;
}
</style>
