<template>
	<div class="px-4">
		<div class="pb-2 d-flex align-center" style="border-bottom: 1px solid #000">
			<h1 class="font-16 font-sm-18 font-weight-medium">Unit</h1>
			<v-spacer></v-spacer>
			<span class="font-14">전체 {{ units.length }}개</span>
		</div>
		<div
			v-for="(unit, i) in units"
			:key="unit.id"
			class="font-13 font-sm-14 d-flex align-center"
			:style="i !== 0 ? 'border-top: 1px solid #eee;width: 100%' : ''"
			style="height: 64px"
			@click="$emit('clickUnit', unit)"
		>
			<img
				:src="
					require(`@/assets/images/icon/check${
						selectedUnitId === unit.id ? '-active' : ''
					}.png`)
				"
				class="mr-2"
				alt=""
			/>
			<!-- <v-icon class="mr-2 grey--text">mdi-check-circle-outline</v-icon> -->
			<!-- <v-icon class="mr-2 primary--text">mdi-check-circle</v-icon> -->
			<div
				class="text-truncate cursor-pointer"
				:style="i === 0 ? 'width: calc(100% - 40px)' : ''"
			>
				<div class="tag appointed" v-if="unit.nextLesson >= 0">예약중</div>
				<div class="tag completed" v-else-if="unit.progress === 0">완료</div>
				<div class="tag in-progress" v-else-if="unit.progress === 1">
					진행중
				</div>
				<div>{{ i + 1 }}. {{ getUnitTitleByLanguage(unit.unitTitle) }}</div>
			</div>
			<v-spacer></v-spacer>
			<v-icon
				@click.stop="$emit('clickGuide', unit.id)"
				class="grey--text text--ligthen-3"
				>mdi-chevron-right</v-icon
			>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		dialog: false,
	}),
	props: {
		units: {
			required: true,
			type: Array,
		},
		selectedUnitId: {
			required: true,
		},
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
	},
	methods: {
		getUnitTitleByLanguage(unitTitle) {
			let title = ''
			const titleObj = JSON.parse(unitTitle)
			if (titleObj) title = titleObj[this.language]
			return title
		},
	},
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 600px) {
	.show-all-button {
		background: #f7f7f7;
		border: 1px solid #eeeeee;
		border-radius: 4px;
	}
}

.tag {
	font-size: 11px;
	border-radius: 3px;
	padding-top: 4px;
	min-width: 42px;
	text-align: center;
	border: 1px solid;
	display: inline-block;
	margin-bottom: 4px;
}
.tag.appointed {
	color: #0074ff;
	border-color: #0074ff;
}
.tag.completed {
	color: #1a1a1a;
	border-color: #1a1a1a;
}
.tag.in-progress {
	color: #00cba0;
	border-color: #00cba0;
}
</style>
