<template>
	<div class="background pt-12 pb-15">
		<v-toolbar
			style="
				width: 100%;
				max-width: 400px;
				position: fixed;
				top: 0px;
				z-index: 3;
			"
			elevation="0"
			height="60"
			class="px-1"
		>
			<div
				@click="$router.push({ name: 'AppointedLessons' })"
				class="mr-4 font-16 grey--text font-weight-medium hover-pointer"
			>
				{{ $t('page.reviewList.toolbar.menu.예약') }}
			</div>
			<div class="font-16 font-weight-medium hover-pointer">
				{{ $t('page.reviewList.toolbar.menu.복습') }}
			</div>
			<v-spacer></v-spacer>
			<CalendarButton />
		</v-toolbar>

		<Menu class="px-4 mt-3 mb-8" />
		<div class="px-4" v-if="isLoading === true">
			<LoadingLessonCard v-for="i in 2" :key="`loading-${i}`" class="mb-8" />
		</div>
		<div class="px-4" v-if="isLoading === false">
			<div v-for="lesson in lessons" :key="lesson.id">
				<PassedLessonCard :lesson="lesson" class="mb-8" />
			</div>
		</div>
		<Empty
			:category="'call'"
			v-if="
				isLoading === false && isCountLoading === false && lessons.length === 0
			"
		/>
		<Pagination
			class="mt-8 mb-5"
			v-if="isCountLoading === false && isLoading === false && totalPage > 0"
			@changePage="changePage"
			:page="page"
			:totalPage="totalPage"
		/>
	</div>
</template>

<script>
import scheduleApi from '@/services/schedule'
import PassedLessonCard from '@/components/lesson-card/passed/Wrap'
import LoadingLessonCard from '@/components/lesson-card/Loading'
import Menu from './Menu'
import Pagination from './Pagination.vue'
import Empty from './Empty'
import CalendarButton from './CalendarButton'

export default {
	components: {
		PassedLessonCard,
		Menu,
		Pagination,
		Empty,
		CalendarButton,
		LoadingLessonCard,
	},
	data: () => ({
		totalCount: 0,
		totalPage: 0,
		limit: 10,
		isCountLoading: false,
		isLoading: false,
		lessons: [],
	}),
	computed: {
		page() {
			const initPage = parseInt(this.$route.query.page)
			if (isNaN(initPage) === true) {
				return 1
			}
			if (initPage > this.totalPage) {
				return 1
			}
			return initPage
		},
	},
	watch: {
		page() {
			this.getLessons()
		},
	},
	created() {
		this.getCount()
	},
	methods: {
		async getCount() {
			this.isCountLoading = true
			this.totalCount = await scheduleApi.getCount({
				status: 'analyzed,analyzed-absent',
				lessonType: 1,
			})
			this.totalPage = Math.ceil(this.totalCount / this.limit)
			this.isCountLoading = false
			this.getLessons()
		},
		async getLessons() {
			this.isLoading = true
			this.lessons = await scheduleApi.getLessons({
				status: 'analyzed,analyzed-absent',
				lessonType: 1,
				page: this.page,
				limit: this.limit,
			})

			const lessonIds = this.lessons.map(l => l.id).toString()
			const ratings = await scheduleApi.getRatings({ lessonIds })

			this.lessons.forEach(l => {
				const alreadyRated = ratings.find(r => r.lessonId === l.id)
				l.ratingScore = alreadyRated ? alreadyRated.score : null
			})
			this.isLoading = false
		},
		changePage(page) {
			this.$router.push({ name: this.$route.name, query: { page } })
		},
		goTop() {
			if (this.scrollTarget) {
				this.scrollTarget.scrollTop = 0
			}
		},
	},
	// 날짜를 정하지 않고 수업 갯수를 불러온다.
	// 수업 갯수로 페이지를 나누고 현재 페이지에 맞는 수업을 불러온다.
	// 페이지를 변경 시, 주소를 업데이트 한다.
	// 주소를 watch 하고 있다가 페이지가 업데이트 되면 해당 수업을 불러온다.
}
</script>

<style lang="scss" scoped>
.background {
	background-color: #f2f4f6;
	min-height: calc(var(--vh, 1vh) * 100);
	overflow-y: auto;
}
// /* iOS only */
// @supports (-webkit-touch-callout: none) {
// 	.background {
// 		height: -webkit-fill-available;
// 	}
// }

.lesson-menu {
	padding: 7px 14px 6px;
	background: #ffffff;
	border-radius: 30px;
}
.lesson-menu.active {
	background-color: #1a1a1a;
	color: #ffffff;
}

.pagination-item {
	width: 26px;
	height: 26px;
	border-radius: 50%;
	margin: 0 5px;
	text-align: center;
	font-size: 15px;
	font-weight: 500;
	padding-top: 2.5px;
	color: #666;
}
.pagination-item.active {
	background-image: linear-gradient(138deg, #6b72fa 9%, #6aaeff 106%);
	color: #fff;
}
.pagination-navigation {
	margin-top: -2px;
}
.pagination-navigation.disabled {
	color: #f7f7f7 !important;
	i {
		color: #ccc !important;
	}
}
.opacity-zero {
	opacity: 0;
}
</style>
