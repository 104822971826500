<template>
	<div id="notice-detail" style="position: relative">
		<section class="fixed-bar-wrap">
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">친구 추천</h1>
				</v-col>
			</v-row>
		</section>
		<section
			style="margin-top: 60px; max-width: 400px"
			v-if="isLoading === false"
		>
			<div
				style="border-bottom: 1px solid #000"
				class="pa-5"
				v-html="notice.content"
			></div>
		</section>
	</div>
</template>

<script>
import Api from '@/services/index'
import moment from 'moment-timezone'

export default {
	data: () => ({
		isLoading: false,
		notice: null,
	}),
	created() {
		this.getNotice()
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			beforeName !== 'MyPage' && beforeName !== 'Home' && beforeName !== 'Plans'
				? this.$router.push({ name: 'MyPage' })
				: this.$router.go(-1)
		},
		async getNotice() {
			this.isLoading = true

			const res = await Api.get(`boards?board-list-id=12969&board-type-id=1`)
			if (res.data.length === 0) {
				alert(this.$t('게시물을 찾을 수 없습니다.'))
				this.goBack()
			} else {
				this.notice = res.data[0]
				this.isLoading = false
			}
		},
		momentFormat(timecode) {
			return moment.tz(timecode, 'Asia/Seoul').locale('ko')
		},
	},
}
</script>

<style>
#notice-detail table {
	max-width: 380px;
	box-sizing: border-box;
}
</style>
