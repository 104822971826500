<template>
	<v-form>
		<div v-if="selectedMoment !== null">
			<div class="d-flex align-start">
				<v-text-field
					class="mr-1"
					background-color="white"
					:label="$t('form.label.수업일시')"
					ref="formattedDatetime"
					v-model="formattedDatetime"
					outlined
					:disabled="isLoading"
					readonly
					@click="selectedMoment = null"
					hide-details="auto"
				>
				</v-text-field>
				<v-btn
					depressed
					large
					color="primary"
					@click="selectedMoment = null"
					:disabled="selectedMoment === null"
					>{{ $t('form.button.일시변경') }}</v-btn
				>
			</div>
			<p
				class="
					mb-6
					mt-2
					px-1
					grey--text
					text--darken-1 text-body-2
					grey--4--text
				"
			>
				{{
					$t('trial.text.타임존-표기-안내', {
						timezone,
					})
				}}
				<TimezonePicker
					:originTimezone="timezone"
					@selectTimezone="selectTimezone"
				/>
			</p>
		</div>
		<TimePicker
			v-else
			:nationality="nationality"
			@selectMoment="selectMoment"
			@selectTimezone="selectTimezone"
			:timezone="timezone"
		/>

		<v-text-field
			v-if="!memberId"
			class="tella-form"
			:label="$t('form.label.name')"
			ref="name"
			v-model="name"
			outlined
			:rules="nameRules"
			:disabled="isLoading"
			background-color="white"
		>
		</v-text-field>
		<v-text-field
			v-if="nationality === 'jp'"
			class="tella-form"
			:label="$t('form.label.email')"
			ref="email"
			v-model="email"
			outlined
			:rules="emailRules"
			:disabled="isLoading || memberId"
			background-color="white"
		>
		</v-text-field>
		<div v-if="!memberId" class="d-flex align-start">
			<div
				style="max-width: 100px; width: 40%"
				class="flex-grow-0 flex-shrink-1"
			>
				<CountryPicker
					@selectCountry="selectCountry"
					:originCountryCode="countryCode"
					:backgroundColor="'white'"
					:hideDetails="true"
				/>
			</div>

			<v-text-field
				style="max-width: 250px; width: 60%"
				class="tella-form ml-2 flex-grow-1 flex-shrink-0"
				:label="$t('form.label.phone')"
				ref="phone"
				v-model="phone"
				outlined
				type="tel"
				:rules="phoneRules"
				:disabled="isLoading"
				background-color="white"
				hide-details="auto"
			>
			</v-text-field>
		</div>
		<div
			v-if="!memberId && nationality !== 'jp'"
			class="mb-10 mt-3 d-flex align-center"
		>
			<v-checkbox class="mt-0 pt-0" hide-details v-model="kakaoNumberCheck">
			</v-checkbox>
			<div
				class="text-body-2 grey--text text--darken-1"
				@click.prevent="kakaoNumberCheck = !kakaoNumberCheck"
			>
				{{ $t('form.info.duplicated-phone') }}
			</div>
			<v-spacer></v-spacer>
			<HowToCheckKakaoNumber @check="kakaoNumberCheck = true" />
		</div>

		<UpdateButton
			:id="id"
			:name="name"
			:email="email"
			:phone="phone"
			:countryCode="countryCode"
			:originPhone="originPhone"
			:originCountryCode="originCountryCode"
			:trialMoment="originSelectedMoment"
			:timezone="timezone"
			:datetime="
				selectedMoment && originSelectedMoment !== selectedMoment
					? selectedMoment.toISOString()
					: undefined
			"
			:lessonType="0"
			:isValid="isValid"
			:class="{ 'mt-10': nationality === 'jp' }"
		/>
	</v-form>
</template>

<script>
import CountryPicker from '@/components/common/CountryPicker'
import TimePicker from '../common/TimePicker'
import HowToCheckKakaoNumber from '@/components/common/HowToCheckKakaoNumber'
import UpdateButton from './UpdateButton'
import TimezonePicker from '../common/TimezonePicker'

export default {
	components: {
		CountryPicker,
		TimePicker,
		HowToCheckKakaoNumber,
		UpdateButton,
		TimezonePicker,
	},
	props: {
		id: {
			required: true,
			type: Number,
		},
		originName: {
			required: true,
			type: String,
		},
		originEmail: {
			required: true,
			type: String,
		},
		originPhone: {
			required: true,
			type: String,
		},
		originCountryCode: {
			required: true,
			type: String,
		},
		originSelectedMoment: {
			required: true,
		},
		originTimezone: {
			required: true,
		},
		nationality: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			kakaoNumberCheck: true,
			selectedMoment: null,
			isLoading: false,
			name: null,
			nameRules: [v => !!v || this.$t('form.error.name-required')],
			email: null,
			emailRules: [
				v => !!v || this.$t('form.error.email-required'),
				v => (v && v.length <= 100) || this.$t('form.error.email-too-long'),
				v => /.+@.+\..+/.test(v) || this.$t('form.error.email-format'),
			],
			timezone: null,
			countryCode: null,
			phone: null,
			phoneRules: [
				v => !!v || this.$t('form.error.phone-required'),
				v => /^[0-9]*$/.test(v) || this.$t('form.error.phone-only-number'),
				v =>
					(v &&
						v.length >= this.minNumberLength &&
						v.length <= this.maxNumberLength) ||
					this.$t('form.error.phone-min-and-max'),
			],
			policyAgree: true,
		}
	},
	computed: {
		minNumberLength() {
			return this.countryCode === '81' ? 11 : this.countryCode === '82' ? 9 : 6
		},
		maxNumberLength() {
			return this.countryCode === '81'
				? 14
				: this.countryCode === '82'
				? 11
				: 16
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		language() {
			return this.$store.getters['member/language']
		},
		formattedDatetime() {
			if (this.selectedMoment === null) return null
			const m = this.selectedMoment
			const date = m
				.clone()
				.tz(this.timezone)
				.locale(this.language)
				.format('ll')
				.substr(5)
			const day = m
				.clone()
				.tz(this.timezone)
				.locale(this.language)
				.format('(ddd)')
			const time = m.clone().tz(this.timezone).locale('en').format('h:mm A')
			return `${date} ${day} ${time}`
		},
		isValid() {
			let valid = false
			if (
				this.selectedMoment &&
				this.name &&
				this.phone &&
				this.countryCode &&
				this.kakaoNumberCheck &&
				/^[0-9]*$/.test(this.countryCode) &&
				/^[0-9]*$/.test(this.phone)
			)
				valid = true
			return valid
		},
	},
	created() {
		this.name = this.originName
		this.email = this.originEmail
		this.countryCode = this.originCountryCode
		this.phone = this.originPhone
		this.selectedMoment = this.originSelectedMoment
		this.timezone = this.originTimezone
	},
	methods: {
		selectCountry(country) {
			this.countryCode = country.dialCode
		},
		selectMoment(m) {
			this.selectedMoment = m
		},
		selectTimezone(t) {
			this.timezone = t
		},
	},
}
</script>

<style lang="scss" scoped></style>
