/* eslint-disable no-undef */
import store from '../store/index'

function removeZero(phone) {
	return phone.replace(/(^0+)/, '')
}

function getCookie(cookie_name) {
	var x, y
	var val = document.cookie.split(';')
	for (var i = 0; i < val.length; i++) {
		x = val[i].substr(0, val[i].indexOf('='))
		y = val[i].substr(val[i].indexOf('=') + 1)
		x = x.replace(/^\s+|\s+$/g, '')
		// 앞과 뒤의 공백 제거하기
		if (x == cookie_name) {
			return unescape(y)
			// unescape로 디코딩 후 값 리턴
		}
	}
}
export default {
	install(Vue) {
		Vue.prototype.$conversionTrialComplete = (
			trialId,
			{ phone, countryCode, name },
		) => {
			// console.log(getCookie('_fbp'))

			fbq('init', '1786362331644651', {
				ph: `${countryCode}${removeZero(phone)}`,
				fn: name,
				em: store.getters['member/email'],
				external_id: store.getters['auth/memberId'],
				fbp: getCookie('_fbp'),
				fbc: getCookie('_fbc'),
				client_ip_address: store.getters['member/ipinfo']
					? store.getters['member/ipinfo'].ip
					: null,
				client_user_agent: window.navigator.userAgent,
			})
			fbq('track', 'Lead', {}, { eventID: trialId })
			gtag('event', 'trial_complete')
			kakaoPixel('8986155009979562519').participation('Lead')
		}
		Vue.prototype.$conversionSignup = () => {
			fbq('init', '1786362331644651', {
				ph: `${store.getters['member/countryCode']}${removeZero(
					store.getters['member/phone'],
				)}`,
				fn: store.getters['member/nameKr'],
				em: store.getters['member/email'],
				external_id: store.getters['auth/memberId'],
				fbp: getCookie('_fbp'),
				fbc: getCookie('_fbc'),
				client_ip_address: store.getters['member/ipinfo']
					? store.getters['member/ipinfo'].ip
					: null,
				client_user_agent: window.navigator.userAgent,
			})
			fbq(
				'track',
				'CompleteRegistration',
				{},
				{ eventID: `signup-member-${store.getters['auth/memberId']}` },
			)
			gtag('event', 'sign_up')

			var _nasa = {}
			_nasa['cnv'] = wcs.cnv('2', 1)
			kakaoPixel('8986155009979562519').completeRegistration()
			amplitude.getInstance().setUserId(store.getters['auth/memberId'])
			amplitude.getInstance().logEvent('signUp')
		}

		Vue.prototype.$conversionBeginCheckout = (paymentId, items) => {
			gtag('event', 'begin_checkout', {
				items,
			})

			fbq(
				'track',
				'InitiateCheckout',
				{},
				{ eventID: `InitiateCheckout-payment-${paymentId}` },
			)
			amplitude.getInstance().logEvent('beginCheckout')
		}
		Vue.prototype.$conversionPurchase = (paymentId, value, currency, items) => {
			dataLayer.push({
				purchaseValue: value,
				purchasePaymentId: paymentId,
				purchaseCurrency: currency,
				purchaseItems: items,
			})
			gtag('event', 'purchase', {
				transaction_id: paymentId,
				value,
				currency,
				items,
			})

			fbq('init', '1786362331644651', {
				ph: `${store.getters['member/countryCode']}${removeZero(
					store.getters['member/phone'],
				)}`,
				fn: store.getters['member/nameKr'],
				em: store.getters['member/email'],
				external_id: store.getters['auth/memberId'],
				fbp: getCookie('_fbp'),
				fbc: getCookie('_fbc'),
				client_ip_address: store.getters['member/ipinfo']
					? store.getters['member/ipinfo'].ip
					: null,
				client_user_agent: window.navigator.userAgent,
			})

			fbq(
				'track',
				'Purchase',
				{ value, currency: 'KRW' },
				{ eventID: `purchase-payment-${paymentId}` },
			)

			kakaoPixel('8986155009979562519').purchase({
				total_quantity: '1', // 주문 내 상품 개수(optional)
				total_price: value, // 주문 총 가격(optional)
				currency: 'KRW', // 주문 가격의 화폐 단위(optional, 기본 값은 KRW)
				products: items,
			})

			var eventProperties = {
				value,
				currency,
			}
			amplitude.getInstance().setUserId(store.getters['auth/memberId'])
			amplitude.getInstance().logEvent('purchase', eventProperties)
		}
	},
}
