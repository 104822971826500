export default {
	isLoading: state => state.isLoading,
	email: state => state.email,
	phone: state => state.phone,
	kakaoId: state => state.kakaoId,
	loginDate: state => state.loginDate,
	nameKr: state => state.nameKr,
	nameEn: state => state.nameEn,
	regDate: state => state.regDate,
	countryCode: state => state.countryCode,
	authFacebook: state => state.authFacebook,
	authKakao: state => state.authKakao,
	group: state => state.group,
	status: state => state.status,
	timezone: state => state.timezone,
	language: state => state.language,
	nationality: state => state.nationality,
	bonusCash: state => state.bonusCash,
	// numberOfLessons: state => state.numberOfLessons,
	yearMonthformat: state =>
		state.language === 'ko'
			? 'YYYY년 M월'
			: state.language === 'ja'
			? 'YYYY年 M月'
			: 'MMM, YYYY',
	yeardateformat: state =>
		state.language === 'ko'
			? 'YYYY년 M월 D일'
			: state.language === 'ja'
			? 'YYYY年 M月 D日'
			: 'MMM. DD, YYYY',
	dateformat: state =>
		state.language === 'ko'
			? 'M월 D일 (ddd)'
			: state.language === 'ja'
			? 'M月 D日(dd)'
			: 'MMM. DD(ddd)',
	datetimeformat: state =>
		state.language === 'ko'
			? 'M월 D일 (ddd) h:mm A'
			: state.language === 'ja'
			? 'M月 D日(dd) A H時mm分'
			: 'MMM. DD(ddd) A H:mm',
	ipinfo: state => state.ipinfo,
}
