<template>
	<v-toolbar
		style="width: 100%; max-width: 400px"
		elevation="0"
		height="60"
		class="px-1"
	>
		<div
			@click="selectType(0)"
			:class="{ 'grey--text': selectedLessonType === 1 }"
			class="mr-4 font-16 font-weight-medium hover-pointer d-flex align-center"
		>
			<img
				style="width: 16px"
				class="mr-1"
				:src="
					require(`@/assets/images/icon/talk-${
						selectedLessonType === 0 ? 'black' : 'grey'
					}.png`)
				"
			/>
			{{ $t('25분톡') }}
		</div>
		<div
			@click="selectType(1)"
			:class="{ 'grey--text': selectedLessonType === 0 }"
			class="font-16 font-weight-medium hover-pointer d-flex align-center"
		>
			<img
				style="width: 16px"
				class="mr-1"
				:src="
					require(`@/assets/images/icon/call-${
						selectedLessonType === 1 ? 'black' : 'grey'
					}.png`)
				"
			/>{{ $t('15분콜') }}
		</div>
		<v-spacer></v-spacer>
		<PlanButton />
		<v-dialog v-model="dialog" width="350" content-class="rounded-xl">
			<v-card rounded="xl" class="pt-5">
				<v-card-text>
					<h1 class="text-h6 mb-3 font-weight-bold">
						{{ $t('준비 중입니다.') }}
					</h1>
					<p
						class="text-body-2 grey--text text--darken-1 mb-8"
						v-html="
							$t('업데이트가 되면 공지 드릴게요!<br />조금만 기다려주세요.🙏')
						"
					></p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="black" text @click="dialog = false">
						{{ $t('확인') }}
					</v-btn>

					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-toolbar>

	<!-- <div class="d-flex align-center white">
		<div
			class="cursor-pointer pa-1 tab-btn"
			:class="{ active: selectedLessonType === 0 }"
			@click="selectType(0)"
		>
			<h1 class="font-weight-bold text-body-1">
				💬&nbsp;&nbsp;{{ $t('25분톡') }}
			</h1>
		</div>
		<div
			class="cursor-pointer pa-1 tab-btn ml-3"
			:class="{ active: selectedLessonType === 1 }"
			@click="selectType(1)"
		>
			<h1 class="font-weight-regular text-body-1">
				📞&nbsp;&nbsp;{{ $t('15분콜') }}
			</h1>
		</div>
		<v-spacer></v-spacer>
		<PlanButton class="mb-2" />
		
	</div> -->
</template>

<script>
import PlanButton from '@/components/common/PlanButton'

export default {
	components: {
		PlanButton,
	},
	data() {
		return {
			dialog: false,
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		selectedLessonType() {
			return this.$store.getters['program/selectedLessonType']
		},
	},
	methods: {
		selectType(type) {
			if (this.language === 'ja' && type === 1) {
				this.dialog = true
				return
			}
			this.$store.commit('program/STORE_LESSON_TYPE', type)
		},
	},
}
</script>

<style lang="scss" scoped></style>
