<template>
	<v-dialog v-model="dialog" width="450">
		<v-card class="pa-5">
			<section style="height: 350px; overflow-y: scroll">
				<h1 class="mb-5 text-h2 font-weight-bold grey--text text--darken-3">
					{{ $t('title.trial-policy') }}
				</h1>

				<div class="text-body-1 grey--text text--darken-1">
					<p class="font-weight-bold mb-1">
						{{ $t('trial-policy.1-title') }}
					</p>
					<p class="white-space-pre mb-5">
						{{ $t('trial-policy.1-text') }}
					</p>
					<p class="font-weight-bold mb-1">
						{{ $t('trial-policy.2-title') }}
					</p>
					<p class="white-space-pre mb-5">
						{{ $t('trial-policy.2-text') }}
					</p>
					<p class="font-weight-bold mb-1">
						{{ $t('trial-policy.3-title') }}
					</p>
					<p class="white-space-pre mb-5">
						{{ $t('trial-policy.3-text') }}
					</p>

					<p class="font-weight-bold mb-5">
						{{ $t('trial-policy.4-title') }}
					</p>
				</div>
			</section>
			<div class="pt-5">
				<v-btn @click="agree()" color="primary" primary block depressed>
					{{ $t('button.confrim') }}
				</v-btn>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		policyDialogControl: {
			required: true,
			type: Boolean,
		},
	},
	data() {
		return {
			dialog: false,
		}
	},
	watch: {
		policyDialogControl() {
			this.dialog = true
		},
	},
	methods: {
		agree() {
			// this.$emit('agreeWithPolicy')
			this.dialog = false
		},
	},
}
</script>

<style lang="scss" scoped></style>
