<template>
	<div>
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="1">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="10">
					<h1 class="text-center text-body-1 font-weight-medium">
						{{ group.title }}
					</h1>
				</v-col>
			</v-row>
		</section>
		<section class="pa-5">
			<ProgramCard
				v-for="program in group.programs"
				:key="program.id"
				:program="program"
				@selectProgram="selectProgram"
				class="mb-3"
			/>
		</section>
	</div>
</template>

<script>
import ProgramCard from '../common/Card'

export default {
	components: {
		ProgramCard,
	},
	data() {
		return {}
	},
	computed: {
		programs() {
			return this.$store.getters['program/programs']
		},
		category() {
			return this.$route.query.category
		},
		type() {
			return this.$route.query.type
		},
		group() {
			const list = [
				{
					title: '✨ 새로운 프로그램이 나왔어요!',
					description: null,
					category: 'new',
					type: 'talk',
					programs: this.programs.filter(
						p => [225, 224, 223, 222, 220, 219].includes(p.id) === true,
					),
				},
				{
					title: '💻 비대면 업무 환경에서 유용한 표현!',
					description: 'Slack, Notion, Zoom에서 영어로 유창하게 말해봐요.',
					category: 'business',
					type: 'talk',
					programs: this.programs.filter(
						p => [203, 204, 205, 206].includes(p.id) === true,
					),
				},
				{
					title: '🛫 해외유학/취직을 꿈꾸는 당신을 위해',
					description:
						'해외에서 공부하고, 일하고, 생활하기 위해 필요한 표현을 알아보고, 대화를 나눠봐요.',
					category: 'abroad',
					type: 'talk',
					programs: this.programs.filter(
						p => [27, 28, 30, 31].includes(p.id) === true,
					),
				},
				{
					title: '🤸‍♀️ 즐겁게 VOCA 공부하기',
					description:
						'무작정 단어 암기는 이제 그만! 채팅으로 자연스럽게 어휘력을 길러보세요.',
					category: 'voca',
					type: 'talk',
					programs: this.programs.filter(
						p => [74, 123, 33, 133, 63, 77].includes(p.id) === true,
					),
				},
				{
					title: '🤔 아직 문장 만들기가 어렵다면?',
					description: '패턴 연습부터 차근차근 시작해봐요!',
					category: 'pattern',
					type: 'talk',
					programs: this.programs.filter(
						p => [20, 21, 103, 107].includes(p.id) === true,
					),
				},
				{
					title: '🎤 OPIc 완전정복',
					description: '나만의 맞춤 답안을 만들고 실전처럼 연습해보세요!',
					category: 'opic',
					type: 'call',
					programs: this.programs.filter(
						p => [170, 171, 172, 173, 174, 175].includes(p.id) === true,
					),
				},
				{
					title: '🤔 아직 영어로 말하기 두렵다면?',
					description: '패턴 연습부터 차근차근 시작해봐요!',
					category: 'pattern',
					type: 'call',
					programs: this.programs.filter(
						p => [141, 142, 163, 164].includes(p.id) === true,
					),
				},
				{
					title: '👊 영어 면접 한 방에 끝내기!',
					description: '영어 면접이 걱정되는 분들을 위한 토탈 솔루션',
					category: 'interview',
					type: 'call',
					programs: this.programs.filter(
						p => [160, 155, 167].includes(p.id) === true,
					),
				},
			]
			return list.filter(
				l => l.type === this.type && l.category === this.category,
			)[0]
		},
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			// const accessDeniedPages = [
			// 	null,
			// 	'BookTimezone',
			// 	'TimeFirstStep2',
			// 	'LessonProgramLanding',
			// 	'LessonProgramBooked',
			// ]
			beforeName !== 'Programs'
				? this.$router.push({ name: 'Programs' })
				: this.$router.go(-1)
		},
		selectProgram(programObj) {
			this.$router.push({
				name: 'ProgramUnit',
				params: { programId: programObj.id },
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
