var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"flex-grow-0 flex-shrink-1",staticStyle:{"min-width":"70px","max-width":"100px"}},[_c('CountryPicker',{attrs:{"originCountryCode":_vm.countryCode,"backgroundColor":'white'},on:{"selectCountry":_vm.selectCountry}})],1),_c('v-text-field',{ref:"phone",staticClass:"tella-form ml-2",attrs:{"label":_vm.$t('common.form.label.phone'),"dense":_vm.dense,"single-line":"","filled":"","type":"tel","rules":_vm.phoneRules,"error-messages":_vm.isPhoneDuplicated === true
					? _vm.$t('common.form.error.phone-duplicated')
					: '',"disabled":_vm.isLoading,"readonly":_vm.isSentNumber === true,"outlined":_vm.outlined,"background-color":_vm.outlined ? 'white' : '',"autocomplete":"off"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('div',{staticClass:"text-right"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"position":"relative","width":"190px"}},[_c('v-text-field',{attrs:{"height":"50","dense":_vm.dense,"label":_vm.$t('common.form.label.verify-code'),"outlined":_vm.outlined,"background-color":_vm.outlined ? 'white' : '',"disabled":_vm.isSentNumber === false ||
						_vm.isVerified === true ||
						_vm.isLoading === true,"readonly":_vm.isVerified === true,"maxlength":"6"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}}),(_vm.isSentNumber)?_c('div',{staticClass:"text-body-1 primary--text",staticStyle:{"position":"absolute","top":"10px","right":"10px"},style:(("top: " + (_vm.dense ? 5 : 12) + "px; "))},[(_vm.isVerified === false && _vm.codeValid.isError === false)?_c('span',[_vm._v(" "+_vm._s(_vm.dueTimer)+" ")]):_vm._e(),(_vm.isVerified)?_c('span',{staticClass:"pt-3 green--text"},[_c('i',{staticClass:"fas fa-check"}),_vm._v(" "+_vm._s(_vm.$t('common.form.text.verify-code-match'))+" ")]):(_vm.codeValid.isError)?_c('span',{staticClass:"pt-3 red--text"},[_c('i',{staticClass:"fal fa-times"}),_vm._v(" "+_vm._s(_vm.codeValid.errMsg)+" ")]):_vm._e()]):_vm._e()],1),_c('v-spacer'),(_vm.isSentNumber === false)?_c('v-btn',{staticStyle:{"width":"calc(100% - 200px)"},attrs:{"x-large":"","height":"50","disabled":_vm.phoneValid == false || _vm.isLoading === true,"loading":_vm.isLoading,"depressed":"","color":_vm.actionColor},on:{"click":function($event){return _vm.sendVerifyNumber()}}},[_vm._v(" "+_vm._s(_vm.$t('common.form.text.send-verify-code'))+" ")]):_c('v-btn',{staticClass:"ml-3",staticStyle:{"width":"calc(100% - 200px)"},attrs:{"disabled":_vm.isSentNumber === false ||
					!_vm.code ||
					_vm.isVerified === true ||
					_vm.isLoading == true,"loading":_vm.isLoading,"small":_vm.dense,"height":_vm.dense ? 40 : 51,"color":_vm.actionColor,"depressed":"","x-large":""},on:{"click":function($event){return _vm.verifyCode()}}},[_vm._v(" "+_vm._s(_vm.$t('common.form.text.phone-verify'))+" ")])],1)]),(_vm.status === 'pending')?_c('p',{staticClass:"mt-2 text-caption"},[_vm._v(" "+_vm._s(_vm.$t('common.form.text.verify-code-sent'))),_c('br'),_vm._v(" "+_vm._s(_vm.$t( '인증번호가 오지 않으면 입력하신 정보가 정확한지 확인하여 주세요. 정확한 정보임에도 인증번호가 오지 않을 시' ))+" "),_c('u',{staticClass:"primary--text hover-pointer",on:{"click":_vm.resend}},[_vm._v(" "+_vm._s(_vm.$t('재전송')))]),_vm._v(_vm._s(_vm.$t('을 클릭해주세요.'))+" ")]):(_vm.status === 'tooManyRequest')?_c('p',{staticClass:"text-caption white-space-pre"},[_vm._v(" "+_vm._s(_vm.$t('common.form.error.verify-code-too-many'))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }