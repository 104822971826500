<template>
	<v-app id="tella-web" :class="background">
		<!-- v-if="isLoading === false" -->
		<component :is="layout">
			<router-view></router-view>
			<ChannelTalk v-if="language === 'ko'" />
		</component>
		<CancelledSnackBar />
		<ErrorDialog />
	</v-app>
</template>

<script>
import CancelledSnackBar from '@/components/common/CancelledSnackBar.vue'

import ChannelTalk from '@/components/common/ChannelTalk'
import ErrorDialog from '@/components/common/ErrorDialog'

export default {
	name: 'App',
	components: {
		CancelledSnackBar,
		ChannelTalk,
		ErrorDialog,
	},
	data: function () {
		return {
			isLoading: false,
		}
	},
	computed: {
		isAuthChecked() {
			return this.$store.getters['auth/isAuthChecked']
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		routeInfo() {
			return JSON.stringify({
				name: this.$route.name,
				params: this.$route.params,
				query: this.$route.query,
			})
		},
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		background() {
			return this.$store.getters['device/background']
		},
		language() {
			return this.$store.getters['member/language']
		},
		layout() {
			return this.$route.meta.layout
		},
	},
	created() {
		this.setScreenSize()
		window.addEventListener('resize', () => this.setScreenSize())
		this.$store.commit('device/DETECT')
	},
	methods: {
		setScreenSize() {
			let vh = window.innerHeight * 0.01
			// Then we set the value in the --vh custom property to the root of the document
			document.documentElement.style.setProperty('--vh', `${vh}px`)
		},
	},
}
</script>

<style>
@import url('./assets/css/main.css');
/* @import url('./assets/css/color.scss'); */
@import url('./assets/css/typography.css');
@import url('./assets/css/button.css');
@import url('./assets/css/form.css');

#tella-web {
	/* overflow-y: hidden; */
	touch-action: none !important;
	height: calc(var(--vh, 1vh) * 100);
}
#tella-web .v-application--wrap {
	min-height: calc(var(--vh, 1vh) * 100);
}

/**레이아웃 */
.with-footer {
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(var(--vh, 1vh) * 100 - 59px);
	padding-bottom: 100px;
}

.without-footer {
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(var(--vh, 1vh) * 100);
	padding-bottom: 100px;
}

/** */
.cursor-pointer:hover {
	cursor: pointer;
}
.cursor-disabled:hover {
	cursor: not-allowed;
}
/** 탭 버튼 설정 */
.tab-btn {
	transition: all 0.1s ease-in-out;
	border-bottom: 3px solid rgba(0, 0, 0, 0);
}
.tab-btn h1 {
	transition: all 0.1s ease-in-out;
	color: #c4c4c4;
	font-weight: normal;
}
.tab-btn.active {
	border-color: #000;
}
.tab-btn.active h1 {
	font-weight: bold;
	color: #000;
}
.sub-tab-btn {
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	border-radius: 10px;
	padding: 2px 6px;
	margin-right: 3px;
	color: #c4c4c4;
}
.sub-tab-btn.active {
	background: #353535;
	color: #fff;
}

.fixed-button-wrap {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 4;
	max-width: 400px;
}
@media (min-width: 400px) {
	/* .fixed-button-wrap {
		margin-left: -1px;
	} */
}

.fixed-bar-wrap {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 2;
	max-width: 398px;
}
@media (min-width: 400px) {
	.fixed-bar-wrap {
		/* margin-left: -1px; */
		/* border-right: 1px solid #dadada; */
		/* border-left: 1px solid #dadada; */
	}
}

::-webkit-scrollbar {
	width: 5px; /* 스크롤바의 너비 */
	height: 5px; /* 스크롤바의 너비 */
}

::-webkit-scrollbar-thumb {
	height: 30%; /* 스크롤바의 길이 */
	background: rgba(0, 0, 0, 0.3); /* 스크롤바의 색상 */
	border-radius: 10px;
}

::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0.1); /*스크롤바 뒷 배경 색상*/
}
</style>
