<template>
	<div>
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('적립금') }}</h1>
				</v-col>
			</v-row>
		</section>
		<section class="blue lighten-5">
			<v-row no-gutters align="center" justify="center" style="height: 200px">
				<v-col class="text-center">
					<p class="text-body-2">{{ $t('보유한 적립금') }}</p>
					<p class="primary--text">
						<span class="text-h4 font-weight-bold">
							{{ numberWithCommas(bonusCash) }}
						</span>
						{{ $t('cash') }}
					</p>
				</v-col>
			</v-row>
		</section>
		<section class="pt-3">
			<v-row no-gutters class="text-center">
				<v-col cols="4">
					<div
						@click="tab = 'all'"
						class="py-1 tab-btn"
						:class="{ active: tab === 'all' }"
					>
						{{ $t('전체') }}
					</div>
				</v-col>
				<v-col cols="4">
					<div
						@click="tab = 'add'"
						class="py-1 tab-btn"
						:class="{ active: tab === 'add' }"
					>
						{{ $t('적립') }}
					</div>
				</v-col>
				<v-col cols="4">
					<div
						@click="tab = 'sub'"
						class="py-1 tab-btn"
						:class="{ active: tab === 'sub' }"
					>
						{{ $t('사용') }}
					</div>
				</v-col>
			</v-row>
			<v-divider></v-divider>
		</section>
		<section class="px-3">
			<List :history="item" v-for="item in filtered" :key="item.id" />
			<div
				v-if="isLoading === false && history.length === 0"
				class="py-12 text-center text-body-2 grey--text"
			>
				{{ $t('보유한 적립금이 없습니다.') }}
			</div>
		</section>
	</div>
</template>

<script>
import Api from '@/services/index'
import List from './List'
import { numberWithCommas } from '@/helpers/number-format'
const R = require('ramda')

export default {
	components: {
		List,
	},
	data: function () {
		return {
			isLoading: false,
			history: [],
			tab: 'all',
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		bonusCash() {
			return this.$store.getters['member/bonusCash']
		},
		filtered() {
			return R.filter(
				h => h.operator.toLowerCase() === this.tab || this.tab === 'all',
			)(this.history)
		},
	},
	created() {
		this.get()
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			beforeName !== 'MyPage' && beforeName !== 'Quest'
				? this.$router.push({ name: 'MyPage' })
				: this.$router.go(-1)
		},
		async get() {
			this.isLoading = true
			const result = await Promise.all([
				Api.get(`cash-history?member-id=${this.memberId}&sort-by=-created_at`),
				this.$store.dispatch('member/get'),
			])
			this.history = result[0].data
			this.isLoading = false
			// 멤버정보, 쿠폰정보
		},
		numberWithCommas: number => numberWithCommas(number),
	},
}
</script>

<style lang="scss" scoped>
.tab-btn {
	color: #c4c4c4;
	box-sizing: border-box;
}
.tab-btn.active {
	color: #353535;
	font-weight: bold;
	border-bottom: 3px solid #353535;
}
</style>
