<template>
	<span class="font-weight-bold after hover-pointer" @click="clickWord">
		<span v-if="clickCount === 0">{{ blank }}</span>
		<span v-else-if="clickCount === 1">{{ firstShow }}</span>
		<span v-else>{{ word.value }}</span>
	</span>
</template>

<script>
import EventBus from '@/lib/eventBus'

export default {
	props: {
		correctionId: {
			required: true,
			type: Number,
		},
		word: {
			required: true,
		},
		index: {
			required: true,
			type: Number,
		},
	},
	data: () => ({
		clickCount: 0,
	}),
	computed: {
		blank() {
			let blank = ''
			for (let i = 0; i < this.word.value.length; i++) {
				blank += '_'
			}
			return blank
		},
		firstShow() {
			let blank = this.word.value.substring(0, 1)
			if (blank === ' ') {
				blank = this.word.value.substring(1, 2)
			}
			for (let i = 0; i < this.word.value.length - 1; i++) {
				blank += '_'
			}
			return blank
		},
	},

	created() {
		EventBus.$on('clickShowAllInReviewCorrection', this.showAll)
	},
	beforeDestroy() {
		EventBus.$off('clickShowAllInReviewCorrection', this.showAll)
	},
	methods: {
		showAll(val) {
			if (this.correctionId === val.correctionId) {
				this.clickCount = val.status === true ? 2 : 0
			}
		},
		clickWord() {
			this.clickCount++

			if (this.clickCount === 1 && this.word.value.length <= 1) {
				this.clickCount++
			}

			if (this.clickCount === 2) {
				this.$emit('showWord', 'show', {
					index: this.index,
					value: this.word.value,
				})
				return
			}
			if (this.clickCount === 3) {
				this.clickCount = 0
				this.$emit('showWord', 'hide', {
					index: this.index,
					value: this.word.value,
				})
				return
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.after {
	color: #00cba0;
}
</style>
