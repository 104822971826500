<template>
	<div
		class="d-flex align-center my-2 py-2 hover-pointer"
		data-test="checkCancelButton"
		@click="checkCancel"
	>
		<img src="@/assets/images/home/cancel.png" style="width: 40px" alt="" />
		<div class="pl-2 text-left" data-test="checkCancelText">
			<h3 class="font-15">{{ $t('component.lessonCard.button.수업 취소') }}</h3>
			<p class="font-13 grey--text text--darken-1">
				{{
					$t(
						'component.lessonCard.text.수업 취소는 수업 1시간 전까지만 가능합니다.',
					)
				}}
			</p>
		</div>
		<v-spacer></v-spacer>
		<img
			src="@/assets/images/icon/chevron-right-light.png"
			style="width: 18px; vertical-align: middle"
			alt=""
		/>

		<v-dialog v-model="rejectDialog" width="310">
			<v-card data-test="rejectDialog" class="rounded-lg">
				<div class="px-4 py-5">
					<h1 class="font-19 mb-4">
						{{ $t('component.lessonCard.title.수업을 취소할 수 없습니다.') }}
					</h1>
					<p class="font-14 mb-5">
						{{
							$t(
								'component.lessonCard.text.수업 시작 1시간 전까지만 예약을 취소하실 수 있습니다.',
							)
						}}
					</p>
					<v-btn
						depressed
						block
						color="primary"
						@click="rejectDialog = false"
						>{{ $t('component.lessonCard.button.확인') }}</v-btn
					>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog v-model="confirmDialog" width="310">
			<v-card data-test="confirmDialog" class="rounded-lg">
				<div class="px-4 py-5">
					<h1 class="font-19 mb-4">
						{{ $t('component.lessonCard.title.수업을 취소할까요?') }}
					</h1>
					<p class="grey--text text--darken-1 font-14 mb-5">
						{{
							$t(
								'component.lessonCard.text.아래의 수업을 취소하고 수강권을 복구합니다.',
							)
						}}
					</p>
					<div class="date-box d-flex align-center mb-3">
						<div
							:class="{
								'primary--text primary--1': lesson.lessonType === 0,
								'emerald--text emrald--1': lesson.lessonType === 1,
							}"
							class="mr-1 lesson-type-chip font-12 font-weight-bold"
						>
							{{ lesson.lessonType === 1 ? 'Call' : 'Talk' }}
						</div>
						<div class="font-15 font-weight-medium">
							{{
								`${lesson.dt
									.toFormat('ff')
									.substr(5)} - ${lesson.endDt.toFormat('h')}:25`
							}}
						</div>
					</div>

					<v-row no-gutters>
						<v-col cols="6" class="pr-1">
							<v-btn
								:disabled="isLoading === true"
								depressed
								block
								color="primary--0"
								@click="confirmDialog = false"
								>{{ $t('component.lessonCard.button.닫기') }}</v-btn
							>
						</v-col>
						<v-col cols="6" class="pl-1">
							<v-btn
								:loading="isLoading === true"
								depressed
								block
								color="primary"
								@click="cancel('수강권이 복구되었습니다.')"
								data-test="cancelButtonInConfirmDialog"
								>{{ $t('component.lessonCard.button.수업 취소하기') }}</v-btn
							>
						</v-col>
					</v-row>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog v-model="expiredPointDialog" width="310">
			<v-card data-test="expiredPointDialog" class="rounded-lg">
				<div class="px-4 py-5">
					<h1 class="font-19 mb-4">
						{{ $t('component.lessonCard.title.유효기간이 만료된 수강권입니다.')
						}}<br />{{ $t('component.lessonCard.title.수업을 취소할까요?') }}
					</h1>
					<p
						class="grey--text text--darken-1 font-14 mb-5"
						v-html="
							$t(
								`component.lessonCard.text.유효기간이 만료된 수강권의 경우,<br />예약을 취소하시면 수강권이반환되지 않습니다.`,
							)
						"
					></p>

					<v-row no-gutters>
						<v-col cols="6" class="pr-1">
							<v-btn
								:disabled="isLoading === true"
								depressed
								block
								color="primary--0"
								@click="expiredPointDialog = false"
								>{{ $t('component.lessonCard.button.닫기') }}</v-btn
							>
						</v-col>
						<v-col cols="6" class="pl-1">
							<v-btn
								:loading="isLoading === true"
								depressed
								block
								color="primary"
								@click="cancel('수업이 취소되었습니다.')"
								data-test="cancelButtonInExpiredPointDialog"
								>{{ $t('component.lessonCard.button.수업 취소하기') }}</v-btn
							>
						</v-col>
					</v-row>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import scheduleApi from '@/services/schedule'
import { DateTime } from 'luxon'
import EventBus from '@/lib/eventBus'

export default {
	props: {
		lesson: {
			required: true,
			type: Object,
		},
	},
	data: () => ({
		isLoading: false,
		rejectDialog: false,
		confirmDialog: false,
		expiredPointDialog: false,
	}),
	methods: {
		checkCancel() {
			if (this.lesson.dt <= DateTime.now().plus({ minutes: 60 })) {
				this.rejectDialog = true
				return
			}

			if (
				this.lesson.pointExpiryDate <
				DateTime.now().setZone('Asia/Seoul').toISODate()
			) {
				this.expiredPointDialog = true
				return
			}

			this.confirmDialog = true
			// this.$router.push({
			// 	name: 'LessonProgramSelect',
			// 	params: { lessonId: this.lesson.id },
			// })
		},
		async cancel(msg) {
			this.isLoading = true
			await scheduleApi.cancelLesson(this.lesson.id)
			this.confirmDialog = false
			this.expiredPointDialog = false
			EventBus.$emit('lessonIsCancelled', msg)
			this.isLoading = false
		},
	},
}
</script>

<style lang="scss" scoped>
.date-box {
	border: 1px solid #f0f0f0;
	border-radius: 10px;
	padding: 15px 10px;
}
.lesson-type-chip {
	border-radius: 18px;
	padding: 4px 10px;
}
.talk.lesson-type-chip {
	color: #0074ff;
	background: #cce6ff;
}
</style>
