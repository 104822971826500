<template>
	<div>
		<v-app-bar class="pt-4" color="white" height="59" elevation="0">
			<a href="https://tella.co.kr">
				<img
					src="@/assets/images/logo/default.png"
					style="width: 50px"
					:alt="$t('img.alt.텔라 홈페이지')"
					class="mb-6"
				/>
			</a>
		</v-app-bar>
		<v-divider></v-divider>
		<v-container style="padding-bottom: 60px">
			<h1 class="pt-4 pb-7 text-h1 font-weight-bold">
				{{ $t('sign-up.title.sign-up') }}
			</h1>
			<v-form ref="form" v-model="isValid" @submit.prevent="signup">
				<v-text-field
					class="mb-4"
					:label="$t('common.form.label.email')"
					v-model.trim="email"
					:rules="emailRules"
					:disabled="isLoading"
					style="ime-mode: inactive"
					outlined
					background-color="white"
				></v-text-field>
				<v-text-field
					class="mb-4"
					:label="$t('form.label.name')"
					v-model="name"
					:rules="nameRules"
					:disabled="isLoading"
					outlined
					background-color="white"
				>
				</v-text-field>
				<VerifyPhone
					:actionColor="'primary'"
					:outlined="true"
					:dense="false"
					@verify="verify"
					class="mb-4"
				/>

				<v-text-field
					type="password"
					:label="$t('common.form.label.password')"
					v-model.trim="password"
					:rules="passwordRules"
					autocomplete="false"
					:disabled="isLoading"
					outlined
					background-color="white"
				></v-text-field>
				<v-text-field
					type="password"
					:label="$t('common.form.label.password-confirm')"
					v-model.trim="confirm"
					:rules="confirmRules"
					autocomplete="false"
					:disabled="isLoading"
					outlined
					background-color="white"
				></v-text-field>
				<div class="d-flex align-center">
					<v-checkbox
						single-line
						class="mt-0"
						v-model="marketingAgree"
						hide-details
					>
					</v-checkbox>

					<p
						@click="marketingDialog = true"
						class="font-weight-bold text-body-2 grey--text text--darken-1"
					>
						{{ $t('sign-up.text.marketing-agree-option') }}
					</p>
				</div>

				<p
					style="margin-left: 32px"
					class="font-weight-regular text-body-2 grey--text text--darken-1"
				>
					{{ $t('sign-up.text.let-you-know-event-by-marketing-sms') }}
				</p>
				<v-btn
					:disabled="available === false"
					class="mt-8"
					depressed
					color="primary"
					block
					large
					type="submit"
					:loading="isLoading"
					@click.native="loader = 'loading'"
					>{{ $t('sign-up.button.sign-up') }}</v-btn
				>
				<i18n
					path="sign-up.text.agree"
					tag="p"
					class="text-body-2 text-center mt-2"
				>
					<template v-slot:policy>
						<a
							style="text-decoration: unset"
							class="primary--text text--darken-2"
							href="#"
							@click="policyDialog = true"
							>{{ $t('sign-up.text.policy') }}</a
						>
					</template>
					<template v-slot:privacy>
						<a
							style="text-decoration: unset"
							class="primary--text text--darken-2"
							href="#"
							@click="privacyDialog = true"
							>{{ $t('sign-up.text.privacy') }}</a
						>
					</template>
				</i18n>
			</v-form>
			<div class="mt-8 mb-3 d-flex align-center">
				<v-divider class="grey lighten-1"></v-divider>
				<p class="grey--text text--darken-1 text-body-2 px-1">
					{{ $t('sign-up.text.sign-up-with-social-account') }}
				</p>
				<v-divider class="grey lighten-1"></v-divider>
			</div>
			<div class="d-flex justify-center">
				<KakaoButton @loading="loading" :isLoading="isLoading" class="pr-1" />
				<FacebookButton
					@loading="loading"
					:isLoading="isLoading"
					class="pl-1"
				/>
			</div>
		</v-container>
		<v-dialog v-model="policyDialog" width="500" content-class="rounded-lg">
			<v-card rounded="lg" class="py-3 px-4">
				<div class="pt-2 pb-3">
					<h1 class="modal-h1 font-weight-bold">
						{{ $t('sign-up.policy-modal.title.policy') }}
					</h1>
				</div>
				<div class="py-2" style="height: 400px; overflow-y: auto">
					<Policy class="white-space-pre" />
				</div>
				<v-row no-gutters class="py-3">
					<v-btn color="primary" depressed block @click="policyDialog = false">
						{{ $t('sign-up.policy-modal.button.confirm') }}
					</v-btn>
				</v-row>
			</v-card>
		</v-dialog>
		<v-dialog v-model="privacyDialog" width="500" content-class="rounded-lg">
			<v-card rounded="lg" class="py-3 px-4">
				<div class="pt-2 pb-3">
					<h1 class="modal-h1 font-weight-bold">
						{{ $t('sign-up.pravacy-modal.title.pravacy') }}
					</h1>
				</div>
				<div class="py-2" style="height: 400px; overflow-y: auto">
					<Privacy class="white-space-pre" />
				</div>
				<v-row no-gutters class="py-3">
					<v-btn color="primary" depressed block @click="privacyDialog = false">
						{{ $t('sign-up.pravacy-modal.button.confirm') }}
					</v-btn>
				</v-row>
			</v-card>
		</v-dialog>
		<v-dialog v-model="marketingDialog" width="500" content-class="rounded-lg">
			<v-card rounded="lg" class="py-3 px-4">
				<div class="pt-2 pb-3">
					<h1 class="modal-h1 font-weight-bold">
						{{ $t('sign-up.marketing-agree-modal.title') }}
					</h1>
				</div>
				<div class="py-2" style="max-height: 400px; overflow-y: auto">
					<p class="mb-3">
						{{ $t('marketing-agree.1.title') }}
					</p>
					<p class="mb-7 white-space-pre">
						{{ $t('marketing-agree.1.text') }}
					</p>
					<p class="mb-3">
						{{ $t('marketing-agree.2.title') }}
					</p>
					<p class="mb-7 white-space-pre">
						{{ $t('marketing-agree.2.text') }}
					</p>
					<p class="mb-3">
						{{ $t('marketing-agree.3.title') }}
					</p>
					<p class="mb-7 white-space-pre">
						{{ $t('marketing-agree.3.text') }}
					</p>
				</div>
				<v-row no-gutters class="py-3">
					<v-btn
						color="primary"
						depressed
						block
						@click="marketingDialog = false"
					>
						{{ $t('sign-up.pravacy-modal.button.confirm') }}
					</v-btn>
				</v-row>
			</v-card>
		</v-dialog>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="false"
			:numberOfButton="1"
			:title="errTitle"
			:text="''"
			:actionColor="'primary'"
			:action="errAction"
			:actionText="$t('common.modal.button.confirm')"
		/>
	</div>
</template>

<script>
import Api from '@/services/index'
import Policy from '@/components/common/Policy'
import Privacy from '@/components/common/Privacy'
import KakaoButton from './KakaoButton'
import FacebookButton from './FacebookButton'
import Dialog from '@/components/common/Dialog'
import VerifyPhone from '@/components/common/VerifyPhone'

var crypto = require('crypto')

export default {
	components: {
		Policy,
		Privacy,
		KakaoButton,
		FacebookButton,
		Dialog,
		VerifyPhone,
	},
	data() {
		return {
			isLoading: false,
			isValid: false,
			policyDialog: false,
			privacyDialog: false,
			marketingDialog: false,
			publicKey: null,
			isVerified: false,
			phone: null,
			countryCode: null,
			email: null,
			emailRules: [
				v => !!v || this.$t('common.form.error.email-required'),
				v =>
					(v && v.length <= 100) || this.$t('common.form.error.email-too-long'),
				v => /.+@.+\..+/.test(v) || this.$t('common.form.error.email-format'),
			],
			name: null,
			nameRules: [v => !!v || this.$t('form.error.name-required')],
			password: null,
			passwordRules: [
				v => !!v || this.$t('common.form.error.password-required'),
				v =>
					(v && /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(v) === false) ||
					this.$t('common.form.error.password-format'),
				v =>
					(v && v.length <= 20 && v.length >= 6) ||
					this.$t('common.form.error.password-minimum-maximum'),
			],
			confirm: '',
			confirmRules: [
				v => !!v || this.$t('common.form.error.password-required'),
				v =>
					(v && v === this.password) ||
					this.$t('common.form.error.confrim-not-matched'),
			],
			marketingAgree: false,
			// === 에러
			errorDialog: false,
			errTitle: '',
			errAction: this.closeErrDialog,
		}
	},
	computed: {
		encryptedPassword() {
			let encryptedPW = null
			if (this.password) {
				const hashPassword = crypto
					.createHash('md5')
					.update(this.password)
					.digest('hex')
				encryptedPW = crypto
					.publicEncrypt(this.publicKey, Buffer.from(hashPassword, 'utf8'))
					.toString('base64')
			}
			return encryptedPW
		},
		timezone() {
			return this.$store.getters['member/timezone']
		},
		language() {
			return this.$store.getters['member/language']
		},
		nationality() {
			return this.$store.getters['member/nationality']
		},
		available() {
			return this.isValid === true && this.isVerified === true ? true : false
		},
		redir() {
			return this.$store.getters['device/redir']
		},
	},
	created() {
		this.getPublicKey()
		this.gtagEvent()
	},
	methods: {
		gtagEvent() {
			// eslint-disable-next-line no-undef
			gtag('event', 'signup_form_view', {
				event_path: `my.tella.co.kr${this.$route.path}`,
			})
		},
		loading(boolean) {
			this.isLoading = boolean
		},
		verify(boolean, numberObj) {
			this.isVerified = boolean
			if (boolean === false) {
				this.phone = null
				this.countryCode = null
			}
			if (boolean === true && numberObj) {
				this.phone = numberObj.phone
				this.countryCode = numberObj.countryCode
			}
			this.phoneDialog = false
		},
		async getPublicKey() {
			try {
				this.isLoading = true
				const res = await Api.get('/auth/pulic-key')
				this.publicKey = res.data
				this.isLoading = false
			} catch (err) {
				this.errorDialog('getPublicKeyError')
			}
		},
		async signup() {
			await this.$refs.form.validate()

			if (this.isValid === false) {
				return
			}

			try {
				this.isLoading = true
				let body = {
					name: this.name,
					email: this.email,
					encryptedPassword: this.encryptedPassword,
					countryCode: this.countryCode,
					phone: this.phone,
					nationality: this.nationality,
					language: this.language,
					timezone: this.timezone,
					marketingAgree: this.marketingAgree,
				}
				await Api.post('/auth/sign-up', body)
				await this.$store.dispatch('auth/get')
				await this.$store.dispatch('member/get')
				this.$conversionSignup()
				const redir = this.redir ? this.redir : { name: 'Home' }
				this.$router.push(redir)
			} catch (err) {
				const code = err.response.data.code
				this.errorHandler(code)
				// console.log(err.response ? err.response : err)
			} finally {
				this.isLoading = false
			}
		},
		errorHandler(code) {
			switch (code) {
				case 'getPublicKeyError':
					this.errTitle = this.$t('sign-up.error.get-public-key')
					break
				case '10100':
					this.errTitle = this.$t('sign-up.error.already-user')
					break
				default:
					this.errTitle = this.$t('sign-up.error.default-error-message')
			}
			this.errorDialog = !this.errorDialog
		},
		closeErrDialog() {
			this.errorDialog = !this.errorDialog
		},
	},
}
</script>

<style lang="scss" scoped></style>
