<template>
	<Wrap />
</template>

<script>
import Wrap from '@/components/ResetPassword'
export default {
	components: {
		Wrap,
	},
}
</script>

<style lang="scss" scoped></style>
