import moment from 'moment-timezone'
import store from '../store'

function codeToTime(timeCode) {
	let timeStr = timeCode.toString()
	let timeHour = timeStr.slice(0, -1)
	let lastNumber = timeStr.substring(timeStr.length - 1, timeStr.length)
	let timeMin
	if (lastNumber === '0') {
		timeMin = '00'
	} else if (lastNumber === '1') {
		timeMin = '00'
	} else if (lastNumber === '2') {
		timeMin = '20'
	} else if (lastNumber === '3') {
		timeMin = '40'
	} else if (lastNumber === '5') {
		timeMin = '30'
	}
	return timeHour + ':' + timeMin
}

function codeToMoment(date, timecode, language) {
	if (!language) language = store.getters['member/language']
	return timecode
		? moment
				.tz(date + ' ' + codeToTime(timecode), 'YYYY-MM-DD hh:mm', 'Asia/Seoul')
				.locale(language)
		: moment.tz(date, 'YYYY-MM-DD', 'Asia/Seoul').locale(language)
}

/**
 *
 * @param {*} datetimeMoment moment
 * @param {Number} lessnType 0 or 1
 * @returns
 */
function momentToCode(datetimeMoment, lessonType) {
	const hour = datetimeMoment.tz('Asia/Seoul').format('H')
	const min = datetimeMoment.tz('Asia/Seoul').format('mm')
	let last = '0'
	if (min === '00' && lessonType === 0) last = 0
	if (min === '30' && lessonType === 0) last = 5
	if (min === '00' && lessonType === 1) last = 1
	if (min === '20' && lessonType === 1) last = 2
	if (min === '40' && lessonType === 1) last = 3
	/* time = H:mm */

	return `${hour}${last}`
}

function addMoment(array) {
	return array.map(obj => {
		obj.moment = codeToMoment(obj.lesson_date, obj.lesson_time)
			.tz(store.getters['member/timezone'])
			.locale(store.getters['member/language'])
		let classTime = obj.lesson_type === 0 ? 25 : 15
		obj.endMoment = obj.moment.clone().add(classTime, 'minutes')
		return obj
	})
}

export { codeToMoment, momentToCode, addMoment }
