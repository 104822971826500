<template>
	<v-container style="max-width: 375px">
		<section class="pt-5 pb-10">
			<a href="https://tella.co.kr">
				<img
					src="@/assets/images/logo/default.png"
					style="width: 50px"
					:alt="$t('img.alt.텔라 홈페이지')"
					class="mb-4"
				/>
			</a>
			<h1 class="text-h2 font-weight-bold white-space-pre">{{ h1 }}</h1>
		</section>
		<v-slide-y-transition v-if="selectedMoment === null">
			<TimePicker
				:nationality="'jp'"
				:timezone="timezone"
				style="max-width: 450px"
				@selectMoment="selectMoment"
			/>
		</v-slide-y-transition>
		<section>
			<TrialForm
				:selectedMoment="selectedMoment"
				@selectMoment="selectMoment"
			/>
		</section>
	</v-container>
</template>

<script>
import TimePicker from '../common/TimePicker'
import TrialForm from './Form'

export default {
	components: {
		TimePicker,
		TrialForm,
	},
	data() {
		return {
			h1: this.$t('title.book-trial-for-member'),
			selectedMoment: null,
		}
	},
	computed: {
		timezone() {
			return this.$store.getters['member/timezone']
		},
	},
	created() {
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-3', {
			root: true,
		})
	},
	beforeRouteLeave() {
		this.$store.commit('device/SET_BACKGROUND', null, {
			root: true,
		})
	},
	methods: {
		selectMoment(m) {
			this.selectedMoment = m
		},
	},
}
</script>

<style lang="scss" scoped></style>
