const R = require('ramda')

export const LOAD = 'LOAD'
export const STORE = 'STORE'
export const STORE_TOTAL_COUNT = 'STORE_TOTAL_COUNT'
export const STORE_PAST_ALL = 'STORE_PAST_ALL'
export const STORE_PAST_MONTH = 'STORE_PAST_MONTH'
export const SELECT_CATEGORY = 'SELECT_CATEGORY'
export const SET_KEYWORD = 'SET_KEYWORD'

export default {
	[LOAD](state, payload) {
		state.isLoading = payload
	},
	[STORE](state, payload) {
		if (payload === null || payload === undefined) state.tutors = []
		else if (payload.length > 0) state.tutors = R.concat(state.tutors, payload)
	},
	[STORE_TOTAL_COUNT](state, payload) {
		state.totalCount = payload
	},
	[STORE_PAST_ALL](state, payload) {
		state.pastTutorIds = payload
	},
	[STORE_PAST_MONTH](state, payload) {
		state.pastTutorIdsForMonth = payload
	},
	[SELECT_CATEGORY](state, payload) {
		state.category = payload
	},
	[SET_KEYWORD](state, payload) {
		state.keyword = payload
	},
}
