<template>
	<div>
		<div class="text-h3">📬</div>
		<h1
			class="text-h6 font-weight-bold"
			v-html="
				$t('월별 리포트는 수업 수강 후<br />매달 마지막 날에 보내드려요.')
			"
		></h1>
		<p class="text-body-2 grey--text text--darken-1">
			{{ $t('수업을 수강하고 한 달간 학습 성과를 확인해보세요!') }}
		</p>
		<v-btn
			v-if="availablePoints.length === 0"
			@click="showMePlans"
			large
			class="mt-5 font-weight-bold px-6 text-body-1"
			rounded
			color="primary"
			depressed
			>{{ $t('수강권 살펴보기') }}</v-btn
		>
		<v-btn
			v-else
			@click="$store.commit('device/BOOK_ACTION_SHEET_CONTROL')"
			large
			class="mt-5 font-weight-bold px-6 text-body-1"
			rounded
			color="primary"
			depressed
			>{{ $t('수업 예약하기') }}</v-btn
		>
		<v-dialog content-class="rounded-xl" v-model="dialog" width="350">
			<v-card rounded="xl" class="pt-5">
				<v-card-text>
					<h1 class="text-h6 mb-3 font-weight-bold">
						⚠️&nbsp;{{ $t('준비 중입니다.') }}
					</h1>
					<p
						class="text-body-2 grey--text text--darken-1"
						v-html="
							$t(
								'고객센터로 문의 주시면 수강권 구성 및 가격 관련 안내 드리겠습니다.<br />고객센터로 이동하시겠어요?',
							)
						"
					></p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-btn color="grey" text @click="dialog = false">
						{{ $t('아니오.') }}
					</v-btn>
					<v-spacer></v-spacer>

					<v-btn color="primary" text @click="goToCs()">
						{{ $t('고객센터로 이동하기') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		availablePoints() {
			return this.$store.getters['point/availablePoints']
		},
	},
	methods: {
		showMePlans() {
			this.language === 'ja'
				? (this.dialog = true)
				: this.$router.push({ name: 'Plans', params: { category: 'package' } })
		},
	},
}
</script>

<style lang="scss" scoped></style>
