<template>
	<div>
		<v-app-bar class="pt-4" color="white" height="59" elevation="0">
			<a href="https://tella.co.kr">
				<img
					src="@/assets/images/logo/default.png"
					style="width: 50px"
					:alt="$t('img.alt.텔라 홈페이지')"
					class="mb-6"
				/>
			</a>
		</v-app-bar>
		<v-divider></v-divider>
		<v-container style="padding-bottom: 60px">
			<h1 class="pt-4 pb-7 text-h1 font-weight-bold">
				{{ $t('login.title.login') }}
			</h1>
			<div class="mb-3 d-flex align-center">
				<v-divider class="grey lighten-1"></v-divider>
				<p class="grey--text text--darken-1 text-body-2 px-1">
					{{ $t('login.text.start-with-social-account') }}
				</p>
				<v-divider class="grey lighten-1"></v-divider>
			</div>
			<div class="d-flex justify-center">
				<KakaoButton
					class="pr-1"
					@tokenIssued="getMe"
					@loading="childLoading"
					:isAllLoading="isAllLoading"
				/>
				<FacebookButton
					class="pl-1"
					@tokenIssued="getMe"
					@loading="childLoading"
					:isAllLoading="isAllLoading"
				/>
			</div>
			<v-divider class="grey lighten-1 mt-5 mb-4"></v-divider>
			<Form
				@tokenIssued="getMe"
				@loading="childLoading"
				:isAllLoading="isAllLoading"
			/>
		</v-container>
		<div class="pb-4 d-block d-sm-none">
			<v-divider class="mt-16"></v-divider>
			<p class="pt-4 text-center text-body-2 grey--text text--darken-3">
				{{ $t('login.text.You don`t have an account yet?') }}
				<a
					@click="$router.push({ name: 'SignUp' })"
					class="pl-3"
					style="text-decoration: underline"
					>{{ $t('login.button.sign-up') }}</a
				>
			</p>
		</div>
		<div
			class="text-body-2 text-center d-none d-sm-block"
			style="
				position: fixed;
				bottom: 0;
				width: 100%;
				max-width: 400px;
				height: 60px;
				padding-top: 22px;
				border-top: 1px solid #f0f0f0;
			"
		>
			{{
				$t('login.text.You don`t have an account yet?')
			}}&nbsp;&nbsp;&nbsp;&nbsp;<a
				@click="goToSignUp"
				style="text-decoration: underline"
				>{{ $t('login.button.sign-up') }}</a
			>
		</div>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="errDialogPersistent"
			:numberOfButton="errNumberOfButton"
			:title="errTitle"
			:text="errMsg"
			:actionColor="errActionColor"
			:action="errAction"
			:actionText="errActionText"
		/>
	</div>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import KakaoButton from './KakaoButton'
import FacebookButton from './FacebookButton'
import Form from './Form'
import SlackMsg from '@/services/slack'

export default {
	components: {
		KakaoButton,
		FacebookButton,
		Form,
		Dialog,
	},
	data() {
		return {
			isLoading: false,
			isChildLoading: false,

			// === 에러
			errorDialog: false,
			errTitle: '',
			errMsg: '',
			errNumberOfButton: 1,
			errDialogPersistent: false,
			errActionColor: 'primary',
			errAction: this.closeErrDialog,
			errActionText: this.$t('common.modal.button.confirm'),
		}
	},
	computed: {
		redir() {
			return this.$route.query.redir
				? JSON.parse(this.$route.query.redir)
				: null
		},
		isAllLoading() {
			return this.isLoading === true || this.isChildLoading === true
				? true
				: false
		},
	},
	methods: {
		goToSignUp() {
			this.$router.push({ name: 'SignUp' })
		},
		async getMe() {
			try {
				this.isLoading = true
				await this.$store.dispatch('auth/get')
				await this.$store.dispatch('member/get')

				if (this.$store.getters['member/status'] === 2) {
					this.$router.push({ name: 'Activate' })
					return
				}
				const redir = this.redir
					? this.redir
					: {
							name: 'Home',
							params: { locale: this.$i18n.locale },
					  }
				this.$router.push(redir)
			} catch (err) {
				SlackMsg.post('', { text: JSON.stringify(err.stack.substring(0, 500)) })
				const errorCode =
					err.response && err.response.data ? err.response.data.code : 'FVBG201'

				this.errTitle = this.$t('login.error.default-error-message', {
					errorCode,
				})
				this.errorDialog = true

				await this.$store.dispatch('auth/signOut')
				this.isLoading = false
			} finally {
				// eslint-disable-next-line no-undef
				gtag('event', 'login')
				// eslint-disable-next-line no-undef
				amplitude.getInstance().setUserId(this.$store.getters['auth/memberId'])
			}
		},

		childLoading(status) {
			this.isChildLoading = status
		},
		closeErrDialog() {
			this.errorDialog = !this.errorDialog
		},
	},
}
</script>

<style lang="scss" scoped></style>
