<template>
	<div v-if="isLoading === false">
		<section>
			<div class="mb-5">
				<div v-if="recommendSlot">
					<h2 class="grey--text text--darken-3 text-body-1 font-weight-bold">
						{{ $t('trial.title.recommend-time') }}
					</h2>
					<p class="grey--text text--darken-1 mb-5 text-body-2 grey--4--text">
						{{ $t('trial.text.recommend-time') }}
					</p>
					<v-btn
						@click="selectTime(recommendSlot, 'recommend')"
						class="mb-3 slot"
						outlined
						x-large
						block
						:class="{
							selected: recommendSlot === selectedSlot,
						}"
					>
						<v-icon class="mr-2">mdi-check-circle</v-icon>
						{{ formattedRecommendSlot }}
					</v-btn>
				</div>
				<p class="grey--text text--darken-1 text-body-2 grey--4--text">
					{{
						$t('trial.text.타임존-표기-안내', {
							timezone,
						})
					}}
					<TimezonePicker
						:originTimezone="timezone"
						@selectTimezone="selectTimezone"
					/>
				</p>
			</div>
			<div>
				<h2
					class="
						grey--text
						text--darken-3
						mt-7
						mb-4
						text-body-1
						font-weight-bold
					"
				>
					{{ $t('trial.title.all-time') }}
				</h2>
				<div
					v-for="(group, date) in groupByDate"
					:key="toMoment(date).format('MM-DD')"
					style="position: relative"
				>
					<h1
						style="
							z-index: 2;
							position: -webkit-sticky;
							position: sticky;
							top: 0;
						"
						class="text-body-2 grey--text text--darken-3 mb-2"
					>
						{{ localeDateFormat(date) }}
					</h1>
					<v-row no-gutters class="pb-10">
						<v-col
							cols="2"
							class="mb-2"
							v-for="slot in group"
							:key="`${date}-${slot.time_code}`"
						>
							<v-btn
								x-small
								:ripple="false"
								outlined
								class="slot text-body-2"
								:disabled="
									slot.lessons_count >= slot.capacity ||
									slot.isDuplicated === true
								"
								:class="{
									disabled:
										slot.lessons_count >= slot.capacity ||
										slot.isDuplicated === true,
									selected: slot === selectedSlot,
								}"
								@click="selectTime(slot, 'list')"
							>
								{{ localTime(slot.moment) }}
							</v-btn>
						</v-col>
					</v-row>
				</div>
				<div v-if="Object.keys(groupByDate).length === 0">
					<p class="text-body-1 font-italic">
						{{ $t('trial.text.no-slot') }}
					</p>
				</div>
			</div>
		</section>
	</div>

	<Loader v-else :height="100" />
</template>

<script>
import moment from 'moment-timezone'
import Api from '@/services/index'
import Loader from '@/components/common/Loader'
import TimezonePicker from './TimezonePicker'
const R = require('ramda')

export default {
	components: {
		Loader,
		TimezonePicker,
	},
	props: {
		nationality: {
			type: String,
			default: 'korea',
		},
		timezone: {
			required: false,
			type: String,
			defualt: 'Asia/Seoul',
		},
	},
	data: function () {
		return {
			isLoading: false,
			selectedType: 0,
			selectedSlot: null,
			slots: [],
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		startMoment() {
			let startMoment = moment().add(30, 'minutes')
			// 	this.nationality === 'jp'
			// 		? moment().add(36, 'hours')
			// 		: moment().add(30, 'minutes')
			// while (startMoment.day() === 0) {
			// 	startMoment = startMoment.clone().add(1, 'hours')
			// }
			return startMoment
		},
		endMoment() {
			const startMoment = moment(this.startMoment).clone()
			let endMoment = startMoment.add(24, 'hours')
			// while (endMoment.day() === 0) {
			// 	endMoment = endMoment.clone().add(24, 'hours')
			// }
			// while (endMoment.day() === 1 && endMoment.format('H') * 1 < 6) {
			// 	endMoment = endMoment.clone().add(6, 'hours')
			// }
			return endMoment
		},
		query() {
			let query = `?lesson-type=0&start-datetime=${this.startMoment.toISOString()}&end-datetime=${this.endMoment.toISOString()}`
			return query
		},
		groupByDate() {
			const byDate = R.groupBy(time => {
				const date = moment(time.moment).tz(this.timezone).format(`YYYY-MM-DD`)
				return date
			})

			return byDate(this.slots)
		},
		recommendSlot() {
			const availableOrderedSlots = R.pipe(
				R.sortWith([R.ascend(R.prop('datetime'))]),
				R.filter(s => s.lessons_count < s.capacity),
			)(this.slots)
			return availableOrderedSlots.length > 0 ? availableOrderedSlots[0] : null
		},
		formattedRecommendSlot() {
			if (this.recommendSlot === null) return null
			const m = moment(this.recommendSlot.moment).tz(this.timezone)
			const date =
				m.format('MM/DD') === moment().format('MM/DD')
					? this.$t('common.today')
					: m.clone().locale(this.language).format('ll').substr(5) +
					  m.clone().locale(this.language).format(' (ddd)')
			const time = m.clone().locale('en').format(' h:mm A')
			return `${date}${time}`
		},
	},
	watch: {
		timezone() {
			this.get()
		},
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			this.isLoading = true
			const res = await Api.get(`capacities/times${this.query}`)

			this.slots = res.data
			this.isLoading = false
		},
		localeDateFormat(date) {
			const dayName =
				this.startMoment.format('YYYY-MM-DD') === date
					? this.$t('common.today')
					: this.startMoment.clone().add(1, 'days').format('YYYY-MM-DD') ===
					  date
					? this.$t('common.tomorrow')
					: this.$t('common.day-after-tomorrow')
			const dateMoment = moment
				.tz(date, this.timezone)
				.locale(this.$i18n.locale)
			return dateMoment.format(`ll (${dayName}, ddd)`).substr(5)
		},
		toMoment(date) {
			return moment.tz(date, this.timezone)
		},
		selectTime(slot) {
			const slotMoment = moment(slot.moment).tz(this.timezone)
			if (slotMoment < moment().add(30, 'minutes')) {
				alert(this.$t('modal.title.trial-book-error-no-slot'))
				return
			}
			this.selectedSlot = slot
			this.$emit('selectMoment', slotMoment)
		},
		localTime(iso) {
			return moment(iso).tz(this.timezone).format('HH:mm')
		},
		selectTimezone(timezone) {
			this.$emit('selectTimezone', timezone)
		},
	},
}
</script>

<style lang="scss" scoped>
.date-btn {
	border-radius: 50%;
	width: 31px;
	height: 31px;
	margin: 0 auto;
}
.date-btn.active {
	color: #fff;
	background-color: #1e78ff;
}

// .slot {
// 	letter-spacing: normal;
// 	font-weight: normal;
// 	max-width: 95%;
// 	height: 30px;
// 	margin: 5px auto;
// 	border: solid 1px #e9e9e9;
// 	background-color: #ffffff;
// }

// .slot:hover {
// 	cursor: unset;
// }
// .selected.slot {
// 	background: rgba(30, 120, 255, 0.05);
// 	border: 1px solid #1e78ff;
// 	box-sizing: border-box;
// }
</style>
