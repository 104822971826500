<template>
	<div style="position: relative">
		<v-btn
			@click="endDialog = true"
			:max-width="userDevice === 'desktop' ? 471 : 215"
			:height="userDevice === 'desktop' ? 61 : 44"
			x-large
			rounded
			depressed
			class="action-btn"
			:class="btnClass"
			><span
				class="font-sm-28 font-16 font-weight-bold"
				:class="userDevice === 'desktop' ? contentClass : ''"
				>수강 신청하기</span
			>
		</v-btn>
		<v-dialog v-model="endDialog" width="310">
			<v-card class="py-5 px-3">
				<h1 class="font-19 font-weight-bold mb-3">
					수강신청이 마감되었습니다.
				</h1>
				<p class="mb-5 font-14 font-weight-regular grey--text text--darken-1">
					수강을 원하시는 경우 고객센터로 문의해주세요.
				</p>

				<v-row no-gutters>
					<v-col cols="6" class="pr-1">
						<v-btn
							@click="endDialog = false"
							block
							depressed
							color="grey lighten-3"
							><strong class="font-weight-bold grey--text text--darken-3"
								>닫기</strong
							></v-btn
						>
					</v-col>
					<v-col cols="6" class="pl-1">
						<v-btn
							href="https://tella.channel.io/support-bots/30776"
							block
							depressed
							color="primary"
							><strong class="font-weight-bold">1:1 채팅상담</strong></v-btn
						></v-col
					>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
// import EventBus from '@/lib/eventBus'

export default {
	props: {
		btnClass: {
			required: true,
			type: String,
		},
		contentClass: {
			required: true,
			type: String,
		},
	},
	data: () => ({
		show: false,
		endDialog: false,
	}),
	computed: {
		userDevice() {
			return this.$store.getters.userDevice
		},
	},
	// methods: {
	// 	clickApply() {
	// 		EventBus.$emit('clickApply')
	// 	},
	// },
}
</script>

<style lang="scss" scoped>
.action-btn {
	width: 100%;
}

.black-btn {
	background-color: #0d0d0d !important;
	span {
		color: #dbff73;
	}
}
.green-btn {
	background-color: #dbff73 !important;
}
</style>
