import { render, staticRenderFns } from "./HowToCheckKakaoNumber.vue?vue&type=template&id=55d868ce&scoped=true&"
import script from "./HowToCheckKakaoNumber.vue?vue&type=script&lang=js&"
export * from "./HowToCheckKakaoNumber.vue?vue&type=script&lang=js&"
import style0 from "./HowToCheckKakaoNumber.vue?vue&type=style&index=0&id=55d868ce&lang=scss&scoped=true&"
import style1 from "./HowToCheckKakaoNumber.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55d868ce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBottomSheet,VBtn,VCard,VCardActions,VImg,VItem,VItemGroup,VSheet,VWindow,VWindowItem})
