<template>
	<div>
		<Wrap />
	</div>
</template>

<script>
// import store from '@/store'
import Wrap from '@/components/book/time-first/Wrap'
// import moment from 'moment-timezone'
// import BookButtonFab from '@/components/my/common/BookButtonFab'
/**
 * 예약되어 있는 수업, 사용 가능한 수강권 불러오기
 */
export default {
	components: {
		Wrap,
	},
	// beforeRouteEnter(to, from, next) {
	// 	const goTo = { name: to.name, query: to.query, params: to.params }

	// 	store.getters['member/kakaoId']
	// 		? next()
	// 		: next({ name: 'MoreInfo', query: { redir: JSON.stringify(goTo) } })
	// },
}
</script>
