<template>
	<span class="font-weight-bold after hover-pointer">
		{{ blank }}
	</span>
</template>

<script>
export default {
	props: {
		word: {
			required: true,
		},
	},
	computed: {
		blank() {
			let blank = ''
			for (let i = 0; i < this.word.value.length; i++) {
				blank += '_'
			}
			return blank
		},
	},
}
</script>

<style lang="scss" scoped>
.after {
	color: #00cba0;
}
</style>
