<template>
	<div>
		<v-app-bar class="pt-4" color="white" height="59" elevation="0">
		</v-app-bar>
		<v-divider></v-divider>
		<v-container>
			<h1 class="pt-7 pb-3 text-h1 font-weight-bold">
				{{ $t('reset-password.title.reset-password') }}
			</h1>
			<p class="text-body-1 pb-12">
				{{ $t('reset-password.text.reset-password') }}
			</p>
			<v-form ref="form" v-model="valid" @submit.prevent="reset">
				<v-text-field
					:label="$t('reset.password.label.current-email')"
					v-model.trim="email"
					type="email"
					class="mb-3"
					outlined
					hide-details="auto"
					autocomplete="on"
					disabled
				></v-text-field>
				<v-text-field
					:label="$t('common.form.label.password')"
					v-model.trim="password"
					type="password"
					class="mb-3"
					outlined
					hide-details="auto"
					:rules="passwordRules"
					autocomplete="on"
					:disabled="isLoading"
				></v-text-field>
				<v-text-field
					:label="$t('common.form.label.password-confirm')"
					v-model.trim="confirm"
					type="password"
					class="mb-3"
					outlined
					hide-details="auto"
					:rules="passwordRules"
					:error-messages="
						isPasswordMatch === false
							? $t('common.form.error.confirm-password-not-matched')
							: ''
					"
					autocomplete="on"
					:disabled="isLoading"
				></v-text-field>
				<v-btn
					type="submit"
					@click.native="loader = 'loading'"
					block
					depressed
					color="primary"
					x-large
					:dark="valid && !isLoading"
					:disabled="!valid || isLoading"
				>
					{{ $t('reset-password.button.reset-password') }}
				</v-btn>
			</v-form>
		</v-container>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="true"
			:numberOfButton="1"
			:title="errTitle"
			:text="''"
			:actionColor="'primary'"
			:action="errAction"
			:actionText="$t('common.modal.button.confirm')"
		/>
	</div>
</template>

<script>
import Api from '@/services/index'
var crypto = require('crypto')
import Dialog from '@/components/common/Dialog'

export default {
	components: {
		Dialog,
	},
	data: function () {
		return {
			isLoading: false,
			valid: false,
			publicKey: null,
			password: null,
			passwordRules: [
				v => !!v || this.$t('common.form.error.password-required'),
				v =>
					(v && /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(v) === false) ||
					this.$t('common.form.error.password-format'),
				v =>
					(v && v.length <= 20 && v.length >= 6) ||
					this.$t('common.form.error.password-minimum-maximum'),
			],
			confirm: '',
			// === 에러
			errorDialog: true,
			errTitle: '',
			errAction: this.goToFindPassword,
		}
	},
	computed: {
		code() {
			return this.$route.params.code
		},
		email() {
			return this.$route.query.email
		},
		isPasswordMatch() {
			let isMatch = true
			if (
				this.password !== null &&
				this.confirm !== null &&
				this.password !== this.confirm
			)
				isMatch = false
			return isMatch
		},
		encryptedPassword() {
			let encryptedPW = null
			if (this.password) {
				const hashPassword = crypto
					.createHash('md5')
					.update(this.password)
					.digest('hex')
				encryptedPW = crypto
					.publicEncrypt(this.publicKey, Buffer.from(hashPassword, 'utf8'))
					.toString('base64')
			}
			return encryptedPW
		},
	},
	created() {
		this.verify()
	},
	methods: {
		async getPublicKey() {
			try {
				this.isLoading = true
				const res = await Api.get('/auth/pulic-key')
				this.publicKey = res.data
				this.isLoading = false
			} catch (err) {
				this.errorHandler('publicKey')
			}
		},
		async verify() {
			try {
				this.isLoading = true
				if (!this.code || !this.email) {
					this.errHandler('accessDenied')
					return
				}
				await Api.get(
					`/auth/reset-password/${encodeURIComponent(this.code)}?email=${
						this.email
					}`,
				)
				this.getPublicKey()
			} catch (err) {
				const errCode =
					err.response.data && err.response.data.code
						? err.response.data.code
						: 'FB20AO'
				this.errHandler(errCode)
			}
		},
		async reset() {
			try {
				this.isLoading = true
				await Api.patch(
					`/auth/reset-password/${encodeURIComponent(this.code)}?email=${
						this.email
					}`,
					{ encryptedPassword: this.encryptedPassword },
				)
				this.errHandler('passwordChanged')
			} catch (err) {
				this.errHandler(err)
			}
		},
		errHandler(errCode) {
			switch (errCode) {
				case 'passwordChanged':
					this.errTitle = this.$t('reset-password.success.reset-password')
					this.errAction = this.goToSignIn
					break
				case 'publicKey':
					this.errTitle = this.$t('reset-password.error.get-public-key')
					this.errAction = this.goToFindPassword
					break
				case 'accessDenied':
					this.errTitle = this.$t('reset-password.error.access-denied')
					this.errAction = this.goToFindPassword
					break
				case '10030':
					this.errTitle = this.$t('reset-password.error.email-is-broken')
					this.errAction = this.goToFindPassword
					break
				case '10031':
					this.errTitle = this.$t('reset-password.error.code-is-broken')
					this.errAction = this.goToFindPassword
					break
				case '10032':
					this.errTitle = this.$t(
						'reset-password.error.reset-password-avilable-in-2-hours',
					)
					this.errAction = this.goToFindPassword
					break
				case '10033':
					this.errTitle = this.$t('reset-password.error.code-is-already-used')
					this.errAction = this.goToSignIn
					break
				default:
					this.errTitle = this.$t('reset-password.error.default-error-message')
					this.errAction = this.goToFindPassword
			}
			this.errorDialog = !this.errorDialog
		},

		goToFindPassword() {
			this.$router.push({ name: 'FindPassword' })
		},
		goToSignIn() {
			this.$router.push({ name: 'SignIn' })
		},
	},
}
</script>

<style lang="scss" scoped></style>
