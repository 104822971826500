var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.logs),function(log,i){return _c('div',{key:log.id,class:{
			'mb-5': !_vm.logs[i + 1] || log.type !== _vm.logs[i + 1].type,
			'mb-2': _vm.logs[i + 1] && log.type === _vm.logs[i + 1].type,
		}},[_c('div',{staticClass:"d-flex align-end",class:{
				'justify-start': log.type === 'Tutor',
				'justify-end': log.type === 'Tutee',
			}},[_c('div',{staticClass:"chat-bubble",class:{
					'order-1 tutee': log.type === 'Tutee',
					tutor: log.type === 'Tutor',
				},domProps:{"innerHTML":_vm._s(log.content)}})]),(log.editings && log.editings.length > 0)?_c('div',{staticClass:"mt-3 d-flex",class:{
				'justify-start': log.type === 'Tutor',
				'justify-end': log.type === 'Tutee',
			}},[_c('div',{staticClass:"editing-wrap"},[_c('h2',{staticClass:"font-weight-bold font-12 mb-2"},[_c('img',{staticStyle:{"width":"15px","vertical-align":"middle","margin-right":"2px"},attrs:{"src":require("@/assets/images/emoji/pencil.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t('튜터 첨삭'))+" ")]),_vm._l((log.editings),function(editing){return _c('div',{key:editing.id,staticClass:"mb-1"},[_c('div',{staticClass:"editing font-14"},[_c('span',{domProps:{"innerHTML":_vm._s(
								_vm.afterDiff(
									editing.tutee_chat_log_content,
									editing.tutor_chat_log_content
								)
							)}})])])})],2)]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }