var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-14 pb-sm-15 pb-12 px-5",staticStyle:{"background-color":"#f1f5ff"},attrs:{"id":"faq"}},[_c('div',{staticClass:"pt-sm-6 pb-sm-10 pb-6 text-center"},[_c('h1',{staticClass:"text--h2 mb-10 text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('trial.confirmation.title.FAQ'))+" ")]),_c('v-expansion-panels',{staticClass:"rounded-lg mx-auto",staticStyle:{"max-width":"730px","overflow":"hidden"},attrs:{"accordion":"","flat":""}},_vm._l((_vm.list),function(item){return _c('v-expansion-panel',{key:item.id},[_c('v-expansion-panel-header',{staticClass:"py-2 pl-4 pr-1 py-sm-5 pl-sm-9 pr-sm-5",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-img',{style:(("width:" + (_vm.userDevice === 'mobile' ? 40 : 60) + "px;")),attrs:{"src":require("@/assets/images/trial-landings/ic-arrow-dropdown-d.svg")}})]},proxy:true}],null,true)},[_c('h1',{staticClass:"pt-1 title"},[_c('strong',{staticClass:"primary--text"},[_vm._v("Q. ")]),_vm._v(_vm._s(item.q)+" ")])]),_c('v-expansion-panel-content',{staticClass:"pa-4 pt-sm-6 pb-sm-7 px-sm-10 text-left white-space-pre",staticStyle:{"background-color":"#f8faff"}},[_c('p',{staticClass:"text mb-2"},[_vm._v(" "+_vm._s(item.a)+" ")]),(item.id === 1 || item.id === 2)?_c('v-btn',{attrs:{"rounded":"","outlined":"","color":"primary","small":_vm.userDevice === 'mobile'},on:{"click":function($event){return _vm.$router.push({
								name: 'TrialReschedule',
								params: { trialId: _vm.trialId },
								query: {
									'country-code': _vm.countryCode,
									phone: _vm.phone,
								},
							})}}},[_c('p',{staticClass:"button-text primary--text"},[_vm._v(" "+_vm._s(item.id === 1 ? _vm.$t('trial.confirmation.button.change-schedule-or-cancel') : _vm.$t('trial.confirmation.button.change-info'))+" "),_c('v-icon',{staticStyle:{"margin-top":"-4px"}},[_vm._v("mdi-chevron-right")])],1)]):_vm._e()],1)],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }