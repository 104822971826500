<template>
	<!-- :style="
			userDevice === 'desktop'
				? 'border-right: 1px solid #dadada !important;border-left: 1px solid #dadada !important;'
				: ''
		" -->
	<v-footer
		id="footer"
		fixed
		flat
		style="border-top: 1px solid #dadada !important; height: 59px"
		class="white text-center pa-0 no-gutters"
	>
		<div class="foot-menu" @click="gtagEvent('홈'), goTo('Home')">
			<img
				style="width: 30px"
				:src="
					require(`@/assets/images/icon/home${
						$route.name === 'Home' ? '-active' : ''
					}.png`)
				"
				alt=""
			/>
			<p
				class="menu-title grey--text"
				:class="{
					'text--darken-3 font-weight-medium': $route.name === 'Home',
					'text--darken-1 font-weight-regular': $route.name !== 'Home',
				}"
			>
				{{ $t('홈') }}
			</p>
		</div>
		<div class="foot-menu" @click="gtagEvent('프로그램'), goTo('Programs')">
			<img
				style="width: 30px"
				:src="
					require(`@/assets/images/icon/program${
						programRoutes.includes($route.name) === true ? '-active' : ''
					}.png`)
				"
				alt=""
			/>
			<p
				class="menu-title grey--text"
				:class="{
					'text--darken-3 font-weight-medium':
						programRoutes.includes($route.name) === true,
					'text--darken-1 font-weight-regular':
						programRoutes.includes($route.name) === false,
				}"
			>
				{{ $t('프로그램') }}
			</p>
		</div>
		<div
			class="foot-menu"
			@click="gtagEvent('나의 수업'), goTo('AppointedLessons')"
		>
			<img
				style="width: 30px"
				:src="
					require(`@/assets/images/icon/schedule${
						lessonRoutes.includes($route.name) === true ? '-active' : ''
					}.png`)
				"
				alt=""
			/>
			<p
				class="menu-title grey--text"
				:class="{
					'text--darken-3 font-weight-medium':
						lessonRoutes.includes($route.name) === true,
					'text--darken-1 font-weight-regular':
						lessonRoutes.includes($route.name) === false,
				}"
			>
				{{ $t('footer.menu-name.나의 수업') }}
			</p>
		</div>
		<div
			@click="gtagEvent('학습리포트'), goTo('MonthlyReport')"
			class="foot-menu"
		>
			<img
				style="width: 30px"
				:src="
					require(`@/assets/images/icon/report${
						reportRouts.includes($route.name) === true ? '-active' : ''
					}.png`)
				"
				alt=""
			/>

			<p
				class="menu-title grey--text"
				:class="{
					'text--darken-3 font-weight-medium':
						reportRouts.includes($route.name) === true,
					'text--darken-1 font-weight-regular':
						reportRouts.includes($route.name) === false,
				}"
			>
				{{ $t('학습리포트') }}
			</p>
		</div>
		<div @click="gtagEvent('마이페이지'), goTo('MyPage')" class="foot-menu">
			<img
				style="width: 30px"
				:src="
					require(`@/assets/images/icon/my${
						mypageRoutes.includes($route.name) === true ? '-active' : ''
					}.png`)
				"
				alt=""
			/>

			<p
				class="menu-title grey--text"
				:class="{
					'text--darken-3 font-weight-medium':
						mypageRoutes.includes($route.name) === true,
					'text--darken-1 font-weight-regular':
						mypageRoutes.includes($route.name) === false,
				}"
			>
				{{ $t('마이페이지') }}
			</p>
		</div>
	</v-footer>
</template>

<script>
export default {
	name: 'Footer',
	data: () => ({
		programRoutes: ['Programs', 'ProgramSearch'],
		reportRouts: ['Quest', 'MonthlyReport'],
		mypageRoutes: ['MyPage', 'Setting'],
		lessonRoutes: [
			'AppointedLessons',
			'ReviewLessonsAll',
			'ReviewLessonsTalk',
			'ReviewLessonsCall',
			'ReviewLessonsLevelTest',
			'LessonsCalendar',
		],
	}),
	computed: {
		availablePoints() {
			return this.$store.getters['point/availablePoints']
		},
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		kakaoId() {
			return this.$store.getters['member/kakaoId']
		},
		phone() {
			return this.$store.getters['member/phone']
		},
	},
	methods: {
		gtagEvent(category) {
			const params = {
				event_path: `my.tella.co.kr${this.$route.path}`,
				event_category: category,
			}
			// eslint-disable-next-line no-undef
			gtag('event', 'click_2home_GNB', params)
		},
		openBookActionSheet() {
			// if (!this.kakaoId) {
			// 	const goTo = {
			// 		name: this.$route.name,
			// 		query: this.$route.query,
			// 		params: this.$route.params,
			// 	}

			// 	this.$router.push({
			// 		name: 'MoreInfo',
			// 		query: { redir: JSON.stringify(goTo) },
			// 	})
			// 	return
			// }
			this.availablePoints.length > 0
				? this.$store.commit('device/BOOK_ACTION_SHEET_CONTROL')
				: this.$router.push({ name: 'Plans', params: { category: 'package' } })
		},
		goTo(routeName) {
			this.$route.name === routeName
				? this.$router.go()
				: this.$router.push({ name: routeName }).catch(() => {})
		},
	},
}
</script>

<style scoped>
.foot-menu {
	width: 20%;
	line-height: 1.2em;
	padding-top: 8px;
}
.foot-menu:hover {
	cursor: pointer;
}
.active {
	position: absolute;
	top: -1px;
	background: #076cfa;
	display: inline-block;
	margin: 0 1em 1em 0;
	height: 6px;
	width: 12px;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	display: inline-block;
	vertical-align: top;
	box-sizing: border-box;
}
/* .book-btn {
	background: linear-gradient(135deg, #007aff 30%, #00cba0 90%);
} */

.foot-menu .menu-title {
	font-size: 10px;
	line-height: 12px;
}

.gradient-button {
	color: #fff;
	background: linear-gradient(136.67deg, #466bf8 14.07%, #9e53e9 80.15%);
}
</style>
