import moment from 'moment-timezone'
const R = require('ramda')

/**
 * 만료일 >= 지금, 시작일 <= 오늘, active =1
 *
 * @param {*} point
 * @returns
 */
const isAvailable = point =>
	point.active === 1 &&
	point.remaining_points > 0 &&
	point.expiry_moment >= moment() &&
	point.start_moment <= moment()

const isPaid = point =>
	point.active === 1 &&
	point.is_bonus === 0 &&
	point.expiry_moment >= moment() &&
	point.start_moment <= moment()

export default {
	points: state => state.points,
	totalCount: state => state.totalCount,
	isLoading: state => state.isLoading,
	availablePoints: state => R.filter(isAvailable, state.points),
	paidPoints: state => R.filter(isPaid, state.points),
	talkRemaining: (state, getters) => {
		return R.pipe(
			R.filter(p => p.type === 0),
			R.map(x => x.remaining_points),
			R.sum(),
		)(getters.availablePoints)
	},
	callRemaining: (state, getters) => {
		return R.pipe(
			R.filter(p => p.type === 1),
			R.map(x => x.remaining_points),
			R.sum(),
		)(getters.availablePoints)
	},
	expiryDates: (state, getters) => {
		let dates = []
		getters.availablePoints.forEach(p => {
			let index = R.findIndex(R.propEq('expiryDate', p.expiry_date))(dates)
			if (index >= 0) {
				if (p.type === 0)
					dates[index].talkSum = dates[index].talkSum + p.remaining_points
				if (p.type === 1)
					dates[index].callSum = dates[index].callSum + p.remaining_points
			} else {
				dates.push({
					expiryDate: p.expiry_date,
					expiryMoment: moment.tz(p.expiry_date, 'Asia/Seoul'),
					talkSum: p.type === 0 ? p.remaining_points : 0,
					callSum: p.type === 1 ? p.remaining_points : 0,
				})
			}
		})
		return R.sortWith([R.ascend(R.prop('expiryDate'))])(dates)
	},
	availableTalk: (state, getters) => {
		const points = R.filter(point => point.type === 0, getters.availablePoints)
		const remaining = R.pipe(
			R.map(x => x.remaining_points),
			R.sum(),
		)(points)
		const assigned = R.pipe(
			R.map(x => x.assigned_points),
			R.sum(),
		)(points)

		return {
			type: 0,
			points,
			remaining,
			assigned,
		}
	},
	alertsTalk: (state, getters) => {
		let alerts = []
		getters.availableTalk.points.forEach(point => {
			const index = R.findIndex(R.propEq('expiryDate', point.expiry_date))(
				alerts,
			)
			if (index >= 0) {
				alerts[index].remainingPoints =
					alerts[index].remainingPoints + point.remaining_points
			} else {
				alerts.push({
					expiryDate: point.expiry_date,
					expiryMoment: point.expiry_moment,
					remainingPoints: point.remaining_points,
					isForcedShow: point.expiry_date <= moment().add(7, 'days'),
				})
			}
		})
		return R.sortWith([R.ascend(R.prop('expiryDate'))])(alerts)
	},
	availableCall: (state, getters) => {
		const points = R.filter(point => point.type === 1, getters.availablePoints)
		const remaining = R.pipe(
			R.map(x => x.remaining_points),
			R.sum(),
		)(points)
		const assigned = R.pipe(
			R.map(x => x.assigned_points),
			R.sum(),
		)(points)

		return {
			type: 1,
			points,
			remaining,
			assigned,
		}
	},
	alertsCall: (state, getters) => {
		let alerts = []
		getters.availableCall.points.forEach(point => {
			const index = R.findIndex(R.propEq('expiryDate', point.expiry_date))(
				alerts,
			)
			if (index >= 0) {
				alerts[index].remainingPoints =
					alerts[index].remainingPoints + point.remaining_points
			} else {
				alerts.push({
					expiryDate: point.expiry_date,
					expiryMoment: point.expiry_moment,
					remainingPoints: point.remaining_points,
					isForcedShow: point.expiry_date <= moment().add(7, 'days'),
				})
			}
		})
		return R.sortWith([R.ascend(R.prop('expiryDate'))])(alerts)
	},
}
