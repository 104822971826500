<template>
	<section>
		<div class="px-2">
			<h2 class="mb-3 text-body-1 font-weight-bold">
				{{ $t('이번 달에 얼마나 공부했을까?') }}
			</h2>
			<p class="mb-4 text-body-2 grey--text text--darken-2">
				{{ $t('영어 공부는 조금씩이라도 매일 하는 것이 중요해요!') }}
			</p>
		</div>
		<div>
			<v-row no-gutters>
				<v-col
					cols="6"
					:class="item.class"
					v-for="item in stats"
					:key="item.title"
				>
					<v-card
						flat
						rounded="xl"
						:class="{
							'talk-card': lessonType === 0,
							'call-card': lessonType === 1,
						}"
						class="px-5 pt-4 pb-6"
					>
						<h3
							:class="{
								'primary--text': lessonType === 0,
								'emerald--text': lessonType === 1,
							}"
							class="text-body-1 font-weight-bold"
							v-html="item.title"
						></h3>
						<p
							class="text-body-1"
							style="margin-top: -3px"
							v-html="item.value"
						></p>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</section>
</template>

<script>
const R = require('ramda')

export default {
	props: {
		lessons: {
			required: true,
			tyep: Array,
		},
		progresses: {
			required: true,
			tyep: Array,
		},
		lessonType: {
			required: true,
			tyep: Number,
		},
	},
	computed: {
		analyzedLessons() {
			return R.filter(l => l.status === 'analyzed')(this.lessons)
		},
		attendaceRate() {
			return this.lessons.length
				? Math.round((this.analyzedLessons.length / this.lessons.length) * 100)
				: 0
		},
		time() {
			const unitTime = this.lessonType === 0 ? 0.5 : 0.25
			return this.analyzedLessons.length * unitTime * 60
		},
		sumOfExpressions() {
			return R.pipe(
				R.filter(p => p.lesson_type === this.lessonType),
				R.map(p => p.number_of_target_expressions),
				R.sum(),
			)(this.progresses)
		},
		stats() {
			return [
				{
					title: `⏰<br />${
						this.lessonType === 0
							? this.$t('25분톡 수업')
							: this.$t('15분콜 수업')
					}`,
					value: this.$t(`%{lessonCount}회`, {
						lessonCount: `<span class="text-h5 font-weight-bold">${this.analyzedLessons.length}</span>`,
					}),
					class: 'pr-1 pb-1',
				},
				{
					title: `📊<br />${
						this.lessonType === 0
							? this.$t('25분톡 출석률')
							: this.$t('15분콜 출석률')
					}`,
					value: this.$t('%{rate}%', {
						rate: `<span class="text-h5 font-weight-bold">${this.attendaceRate}</span>`,
					}),
					class: 'pl-1 pb-1',
				},
				{
					title: this.$t('🕘<br />영어로 생각한 시간'),
					value: this.$t('%{time}분', {
						time: `<span class="text-h5 font-weight-bold">${this.time}</span>`,
					}),
					class: 'pr-1 pt-1',
				},
				{
					title: this.$t('💡<br />새로 익힌 표현'),
					value: this.$t('%{sumOfExpressions}개', {
						sumOfExpressions: `<span class="text-h5 font-weight-bold">${this.sumOfExpressions}</span>`,
					}),
					class: 'pl-1 pt-1',
				},
			]
		},
	},
}
</script>

<style lang="scss" scoped>
.talk-card {
	background: rgba(30, 120, 255, 0.05);
}
.call-card {
	background: rgba(0, 204, 161, 0.05);
}
</style>
