var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-sm-16 mt-sm-6 pb-sm-16 pt-15 pb-12",staticStyle:{"background-color":"#f1f5ff"},attrs:{"id":"trial-correction"}},[_c('h1',{staticClass:"text--h1 mb-sm-12 mb-9 text-center"},[_c('strong',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('trial.confirmation.title.첨삭 문장 모아보기')))])]),_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"880px","width":"100%","position":"relative"}},[_c('div',{staticClass:"d-none d-sm-block fade-out fade-out-left"}),_c('div',{staticClass:"d-none d-sm-block fade-out fade-out-right"}),_c('swiper',{staticClass:"swiper",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.corrections),function(correction,i){return _c('swiperSlide',{key:("correction-" + i)},[_c('v-card',{staticClass:"correction-card"},[_c('h1',{staticClass:"mb-1 before-title"},[_vm._v(" "+_vm._s(_vm.$t('trial.confirmation.title.내가 쓴 문장'))+" "),_c('img',{staticStyle:{"vertical-align":"text-bottom"},attrs:{"src":require("@/assets/images/trial-landings/ic-ios-writing.png"),"alt":""}})]),_c('p',{staticClass:"mb-6 correction-text",domProps:{"innerHTML":_vm._s(
							_vm.beforeDiff(
								correction.tutee_chat_log_content,
								correction.tutor_chat_log_content
							)
						)}}),_c('h1',{staticClass:"d-inline-block after-title"},[_vm._v(" "+_vm._s(_vm.$t('trial.confirmation.text.첨삭 받은 문장'))+" ")]),_c('p',[_c('span',{staticClass:"correction-text",domProps:{"innerHTML":_vm._s(
								_vm.afterDiff(
									correction.tutee_chat_log_content,
									correction.tutor_chat_log_content
								)
							)}}),_c('AudioPlayer',{staticClass:"d-inline-block ml-1",attrs:{"script":correction.origin,"color":'after',"text":true}})],1)])],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }