<template>
	<!-- <swiper class="swiper pl-3" :options="swiperOption">
		<swiper-slide
			v-for="topic in topics"
			:key="topic.code"
			class="slide font-15 text-center"
			:style="`width: ${topic.width}px`"
		>
			<div
				:class="{ active: selectedTopic === topic.code }"
				:data-test="topic.code"
				@click.prevent="selectTopic(topic.code)"
			>
				{{ topic.title }}
			</div>
		</swiper-slide>
	</swiper> -->
	<div class="pl-3 d-flex">
		<div
			v-for="topic in topics"
			:key="topic.title"
			class="mr-4 font-15 text-center cursor-pointer"
			style="padding-bottom: 2px"
			:style="`width: ${topic.width}px`"
			:class="{ active: selectedTopic === topic.title }"
			:data-test="topic.title"
			@click.prevent="selectTopic(topic.title)"
		>
			{{ topic.title }}
		</div>
	</div>
</template>

<script>
// import 'swiper/dist/css/swiper.css'
// import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
	// components: { swiper, swiperSlide },
	data() {
		return {
			selectedTopic: 'All',
			topics: [
				{ title: 'All', width: 30 },
				{ title: '비즈니스', width: 60 },
				{ title: '일상생활', width: 60 },
				{ title: '시험대비', width: 60 },
				{ title: '시사토픽', width: 60 },
			],
			swiperOption: {
				slidesPerView: 'auto',
				freeMode: true,
				spaceBetween: 20,
			},
		}
	},
	methods: {
		selectTopic(topic) {
			this.selectedTopic = topic
			this.$emit('selectTopic', topic)
		},
	},
}
</script>

<style lang="scss" scoped>
.active {
	border-bottom: 3px solid #0074ff;
}
</style>
