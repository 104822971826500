<template>
	<div
		class="grey--text text-body-2 text--darken-2"
		style="line-height: 1.75rem"
	>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.preface') }}
		</p>

		<p class="mb-3">
			{{ $t('privacy.1.title') }}
		</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.1.text-1') }}
		</p>

		<p class="mb-3">
			{{ $t('privacy.2.title') }}
		</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.2.text') }}
		</p>

		<p class="mb-3">
			{{ $t('privacy.3.title') }}
		</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.3.text') }}
		</p>

		<p class="mb-3">
			{{ $t('privacy.4.title') }}
		</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.4.text') }}
		</p>

		<p class="mb-3">
			{{ $t('privacy.5.title') }}
		</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.5.text') }}
		</p>

		<p class="mb-3">
			{{ $t('privacy.6.title') }}
		</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.6.text') }}
		</p>

		<p class="mb-3">
			{{ $t('privacy.7.title') }}
		</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.7.text') }}
		</p>

		<p class="mb-3">
			{{ $t('privacy.8.title') }}
		</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.8.text') }}
		</p>

		<p class="mb-3">
			{{ $t('privacy.9.title') }}
		</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.9.text') }}
		</p>

		<p class="mb-3">
			{{ $t('privacy.10.title') }}
		</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.10.text') }}
		</p>

		<p class="mb-3">
			{{ $t('privacy.11.title') }}
		</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.11.text') }}
		</p>

		<p class="mb-3">{{ $t('privacy.12.title') }}</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.12.text') }}
		</p>

		<p class="mb-3">{{ $t('privacy.13.title') }}</p>
		<p class="mb-7 white-space-pre">
			{{ $t('privacy.13.text') }}
		</p>

		<p>
			{{ $t('privacy.14.title') }}
		</p>
	</div>
</template>

<script>
export default {}
</script>
