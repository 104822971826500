<template>
	<div id="quest-group-selector">
		<v-select
			v-model="selectedGroup"
			:disabled="isLoading"
			:items="groups"
			single-line
			outlined
			rounded
			return-object
			hide-details
			:item-text="groupText"
		>
			<template v-slot:item="{ item }">
				<p class="mb-0">{{ groupText(item) }}</p>
			</template>
			<template v-slot:selection>
				<p style="color: #5f6f9d; font-size: 11px" class="mb-0">
					{{ groupText(selectedGroup) }}
				</p>
			</template>
			<template v-slot:append>
				<v-icon small>mdi-chevron-down</v-icon>
			</template>
		</v-select>
	</div>
</template>

<script>
import moment from 'moment-timezone'

export default {
	props: {
		isLoading: {
			required: true,
			type: Boolean,
		},
		groups: {
			required: true,
			type: Array,
		},
	},
	data: () => ({
		selectedGroup: null,
	}),
	watch: {
		groups() {
			this.selectedGroup = this.groups[0]
		},
		selectedGroup(val) {
			this.$emit('selectGroup', val)
		},
	},
	methods: {
		groupText: item => moment(item.start_date).format('MMM YYYY'),
	},
}
</script>

<style>
#quest-group-selector-group-selector
	.v-text-field--rounded
	> .v-input__control
	> .v-input__slot {
	padding: 0 13px 0 15px !important;
}
#quest-group-selector .v-text-field--enclosed .v-input__append-inner {
	margin-top: 6px;
}
#quest-group-selector
	.v-text-field--outlined
	> .v-input__control
	> .v-input__slot {
	min-height: 10px;
}

#quest-group-selector
	.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
	> .v-input__control
	> .v-input__slot
	fieldset {
	color: #b5b8d2;
}
#quest-group-selector .v-text-field--outlined.v-input--is-focused fieldset,
.v-text-field--outlined.v-input--has-state fieldset {
	border: 1px solid #b5b8d2;
}

#quest-group-selector
	.v-text-field--rounded
	> .v-input__control
	> .v-input__slot {
	padding: 0 10px 0 15px;
}
</style>
