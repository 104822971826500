<template>
	<!--
			order 순으로 정렬되어 있음.
			이 order중에 achivement가 있으면 패스
			남은 것 보여줌
			다 달성했으면 다 달성했다고 보여줌
		-->
	<v-card class="px-5 py-8 elevation-card" rounded="xl" height="178">
		<div v-if="isLoading === true"></div>
		<div v-else>
			<div v-if="quest !== null">
				<div class="d-flex align-center mb-3">
					🎯&nbsp;
					<div style="color: #4e5771; font-size: 14px">
						{{
							$t('수업을 %{goal}회 달성하세요.', {
								goal: quest.goal,
							})
						}}
					</div>
					<v-spacer></v-spacer>
					<div class="text-caption font-weight-medium">
						<span
							:class="{
								inactive: lessonCount === 0,
								active: lessonCount / quest.goal < 1,
							}"
							>{{ lessonCount }}</span
						><span
							:class="{
								inactive: lessonCount / quest.goal < 1,
								active: lessonCount / quest.goal >= 1,
							}"
							>/{{ quest.goal }}
						</span>
					</div>
				</div>
				<div style="position: relative" class="quest-progress-wrap">
					<div
						class="quest-progress"
						:style="`width:${(100 * lessonCount) / quest.goal}%`"
					></div>
				</div>
				<div class="text-center mt-7">
					<AchieveButton
						@achieve="getQuest"
						:quest="quest"
						:lessonCount="lessonCount"
					/>
				</div>
			</div>
			<div v-else class="text-body-2 font-italic">
				{{ $t('해당 월에 속하는 퀘스트가 없습니다.') }}
			</div>
		</div>
	</v-card>
</template>

<script>
import Api from '@/services/index'
import moment from 'moment-timezone'
import AchieveButton from './AchieveButton'
const R = require('ramda')

export default {
	components: {
		AchieveButton,
	},
	props: {
		startDate: {
			required: true,
		},
		endDate: {
			required: true,
		},
		groupId: {
			required: true,
		},
	},
	data() {
		return {
			isLoading: false,
			listOfQuest: [],
			acheivements: [],
			lessons: [],
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		quest() {
			const notAcheiveQuests = R.pipe(
				R.filter(quest => !quest.acheivements),
				R.sortWith([R.ascend(R.prop('order'))]),
			)(this.listOfQuest)
			return notAcheiveQuests.length > 0
				? notAcheiveQuests[0] // 달성하지 못한 목표가 있는 경우
				: this.listOfQuest[0] // 달성하지 못한 목표가 없는 경우 가장 마지막 quest를 보여준다.
				? this.listOfQuest[0]
				: null
		},
		lessonCount() {
			return this.lessons.length
		},
	},
	watch: {
		groupId() {
			this.getQuest()
		},
	},
	methods: {
		async getQuest() {
			this.isLoading = true
			const questRes = await Api.get(
				`quests?sort-by=-order&status=1&quest-group-id=${this.groupId}`,
			)
			this.listOfQuest = questRes.data

			const questIds = this.listOfQuest.map(quest => quest.id).join()

			const lteLessonDatetime = moment
				.tz(this.endDate, 'Asia/Seoul')
				.add(1, 'days')
				.toISOString()
			const gteLessonDatetime = moment
				.tz(this.startDate, 'Asia/Seoul')
				.toISOString()

			const res = await Promise.all([
				Api.get(
					`quests/achievement?status=1&quest-ids=${questIds}&member-id=${this.memberId}`,
				),
				Api.get(
					`lessons?limit=99&gte-lesson-datetime=${gteLessonDatetime}&lte-lesson-datetime=${lteLessonDatetime}&member-id=${this.memberId}&status=analyzed`,
				),
			])
			this.acheivements = res[0].data
			this.lessons = res[1].data
			this.mappingAcheive()
			this.isLoading = false
		},
		mappingAcheive() {
			this.listOfQuest.forEach(q => {
				q.acheivements = R.find(R.propEq('quest_id', q.id))(this.acheivements)
			})
		},
	},
}
</script>

<style scoped>
.header {
	font-size: 22px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: left;
	color: #4e5771;
}
.quest-progress-wrap {
	overflow: hidden;
	padding: 2px;
	border: 1px solid #e9e9e9;
	border-radius: 30px !important;
	width: 100%;
	height: 14px;
}
.quest-progress {
	height: 8px;
	border-radius: 30px;
	background-image: linear-gradient(to right, #5675cb, #af4fe5 148%);
}
.quest-icon {
	width: 18px;
	height: 18px;
}
.inactive {
	color: #b5b8d2;
}
.active {
	color: #5675cb;
}
</style>
