<template>
	<div>
		<router-view v-if="isLoading === false"></router-view>
	</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('book')

export default {
	data: () => ({
		isLoading: false,
	}),
	computed: {
		...mapGetters(['isBookable']),
		typeEn() {
			return this.$route.params.typeEn
		},
	},
	created() {
		this.setReady()
	},
	destroyed() {
		this.SET_INITIAL()
	},
	methods: {
		...mapMutations(['SELECT_TYPE', 'SET_INITIAL']),
		async setReady() {
			try {
				this.isLoading = true
				this.SET_INITIAL()

				if (this.typeEn !== 'talk' && this.typeEn !== 'call') {
					this.accessDenied('type')
					return
				}

				this.SELECT_TYPE(this.typeEn === 'talk' ? 0 : 1)

				await Promise.all([
					this.$store.dispatch('appointed/get', false),
					this.$store.dispatch('point/get'),
				])

				if (this.isBookable !== true) {
					this.accessDenied(this.isBookable)
					return
				}

				this.isLoading = false
			} catch (err) {
				this.accessDenied()
			}
		},
		accessDenied(reason) {
			let msg = '에러가 발생했습니다.'
			switch (reason) {
				case 'lack of points':
					msg = this.$t(`현재 사용가능한 %{passName}수강권이 없습니다.`, {
						passName: this.typeEn === 0 ? '25분톡' : '15분콜',
					})
					break
				case 'lack of lesson slots':
					msg = this.$t(
						'예약 가능한 수업을 모두 예약 하셨습니다! 수업을 완료한 뒤에 다시 예약하세요!\n텔라의 수업은 톡과 콜 각각 10회까지 동시에 예약이 가능합니다.',
					)
					break
				case 'type':
					msg = this.$t('수업 예약을 위해서는 타입을 선택하셔야합니다.')
					break
			}

			alert(msg)
			this.$router.push({ name: 'Home' })
		},
	},
}
</script>

<style lang="scss" scoped></style>
