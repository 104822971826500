<template>
	<div v-if="isLoading === false">
		<section class="d-print-none">
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-window-close</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('출석증') }}</h1>
				</v-col>
				<v-col cols="2">
					<Setting
						:points="points"
						:query="query"
						@issueCertificate="issueCertificate"
					/>
				</v-col>
			</v-row>
		</section>
		<section
			class="pa-5"
			style="overflow-y: auto; height: calc(var(--vh, 1vh) * 100 - 120px)"
		>
			<div class="my-5 d-print-block d-none">
				<div class="text-right">
					<img
						src="@/assets/images/logo/default.png"
						style="width: 80px"
						:alt="$t('텔라 홈페이지')"
					/>
				</div>
				<h1 class="text-h4 font-weight-bold">{{ $t('수강증') }}</h1>
			</div>
			<Table
				v-if="certificateLanguage === 'kr'"
				:points="points"
				:lessons="lessons"
				:passName="passName"
				:selectedPointId="selectedPointId"
				:includeTerm="includeTerm"
				:startDate="startDate"
				:endDate="endDate"
			/>
			<TableEn
				v-else-if="certificateLanguage === 'en'"
				:points="points"
				:lessons="lessons"
				:passName="passName"
				:selectedPointId="selectedPointId"
				:includeTerm="includeTerm"
				:startDate="startDate"
				:endDate="endDate"
			/>
		</section>
		<section class="d-print-none fixed-button-wrap">
			<v-btn
				v-if="userDevice === 'mobile'"
				@click="share"
				tile
				height="56"
				block
				color="primary"
				>{{ $t('공유하기') }}</v-btn
			>
			<v-btn v-else @click="print" tile height="56" block color="primary">{{
				$t('인쇄하기')
			}}</v-btn>
		</section>
	</div>
</template>

<script>
import Api from '@/services/index'
import Table from './Table'
import TableEn from './TableEn'
import Setting from './Setting'
const R = require('ramda')

export default {
	components: {
		Table,
		TableEn,
		Setting,
	},
	data: () => ({
		isLoading: false,
		points: [],
		lessons: [],
		selectedPointId: 0,
		includeTerm: true,
		startDate: null,
		endDate: null,
		passName: null,
		certificateLanguage: 'kr',
	}),
	computed: {
		query() {
			return this.$route.query
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		paymentId() {
			return this.$route.query['payment-id']
		},
		groupPointTypeId() {
			return this.$route.query['group-point-type-id']
		},
		passCouponMemberId() {
			return this.$route.query['pass-coupon-member-id']
		},
		pointId() {
			return this.$route.query['point-id']
		},
		pointIds() {
			return R.pipe(
				R.map(p => p.id),
				R.uniq(),
			)(this.points)
		},
	},
	created() {
		if (!this.paymentId && !this.passCouponMemberId && !this.groupPointTypeId) {
			alert(
				this.$t(
					'결제 혹은 수강권 코드 쿠폰으로 등록된 수강권만 수강증을 발급할 수 있습니다. 결제 번호 혹은 수강권 코드 쿠폰 번호를 확인해주세요.',
				),
			)
			this.goBack()
		} else {
			this.get()
		}
	},
	methods: {
		wonCommaFormat: won => won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			let query = {}
			if (this.paymentId) query['payment-id'] = this.paymentId
			else if (this.groupPointTypeId)
				query['group-point-type-id'] = this.groupPointTypeId
			else if (this.passCouponMemberId)
				query['pass-coupon-member-id'] = this.passCouponMemberId
			else query['point-id'] = this.points[0].id
			beforeName !== 'PassDetail'
				? this.$router.push({ name: 'PassDetail', query })
				: this.$router.go(-1)
		},
		/** */
		async get() {
			this.isLoading = true
			await this.getPoints()
			await this.getLessons()
			this.isLoading = false
		},

		async getPoints() {
			let query = `?member-id=${this.memberId}&active=1`
			if (this.paymentId) query += `&payment-id=${this.paymentId}`
			else if (this.groupPointTypeId)
				query += `&group-point-type-id=${this.groupPointTypeId}`
			else if (this.passCouponMemberId)
				query += `&pass-coupon-member-id=${this.passCouponMemberId}`
			if (this.pointId) query += `&id=${this.pointId}`
			const res = await Api.get(`points${query}`)
			this.points = res.data
		},
		async getLessons() {
			let query = `lessons?member-id=${
				this.memberId
			}&status-in=analyzed&point-id-in=${this.pointIds.toString()}`

			const res = await Api.get(query)
			this.lessons = res.data
		},
		issueCertificate({
			selectedPointId,
			startDate,
			endDate,
			passName,
			includeTerm,
			certificateLanguage,
		}) {
			const query = {}
			if (this.paymentId) query['payment-id'] = this.paymentId
			else if (this.groupPointTypeId)
				query['group-point-type-id'] = this.groupPointTypeId
			else if (this.passCouponMemberId)
				query['pass-coupon-member-id'] = this.passCouponMemberId
			else query['point-id'] = this.points[0].id

			if (selectedPointId !== undefined)
				query['selected-point-id'] = selectedPointId
			if (startDate) query['start-date'] = startDate
			if (endDate !== undefined) query['end-date'] = endDate
			if (includeTerm !== undefined) query['include-term'] = includeTerm
			if (certificateLanguage)
				query['certificate-language'] = certificateLanguage

			this.$router
				.replace({
					query,
				})
				.catch(() => {})

			this.selectedPointId = Number(selectedPointId)
			this.passName = passName
			this.startDate = startDate
			this.endDate = endDate
			this.includeTerm = includeTerm
			this.certificateLanguage = certificateLanguage
		},
		share() {
			const url = window.document.location.href
			window.navigator.share({
				title: this.$t(`[텔라] %{passName} 출석증`, {
					passName: this.passName,
				}),
				text: this.$t(`인쇄가 가능한 PC 환경에서 아래의 링크를 클릭하세요.\n`),
				url: url,
			})
		},

		print() {
			window.print()
		},
	},
}
</script>

<style lang="scss" scoped>
* {
	-webkit-print-color-adjust: exact !important; /* Chrome, Safari */
	color-adjust: exact !important; /*Firefox*/
}
@page {
	margin: 0 10mm;
}
</style>
