<template>
	<v-container :style="`height: ${height}px; width: 100%`">
		<v-row class="fill-height" align="center" justify="center">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</v-row>
	</v-container>
</template>

<script>
export default {
	props: {
		height: {
			type: Number,
			default: 400,
		},
	},
}
</script>

<style lang="scss" scoped></style>
