var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-card corrected-card pa-5",attrs:{"rounded":"lg"}},[_c('h1',{staticClass:"\n\t\t\trounded-0\n\t\t\tmb-1\n\t\t\td-inline-block\n\t\t\tpl-1\n\t\t\tpr-2\n\t\t\ttext-body-2\n\t\t\tfont-weight-bold\n\t\t\tbefore-highlight before\n\t\t"},[_vm._v(" ✏️  "+_vm._s(_vm.$t('내가 쓴 문장'))+" ")]),_c('p',{staticClass:"mb-4 text-body-2"},[_c('span',{domProps:{"innerHTML":_vm._s(
				_vm.beforeDiff(
					_vm.correction.tutee_chat_log_content,
					_vm.correction.tutor_chat_log_content
				)
			)}})]),_c('h1',{staticClass:"\n\t\t\trounded-0\n\t\t\tmb-1\n\t\t\td-inline-block\n\t\t\tpl-1\n\t\t\tpr-2\n\t\t\ttext-body-2\n\t\t\tfont-weight-bold\n\t\t\tafter-highlight after\n\t\t"},[_vm._v(" 🙌  "+_vm._s(_vm.$t('첨삭 받은 문장'))+" ")]),_c('p',{staticClass:"mb-4 text-body-2"},[_c('span',{domProps:{"innerHTML":_vm._s(
				_vm.afterDiff(
					_vm.correction.tutee_chat_log_content,
					_vm.correction.tutor_chat_log_content
				)
			)}}),_c('AudioPlayer',{staticClass:"d-inline-block ml-1",attrs:{"script":_vm.correction.tutor_chat_log_content,"color":'primary--text',"text":true}})],1),_c('div',{staticClass:"mt-6"},[_c('p',{staticClass:"text-h6 font-weight-light grey--text text-right"},[_vm._v(" "+_vm._s(_vm.i + 1)+"/"+_vm._s(_vm.totalLength)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }