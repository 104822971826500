import { render, staticRenderFns } from "./Rating.vue?vue&type=template&id=23e46345&scoped=true&"
import script from "./Rating.vue?vue&type=script&lang=js&"
export * from "./Rating.vue?vue&type=script&lang=js&"
import style0 from "./Rating.vue?vue&type=style&index=0&id=23e46345&lang=scss&scoped=true&"
import style1 from "./Rating.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23e46345",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRating } from 'vuetify/lib/components/VRating';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VDialog,VDivider,VRating,VSpacer,VTextarea})
