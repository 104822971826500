import { momentToCode } from '@/helpers/date-format'
import i18n from '@/i18n'

export default {
	isLoading: state => state.isLoading,

	selectedType: state => state.selectedType,
	selectedTypeEn: state => (state.selectedType === 0 ? 'talk' : 'call'),
	selectedTypeKo: state =>
		state.selectedType === 0 ? i18n.t('25분톡') : i18n.t('15분콜'),
	selectedMoment: (state, getters, rootState, rootGetters) =>
		state.selectedMoment
			? state.selectedMoment.locale(rootGetters['member/language'])
			: null,
	selectedTime: (state, getters) =>
		state.selectedDatetime
			? momentToCode(getters.selectedMoment, getters.selectedType)
			: null,
	selectedTutor: state => state.selectedTutor,
	isBookable: (state, getters, rootState, rootGetters) => {
		if (getters.selectedType === 0) {
			if (rootGetters['point/availableTalk'].remaining === 0)
				return 'lack of points'
			else if (rootGetters['appointed/talkLessons'].length >= 10)
				return 'lack of lesson slots'
			else return true
		} else if (getters.selectedType === 1) {
			if (rootGetters['point/availableCall'].remaining === 0)
				return 'lack of points'
			else if (rootGetters['appointed/callLessons'].length >= 10)
				return 'lack of lesson slots'
			else return true
		} else return false
	},
}
