<template>
	<div>
		<v-form ref="form" @submit.prevent="search">
			<section class="py-2 d-flex align-center">
				<v-btn fab icon text small @click="goBack()">
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>
				<v-text-field
					dense
					hide-details
					single-line
					solo
					flat
					:placeholder="$t('프로그램 이름을 검색하세요.')"
					class="mx-1 rounded-lg"
					background-color="grey lighten-3"
					clearable
					v-model="keyword"
				></v-text-field>
				<v-btn fab icon text small @click="search()">
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
			</section>
		</v-form>
		<section
			v-if="filteredProgram && filteredProgram.length > 0"
			class="px-5 py-3"
			style="overflow-y: auto; height: calc(100vh - 60px)"
		>
			<ProgramCard
				v-for="program in filteredProgram"
				:key="program.id"
				:program="program"
				@selectProgram="selectProgram"
				class="mb-3"
			/>
		</section>
		<section
			v-else-if="filteredProgram && filteredProgram.length === 0"
			class="px-5 py-3 text-center"
		>
			👀
			<h1 class="text-body-1 grey--text text--darken-2">
				{{ $t('검색 결과가 없습니다.') }}
			</h1>

			<p class="text-body-2 grey--text text--darken-2">
				{{ $t('제안하고 싶은 프로그램이 있다면 1:1 문의를 이용해주세요.') }}
			</p>
		</section>
	</div>
</template>

<script>
import ProgramCard from '../common/Card'
const R = require('ramda')

export default {
	components: {
		ProgramCard,
	},
	data: () => ({
		keyword: null,
		filteredProgram: null,
	}),
	computed: {
		programsFilteredType() {
			return this.$store.getters['program/programsFilteredType']
		},
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			beforeName !== 'Programs'
				? this.$router.push({ name: 'Programs' })
				: this.$router.go(-1)
		},
		selectProgram(programObj) {
			this.$router.push({
				name: 'ProgramUnit',
				params: { programId: programObj.id },
			})
		},
		search() {
			if (!this.keyword || this.keyword.length === 0) {
				alert(this.$t('검색하실 프로그램 이름을 입력해주세요.'))
				return
			}

			this.filteredProgram = R.filter(
				x => this.keyword.length > 0 && x.name.includes(this.keyword),
			)(this.programsFilteredType)
		},
	},
}
</script>

<style lang="scss" scoped></style>
