<template>
	<div>
		<v-btn :disabled="isAllLoading" color="kakao" depressed fab @click="signin">
			<img
				src="@/assets/images/logo/kakao_blank.png"
				alt=""
				style="width: 25px"
			/>
		</v-btn>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="errDialogPersistent"
			:numberOfButton="errNumberOfButton"
			:title="errTitle"
			:text="errMsg"
			:actionColor="errActionColor"
			:action="errAction"
			:actionText="errActionText"
		/>
	</div>
</template>

<script>
import kakao from '@/services/kakao'
import Api from '@/services/index'
import Dialog from '@/components/common/Dialog'
import SlackMsg from '@/services/slack'

export default {
	components: {
		Dialog,
	},
	props: {
		isAllLoading: {
			required: true,
			type: Boolean,
		},
	},
	data() {
		return {
			kakaoToken: null,
			authKakaoId: null,
			// === 에러
			errorDialog: false,
			errTitle: '',
			errMsg: '',
			errNumberOfButton: 1,
			errDialogPersistent: false,
			errActionColor: 'primary',
			errAction: this.closeErrDialog,
			errActionText: this.$t('common.modal.button.confirm'),
		}
	},
	methods: {
		gtagEvent() {
			const params = {
				event_category: 'kakao',
				event_path: `my.tella.co.kr${this.$route.path}`,
			}
			// eslint-disable-next-line no-undef
			gtag('event', 'click_login_btn', params)
		},
		async kakaoLogin() {
			try {
				this.gtagEvent()
				const loginRes = await kakao.login()
				this.kakaoToken = loginRes.access_token
				this.$emit('loading', true)
				const getMeRes = await kakao.getMe()
				this.authKakaoId = getMeRes.id
			} catch (err) {
				SlackMsg.post('', {
					text: `NAME: ${err.name}, Message: ${
						err.message
					}, STRING: ${JSON.stringify(err).substring(0, 1000)},`,
				})
				throw new Error('kakaoError')
			}
		},
		async signin() {
			try {
				await this.kakaoLogin()
				const params = {
					authKakaoId: this.authKakaoId,
					kakaoToken: this.kakaoToken,
				}
				await Api.post('/auth', params)
				this.$emit('tokenIssued')
			} catch (err) {
				const errorCode =
					err.message === 'kakaoError'
						? 'kakaoError'
						: err.response && err.response.data
						? err.response.data.code
						: 'FKE65B'

				this.errorHandler(errorCode)
			} finally {
				this.$emit('loading', false)
			}
		},
		errorHandler(errorCode) {
			switch (errorCode) {
				case 'kakaoError':
					this.errTitle = this.$t('login.error.error-from-kakao-auth-server')
					break
				case '10008':
					this.errTitle = this.$t('login.error.deleted-error')
					break
				case '10001':
					this.errTitle = this.$t('login.error.kakao-token-and-id-wrong')
					break
				default:
					this.errTitle = this.$t('login.error.default-error-message', {
						errorCode,
					})
			}
			this.errorDialog = true
		},
		closeErrDialog() {
			this.errorDialog = !this.errorDialog
		},
	},
}
</script>

<style lang="scss" scoped></style>
