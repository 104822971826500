<template>
	<Wrap />
</template>

<script>
import Wrap from '@/components/SettingStyleCall'
export default {
	components: { Wrap },
}
</script>

<style lang="scss" scoped></style>
