<template>
	<div class="background pt-12 pb-15">
		<v-toolbar
			style="
				width: 100%;
				max-width: 400px;
				position: fixed;
				top: 0px;
				z-index: 3;
			"
			elevation="0"
			height="60"
			class="px-1"
		>
			<div class="mr-4 font-16 font-weight-medium hover-pointer">
				{{ $t('page.reviewList.toolbar.menu.예약') }}
			</div>
			<div
				class="font-16 font-weight-medium grey--text hover-pointer"
				data-test="reviewMenu"
				@click="$router.push({ name: 'ReviewLessonsAll' })"
			>
				{{ $t('page.reviewList.toolbar.menu.복습') }}
			</div>
			<v-spacer></v-spacer>
			<CalendarButton />
		</v-toolbar>
		<div class="pt-3 px-4">
			<div v-if="isLoading === false && lessons.length > 0">
				<h1 class="font-14 font-weight-regular mb-8">
					톡
					<span
						class="primary--text font-weight-medium"
						data-test="talkCount"
						>{{ talkLessons.length }}</span
					>개<span class="px-1 grey--text">|</span>콜
					<span
						class="emerald--text font-weight-medium"
						data-test="callCount"
						>{{ callLessons.length }}</span
					>개
				</h1>
				<div v-for="lesson in lessons" :key="lesson.id" data-test="lessonCard">
					<AppointedLessonCard
						:lesson="lesson"
						class="mb-8"
						ref="appointedLessonCard"
					/>
				</div>
			</div>
			<div v-if="isLoading === true" style="margin-top: 61px">
				<LoadingLessonCard class="mb-8" v-for="i in 2" :key="i" />
			</div>
		</div>
		<Empty
			:category="'appointed'"
			v-if="isLoading === false && lessons.length === 0"
		/>
		<!-- <CancelledSnackBar /> -->
	</div>
</template>

<script>
import { DateTime } from 'luxon'
import scheduleApi from '@/services/schedule'
import EventBus from '@/lib/eventBus'
import AppointedLessonCard from '@/components/lesson-card/appointed/Wrap.vue'
import LoadingLessonCard from '@/components/lesson-card/Loading.vue'
// import CancelledSnackBar from '@/components/common/CancelledSnackBar.vue'
import Empty from './Empty'
import CalendarButton from './CalendarButton'

export default {
	components: {
		AppointedLessonCard,
		LoadingLessonCard,
		Empty,
		CalendarButton,
		// CancelledSnackBar,
	},
	data: () => ({
		isLoading: false,
		lessons: [],
	}),
	computed: {
		talkLessons() {
			return this.lessons.filter(
				l => l.status === 'appointed' && l.lessonType === 0,
			)
		},
		callLessons() {
			return this.lessons.filter(
				l => l.status === 'appointed' && l.lessonType === 1,
			)
		},
	},
	created() {
		EventBus.$on('lessonIsCancelled', this.getLessons)
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-3', {
			root: true,
		})
		this.getLessons()
	},
	beforeDestroy() {
		EventBus.$off('lessonIsCancelled', this.getLessons)
		this.$store.commit('device/SET_BACKGROUND', 'white', {
			root: true,
		})
	},
	methods: {
		async getLessons() {
			this.isLoading = true
			const dt = DateTime.now().toISO()
			const responseLessons = await scheduleApi.getLessons({
				gteLessonDatetime: dt,
			})
			this.lessons = responseLessons
				.sort((a, b) => a.dt - b.dt)
				.filter(l => l.status === 'appointed')
			this.isLoading = false
		},
	},
}
</script>

<style lang="scss" scoped>
.background {
	background-color: #f2f4f6;
	min-height: calc(var(--vh, 1vh) * 100);
	overflow-y: auto;
}
// /* iOS only */
// @supports (-webkit-touch-callout: none) {
// 	.background {
// 		height: -webkit-fill-available;
// 	}
// }
.no-lesson-wrap {
	padding-top: 100px;
}
</style>
