<template>
	<div class="pt-sm-16 mt-sm-6 pb-sm-16 mb-sm-5 pt-15 pb-12">
		<h1 class="mb-sm-5 mb-4 text--h1 text-center font-weight-bold">
			{{ $t('trial.confirmation.title.레벨 진단 리포트 예시') }}
		</h1>
		<p class="text--body-1 text-center mb-sm-12 mb-9">
			{{ $t('trial.confirmation.text.레벨 진단 리포트 예시') }}
		</p>
		<v-row
			no-gutters
			style="width: 100%; max-width: 710px"
			class="mx-auto d-none d-sm-flex"
		>
			<v-col cols="6" class="pr-sm-3">
				<v-img
					class="rounded-lg img-card"
					width="340"
					:src="
						require(`@/assets/images/trial-landings/pc-card-05-${language}.png`)
					"
				>
				</v-img>
			</v-col>
			<v-col cols="6" class="pl-sm-3">
				<v-img
					class="rounded-lg img-card"
					width="340"
					:src="
						require(`@/assets/images/trial-landings/pc-card-06-${language}.png`)
					"
				>
				</v-img>
			</v-col>
		</v-row>
		<div class="d-block d-sm-none">
			<v-img
				class="rounded-lg img-card mb-5 mx-auto"
				width="300"
				:src="
					require(`@/assets/images/trial-landings/mo-card-05-${language}.png`)
				"
			>
			</v-img>
			<v-img
				class="rounded-lg img-card mx-auto"
				width="300"
				:src="
					require(`@/assets/images/trial-landings/mo-card-06-${language}.png`)
				"
			>
			</v-img>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
	},
}
</script>

<style lang="scss" scoped>
.img-card {
	box-shadow: 0 10px 30px 0 rgba(33, 51, 82, 0.1);
}
</style>
