var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"price-box"}},[_c('div',{staticClass:"py-4 d-flex align-center"},[_c('h1',{staticClass:"text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('order.price-box.title'))+" ")]),_c('v-spacer'),(_vm.currency !== 'USD')?_c('div',{staticClass:"d-flex align-center",on:{"click":function($event){return _vm.$emit('selectCurrency', 'USD')}}},[_c('span',{staticClass:"primary--text text-body-2",staticStyle:{"border-bottom":"solid 1px #0074ff","line-height":"1.2em !important"}},[_vm._v(" "+_vm._s(_vm.$t('order.price-box.button.change-currency')))]),_c('img',{staticClass:"ml-1",attrs:{"src":require("@/assets/icons/exchange-circle-primary.png"),"alt":_vm.$t('order.price-box.img-alt.change-currency')}})]):_vm._e(),(_vm.currency === 'USD')?_c('div',{staticClass:"d-flex align-center",on:{"click":function($event){_vm.language === 'ja'
					? _vm.$emit('selectCurrency', 'JPY')
					: _vm.$emit('selectCurrency', 'KRW')}}},[_c('span',{staticClass:"primary--text text-body-2",staticStyle:{"border-bottom":"solid 1px #0074ff","line-height":"1.2em !important"}},[_vm._v(" "+_vm._s(_vm.$t('order.price-box.button.change-to-krw')))]),_c('img',{staticClass:"ml-1",attrs:{"src":require("@/assets/icons/exchange-circle-primary.png"),"alt":_vm.$t('order.price-box.img-alt.change-to-krw')}})]):_vm._e()],1),_c('div',{staticClass:"py-2 d-flex align-center"},[_c('h1',{staticClass:"text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('order.price-box.title.origin-price'))+" ")]),_c('v-spacer'),_c('p',{staticClass:"text-body-1 font-weight-medium price"},[_vm._v(" "+_vm._s(_vm.$numberWithCommas(_vm.originPrice))+" "+_vm._s(_vm.currencyString)+" ")])],1),_c('div',{staticClass:"py-2 d-flex align-center"},[_c('h1',{staticClass:"text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('order.price-box.title.total-discount-amount'))+" ")]),_c('v-spacer'),_c('p',{staticClass:"text-body-1 font-weight-medium price"},[_vm._v(" - "+_vm._s(_vm.$numberWithCommas(_vm.totalDiscount))+" "+_vm._s(_vm.currencyString)+" ")])],1),_c('div',{staticClass:"py-3 d-flex align-center"},[_c('img',{staticClass:"mr-1",attrs:{"src":require("@/assets/icons/L.png"),"alt":""}}),_c('h1',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('order.price-box.title.basic-discount'))+" ")]),_c('v-spacer'),_c('p',{staticClass:"text-body-1 grey--text text--darken-1 price"},[_vm._v(" "+_vm._s(_vm.$numberWithCommas(_vm.basicDiscount))+" "+_vm._s(_vm.currencyString)+" ")])],1),_c('CouponPicker',{staticClass:"py-3",attrs:{"coupons":_vm.coupons,"realPrice":_vm.realPrice,"discountCouponAmount":_vm.discountCouponAmount,"currency":_vm.currency,"currencyString":_vm.currencyString,"planId":_vm.plan.id},on:{"selectCoupon":_vm.selectCoupon,"registeredCoupon":function($event){return _vm.$emit('registeredCoupon')}}}),(
			[
				109,
				110,
				111,
				112,
				113,
				114,
				'109',
				'110',
				'111',
				'112',
				'113',
				'114' ].includes(this.plan.id) === false
		)?_c('CashPicker',{staticClass:"py-3",attrs:{"price":_vm.priceBeforeBonusCash,"currency":_vm.currency,"currencyString":_vm.currencyString},on:{"selectCash":_vm.selectCash}}):_vm._e(),(_vm.nthPayment === 0)?_c('Recommend',{staticClass:"py-3",attrs:{"recommendDiscount":_vm.recommendDiscount,"currencyString":_vm.currencyString},on:{"selectRecommender":_vm.selectRecommender}}):_vm._e(),_c('v-divider',{staticClass:"mb-3 mt-5"}),_c('div',{staticClass:"pt-1 d-flex align-center"},[_c('h1',{staticClass:"text-body-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('order.price-box.title.pay-price'))+" ")]),_c('v-spacer'),_c('p',{staticClass:"text-h2 font-weight-bold price narrow-line-height"},[_vm._v(" "+_vm._s(_vm.$numberWithCommas(_vm.payPrice))+" "),_c('span',{staticClass:"text-body-1 font-weight-medium"},[_vm._v(_vm._s(_vm.currencyString))])])],1),_c('p',{staticClass:"text-right text-body-1 price grey--text text--darken-1 pb-3"},[_vm._v(" "+_vm._s(_vm.$t('order.price-box.text.monthly-price', { monthlyPrice: _vm.$numberWithCommas(_vm.monthlyPrice), currencyString: _vm.currencyString, month: _vm.plan.month, }))+" ")]),_c('div',{staticClass:"estimated-wrap text-right pb-7 d-flex align-center justify-end"},[_c('div',{staticClass:"estimated"},[_vm._v(" "+_vm._s(_vm.$t('order.price-box.title.estimated-cash'))+" ")]),_vm._v(" "+_vm._s(_vm.$t('order.price-box.text.estimated-cash', { cash: _vm.$numberWithCommas(_vm.estimatedBonusCash), }))+" "),_c('EsimatedCashTooltip')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }