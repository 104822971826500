import Vue from 'vue'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import numberFormat from './plugins/number-format'
import ConversionTracking from './plugins/conversion-tracking'
import EmptyLayout from './components/layout/Empty'
import SimpleLayout from './components/layout/Simple'
import DefaultLayout from './components/layout/Default'
import PrintLayout from './components/layout/Print'
import i18n from './i18n'
//쿠키를 사용한다.
Vue.use(VueCookies)
Vue.use(numberFormat)
Vue.use(ConversionTracking)

Vue.component('empty', EmptyLayout)
Vue.component('simple', SimpleLayout)
Vue.component('default', DefaultLayout)
Vue.component('print', PrintLayout)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	vuetify,
	i18n,
	render: h => h(App),
}).$mount('#app')
