<template>
	<!-- <FromToday /> -->
	<!-- <v-carousel
		v-model="model"
		:height="userDevice === 'desktop' ? 450 : 140"
		:hide-delimiters="true"
		:cycle="true"
		:continuous="true"
		:interval="5000"
		class="px-3 py-4"
		:show-arrows="false"
	>
		<v-carousel-item>
			<Main2 @clickMainRolling="clickMainRolling" />
		</v-carousel-item>
		<v-carousel-item>
			<Main1 @clickMainRolling="clickMainRolling" />
		</v-carousel-item>
	</v-carousel> -->
	<!-- <swiper
		ref="swiper"
		class="swiper"
		:options="swiperOption"
		@click.native="slideClicked()"
	>
		<swiperSlide v-if="isFromTodayEnd === false">
			<FromToday />
		</swiperSlide>
		<swiperSlide>
			<Print />
		</swiperSlide>
	</swiper> -->
	<Print />
</template>

<script>
// import 'swiper/dist/css/swiper.css'
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import FromToday from './banner/FromToday'
import Print from './banner/Print'
import { DateTime } from 'luxon'

export default {
	components: {
		// FromToday,
		Print,
		// swiper,
		// swiperSlide,
	},
	data: () => ({
		swiperOption: {
			spaceBetween: 15,
			loop: true,
			autoplay: {
				delay: 1500,
			},
		},
	}),
	computed: {
		userDevice() {
			return this.$store.getters.userDevice
		},
		isFromTodayEnd() {
			return DateTime.now().setZone('Asia/Seoul').toISODate() > '2023-01-04'
		},
	},
	methods: {
		slideClicked() {
			if (
				this.$refs.swiper.swiper.activeIndex === 1 ||
				this.$refs.swiper.swiper.activeIndex === 3
			)
				this.$router.push({ name: 'Event2022FromToday' })
		},
	},
}
</script>

<style lang="scss" scoped></style>
