import moment from 'moment-timezone'
import Api from '@/services/index'
// import { addMoment } from '@/helpers/date-format'
import { LOAD, STORE } from './mutations'

export default {
	getTimes: async ({ commit, getters, rootGetters }) => {
		commit(LOAD, true)
		commit(STORE, [])

		const query = slotQuery(getters, rootGetters)
		const res = await Api.get(`capacities/times${query}`)
		console.log(res)
		commit(STORE, res.data)
		commit(LOAD, false)
	},
}

function slotQuery(getters, rootGetters) {
	let startDatetime, endDatetime

	if (getters.selectedDate) {
		startDatetime =
			getters.startMoment >= moment().add(10, 'minutes')
				? getters.startMoment.toISOString()
				: moment().add(10, 'minutes').toISOString()
		endDatetime =
			getters.endMoment > moment().add(13 * 24, 'hours')
				? moment()
						.add(13 * 24, 'hours')
						.toISOString()
				: getters.endMoment.toISOString()
	} else {
		startDatetime = moment().add(10, 'minutes').toISOString()
		// endDatetime = moment().add(6, 'days').endOf('day').toISOString()
		endDatetime = moment()
			.add(13 * 24, 'hours')
			.toISOString()
	}

	let query = `?lesson-type=${rootGetters['book/selectedType']}&start-datetime=${startDatetime}&end-datetime=${endDatetime}`

	const selectedTutor = rootGetters['book/selectedTutor']
	if (selectedTutor !== null) query = query + `&tutor-id=${selectedTutor.id}`
	return query
}
