<template>
	<div style="position: relative">
		<v-fade-transition v-if="showTooltip">
			<div
				class="text-caption text-left tooltip white-space-pre"
				:style="`top: ${language === 'ko' ? '-72' : '-90'}px`"
			>
				{{ $t('order.esimated-cash-tooltip.text') }}

				<div class="chat-tail"></div>
			</div>
		</v-fade-transition>
		<div
			class="d-flex align-center"
			@mouseleave="showTooltip = false"
			@mouseover="showTooltip = true"
		>
			<img
				style="margin-bottom: -2px"
				class="ml-1"
				src="@/assets/icons/question-circle.png"
				:alt="$t('order.esimated-cash-tooltip.img-alt')"
			/>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showTooltip: false,
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
	},
	methods: {
		closeTooltip() {
			this.showTooltip = false
		},
	},
}
</script>

<style lang="scss" scoped>
.tooltip {
	z-index: 3;
	color: #fff;
	line-height: 1.3em;
	border-radius: 5px;
	position: absolute;
	right: -5px;
	width: 250px;
	padding: 10px 10px;
	object-fit: contain;
	background-color: #3c3b41;

	.chat-tail {
		width: 0;
		height: 0;
		position: absolute;
		bottom: -5px;
		right: 12px;
		margin-left: 2.5px;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;

		border-top: 5px solid #353535;
	}
}
</style>
