<template>
	<div
		@click="search()"
		class="grey px-5 pt-3 pb-2 lighten-1 grey--text text--darken-1 text-body-2"
		style="border-radius: 15px"
	>
		<v-icon style="margin-top: -5px">mdi-magnify</v-icon>
		{{ $t('프로그램 이름을 검색하세요.') }}
	</div>
</template>

<script>
export default {
	props: {
		lessonId: {
			required: true,
		},
	},
	methods: {
		search() {
			this.$router
				.push({
					name: 'LessonProgramSearch',
					params: { lessonId: this.lessonId },
				})
				.catch(() => {})
		},
	},
}
</script>

<style lang="scss" scoped></style>
