<template>
	<div style="position: relative">
		<v-fade-transition v-if="showTooltip">
			<div class="text-caption text-left tooltip white-space-pre">
				{{ $t('order.bonus-cash-tooltip.text') }}
				{{ currencyRateMsg }}

				<div class="chat-tail"></div>
			</div>
		</v-fade-transition>
		<div
			class="d-flex align-center"
			@mouseleave="showTooltip = false"
			@mouseover="showTooltip = true"
		>
			<img
				style="margin-bottom: -2px"
				class="ml-1"
				src="@/assets/icons/question-circle.png"
				:alt="$t('order.bonus-cash-tooltip.img-alt')"
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		currency: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			showTooltip: false,
		}
	},
	computed: {
		currencyRateMsg() {
			return this.currency === 'JPY'
				? this.$t('order.bonus-cash-tooltip.currency-rate-msg-jpy')
				: this.currency === 'USD'
				? this.$t('order.bonus-cash-tooltip.currency-rate-msg-usd')
				: this.$t('order.bonus-cash-tooltip.currency-rate-msg-krw')
		},
	},
	methods: {
		closeTooltip() {
			this.showTooltip = false
		},
	},
}
</script>

<style lang="scss" scoped>
.tooltip {
	z-index: 3;
	color: #fff;
	line-height: 1.3em;
	border-radius: 5px;
	position: absolute;
	top: -72px;
	left: -35px;
	width: 250px;
	padding: 10px 10px;
	object-fit: contain;
	background-color: #3c3b41;

	.chat-tail {
		width: 0;
		height: 0;
		position: absolute;
		bottom: -5px;
		left: 43px;
		margin-left: 2.5px;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;

		border-top: 5px solid #353535;
	}
}
</style>
