<template>
	<div>
		<v-row align="center" no-gutters class="pa-3">
			<v-col cols="2">
				<v-btn @click="goBack" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
			</v-col>
			<v-col cols="8">
				<h1 class="text-center text-body-1 font-weight-regular">
					{{ $t('영어 이름') }}
				</h1>
			</v-col>
		</v-row>
		<section class="pa-5">
			<h2 class="text-h6 mb-1 font-weight-bold">
				{{ $t('영어 이름을 알려주세요.') }}
			</h2>
			<p class="text-body-2 mb-8 grey--text text--darken-3">
				{{ $t('튜터와 수업할 때 사용됩니다.') }}
			</p>

			<v-form v-model="valid" @submit.prevent="changeProfile">
				<v-text-field
					v-model="nameEn"
					placeholder="English Name"
					filled
					:rules="nameEnRules"
					:disabled="isLoading"
					class="tella-form"
					single-line
				>
				</v-text-field>
				<v-btn
					class="mb-3"
					block
					x-large
					type="submit"
					@click.native="loader = 'loading'"
					color="grey darken-3 white--text"
					depressed
					:disabled="!valid || isLoading"
					>{{ $t('변경') }}</v-btn
				>
			</v-form>
		</section>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	data: function () {
		return {
			isLoading: false,
			prevRoute: {},
			valid: false,
			nameEn: null,
			nameEnRules: [
				v => !!v || this.$t('영어 이름을 입력해주세요.'),
				v =>
					(v && v.length <= 25) ||
					this.$t('영어이름은 25자 이하로 입력해주세요.'),
				v =>
					/^[0-9a-zA-Z\s]+$/.test(v) ||
					this.$t('영어, 숫자, 공백만 입력 가능합니다.'),
			],
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},
	created() {
		this.nameEn = this.$store.getters['member/nameEn']
	},
	methods: {
		async changeProfile() {
			try {
				this.isLoading = true
				await Api.patch(`members/${this.memberId}`, { nameEn: this.nameEn })
				alert(this.$t('회원 정보 변경이 완료되었습니다.'))
				this.goBack()
			} catch (err) {
				alert(this.$t('회원 정보 변경이 실패하였습니다. 다시 시도해주세요.'))
			} finally {
				this.isLoading = false
			}
		},
		goBack() {
			this.$router.push({ name: 'Setting' })
		},
	},
}
</script>

<style lang="scss" scoped></style>
