<template>
	<div></div>
</template>

<script>
import Api from '@/services/index'
// 성공 curl https://myservice.com/payments/complete?imp_uid=결제건을_특정하는_아임포트_번호&merchant_uid=가맹점_고유_주문번호&imp_success=true
// 실패  https://myservice.com/payments/complete?imp_uid=결제건을_특정하는_아임포트_번호&merchant_uid=가맹점_고유_주문번호&imp_success=false&error_code=에러_코드(현재_정리된_체계는_없음)&error_msg=에러_메시지

export default {
	data() {
		return {
			plan: null,
			payment: null,
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		planId() {
			return this.$route.query['plan-id']
		},
		paymentId() {
			return this.$route.query['payment-id']
		},
		impSuccess() {
			return this.$route.query['imp_success']
		},
	},
	created() {
		this.checkPayment()
	},
	methods: {
		async checkPayment() {
			if (this.impSuccess === 'true' || this.impSuccess === true) {
				try {
					const result = await Promise.all([
						Api.get(`plans/${this.planId}`),
						Api.get(
							`/payments?id=${this.paymentId}&member-id=${this.memberId}`,
						),
					])
					this.plan = result[0].data
					this.payment = result[1].data[0]
					this.trackingPurchase()

					this.$router.push({
						name: 'OrderLanding',
						params: { paymentId: this.paymentId },
					})
					return
				} catch (err) {
					alert('결제가 실패하였습니다.')
					this.$router.push({
						name: 'Order',
						query: { 'plan-id': this.planId },
					})
					return
				}
			}
			alert('결제가 실패하였습니다.')
			this.$router.push({
				name: 'Order',
				query: { 'plan-id': this.planId },
			})
		},

		trackingPurchase() {
			const category =
				this.plan.talk_points > 0 && this.plan.call_points > 0
					? 'package'
					: this.plan.call_points > 0
					? 'call'
					: 'talk'

			const price = this.plan.real_call_price + this.plan.real_talk_price
			// eslint-disable-next-line no-undef
			this.$conversionPurchase(
				this.paymentId,
				this.payment.pay_price,
				this.payment.currency,
				[
					{
						id: this.plan.id,
						name: this.plan.order_title,
						category,
						price,
					},
				],
			)
		},
	},
}
</script>

<style lang="scss" scoped></style>
