import Api from '@/services/index'
import { LOAD } from './mutations'

export default {
	book: async ({ commit, getters, rootGetters }) => {
		commit(LOAD, true)
		const memberId = rootGetters['auth/memberId']
		const params = {
			memberId,
			lessonType: getters.selectedType,
			tutorId: getters.selectedTutor.id,
			datetime: getters.selectedMoment.toISOString(),
		}

		const res = await Api.post(`lessons`, params)

		commit(LOAD, false)

		return res.data[0]
	},
}
