<template>
	<div>
		<div class="white">
			<v-row no-gutters align="center" class="py-2">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('타임존 변경') }}</h1>
				</v-col>
			</v-row>
			<div class="px-5 pt-5 pb-7 grey lighten-4">
				<v-text-field
					hide-details
					prepend-icon="mdi-magnify"
					v-model="search"
				></v-text-field>
			</div>
		</div>
		<section style="overflow-y: auto; height: calc(100vh - 210px)">
			<v-divider class="mb-3"></v-divider>
			<div
				class="px-5"
				@click="timezone = item"
				v-for="(item, i) in filteredTimezones"
				:key="i"
			>
				<div class="d-flex align-center">
					{{ item }}
					<v-spacer></v-spacer>
					<div class="pl-3">
						<v-icon
							:class="{
								'grey--text text--lighten-1': timezone !== item,
								'primary--text': timezone === item,
							}"
						>
							{{
								timezone !== item ? 'mdi-radiobox-blank' : 'mdi-radiobox-marked'
							}}
						</v-icon>
					</div>
				</div>
				<v-divider class="my-3"></v-divider>
			</div>
		</section>
		<div
			class="white"
			style="position: absolute; bottom: 0; width: 100%; z-index: 4"
		>
			<v-btn
				height="55px"
				:disabled="isLoading === true"
				large
				tile
				block
				color="primary"
				@click="changeLocale"
				>{{ $t('저장') }}</v-btn
			>
		</div>
	</div>
</template>

<script>
import Api from '@/services/index'
// import allTimezones from '@/assets/time-zone'
// const R = require('ramda')
import moment from 'moment-timezone'

export default {
	data: () => ({
		isLoading: false,
		timezone: null,
		search: null,
		timeout: null,
		filteredTimezones: [],
		allTimezones: [],
		popularTimezones: [
			'Asia/Seoul',
			'America/New_York',
			'America/Los_Angeles',
			'Asia/Singapore',
			'Asia/Tokyo',
			'America/Chicago',
			'America/Toronto',
			'America/Vancouver',
			'Australia/Sydney',
			'Australia/Brisbane',
			'Australia/Melbourne',
			'Pacific/Auckland',
			'Asia/Hong_Kong',
			'Asia/Kuala_Lumpur',
			'Asia/Ho_Chi_Minh',
			'Asia/Jakarta',
			'Asia/Dubai',
			'Asia/Shanghai',
			'Asia/Kolkata',
			'Asia/Manila',
			'Asia/Bangkok',
			'Europe/London',
			'Europe/Berlin',
			'Europe/Paris',
			'Europe/Amsterdam',
			'America/Edmonton',
			'America/Denver',
			'America/Detroit',
			'US/Pacific',
		],
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		originTimezone() {
			return this.$store.getters['member/timezone']
		},
	},
	watch: {
		search() {
			clearTimeout(this.timeout)
			this.timeout = setTimeout(() => {
				this.searchTimezone()
			}, 300)
		},
	},
	created() {
		this.timezone = this.originTimezone
		const removedTimezone = moment.tz
			.names()
			.filter(x => this.popularTimezones.includes(x) === false)
		this.allTimezones = [...this.popularTimezones, ...removedTimezone]
		this.searchTimezone()
	},
	methods: {
		searchTimezone() {
			this.filteredTimezones = this.allTimezones.filter(
				x =>
					this.search === null ||
					this.search === '' ||
					x.toLowerCase().includes(this.search.toLowerCase()),
			)
		},
		goBack() {
			this.$store.getters['device/fromRouter'].name === null
				? this.$router.push({ name: 'Home' })
				: this.$router.go(-1)
		},
		async changeLocale() {
			try {
				this.isLoading = true
				await Api.patch(`members/${this.memberId}`, {
					timezone: this.timezone,
				})
				await this.$store.dispatch('member/get')
				alert(this.$t('타임존이 변경되었습니다.'))
				this.goBack()
			} catch (err) {
				alert(this.$t('타임존 변경이 실패하였습니다. 다시 시도해주세요.'))
				this.isLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
