<template>
	<div v-if="diffSeconds > 0" class="pa-5 wrap d-flex align-center">
		<div>
			<h1 class="event-title">회원가입만 해도 12만원 상당</h1>
			<p class="event-time">
				오 마이 쿠폰팩 즉시 지급!
				<!-- {{ timer }} -->
			</p>
		</div>
		<v-spacer></v-spacer>
		<v-btn
			@click="$router.push({ name: 'SignUp' })"
			color="primary"
			depressed
			height="46"
			style="font-size: 14px"
			>{{ $t('trial-report.footer.button') }}</v-btn
		>
		<!-- <v-img src="@/assets/images/trials/badge-event.svg" class="badge">
			<span>종료임박</span>
		</v-img> -->
	</div>
</template>

<script>
import moment from 'moment-timezone'

export default {
	props: {
		trialMoment: {
			required: true,
		},
	},
	data() {
		return {
			// timer: null,
		}
	},
	// created() {
	// 	this.calcTimer()
	// },
	computed: {
		diffSeconds() {
			return this.trialMoment.clone().add(7, 'days').diff(moment(), 'second')
		},
		// hour() {
		// 	return parseInt(this.diffSeconds / (60 * 60))
		// },
		// minutes() {
		// 	return parseInt((this.diffSeconds - this.hour * 60 * 60) / 60)
		// },
		// secondes() {
		// 	return this.diffSeconds - this.hour * 60 * 60 - this.minutes * 60
		// },
	},
	methods: {
		// calcTimer() {
		// 	const duration = moment.duration(
		// 		this.trialMoment.clone().add(7, 'days').diff(moment()),
		// 	)
		// 	this.timer = this.$t('trial-report.footer.timer', {
		// 		days: duration.get('days'),
		// 		hours: duration.get('hours'),
		// 		minutes: duration.get('minutes'),
		// 	})
		// 	if (this.$route.name === 'TrialReport') {
		// 		setTimeout(() => {
		// 			this.calcTimer()
		// 		}, 1000)
		// 	}
		// },
	},
}
</script>

<style lang="scss" scoped>
.wrap {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 3;
	max-width: 375px;
	box-shadow: 0 -10px 30px 0 rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.event-title {
	font-size: 12.8px !important;
	font-weight: 500 !important;
	line-height: 1.56 !important;
	letter-spacing: -0.4px !important;
	color: #191f28 !important;
}
.event-time {
	font-size: 19px !important;
	font-weight: bold !important;
	line-height: 1.47 !important;
	letter-spacing: -0.73px !important;
	color: #0073ff !important;
}
.badge {
	width: 86px;
	height: 36.3px;
	position: absolute;
	left: 24px;
	top: -14px;
	padding-left: 11px;
	padding-top: 5px;
	font-size: 12.5px;
	font-weight: bold;
	line-height: 1.76;
	letter-spacing: -0.39px;
	color: #fff;
}
</style>
