<template>
	<div>
		<v-dialog
			v-model="dialog"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<v-card class="elevation-0">
				<div
					style="
						max-width: 400px;
						height: calc(var(--vh, 1vh) * 100);
						overflow-y: auto;
					"
					class="mx-auto"
					:style="
						userDevice === 'desktop' &&
						'border-right:1px solid #dadada;border-left:1px solid #dadada;'
					"
				>
					<section>
						<v-row class="white py-2 pb-md-5" no-gutters align="center">
							<v-col cols="2">
								<v-btn fab icon text small @click="confirmDialog = true">
									<v-icon>mdi-window-close</v-icon>
								</v-btn>
							</v-col>
							<v-col cols="8">
								<h1 class="text-center text-body-1">{{ $t('수업 평가') }}</h1>
							</v-col>
						</v-row>
					</section>
					<section v-if="isLoading === false" style="padding-bottom: 80px">
						<LessonCard :lesson="lesson" class="mb-8 px-5" />

						<Rating
							:lesson="lesson"
							:ratings="ratings"
							@rate="dialog = false"
						/>

						<ReportIssue
							class="mt-5 px-5"
							:lesson="lesson"
							@report="dialog = false"
						/>
					</section>
				</div>
			</v-card>
		</v-dialog>

		<v-dialog v-model="confirmDialog" width="350" content-class="rounded-xl">
			<v-card rounded="xl" class="pt-5">
				<v-card-text>
					<h1 class="text-body-1 mb-3 font-weight-bold">
						{{ $t('수업 평가 페이지를 정말 나가시겠어요?') }}
					</h1>
					<p
						class="text-body-2 grey--text text--darken-1 mb-5"
						v-html="
							$t(
								`수업 평가는 수업 후 24시간 동안만 가능해요.<br />지금 작성을 마치시고 <span class='font-weight-bold primary--text'>캐시 200원</span>을 받아보세요. 🙆‍♀️`,
							)
						"
					></p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-btn color="black" text @click="closeConfirmDialog()">
						{{ $t('나갈래요') }}
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="confirmDialog = false">
						{{ $t('계속 작성할래요') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import LessonCard from '@/components/rating/LessonCard'
import Rating from '@/components/rating/Rating'
import ReportIssue from '@/components/rating/ReportIssue'

export default {
	components: {
		LessonCard,
		ReportIssue,
		Rating,
	},
	props: {
		lesson: {
			required: true,
			type: Object,
		},
		issues: {
			required: true,
			type: Array,
		},
		ratings: {
			required: true,
			type: Array,
		},
	},
	data() {
		return {
			dialog: false,
			confirmDialog: false,
			isLoading: false,
		}
	},
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		lessonId() {
			return this.$route.params.lessonId
		},
		isRatingPossible() {
			return moment() > this.lesson.moment &&
				this.lesson.moment.clone().add(24, 'hours') > moment() &&
				this.ratings.length === 0 &&
				this.issues.length === 0
				? true
				: false
		},
	},
	created() {
		if (this.isRatingPossible === true) this.dialog = true
	},
	methods: {
		closeConfirmDialog() {
			this.dialog = false
			this.confirmDialog = false
		},
	},
}
</script>

<style lang="scss" scoped></style>
