<template>
	<div></div>
</template>

<script>
import ChannelService from '@/lib/channel-talk'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('member')

export default {
	computed: {
		...mapGetters([
			'email',
			'nameKr',
			'nameEn',
			'countryCode',
			'phone',
			'language',
			'nationality',
		]),
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		setting() {
			let setting = {
				pluginKey: '3d358728-1357-486f-ba3d-dfa6862842e2',
				zIndex: 5,
				hideDefaultLauncher: false,
			}
			if (this.memberId) {
				setting.userId = this.memberId
				setting.profile = {
					name: this.nameKr,
					name_en: this.nameEn,
					mobileNumber: `${this.countryCode})${this.phone}`,
					email: this.email,
					kakao_id: this.kakaoId, // 카카오아이디
					language: this.language,
					nationality: this.nationality,
				}
			}
			return setting
		},
	},
	watch: {
		setting: {
			deep: true,
			handler() {
				ChannelService.updateUser(this.setting)
			},
		},
	},
	created() {
		ChannelService.boot(this.setting)
	},
}
</script>

<style lang="scss" scoped></style>
