<template>
	<div>
		<v-form ref="form" @submit.prevent="register">
			<v-text-field
				v-model="couponNumber"
				type="text"
				outlined
				single-line
				hide-details
				class="order-picker"
				:placeholder="$t('할인 쿠폰 코드를 입력해주세요.')"
			>
				<template v-slot:append>
					<v-btn
						width="75"
						@click.native="register"
						color="primary"
						class="ma-0 font-weight-bold"
						depressed
					>
						{{ $t('등록') }}</v-btn
					>
				</template>
			</v-text-field>
		</v-form>
		<v-dialog v-model="dialog" width="310">
			<v-card class="rounded-lg">
				<div class="px-4 py-5">
					<h1 class="my-title font-19 mb-3">{{ title }}</h1>
					<p class="my-text font-14 mb-5">
						{{ msg }}
					</p>
					<v-btn @click="dialog = false" depressed block color="primary"
						>확인</v-btn
					>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	data: () => ({
		isLoading: false,
		msg: null,
		title: null,
		dialog: false,
		couponNumber: null,
	}),
	watch: {
		dialog() {
			this.couponNumber = null
		},
	},
	methods: {
		async register() {
			try {
				this.isLoading = true
				this.title = null
				this.msg = null
				await Api.post(`coupons/${encodeURIComponent(this.couponNumber)}`)
				alert('할인쿠폰이 등록되었습니다.')
				this.$emit('registeredCoupon')
				this.couponNumber = null
			} catch (err) {
				this.title = '코드를 다시 확인해주세요.'
				this.msg = this.$t(
					'알 수 없는 에러가 발생했습니다. 고객센터로 문의주세요.\nERROR CODE: BV910S',
				)
				if (err.response.status === 400) {
					const code = err.response.data.code.toString()
					if (code === '70000')
						this.msg = this.$t(
							'유효한 쿠폰 번호가 아닙니다. 쿠폰 번호를 확인하세요.',
						)
					if (code === '70001') this.msg = this.$t('이미 등록된 쿠폰입니다.')
					if (code === '70002') this.msg = this.$t('이미 만료된 쿠폰입니다.')
					if (code === '70003')
						this.msg = this.$t('아직 사용기간이 시작되지 않은 쿠폰입니다.')
					if (code === '70004')
						// this.msg = this.$t('이미 체험수업 쿠폰을 발급 받으셨습니다.')
						this.msg = this.$t('이미 등록된 쿠폰입니다.')
					if (code === '70005')
						this.msg = this.$t(
							'가입 시 입력하신 전화번호로 진행된 체험수업 정보를 확인할 수 없습니다.',
						)
				}
			} finally {
				this.isLoading = false
				this.dialog = true
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
