<template>
	<div
		class="d-flex align-center my-2 py-2 hover-pointer"
		data-test="changeProgramButton"
		@click="checkForChange('program')"
	>
		<img
			src="@/assets/images/home/program-change.png"
			style="width: 40px"
			alt=""
		/>
		<div class="pl-2 text-left" data-test="changeText">
			<h3 class="font-15">
				{{ $t('component.lessonCard.title.프로그램 변경') }}
			</h3>
			<p class="font-13 grey--text text--darken-1">
				{{
					$t('component.lessonCard.text.수업 10분 전까지 변경이 가능합니다.')
				}}
			</p>
		</div>
		<v-spacer></v-spacer>
		<img
			src="@/assets/images/icon/chevron-right-light.png"
			style="width: 18px; vertical-align: middle"
			alt=""
		/>

		<v-dialog v-model="programRejectDialog" width="310">
			<v-card data-test="programRejectDialog" class="rounded-lg">
				<div class="px-4 py-5">
					<h1 class="my-title font-19 mb-5">
						{{
							$t(
								'component.lessonCard.title.프로그램은 수업 10분전까지 변경하실 수 있습니다.',
							)
						}}
					</h1>

					<v-btn
						depressed
						block
						color="primary"
						@click="programRejectDialog = false"
						>{{ $t('component.lessonCard.button.확인') }}</v-btn
					>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
	props: {
		lesson: {
			required: true,
			type: Object,
		},
	},
	data: () => ({
		programRejectDialog: false,
	}),
	methods: {
		checkForChange() {
			if (this.lesson.dt <= DateTime.now().plus({ minutes: 10 })) {
				this.programRejectDialog = true
				return
			}

			// this.$router.push({
			// 	name: 'ProgramChange',
			// 	params: { lessonId: this.lesson.id },
			// })
			this.$router.push({
				name: 'LessonProgramSelect',
				params: { lessonId: this.lesson.id },
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
