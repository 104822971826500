<template>
	<div class="pt-5 pb-14 px-5">
		<div class="rounded-lg wrap py-5 text-center">
			<h2 class="text white--text mb-2">쿠폰 + 수강권 최대 이득 조합은?</h2>
			<h1 class="title white--text font-weight-bold mb-6">
				영어 잘 하고 싶지만<br />
				쿠폰도 쓰고 싶어
			</h1>
			<img
				style="width: 254px"
				class="mx-auto mb-10"
				lazy-src="@/assets/images/common/2022-coupon-pack-mobile-min.png"
				src="@/assets/images/banner/2022-coupon-pack-mobile.png"
			/>
			<v-btn
				href="http://tella.co.kr/event/2022/coupon-pack"
				class="rounded-lg action-button"
				depressed
				x-large
				height="56"
				color="white"
				style="width: 220px"
				@click="$router.push({ name: 'Event2022CouponPack' })"
				><span class="couponpack--text"
					>더 알아보기
					<v-icon class="mt-n1 mr-n3">mdi-chevron-right</v-icon></span
				>
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		trialName: {
			required: true,
			type: String,
		},
		trialMoment: {
			required: true,
		},
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		expireDate() {
			return this.trialMoment
				.clone()
				.add(7, 'days')
				.locale(this.language)
				.format('MMMM Do')
		},
	},
	methods: {
		copySecretCode() {
			/* Get the text field */
			var copyText = document.getElementById('secret-code')

			/* Select the text field */
			copyText.select()
			copyText.setSelectionRange(0, 99999) /* For mobile devices */

			/* Copy the text inside the text field */
			navigator.clipboard.writeText(copyText.value)
		},
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-size: 24px !important;
	font-weight: bold !important;
	line-height: 1.38 !important;
	letter-spacing: -0.96px !important;
	// color: #191f28 !important;
}
.text {
	font-size: 16px !important;
	line-height: 1.56 !important;
	letter-spacing: -0.5px !important;
}
.event-title {
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 1.56 !important;
	letter-spacing: -0.5px !important;
	color: #fff !important;
}
.event-time {
	font-size: 24px !important;
	font-weight: bold !important;
	line-height: 1.46 !important;
	letter-spacing: -0.92px !important;
	color: #fff !important;
}
.event-description {
	font-size: 12px !important;
	font-weight: normal !important;
	line-height: 1.5 !important;
	letter-spacing: -0.38px !important;
	text-align: center !important;
	color: #38424f !important;
}
.popup-button.code {
	background: #fff !important;
	color: #0074ff !important;
	font-size: 22px;
	letter-spacing: -0.05em;
}

.popup-button {
	box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.25) !important;
	border-radius: 16px !important;
	width: 90%;
	font-weight: 700;
	max-width: 300px;
}
.copy-button {
	margin-left: 20px;
	padding: 6px 14px;
	border-radius: 7px;
	font-size: 14px;
	margin-right: -15px;
}

.action-button {
	box-shadow: 0px 16px 32px rgb(0 0 0 / 8%) !important;
}
.couponpack--text {
	color: #00b2bc;
}
.action-button:hover {
	box-shadow: unset !important;
}
.wrap {
	background-color: #00b2bc;
}

.sub--title {
	color: #fff;
}

.main--title {
	color: #fff;
}
</style>
