<template>
	<div id="setting-notify">
		<v-row align="center" no-gutters class="pa-3">
			<v-col cols="2">
				<v-btn @click="goBack" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
			</v-col>
			<v-col cols="8">
				<h1 class="text-center text-body-1 font-weight-regular">
					{{ $t('수업 알림') }}
				</h1>
			</v-col>
		</v-row>

		<section class="pa-5" v-if="isLoading === false">
			<h2
				class="text-h6 mb-1 font-weight-bold"
				v-html="$t('학습 효과를 높이기 위한<br />중요한 정보들을 알려드려요.')"
			></h2>
			<p class="font-14 mb-8 grey--text text--darken-3">
				{{
					$t(
						'page.setting.notify.text.알림은 카카오톡 카카오톡 알림톡으로 보내드립니다.',
					)
				}}
			</p>

			<div class="d-flex align-center">
				<p class="font-16 font-weight-bold">
					{{ $t('수업 전 예습 알림') }}
				</p>
				<v-spacer></v-spacer>
				<v-switch
					v-model="preLesson"
					@click="changeAlarmSetting"
					inset
					hide-details
					class="mt-0 mr-n2"
				></v-switch>
			</div>
			<v-divider class="mt-1"></v-divider>

			<v-radio-group
				class="mb-12"
				v-model="preLessonAlarm"
				@change="changeAlarmSetting"
				hide-details
				:disabled="isUpdating || preLesson === false"
			>
				<v-radio
					color="primary"
					:label="$t('15분 전 알림')"
					:value="1"
					class="d-flex flex-row-reverse"
				>
				</v-radio>
				<v-radio
					:label="$t('30분 전 알림')"
					color="primary"
					class="d-flex flex-row-reverse"
					:value="2"
				>
				</v-radio>
			</v-radio-group>

			<div class="d-flex align-center">
				<p class="font-16 font-weight-bold">
					{{ $t('수업 후 튜터 평가 알림') }}
				</p>
				<v-spacer></v-spacer>
				<v-switch
					@click="changeAlarmSetting"
					v-model="postLessonAlarm"
					inset
					hide-details
					class="mt-0 mr-n2"
				></v-switch>
			</div>
			<v-divider class="mt-1 mb-11"></v-divider>
			<div class="d-flex align-center">
				<p class="font-16 font-weight-bold">
					{{ $t('page.setting.notify.title.수업 후 복습 알림') }}
				</p>
				<v-spacer></v-spacer>
				<v-switch
					@click="changeAlarmSetting"
					v-model="reviewAlarm"
					inset
					hide-details
					class="mt-0 mr-n2"
				></v-switch>
			</div>
			<v-divider class="mt-1 mb-2"></v-divider>
			<p class="font-14 grey--text text--darken-1 mb-8">
				{{
					$t('page.setting.notify.text.수업 24시간 후 복습 알림을 보내드려요')
				}}
			</p>
			<div class="mt-11 d-flex align-center">
				<p class="font-16 font-weight-bold">
					{{ $t('page.setting.notify.title.주간 리포트') }}
				</p>
				<v-spacer></v-spacer>
				<v-switch
					@click="changeAlarmSetting"
					v-model="weeklyReportAlarm"
					inset
					hide-details
					class="mt-0 mr-n2"
				></v-switch>
			</div>
			<v-divider class="mt-1 mb-2"></v-divider>
			<p class="font-14 grey--text text--darken-1 mb-8">
				{{
					$t(
						'page.setting.notify.text.매주 일요일 8시, 한 주간 학습 성과를 정리하여 보내드려요.',
					)
				}}
			</p>
		</section>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	data() {
		return {
			isLoading: false,
			isUpdating: false,
			id: null,
			preLesson: false,
			preLessonAlarm: 1,
			postLessonAlarm: false,
			needRegister: false,
			reviewAlarm: false,
			weeklyReportAlarm: false,
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		preLessonAlarmKr() {
			return this.preLessonAlarm === '0'
				? this.$t('받지 않음')
				: this.preLessonAlarm === '1'
				? this.$t('15분 전 알림')
				: this.$t('30분 전 알림')
		},
		postLessonAlarmKr() {
			return this.postLessonAlarm === '0'
				? this.$t('받지 않음')
				: this.$t('받음')
		},
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			this.isLoading = true
			const res = await Api.get(`student-info?member-id=${this.memberId}`)
			if (res.data.length > 0) {
				const result = res.data[0]
				this.id = result.id
				this.preLesson = result.pre_lesson_alarm !== 0
				this.preLessonAlarm =
					result.pre_lesson_alarm === 1 || result.pre_lesson_alarm === 2
						? result.pre_lesson_alarm
						: 1
				this.postLessonAlarm = result.post_lesson_alarm === 0 ? false : true
				this.reviewAlarm = result.review_alarm === 0 ? false : true
				this.weeklyReportAlarm = result.weekly_report_alarm === 0 ? false : true
			}

			this.isLoading = false
		},
		async changeAlarmSetting() {
			try {
				this.isUpdating = true
				this.id === null
					? await Api.post(`student-info`, {
							memberId: this.memberId,
							preLessonAlarm:
								this.preLesson === false ? 0 : this.preLessonAlarm,
							postLessonAlarm: this.postLessonAlarm === true ? 1 : 0,
							reviewAlarm: this.reviewAlarm === false ? 0 : 1,
							weeklyReportAlarm: this.weeklyReportAlarm === false ? 0 : 1,
					  })
					: await Api.patch(`student-info/${this.id}`, {
							preLessonAlarm:
								this.preLesson === false ? 0 : this.preLessonAlarm,
							postLessonAlarm: this.postLessonAlarm === true ? 1 : 0,
							reviewAlarm: this.reviewAlarm === false ? 0 : 1,
							weeklyReportAlarm: this.weeklyReportAlarm === false ? 0 : 1,
					  })
				// alert(this.$t('알림 설정이 완료되었습니다.'))
			} catch (err) {
				let alertMsg = this.$t('알림을 설정하는데에 오류가 발생했습니다.')
				if (err.response) {
					const errMsg = err.response.data.message
					alertMsg = this.$t(`알림 설정에 실패하였습니다. \n ${errMsg}`)
				}
				alert(alertMsg)
			} finally {
				this.isUpdating = false
			}
		},

		goBack() {
			this.$router.push({ name: 'Setting' })
		},
	},
}
</script>

<style>
#setting-notify .v-input--selection-controls__input + .v-label {
	color: #1a1a1a;
	font-size: 14px;
}
#lesson-style-dialog .v-input--selection-controls.v-input .v-label {
	/* font-size: 1rem; */
	margin-top: -3px;
	color: #000;
}
#lesson-style-dialog
	.v-input--selection-controls.v-input
	.accent--text
	.v-label {
	color: #000;
}
#lesson-style-dialog .v-stepper__step__step.primary {
	background-color: #000 !important;
}
#lesson-style-dialog .accent--text {
	caret-color: #076cfa !important;
	color: #076cfa !important;
}
#lesson-style-dialog .v-stepper--vertical .v-stepper__content {
	padding-top: 0;
	padding-bottom: 0;
}
</style>

<style scoped>
.explain strong {
	color: black;
}
</style>
