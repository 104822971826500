<template>
	<div
		v-if="isLoading === false"
		class="pb-10 d-flex align-center justify-center empty-wrap"
	>
		<div class="text-center">
			<img
				v-if="
					category === 'talk' || category === 'call' || category === 'appointed'
				"
				:style="categoryIconWidthSize"
				:src="require(`@/assets/images/schedule/${imgName}.png`)"
				class="mb-2"
				alt=""
			/>
			<p
				class="
					text-center
					font-16 font-weight-regular
					grey--text
					text--darken-1
					mb-5
				"
			>
				<span v-if="category === 'all' || category === 'levelTest'"
					>{{ $t('page.reviewList.text.무료수업으로 채팅영어 체험하고') }}
					<br />
					{{ $t('page.reviewList.text.현재 영어 실력도 진단해보세요!') }}
				</span>
				<span v-if="category === 'talk'"
					>{{ $t('page.reviewList.text.수강하신 25분톡 수업이 없습니다.') }}
				</span>
				<span v-if="category === 'call'"
					>{{ $t('page.reviewList.text.수강하신 15분콜 수업이 없습니다.') }}
				</span>
				<span v-if="category === 'appointed'"
					>{{ $t('page.reviewList.text.예약하신 수업이 없습니다.') }}
				</span>
			</p>
			<v-btn
				v-if="category === 'all' || category === 'levelTest'"
				@click="$router.push({ name: 'TrialBookForMember' })"
				depressed
				color="primary"
				height="40"
			>
				<span class="font-12">{{
					$t('page.reviewList.button.무료수업 신청하기')
				}}</span></v-btn
			>
			<v-btn
				v-if="category === 'talk'"
				@click="clickBook(0)"
				depressed
				color="primary"
				height="40"
			>
				<span class="font-12">{{
					$t('page.reviewList.button.25분톡 수업 예약하기')
				}}</span></v-btn
			>
			<v-btn
				v-if="category === 'call'"
				@click="clickBook(1)"
				depressed
				color="primary"
				height="40"
			>
				<span class="font-12">{{
					$t('page.reviewList.button.15분콜 수업 예약하기')
				}}</span></v-btn
			>
		</div>
		<v-dialog v-model="noPlanDialog" width="310">
			<v-card data-test="warningAlert">
				<div class="px-4 py-5">
					<h1 class="my-title font-19 mb-5">
						{{ $t('page.reviewList.title.수강신청 후 이용하세요.') }}
					</h1>
					<p class="my-text font-14 mb-5">
						{{
							$t(
								'page.reviewList.text.%{name}님을 위한 할인 혜택이 준비되어 있어요. 편하게 수강권을 둘러보세요!',
							)
						}}
					</p>
					<v-row no-gutters>
						<v-col cols="6" class="pr-1">
							<v-btn
								depressed
								block
								color="primary--text"
								style="background-color: #f2f9ff"
								@click="noPlanDialog = false"
								>{{ $t('page.reviewList.button.취소') }}</v-btn
							>
						</v-col>
						<v-col cols="6" class="pl-1">
							<v-btn
								@click="$router.push({ name: 'Plans' })"
								depressed
								block
								color="primary"
								>{{ $t('page.reviewList.button.수강권 둘러보기') }}</v-btn
							>
						</v-col>
					</v-row>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog v-model="bookTypeDialog" width="310">
			<v-card data-test="bookTypeDialog" class="grey lighten-1">
				<div class="px-4 py-5">
					<h1 class="my-title font-19 mb-5">
						{{ $t('page.home.bookLesson.title.예약 방식을 선택하세요.') }}
					</h1>

					<v-row no-gutters class="text-center">
						<v-col cols="6" class="pr-1">
							<v-sheet
								data-test="tutor-first-button"
								class="rounded-lg px-1 py-6"
								@click="selectBookType('tutor')"
							>
								<img
									class="first-img mx-auto"
									src="@/assets/images/home/tutor-first.png"
									alt=""
								/>
								<h2 class="mt-1 font-16 font-weight-bold">
									{{ $t('page.home.bookLesson.button.튜터 먼저 선택')
									}}<v-icon class="mr-n2" style="margin-top: -3px"
										>mdi-chevron-right</v-icon
									>
								</h2>
							</v-sheet>
						</v-col>
						<v-col cols="6" class="pl-1">
							<v-sheet
								data-test="time-first-button"
								class="rounded-lg px-1 py-6"
								@click="selectBookType('time')"
								><img
									class="first-img mx-auto"
									src="@/assets/images/home/time-first.png"
									alt=""
								/>
								<h2 class="mt-1 font-16 font-weight-bold">
									{{ $t('page.home.bookLesson.button.시간 먼저 선택')
									}}<v-icon class="mr-n2" style="margin-top: -3px"
										>mdi-chevron-right</v-icon
									>
								</h2>
							</v-sheet>
						</v-col>
					</v-row>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { DateTime } from 'luxon'
import scheduleApi from '@/services/schedule'

export default {
	props: {
		category: {
			required: true,
			type: String,
		},
	},
	data: () => ({
		isLoading: false,
		noPlanDialog: false,
		bookTypeDialog: false,
		lessonType: 0,
		points: [],
	}),
	computed: {
		name() {
			return this.$store.getters['member/nameKr']
		},
		imgName() {
			return this.category === 'appointed'
				? 'calendar-icon-grey'
				: this.category === 'call'
				? 'call-icon-grey'
				: 'talk-icon-grey'
		},
		availablePoints() {
			const isAvailable = p => {
				const KSTDT = DateTime.local().setZone('Asia/Seoul')
				const KSTDate = KSTDT.toFormat('y-LL-dd')

				return (
					p.startDate <= KSTDate &&
					p.expiryDate >= KSTDate &&
					p.active === 1 &&
					p.remainingPoints >= 1 &&
					p.lessonType === this.lessonType
				)
			}
			return this.points.filter(isAvailable)
		},
		lessonTypeEn() {
			return this.lessonType === 1 ? 'call' : 'talk'
		},
		categoryIconWidthSize() {
			return this.category === 'appointed'
				? 'width:65px'
				: this.category === 'talk'
				? 'width: 60px'
				: 'width:40px'
		},
	},
	created() {
		if (this.category === 'talk' || this.category === 'call') this.getPoints
	},
	methods: {
		async getPoints() {
			this.isLoading = true
			this.points = await scheduleApi.getPoints()
			this.isLoading = false
		},
		clickBook(lessonType) {
			this.lessonType = lessonType

			if (this.availablePoints.length === 0) {
				this.noPlanDialog = true
				return
			}
			this.bookTypeDialog = true
		},

		selectBookType(bookType) {
			const name = bookType === 'tutor' ? 'TutorFirst' : 'TimeFirstStep1'
			this.$router.push({ name, params: { typeEn: this.lessonTypeEn } })
		},
	},
}
</script>

<style lang="scss" scoped>
.empty-wrap {
	padding-top: 100px;
	// height: calc(var(--vh, 1vh) * 100 - 500px);
	overflow-y: auto;
}
/* iOS only */
// @supports (-webkit-touch-callout: none) {
// 	.empty-wrap {
// 		height: -webkit-fill-available;
// 	}
// }
</style>
