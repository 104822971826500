<template>
	<section>
		<h1 class="font-weight-bold text-h6">{{ $t('문장 수 증가추이') }}</h1>
		<BarChart
			style="width: 100%; max-height: 400px; margin: 0 auto"
			:chart-data="dataSets"
			:options="options"
		></BarChart>
		<div class="d-flex pt-2 justify-center">
			<div class="d-flex align-center mb-1 mx-2">
				<div
					class="mr-1"
					style="background-color: #076cfa; width: 10px; height: 10px"
				></div>
				<p class="small nls">{{ $t('그날의 문장수') }}</p>
			</div>
			<div class="d-flex align-center mb-1 mx-2">
				<div
					class="mr-1"
					style="background-color: #ddd; width: 10px; height: 10px"
				></div>
				<p class="small nls">{{ $t('누적 문장수') }}</p>
			</div>
		</div>
	</section>
</template>

<script>
// import SentenceChart from './SentenceChart'
// import LineChart from '@/lib/LineChart.js'
import BarChart from '@/lib/BarChart.js'
import moment from 'moment-timezone'
// import { mapGetters } from 'vuex'

export default {
	components: {
		BarChart,
	},
	props: {
		stats: {
			type: Array,
			required: true,
		},
	},
	computed: {
		options() {
			let options = {
				responsive: true,
				// maintainAspectRatio: false,
				legend: {
					display: false,
				},
				scales: {
					xAxes: [
						{
							maxBarThickness: 30,
							stacked: true,
						},
					],
					yAxes: [
						{
							stacked: true,
						},
					],
				},
			}
			return options
		},
		dataSets() {
			let sentences = []
			let cumulativeSentence = []
			let lessonDateTime = []
			let cumulative = 0
			this.stats.forEach(stat => {
				if (stat.tutee_number_of_sentences > 0) {
					sentences.push(stat.tutee_number_of_sentences)
					cumulativeSentence.push(cumulative)
					cumulative = cumulative + stat.tutee_number_of_sentences
					lessonDateTime.push(moment(stat.lesson_datetime).format('M/D H:mm'))
				}
			})
			return {
				datasets: [
					{
						data: cumulativeSentence,
						backgroundColor: '#ddd',
					},
					{
						data: sentences,
						backgroundColor: '#076cfa',
					},
				],
				labels: lessonDateTime,
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
