<template>
	<div class="py-14 px-5">
		<h1 class="title2 mb-10 white-space-pre">
			{{ $t('trial-report.program.title') }}
		</h1>
		<h2 class="mb-2 sub-title primary--text">
			{{
				$t('trial-report.program.text', {
					level,
				})
			}}
		</h2>
		<p class="text default-color">
			{{ $t(`trial-report.program.recommend-for-level-${level}`) }}
		</p>
		<swiper class="mb-10 swiper" :options="swiperOption">
			<swiperSlide v-for="id in programsForLevel[language][level]" :key="id">
				<div class="py-5">
					<ProgramCard :id="id" />
				</div>
			</swiperSlide>
		</swiper>
		<h2 class="mb-2 sub-title primary--text">{{ forPurpose.kr }}</h2>
		<p class="text default-color">
			{{ forPurpose.description }}
		</p>
		<swiper class="mb-10 swiper" :options="swiperOption">
			<swiperSlide
				v-for="id in programsForPurpose[language][purpose][level]"
				:key="`purpose-${id}`"
			>
				<div class="py-5">
					<ProgramCard :id="id" />
				</div>
			</swiperSlide>
		</swiper>
		<h2 class="mb-2 sub-title primary--text white-space-pre">
			{{ $t('trial-report.program.title-for-call') }}
		</h2>
		<p class="text default-color">
			{{ $t('trial-report.program.text-for-call') }}
		</p>
		<swiper class="swiper" :options="swiperOption">
			<swiperSlide
				v-for="id in programsForCall[language][purpose][level]"
				:key="`call-${id}`"
			>
				<div class="py-5">
					<ProgramCard :id="id" />
				</div>
			</swiperSlide>
		</swiper>
	</div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import ProgramCard from './ProgramCard'
const R = require('ramda')

export default {
	components: {
		swiper,
		swiperSlide,
		ProgramCard,
	},
	props: {
		level: {
			required: true,
			type: Number,
		},
		purpose: {
			required: true,
			type: Number,
		},
		language: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: 1.7,
				spaceBetween: 15,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			},
			programsForLevel: {
				ja: {
					3: [20, 103],
					4: [22, 23, 68],
					5: [123, 74, 33],
					6: [29, 24],
					7: [122, 186],
				},
				ko: {
					3: [20, 103],
					4: [22, 23, 68],
					5: [123, 74, 33],
					6: [29, 24],
					7: [122, 186],
				},
			},
			programsForPurpose: {
				ja: {
					1: {
						3: [28, 30],
						4: [28, 30],
						5: [28, 30, 31],
						6: [36, 133, 140],
						7: [140, 133],
					},
					2: {
						3: [26, 27],
						4: [26, 27],
						5: [26, 27, 61],
						6: [27, 61],
						7: [27, 61],
					},
					3: {
						3: [26, 27],
						4: [26, 27],
						5: [26, 27, 61],
						6: [27, 61],
						7: [27, 61],
					},
					4: {
						3: [119, 120, 132],
						4: [119, 120, 132, 63],
						5: [119, 120, 132, 60],
						6: [34, 120, 60],
						7: [34, 120],
					},
					5: {
						3: [119, 120, 132],
						4: [119, 120, 132, 63],
						5: [119, 120, 132, 60],
						6: [34, 120, 60],
						7: [34, 120],
					},
				},
				ko: {
					// 비즈니스(1)
					1: {
						3: [28, 30],
						4: [28, 30],
						5: [28, 30, 31],
						6: [36, 133, 140],
						7: [140, 203, 204],
					},
					// 해외생활(2,3)
					2: {
						3: [26, 27],
						4: [26, 27],
						5: [26, 27, 61],
						6: [27, 61],
						7: [27, 61],
					},
					// 해외생활(2,3)
					3: {
						3: [26, 27],
						4: [26, 27],
						5: [26, 27, 61],
						6: [27, 61],
						7: [27, 61],
					},
					// 시험대비
					4: {
						3: [124, 217, 218, 125, 126, 127, 128, 129],
						4: [124, 217, 218, 125, 126, 127, 128, 129],
						5: [124, 217, 218, 125, 126, 127, 128, 129],
						6: [124, 217, 218, 125, 126, 127, 128, 129],
						7: [124, 125, 126, 127, 128, 129],
					},
					// 자기계발
					5: {
						3: [119, 120, 132],
						4: [119, 120, 132, 63],
						5: [119, 120, 132, 60],
						6: [34, 120, 60, 219],
						7: [211, 212, 120, 220],
					},
				},
			},
			programsForCall: {
				ja: {
					1: {
						3: [141, 163, 148, 150],
						4: [143, 144, 159, 148, 150],
						5: [169, 161, 153, 148, 150, 151],
						6: [149, 145, 155, 177, 184],
						7: [168, 184, 177],
					},
					2: {
						3: [141, 163, 146, 147],
						4: [143, 144, 159, 146, 147],
						5: [169, 161, 153, 146, 147, 157],
						6: [149, 145, 147, 157],
						7: [168, 147, 157],
					},
					3: {
						3: [141, 163, 146, 147],
						4: [143, 144, 159, 146, 147],
						5: [169, 161, 153, 146, 147, 157],
						6: [149, 145, 147, 157],
						7: [168, 147, 157],
					},
					4: {
						3: [141, 163, 166, 167, 176],
						4: [143, 144, 159, 166, 167, 176, 158],
						5: [169, 161, 153, 166, 167, 176, 156],
						6: [149, 145, 154, 167, 156],
						7: [168, 154, 167],
					},
					5: {
						3: [141, 163, 166, 167, 176],
						4: [143, 144, 159, 166, 167, 176, 158],
						5: [169, 161, 153, 166, 167, 176, 156],
						6: [149, 145, 154, 167, 156],
						7: [168, 154, 167],
					},
				},
				ko: {
					// 비즈니스
					1: {
						3: [141, 163, 148, 150],
						4: [143, 144, 159, 148, 150],
						5: [169, 161, 153, 148, 150, 151],
						6: [149, 145, 155, 177, 184],
						7: [168, 184],
					},
					// 해외생활
					2: {
						3: [141, 163, 146, 147],
						4: [143, 144, 159, 146, 147],
						5: [169, 161, 153, 146, 147, 157],
						6: [149, 145, 147, 157],
						7: [168, 147, 157],
					},
					// 해외생활
					3: {
						3: [141, 163, 146, 147],
						4: [143, 144, 159, 146, 147],
						5: [169, 161, 153, 146, 147, 157],
						6: [149, 145, 147, 157],
						7: [168, 147, 157],
					},
					// 시험대비
					4: {
						3: [141, 163, 170, 171, 172, 173, 174, 175],
						4: [143, 144, 159, 170, 171, 172, 173, 174, 175],
						5: [169, 161, 153, 170, 171, 172, 173, 174, 175],
						6: [149, 145, 170, 171, 172, 173, 174, 175],
						7: [168, 170, 171, 172, 173, 174, 175],
					},
					// 자기계발
					5: {
						3: [141, 163, 166, 167, 176],
						4: [143, 144, 159, 166, 167, 176, 158],
						5: [169, 161, 153, 166, 167, 176, 156],
						6: [149, 145, 154, 167, 156],
						7: [168, 154, 167],
					},
				},
			},
		}
	},
	computed: {
		forPurpose() {
			const list = [
				{
					purpose: 1,
					en: 'For career; for business use',
					kr: this.$t('trial-report.program.buisiness-title'),
					description: this.$t('ttrial-report.program.buisiness-description'),
				},
				{
					purpose: 2,
					en: 'To travel abroad',
					kr: this.$t('trial-report.program.travel-title'),
					description: this.$t('trial-report.program.travel-description'),
				},
				{
					purpose: 3,
					en: 'To study or live abroad',
					kr: this.$t('trial-report.program.study-abraod-title'),
					description: this.$t('trial-report.program.study-abraod-description'),
				},
				{
					purpose: 4,
					en: 'For an English language test (TOEFL, IELTS, OPIc, etc.)',
					kr: this.$t('trial-report.program.test-title'),
					description: this.$t('trial-report.program.test-description'),
				},
				{
					purpose: 5,
					en: 'For personal growth; as a hobby',
					kr: this.$t('trial-report.program.hobby-title'),
					description: this.$t('trial-report.program.hobby-description'),
				},
			]
			return R.find(R.propEq('purpose', this.purpose))(list)
		},
	},
}
</script>

<style lang="scss" scoped></style>
