import moment from 'moment-timezone'
import i18n from '@/i18n'

const R = require('ramda')

export default {
	storeAt: state => state.storeAt,
	storeProgressesAt: state => state.storeProgressesAt,
	selectedLessonType: state => state.selectedLessonType,
	programs: (state, getters, rootState, rootGetters) => {
		const excluededPrograms = []
		if (rootGetters['member/language'] !== 'ja') excluededPrograms.push(207)

		const groups = rootGetters['group/groups']
		// if (groups === null || groups.includes(6) === false)
		// 	excluededPrograms.push(
		// 		191,
		// 		192,
		// 		193,
		// 		194,
		// 		195,
		// 		196,
		// 		197,
		// 		198,
		// 		199,
		// 		200,
		// 		201,
		// 		202,
		// 	)
		return R.pipe(
			R.filter(
				p =>
					excluededPrograms.includes(p.id) === false &&
					(groups.includes(p.group_id) === true || p.group_id === 1),
			),
			R.forEach(p => {
				p.recent = getters.recentProgramIds.includes(p.id)
				p.appointed = getters.appointedProgramIds.includes(p.id)
			}),
		)(state.programs)
	},
	programsFilteredType: (state, getters) => {
		return R.pipe(R.filter(p => p.lesson_type === state.selectedLessonType))(
			getters.programs,
		)
	},
	category: state => state.category,
	subCategory: state => state.subCategory,
	inProgressProgramIds: state =>
		R.pipe(
			R.map(x => x.program_id),
			R.filter(x => x !== null && x !== undefined),
			R.uniq(),
		)(state.progresses),
	appointedProgramIds: (state, getters, rootState, rootGetters) =>
		R.pipe(
			R.map(x => x.program_id),
			R.filter(x => x !== null && x !== undefined),
			R.uniq(),
		)(rootGetters['appointed/lessons']),
	recentProgramIds: state =>
		R.pipe(
			R.filter(
				x =>
					moment.tz(x.lesson_datetime, 'Asia/Seoul') >
					moment().add(-30, 'days'),
			),
			R.map(x => x.program_id),
			R.filter(x => x !== null && x !== undefined),
			R.uniq(),
		)(state.progresses),
	programsInCategory: (state, getters) => {
		let programs = getters.programsFilteredType
		switch (state.category) {
			case 'inProgress':
				switch (state.subCategory) {
					case 'all':
						programs = R.filter(x =>
							R.uniq(
								R.concat(
									getters.inProgressProgramIds,
									getters.appointedProgramIds,
									getters.recentProgramIds,
								),
							).includes(x.id),
						)(getters.programsFilteredType)
						break
					case 'appointed':
						programs = R.filter(x =>
							getters.appointedProgramIds.includes(x.id),
						)(getters.programsFilteredType)
						break
					case 'recent':
						programs = R.filter(x => getters.recentProgramIds.includes(x.id))(
							getters.programsFilteredType,
						)
						break
				}
				break
			case 'level':
				switch (state.subCategory) {
					case '3':
						programs = R.filter(x => x.program_level.split('|').includes('3'))(
							getters.programsFilteredType,
						)
						break
					case '4':
						programs = R.filter(x => x.program_level.split('|').includes('4'))(
							getters.programsFilteredType,
						)
						break
					case '5':
						programs = R.filter(x => x.program_level.split('|').includes('5'))(
							getters.programsFilteredType,
						)
						break
					case '6':
						programs = R.filter(x => x.program_level.split('|').includes('6'))(
							getters.programsFilteredType,
						)
						break
					case '7':
						programs = R.filter(x => x.program_level.split('|').includes('7'))(
							getters.programsFilteredType,
						)
						break
				}
				break
			case 'topic':
				switch (state.subCategory) {
					case 'all':
						programs = getters.programsFilteredType
						break
					case i18n.t('일상생활'):
						programs = R.filter(x => x.topic === i18n.t('일상생활'))(
							getters.programsFilteredType,
						)
						break
					case i18n.t('비즈니스'):
						programs = R.filter(x => x.topic === i18n.t('비즈니스'))(
							getters.programsFilteredType,
						)
						break
					case i18n.t('시사토픽'):
						programs = R.filter(x => x.topic === i18n.t('시사토픽'))(
							getters.programsFilteredType,
						)
						break
					case i18n.t('시험대비'):
						programs = R.filter(x => x.topic === i18n.t('시험대비'))(
							getters.programsFilteredType,
						)
						break
				}
				break
		}
		return programs
	},

	selectedProgramId: state => state.selectedProgramId,
	selectedKeyword: state => state.selectedKeyword,
	selectedTags: state => state.selectedTags,
	selectedLevel: state => state.selectedLevel,

	sortedWithRecent: (state, getters, rootState, rootGetters) => {
		const rappointedProgramIds = rootGetters['appointed/programIds']

		const appointedPrograms = R.filter(
			x => rappointedProgramIds.includes(x.id),
			state.programs,
		)

		const notAppointedPrograms = R.filter(
			x => rappointedProgramIds.includes(x.id) === false,
			state.programs,
		)

		const recentPrograms = R.filter(
			x => getters.recentIds.includes(x.id),
			notAppointedPrograms,
		)

		const notRecentPrograms = R.filter(
			x => getters.recentIds.includes(x.id) === false,
			notAppointedPrograms,
		)

		let programs = notRecentPrograms
		if (recentPrograms.length > 0) programs = R.concat(recentPrograms, programs)
		if (appointedPrograms.length > 0)
			programs = R.concat(appointedPrograms, programs)
		return programs
	},
	filteredByType: (state, getters) => {
		return R.filter(
			program => program.lesson_type === state.selectedLessonType,
			getters.sortedWithRecent,
		)
	},
	filteredBySearch: (state, getters, rootState, rootGetters) => {
		const keyword = p =>
			state.selectedKeyword === '' || p.name.includes(state.selectedKeyword)
		const level = p =>
			state.selectedLevel === 0 ||
			p.program_level.split('|').includes(state.selectedLevel.toString())

		const tags = p => {
			const tags = p.tags.split('|')
			let isIncluded = false
			tags.forEach(tag => {
				if (state.selectedTags.includes(tag) === true) isIncluded = true
			})

			const isEmpty =
				Array.isArray(state.selectedTags) && state.selectedTags.length === 0

			return isEmpty === true || isIncluded === true
		}

		const language = p => {
			const nationality = rootGetters['member/nationality']
			const jaPrograms = [20, 103, 22, 68, 123, 74, 133, 140, 207]
			return (
				nationality === 'kr' ||
				(nationality === 'jp' && jaPrograms.includes(p.id))
			)
		}

		return R.pipe(
			R.filter(keyword),
			R.filter(level),
			R.filter(tags),
			R.filter(language),
		)(getters.filteredByType)
	},
	tags: (state, getters) => {
		const tagsArray = R.pipe(
			R.map(p => p.tags.split('|')),
			R.join(','),
			R.split(','),
			R.uniq(),
		)(getters.filteredByType)
		return tagsArray
	},
	progresses: state => state.progresses,
}
