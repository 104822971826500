<template>
	<div>
		<Wrap />
	</div>
</template>

<script>
// import store from '@/store'
import Wrap from '@/components/book/tutor-first/Wrap'

export default {
	components: {
		Wrap,
	},
	// beforeRouteEnter(to, from, next) {
	// 	const goTo = { name: to.name, query: to.query, params: to.params }

	// 	store.getters['member/kakaoId']
	// 		? next()
	// 		: next({ name: 'MoreInfo', query: { redir: JSON.stringify(goTo) } })
	// },
}
</script>

<style lang="scss" scoped></style>
