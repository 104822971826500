<template>
	<div class="box px-4 pt-4 pb-3" data-test="bookButton" @click="goToBook()">
		<h1 class="my-title font-16">
			<span v-if="type === 0">{{
				$t('page.home.bookLesson.bookButton.25분톡 예약')
			}}</span>
			<span v-else>{{
				$t('page.home.bookLesson.bookButton.15분콜 예약')
			}}</span>
			<img
				src="@/assets/images/icon/chevron-right-light.png"
				style="width: 18px; margin-top: -3px; vertical-align: middle"
				alt=""
			/>
		</h1>
		<p
			class="my-text font-14 mb-6"
			:class="{ 'primary--text': type === 0, 'emerald--text': type === 1 }"
		>
			{{ $t('page.home.bookLesson.bookButton.수강권') }}
			<span v-if="isLoading === false" data-test="remainingPoints">{{
				sumRemainingPoints
			}}</span>
		</p>
		<img
			style="vertical-align: bottom; width: 40px"
			:src="require(`@/assets/images/home/${type === 0 ? 'talk' : 'call'}.png`)"
			alt=""
		/>
	</div>
</template>

<script>
export default {
	props: {
		availablePoints: {
			required: true,
			type: Array,
		},
		type: {
			required: true,
			type: Number,
		},
		isLoading: {
			default: false,
			type: Boolean,
		},
	},
	data() {
		return {
			dialog: false,
		}
	},
	computed: {
		availableTypePoints() {
			return this.availablePoints.filter(p => p.type === this.type)
		},
		sumRemainingPoints() {
			return this.availableTypePoints.reduce((a, b) => a + b.remainingPoints, 0)
		},
	},
	methods: {
		goToBook() {
			if (this.sumRemainingPoints === 0) {
				this.$emit('noPlan')
				return
			}
			this.$emit('startBook', this.type === 0 ? 'talk' : 'call')

			// const bookType = this.type === 0 ? 'talk' : 'call'

			// this.$router.push({
			// 	name: 'BookProgram',
			// 	query: { 'lesson-type': bookType },
			// })
		},
	},
}
</script>

<style lang="scss" scoped>
.box {
	background-color: #fff;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
}
</style>
