<template>
	<div>
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('쿠폰') }}</h1>
				</v-col>
			</v-row>
		</section>
		<section class="pa-5">
			<div class="d-flex align-center">
				<h1 class="text-body-1">{{ $t('쿠폰을 가지고 계신가요?') }}</h1>
				<v-spacer></v-spacer>
				<Register @registered="get" />
			</div>
		</section>
		<section class="pt-3">
			<v-row no-gutters class="text-center">
				<v-col cols="6">
					<div
						@click="tab = 'available'"
						class="py-1 tab-btn"
						:class="{ active: tab === 'available' }"
					>
						{{ $t('보유') }}
					</div>
				</v-col>
				<v-col cols="6">
					<div
						@click="tab = 'used'"
						class="py-1 tab-btn"
						:class="{ active: tab === 'used' }"
					>
						{{ $t('사용/만료') }}
					</div>
				</v-col>
			</v-row>
			<v-divider></v-divider>
		</section>
		<section v-if="isLoading === false">
			<ListItem :coupon="coupon" v-for="coupon in filtered" :key="coupon.id" />
			<p
				v-if="filtered.length === 0"
				class="text-body-2 grey--text pt-10 text-center font-weight-medium"
			>
				{{ $t('보유하신 할인 쿠폰이 없습니다.') }}
			</p>
		</section>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import Api from '@/services/index'
import Register from './Register'
import ListItem from './ListItem'
// import Table from '@/components/coupon/Table'
// import Card from '@/components/coupon/Card'
const R = require('ramda')
// import { createNamespacedHelpers } from 'vuex'
// const { mapGetters, mapActions } = createNamespacedHelpers('coupon')

export default {
	components: {
		Register,
		ListItem,
		// Card,
		// Table,
	},
	data: () => ({
		tab: 'available',
		isLoading: false,
		coupons: [],
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		expiryDates() {
			return this.$store.getters['point/expiryDates']
		},
		paidPoints() {
			return this.$store.getters['point/paidPoints']
		},
		filtered() {
			return this.tab === 'available' ? this.available : this.unavailable
		},
		available() {
			const available = c =>
				c.used_date === null &&
				c.active === 1 &&
				c.expiration_date >= moment.tz('Asia/Seoul').format('YYYY-MM-DD')
			return R.filter(available, this.coupons)
		},
		unavailable() {
			const unavailable = c =>
				c.active === 1 &&
				(c.used_date !== null ||
					c.expiration_date < moment.tz('Asia/Seoul').format('YYYY-MM-DD'))
			return R.filter(unavailable, this.coupons)
		},
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			try {
				this.isLoading = true
				const res = await Promise.all([
					Api.get(`coupons?member-id=${this.memberId}`),
					this.$store.dispatch('point/get'),
				])
				this.coupons = res[0].data
				// if (this.paidPoints.length > 0) this.addRepurchaseCoupon()
				this.isLoading = false
			} catch (err) {
				alert(
					this.$t(
						'쿠폰을 불러오는 데 실패했습니다. 고객센터로 문의주세요.\nERROR CODE: G2OP11',
					),
				)
			}
		},
		// addRepurchaseCoupon() {
		// 	const expirationDate =
		// 		this.expiryDates.length > 0
		// 			? this.expiryDates[this.expiryDates.length - 1].expiryDate
		// 			: null

		// 	this.coupons.unshift({
		// 		active: 1,
		// 		coupon_id: 0,
		// 		coupon_no: '-',
		// 		coupon_type: this.$t('재구매할인 쿠폰'),
		// 		discount: 5,
		// 		discount_type: '%',
		// 		duration: null,
		// 		expiration_date: expirationDate,
		// 		id: null,
		// 		issued_date: null,
		// 		member_id: this.memberId,
		// 		used_date: null,
		// 	})
		// },
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			beforeName !== 'MyPage'
				? this.$router.push({ name: 'MyPage' })
				: this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped>
.tab-btn {
	color: #c4c4c4;
	box-sizing: border-box;
}
.tab-btn.active {
	color: #0073ff;
	font-weight: bold;
	border-bottom: 3px solid #0073ff;
}
</style>
