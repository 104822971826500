<template>
	<v-container style="max-width: 375px">
		<section class="pt-5 pb-7">
			<a href="https://tella.co.kr" @click="gtagEvent()">
				<img
					src="@/assets/images/logo/default.png"
					style="width: 50px"
					:alt="$t('img.alt.텔라 홈페이지')"
					class="mb-4"
				/>
			</a>
			<h1 class="text-h2 font-weight-bold white-space-pre mb-7">
				1:1 원어민 채팅 영어회화<br />
				지금 무료수업 신청하세요!
			</h1>

			<p class="mb-3 font-16 font-weight-bold d-flex align-center">
				<img
					src="@/assets/images/emoji/eyes.png"
					style="width: 20px"
					class="mr-1"
				/>
				25분 레벨 진단 수업 3컷으로 미리보기
			</p>
			<div
				style="border-radius: 5px; background-color: #f2f3f6; color: #485363"
				class="py-3 px-2 font-14 d-flex align-center"
			>
				<img
					src="@/assets/images/trials/kakao-logo.png"
					style="width: 20px"
					class="mr-1"
				/>
				수업은 별도 앱 설치 없이&nbsp;
				<span class="kakao-bold">카카오톡으로 진행</span>됩니다.
			</div>
			<swiper class="swiper mb-4" :options="swiperOption">
				<swiperSlide v-for="i in 3" :key="i">
					<div class="px-2 py-3">
						<img
							class="banner"
							style="width: 100%"
							:src="require(`@/assets/images/trials/test-c-${i}.png`)"
							alt=""
						/>
					</div>
				</swiperSlide>
			</swiper>
			<p class="mb-3 font-16 font-weight-bold d-flex align-center">
				<img
					src="@/assets/images/emoji/gift.png"
					style="width: 20px"
					class="mr-1"
				/>
				100% 드리는 3가지 영구 소장 혜택
			</p>
			<swiper class="swiper" :options="swiperOption">
				<swiperSlide v-for="i in 3" :key="i">
					<div class="px-2 py-3">
						<img
							class="banner"
							style="width: 100%"
							:src="require(`@/assets/images/trials/test-b-${i}.png`)"
							alt=""
						/>
					</div>
				</swiperSlide>
			</swiper>
		</section>
		<v-slide-y-transition v-if="selectedMoment === null">
			<TimePicker
				:timezone="timezone"
				style="max-width: 450px"
				@selectMoment="selectMoment"
				@selectTimezone="selectTimezone"
			/>
		</v-slide-y-transition>
		<section>
			<TrialForm
				:originPhone="phone"
				:originCountryCode="countryCode"
				:originName="name"
				:originTimezone="timezone"
				:selectedMoment="selectedMoment"
				:timezone="timezone"
				@selectMoment="selectMoment"
				@selectTimezone="selectTimezone"
			/>
		</section>
	</v-container>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import moment from 'moment-timezone'
import TimePicker from '../common/TimePicker'
import TrialForm from './Form'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
	components: { swiper, swiperSlide, TimePicker, TrialForm },
	data() {
		return {
			h1: this.$t('title.book-trial'),
			selectedMoment: null,
			timezone: null,
			swiperOption: {
				slidesPerView: 1.35,
				spaceBetween: 0,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			},
		}
	},
	computed: {
		phone() {
			return this.$route.query.phone || ''
		},
		name() {
			return this.$route.query.phone || ''
		},
		countryCode() {
			return this.$route.query['country-code'] || ''
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
	},
	created() {
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-3', {
			root: true,
		})
		if (this.$store.getters['member/nationality'] === 'jp')
			this.$router.push({
				name: 'TrialBookJP',
			})
		this.guessTimezone()
	},
	beforeRouteLeave() {
		this.$store.commit('device/SET_BACKGROUND', null, {
			root: true,
		})
	},
	methods: {
		gtagEvent() {
			// eslint-disable-next-line no-undef
			gtag('event', 'form_to_home')
		},
		selectMoment(m) {
			this.selectedMoment = m
		},
		guessTimezone() {
			if (this.memberId) {
				this.timezone = this.$store.getters['member/timezone']
				return
			}

			const storageTimezone = localStorage.getItem('_timezone')
			if (storageTimezone) {
				this.timezone = localStorage.getItem('_timezone')
				return
			}

			this.timezone = moment.tz.guess()
		},
		selectTimezone(timezone) {
			this.timezone = timezone
		},
	},
}
</script>

<style lang="scss" scoped>
.benefit-box {
	background: #ffffff;
	border: 1px solid #0074ff;
	border-radius: 10px;
}
.benefit-title {
	background: #f2f9ff;
	border-radius: 5px;
	padding: 5px 7px;
	color: #0074ff;
	margin-right: 3px;
}
.banner {
	border-radius: 15px;
	box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.0949);
}

.kakao-bold {
	position: relative;
	font-weight: 700;
	z-index: 3;
}
.kakao-bold::before {
	z-index: -1;
	position: absolute;
	left: 0px;
	bottom: 0px;
	height: 9px;
	width: 100%;
	content: '';
	background-color: #fae301;
}
</style>
