import Api from '@/services/index'
import axios from 'axios'
import {
	STORE,
	INITIALIZE,
	SET_NATIONALITY,
	SET_LANGUAGE,
	SET_IPINFO,
} from './mutations'

export default {
	get: async ({ commit, rootGetters }) => {
		commit(INITIALIZE)
		// dispatch('stopTime')
		const memberId = rootGetters['auth/memberId']
		const res = await Api.get(`/members/${memberId}`)
		commit(STORE, res.data)
	},
	getCountryByIp: async ({ commit }, query) => {
		let country = 'kr'
		// let url = '/members/country-by-ip'

		try {
			const result = await axios.get(
				// `https://ipinfo.io/1.112.0.0?token=4262ba3bfbc13e`,
				`https://ipinfo.io?token=4262ba3bfbc13e`,
			)
			commit(SET_IPINFO, result.data)
			// const res = await Api.get(url)
			if (result.data.country === 'JP') {
				country = 'jp'
			}
			if (query['forced-nationality'] === 'jp') country = 'jp'
			if (query['forced-nationality'] === 'kr') country = 'kr'
		} catch (err) {
			throw new Error(err)
		} finally {
			commit(SET_NATIONALITY, country)
			if (country === 'jp') {
				commit(SET_LANGUAGE, 'ja')
			}
		}
	},
}
