<template>
	<div style="position: relative">
		<v-fade-transition>
			<div
				class="font-weight-bold text-caption text-left tooltip white-space-pre"
				:style="language === 'ja' ? 'width: 228px;' : 'width: 145px;'"
			>
				{{ $t('review.post-button.tooltip') }}&nbsp;
				<img src="@/assets/images/reviews/icon-party.png" />

				<div class="chat-tail"></div>
			</div>
		</v-fade-transition>
		<!-- @click="showTooltip = true" -->
		<div class="grey lighten-3 py-5 px-3 d-flex align-center white-space-pre">
			<div
				class="text-nlh text-body-1"
				v-html="$t('review.post-button.text', { name })"
			></div>
			<v-spacer></v-spacer>
			<v-btn
				depressed
				color="primary"
				large
				@click="$router.push({ name: 'ReviewWrite' })"
				><strong class="font-weight-bold text-body-1">{{
					$t('review.post-button')
				}}</strong></v-btn
			>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		name() {
			return this.$store.getters['member/nameKr']
		},
		language() {
			return this.$store.getters['member/language']
		},
	},
}
</script>

<style lang="scss" scoped>
.tooltip {
	z-index: 3;
	color: #fff;
	line-height: 1.3em;
	border-radius: 5px;
	position: absolute;
	top: -20px;
	right: 15px;
	padding: 10px 0px 10px 10px;
	object-fit: contain;
	background: linear-gradient(90.19deg, #02c8bd 0.15%, #038eeb 99.83%);

	.chat-tail {
		width: 0;
		height: 0;
		position: absolute;
		bottom: -5px;
		right: 45px;
		margin-left: 2.5px;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;

		border-top: 5px solid #038eeb;
	}
}
</style>
