var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-bottom-sheet',{attrs:{"max-width":"400","inset":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('img',{staticStyle:{"width":"20px","vertical-align":"text-bottom"},attrs:{"src":require("@/assets/images/trials/ic-notice.svg"),"alt":_vm.$t('how-to-check-kakao-id.image-alt.way-to-check-kakao-id'),"srcset":""}}),(_vm.text)?_c('p',{staticClass:"ml-1 grey--text text--darken-1 text-body-2"},[_vm._v(" "+_vm._s(_vm.$t('how-to-check-kakao-id.text-button.how-to-check-kakao-id'))+" ")]):_vm._e()])]}}]),model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"pt-7 text-center grey lighten-3",staticStyle:{"border-top-left-radius":"32px","border-top-right-radius":"32px","position":"relative"}},[_c('h1',{staticClass:"mb-3 text-body-1 font-weight-bold",domProps:{"innerHTML":_vm._s(
				_vm.$t(
					'how-to-check-kakao-id.title.you-can-check-your-kakao-id-like-this'
				)
			)}}),_c('v-window',{model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((3),function(n){return _c('v-window-item',{key:("card-" + n)},[_c('v-card',{attrs:{"color":"grey lighten-3","min-height":"350","max-height":"370"}},[_c('v-img',{staticClass:"mx-auto",attrs:{"max-width":"340","src":require(("@/assets/images/trials/kakao_" + n + "@1x.png"))}})],1)],1)}),1),_c('v-card-actions',{staticClass:"pt-3 justify-center",attrs:{"id":"kakao-info"}},[_c('v-item-group',{staticClass:"text-center",attrs:{"mandatory":""},model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((3),function(n){return _c('v-item',{key:("btn-" + n),scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var active = ref.active;
			var toggle = ref.toggle;
return [_c('div',{staticClass:"d-inline-block pagination-dot mx-1 cursor-pointer",attrs:{"input-value":active,"icon":""},on:{"click":toggle}})]}}],null,true)})}),1)],1),_c('v-btn',{staticStyle:{"border-radius":"0px !important"},attrs:{"block":"","depressed":"","x-large":"","color":"primary"},on:{"click":function($event){return _vm.check()}}},[_vm._v(_vm._s(_vm.$t('how-to-check-kakao-id.button.confirm')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }