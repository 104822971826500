<template>
	<div>
		<router-view v-if="isLoading === false"></router-view>
	</div>
</template>

<script>
const R = require('ramda')

/**
 * 프로그램 전체 불러오기(App 1번)
 * 프로그래스 group-by 프로그램별(페이지별 1번)
 * 미래(appointed) 수업 불러오기(페이지별 1번)
 */
export default {
	data: () => ({
		isLoading: false,
	}),
	computed: {
		lessonId() {
			return Number(this.$route.params.lessonId)
		},
	},
	created() {
		this.get()
	},
	methods: {
		// ...mapMutations(['STORE_LESSON_TYPE']),
		async get() {
			this.isLoading = true
			await this.$store.dispatch('appointed/get')

			const lesson = R.find(R.propEq('id', this.lessonId))(
				this.$store.getters['appointed/lessons'],
			)
			if (!lesson) {
				alert('수업을 찾을 수 없습니다.')
				this.$router.push({ name: 'Home' })
			}

			// this.STORE_LESSON_TYPE(lesson.lesson_type)

			this.isLoading = false
		},
	},
}
</script>
<style lang="scss" scoped></style>
