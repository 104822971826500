<template>
	<div style="max-width: 375px" class="mx-auto">
		<div class="pt-2">
			<v-btn icon text @click="goBackConfirmation"
				><v-icon>mdi-arrow-left</v-icon></v-btn
			>
		</div>
		<v-container v-if="trial && isLoading === false">
			<section class="pb-10">
				<img
					src="@/assets/images/logo/default.png"
					style="width: 50px"
					:alt="$t('img.alt.텔라 홈페이지')"
					class="mb-4"
				/>
				<h1
					style="line-height: 35px !important"
					class="text-h2 font-weight-bold white-space-pre"
				>
					{{ $t('trial-reschedule.title') }}
				</h1>
			</section>

			<Form
				:id="trial.id"
				:originName="trial.name || $t('trial-reschedule.alternative-name')"
				:originPhone="trial.phone"
				:originCountryCode="trial.country_code.toString()"
				:originSelectedMoment="trial.moment"
				:originTimezone="trial.timezone"
				:originEmail="trial.email"
				:nationality="trial.nationality"
				@updated="get"
				class="mb-5"
			/>
			<!-- <CancelButton :trial="trial" :memberId="memberId" /> -->
		</v-container>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="true"
			:numberOfButton="1"
			:title="errTitle"
			:text="errMsg"
			:actionColor="'primary'"
			:action="errAction"
			:actionText="$t('modal.button.go-back')"
		/>
		<!-- <NotTimeForChange :dialogControl="notTimeForChangeError" />
		<StatusNotMatch :dialogControl="statusNotMatchedError" />
		<NotAvailable :dialogControl="notAvailableError" /> -->
	</div>
</template>

<script>
import { codeToMoment } from '@/helpers/date-format'
import Api from '@/services/index'
import Form from './Form'
// import CancelButton from './CancelButton'
import Dialog from '@/components/common/Dialog'
import moment from 'moment-timezone'

export default {
	components: {
		Form,
		// CancelButton,
		Dialog,
	},
	data() {
		return {
			isLoading: false,
			trial: null,
			errorDialog: false,
			errTitle: this.$t('modal.title.can`t-find-trial'),
			errMsg: '',
			errAction: this.goBackConfirmation,
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		trialId() {
			return this.$route.params.trialId
		},
		countryCode() {
			return this.$route.query['country-code']
		},
		phone() {
			return this.$route.query.phone
		},
		isAccessible() {
			return !this.trialId || !this.countryCode || !this.phone ? false : true
		},
	},

	created() {
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-3', {
			root: true,
		})
		if (this.isAccessible === false) {
			this.$router.push({ name: 'Trial' })
		}
		this.get()
	},
	beforeRouteLeave() {
		this.$store.commit('device/SET_BACKGROUND', null, {
			root: true,
		})
	},
	methods: {
		goBackConfirmation() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			if (!beforeName) {
				this.$router.push({
					name: 'TrialConfirmation',
					params: { trialId: this.trialId },
					query: { phone: this.phone, 'country-code': this.countryCode },
				})
				return
			}

			this.$router.go(-1)
		},
		goBackBook() {
			this.$router.push({
				name: 'TrialBook',
			})
		},
		async get() {
			try {
				this.dialog = false
				this.isLoading = true
				const res = await Api.get(
					`trials?id=${this.trialId}&country-code=${this.countryCode}&phone=${this.phone}`,
				)
				if (res.data.length === 0) throw new Error('No Trials')
				this.trial = res.data[0]
				if (this.trial.status !== 'appointed')
					throw new Error('Status Not Matched')
				this.trial.timezone = this.trial.timezone || 'Asia/Seoul'
				this.trial.moment = codeToMoment(
					this.trial.lesson_date,
					this.trial.lesson_time,
					this.$i18n.locale,
				).tz(this.trial.timezone)
				this.isPossibleToChange()

				this.isLoading = false
			} catch (err) {
				this.errorHandler(err.message)
				return
			}
		},
		errorHandler(errMsg) {
			switch (errMsg) {
				case 'Not Time':
					this.errTitle = this.$t('modal.title.trial-edit-only-in-60-minutes')
					this.errMsg = ''
					this.errAction = this.goBackConfirmation
					break
				case 'Status Not Matched':
					this.errTitle = this.$t(
						'modal.title.trial-reschedule-already-passed-or-cancelled',
					)

					this.errMsg = ''
					this.errAction = this.goBackBook
					break
				case 'No Trials':
					this.errTitle = this.$t('modal.title.can`t-find-trial')
					this.errMsg = ''
					this.errAction = this.goBackBook
					break
			}
			this.errorDialog = true
		},
		isPossibleToChange() {
			if (this.trial.moment < moment().add(60, 'minutes')) {
				throw new Error('Not Time')
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
