<template>
	<div class="grey lighten-4">
		<section class="fixed-bar-wrap">
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('튜터 선택') }}</h1>
				</v-col>
				<!-- <v-col cols="2" class="text-right">
					<v-btn fab icon text small>
						<v-icon>mdi-help-circle-outline</v-icon>
					</v-btn>
				</v-col> -->
			</v-row>
			<v-progress-linear :value="progress" class="white"></v-progress-linear>
			<TutorCategoryPicker
				class="grey lighten-4 pb-1 pt-3 px-5"
				v-if="isLoading === false"
			/>
		</section>
		<section class="px-5 pt-16 mt-8" v-if="isLoading === false">
			<!-- <div style="height: calc(var(--vh, 1vh) * 100 - 120px); overflow-y: auto"> -->
			<TutorPicker />
			<!-- </div> -->
		</section>
		<v-bottom-sheet v-model="sheet" max-width="400">
			<v-sheet height="90vh" class="mx-auto rounded-t-xl elevation-0">
				<div class="px-5 pt-5 d-flex align-center">
					<h1 class="text-left text-body-1 font-weight-bold">
						{{ $t('시간을 선택하세요.') }}
					</h1>
					<v-spacer></v-spacer>
					<v-btn icon @click="sheet = false"
						><v-icon>mdi-chevron-down</v-icon></v-btn
					>
				</div>

				<div class="py-5 px-8 d-flex align-center" v-if="selectedTutor">
					<TutorAvatar
						:size="25"
						:id="selectedTutor.id"
						:name="selectedTutor.name"
						:type="selectedType"
					/>
					<p class="text-body-2 ml-2">{{ selectedTutor.name_en }}</p>
				</div>
				<section class="d-flex align-center pb-5 px-5">
					<p class="text-body-2">
						{{ timezone }}
					</p>
					<v-spacer></v-spacer>
					<p
						@click="$router.push({ name: 'BookTimezone' }).catch(() => {})"
						class="hover-pointer text-body-2"
					>
						{{ $t('타임존 변경')
						}}<v-icon class="pb-1">mdi-chevron-right</v-icon>
					</p>
				</section>
				<section
					class="px-5"
					style="overflow-y: auto; height: calc(100% - 220px)"
				>
					<TimePicker />
				</section>
				<section class="white fixed-button-wrap">
					<BookButton :isLoading="isLoading" />
				</section>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
import TutorPicker from '../common/TutorPicker'
import TutorCategoryPicker from '../common/TutorCategoryPicker'
import TutorAvatar from '@/components/common/TutorAvatar'
import TimePicker from './TimePicker'
import BookButton from '../common/BookButton'

import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('book')

export default {
	components: {
		TutorCategoryPicker,
		TutorPicker,
		TutorAvatar,
		TimePicker,
		BookButton,
	},
	data: () => ({
		isLoading: false,
		sheet: false,
	}),
	computed: {
		progress() {
			return this.selectedTutor ? 66.7 : 33.3
		},
		...mapGetters(['selectedType', 'isBookable', 'selectedTutor']),
		typeEn() {
			return this.$route.params.typeEn
		},
		timezone() {
			return this.$store.getters['member/timezone']
		},
	},
	watch: {
		selectedTutor(val) {
			if (val) this.sheet = true
		},
		sheet(val) {
			if (val === false) this.SELECT_TUTOR(null)
		},
	},
	created() {
		this.setReady()
	},
	destroyed() {
		this.$store.commit('book/SET_INITIAL')
	},
	methods: {
		...mapMutations(['SELECT_TUTOR', 'SELECT_TYPE', 'SET_INITIAL']),
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			const accessDeniedPages = [
				null,
				'BookTimezone',
				'TimeFirstStep2',
				'LessonProgramLanding',
				'LessonProgramBooked',
			]
			accessDeniedPages.includes(beforeName)
				? this.$router.push({ name: 'Home' })
				: this.$router.go(-1)
		},

		async setReady() {
			try {
				this.isLoading = true
				this.SET_INITIAL()

				if (this.typeEn !== 'talk' && this.typeEn !== 'call') {
					this.accessDenied('type')
					return
				}

				this.SELECT_TYPE(this.typeEn === 'talk' ? 0 : 1)

				await Promise.all([
					this.$store.dispatch('appointed/get', false),
					this.$store.dispatch('point/get'),
				])

				if (this.isBookable !== true) {
					this.accessDenied(this.isBookable)
					return
				}
				this.isLoading = false
			} catch (err) {
				this.accessDenied()
			}
		},
		accessDenied(reason) {
			let msg = this.$t('에러가 발생했습니다.')
			switch (reason) {
				case 'lack of points':
					msg = this.$t(`현재 사용가능한 %{passName}수강권이 없습니다.`, {
						passName: this.typeEn === 0 ? this.$t('25분톡') : this.$t('15분콜'),
					})
					break
				case 'lack of lesson slots':
					msg = this.$t(
						'예약 가능한 수업을 모두 예약 하셨습니다! 수업을 완료한 뒤에 다시 예약하세요!\n텔라의 수업은 톡과 콜 각각 10회까지 동시에 예약이 가능합니다.',
					)
					break
				case 'type':
					msg = this.$t('수업 예약을 위해서는 타입을 선택하셔야합니다.')
					break
			}

			alert(msg)
			this.$router.push({ name: 'Home' })
		},
	},
}
</script>

<style lang="scss" scoped></style>
