<template>
	<div class="pt-15" v-if="isLoading === false">
		<section
			style="position: fixed; top: 0; max-width: 400px; z-index: 3; width: 100%"
		>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('프로그램 선택') }}</h1>
				</v-col>
				<!-- <v-col cols="2" class="text-right">
					<v-btn fab icon text small>
						<v-icon>mdi-help-circle-outline</v-icon>
					</v-btn>
				</v-col> -->
			</v-row>
			<v-progress-linear :value="progress"></v-progress-linear>
		</section>
		<section class="pt-5 px-5 pb-3">
			<SelectedCard :lesson="lesson" />
		</section>
		<section class="px-5 pt-5 pb-1">
			<SearchInput :lessonId="lessonId" />
		</section>
		<section
			class="px-5 py-3"
			style="position: -webkit-sticky; position: sticky; top: 60px; z-index: 2"
		>
			<ProgramCategory />
		</section>
		<section class="px-5 py-3">
			<ProgramCard
				v-for="program in programsInCategory"
				:key="program.id"
				:program="program"
				:selectedProgramId="selectedProgram ? selectedProgram.id : null"
				@selectProgram="selectProgram"
				class="mb-3"
			/>
		</section>

		<UnitPicker
			:lesson="lesson"
			@selectUnit="selectUnit"
			:selectedProgram="selectedProgram"
		/>
	</div>
</template>

<script>
// import TutorAvatar from '@/components/common/TutorAvatar'
import SelectedCard from '../common/SelectedCard'
import ProgramCard from '../common/ProgramCard'
import UnitPicker from '../common/UnitPicker'
import ProgramCategory from './ProgramCategoryPicker'
import SearchInput from './SearchInput'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions, mapMutations } =
	createNamespacedHelpers('program')
const R = require('ramda')

/**
 * 프로그램 전체 불러오기(App 1번)
 * 프로그래스 group-by 프로그램별(페이지별 1번)
 * 미래(appointed) 수업 불러오기(페이지별 1번)
 */
export default {
	components: {
		SelectedCard,
		SearchInput,
		// TutorAvatar,
		ProgramCategory,
		ProgramCard,
		UnitPicker,
	},
	data: () => ({
		dialog: false,
		isLoading: false,
		progress: 100,
		selectedProgram: null,
	}),
	computed: {
		...mapGetters(['programs', 'programsInCategory']),
		lessonId() {
			return Number(this.$route.params.lessonId)
		},
		lesson() {
			return R.find(R.propEq('id', this.lessonId))(
				this.$store.getters['appointed/lessons'],
			)
		},
		language() {
			return this.$store.getters['member/language']
		},
		programImgUrl() {
			return this.selectedProgram
				? `https://image.tella.co.kr/v1/program/thumbnail/portrait/${this.language}/${this.selectedProgram.id}.jpg`
				: ''
		},
	},
	created() {
		this.getAll()
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-3', {
			root: true,
		})
	},
	beforeRouteLeave(to, from, next) {
		this.$store.commit('device/SET_BACKGROUND', null, {
			root: true,
		})
		next()
	},
	methods: {
		...mapMutations(['STORE_LESSON_TYPE']),
		...mapActions(['get', 'getProgresses']),
		async getAll() {
			this.isLoading = true
			let promises = [this.getProgresses()]
			if (this.programs.length === 0) promises.push(this.get())
			if (this.$store.getters['group/groups'] === null)
				promises.push(this.$store.dispatch('group/get'))
			await Promise.all(promises)

			this.STORE_LESSON_TYPE(this.lesson.lesson_type)

			this.isLoading = false
		},

		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			const accessDeniedPages = [null, 'LessonProgramBooked']
			accessDeniedPages.includes(beforeName)
				? this.$router.push({ name: 'Home' })
				: this.$router.go(-1)
		},
		selectProgram(program) {
			this.selectedProgram = program
		},
		selectUnit(unit) {
			if (unit === null) {
				this.selectedProgram = null
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
