<template>
	<div v-if="isAvailable === true">
		<section class="fixed-bar-wrap">
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('이용후기 작성') }}</h1>
				</v-col>
			</v-row>
		</section>
		<section class="px-5" style="padding-top: 60px">
			<v-text-field
				outlined
				:label="$t('제목을 입력해주세요.')"
				v-model="title"
				single-line
				hide-details
			></v-text-field>

			<div style="height: 400px" class="mt-3">
				<quill-editor
					v-model="content"
					ref="myQuillEditor"
					:options="editorOption"
				></quill-editor>
				<!-- @blur="onEditorBlur($event)"
					@focus="onEditorFocus($event)"
					@ready="onEditorReady($event)" -->
			</div>
		</section>
		<div class="fixed-button-wrap">
			<v-btn
				block
				tile
				height="56"
				:disabled="validation === false"
				:loading="isLoading"
				depressed
				color="primary"
				@click="postReview"
				>{{ $t('이용후기 작성') }}</v-btn
			>
		</div>
	</div>
</template>

<script>
// import membersApi from '@/api/members'
// import boardListsApi from '@/api/board-lists'
import Api from '@/services/index'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
	components: {
		quillEditor,
	},
	data() {
		return {
			isLoading: false,
			isAvailable: false,
			title: null,
			content: null,
			editorOption: {
				placeholder: this.$t(
					'생생한 이용후기를 남기시면 3000캐시를 드립니다. \n월별로 베스트 리뷰어를 선정하여 5,000 캐시를 더 드려요!(선정 후 개별연락) \n \nTELLA팀 일동',
				),
				modules: {
					toolbar: [
						['bold', 'italic', 'underline', 'strike'],
						[{ font: [] }],
						[{ header: [1, 2, 3, 4, 5, 6, false] }],
						[{ color: [] }, { background: [] }],
						[{ list: 'ordered' }, { list: 'bullet' }],
						[{ align: [] }],
						// ['link'],
						// ['blockquote'],
						// [{ 'header': 1 }, { 'header': 2 }],
						// [{ 'script': 'sub' }, { 'script': 'super' }],
						// [{ 'indent': '-1' }, { 'indent': '+1' }],
						// [{ 'direction': 'rtl' }],
						// [{ 'size': ['small', false, 'large', 'huge'] }],
						// ['clean'],
					],
				},
			},
			alreadyDoneMsg: this.$t(
				'이미 작성된 이용후기가 있습니다. 수강권 구매 건 당 이용후기는 한 번만 작성이 가능합니다.',
			),
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		language() {
			return this.$store.getters['member/language']
		},
		validation() {
			return !!(this.title && this.content)
		},
	},
	created() {
		this.getAvailable()
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			beforeName !== 'MyPage' &&
			beforeName !== 'Home' &&
			beforeName !== 'Plans' &&
			beforeName !== 'Review'
				? this.$router.push({ name: 'MyPage' })
				: this.$router.go(-1)
		},
		async getAvailable() {
			this.isAvailable = false
			const result = await Promise.all([
				Api.get(
					`payments?member-id=${this.memberId}&status=paid&sort-by=-created_at`,
				),
				Api.get(
					`boards?board-type-id=4&member-id=${this.memberId}&sort-by=-created_at`,
				),
			])
			const payments = result[0].data
			const reviews = result[1].data

			this.isAvailable = true
			if (payments.length > 0 && payments.length > reviews.length) {
				this.isAvailable = true
			} else {
				alert(this.alreadyDoneMsg)
				this.goBack()
			}
		},
		// onEditorBlur(quill) {
		// 	console.log('editor blur!', quill)
		// },
		// onEditorFocus(quill) {
		// 	console.log('editor focus!', quill)
		// },
		// onEditorReady(quill) {
		// 	console.log('editor ready!', quill)
		// },
		onEditorChange({ /*quill*/ html /*text*/ }) {
			// console.log('editor change!', quill, html, text)
			this.content = html
		},
		async postReview() {
			try {
				this.isLoading = true
				await Api.post('boards/review', {
					memberId: this.memberId,
					subject: this.title,
					content: this.content,
					language: this.language,
				})
				alert(this.$t('소중한 이용후기 감사합니다 :)'))
				this.$router.push({ name: 'Cash' })
				this.isLoading = false
			} catch (err) {
				let errMsg = this.$t(
					'알 수 없는 에러가 발생했습니다. 고객센터로 문의주세요.\nERROR CODE: BV910S',
				)
				if (err.response.status === 400) {
					const code = err.response.data.code.toString()
					if (code === '90000') errMsg = this.alreadyDoneMsg
				}
				alert(errMsg)
				this.goBack()
			}
		},
	},
}
</script>

<style scoped>
.quill-editor {
	height: calc(var(--vh, 1vh) * 100 - 260px);
}
</style>
