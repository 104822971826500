<template>
	<div style="min-height: 650px">
		<v-app-bar class="pt-4" color="white" height="59" elevation="0">
			<a href="https://tella.co.kr">
				<img
					src="@/assets/images/logo/default.png"
					style="width: 50px"
					:alt="$t('img.alt.텔라 홈페이지')"
					class="mb-6"
				/>
			</a>
		</v-app-bar>
		<v-divider></v-divider>
		<v-container style="padding-bottom: 60px">
			<h1 class="pt-4 pb-7 text-h1 font-weight-bold">
				{{ $t('sign-up.title.sign-up') }}
			</h1>
			<KakaoButton :isLoading="isLoading" @loading="loading" />
			<FacebookButton :isLoading="isLoading" @loading="loading" />
			<div class="mt-5 text-center">
				<v-btn
					plain
					:disabled="isLoading"
					@click="goToSignUpByEmail"
					text
					color="primary"
					><p class="text-body-1">
						{{ $t('sign-up.button.sign-up-with-email') }}
					</p>
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</div>
		</v-container>
		<div
			class="text-body-2 text-center"
			style="
				position: fixed;
				bottom: 0;
				width: 100%;
				max-width: 400px;
				height: 60px;
				padding-top: 22px;
				border-top: 1px solid #f0f0f0;
			"
		>
			{{
				$t('sign-up.text.already-have-tella-account?')
			}}&nbsp;&nbsp;&nbsp;&nbsp;<a
				href="#"
				@click="$router.push({ name: 'SignIn' })"
				>{{ $t('common.button.sign-in') }}</a
			>
		</div>
		<v-dialog v-model="policyDialog" width="500" content-class="rounded-lg">
			<v-card rounded="lg" class="py-3 px-4">
				<div class="pt-2 pb-3">
					<h1 class="modal-h1 font-weight-bold">
						{{ $t('sign-up.policy-modal.title.policy') }}
					</h1>
				</div>
				<div class="py-2" style="height: 400px; overflow-y: auto">
					<Policy class="white-space-pre" />
				</div>
				<v-row no-gutters class="py-3">
					<v-btn color="primary" depressed block @click="policyDialog = false">
						{{ $t('sign-up.policy-modal.button.confirm') }}
					</v-btn>
				</v-row>
			</v-card>
		</v-dialog>
		<v-dialog v-model="privacyDialog" width="500" content-class="rounded-lg">
			<v-card rounded="lg" class="py-3 px-4">
				<div class="pt-2 pb-3">
					<h1 class="modal-h1 font-weight-bold">
						{{ $t('sign-up.pravacy-modal.title.pravacy') }}
					</h1>
				</div>
				<div class="py-2" style="height: 400px; overflow-y: auto">
					<Privacy class="white-space-pre" />
				</div>
				<v-row no-gutters class="py-3">
					<v-btn color="primary" depressed block @click="privacyDialog = false">
						{{ $t('sign-up.pravacy-modal.button.confirm') }}
					</v-btn>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Policy from '@/components/common/Policy'
import Privacy from '@/components/common/Privacy'
import KakaoButton from './KakaoButton'
import FacebookButton from './FacebookButton'

export default {
	components: {
		Policy,
		Privacy,
		KakaoButton,
		FacebookButton,
	},
	data() {
		return {
			isLoading: false,
			policyDialog: false,
			privacyDialog: false,
		}
	},
	methods: {
		goToSignUpByEmail() {
			this.gtagEvent('email')
			this.$router.push({ name: 'SignUpForm' })
		},
		loading(boolean) {
			this.isLoading = boolean
		},

		gtagEvent(category) {
			const params = {
				event_path: `my.tella.co.kr${this.$route.path}`,
				event_category: category,
			}
			// eslint-disable-next-line no-undef
			gtag('event', 'try_signup', params)
		},
	},
}
</script>

<style lang="scss" scoped></style>
