<template>
	<div>
		<v-expansion-panels flat>
			<v-expansion-panel>
				<v-expansion-panel-header>
					<h1 class="text-body-1 font-weight-medium">
						{{ $t('order.precautions.title') }}
					</h1>
				</v-expansion-panel-header>
				<v-expansion-panel-content
					class="text-body-2 grey--text text--darken-3"
				>
					<strong>{{ $t('order.instructions-for-use.title') }}</strong
					><br />
					•
					{{ $t('order.instructions-for-use.text1') }}
					<br />
					•
					{{ $t('order.instructions-for-use.text2') }}<br />
					•
					{{ $t('order.instructions-for-use.text3') }}
					<br />
					<br />
					•
					{{ $t('order.instructions-for-use.text4') }}<br />
					[{{ $t('order.instructions-for-use.example.title') }}]<br />
					-
					{{ $t('order.instructions-for-use.example1') }}<br />
					-
					{{ $t('order.instructions-for-use.example2') }}
					<br />
					-
					{{ $t('order.instructions-for-use.example3') }}<br />
					-
					{{ $t('order.instructions-for-use.example4') }}<br />
					-
					{{ $t('order.instructions-for-use.example5') }}<br />
					<br />
					•
					{{ $t('order.instructions-for-use.text5') }}<br />
					•
					{{ $t('order.instructions-for-use.text6') }}<br />
					<br />
					<strong>{{ $t('order.refund-regulation.title') }}</strong
					><br />
					•
					{{ $t('order.refund-regulation.text1') }}
					<br />
					<br />
					• {{ $t('order.refund-regulation.text2') }} <br />
					-
					{{ $t('order.refund-regulation.text2-1') }}<br />
					-
					{{ $t('order.refund-regulation.text2-2') }}<br />
					<br />
					•
					{{ $t('order.refund-regulation.text3') }}<br />
					-
					{{ $t('order.refund-regulation.text3-1') }}
					<br />
					-
					{{ $t('order.refund-regulation.text3-2') }}
					<br />
					-
					{{ $t('order.refund-regulation.text3-3') }}
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-divider class="mb-6"></v-divider>
		<div class="px-3 pb-7">
			<v-btn
				:disabled="isLoading"
				block
				color="primary"
				depressed
				large
				@click="insertPayment"
				>{{
					$t('order.button.pay', {
						price: $numberWithCommas(payPrice),
						currencyString,
					})
				}}</v-btn
			>
		</div>
	</div>
</template>

<script>
import Api from '@/services/index'
import moment from 'moment-timezone'
import SlackMsg from '@/services/slack'
const IMP = window.IMP // 생략해도 괜찮습니다.
IMP.init('imp37711618') // "imp00000000" 대신 발급받은 "가맹점 식별코드"를 사용합니다.

export default {
	props: {
		plan: {
			required: true,
			type: Object,
		},
		payPrice: {
			required: true,
			type: Number,
		},
		couponMemberId: {
			type: Number,
		},
		bonusCash: {
			required: true,
			type: Number,
		},
		currencyString: {
			required: true,
			type: String,
		},
		planId: {
			required: true,
			type: [Number, String],
		},
		startDate: {
			required: true,
			type: String,
		},
		currency: {
			required: true,
			type: String,
		},
		orderTitle: {
			type: String,
		},
		payMethod: {
			type: String,
		},
		recommenderEmail: {
			type: String,
		},
	},
	data() {
		return {
			isLoading: false,
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		email() {
			return this.$store.getters['member/email']
		},
		phone() {
			return this.$store.getters['member/phone']
		},
		name() {
			return this.$store.getters['member/nameKr']
		},
		pg() {
			/*
        카카오페이 일때를 제외하고 html_inicis 호출
        todo: 페이팔 추가
      */
			return this.payMethod === 'kakaopay'
				? 'kakaopay'
				: this.payMethod === 'paypal'
				? 'paypal'
				: 'html5_inicis'
		},
		pgPayMethod() {
			/* 실시간계좌이체 일때는 trans, 가상계좌입금은 vbank 나머지는 'card' */
			return this.payMethod === 'trans'
				? 'trans'
				: this.payMethod === 'vbank'
				? 'vbank'
				: 'card'
		},
	},
	methods: {
		trackingBeginCheckout(paymentId) {
			const category =
				this.plan.talk_points > 0 && this.plan.call_points > 0
					? 'package'
					: this.plan.call_points > 0
					? 'call'
					: 'talk'

			const price = this.plan.real_call_price + this.plan.real_talk_price
			this.$conversionBeginCheckout(paymentId, [
				{
					id: this.plan.id,
					name: this.plan.order_title,
					category,
					price,
				},
			])
		},
		trackingPurchase(paymentId) {
			const category =
				this.plan.talk_points > 0 && this.plan.call_points > 0
					? 'package'
					: this.plan.call_points > 0
					? 'call'
					: 'talk'

			const price = this.plan.real_call_price + this.plan.real_talk_price
			// eslint-disable-next-line no-undef
			this.$conversionPurchase(paymentId, this.payPrice, this.currency, [
				{
					id: this.plan.id,
					name: this.plan.order_title,
					category,
					price,
				},
			])
		},
		/**
		 * 페이먼트 입력
		 * 포인트 리스트 생성,
		 * 할인 정보 생성,
		 * 추천인 및 시크릿 코드 생성,
		 */
		async insertPayment() {
			if (!this.startDate || this.startDate.length === 0) {
				this.$emit('startDateError')
				this.$vuetify.goTo(350)
				return
			}
			this.isLoading = true
			let paymentId
			try {
				// this.gaCheckout()
				let paymentInfo = {
					reApply: this.couponMemberId === -1 ? 1 : 0,
					memberId: this.memberId,
					couponMemberId:
						this.couponMemberId && this.couponMemberId > 0
							? this.couponMemberId
							: null,
					bonusCash: this.bonusCash,
					recommenderEmail: this.recommenderEmail,
					planId: this.planId,
					payPrice: this.payPrice,
					startDate: this.startDate,
					currency: this.currency,
				}
				// 결제 디비와 포인트(inactive 상태)를 생성한다.
				const result = await Api.post('/payments', paymentInfo)
				paymentId = result.data

				this.requestPay(paymentId)
			} catch (err) {
				this.isLoading = false
				SlackMsg.post('', { text: JSON.stringify(err.stack.substring(0, 500)) })
				alert(this.$t('order.error.fail-to-insert'))
			} finally {
				if (paymentId) this.trackingBeginCheckout(paymentId)
			}
		},
		requestPay(paymentId) {
			let merchantUid = 'payment_' + paymentId

			let params = {
				// param
				pg: this.pg,
				pay_method: this.pgPayMethod,
				currency: this.currency,
				merchant_uid: merchantUid,
				name: this.orderTitle,
				amount: this.payPrice,
				tax_free: this.payPrice,
				buyer_email: this.email,
				buyer_name: this.name,
				buyer_tel: this.phone,
				buyer_addr: null,
				buyer_postcode: null,
				m_redirect_url: `https://my.tella.co.kr/order/pre-landing?plan-id=${this.planId}&payment-id=${paymentId}`,
				// 리다이렉트랑 결제 완료 컨트롤러
			}
			// 가상계좌 입금인 경우 듀 데이트 설정
			if (this.pgPayMethod === 'vbank')
				params.vbank_due = `${moment
					.tz('Asia/Seoul')
					.format('YYYYMMDD')}23:59:59`
			IMP.request_pay(params, async rsp => {
				// callback
				if (rsp.success) {
					this.trackingPurchase(paymentId)
					await Api.post('/payments/complete', {
						imp_uid: rsp.imp_uid,
						merchant_uid: rsp.merchant_uid,
					})
					this.$router.push({ name: 'OrderLanding', params: { paymentId } })

					this.isLoading = false
				} else {
					alert(this.$t('order.error.fail-to-pay'))
					this.isLoading = false
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
