<template>
	<v-card
		@click="selectProgram"
		flat
		rounded="lg"
		class="d-flex align-start align-stretch"
		style="overflow: hidden; box-sizing: border-box; border-box; border: 1px solid #f2f2f2"
		:height="125"
		:style="
			selectedProgramId === program.id ? 'border: 2px solid #1E78FF;' : ''
		"
	>
		<v-img
			max-width="100"
			:style="selectedProgramId === program.id ? 'margin-left:-2px;' : ''"
			style="border-top-right-radius: 0px; border-bottom-right-radius: 0px"
			:src="programImgUrl"
		>
		</v-img>
		<div
			class="pa-3"
			style="height: 100%; width: calc(100% - 100px)"
			:style="
				selectedProgramId === program.id
					? 'background: rgba(30, 120, 255, 0.05); margin-top:-2px;'
					: ''
			"
		>
			<div
				class="
					px-2
					rounded-pill
					d-inline-block
					primary
					white--text
					text-caption
				"
				v-if="program.appointed"
			>
				{{ $t('예약중') }}
			</div>
			<div
				class="
					px-2
					rounded-pill
					d-inline-block
					primary
					white--text
					text-caption
				"
				v-else-if="program.recent"
			>
				{{ $t('최근') }}
			</div>
			<h1 class="text-body-1">{{ program.name }}</h1>
			<p class="text-caption grey--text text--darken-1">
				{{ program.description }}
			</p>
		</div>
	</v-card>
</template>

<script>
export default {
	props: {
		program: {
			required: true,
			type: Object,
		},
		selectedProgramId: {
			required: true,
		},
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		programImgUrl() {
			return `https://image.tella.co.kr/v1/program/thumbnail/portrait/${this.language}/${this.program.id}.jpg`
		},
	},
	methods: {
		selectProgram() {
			this.$emit('selectProgram', this.program)
		},
	},
}
</script>

<style lang="scss" scoped></style>
