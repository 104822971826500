var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading === false)?_c('div',[_c('section',[_c('div',{staticClass:"mb-5"},[(_vm.recommendSlot)?_c('div',[_c('h2',{staticClass:"grey--text text--darken-3 text-body-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('trial.title.recommend-time'))+" ")]),_c('p',{staticClass:"grey--text text--darken-1 mb-5 text-body-2 grey--4--text"},[_vm._v(" "+_vm._s(_vm.$t('trial.text.recommend-time'))+" ")]),_c('v-btn',{staticClass:"mb-3 slot",class:{
						selected: _vm.recommendSlot === _vm.selectedSlot,
					},attrs:{"outlined":"","x-large":"","block":""},on:{"click":function($event){return _vm.selectTime(_vm.recommendSlot, 'recommend')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-check-circle")]),_vm._v(" "+_vm._s(_vm.formattedRecommendSlot)+" ")],1)],1):_vm._e(),_c('p',{staticClass:"grey--text text--darken-1 text-body-2 grey--4--text"},[_vm._v(" "+_vm._s(_vm.$t('trial.text.타임존-표기-안내', { timezone: _vm.timezone, }))+" "),_c('TimezonePicker',{attrs:{"originTimezone":_vm.timezone},on:{"selectTimezone":_vm.selectTimezone}})],1)]),_c('div',[_c('h2',{staticClass:"\n\t\t\t\t\tgrey--text\n\t\t\t\t\ttext--darken-3\n\t\t\t\t\tmt-7\n\t\t\t\t\tmb-4\n\t\t\t\t\ttext-body-1\n\t\t\t\t\tfont-weight-bold\n\t\t\t\t"},[_vm._v(" "+_vm._s(_vm.$t('trial.title.all-time'))+" ")]),_vm._l((_vm.groupByDate),function(group,date){return _c('div',{key:_vm.toMoment(date).format('MM-DD'),staticStyle:{"position":"relative"}},[_c('h1',{staticClass:"text-body-2 grey--text text--darken-3 mb-2",staticStyle:{"z-index":"2","position":"sticky","top":"0"}},[_vm._v(" "+_vm._s(_vm.localeDateFormat(date))+" ")]),_c('v-row',{staticClass:"pb-10",attrs:{"no-gutters":""}},_vm._l((group),function(slot){return _c('v-col',{key:(date + "-" + (slot.time_code)),staticClass:"mb-2",attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"slot text-body-2",class:{
								disabled:
									slot.lessons_count >= slot.capacity ||
									slot.isDuplicated === true,
								selected: slot === _vm.selectedSlot,
							},attrs:{"x-small":"","ripple":false,"outlined":"","disabled":slot.lessons_count >= slot.capacity ||
								slot.isDuplicated === true},on:{"click":function($event){return _vm.selectTime(slot, 'list')}}},[_vm._v(" "+_vm._s(_vm.localTime(slot.moment))+" ")])],1)}),1)],1)}),(Object.keys(_vm.groupByDate).length === 0)?_c('div',[_c('p',{staticClass:"text-body-1 font-italic"},[_vm._v(" "+_vm._s(_vm.$t('trial.text.no-slot'))+" ")])]):_vm._e()],2)])]):_c('Loader',{attrs:{"height":100}})}
var staticRenderFns = []

export { render, staticRenderFns }