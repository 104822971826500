import Api from '@/services/index'
import moment from 'moment-timezone'
const R = require('ramda')

// import { addMoment } from '@/helpers/date-format'
import {
	LOAD,
	STORE,
	STORE_TOTAL_COUNT,
	STORE_PAST_ALL,
	STORE_PAST_MONTH,
} from './mutations'

export default {
	get: async (
		{ state, commit, rootGetters, dispatch },
		{ needPreLoad, page },
	) => {
		commit(LOAD, true)
		let promises = []

		if (needPreLoad === true) {
			promises.push(dispatch('getPast'))
		}

		if (rootGetters['tutorRating/ratings'].length === 0) {
			promises.push(dispatch('tutorRating/get', null, { root: true }))
		}

		if (promises.length > 0) await Promise.all(promises)

		// commit(STORE_TUTOR, [])
		let query = tutorQuery(state, rootGetters)
		const res = await Api.get(`capacities/tutors/page/${page}${query}`)
		commit(STORE_TOTAL_COUNT, parseInt(res.headers['x-total-count']))
		commit(STORE, res.data)
		commit(LOAD, false)
	},
	getPast: async ({ commit }) => {
		commit(STORE_PAST_ALL, [])
		commit(STORE_PAST_MONTH, [])

		const [result, resultForMonth] = await Promise.all([
			Api.get(`lessons/groups/tutor?status-in=analyzed,analyzed-absent`),
			Api.get(
				`lessons/groups/tutor?status-in=analyzed,analyzed-absent&gte-lesson-datetime=${moment()
					.add(-1, 'months')
					.toISOString()}`,
			),
		])

		const pastTutorIds = R.map(x => x.tutor_id, result.data)
		const pastTutorIdsForMonth = R.map(x => x.tutor_id, resultForMonth.data)
		commit(STORE_PAST_ALL, pastTutorIds)
		commit(STORE_PAST_MONTH, pastTutorIdsForMonth)
		// commit(STORE_PAST_TUTORS, res.data)
	},
}

function tutorQuery(getters, rootGetters) {
	const selectedType = rootGetters['book/selectedType']
	const language = rootGetters['member/language']
	let query = `?lesson-type=${selectedType}&limit=${getters.rowsPerPage}&language=${language}`

	const keyword = getters.keyword
	if (keyword && keyword.length > 0) {
		query = query + `&keyword=${keyword}`
	}

	const pastTutorIdsForMonth = getters.pastTutorIdsForMonth
	const sortBy = getters.sortBy
	switch (sortBy) {
		case 'recent':
			query = query + `&recent-tutor-ids=${pastTutorIdsForMonth.toString()}`
			break
		case 'name':
			query = query + `&sort-by=+name`
			break
		case 'rating':
			query =
				query +
				`&sort-by=${
					selectedType === 0 ? '-talk_avg_rating' : '-call_avg_rating'
				}`
			break
	}

	const selectedMoment = rootGetters['book/selectedMoment']
	if (selectedMoment !== null) {
		query = query + `&lesson-datetime=${selectedMoment.toISOString()}`
	}

	// const appointedTutorIdsInSelectedMoment =
	// 	rootGetters['book/appointedTutorIdsInSelectedMoment']
	// if (appointedTutorIdsInSelectedMoment.length > 0) {
	// 	query = query + `&id-not-in=${appointedTutorIdsInSelectedMoment.toString()}`
	// }

	if (getters.category === 'past' && getters.pastTutorIds.length > 0) {
		query += `&ids=${getters.pastTutorIds.toString()}`
	}
	return query
}
