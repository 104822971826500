<template>
	<v-dialog v-model="dialog" width="350" @click:outside="closeDialog()">
		<v-card class="pa-0">
			<div class="red lighten-1 py-5 text-center">
				<v-icon color="white" x-large>mdi-close-octagon-outline</v-icon>
			</div>
			<div class="py-5 text-center" v-if="error">
				<h3 class="text-h5 font-weight-medium mb-1">
					Error{{ error.code ? ` #${error.code}` : '' }}
				</h3>
				<p>
					죄송합니다. 에러가 발생했습니다.<br />
					고객센터로 문의주세요.
				</p>
				{{ error }}
				<!-- {{ error.name }}
				{{ error.code }}
				{{ error.message }} -->
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	computed: {
		dialog() {
			return this.$store.getters['error/dialog']
		},
		error() {
			return this.$store.getters['error/error']
		},
	},
	methods: {
		closeDialog() {
			this.$store.commit('error/CLOSE_DIALOG')
		},
	},
}
</script>

<style lang="scss" scoped></style>
