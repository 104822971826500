<template>
	<div>
		<div
			class="py-2"
			style="
				position: absolute;
				max-width: 400px;
				margin: 0 auto;
				top: 0;
				z-index: 3;
			"
		>
			<v-btn small fab icon @click="goBack">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
		</div>
		<v-app-bar
			id="program-detail-header"
			fixed
			color="white"
			flat
			inverted-scroll
			height="60"
			:style="userDevice === 'desktop' ? 'max-width: 400px;' : 'left:0;'"
			style="border-bottom: 1px solid #f2f3f5 !important"
			class="mx-auto px-0"
		>
			<v-row align="center" no-gutters class="mx-n3">
				<v-col cols="1">
					<v-btn fab icon text small @click="goBack">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col
					cols="10"
					class="text-truncate font-16 font-weight-medium text-center"
				>
					{{ eventName }}
				</v-col>
			</v-row>
		</v-app-bar>
	</div>
</template>

<script>
export default {
	props: {
		eventName: {
			required: true,
			type: String,
		},
		prevRoute: {
			required: false,
		},
	},
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
	},

	methods: {
		goBack() {
			const prevRouteNmae = this.prevRoute.name
			if (prevRouteNmae) this.$router.go(-1)
			else this.$router.push({ name: 'Event' })
		},
	},
}
</script>

<style lang="scss" scoped></style>
