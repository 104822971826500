import Api from '@/services/index'
import { SET } from './mutations'
// import router from '../../../router'

// import * as actions from './mutation-types'

export default {
	get: async ({ commit, rootGetters }) => {
		commit(SET, [])
		const memberId = rootGetters['auth/memberId']
		const res = await Api.get(
			`groups?member-id=${memberId}&active=1&group-active=1`,
		)
		const result = res.data

		commit(SET, result)
	},
}
