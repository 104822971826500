<template>
	<div id="find" style="min-height: 650px">
		<v-app-bar color="white" height="59" elevation="0">
			<v-btn plain @click="$router.push({ name: 'SignIn' })" icon
				><v-icon>mdi-arrow-left</v-icon></v-btn
			>
		</v-app-bar>
		<v-divider></v-divider>
		<v-tabs grow v-model="tab">
			<v-tab
				:ripple="false"
				@click="$router.push({ name: 'FindPassword' })"
				class="text-body-2 grey--text text--darken-3 font-weight-bold"
				>{{ $t('find-password.tab.password') }}</v-tab
			>
			<v-tab
				:ripple="false"
				class="text-body-2 primary--text font-weight-bold"
				>{{ $t('find-email.tab.email') }}</v-tab
			>
			<v-tab
				:ripple="false"
				@click="$router.push({ name: 'FindEtc' })"
				class="text-body-2 grey--text text--darken-3 font-weight-bold"
				>{{ $t('find-etc.tab.etc') }}</v-tab
			>
		</v-tabs>
		<v-container v-if="isChecked === false">
			<h1 class="pt-7 pb-3 text-h1 font-weight-bold">
				{{ $t('find-email.title.find-email') }}
			</h1>
			<p class="text-body-1 pb-12 white-space-pre">
				{{ $t('find-email.text.please-write-email-and-find-it') }}
			</p>
			<v-form ref="form" v-model="isValid" @submit.prevent="checkEmail">
				<v-text-field
					:label="$t('common.form.label.email')"
					required
					background-color="white"
					v-model.trim="email"
					:rules="emailRules"
					outlined
					:disabled="isLoading"
					style="ime-mode: inactive"
				></v-text-field>
				<v-btn
					:disabled="isValid === false"
					depressed
					color="primary"
					block
					large
					type="submit"
					:loading="isLoading"
					@click.native="loader = 'loading'"
					>{{ $t('find-email.button.confirm') }}</v-btn
				>
			</v-form>
		</v-container>
		<v-container v-else>
			<h1
				class="pt-7 pb-3 text-h1 font-weight-bold"
				style="word-break: break-all"
			>
				{{ this.email }}
			</h1>
			<div v-if="count === 0">
				<p class="text-body-1 pb-12 white-space-pre">
					{{ $t('find-email.text.unregistered-account') }}
				</p>
				<v-btn
					depressed
					color="primary"
					block
					large
					@click.native="$router.push({ name: 'SignUp' })"
					>{{ $t('find-email.button.sign-up') }}</v-btn
				>
				<v-btn
					outlined
					color="primary"
					block
					large
					class="mt-3"
					@click.native="setInitial"
					>{{ $t('find-email.button.check-again') }}</v-btn
				>
			</div>
			<div v-else>
				<p class="text-body-1 pb-12 white-space-pre">
					{{ $t('find-email.text.registered-account') }}
				</p>
				<v-btn
					depressed
					color="primary"
					block
					large
					@click.native="$router.push({ name: 'SignIn' })"
					>{{ $t('find-email.button.sign-in') }}</v-btn
				>
				<v-btn
					outlined
					color="primary"
					block
					large
					class="mt-3"
					@click.native="setInitial"
					>{{ $t('find-email.button.check-again') }}</v-btn
				>
			</div>
		</v-container>
		<div
			class="text-body-2 text-center"
			style="
				position: fixed;
				bottom: 0;
				width: 100%;
				max-width: 400px;
				height: 60px;
				padding-top: 22px;
				border-top: 1px solid #f0f0f0;
			"
		>
			{{ $t('find-email.text.remember-email?') }}&nbsp;&nbsp;&nbsp;&nbsp;<a
				href="#"
				@click="$router.push({ name: 'SignIn' })"
				>{{ $t('common.button.sign-in') }}</a
			>
		</div>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	data() {
		return {
			tab: 1,
			isValid: false,
			isLoading: false,
			isChecked: false,
			count: 0,
			email: null,
			emailRules: [
				v => !!v || this.$t('common.form.error.email-required'),
				v =>
					(v && v.length <= 100) || this.$t('common.form.error.email-too-long'),
				v => /.+@.+\..+/.test(v) || this.$t('common.form.error.email-format'),
			],
		}
	},
	methods: {
		async checkEmail() {
			try {
				this.isLoading = true
				const res = await Api.get(`members/count?email=${this.email}`)
				this.count = res.data
				this.isChecked = true
				this.isLoading = false
			} catch (err) {
				console.log(err)
			}
		},
		setInitial() {
			this.count = 0
			this.isChecked = false
			this.email = null
		},
	},
}
</script>

<style lang="scss">
#find .theme--light.v-tabs .v-tab--active:hover::before,
#find .theme--light.v-tabs .v-tab--active::before {
	display: none !important;
}
#find .v-tabs .v-tab:hover::before,
#find .v-tabs .v-tab::before {
	display: none !important;
}
</style>
