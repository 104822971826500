<template>
	<router-view v-if="isLoading === false"></router-view>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('program')

export default {
	data: () => ({
		isLoading: false,
	}),
	computed: {
		programs() {
			return this.$store.getters['program/programs']
		},
	},
	created() {
		this.getAll()
	},
	methods: {
		...mapActions(['get', 'getProgresses']),
		async getAll() {
			this.isLoading = true
			let promises = [this.getProgresses()]
			if (this.programs.length === 0) promises.push(this.get())
			if (this.$store.getters['group/groups'] === null)
				promises.push(this.$store.dispatch('group/get'))
			await Promise.all(promises)
			this.isLoading = false
		},
	},
}
</script>

<style lang="scss" scoped></style>
