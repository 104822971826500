<template>
	<div class="d-flex align-center">
		<v-img
			class="mr-2"
			max-width="22"
			src="@/assets/images/logo/fb.png"
		></v-img>
		<p class="text-body-1 font-weight-bold">{{ $t('페이스북') }}</p>
		<v-spacer></v-spacer>
		<v-switch
			@click="clickSwitch"
			v-model="isConnected"
			inset
			:disabled="isLoading"
		></v-switch>
	</div>
</template>

<script>
import Api from '@/services/index'
import maskingFunc from '@/helpers/masking'
// import kakao from '@/services/kakao'

export default {
	data: () => ({
		isLoading: false,
		isConnected: false,
		existingMember: null,
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		email() {
			return this.$store.getters['member/email']
		},
		authFacebook() {
			return this.$store.getters['member/authFacebook']
		},
		facebookToken() {
			return this.$store.getters['auth/facebookToken']
		},
		facebookMe() {
			return this.$store.getters['auth/facebookMe']
		},
	},

	created() {
		this.setSwitch()
	},
	methods: {
		async clickSwitch() {
			try {
				if (this.isConnected === true) {
					this.isLoading = true
					if (this.facebookToken === null)
						await this.$store.dispatch('auth/getFacebookToken')
					await this.$store.dispatch('auth/getFacebookMe')
					const res = await Api.get(
						`auth/facebook/${this.facebookMe.authFacebookId}`,
						{
							headers: { 'x-facebook-token': this.facebookToken },
						},
					)
					this.existingMember = res.data

					let forcedConfirm
					if (this.existingMember.memberId) {
						if (this.existingMember.email === this.email) {
							alert(
								this.$t(
									'해당 이메일로 이미 카카오 로그인이 등록되어 있습니다.',
								),
							)
							return
						}
						// 멤버아이디가 있다. 강제할지여부
						forcedConfirm = this.confirmReconnect()
						if (forcedConfirm === false) {
							this.isConnected = false
							return
						}
					}

					if (forcedConfirm === true || !this.existingMember.memberId) {
						// 기존 멤버아이디가 없거나 있더라도 강제 컨펌을 받은 경우
						await this.connect()
						this.$emit('connect')
					}
				}
				if (this.isConnected === false) {
					const deleteConfirm = confirm(
						this.$t('정말로 페이스북 로그인을 해제하시겠습니까?'),
					)
					if (deleteConfirm === true) {
						let params = {
							authFacebookId: null,
						}
						await Api.patch(`members/${this.memberId}`, params)
						alert(this.$t('페이스북 연동이 해제되었습니다.'))
					}
				}
			} catch (err) {
				let msg = this.$t(
					'페이스북 연동/해제에 실패하였습니다. 고객센터로 문의주세요.\nERROR CODE: KN91EA',
				)
				if (err.name === 'kakaoLoginError')
					msg = this.$t('페이스북 로그인을 취소하셨습니다.')
				if (err.name === 'kakaoGetMeError')
					msg = this.$t('페이스북에서 정보를 불러오지 못했습니다.')
				if (err.name === '10006')
					msg = this.$t(
						'유효하지 않은 페이스북 토큰이 사용되었습니다. 페이스북 재로그인 후 다시 한번 시도해주세요.',
					)
				alert(msg)
			} finally {
				this.isLoading = false
			}
		},

		confirmReconnect() {
			const maskingEmail = maskingFunc.email(this.existingMember.email)
			const msg = this.$t(
				'해당 페이스북 로그인이 연결되어 있던 기존의 텔라 아이디(%{maskingEmail})가 있습니다. 이의 연결을 끊고 지금의 아이디(%{email})로 카톡 로그인을 하시겠습니까?',
				{ maskingEmail, email: this.email },
			)
			const forcedConfirm = confirm(msg)

			return forcedConfirm
		},
		async connect() {
			try {
				await Api.patch(
					`auth/facebook/${this.facebookMe.authFacebookId}`,
					{ memberId: this.memberId },
					{ headers: { 'x-facebook-token': this.facebookToken } },
				)
				alert(this.$t('페이스북 간편 로그인 연동이 완료되었습니다.'))
			} catch (err) {
				const e = new Error(err)
				e.name = err.response.data.code
					? err.response.data.code
					: err.response.data.status
				throw e
			}
		},
		setSwitch() {
			this.isConnected = this.authFacebook
		},
		goBack() {
			this.$router.push({ name: 'Setting' })
		},
	},
}
</script>

<style lang="scss" scoped></style>
