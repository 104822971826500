<template>
	<v-container>
		<v-row
			style="height: 100vh; max-height: 500px"
			class="d-flex align-center justify-center"
		>
			<v-card flat class="py-8 text-center" width="350">
				<div class="d-flex justify-center">
					<v-img
						class="mb-8"
						max-width="120"
						src="@/assets/images/logo/default.png"
					></v-img>
				</div>
				<h1 class="font-weight-black">404</h1>
				<p class="mb-5">페이지를 찾을 수 없습니다.</p>
				<v-btn depressed @click="$router.push({ name: 'Home' })"
					>홈으로 돌아가기</v-btn
				>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
