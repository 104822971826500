<template>
	<swiper class="swiper" :options="swiperOption">
		<swiperSlide v-for="(correction, i) in corrections" :key="correction.id">
			<div class="py-5">
				<Card
					:correction="correction"
					:i="i"
					:totalLength="corrections.length"
				/>
			</div>
		</swiperSlide>
	</swiper>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Card from './CorrectionCard'

export default {
	components: {
		swiper,
		swiperSlide,
		Card,
	},
	props: {
		corrections: {
			required: true,
			type: Array,
		},
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: 1.2,

				centeredSlides: true,
				spaceBetween: 15,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			},
		}
	},
}
</script>
