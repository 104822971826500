<template>
	<v-card rounded="xl" class="px-5 py-8 elevation-card">
		<v-row class="py-0">
			<v-col cols="6">
				<div class="d-flex align-end mb-3">
					<h3 class="text-h5 text-nls font-weight-medium">💬<br />Talk</h3>
					<v-spacer></v-spacer>

					<h3 class="text-h4 text-nls">
						<span class="primary--text font-weight-medium">
							{{ talkRemaining }}
						</span>
						<span class="text-body-2"> {{ $t('회') }}</span>
					</h3>
				</div>
			</v-col>
			<v-divider vertical class="grey lighten-4"></v-divider>
			<v-col cols="6">
				<div class="d-flex align-end mb-3">
					<h3 class="text-h5 text-nls font-weight-medium">📞 <br />Call</h3>
					<v-spacer></v-spacer>

					<h3 class="text-h4 text-nls">
						<span class="emerald--text font-weight-medium">{{
							callRemaining
						}}</span>
						<span class="text-body-2"> {{ $t('회') }}</span>
					</h3>
				</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('point')

export default {
	computed: {
		...mapGetters(['talkRemaining', 'callRemaining']),
	},
}
</script>

<style lang="scss" scoped></style>
