<template>
	<div>
		<v-btn
			:disabled="isValid === false"
			:loading="isLoading"
			large
			depressed
			block
			color="primary"
			@click="update"
		>
			{{ $t('trial-reschedule.button.무료수업 변경하기') }}
		</v-btn>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="errPersistent"
			:numberOfButton="1"
			:title="errTitle"
			:text="errMsg"
			:actionColor="errActionColor"
			:action="errAction"
			:actionText="errActionText"
		/>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import Dialog from '@/components/common/Dialog'
import Api from '@/services/index'

export default {
	components: {
		Dialog,
	},
	props: {
		id: { required: true, type: Number },
		originPhone: { required: true, type: [Number, String] },
		originCountryCode: { required: true, type: [Number, String] },
		name: { required: true, type: String },
		email: { required: true, type: String },
		phone: { required: true, type: String },
		countryCode: { required: true, type: [Number, String] },
		timezone: { required: true, type: String },
		datetime: { type: String },
		lessonType: { required: true, type: Number },
		isValid: { required: true, type: Boolean },
		trialMoment: { required: true, type: Object },
	},
	data() {
		return {
			isLoading: false,
			errorDialog: false,
			errTitle: '',
			errMsg: '',
			errActionColor: 'grey lighten-3',
			errPersistent: false,
			errAction: this.goBackConfirmation,
			errActionText: this.$t('modal.button.go-back'),
		}
	},
	methods: {
		async update() {
			try {
				if (moment().add(60, 'minutes') > this.trialMoment) {
					this.errHandler('110007')
					return
				}
				this.isLoading = true
				const params = {
					name: this.name,
					email: this.email,
					phone: this.phone,
					countryCode: this.countryCode,
					timezone: this.timezone,
					datetime:
						this.datetime !== this.trialMoment.toISOString()
							? this.datetime
							: undefined,
					lessonType: 0,
				}
				await Api.patch(
					`trials/${this.id}?phone=${this.originPhone}&country-code=${this.originCountryCode}`,
					params,
				)
				this.$router.push({
					name: 'TrialConfirmation',
					params: { trialId: this.id },
					query: { phone: this.phone, 'country-code': this.countryCode },
				})
			} catch (err) {
				const errCode =
					err.response && err.response.data && err.response.data.code
						? err.response.data.code
						: null
				this.errHandler(errCode)
			} finally {
				this.isLoading = false
			}
		},
		errHandler(errCode) {
			switch (errCode) {
				case '110003':
					this.errTitle = this.$t('modal.title.trial-book-error-no-slot')
					this.errMsg = ''
					this.errActionColor = 'primary'
					this.errPersistent = false
					this.errAction = this.closeErrorDialog
					this.errActionText = this.$t('modal.button.confirm')
					break
				case '110006':
					this.errTitle = this.errTitle = this.$t(
						'modal.title.can`t-find-trial',
					)
					this.errMsg = this.$t('modal.text.unexpected-error')
					this.errActionColor = 'primary'
					this.errPersistent = true
					this.errAction = this.goBackBook
					this.errActionText = this.$t('modal.button.go-back')
					break
				case '110007':
					this.errTitle = this.$t('modal.title.trial-edit-only-in-60-minutes')
					this.errMsg = null
					this.errActionColor = 'primary'
					this.errPersistent = true
					this.errAction = this.goBackConfirmation
					this.errActionText = this.$t('modal.button.go-back')
					break
				case '110009':
					this.errTitle = this.$t(
						'modal.title.trial-reschedule-already-passed-or-cancelled',
					)
					this.errMsg = null
					this.errActionColor = 'primary'
					this.errPersistent = true
					this.errAction = this.goBackBook
					this.errActionText = this.$t('modal.button.go-back')
					break
				default:
					this.errTitle = this.errTitle = this.$t(
						'modal.title.unexpected-error',
					)
					this.errMsg = this.$t('modal.text.unexpected-error')
					this.errActionColor = 'primary'
					this.errPersistent = true
					this.errAction = this.goBackConfirmation
					this.errActionText = this.$t('modal.button.go-back')
			}

			this.errorDialog = !this.errorDialog
		},
		goBackBook() {
			this.$router.push({ name: 'TrialBook' })
		},
		goBackConfirmation() {
			this.$router.push({
				name: 'TrialConfirmation',
				params: { trialId: this.trial.id },
				query: {
					phone: this.trial.phone,
					'country-code': this.trial.country_code,
				},
			})
		},
		closeErrorDialog() {
			this.errorDialog = !this.errorDialog
		},
	},
}
</script>

<style lang="scss" scoped></style>
