<template>
	<div class="white pb-10">
		<div style="max-width: 300px" class="mx-auto">
			<div class="d-flex align-center mb-4">
				<h2 class="title3 title-color">
					{{ $t('trial-report.delivery') }}
				</h2>
				<v-spacer></v-spacer>
				<div class="rectangle">
					<div class="d-inline-block dot"></div>
					<p class="sub-title2 d-inline-block primary--text">
						{{
							$t('trial-report.score', {
								score: deliveryScore,
							})
						}}
					</p>
				</div>
			</div>
			<p class="text default-color">
				{{ description }}
			</p>
		</div>
	</div>
</template>

<script>
const R = require('ramda')

export default {
	props: {
		deliveryScore: {
			required: true,
			type: Number,
		},
	},
	computed: {
		description() {
			const list = [
				{
					score: 2,
					text: this.$t('trial-report.delivery.description-1'),
				},
				{
					score: 4,
					text: this.$t('trial-report.delivery.description-2'),
				},
				{
					score: 6,
					text: this.$t('trial-report.delivery.description-3'),
				},
				{
					score: 8,
					text: this.$t('trial-report.delivery.description-4'),
				},
				{
					score: 10,
					text: this.$t('trial-report.delivery.description-5'),
				},
			]
			const find = R.find(R.propEq('score', this.deliveryScore))(list)
			return find ? find.text : 'N/A'
		},
	},
}
</script>

<style lang="scss" scoped>
.dot {
	margin-right: 6px;
	margin-bottom: 1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #0073ff;
}
.rectangle {
	padding: 6px 17px 4px 17px;
	border-radius: 10px;
	background-color: #f2f3f6;
}
</style>
