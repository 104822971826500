<template>
	<a
		href="https://tella.notion.site/2022-12-15-PDF-46270eee2b29425885e2333ac9cd1c57"
	>
		<img style="width: 100%" src="@/assets/images/home/banner/print.png" />
	</a>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
