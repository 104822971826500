<template>
	<v-expansion-panels class="plan-faq-expansion-panels" flat accordion>
		<v-expansion-panel v-for="item in faq" :key="item.id">
			<v-expansion-panel-header class="py-2" style="background-color: #f2f5f9">
				<h1 class="text-body-1 font-weight-bold grey--text text--darken-3">
					<span class="primary--text">Q. </span>{{ item.q }}
				</h1>
			</v-expansion-panel-header>
			<v-expansion-panel-content
				class="py-4 white-space-pre text-body-2 grey--text text--darken-3"
				style="
					background-color: #fafbff;
					border-bottom: 1px solid #eef2ff;
					border-top: 1px solid #eef2ff;
				"
			>
				<div v-html="item.a"></div>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {
	data() {
		return {
			faq: [
				{
					id: 1,
					q: this.$t('plan.faq.question1'),
					a: this.$t('plan.faq.answer1'),
				},
				{
					id: 2,
					q: this.$t('plan.faq.question2'),
					a: this.$t('plan.faq.answer2'),
				},
				{
					id: 3,
					q: this.$t('plan.faq.question3'),
					a: this.$t('plan.faq.answer3'),
				},
				{
					id: 4,
					q: this.$t('plan.faq.question4'),
					a: this.$t('plan.faq.answer4'),
				},
			],
		}
	},
}
</script>

<style lang="scss">
.plan-faq-expansion-panels {
	.v-expansion-panel--active > .v-expansion-panel-header {
		min-height: unset;
	}
	ul li {
		padding: 5px 0px;
	}
}
</style>
