import axios from 'axios'

const instance = axios.create({
	baseURL:
		'https://hooks.slack.com/services/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',
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
	},
})

export default instance
