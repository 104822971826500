<template>
	<div
		style="
			overflow-y: auto;
			height: calc(var(--vh, 1vh) * 100 - 59px);
			padding-bottom: 100px;
		"
	>
		<div class="text-right py-3 d-flex align-center justify-end">
			<PlanButton class="mr-1" />

			<v-btn icon @click="goTo('Setting')">
				<v-icon class="primary--text">mdi-cog-outline</v-icon>
			</v-btn>
		</div>
		<section class="px-5 py-4">
			<h1 class="text-h4 font-weight-bold">{{ nameKr }}</h1>
			<p
				@click="goTo('SettingNameEn')"
				class="cursor-pointer text-body-1 pl-1"
				style="line-height: 1em"
			>
				{{ nameEn }}
			</p>
		</section>
		<section class="pa-5 grey lighten-3">
			<div class="cursor-pointer d-flex align-center mb-3">
				<h2 class="text-h6 font-weight-medium">{{ $t('나의 수강권') }}</h2>
				<v-spacer></v-spacer>
				<v-btn class="px-0 grey--text" text @click="goTo('Pass')"
					>{{ $t('수강내역') }} <v-icon>mdi-chevron-right</v-icon></v-btn
				>
			</div>
			<PointCard class="mb-5" />
			<PointsList />
		</section>
		<section class="pa-5">
			<h2 class="text-h6 mb-1 font-weight-medium">{{ $t('혜택 및 정보') }}</h2>
			<div
				v-if="language !== 'ja'"
				class="cursor-pointer d-flex align-center py-4"
				style="border-bottom: 1px solid #e5e5e5"
				@click="$router.push({ name: 'Recommend' })"
			>
				<h3 class="text-body-1">{{ $t('친구 추천') }}</h3>
				<v-spacer></v-spacer>
				<p class="text-body-1 primary--text">
					{{ $t('50,000원 혜택 받기') }}
				</p>
				<!-- <p class="text-body-1 grey--text pr-1">{{ $t('준비중') }}</p> -->
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<div
				@click="goTo('Cash')"
				class="cursor-pointer d-flex align-center py-4"
				style="border-bottom: 1px solid #e5e5e5"
			>
				<h3 class="text-body-1">{{ $t('적립금') }}</h3>
				<v-spacer></v-spacer>
				<p class="text-body-1 primary--text">
					{{ $t('%{cash} 원', { cash: numberWithCommas(bonusCash) }) }}
				</p>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<div
				class="cursor-pointer d-flex align-center py-4"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('Coupon')"
			>
				<h3 class="text-body-1">{{ $t('할인 쿠폰') }}</h3>
				<v-spacer></v-spacer>
				<p class="text-body-1 primary--text">
					{{ $t('%{couponCount} 개', { couponCount: available.length }) }}
				</p>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
		</section>
		<section class="pa-5">
			<h2 class="text-h6 mb-1 font-weight-medium">{{ $t('이용 안내') }}</h2>
			<!-- <a
				style="color: unset; text-decoration: unset"
				href="https://tella.notion.site/719279d445044eb79e92db1c5b3bc9c6"
				target="_blank"
			> -->
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="$router.push({ name: 'Faq' })"
			>
				<h3 class="text-body-1">{{ $t('자주 묻는 질문') }}</h3>
				<v-spacer></v-spacer>

				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<!-- </a> -->

			<div
				@click="goToCs()"
				class="cursor-pointer d-flex align-center py-4"
				style="border-bottom: 1px solid #e5e5e5"
			>
				<h3 class="text-body-1">{{ $t('고객 상담') }}</h3>
				<v-spacer></v-spacer>

				<!-- <p class="text-body-1 grey--text pr-1">{{ $t('준비중') }}</p> -->
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<div
				class="cursor-pointer d-flex align-center py-4"
				style="border-bottom: 1px solid #e5e5e5"
				@click="$router.push({ name: 'Notice' })"
			>
				<h3 class="text-body-1">{{ $t('공지사항') }}</h3>
				<v-spacer></v-spacer>

				<!-- <p class="text-body-1 grey--text pr-1">{{ $t('준비중') }}</p> -->
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<a
				style="text-decoration: unset; color: unset"
				target="_blank"
				:href="
					language === 'ja'
						? 'https://tellaco.typeform.com/to/pVDLcKor'
						: 'https://tellaco.typeform.com/to/momPyLNk'
				"
			>
				<div
					class="cursor-pointer d-flex align-center py-4"
					style="border-bottom: 1px solid #e5e5e5"
				>
					<h3 class="text-body-1">
						{{ $t('my-page.menu.텔라 연구소') }}
					</h3>
					<v-spacer></v-spacer>

					<!-- <p class="text-body-1 grey--text pr-1">{{ $t('준비중') }}</p> -->
					<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
				</div>
			</a>
		</section>
	</div>
</template>

<script>
import { numberWithCommas } from '@/helpers/number-format'
import moment from 'moment-timezone'
import PlanButton from '@/components/common/PlanButton'
import PointCard from './PointCard'
import PointsList from './PointsList'
import Api from '@/services/index'
const R = require('ramda')

export default {
	components: {
		PlanButton,
		PointCard,
		PointsList,
	},
	data: function () {
		return {
			isLoading: false,
			coupons: [],
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		nameEn() {
			return this.$store.getters['member/nameEn']
		},
		nameKr() {
			return this.$store.getters['member/nameKr']
		},
		bonusCash() {
			return this.$store.getters['member/bonusCash']
		},

		email() {
			return this.$store.getters['member/email']
		},
		phone() {
			return this.$store.getters['member/phone']
		},
		countryCode() {
			return this.$store.getters['member/countryCode']
		},
		language() {
			return this.$store.getters['member/language']
		},
		kakaoId() {
			return this.$store.getters['member/kakaoId']
		},
		authFacebook() {
			return this.$store.getters['member/authFacebook']
		},
		authKakao() {
			return this.$store.getters['member/authKakao']
		},
		paidPoints() {
			return this.$store.getters['point/paidPoints']
		},
		expiryDates() {
			return this.$store.getters['point/expiryDates']
		},
		available() {
			const available = c =>
				c.used_date === null &&
				c.active === 1 &&
				c.expiration_date >= moment.tz('Asia/Seoul').format('YYYY-MM-DD')
			return R.filter(available, this.coupons)
		},
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			this.isLoading = true
			const result = await Promise.all([
				Api.get(`coupons?member-id=${this.memberId}`),
				this.$store.dispatch('member/get'),
				this.$store.dispatch('point/get'),
			])

			this.coupons = result[0].data
			// if (this.paidPoints.length > 0) this.addRepurchaseCoupon()
			this.isLoading = false
			// 멤버정보, 쿠폰정보
		},
		// addRepurchaseCoupon() {
		// 	const expirationDate =
		// 		this.expiryDates.length > 0
		// 			? this.expiryDates[this.expiryDates.length - 1].expiryDate
		// 			: null

		// 	this.coupons.unshift({
		// 		active: 1,
		// 		coupon_id: 0,
		// 		coupon_no: '-',
		// 		coupon_type: this.$t('재구매할인 쿠폰'),
		// 		discount: 5,
		// 		discount_type: '%',
		// 		duration: null,
		// 		expiration_date: expirationDate,
		// 		id: null,
		// 		issued_date: null,
		// 		member_id: this.memberId,
		// 		used_date: null,
		// 	})
		// },
		numberWithCommas: number => numberWithCommas(number),
		goTo(routeName) {
			this.$router.push({ name: routeName })
		},
		goToCs() {
			const url =
				this.language === 'ja'
					? // ? 'https://tella.channel.io/support-bots/25548'
					  'https://tella.channel.io/support-bots/37625'
					: 'https://tella.channel.io/support-bots/30776'
			window.open(url, '_blank').focus()
		},
	},
}
</script>

<style lang="scss" scoped>
.menu {
	padding: 20px 10px;
	border-bottom: 1px solid #dadada;
}
.menu:hover {
	cursor: pointer;
}
.menu:last-child {
	border-bottom: unset;
}
</style>
