<template>
	<section>
		<div class="px-2">
			<h2
				class="mb-3 text-body-1 font-weight-bold"
				v-html="title"
				style="letter-spacing: -0.03em !important"
			></h2>
			<p
				class="mb-4 text-body-2 grey--text text--darken-2"
				v-html="
					$t('원어민은 평균적으로<br />1분에 125단어를 사용해 대화한다고 해요.')
				"
			></p>
		</div>

		<div>
			<v-row no-gutters>
				<v-col
					cols="6"
					:class="item.class"
					v-for="item in list"
					:key="item.title"
				>
					<v-card flat rounded="xl" class="stat-card px-5 pt-4 pb-6">
						<h3 class="text-body-1 font-weight-bold" v-html="item.title"></h3>
						<p
							class="text-body-1"
							style="margin-top: -3px"
							v-html="item.value"
						></p>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</section>
</template>

<script>
const R = require('ramda')

export default {
	props: {
		stats: {
			required: true,
			type: Array,
		},
	},
	computed: {
		title() {
			return this.$t(
				`%{name}님이 이번 달에 사용하신<br />
					<span class='primary--text highlight'>%{countOfWords}개 단어</span>로
					<span class='primary--text highlight'>%{availableTime}분간 영어</span>로 
					말할 수 있어요.`,
				{
					name: this.name,
					countOfWords: this.sumOfWords,
					availableTime: this.availableTime,
				},
			)
		},
		name() {
			return this.$store.getters['member/nameKr']
		},
		sumOfWords() {
			return R.pipe(
				R.map(s => s.tutee_number_of_words),
				R.sum(),
			)(this.stats)
		},
		averageOfWords() {
			return this.stats.length > 0
				? Math.round(this.sumOfWords / this.stats.length)
				: 0
		},
		availableTime() {
			return Math.round(this.sumOfWords / 125)
		},
		sumOfSentence() {
			return R.pipe(
				R.map(s => s.tutee_number_of_sentences),
				R.sum(),
			)(this.stats)
		},
		averageOfSentence() {
			return this.stats.length > 0
				? Math.round(this.sumOfSentence / this.stats.length)
				: 0
		},
		list() {
			return [
				{
					title: this.$t('한달간 사용한 단어'),
					value: this.$t('%{sumOfWords} 개', {
						sumOfWords: `<span class="text-h5 font-weight-bold">${this.sumOfWords}</span>`,
					}),
					class: 'pr-1 pb-1',
				},

				{
					title: this.$t('수업 당 단어량'),
					value: this.$t('%{averageOfWords} 개', {
						averageOfWords: `<span class="text-h5 font-weight-bold">${this.averageOfWords}</span>`,
					}),
					class: 'pl-1 pb-1 primary--card',
				},
				{
					title: this.$t('한 달간 영작한 문장'),
					value: this.$t('%{sumOfSentence} 개', {
						sumOfSentence: `<span class="text-h5 font-weight-bold">${this.sumOfSentence}</span>`,
					}),
					class: 'pr-1 pt-1',
				},
				{
					title: this.$t('수업 당 영작한 문장'),
					value: this.$t('%{averageOfSentence} 개', {
						averageOfSentence: `<span class="text-h5 font-weight-bold">${this.averageOfSentence}</span>`,
					}),
					class: 'pl-1 pt-1 primary--card',
				},
			]
		},
	},
}
</script>

<style lang="scss" scoped>
.stat-card {
	background: rgba(30, 120, 255, 0.05);
	h3 {
		color: #1e78ff;
	}
}
.primary--card {
	.stat-card {
		background: #1e78ff;
	}
	h3 {
		color: #fff;
	}
	p {
		color: #fff;
	}
}

.highlight {
	padding: 2px 1px;
	border-radius: 5px;
	background: rgba(30, 120, 255, 0.1);
}
</style>
