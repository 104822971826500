<template>
	<div v-if="isLoading === false">
		<div v-for="item in checkList" :key="item.id">
			<v-checkbox
				v-if="
					isGood === item.is_good &&
					(item.type === -1 || lesson.lesson_type === item.type)
				"
				hide-details
				class="mt-0"
				dense
				v-model="reasons[item.id]"
				:value="item.name_ko"
				@change="onChange"
				:disabled="isRating"
			>
				<template v-slot:label>
					<div class="text-body-2">{{ item[`name_${language}`] }}</div>
				</template>
			</v-checkbox>
		</div>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	props: {
		lesson: {
			required: true,
			type: Object,
		},
		isRating: {
			required: true,
			type: Boolean,
		},
		rating: {
			required: true,
			type: Number,
		},
	},
	data: function () {
		return {
			isLoading: false,
			checkList: [],
			reasons: {},
		}
	},
	computed: {
		isGood() {
			return this.rating > 3.5 ? 1 : 0
		},
		language() {
			return this.$store.getters['member/language']
		},
	},
	created() {
		this.getReasonType()
	},
	watch: {
		isGood() {
			this.makeInitialReason()
			this.onChange()
		},
	},
	methods: {
		async getReasonType() {
			this.isLoading = true
			const res = await Api.get('/lesson-ratings/reason-type')
			this.checkList = res.data
			this.makeInitialReason()
			this.isLoading = false
		},
		makeInitialReason() {
			this.reasons = {}
			this.checkList.forEach(item => {
				this.reasons[item.id] = null
			})
		},
		onChange() {
			this.$emit('reasonChange', this.reasons)
		},
	},
}
</script>

<style lang="scss" scoped></style>
