<template>
	<div @click="clickEvent" class="cursor-pointer">
		<v-img
			aspect-ratio="2.07"
			:src="require(`@/assets/images/events/thumbnail/${event.src}`)"
			class="mb-4 thumbnail-img"
		>
			<div
				style="border-top-left-radius: 5px"
				class="d-inline-block px-2 py-1 black font-14 white--text"
				v-if="dayDiff !== undefined && dayDiff >= 0"
			>
				D-{{ dayDiff == 0 ? 'Day' : dayDiff }}
			</div>
		</v-img>
		<h2
			class="font-14 mb-1 font-weight-bold"
			:class="{
				'primary--text': category !== '종료',
				'grey--text text--darken-1': category === '종료',
			}"
		>
			{{ category }}
		</h2>
		<h1 class="mb-2 font-16 font-weight-bold">
			{{ event[`${userDevice}Title`] }}
		</h1>
		<p class="font-14 grey--text text--darken-1">
			<span v-if="event.endDt && dayDiff >= 0"
				>{{ event.startDt.toFormat('yyyy. LL. dd') }} ~
				{{ event.endDt.toFormat('yyyy. LL. dd') }}</span
			>
		</p>
	</div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
	props: {
		event: {
			type: Object,
			required: true,
		},
	},
	computed: {
		userDevice() {
			// return this.$store.getters['device/userDevice']
			return 'mobile'
		},
		category() {
			return this.event.endDt
				? this.event.endDt < DateTime.now()
					? '종료'
					: '프로모션'
				: '상시 이벤트'
		},
		dayDiff() {
			if (!this.event.endDt) return undefined

			const today = DateTime.now()

			const diff = this.event.endDt.diff(today, ['days'])
			const dDay = Math.ceil(diff.toObject().days)
			return dDay
		},
	},
	methods: {
		clickEvent() {
			// if (this.category === '종료') {
			// 	alert('이미 종료된 이벤트 입니다.')
			// 	return
			// }
			this.$router.push({ name: this.event.routeName })
		},
	},
}
</script>

<style lang="scss" scoped>
.thumbnail-img {
	border-radius: 5px;
}
</style>
