<template>
	<v-snackbar
		top
		v-model="cancelledNotify"
		min-width="150"
		timeout="3000"
		data-test="cancelledNotify"
		content-class="text-center"
		class="lesson-cancelled-snackbar"
	>
		{{ cancelledMsg }}
	</v-snackbar>
</template>

<script>
import EventBus from '@/lib/eventBus'

export default {
	data: () => ({
		cancelledNotify: false,
		cancelledMsg: null,
	}),
	created() {
		EventBus.$on('lessonIsCancelled', this.toast)
	},
	beforeDestroy() {
		EventBus.$off('lessonIsCancelled', this.toast)
	},
	methods: {
		toast(msg) {
			this.cancelledNotify = true
			this.cancelledMsg = msg
		},
	},
}
</script>

<style lang="scss">
.lesson-cancelled-snackbar {
	.v-sheet.v-snack__wrapper {
		border-radius: 8px;
		.v-snack__action {
			display: none;
		}
	}
}
</style>
