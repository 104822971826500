// import Api from '@/services/index'

/* global FB */
export default {
	// async findMember() {
	// 	let facebookToken, authFacebookId
	// 	try {
	// 		let loginStatus = await this.getLoginStatus()

	// 		if (loginStatus.status !== 'connected') {
	// 			await this.login()
	// 			loginStatus = await this.getLoginStatus()
	// 		}

	// 		if (loginStatus.status !== 'connected') throw new Error()
	// 		facebookToken = loginStatus.authResponse.accessToken
	// 	} catch (err) {
	// 		const e = new Error('facebook login error')
	// 		e.name = 'facebookLoginError'
	// 		throw e
	// 	}

	// 	try {
	// 		const getMeRes = await this.getMe()
	// 		authFacebookId = getMeRes.id
	// 	} catch (err) {
	// 		const e = new Error('facebook get me error')
	// 		e.name = 'facebookGetMeError'
	// 		throw e
	// 	}

	// 	try {
	// 		const res = await Api.get(`auth/facebook/${authFacebookId}`, {
	// 			headers: { 'x-facebook-token': facebookToken },
	// 		})
	// 		const { memberId, email } = res.data
	// 		return {
	// 			existingMember: { memberId, email },
	// 			authFacebookId,
	// 			facebookToken,
	// 		}
	// 	} catch (err) {
	// 		const e = new Error(err)
	// 		e.name = err.response.data.code
	// 			? err.response.data.code
	// 			: err.response.data.status
	// 		throw e
	// 	}
	// },
	async get() {
		try {
			let loginStatus = await this.getLoginStatus()

			if (loginStatus.status !== 'connected') {
				await this.login()
				loginStatus = await this.getLoginStatus()
				if (loginStatus.status !== 'connected') throw new Error()
			}

			const getMeRes = await this.getMe()
			return {
				authFacebookId: getMeRes.id,
				email: getMeRes.email,
				name: getMeRes.name,
				token: loginStatus.authResponse.accessToken,
			}
		} catch (err) {
			throw new Error('facebook error')
		}
	},
	getLoginStatus() {
		return new Promise(resolve => {
			FB.getLoginStatus(response => {
				/**
				 * {
				 * 	status: 'connected',
				 * 	authResponse: {
				 * 		accessToken: '...',
				 * 		expiresIn:'...',
				 * 		signedRequest:'...',
				 * 		userID:'...'
				 * 	}
				 * }
				 */
				resolve(response)
			})
		})
	},
	login() {
		return new Promise(resolve => {
			FB.login(
				res => {
					resolve(res)
				},
				{ scope: 'public_profile,email' },
			)
		})
	},
	getMe() {
		return new Promise(resolve => {
			FB.api('/me', { fields: 'email,name' }, response => {
				/**
				 * response = {
				 * 	name,
				 *  email,
				 *  id
				 * }
				 */
				resolve({
					id: response.id,
					email: response.email,
					name: response.name,
				})
			})
		})
	},
}
