<template>
	<div>
		<div>
			<v-card
				v-for="tutor in tutors"
				:key="tutor.id"
				outlined
				flat
				rounded="lg"
				style="position: relative"
				class="px-4 py-3 my-2 d-flex align-center tutor-card"
				:class="{
					active: selectedTutor === tutor,
				}"
				@click="SELECT_TUTOR(tutor)"
			>
				<!-- <div
					@click.stop="addFavorite(tutor.id)"
					style="position: absolute; left: 10px; top: 5px"
				>
					<v-icon class="text-h6">mdi-heart-outline</v-icon>
				</div> -->
				<TutorAvatar
					:size="60"
					class="mr-1"
					:id="tutor.id"
					:name="tutor.name"
					:type="selectedType"
				/>
				<div class="px-2" style="width: calc(100% - 85px)">
					<div class="mb-1 d-flex">
						<div class="">
							<div
								v-if="pastTutorIdsForMonth.includes(tutor.id)"
								class="primary--text text-caption text-nls"
							>
								#{{ $t('최근 1달 내 수업') }}
							</div>
							<span class="text-body-1">
								{{ tutor.name_en }}
							</span>
						</div>
						<v-spacer></v-spacer>
						<TutorNation :nation="tutor.nation" />
					</div>
					<div
						v-if="tutor.tag"
						class="mb-1 text-caption d-flex align-center text-truncate"
					>
						<div
							v-for="(tag, index) in tutor.tag.split('|')"
							:key="`${tutor.id}-${tag}`"
						>
							<div class="tag" v-if="index < 3">#{{ tag }}</div>
						</div>
					</div>
					<div class="grey--text text--darken-1 text-caption">
						<v-icon small style="margin-bottom: 2px">mdi-star</v-icon
						>{{ tutor.rating }} ({{ tutor.ratingCount }})
					</div>
				</div>
				<div style="width: 25px">
					<v-icon
						:class="{
							'grey--text text--lighten-1': selectedTutor !== tutor,
							'primary--text': selectedTutor === tutor,
						}"
						>mdi-check-circle-outline</v-icon
					>
				</div>
			</v-card>
			<v-btn
				:disabled="isLoading"
				block
				depressed
				@click="addPage"
				v-if="page < totalPage"
			>
				{{ $t('더 보기') }}({{ page }}/{{ totalPage }})
			</v-btn>
		</div>
	</div>
</template>

<script>
import TutorAvatar from '@/components/common/TutorAvatar'
import TutorNation from '@/components/common/TutorNation'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('book')

export default {
	components: {
		TutorAvatar,
		TutorNation,
	},
	data: () => ({
		isLoading: false,
		page: 1,
		baseUrl: 'https://image.tella.co.kr',
	}),
	computed: {
		...mapGetters([
			'selectedType',
			'selectedTypeEn',
			'selectedTutor',
			'selectedMoment',
		]),
		tutors() {
			return this.$store.getters['book/tutor/tutors']
		},
		totalPage() {
			return this.$store.getters['book/tutor/totalPage']
		},
		pastTutorIdsForMonth() {
			return this.$store.getters['book/tutor/pastTutorIdsForMonth']
		},
		category() {
			return this.$store.getters['book/tutor/category']
		},
	},
	watch: {
		category() {
			this.setTutorInitial()
			this.getTutors()
		},
	},
	created() {
		this.setTutorInitial()
		this.getTutors(true)
	},
	destroyed() {
		this.$store.commit('book/tutor/STORE', null)
	},
	methods: {
		...mapMutations(['SELECT_TUTOR', 'SET_KEYWORD']),
		setTutorInitial() {
			this.page = 1
			this.$store.commit('book/tutor/SET_KEYWORD', null)
			this.$store.commit('book/tutor/STORE', null)
		},
		async getTutors(needPreLoad) {
			try {
				this.isLoading = true
				await this.$store.dispatch('book/tutor/get', {
					needPreLoad,
					page: this.page,
				})
				this.isLoading = false
			} catch (err) {
				alert(
					this.$t(
						'튜터 리스트를 불러오는 데 실패했습니다. 고객센터로 문의주세요.\nERROR CODE: J7SNV02B',
					),
				)
				this.$router.push({ name: 'Home' })
			}
		},

		addPage() {
			this.page++
			this.getTutors()
		},

		// addFavorite(/*tutorId*/) {
		// 	console.log(tutorId)
		// },
	},
}
</script>

<style lang="scss" scoped>
.tag {
	border: 0.5px solid #e5e5e5;
	box-sizing: border-box;
	border-radius: 10px;
	font-size: 10px;
	line-height: 10px;
	letter-spacing: normal;
	color: #838383;
	padding: 3px 5px;
	margin: 0 2px;
}
.tutor-card {
	border: 1px solid #f2f2f2 !important;
	box-sizing: border-box;
	border-radius: 10px;
}
.tutor-card.active {
	background: rgba(30, 120, 255, 0.05);
	border: 1px solid #1e78ff !important;
}
</style>
