<template>
	<div v-if="isLoading === false">
		<section class="d-print-none">
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-window-close</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('수강증') }}</h1>
				</v-col>
				<v-col cols="2">
					<Setting
						:payment="payment"
						:points="points"
						:query="query"
						:passName="passName"
						:availableMoments="availableMoments"
						@issueCertificate="issueCertificate"
					/>
				</v-col>
			</v-row>
		</section>
		<section
			style="overflow-y: auto; height: calc(var(--vh, 1vh) * 100 - 120px)"
			class="pa-5"
		>
			<div class="my-5 d-print-block d-none">
				<div class="text-right">
					<img
						src="@/assets/images/logo/default.png"
						style="width: 80px"
						alt="텔라 홈페이지"
					/>
				</div>
				<h1 class="text-h4 font-weight-bold">{{ $t('수강증') }}</h1>
			</div>
			<Table
				v-if="certificateLanguage === 'kr'"
				:passName="passName"
				:firstCreatedAt="firstCreatedAt"
				:includeTerm="includeTerm"
				:startDate="startDate"
				:endDate="endDate"
				:includePrice="includePrice"
				:payPrice="payPrice"
				:payMethod="payMethod"
				:availableMoments="availableMoments"
				id="printable-area"
			/>
			<TableEn
				v-else-if="certificateLanguage === 'en'"
				:passName="passName"
				:firstCreatedAt="firstCreatedAt"
				:includeTerm="includeTerm"
				:startDate="startDate"
				:endDate="endDate"
				:includePrice="includePrice"
				:payPrice="payPrice"
				:payMethod="payMethod"
				:availableMoments="availableMoments"
				id="printable-area"
			/>
		</section>
		<section class="d-print-none fixed-button-wrap">
			<v-btn
				v-if="userDevice === 'mobile'"
				@click="share"
				tile
				height="56"
				block
				color="primary"
			>
				{{ $t('공유하기') }}</v-btn
			>
			<v-btn v-else @click="print" tile height="56" block color="primary">
				{{ $t('인쇄하기') }}</v-btn
			>
		</section>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import Api from '@/services/index'
import Setting from './Setting'
import Table from './Table'
import TableEn from './TableEn'
const R = require('ramda')

export default {
	components: {
		Setting,
		Table,
		TableEn,
	},
	data: () => ({
		isLoading: false,
		points: [],
		payment: null,
		// passName: null,
		startDate: null,
		endDate: null,
		includeTerm: true,
		certificateLanguage: 'kr',
		includePrice: true,
	}),
	computed: {
		query() {
			return this.$route.query
		},
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		paymentId() {
			return this.$route.query['payment-id']
		},
		groupPointTypeId() {
			return this.$route.query['group-point-type-id']
		},
		passCouponMemberId() {
			return this.$route.query['pass-coupon-member-id']
		},
		pointId() {
			return this.$route.query['point-id']
		},
		firstCreatedAt() {
			const createdAt = R.pipe(
				R.sortWith([R.ascend(R.prop('created_at'))]),
				R.map(p => p.created_at),
			)(this.points)

			return createdAt.length > 0 ? createdAt[0] : undefined
		},
		payMethod() {
			return this.payment
				? this.certificateLanguage === 'kr'
					? this.payment.pg_provider && this.payment.pg_provider === 'kakaopay'
						? this.$t('카카오페이')
						: this.payment.pg_provider && this.payment.pg_provider === 'paypal'
						? this.$t('페이팔')
						: this.payment.pay_method && this.payment.pay_method === 'card'
						? this.$t('신용카드')
						: this.payment.pay_method && this.payment.pay_method === 'trans'
						? this.$t('실시간 계좌이체')
						: this.payment.pay_method && this.payment.pay_method === 'vbank'
						? this.$t('계좌이체')
						: ''
					: this.payment.pg_provider && this.payment.pg_provider === 'kakaopay'
					? 'Kakao Pay'
					: this.payment.pg_provider && this.payment.pg_provider === 'paypal'
					? 'Paypal'
					: this.payment.pay_method && this.payment.pay_method === 'card'
					? 'Credit Card'
					: this.payment.pay_method && this.payment.pay_method === 'trans'
					? 'Real-time Account Transfer'
					: this.payment.pay_method && this.payment.pay_method === 'vbank'
					? 'Account Transfer'
					: ''
				: ''
		},
		talkAssigned() {
			return R.pipe(
				R.filter(p => p.type === 0 && p.is_bonus === 0),
				R.map(p => p.assigned_points),
				R.sum(),
			)(this.points)
		},
		callAssigned() {
			return R.pipe(
				R.filter(p => p.type === 1 && p.is_bonus === 0),
				R.map(p => p.assigned_points),
				R.sum(),
			)(this.points)
		},
		passName() {
			return this.certificateLanguage === 'kr'
				? this.talkAssigned > 0 && this.callAssigned > 0
					? this.$t('톡 %{talkAssigned}회 & 콜 %{callAssigned}회 수강권', {
							talkAssigned: this.talkAssigned,
							callAssigned: this.callAssigned,
					  })
					: this.talkAssigned > 0
					? this.$t('25분톡 %{talkAssigned}회 수강권', {
							talkAssigned: this.talkAssigned,
					  })
					: this.callAssigned > 0
					? this.$t('15분콜 %{callAssigned}회 수강권', {
							callAssigned: this.callAssigned,
					  })
					: this.$t('보너스 수강권')
				: this.talkAssigned > 0 && this.callAssigned > 0
				? `Talk & Call Package`
				: this.talkAssigned > 0
				? `25min Talk`
				: this.callAssigned > 0
				? `15min Call`
				: 'Bonus'
		},
		availableMoments() {
			const startDate = R.pipe(
				R.sortWith([R.ascend(R.prop('start_date'))]),
				R.map(p => p.start_date),
			)(this.points)

			const startMoment =
				startDate.length > 0 ? moment.tz(startDate[0], 'Asia/Seoul') : undefined

			const expiryDate = R.pipe(
				R.filter(
					p =>
						p.is_bonus === 0 &&
						(p.assigned_points !== 1 || p.created_at === this.firstCreatedAt),
				),
				R.sortWith([R.descend(R.prop('expiry_date'))]),
				R.map(p => p.expiry_date),
			)(this.points)

			const expiryMoment =
				expiryDate.length > 0
					? moment.tz(expiryDate[0], 'Asia/Seoul')
					: undefined

			return [startMoment, expiryMoment]
		},
		payPrice() {
			return this.payment
				? this.payment.pg_provider === 'paypal'
					? this.payment.pay_price_USD
					: this.payment.pay_price
				: 0
		},
	},
	created() {
		if (!this.paymentId && !this.passCouponMemberId) {
			alert(
				this.$t(
					'결제 혹은 수강권 코드 쿠폰으로 등록된 수강권만 수강증을 발급할 수 있습니다. 결제 번호 혹은 수강권 코드 쿠폰 번호를 확인해주세요.',
				),
			)
			this.goBack()
		} else {
			this.get()
		}
	},
	methods: {
		wonCommaFormat: won => won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			let query = {}
			if (this.paymentId) query['payment-id'] = this.paymentId
			else if (this.groupPointTypeId)
				query['group-point-type-id'] = this.groupPointTypeId
			else if (this.passCouponMemberId)
				query['pass-coupon-member-id'] = this.passCouponMemberId
			else query['point-id'] = this.points[0].id
			beforeName !== 'PassDetail'
				? this.$router.push({ name: 'PassDetail', query })
				: this.$router.go(-1)
		},
		async get() {
			this.isLoading = true
			this.paymentId
				? await this.getPaymentWithPoints()
				: await this.getPoints()

			if (this.points.length === 0) {
				alert(this.$t('수강증을 발급할 수 있는 수강권이 없습니다.'))
				this.goBack()
			} else {
				this.isLoading = false
			}
		},
		async getPoints() {
			let query = `?member-id=${this.memberId}&active=1&pass-coupon-member-id=${this.passCouponMemberId}`
			const res = await Api.get(`points${query}`)
			this.points = res.data
		},
		async getPaymentWithPoints() {
			const res = await Api.get(
				`payments/${this.paymentId}?member-id=${this.memberId}&status=paid`,
			)
			if (!res.data.payment)
				alert(
					this.$t('결제 상태를 확인할 수 없습니다. 결제 내역을 확인해주세요.'),
				)
			this.payment = res.data.payment
			this.points = res.data.points
		},
		issueCertificate({
			startDate,
			endDate,
			includeTerm,
			certificateLanguage,
			includePrice,
		}) {
			const query = {}
			if (this.paymentId) query['payment-id'] = this.paymentId
			else if (this.passCouponMemberId)
				query['pass-coupon-member-id'] = this.passCouponMemberId

			if (startDate) query['start-date'] = startDate
			if (endDate) query['end-date'] = endDate
			if (includeTerm !== undefined) query['include-term'] = includeTerm
			if (certificateLanguage)
				query['certificate-language'] = certificateLanguage
			if (includePrice !== undefined) query['include-price'] = includePrice

			this.$router
				.replace({
					name: 'Certificate',
					query,
				})
				.catch(() => {})

			this.startDate = startDate
			this.endDate = endDate
			this.includeTerm = includeTerm
			this.certificateLanguage = certificateLanguage
			this.includePrice = includePrice
		},
		share() {
			const url = window.document.location.href
			window.navigator.share({
				title: this.$t(`[텔라] %{passName} 수강증`, {
					passName: this.passName,
				}),
				text: this.$t(`인쇄가 가능한 PC 환경에서 아래의 링크를 클릭하세요.\n`),
				url: url,
			})
		},

		print() {
			window.print()
		},
	},
}
</script>

<style lang="scss" scoped>
* {
	-webkit-print-color-adjust: exact !important; /* Chrome, Safari */
	color-adjust: exact !important; /*Firefox*/
}
@page {
	margin: 0 10mm;
}
</style>
