<template>
	<!-- <v-fade-transition v-if="showTooltip"> -->
	<div class="font-10 font-weight-bold white--text tooltip">
		{{ $t('component.lessonCard.text.200 캐시 받기') }}
		<div class="chat-tail"></div>
	</div>
	<!-- </v-fade-transition> -->
</template>

<script>
export default {
	data() {
		return {
			showTooltip: true,
		}
	},
	// computed: {
	// 	permanetlyNotShow() {
	// 		return (
	// 			localStorage.getItem('rating-button-tooltip') === 'true' ||
	// 			localStorage.getItem('rating-button-tooltip') === true
	// 		)
	// 	},
	// },
	// created() {
	// 	if (this.permanetlyNotShow !== true) this.showTooltip = true
	// },
	methods: {
		// closeTooltip() {
		// 	localStorage.setItem('rating-button-tooltip', true)
		// 	this.showTooltip = false
		// },
	},
}
</script>

<style lang="scss" scoped>
.tooltip {
	z-index: 3;
	color: #fff;
	line-height: 1.1em;
	background: #353535;
	border-radius: 5px;
	width: 75px;
	height: 27px;
	padding-top: 8px;
	text-align: center;
	position: absolute;
	bottom: -45px;
	left: 50%;
	margin-left: -37.5px;

	.chat-tail {
		width: 0;
		height: 0;
		position: absolute;
		top: -5px;
		left: 50%;
		margin-left: -2.5px;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;

		border-bottom: 5px solid #353535;
	}
}
</style>
