<template>
	<div style="min-height: 162.5px">
		<div>
			<h1 class="mb-1 font-18 my-title">
				{{ $t('page.home.nextLesson.title.다가오는 수업') }}
			</h1>

			<div v-if="isLoading === false">
				<AppointedLessonCard
					v-if="nextLessons.length > 0"
					:lesson="nextLesson"
					class="mt-4 mb-2"
					ref="appointedLessonCard"
				/>
				<div
					v-if="nextLessons.length === 0"
					class="white mb-2 mt-4 pa-4"
					style="min-height: 160px; border-radius: 12px"
				>
					<div class="font-15 pt-10 grey--text text--darken-1 text-center">
						{{ $t('page.home.nextLesson.text.예약된 수업이 없습니다.') }}
					</div>
				</div>
			</div>
			<LoadingCard v-else class="mt-4 mb-2" />

			<div
				v-if="isLoading === false"
				class="d-flex align-center box pa-4 hover-pointer"
				@click="goToAppointedLessons()"
				data-test="nextLessonButton"
			>
				<h2 class="font-15 my-text">
					{{ $t('page.home.nextLesson.button.예약된 수업 확인하기') }}
				</h2>
				<v-spacer></v-spacer>
				<span data-test="numberOfLessons">{{ nextLessons.length }}</span>
				{{ $t('page.home.nextLesson.text.개') }}
				<img
					src="@/assets/images/icon/chevron-right-light.png"
					style="
						margin-left: 3px;
						width: 15px;
						margin-top: -1.5px;
						vertical-align: middle;
					"
					alt=""
				/>
			</div>
			<v-skeleton-loader v-else height="64" type="image"></v-skeleton-loader>
			<!-- <CancelledSnackBar /> -->
		</div>
	</div>
</template>

<script>
import { DateTime } from 'luxon'
import { makeDatetime } from '@/helpers/datetime'
import AppointedLessonCard from '@/components/lesson-card/appointed/Wrap.vue'
// import CancelledSnackBar from '@/components/common/CancelledSnackBar.vue'
import LoadingCard from '@/components/lesson-card/Loading.vue'
import scheduleApi from '@/services/schedule'
import EventBus from '@/lib/eventBus'

export default {
	components: {
		AppointedLessonCard,
		LoadingCard,
		// CancelledSnackBar,
	},
	data: () => ({
		isLoading: false,
		lessons: [],
	}),
	computed: {
		nextLessons() {
			return this.lessons
				.filter(l => {
					l.dt = makeDatetime(l.lessonDate, l.lessonTime)
					return l.dt >= DateTime.now() && l.status === 'appointed'
				})
				.sort(function (a, b) {
					return a.dt - b.dt
				})
		},
		nextLesson() {
			return this.nextLessons.length > 0 ? this.nextLessons[0] : undefined
		},
	},
	created() {
		EventBus.$on('lessonIsCancelled', this.getLessons)
		this.getLessons()
	},
	destroyed() {
		EventBus.$off('lessonIsCancelled', this.getLessons)
	},
	methods: {
		goToAppointedLessons() {
			this.$router.push({ name: 'AppointedLessons' })
		},
		async getLessons() {
			this.isLoading = true
			const dt = DateTime.now().toISO()
			this.lessons = await scheduleApi.getLessons({
				gteLessonDatetime: dt,
			})
			this.isLoading = false
		},
	},
}
</script>

<style lang="scss" scoped>
.box {
	background: #ffffff;
	border-radius: 12px;
}
</style>
