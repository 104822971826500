<template>
	<div id="price-box">
		<div class="py-4 d-flex align-center">
			<h1 class="text-body-1 font-weight-medium">
				{{ $t('order.price-box.title') }}
			</h1>
			<v-spacer></v-spacer>
			<div
				@click="$emit('selectCurrency', 'USD')"
				class="d-flex align-center"
				v-if="currency !== 'USD'"
			>
				<span
					class="primary--text text-body-2"
					style="
						border-bottom: solid 1px #0074ff;
						line-height: 1.2em !important;
					"
				>
					{{ $t('order.price-box.button.change-currency') }}</span
				>
				<img
					class="ml-1"
					src="@/assets/icons/exchange-circle-primary.png"
					:alt="$t('order.price-box.img-alt.change-currency')"
				/>
			</div>
			<div
				@click="
					language === 'ja'
						? $emit('selectCurrency', 'JPY')
						: $emit('selectCurrency', 'KRW')
				"
				class="d-flex align-center"
				v-if="currency === 'USD'"
			>
				<span
					class="primary--text text-body-2"
					style="
						border-bottom: solid 1px #0074ff;
						line-height: 1.2em !important;
					"
				>
					{{ $t('order.price-box.button.change-to-krw') }}</span
				>
				<img
					class="ml-1"
					src="@/assets/icons/exchange-circle-primary.png"
					:alt="$t('order.price-box.img-alt.change-to-krw')"
				/>
			</div>
		</div>
		<div class="py-2 d-flex align-center">
			<h1 class="text-body-1 font-weight-medium">
				{{ $t('order.price-box.title.origin-price') }}
			</h1>
			<v-spacer></v-spacer>
			<p class="text-body-1 font-weight-medium price">
				{{ $numberWithCommas(originPrice) }}
				{{ currencyString }}
			</p>
		</div>
		<div class="py-2 d-flex align-center">
			<h1 class="text-body-1 font-weight-medium">
				{{ $t('order.price-box.title.total-discount-amount') }}
			</h1>
			<v-spacer></v-spacer>
			<p class="text-body-1 font-weight-medium price">
				- {{ $numberWithCommas(totalDiscount) }} {{ currencyString }}
			</p>
		</div>
		<div class="py-3 d-flex align-center">
			<img class="mr-1" src="@/assets/icons/L.png" alt="" />
			<h1 class="text-body-1">
				{{ $t('order.price-box.title.basic-discount') }}
			</h1>
			<v-spacer></v-spacer>
			<p class="text-body-1 grey--text text--darken-1 price">
				{{ $numberWithCommas(basicDiscount) }} {{ currencyString }}
			</p>
		</div>
		<CouponPicker
			class="py-3"
			:coupons="coupons"
			:realPrice="realPrice"
			:discountCouponAmount="discountCouponAmount"
			:currency="currency"
			:currencyString="currencyString"
			:planId="plan.id"
			@selectCoupon="selectCoupon"
			@registeredCoupon="$emit('registeredCoupon')"
		/>
		<CashPicker
			class="py-3"
			:price="priceBeforeBonusCash"
			:currency="currency"
			:currencyString="currencyString"
			@selectCash="selectCash"
			v-if="
				[
					109,
					110,
					111,
					112,
					113,
					114,
					'109',
					'110',
					'111',
					'112',
					'113',
					'114',
				].includes(this.plan.id) === false
			"
		/>
		<Recommend
			class="py-3"
			v-if="nthPayment === 0"
			@selectRecommender="selectRecommender"
			:recommendDiscount="recommendDiscount"
			:currencyString="currencyString"
		/>
		<v-divider class="mb-3 mt-5"></v-divider>
		<div class="pt-1 d-flex align-center">
			<h1 class="text-body-1 font-weight-medium">
				{{ $t('order.price-box.title.pay-price') }}
			</h1>
			<v-spacer></v-spacer>
			<p class="text-h2 font-weight-bold price narrow-line-height">
				{{ $numberWithCommas(payPrice) }}
				<span class="text-body-1 font-weight-medium">{{ currencyString }}</span>
			</p>
		</div>
		<p class="text-right text-body-1 price grey--text text--darken-1 pb-3">
			{{
				$t('order.price-box.text.monthly-price', {
					monthlyPrice: $numberWithCommas(monthlyPrice),
					currencyString,
					month: plan.month,
				})
			}}
		</p>
		<div class="estimated-wrap text-right pb-7 d-flex align-center justify-end">
			<div class="estimated">
				{{ $t('order.price-box.title.estimated-cash') }}
			</div>
			{{
				$t('order.price-box.text.estimated-cash', {
					cash: $numberWithCommas(estimatedBonusCash),
				})
			}}
			<EsimatedCashTooltip />
		</div>
	</div>
</template>

<script>
import CouponPicker from './CouponPicker'
import CashPicker from './CashPicker'
import Recommend from './Recommend'
import EsimatedCashTooltip from './EsimatedCashTooltip'

// import { numberWithCommas } from '@/helper/number-format'

export default {
	components: {
		CouponPicker,
		CashPicker,
		Recommend,
		EsimatedCashTooltip,
	},
	props: {
		plan: {
			required: true,
			type: Object,
		},
		coupons: {
			required: true,
			type: Array,
		},
		currency: {
			required: true,
			type: String,
		},
		currencyString: {
			required: true,
			type: String,
		},
		nthPayment: {
			required: true,
			type: Number,
		},
	},
	data() {
		return {
			selectedCoupon: null,
			selectedCash: 0,
			recommenderMemberEmail: null,
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		originPrice() {
			return this.currency === 'JPY'
				? this.plan.origin_talk_price_JPY + this.plan.origin_call_price_JPY
				: this.currency === 'USD'
				? this.$decimalFix(
						this.plan.origin_talk_price_USD + this.plan.origin_call_price_USD,
				  )
				: this.plan.origin_talk_price + this.plan.origin_call_price
		},
		realPrice() {
			return this.currency === 'JPY'
				? this.plan.real_talk_price_JPY + this.plan.real_call_price_JPY
				: this.currency === 'USD'
				? this.$decimalFix(
						this.plan.real_talk_price_USD + this.plan.real_call_price_USD,
				  )
				: this.plan.real_talk_price + this.plan.real_call_price
		},
		totalDiscount() {
			const rate =
				this.currency === 'JPY' ? 10 : this.currency === 'USD' ? 1000 : 1

			const cashToCurrency = this.selectedCash / rate
			return this.$decimalFix(
				this.basicDiscount +
					this.discountCouponAmount +
					cashToCurrency +
					this.recommendDiscount,
			)
		},
		basicDiscount() {
			return this.$decimalFix(this.originPrice - this.realPrice)
		},
		discountCouponAmount() {
			return this.selectedCoupon
				? this.selectedCoupon.discountType === '%'
					? this.$decimalFix(
							(this.realPrice * this.selectedCoupon.discount) / 100,
					  )
					: this.currency === 'JPY'
					? this.$decimalFix(this.selectedCoupon.discount / 10)
					: this.currency === 'USD'
					? this.$decimalFix(this.selectedCoupon.discount / 1000)
					: this.selectedCoupon.discount
				: 0
		},
		recommendDiscount() {
			if (!this.recommenderMemberEmail) return 0
			return this.currency === 'JPY'
				? 2000
				: this.currency === 'USD'
				? 20
				: 20000
		},
		payPrice() {
			return this.currency === 'KRW'
				? Math.floor(this.originPrice - this.totalDiscount)
				: this.$decimalFix(this.originPrice - this.totalDiscount)
		},
		monthlyPrice() {
			return this.currency === 'USD'
				? Math.ceil((this.payPrice / this.plan.month) * 100) / 100
				: Math.ceil(this.payPrice / this.plan.month)
		},
		estimatedBonusCash() {
			const cash = 200
			const questReward =
				this.plan.count_of_week === 2
					? 500
					: this.plan.count_of_week === 3
					? 1500
					: this.plan.count_of_week === 4
					? 1500
					: this.plan.count_of_week === 5
					? 3500
					: 0
			// this.currency === 'JPY' ? 20 : this.currency === 'USD' ? 0.2 : 200
			return (
				(this.plan.talk_points + this.plan.call_points) * cash + questReward
			)
		},
		priceBeforeBonusCash() {
			return this.realPrice - this.discountCouponAmount - this.recommendDiscount
		},
	},
	watch: {
		payPrice(val) {
			this.$emit('updatePayPrice', val)
		},
	},
	created() {
		this.$emit('updatePayPrice', this.payPrice)
	},
	methods: {
		selectCoupon(val) {
			this.selectedCoupon = val
			this.$emit('selectCoupon', val)
		},
		selectCash(val) {
			this.selectedCash = val
			this.$emit('selectCash', val)
		},
		selectRecommender(recommenderMemberEmail) {
			this.recommenderMemberEmail = recommenderMemberEmail
			this.$emit('selectRecommender', recommenderMemberEmail)
		},
	},
}
</script>

<style lang="scss" scoped>
#tella-web .text-body-1.price {
	letter-spacing: normal !important;
}
#tella-web .narrow-line-height {
	line-height: 1.25em !important;
}
.estimated-wrap {
	font-size: 14px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.2px;
	color: #444;
	.estimated {
		margin-right: 5px;
		font-weight: bold;
		letter-spacing: -0.5px;
		text-align: center;
		color: #fff;
		padding: 2px 6px;
		object-fit: contain;
		border-radius: 12.5px;
		border: solid 1px #00c6b6;
		background-color: #00d3c2;
	}
}
</style>

<style lang="scss">
.order-picker.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
	> .v-input__control
	> .v-input__slot {
	padding-right: 5px;
}
.order-picker {
	.v-input__append-inner {
		margin-top: 5px;
	}
}
</style>
