<template>
	<v-container style="max-width: 375px">
		<section class="pt-5 pb-10">
			<a href="https://tella.co.kr">
				<img
					src="@/assets/images/logo/default.png"
					style="width: 50px"
					:alt="$t('img.alt.텔라 홈페이지')"
					class="mb-4"
				/>
			</a>
			<h1 class="text-h2 font-weight-bold white-space-pre">{{ h1 }}</h1>
		</section>
		<v-slide-y-transition v-if="selectedMoment === null">
			<TimePicker
				:nationality="'jp'"
				:timezone="timezone"
				style="max-width: 450px"
				@selectMoment="selectMoment"
				@selectTimezone="selectTimezone"
			/>
		</v-slide-y-transition>
		<section>
			<TrialForm
				:originPhone="phone"
				:originCountryCode="countryCode"
				:originName="name"
				:originTimezone="timezone"
				:selectedMoment="selectedMoment"
				:timezone="timezone"
				@selectMoment="selectMoment"
				@selectTimezone="selectTimezone"
			/>
		</section>
	</v-container>
</template>

<script>
import moment from 'moment-timezone'
import TimePicker from '../common/TimePicker'
import TrialForm from './Form'

export default {
	components: {
		TimePicker,
		TrialForm,
	},
	data() {
		return {
			h1: this.$t('title.book-trial'),
			selectedMoment: null,
			timezone: null,
		}
	},
	computed: {
		phone() {
			return this.$route.query.phone || ''
		},
		name() {
			return this.$route.query.phone || ''
		},
		countryCode() {
			return this.$route.query['country-code'] || ''
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
	},
	created() {
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-3', {
			root: true,
		})
		this.guessTimezone()
	},
	beforeRouteLeave() {
		this.$store.commit('device/SET_BACKGROUND', null, {
			root: true,
		})
	},
	methods: {
		selectMoment(m) {
			this.selectedMoment = m
		},
		guessTimezone() {
			if (this.memberId) {
				this.timezone = this.$store.getters['member/timezone']
				return
			}

			const storageTimezone = localStorage.getItem('_timezone')
			if (storageTimezone) {
				this.timezone = localStorage.getItem('_timezone')
				return
			}

			this.timezone = moment.tz.guess()
		},
		selectTimezone(timezone) {
			this.timezone = timezone
		},
	},
}
</script>

<style lang="scss" scoped></style>
