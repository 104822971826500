<template>
	<div class="d-flex algin-center">
		<div
			class="lesson-menu mr-1"
			:class="{
				active: $route.name === 'ReviewLessonsAll',
				'font-13': language === 'ko',
				'font-10': language !== 'ko',
			}"
			@click="$router.push({ name: 'ReviewLessonsAll' }).catch(() => {})"
		>
			{{ $t('page.reviewList.menu.전체') }}
		</div>
		<div
			class="lesson-menu mr-1"
			:class="{
				active: $route.name === 'ReviewLessonsTalk',
				'font-13': language === 'ko',
				'font-10': language !== 'ko',
			}"
			@click="$router.push({ name: 'ReviewLessonsTalk' }).catch(() => {})"
		>
			{{ $t('page.reviewList.menu.25분톡') }}
		</div>
		<div
			class="lesson-menu mr-1"
			:class="{
				active: $route.name === 'ReviewLessonsCall',
				'font-13': language === 'ko',
				'font-10': language !== 'ko',
			}"
			@click="$router.push({ name: 'ReviewLessonsCall' }).catch(() => {})"
		>
			{{ $t('page.reviewList.menu.15분콜') }}
		</div>
		<div
			class="lesson-menu"
			:class="{
				active: $route.name === 'ReviewLessonsLevelTest',
				'font-13': language === 'ko',
				'font-10': language !== 'ko',
			}"
			@click="
				$router
					.push({
						name: 'ReviewLessonsLevelTest',
						'font-13': language === 'ko',
						'font-10': language !== 'ko',
					})
					.catch(() => {})
			"
		>
			{{ $t('page.reviewList.menu.레벨 진단 리포트') }}
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
	},
}
</script>

<style lang="scss" scoped>
.lesson-menu {
	padding: 7px 14px 6px;
	background: #ffffff;
	border-radius: 30px;
}
.lesson-menu.active {
	background-color: #1a1a1a;
	color: #ffffff;
}
.lesson-menu:hover {
	cursor: pointer;
}
</style>
