export const LOAD = 'LOAD'
export const STORE = 'STORE'
export const SELECT_DATE = 'SELECT_DATE'

export default {
	[LOAD](state, payload) {
		state.isLoading = payload
	},
	[STORE](state, payload) {
		state.times = payload
	},
	[SELECT_DATE](state, payload) {
		state.selectedDate = payload
	},
}
