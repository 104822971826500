var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.stat)?_c('div',[_c('h2',{staticClass:"font-16 font-weight-bold mb-3"},[_vm._v(_vm._s(_vm.$t('문장')))]),_c('div',{staticClass:"analyze-box d-flex align-center mb-5"},[_vm._m(0),_c('span',{staticClass:"font-18 font-weight-medium"},[_c('span',{staticClass:"orange--text text--darken-3 font-weight-bold"},[_vm._v(_vm._s(_vm.$t('%{count}개 문장', { count: _vm.stat.tutee_number_of_sentences, })))]),_vm._v(_vm._s(_vm.$t('을 말했어요.'))+" ")])]),_c('div',{staticClass:"analyze-box mb-4"},[_c('div',{staticClass:"font-18 font-weight-medium mb-2"},[_c('div',{domProps:{"innerHTML":_vm._s(
						_vm.$t('lesson.review.analyze.averageWords', {
							name: _vm.name,
							count: _vm.stat.tutee_length_of_sentences,
						})
					)}})]),_c('div',{staticClass:"white font-12 px-2 py-1",staticStyle:{"border-radius":"14px"}},[_c('span',{staticClass:"pr-2",staticStyle:{"color":"#485363","opacity":"0.45"}},[_vm._v(_vm._s(_vm.$t('trial-report.sentenceStructure.example'))+" ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.sentenceStructureTable.example)+" ")])])]),_c('p',{staticClass:"mb-10 font-14 grey--text text--darken-1"},[_vm._v(" “"+_vm._s(_vm.sentenceStructureTable.description)+"” ")]),_c('div',{staticClass:"d-flex align-center"},[_c('h2',{staticClass:"font-16 font-weight-bold mb-3"},[_vm._v(_vm._s(_vm.$t('첨삭')))]),_c('v-spacer'),_c('div',{staticClass:"hover-pointer grey--text text--darken-1 font-14",on:{"click":function($event){return _vm.$emit('goToCorrection')}}},[_vm._v(" "+_vm._s(_vm.$t('첨삭 탭 바로가기'))+" "),_c('i',{staticClass:"font-12 fa-solid fa-angle-right"})])],1),_c('div',{staticClass:"analyze-box d-flex align-center mb-7"},[_vm._m(1),_c('span',{staticClass:"font-18 font-weight-medium",domProps:{"innerHTML":_vm._s(
					_vm.$t("lesson.review.analyze.correction-count", {
						count: _vm.stat.number_of_corrections,
					})
				)}})]),_c('h2',{staticClass:"font-16 font-weight-bold mb-3"},[_vm._v(_vm._s(_vm.$t('단어')))]),_c('div',{staticClass:"analyze-box d-flex align-center"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-4",staticStyle:{"border-right":"1px solid #e2e6ed"},attrs:{"cols":"6"}},[_c('div',{staticClass:"mb-7 font-14 font-weight-medium white-space-pre"},[_vm._v(" "+_vm._s(_vm.$t('lesson.review.analyze.today-words-count'))+" "),_c('i',{staticClass:"pl-1 fa-solid fa-comment",staticStyle:{"color":"#aeb8c1"}})]),_c('div',{staticClass:"text-right emerald--text font-weight-medium"},[_c('span',{staticClass:"font-weight-bold font-33"},[_vm._v(_vm._s(_vm.stat.tutee_number_of_words))]),_vm._v(_vm._s(_vm.$t('개'))+" ")])]),_c('v-col',{staticClass:"pl-4 pr-1",attrs:{"cols":"6"}},[_c('div',{staticClass:"mb-7 font-14 font-weight-medium white-space-pre"},[_vm._v(" "+_vm._s(_vm.$t('lesson.review.analyze.today-distinct-words-count'))),_c('i',{staticClass:"pl-1 fa-solid fa-comment",staticStyle:{"color":"#aeb8c1"}})]),_c('div',{staticClass:"text-right emerald--text font-weight-medium"},[_c('span',{staticClass:"font-weight-bold font-33"},[_vm._v(_vm._s(_vm.stat.tutee_number_of_distinct_words))]),_vm._v(_vm._s(_vm.$t('개'))+" ")])])],1)],1)]):_c('div',{staticClass:"px-3 py-2"},[_c('p',{staticClass:"text-center grey--text text--darken-1 font-italic"},[_vm._v(" "+_vm._s(_vm.$t('수업 통계 자료를 찾을 수 없습니다.'))+" ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n\t\t\t\t\tfont-12\n\t\t\t\t\torange--text\n\t\t\t\t\ttext--darken-3\n\t\t\t\t\torange\n\t\t\t\t\tlighten-4\n\t\t\t\t\td-inline-block\n\t\t\t\t\ttext-center\n\t\t\t\t",staticStyle:{"width":"24px","height":"24px","border-radius":"12px","padding-top":"3px","margin-right":"8px"}},[_c('i',{staticClass:"fa-solid fa-pen-to-square"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-12 d-inline-block text-center",staticStyle:{"width":"24px","height":"24px","border-radius":"12px","padding-top":"3px","margin-right":"8px","background":"#bfd7f8","color":"#4e62fa"}},[_c('i',{staticClass:"fa-solid fa-pen-to-square"})])}]

export { render, staticRenderFns }