export const SET = 'SET'
export const LOADING = 'LOADING'

export default {
	[LOADING](state, payload) {
		state.isLoading = payload
	},
	[SET](state, payload) {
		state.groups = payload
	},
}
