export const LOAD = 'LOAD'

export const SELECT_METHOD = 'SELECT_METHOD'
export const SELECT_TUTOR = 'SELECT_TUTOR'
export const SELECT_TYPE = 'SELECT_TYPE'
// export const SELECT_DATETIME = 'SELECT_DATETIME'
export const SELECT_MOMENT = 'SELECT_MOMENT'
export const SET_INITIAL = 'SET_INITIAL'

// export const LOAD_TUTOR = 'LOAD_TUTOR'
// export const STORE_TUTOR = 'STORE_TUTOR'
// export const STORE_TUTOR_TOTAL_COUNT = 'STORE_TUTOR_TOTAL_COUNT'
// export const SET_KEYWORD = 'SET_KEYWORD'
// export const SET_SORT_BY = 'SET_SORT_BY'

// export const LOAD_LESSON = 'LOAD_LESSON'
// export const STORE_LESSON = 'STORE_LESSON'

export const LOAD_SLOT = 'LOAD_SLOT'
export const STORE_SLOT = 'STORE_SLOT'
export const SELECT_DATE = 'SELECT_DATE'

export default {
	[LOAD](state, payload) {
		state.isLoading = payload
	},
	[SELECT_METHOD](state, payload) {
		state.method = payload
	},
	[SELECT_TUTOR](state, payload) {
		state.selectedTutor = payload
	},
	[SELECT_TYPE](state, payload) {
		state.selectedType = payload
	},
	[SELECT_MOMENT](state, payload) {
		state.selectedMoment = payload
	},
	[SET_INITIAL](state) {
		state.selectedType = null
		state.selectedDate = null
		state.selectedMoment = null
		state.selectedTutor = null
	},
}
