<template>
	<div id="trial-landing">
		<div v-if="trial && isLoading === false">
			<Header
				:trialMoment="trial.moment"
				:id="trial.id"
				:countryCode="trial.country_code"
				:phone="trial.phone"
				:name="trial.name"
				:timezone="trial.timezone"
				:tutorId="trial.tutor_id"
				:tutorName="trial.tutor_name"
				:language="trial.language"
			/>
			<KakaoTalkInfo v-if="trial.nationality === 'jp'" />
			<PreviewPc class="d-none d-sm-block" />
			<PreviewMobile class="d-block d-sm-none" />
			<Example />
			<Correction />
			<Report />
			<FAQ
				:trialId="trial.id"
				:countryCode="trial.country_code"
				:phone="trial.phone"
			/>
		</div>
		<Dialog
			:dialogControl="errDialog"
			:persistent="errPersistent"
			:numberOfButton="1"
			:title="errTitle"
			:text="errText"
			:actionColor="'primary'"
			:action="errAction"
			:actionText="errActionText"
		/>
		<!-- <NotAvailable
			:dialogControl="error"
			:routeParams="{ name: 'TrialLanding' }"
		/> -->
	</div>
</template>

<script>
import { codeToMoment } from '@/helpers/date-format'
import Api from '@/services/index'
import Header from './Header'
import PreviewPc from './PreviewPc'
import PreviewMobile from './PreviewMobile'
import Example from './Example'
import Correction from './Correction'
import Report from './Report'
import FAQ from './FAQ'
// import NotAvailable from '../common/error-dialog/NotAvailable'
import Dialog from '@/components/common/Dialog'
import KakaoTalkInfo from './KakaoTalkInfo'

/**
 * 아이디와 전화번호로.
 */
export default {
	components: {
		Header,
		KakaoTalkInfo,
		PreviewPc,
		PreviewMobile,
		Example,
		Correction,
		Report,
		FAQ,
		// NotAvailable,
		Dialog,
	},
	data() {
		return {
			isLoading: false,
			trial: null,
			// error: false,
			errDialog: false,
			errPersistent: true,
			errTitle: this.$t('modal.title.can`t-find-trial'),
			errText: '',
			errAction: this.goToTrialBook,
			errActionText: this.$t('modal.button.go-back'),
		}
	},
	computed: {
		trialId() {
			return this.$route.params.trialId
		},
		countryCode() {
			return this.$route.query['country-code']
		},
		phone() {
			return this.$route.query.phone
		},
		isAccessible() {
			return !this.trialId || !this.countryCode || !this.phone ? false : true
		},
	},
	created() {
		if (this.isAccessible === false) {
			this.$router.push({ name: 'Trial' })
			return
		}
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-3', {
			root: true,
		})

		this.get()
	},
	beforeRouteLeave() {
		this.$store.commit('device/SET_BACKGROUND', null, {
			root: true,
		})
	},
	methods: {
		async get() {
			try {
				this.dialog = false
				this.isLoading = true
				const res = await Api.get(
					`trials?id=${this.trialId}&country-code=${this.countryCode}&phone=${this.phone}`,
				)
				if (res.data.length === 0) throw new Error()
				this.trial = res.data[0]
				if (this.trial.status !== 'appointed')
					throw new Error('Status Not Matched')
				this.trial.moment = codeToMoment(
					this.trial.lesson_date,
					this.trial.lesson_time,
					this.$i18n.locale,
				)
				this.isLoading = false
			} catch (err) {
				const errMsg = err.message
				switch (errMsg) {
					case 'Status Not Mached':
						this.errPersistent = true
						this.errTitle = this.$t(
							'modal.title.trial-confirmation-already-passed-or-cancelled',
						)
						this.errText = ''
						this.errAction = this.goToTrialBook
						this.errActionText = this.$t('modal.button.go-back')
						break
					default:
						this.errPersistent = true
						this.errTitle = this.$t('modal.title.can`t-find-trial')
						this.errText = ''
						this.errAction = this.goBack
						this.errActionText = this.$t('modal.button.go-back')
				}
				this.errDialog = !this.errDialog
				return
			}
		},
		goToTrialBook() {
			this.$router.push({ name: 'TrialBook' })
		},
		goBack() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss">
#trial-landing p {
	color: #434e5c;
}
#trial-landing .text--h0,
#trial-landing .text--h1 {
	font-size: 37px;
	line-height: 1.17;
	font-weight: normal;
	letter-spacing: -0.77px;
}
#trial-landing .text--h2 {
	font-size: 33px;
	line-height: 1.36;
	font-weight: normal;
	letter-spacing: -1.32px;
}
#trial-landing .text--h3 {
	font-size: 22px;
	line-height: 1.14;
	letter-spacing: -0.69px;
	font-weight: normal;
}
#trial-landing .text--h4 {
	font-size: 18px;
	line-height: 1.39;
	letter-spacing: -0.56px;
	font-weight: normal;
}
#trial-landing .text--body-1 {
	font-size: 18px;
	line-height: 1.47;
	letter-spacing: -0.53px;
	font-weight: normal;
}
#trial-landing .text--body-2 {
	font-size: 16px;
	line-height: 1.5;
	// letter-spacing: -0.44px;
	font-weight: normal;
}
#trial-landing .text--caption {
	font-size: 14px;
	line-height: 2.08;
	letter-spacing: -0.38px;
	font-weight: normal;
}

@media screen and (max-width: 600px) {
	#trial-landing .text--h0 {
		font-size: 29px;
		line-height: 0.86;
		letter-spacing: -0.6px;
	}
	#trial-landing .text--h1 {
		font-size: 27px;
		line-height: 1.33;
		letter-spacing: -1.25px;
	}
	#trial-landing .text--h3 {
		font-size: 18px;
		line-height: 1.94;
		letter-spacing: -0.1px;
	}
	#trial-landing .text--body-1 {
		font-size: 13px;
		line-height: 1.92;
		letter-spacing: -0.27px;
	}
	#trial-landing .text--body-2 {
		font-size: 14px;
		letter-spacing: normal;
	}
}
</style>
