<template>
	<div
		id="event-from-today-fixed-button"
		style="z-index: 100000000; position: fixed; bottom: 0; width: 100%"
		:style="
			userDevice === 'desktop'
				? 'max-width: 400px;left:50%;margin-left:-200px;'
				: 'left:0;'
		"
	>
		<div
			v-if="isEarlyBird === true"
			class="
				font-weight-bold font-13
				text-center
				d-flex
				align-center
				justify-center
			"
			style="padding: 4px 0px 2.5px; color: #cce6ff; background: #0058c2"
		>
			<img
				style="width: 15px; margin-top: 1px"
				src="@/assets/images/events/2022/from-today/gift.png"
				alt=""
				class="mr-1"
			/>
			얼리버드 구매 시
			<strong class="mx-1 white--text font-weight-black"
				>25분톡 수강권 3개</strong
			>
			추가 증정! (~12/15)
		</div>
		<div
			v-click-outside="onClickOutside"
			style="width: 100%; max-width: 980px; position: relative"
			class="wrap mx-auto py-3 px-2 d-flex align-center"
		>
			<div>
				<div class="from-today-primary--text font-16 font-weight-bold">
					1월 3일 모집 마감
				</div>
				<!-- <h1 v-if="end" class="font-14 font-weight-regular white--text">
					‘레벨업스킬업’ 코드 입력 시
					<strong class="font-weight-bold">23% 더</strong> 할인
				</h1>
				<div
					v-if="end"
					class="white--text font-16 font-weight-bold d-flex align-center"
				>
					마감까지
					<Timer class="ml-1" :end="end" style="width: 120px" v-if="end" />
				</div>
				<div v-else class="from-today-primary--text font-16 font-weight-bold">
					1월 3일 모집 마감
					<Timer class="ml-1" :end="end" style="width: 120px" v-if="end" />
				</div> -->
			</div>
			<v-spacer></v-spacer>
			<!-- <v-btn
				v-if="optionShow === false"
				@click="optionShow = true"
				depressed
				height="50"
				style="background-color: #dbff73"
				width="94"
				><span class="font-14 font-weight-bold">수강권 선택</span></v-btn
			> -->
			<!-- <v-btn
				v-else-if="end !== null"
				:disabled="!selectedPlanId"
				@click="
					$router.push({ name: 'Order', query: { 'plan-id': selectedPlanId } })
				"
				depressed
				height="50"
				color="from-today-primary"
				width="94"
				><span class="font-14 font-weight-bold">수강 신청</span></v-btn
			> -->

			<v-btn
				depressed
				height="50"
				color="from-today-primary"
				@click="endDialog = true"
				><span class="px-1 font-14 font-weight-bold">수강권 선택</span></v-btn
			>
			<div
				style="position: absolute; left: 0; width: 100%; z-index: 3"
				:style="`top: ${isEarlyBird === true ? -313 : -288}px;`"
				v-if="optionShow === true"
			>
				<v-card
					style="border-radius: 0px"
					max-width="980"
					width="100%"
					class="px-2 py-5 font-13"
				>
					<div
						@click="selectedPlanId = 106"
						class="d-flex align-center option-box px-2 py-3 mb-4 hover-pointer"
						:class="{ selected: selectedPlanId === 106 }"
					>
						<div>
							<strong class="font-weight-bold"
								>8:2 플랜 (톡 60회 + 콜 12회, 총 72회 수업)</strong
							><br />
							+ 프로젝트 할인 + 스킬업 쿠폰 23% 적용
						</div>
						<v-spacer></v-spacer>
						462,000 원
					</div>

					<div
						@click="selectedPlanId = 107"
						class="d-flex align-center option-box px-2 py-3 mb-4 hover-pointer"
						:class="{ selected: selectedPlanId === 107 }"
					>
						<div>
							<strong class="font-weight-bold"
								>5:5 플랜 (톡 33회 + 콜 33회, 총 68회 수업)</strong
							><br />
							+ 프로젝트 할인 + 스킬업 쿠폰 23% 적용
						</div>
						<v-spacer></v-spacer>
						462,000 원
					</div>
					<div
						@click="selectedPlanId = 108"
						class="d-flex align-center option-box px-2 py-3 hover-pointer"
						:class="{ selected: selectedPlanId === 108 }"
					>
						<div>
							<strong class="font-weight-bold"
								>2:8 플랜 (톡 12회 + 콜 48회, 총 66회 수업) </strong
							><br />
							+ 프로젝트 할인 + 스킬업 쿠폰 23% 적용
						</div>
						<v-spacer></v-spacer>
						462,000 원
					</div>
				</v-card>
			</div>
		</div>
		<v-dialog v-model="endDialog" width="310">
			<v-card class="py-5 px-3">
				<h1 class="font-19 font-weight-bold mb-3">
					수강신청이 마감되었습니다.
				</h1>
				<p class="mb-5 font-14 font-weight-regular grey--text text--darken-1">
					수강을 원하시는 경우 고객센터로 문의해주세요.
				</p>

				<v-row no-gutters>
					<v-col cols="6" class="pr-1">
						<v-btn
							@click="endDialog = false"
							block
							depressed
							color="grey lighten-3"
							><strong class="font-weight-bold grey--text text--darken-3"
								>닫기</strong
							></v-btn
						>
					</v-col>
					<v-col cols="6" class="pl-1">
						<v-btn
							href="https://tella.channel.io/support-bots/30776"
							block
							depressed
							color="primary"
							><strong class="font-weight-bold">1:1 채팅상담</strong></v-btn
						></v-col
					>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import EventBus from '@/lib/eventBus'
import { DateTime } from 'luxon'
// import Timer from './Timer.vue'

export default {
	components: {
		// Timer,
	},
	data: () => ({
		optionShow: false,
		selectedPlanId: null,
		holdOption: false,
		endDialog: false,
	}),
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		isEarlyBird() {
			return DateTime.now().toISODate() < '2022-12-16'
		},
		end() {
			return DateTime.now().toISODate() < '2022-12-16'
				? DateTime.fromISO('2022-12-16')
				: DateTime.now().toISODate() < '2023-01-04'
				? DateTime.fromISO('2023-01-05')
				: null
		},
	},
	created() {
		EventBus.$on('clickApply', () => {
			this.showOptions()
		})
	},
	methods: {
		showOptions() {
			this.optionShow = true
			this.holdOption = true
			setTimeout(() => {
				this.holdOption = false
			}, 300)
		},
		onClickOutside() {
			if (this.holdOption === false) {
				this.selectedPlanId = null
				this.optionShow = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.wrap {
	background-color: #333;
}
.option-box {
	border-radius: 10px !important;
	box-sizing: border-box;
	height: 66px;
	border: 1px solid #f0f0f0;
}
.option-box.selected {
	border: 2px solid #1a1a1a;
	padding-left: 9px !important;
	padding-right: 9px !important;
}
.option-box:hover {
	background-color: #fafafa;
}
</style>

<style>
#event-from-today-fixed-button
	.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
	background-color: #fff !important;
	color: #000 !important;
}
</style>
