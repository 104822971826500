<template>
	<div id="notice-detail" style="position: relative">
		<section class="fixed-bar-wrap">
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ title }}</h1>
				</v-col>
			</v-row>
		</section>
		<section
			style="margin-top: 60px; max-width: 400px"
			v-if="isLoading === false"
		>
			<div class="pa-5" style="border-bottom: 2px solid #000">
				<h3 class="text-h5 font-weight-bold">{{ notice.subject }}</h3>
				<p class="text-body-1 text-right">
					{{ momentFormat(notice.created_at).format('MM/DD(ddd)') }}
				</p>
			</div>
			<div style="border-bottom: 1px solid #000">
				<div class="pa-5" v-html="notice.content"></div>
			</div>
		</section>
		<!-- <div class="text-center py-5">
			<v-btn
				x-large
				depressed
				min-width="150"
				rounded
				@click="$router.push({ name: 'notices' })"
				>{{ $t('목록으로') }}</v-btn
			>
		</div> -->
	</div>
</template>

<script>
import Api from '@/services/index'
import moment from 'moment-timezone'

export default {
	data: () => ({
		isLoading: false,
		notice: null,
	}),
	computed: {
		id() {
			return Number(this.$route.params.id)
		},
		title() {
			return this.id === 12969 ? this.$t('친구 추천') : this.$t('공지사항')
		},
	},
	created() {
		this.getNotice()
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			beforeName !== 'MyPage' &&
			beforeName !== 'Notice' &&
			beforeName !== 'Home'
				? this.$router.push({ name: 'MyPage' })
				: this.$router.go(-1)
		},
		async getNotice() {
			this.isLoading = true

			const res = await Api.get(
				`boards?board-list-id=${this.id}&board-type-id=1`,
			)
			if (res.data.length === 0) {
				alert(this.$t('게시물을 찾을 수 없습니다.'))
				this.goBack()
			} else {
				this.notice = res.data[0]
				this.isLoading = false
			}
		},
		momentFormat(timecode) {
			return moment.tz(timecode, 'Asia/Seoul').locale('ko')
		},
	},
}
</script>

<style>
#notice-detail table {
	max-width: 380px;
	box-sizing: border-box;
}
</style>
