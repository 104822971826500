<template>
	<div class="white py-4">
		<h1 class="mb-4 px-3 text-body-1 font-weight-medium">
			{{ $t('order.pay-method.title') }}
		</h1>
		<v-row
			no-gutters
			style="padding: 0 10.5px"
			class="mb-5"
			v-if="currency === 'KRW'"
		>
			<v-col cols="6">
				<div
					@click="method = 'card'"
					class="button font-weight-bold primary--text text-center"
					:class="{ active: method === 'card' }"
				>
					{{ $t('order.pay-method.text.card') }}
				</div>
			</v-col>
			<v-col cols="6">
				<div
					@click="method = 'kakaopay'"
					class="button font-weight-bold primary--text text-center"
					:class="{ active: method === 'kakaopay' }"
				>
					{{ $t('order.pay-method.text.kakao-pay') }}
				</div>
			</v-col>
			<v-col cols="6">
				<div
					@click="method = 'vbank'"
					class="button font-weight-bold primary--text text-center"
					:class="{ active: method === 'vbank' }"
				>
					{{ $t('order.pay-method.text.vbank') }}
				</div>
			</v-col>
			<v-col cols="6">
				<div
					@click="method = 'trans'"
					class="button font-weight-bold primary--text text-center"
					:class="{ active: method === 'trans' }"
				>
					{{ $t('order.pay-method.text.trans') }}
				</div>
			</v-col>
		</v-row>
		<div
			v-else-if="currency === 'USD' || currency === 'JPY'"
			style="padding: 0 10.5px"
		>
			<div
				@click="method = 'paypal'"
				class="button font-weight-bold primary--text text-center"
				:class="{ active: method === 'paypal' }"
			>
				Paypal
			</div>
		</div>
		<div
			v-if="method === 'vbank'"
			class="
				mb-2
				grey
				lighten-3
				pa-3
				mx-3
				box
				grey--text
				text--darken-1 text-body-2
			"
		>
			<div class="d-flex align-center font-weight-bold">
				<img
					class="mr-1"
					src="@/assets/icons/info-circle.png"
					:alt="$t('order.pay-method.img-alt.vbank-info')"
				/>
				{{ $t('order.pay-method.title.vbank-info') }}
			</div>
			<div
				class="pt-1 pl-3"
				v-html="$t('order.pay-method.text.vbank-info', { today })"
			></div>
		</div>

		<div
			v-if="method !== 'paypal'"
			class="
				grey
				lighten-3
				pa-3
				mx-3
				box
				d-flex
				align-start
				grey--text
				text--darken-1 text-body-2
			"
		>
			<img
				class="mr-1"
				src="@/assets/icons/info-circle.png"
				:alt="$t('order.pay-method.img-alt.monthly-pay-info')"
			/>
			{{ $t('order.pay-method.text.monthly-pay-info') }}
		</div>
		<div
			v-if="method === 'paypal' && language === 'ko'"
			class="
				mb-2
				grey
				lighten-3
				pa-3
				mx-3
				box
				grey--text
				text--darken-1 text-body-2
			"
		>
			<div class="d-flex align-center font-weight-bold">
				<img
					class="mr-1"
					src="@/assets/icons/info-circle.png"
					:alt="$t('order.pay-method.img-alt.paypal-info')"
				/>
				{{ $t('order.pay-method.title.paypal-info') }}
			</div>
			<div
				class="pt-1 pl-3"
				v-html="
					$t(
						'order.pay-method.text.paypal-info',
						`페이팔 정책에 따라 <span class='primary--text font-weight-bold'>해외에 거주하고, 해외 발급 카드를 소지하신 분</span>에 한해 페이팔 결제를 이용하실 수 있습니다.)`,
					)
				"
			></div>
		</div>
	</div>
</template>

<script>
import moment from 'moment-timezone'

export default {
	props: {
		currency: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			method: 'card',
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		today() {
			return moment().format('YYYY-MM-DD')
		},
	},
	watch: {
		currency(val) {
			if (val === 'USD' || val === 'JPY') {
				this.method = 'paypal'
			} else this.method = 'card'
		},
		method(val) {
			this.$emit('selectPayMethod', val)
		},
	},
	created() {
		if (this.currency === 'USD' || this.currency === 'JPY') {
			this.method = 'paypal'
		} else this.method = 'card'
	},
}
</script>

<style lang="scss" scoped>
.button {
	font-size: 14px;
	border-radius: 10px;
	border: solid 1px #0074ff;
	background-color: #fff;
	margin: 6.5px 4.5px;
	box-sizing: border-box;
	padding: 17px 15px;
	height: 55px;
}
.button:hover {
	cursor: pointer;
}
.button.active {
	border: solid 2px #0074ff;
	background-color: #f2f9ff;
}
.box {
	border-radius: 6px;
}
</style>
