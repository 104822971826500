<template>
	<div v-if="isLoading === false">
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">
						{{
							program.lesson_type === 0
								? `💬&nbsp;&nbsp;${$t('25분톡')}`
								: `📞&nbsp;&nbsp;${$t('15분콜')}`
						}}
						|
						{{ program.name }}
					</h1>
				</v-col>
			</v-row>
		</section>
		<v-img height="180" :src="programImgUrl"> </v-img>
		<section class="pa-5">
			<h2 class="text-body-1 font-weight-medium mb-1">
				{{ $t('프로그램 소개') }}
			</h2>
			<p class="text-body-2">{{ program.description }}</p>
		</section>
		<section
			v-if="isLoading === false"
			class="grey lighten-3 py-5 d-flex align-center text-center justify-center"
		>
			<div class="px-3">
				<p class="text-nls text-caption grey--text text--darken-5 mb-3">
					<small>{{ $t('유닛') }}</small>
				</p>
				<p class="text-nls text-caption font-weight-medium">
					{{ $t('총 %{countOfUnits}개', { countOfUnits: units.length }) }}
				</p>
			</div>
			<v-divider class="grey lighten-2" vertical></v-divider>

			<div class="px-3">
				<p class="text-nls text-caption grey--text text--darken-5 mb-3">
					<small>{{ $t('학습할 표현 수') }}</small>
				</p>
				<p class="text-nls text-caption font-weight-medium">
					{{ $t('%{countOfexpressions}개', { countOfexpressions }) }}
				</p>
			</div>
			<v-divider class="grey lighten-2" vertical></v-divider>

			<div class="px-3">
				<p class="text-nls text-caption grey--text text--darken-5 mb-3">
					<small>{{ $t('영어로 생각하는 시간') }}</small>
				</p>
				<p class="text-nls text-caption font-weight-medium">
					{{
						$t('%{time}시간', {
							time:
								program.lesson_type === 0
									? units.length * 0.5
									: units.length * 0.25,
						})
					}}
				</p>
			</div>
			<v-divider class="grey lighten-2" vertical></v-divider>

			<div class="px-3">
				<p class="text-nls text-caption grey--text text--darken-5 mb-3">
					<small>{{ $t('만들게 될 문장 수') }}</small>
				</p>
				<p class="text-nls text-caption font-weight-medium">
					{{
						$t('수업 당 평균 %{averageOfSentences}개', { averageOfSentences })
					}}
				</p>
			</div>
		</section>
		<section v-if="isLoading === false" class="py-5 px-4">
			<Picker :selectedProgramId="programId" :units="units" />
		</section>
	</div>
</template>

<script>
import Picker from './Picker'
import Api from '@/services/index'
const R = require('ramda')

export default {
	components: {
		Picker,
	},
	data: () => ({
		isLoading: false,
	}),
	computed: {
		program() {
			const programs = this.$store.getters['program/programs']
			return R.find(R.propEq('id', this.programId))(programs)
		},
		programId() {
			return Number(this.$route.params.programId)
		},
		language() {
			return this.$store.getters['member/language']
		},
		programImgUrl() {
			return `https://image.tella.co.kr/v1/program/thumbnail/landscape/${this.language}/${this.programId}.jpg`
		},
		countOfexpressions() {
			let sum = 0
			this.units.forEach(unit => {
				sum = sum + unit.number_of_target_expressions
			})
			return sum
		},
		averageOfSentences() {
			const standards = [0, 0, 0, 18, 20, 26, 30, 34]
			const levels = this.program.program_level.split('|')
			let sum = 0
			levels.forEach(level => {
				sum = sum + standards[level * 1]
			})
			return Math.round(sum / levels.length)
		},
	},
	watch: {
		programId() {
			this.get()
		},
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			this.isLoading = true
			if (!this.program) {
				alert(
					'해당 프로그램은 스페셜 패키지로 판매된 프로그램으로 해당 수강권을 구매하신 학습자님들에게만 제공되는 컨텐츠입니다.',
				)
				this.goBack()
				return
			}
			this.units = []
			// const memberId = this.$store.getters['auth/memberId']
			const unitQuery = `?active=1&sort_by=+unit&program-id=${this.programId}&language=${this.language}`
			const result = await Api.get(`programs/units${unitQuery}`)

			this.units = R.sortWith([
				R.ascend(R.prop('program_id')),
				R.ascend(R.prop('unit')),
			])(result.data)
			this.isLoading = false
		},
		// ...mapActions(['get', 'getProgresses']),
		// async setReady() {
		// 	this.isLoading = true
		// 	let getList = []
		// 	if (this.programs.length === 0) getList.push(this.get())
		// 	// 새로고침, 링크 타고 들어옴 이외에는 접근 방법 없음
		// 	if (this.progresses.length === 0 && this.storeProgressesAt === null) {
		// 		getList.push(this.getProgresses())
		// 	}
		// 	await Promise.all(getList)
		// 	this.isLoading = false
		// },
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			beforeName !== 'Programs'
				? this.$router.push({ name: 'Programs' })
				: this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped></style>
