import { render, staticRenderFns } from "./FacebookSwitch.vue?vue&type=template&id=1e331ae0&scoped=true&"
import script from "./FacebookSwitch.vue?vue&type=script&lang=js&"
export * from "./FacebookSwitch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e331ae0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VImg,VSpacer,VSwitch})
