<template>
	<div class="white pb-14">
		<div style="max-width: 300px" class="mx-auto">
			<div class="d-flex align-center mb-6">
				<h2 class="title3 title-color">
					{{ $t('trial-report.grammar') }}
				</h2>
				<v-spacer></v-spacer>
				<div class="score">
					<div class="d-inline-block dot"></div>
					<p class="d-inline-block sub-title2" style="color: #ef872c">
						{{
							$t('trial-report.score', {
								score: grammarScore,
							})
						}}
					</p>
				</div>
			</div>
			<div>
				<!-- <p class="mb-3 title4 d-inline-block rounded-xl chip title-color">
					어법
				</p> -->
				<p class="text mb-8 default-color">
					{{ description }}
				</p>
				<p class="mb-3 title4 d-inline-block rounded-xl chip title-color">
					{{ $t('trial-report.sentenceStructure') }}
				</p>
				<p class="text mb-8 default-color">
					{{ sentenceStructureTable.description }}
				</p>
			</div>
			<div class="average-card mb-3">
				<p
					class="average-text mb-2"
					v-html="
						$t('trial-report.grammar.average-length-info', { averageLength })
					"
				></p>
				<div class="example-wrap d-flex">
					<div class="example">
						{{ $t('trial-report.sentenceStructure.example') }}
					</div>
					<div>{{ sentenceStructureTable.example }}</div>
				</div>
			</div>
			<div class="average-card pb-4">
				<p
					class="average-text"
					v-html="
						$t('trial-report.grammar.user-length-info', {
							level,
							averageLengthOfLevel,
						})
					"
				></p>
			</div>
		</div>
	</div>
</template>

<script>
const R = require('ramda')

export default {
	props: {
		level: {
			required: true,
			type: Number,
		},
		grammarScore: {
			required: true,
			type: Number,
		},
		sentenceStructureTable: {
			required: true,
			type: Object,
		},
		averageLength: {
			required: true,
		},
	},
	computed: {
		description() {
			const list = [
				{
					score: 1,
					text: this.$t('trial-report.grammar.description-1'),
				},
				{
					score: 2,
					text: this.$t('trial-report.grammar.description-2'),
				},
				{
					score: 3,
					text: this.$t('trial-report.grammar.description-3'),
				},
				{
					score: 4,
					text: this.$t('trial-report.grammar.description-4'),
				},
				{
					score: 5,
					text: this.$t('trial-report.grammar.description-5'),
				},
			]

			const find = R.find(
				R.propEq(
					'score',
					this.grammarScore - this.sentenceStructureTable.score,
				),
			)(list)
			return find ? find.text : 'N/A'
		},
		averageLengthOfLevel() {
			const list = [
				{ level: 3, length: 4.85 },
				{ level: 4, length: 5.36 },
				{ level: 5, length: 5.81 },
				{ level: 6, length: 6.22 },
				{ level: 7, length: 6.97 },
			]

			return R.find(R.propEq('level', this.level))(list).length
		},
	},
}
</script>

<style lang="scss" scoped>
.dot {
	margin-right: 6px;
	margin-bottom: 1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #ef872c;
}
.score {
	padding: 4px 17px;
	border-radius: 10px;
	background-color: #f2f3f6;
}
.chip {
	background-color: #e2e5eb;
	padding: 7px 12px 5px 12px;
}
.average-card {
	padding: 30px 20px;
	border-radius: 16px;
	background-color: #f2f3f6;
}
.average-card p {
	font-family: Pretendard;
	font-size: 16px !important;
	font-weight: 600;
	font-style: normal;
	line-height: 1.25 !important;
	letter-spacing: -0.5px !important;
	color: #404a59 !important;
}
.average-card p.average-text span {
	font-size: 18px !important;
	line-height: 2;
	letter-spacing: -0.41px;
}

.example-wrap {
	padding: 2px 12px 1px 12px;
	border-radius: 14px;
	background-color: #fff;
	font-size: 12px !important;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 2.08 !important;
	letter-spacing: -0.38px !important;
	color: #485363;
}
.example-wrap .example {
	margin-right: 11px;
	opacity: 0.45;
	font-size: 12px !important;
}
</style>
