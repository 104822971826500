<template>
	<div class="pt-12 pb-16" v-if="isLoading === false">
		<div class="top-bar">
			<v-app-bar elevation="0" color="white" height="55">
				<v-icon
					@click="goBack"
					data-test="backButton"
					class="grey--text text--darken-3"
				>
					mdi-arrow-left
				</v-icon>
				<v-spacer></v-spacer>
				<v-toolbar-title>
					<span class="font-18 my-title grey--text text--darken-3"
						>프로그램 변경</span
					>
				</v-toolbar-title>

				<v-spacer></v-spacer>
				<v-icon
					data-test="searchButton"
					@click="
						$router.push({
							name: 'BookProgramSearch',
							query: {
								'lesson-type': lessonTypeWord,
							},
						})
					"
					class="primary--text"
					>mdi-magnify</v-icon
				>
			</v-app-bar>
		</div>
		<LessonInfo class="mx-4 mt-4" :lesson="lesson" />
		<div class="text-center my-2">
			<img
				src="@/assets/images/icon/chevron-right-light.png"
				style="
					width: 18px;
					margin-top: -3px;
					vertical-align: middle;
					transform: rotate(90deg);
					opacity: 0.5;
				"
				alt=""
			/>
		</div>
		<SelectedInfo
			:selectedProgramUnitId="selectedProgramUnitId"
			:unit="unit"
			class="mx-4 mb-6"
		/>
		<div v-if="inProgressPrograms.length > 0" class="primary--0 py-8 mb-2">
			<Curated
				:programs="inProgressPrograms"
				:title="'수강중인 프로그램'"
				id="in-progress-block"
				@selectProgram="selectProgram"
			/>
		</div>
		<Curated
			class="pt-6 pb-2"
			:programs="recommendPrograms"
			:title="'텔라 추천 프로그램'"
			@selectProgram="selectProgram"
		/>
		<Curated
			class="pt-6 pb-2"
			:programs="top10Programs"
			:label="'rank'"
			:title="'텔라 인기 TOP 10'"
			@selectProgram="selectProgram"
		/>

		<Curated
			v-if="newPrograms.length > 0"
			class="pt-6 mb-10"
			:programs="newPrograms"
			:label="'new'"
			:title="'새로 나온 프로그램'"
			id="new-block"
			@selectProgram="selectProgram"
		/>
		<h1 data-test="title" class="pl-4 mb-3 font-18 font-weight-bold">
			프로그램 탐색
		</h1>

		<TopicPicker @selectTopic="selectTopic" class="mb-2" />
		<LevelPicker
			@selectLevel="selectLevel"
			:selectedTopic="selectedTopic"
			:programs="filteredProgramsByTopic"
		/>
		<div class="d-flex justify-end mt-2 mb-1">
			<OrderPicker style="width: 95px" @selectOrderBy="selectOrderBy" />
		</div>

		<List
			class="px-4"
			:programs="filteredProgramsByTopic"
			:selectedLevel="selectedLevel"
			:selectedTopic="selectedTopic"
			:orderBy="orderBy"
			@selectProgram="selectProgram"
		/>
		<div
			v-if="selectedProgramId && selectedProgramUnitId"
			style="
				position: fixed;
				bottom: 10px;
				z-index: 3;
				width: 100%;
				max-width: 400px;
			"
			class="px-4"
			@click="changeUnit()"
		>
			<v-btn
				:disabled="isChanging"
				style=""
				height="50"
				depressed
				block
				color="primary"
				><span class="font-16 font-weight-medium">변경완료</span></v-btn
			>
		</div>
	</div>
</template>

<script>
import { DateTime } from 'luxon'
import Curated from './Curated'
import LevelPicker from './LevelPicker'
import TopicPicker from './TopicPicker'
import OrderPicker from './OrderPicker'
import List from './List'
import LessonInfo from './LessonInfo'
import SelectedInfo from './SelectedInfo'
import studyApi from '@/services/study'
import Api from '@/services/index'
import EventBus from '@/lib/eventBus'

export default {
	components: {
		Curated,
		LevelPicker,
		TopicPicker,
		OrderPicker,
		List,
		LessonInfo,
		SelectedInfo,
	},
	props: {
		beforeRouteInfo: {
			required: false,
		},
		programs: {
			required: true,
			type: Array,
		},
		lessonType: {
			required: true,
			type: Number,
		},
		progresses: {
			required: true,
			type: Array,
		},
		lesson: {
			required: true,
		},
	},
	data() {
		return {
			unit: null,
			isLoading: false,
			recommendIds: [
				// 톡 추천
				119, 68, 123, 29, 33, 60, 132, 133, 203, 71,
				// 콜 추천
				159, 166, 146, 163, 149, 176, 160, 155, 162, 156,
			],
			top10Ids: [
				// 톡 탑텐
				20, 74, 120, 22, 21, 24, 140, 31, 133, 23,
				// 콜 탑텐
				167, 141, 143, 151, 150, 154, 66, 161, 142, 163,
			],
			newIds: [217, 218, 211, 212],
			// progresses: [],
			selectedTopic: 'All',
			selectedLevel: '전체',
			orderBy: 'recent',
			isChanging: false,
		}
	},
	computed: {
		selectedProgramId() {
			return this.$route.params.programId
		},
		selectedProgramUnitId() {
			return this.$route.params.programUnitId
		},
		inProgressIds() {
			return this.progresses.map(p => p.programId)
		},
		recommendPrograms() {
			return this.programs
				.filter(
					p =>
						this.recommendIds.includes(p.id) === true &&
						p.lessonType === this.lessonType,
				)
				.sort(
					(a, b) =>
						this.recommendIds.indexOf(a.id) - this.recommendIds.indexOf(b.id),
				)
		},
		top10Programs() {
			return this.programs
				.filter(
					p =>
						this.top10Ids.includes(p.id) === true &&
						p.lessonType === this.lessonType,
				)
				.sort(
					(a, b) => this.top10Ids.indexOf(a.id) - this.top10Ids.indexOf(b.id),
				)
		},
		newPrograms() {
			return this.programs.filter(
				p =>
					this.newIds.includes(p.id) === true &&
					p.lessonType === this.lessonType,
			)
		},
		inProgressPrograms() {
			return this.programs.filter(
				p =>
					this.inProgressIds.includes(p.id) === true &&
					p.lessonType === this.lessonType,
			)
		},
		lessonTypeWord() {
			return this.lessonType === 1 ? 'call' : 'talk'
		},
		filteredProgramsByTopic() {
			return this.programs.filter(p => {
				return (
					(p.topic && p.topic.includes(this.selectedTopic)) ||
					this.selectedTopic === 'All'
				)
			})
		},
	},
	created() {
		if (this.selectedProgramUnitId) this.get()
	},
	methods: {
		async get() {
			this.isLoading = true
			this.unit = await studyApi.getUnit(this.selectedProgramUnitId)

			this.isLoading = false
		},
		selectTopic(topic) {
			this.selectedTopic = topic
		},
		selectLevel(level) {
			this.selectedLevel = level
		},
		selectOrderBy(orderBy) {
			this.orderBy = orderBy
		},
		goBack() {
			const possibleList = ['Home', 'AppointedLessons', 'LessonsCalendar']
			const name = possibleList.includes(this.beforeRouteInfo.name)
				? this.beforeRouteInfo.name
				: 'Home'
			this.$router.push({ name })
		},
		selectProgram(programId) {
			this.$router.push({
				name: 'ProgramChangeUnit',
				params: { programId },
				query: { 'lesson-type': this.lessonTypeWord },
			})
		},
		async changeUnit() {
			try {
				this.isChanging = true
				if (this.lesson.dt <= DateTime.now()) {
					this.rejectChange('alreadyPassed')
					return
				}

				await Api.patch(`lessons/${this.lesson.id}`, {
					programId: this.selectedProgramId,
					programUnitId: this.selectedProgramUnitId,
				})
				EventBus.$emit('lessonIsCancelled', '프로그램이 변경되었습니다.')
				this.goBack()
			} catch (err) {
				const errCode = err.response.data.code
				const errMsg = err.response.data.message
				if (errCode === '40004') {
					this.rejectChange('Status Not Match')
					return
				}
				if (errCode === '40005') {
					this.rejectChange('Already Passed')
					return
				}

				const alertMsg = this.$t(
					`에러가 발생했습니다.\n\nERROR CODE: %{errCode}\nERROR MESSAGE: %{errMsg}`,
					{ errCode, errMsg },
				)
				alert(alertMsg)

				this.isChanging = false
			}
		},
		rejectChange(x) {
			let msg
			switch (x) {
				case 'No Lesson':
					msg = this.$t('잘못된 수업 번호입니다.')
					break
				case 'Already Passed':
					msg = this.$t('프로그램 변경은 수업 시작 10분전까지 가능합니다.')
					break
				case 'Status Not Match':
					msg = this.$t(
						'수업이 이미 취소되었거나 업로드가 완료되었습니다. 수업의 상태를 확인해주세요.',
					)
					break
				default:
					msg = this.$t(
						'알 수 없는 에러가 발생했습니다.\n고객센터로 문의주세요.',
					)
			}
			alert(msg)
			this.$router.push({ name: 'Home' })
		},
	},
}
</script>

<style lang="scss" scoped>
.top-bar {
	position: fixed;
	z-index: 2;
	top: 0;
	width: 100%;
	max-width: 400px;
	border-bottom: 1px solid #f2f3f5;
}
</style>
