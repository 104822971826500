<template>
	<div>
		<v-form ref="form" v-model="isValid" @submit.prevent="signIn">
			<v-text-field
				:label="$t('common.form.label.email')"
				required
				background-color="white"
				v-model.trim="email"
				:rules="emailRules"
				outlined
				:disabled="isAllLoading"
				style="ime-mode: inactive"
			></v-text-field>
			<v-text-field
				:label="$t('common.form.label.password')"
				required
				background-color="white"
				v-model="password"
				:rules="passwordRules"
				type="password"
				outlined
				:disabled="isAllLoading"
			></v-text-field>
			<div class="d-flex align-center">
				<v-checkbox
					class="mt-0 pt-0"
					v-model="autoLogin"
					hide-details
					:disabled="isAllLoading"
					small
				>
					<template v-slot:label>
						<p class="text-body-2 grey--text text--darken-3">
							{{ $t('login.info.keep-login') }}
						</p>
					</template>
				</v-checkbox>
				<v-spacer></v-spacer>
				<a
					class="text-body-2 grey--text text--darken-3"
					style="text-align: center; text-decoration: unset"
					@click="$router.push({ name: 'FindPassword' })"
				>
					{{ $t('login.button.can`t-login?') }}
					<v-icon>mdi-chevron-right</v-icon>
				</a>
			</div>
			<v-btn
				:disabled="isValid === false || isAllLoading === true"
				class="mt-3"
				depressed
				color="primary"
				block
				large
				type="submit"
				@click.native="loader = 'loading'"
				>{{ $t('login.button.login') }}</v-btn
			>
		</v-form>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="errDialogPersistent"
			:numberOfButton="errNumberOfButton"
			:title="errTitle"
			:text="errMsg"
			:actionColor="errActionColor"
			:action="errAction"
			:actionText="errActionText"
		/>
	</div>
</template>

<script>
import Api from '@/services/index'
import Dialog from '@/components/common/Dialog'
const crypto = require('crypto')

export default {
	components: {
		Dialog,
	},
	props: {
		isAllLoading: {
			required: true,
			type: Boolean,
		},
	},
	data() {
		return {
			isValid: false,
			// === 이메일 로그인 ===
			email: null,
			emailRules: [
				v => !!v || this.$t('common.form.error.email-required'),
				v =>
					(v && v.length <= 100) || this.$t('common.form.error.email-too-long'),
				v => /.+@.+\..+/.test(v) || this.$t('common.form.error.email-format'),
			],
			password: null,
			passwordRules: [
				v => !!v || this.$t('common.form.error.password-required'),
			],
			publicKey: null,
			autoLogin: false,
			// === 에러
			errorDialog: false,
			errTitle: '',
			errMsg: '',
			errNumberOfButton: 1,
			errDialogPersistent: false,
			errActionColor: 'primary',
			errAction: this.closeErrDialog,
			errActionText: this.$t('common.modal.button.confirm'),
		}
	},

	computed: {
		encryptedPW() {
			let encryptedPW = null
			if (this.password) {
				const hashPassword = crypto
					.createHash('md5')
					.update(this.password)
					.digest('hex')
				encryptedPW = crypto
					.publicEncrypt(this.publicKey, Buffer.from(hashPassword, 'utf8'))
					.toString('base64')
			}
			return encryptedPW
		},
	},

	created() {
		this.getPublicKey()
	},
	methods: {
		async getPublicKey() {
			try {
				this.$emit('loading', true)
				const res = await Api.get('/auth/pulic-key')
				this.publicKey = res.data
				this.$emit('loading', false)
			} catch (err) {
				this.errTitle = this.$t('login.error.get-public-key')
				this.errorDialog = true
			}
		},
		gtagEvent() {
			const params = {
				event_category: 'email',
				event_path: `my.tella.co.kr${this.$route.path}`,
			}
			// eslint-disable-next-line no-undef
			gtag('event', 'click_login_btn', params)
		},
		async signIn() {
			try {
				this.gtagEvent()
				this.$emit('loading', true)
				const params = {
					email: this.email,
					encryptedPassword: this.encryptedPW,
					autoLogin: this.autoLogin,
				}
				await Api.post('/auth', params)
				this.$emit('tokenIssued')
			} catch (err) {
				const errorCode =
					err.response && err.response.data ? err.response.data.code : 'F91VL9'

				this.errorHandler(errorCode)

				await this.$store.dispatch('auth/signOut')
			} finally {
				this.$emit('loading', false)
			}
		},
		errorHandler(errorCode) {
			switch (errorCode) {
				case '10001':
					this.errTitle = this.$t('login.error.email-password-wrong')
					break

				case '10008':
					this.errTitle = this.$t('login.error.deleted-error')
					break

				default:
					this.errTitle = this.$t('login.error.default-error-message', {
						errorCode,
					})
			}

			this.errorDialog = true
		},
		closeErrDialog() {
			this.errorDialog = !this.errorDialog
		},
	},
}
</script>

<style lang="scss" scoped></style>
