<template>
	<div>
		<div class="px-2">
			<h2 class="mb-3 text-body-1 font-weight-bold">
				{{ $t('내 영어는 얼마나 유창할까?') }}
			</h2>
		</div>
		<v-card rounded="xl" class="elevation-0 mb-7 yellow-card text-center">
			<div
				style="padding: 6px 8px 3px 8px"
				class="text-h4 mb-3 text-center rounded-pill white d-inline-block"
			>
				🐣
			</div>
			<p class="text-body-2">
				{{ $t('%{name}님이 작성하신 문장의 길이는', { name }) }}<br />
				<span class="text-body-1 font-weight-bold" v-html="averageComment">
				</span>
			</p>
			<div class="text-body-2 d-flex align-center justify-center">
				<div class="ex">{{ $t('예(example)') }}</div>
				<p>{{ example }} ({{ example.split(' ').length }} {{ $t('단어') }})</p>
			</div>
		</v-card>
		<p class="text-body-2 grey--text text--darken-4">“ {{ comment }} ”</p>
	</div>
</template>

<script>
const R = require('ramda')

export default {
	props: {
		stats: {
			required: true,
			type: Array,
		},
	},
	computed: {
		name() {
			return this.$store.getters['member/nameKr']
		},
		sumOfSentence() {
			return R.pipe(
				R.map(s => s.tutee_number_of_sentences),
				R.sum(),
			)(this.stats)
		},
		sumOfWords() {
			return R.pipe(
				R.map(s => s.tutee_number_of_words),
				R.sum(),
			)(this.stats)
		},
		average() {
			return this.sumOfSentence > 0
				? Math.round((this.sumOfWords / this.sumOfSentence) * 100) / 100
				: 0
		},
		averageComment() {
			return this.$t(
				`<span class='yellow--text text--darken-3'>평균 %{average} 단어</span>예요.`,
				{ average: this.average },
			)
		},
		comment() {
			let comments = [
				this.$t(
					'주로 명사와 동사의 조합으로 대화합니다. 형용사와 부사를 사용해서 문장을 더 풍부하게 만들어보세요.',
				),
				this.$t(
					'단순한 문장으로 기초적인 의사전달이 가능합니다. to 부정사나 동명사를 사용하여 여러 형태의 문장을 익혀 보세요.',
				),
				this.$t(
					'대화의 요점을 정확히 이해하고 간혹 접속사를 붙이는 등 긴 문장도 문제없이 만드실 수 있으시네요. 다양한 접속사를 활용하여 복합적인 문장을 만들어 보세요.',
				),
				this.$t(
					'비교적 복합적인 구성의 문장을 자주 구사할 수 있습니다. 관계대명사와 특수문자 등을 활용하여 더 다양한 문장 형태를 연습해 보세요.',
				),
				this.$t(
					'복합적인 문장의 형태로 문장을 자유자재로 구사하실 수 있네요. 실수를 최소화 하여 정확한 문장을 더 빠르게 구성하는 연습을 해 보세요.',
				),
				this.$t('Keep up the good work!'),
			]
			if (this.average < 4) return comments[0]
			else if (this.average < 6) return comments[1]
			else if (this.average < 8) return comments[2]
			else if (this.average < 10) return comments[3]
			else if (this.average < 12) return comments[4]
			else return comments[5]
		},
		example() {
			let example = [
				'They are smart students.',
				'You can go home now.',
				'I am not sure about that.',
				'I will include you in the project.',
				'My teacher told him to lower his voice.',
				'Once you finish your homework, you can watch TV.',
				'My chocolate ice-cream melted and fell on the concrete ground.',
				'Unfortunately, I missed several calls while I was working this afternoon.',
				'Jennifer, whose mother you met yesterday, would like to talk to you.',
				'We had to take a moment together and look at the big picture.',
				'The first thing we assumed is that they might have lots of useful tips.',
				'My personal favorite, which makes sense on some level, is to synthesize all the ideas.',
			]
			if (this.average < 5) return example[0]
			else if (this.average < 15) return example[Math.floor(this.average) - 4]
			else return example[11]
		},
	},
}
</script>

<style lang="scss" scoped>
.yellow-card {
	background: #fefbeb;
	padding: 23px 0 29px 0;
}
.ex {
	background: rgba(30, 120, 255, 0.05);
	width: 27px;
	height: 27px;
	border-radius: 50%;
	padding-top: 3px;
	margin-right: 3px;
}
</style>
