<template>
	<div>
		<div class="d-flex align-start">
			<div
				style="min-width: 70px; max-width: 100px"
				class="flex-grow-0 flex-shrink-1"
			>
				<CountryPicker
					@selectCountry="selectCountry"
					:originCountryCode="countryCode"
					:backgroundColor="'white'"
				/>
			</div>
			<v-text-field
				class="tella-form ml-2"
				:label="$t('common.form.label.phone')"
				ref="phone"
				v-model="phone"
				:dense="dense"
				single-line
				filled
				type="tel"
				:rules="phoneRules"
				:error-messages="
					isPhoneDuplicated === true
						? $t('common.form.error.phone-duplicated')
						: ''
				"
				:disabled="isLoading"
				:readonly="isSentNumber === true"
				:outlined="outlined"
				:background-color="outlined ? 'white' : ''"
				autocomplete="off"
			>
			</v-text-field>
		</div>
		<div class="text-right">
			<div class="d-flex">
				<div style="position: relative; width: 190px">
					<v-text-field
						height="50"
						:dense="dense"
						:label="$t('common.form.label.verify-code')"
						:outlined="outlined"
						:background-color="outlined ? 'white' : ''"
						:disabled="
							isSentNumber === false ||
							isVerified === true ||
							isLoading === true
						"
						:readonly="isVerified === true"
						v-model="code"
						maxlength="6"
					>
					</v-text-field>
					<div
						v-if="isSentNumber"
						class="text-body-1 primary--text"
						style="position: absolute; top: 10px; right: 10px"
						:style="`top: ${dense ? 5 : 12}px; `"
					>
						<span v-if="isVerified === false && codeValid.isError === false">
							{{ dueTimer }}
						</span>
						<span v-if="isVerified" class="pt-3 green--text">
							<i class="fas fa-check"></i>
							{{ $t('common.form.text.verify-code-match') }}
						</span>
						<span v-else-if="codeValid.isError" class="pt-3 red--text">
							<i class="fal fa-times"></i>
							{{ codeValid.errMsg }}
						</span>
					</div>
				</div>
				<v-spacer></v-spacer>
				<v-btn
					v-if="isSentNumber === false"
					x-large
					height="50"
					style="width: calc(100% - 200px)"
					:disabled="phoneValid == false || isLoading === true"
					:loading="isLoading"
					depressed
					:color="actionColor"
					@click="sendVerifyNumber()"
				>
					{{ $t('common.form.text.send-verify-code') }}
				</v-btn>
				<v-btn
					v-else
					style="width: calc(100% - 200px)"
					:disabled="
						isSentNumber === false ||
						!code ||
						isVerified === true ||
						isLoading == true
					"
					:loading="isLoading"
					:small="dense"
					:height="dense ? 40 : 51"
					:color="actionColor"
					depressed
					class="ml-3"
					x-large
					@click="verifyCode()"
				>
					{{ $t('common.form.text.phone-verify') }}
				</v-btn>
			</div>
		</div>

		<p class="mt-2 text-caption" v-if="status === 'pending'">
			{{ $t('common.form.text.verify-code-sent') }}<br />
			{{
				$t(
					'인증번호가 오지 않으면 입력하신 정보가 정확한지 확인하여 주세요. 정확한 정보임에도 인증번호가 오지 않을 시',
				)
			}}
			<u @click="resend" class="primary--text hover-pointer">
				{{ $t('재전송') }}</u
			>{{ $t('을 클릭해주세요.') }}
		</p>
		<p
			class="text-caption white-space-pre"
			v-else-if="status === 'tooManyRequest'"
		>
			{{ $t('common.form.error.verify-code-too-many') }}
		</p>
	</div>
</template>

<script>
import _ from 'lodash'
import allCountries from '@/assets/all-country.js'
import mostSearchedCountries from '@/assets/most-searched-countries.js'
import { errorHandler } from '@/helpers/error-handler'
import CountryPicker from '@/components/common/CountryPicker'
import Api from '@/services/index'
const R = require('ramda')

export default {
	components: {
		CountryPicker,
	},
	props: {
		originPhone: {
			default: null,
		},
		originCountryCode: {
			default: null,
		},
		allowDuplication: {
			default: false,
		},
		dense: {
			default: true,
		},
		outlined: {
			required: false,
			default: false,
			type: Boolean,
		},
		actionColor: {
			required: false,
			default: 'grey darken-3 white--text',
			type: String,
		},
	},
	data: function () {
		return {
			countryDialog: false,
			selectedCountry: null,
			phone: null,
			phoneRules: [
				v => !!v || this.$t('common.form.error.phone-required'),
				v => /^[0-9]*$/.test(v) || this.$t('common.form.error.phone-format'),
			],
			phoneError: false,
			isPhoneDuplicated: null,
			isVerified: false,
			isLoading: false,
			sendMsg: null,
			isSentNumber: false,
			code: null,
			codeValid: {
				isPassed: false,
				isError: false,
				errMsg: null,
			},
			remainingTime: 0,
			status: null,
			timer: null,
		}
	},
	computed: {
		allCountries() {
			return allCountries
		},
		mostSearchedCountries() {
			return mostSearchedCountries
		},
		countryCode() {
			return this.selectedCountry.dialCode
		},
		phoneValid() {
			let valid = false
			if (
				this.phone &&
				this.selectedCountry &&
				/^[0-9]*$/.test(this.phone) &&
				this.isPhoneDuplicated === false
			)
				valid = true
			return valid
		},
		dueTimer() {
			const minutes = parseInt(this.remainingTime / 60)
			const seconds =
				this.remainingTime % 60 >= 10
					? this.remainingTime % 60
					: `0${this.remainingTime % 60}`
			return `${minutes}:${seconds}`
		},
	},
	watch: {
		phone() {
			this.isPhoneDuplicated = null
			this.checkPhoneDuplication()
		},
	},
	created() {
		if (this.originPhone) this.phone = this.originPhone
		if (this.originCountryCode) {
			this.selectedCountry = R.find(
				R.propEq('dialCode', this.originCountryCode.toString()),
			)(this.allCountries)
		} else this.selectedCountry = this.mostSearchedCountries[0]
	},
	destroyed() {
		this.stopTimer()
	},
	methods: {
		selectCountry(country) {
			this.selectedCountry = country
		},
		resend() {
			if (this.remainingTime > 60 * 1) {
				alert(this.$t('common.form.error.retry-verify-code-after-1-minute'))
				return
			}
			this.sendVerifyNumber()
		},
		checkPhoneDuplication: _.debounce(async function () {
			if (this.phone === this.originPhone) {
				this.isPhoneDuplicated = false
				return
			}
			if (this.phone && this.$refs.phone.valid === true) {
				try {
					const res = await Api.get(
						`members/count?phone=${this.phone}&country-code=${this.selectedCountry}`,
					)
					const count = res.data
					this.isPhoneDuplicated = true
					if (count === 0 || this.allowDuplication === true)
						this.isPhoneDuplicated = false
				} catch (err) {
					alert(this.$t('전화번호 중복 검사 중 오류가 발생했습니다.'))
				}
			}
		}, 500),
		async sendVerifyNumber() {
			try {
				this.isLoading = true
				this.isSentNumber = false
				this.remainingTime = 0
				this.stopTimer()
				this.code = null
				await Api.post('auth/verify', {
					countryCode: this.countryCode,
					phone: this.phone,
					category: 'sms',
				})
				this.remainingTime = 60 * 2
				this.startTimer()
				this.isSentNumber = true
			} catch (err) {
				const errResult = errorHandler(err)
				switch (errResult) {
					case '100100':
						this.status = 'tooManyRequest'
						break
					case '100101':
						alert(
							this.$t(
								`인증번호 발송이 실패하였습니다. 입력하신 번호가 정확한지 확인하여 주세요.`,
							),
						)
						break
					default:
						alert(
							this.$t(
								`인증번호 발송 중 오류가 발생하였습니다. 고객센터로 문의주세요.\n ERROR CODE: %{errResult}`,
								{ errResult },
							),
						)
				}
			} finally {
				this.isLoading = false
			}
		},
		async verifyCode() {
			try {
				this.isLoading = true
				const res = await Api.patch('auth/verify', {
					countryCode: this.countryCode,
					phone: this.phone,
					code: this.code,
				})
				const result = res.data
				if (result === 'approved') {
					this.isVerified = true
					this.stopTimer()
					this.$emit('verify', true, {
						countryCode: this.countryCode,
						phone: this.phone,
					})
				}
			} catch (err) {
				this.codeValid.isError = true
				this.codeValid.errMsg = this.$t('인증오류')
				const errResult = errorHandler(err)

				switch (errResult) {
					case '100200':
						this.codeValid.errMsg = this.$t('인증번호를 다시 확인해주세요.')
						break
					default:
						alert(
							this.$t(
								`인증에 문제가 발생했습니다. 고객센터로 문의주세요.\n ERROR CODE: %{errResult}`,
								{ errResult },
							),
						)
				}
			} finally {
				this.isLoading = false
			}
		},
		startTimer() {
			this.timer = setInterval(() => this.countdown(), 1000)
		},
		stopTimer() {
			clearInterval(this.timer)
			this.timer = null
		},
		countdown() {
			if (this.remainingTime >= 1) {
				this.remainingTime--
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.text-field {
	height: 51px;
	width: 80px;
	border-radius: 10px;
}
</style>
