const popularity = [
	74, 20, 22, 103, 120, 140, 23, 133, 24, 31, 119, 21, 68, 123, 29, 30, 34, 107,
	26, 28, 122, 110, 71, 27, 124, 126, 33, 127, 132, 36, 203, 63, 186, 128, 129,
	60, 204, 77, 205, 206, 172, 155, 61, 125, 195, 173, 175, 174, 162, 211, 212,
	196, 193, 158, 192, 171, 191, 194, 157,
]

module.exports = {
	popularity,
}
