<template>
	<div>
		<v-select
			class="program-order-picker font-14"
			:items="items"
			label="Solo field"
			offset-y
			solo
			dense
			append-icon="mdi-chevron-down"
			v-model="orderBy"
			:menu-props="{ contentClass: 'text-center program-order-picker-options' }"
			data-test="program-order-picker"
			@change="selectOrderBy"
			hide-details
		></v-select>
	</div>
</template>

<script>
export default {
	data: () => ({
		orderBy: 'recent',
		items: [
			{ text: '최신순', value: 'recent' },
			{ text: '인기순', value: 'popularity' },
		],
	}),
	methods: {
		selectOrderBy(val) {
			this.$emit('selectOrderBy', val)
		},
	},
}
</script>

<style lang="scss">
.program-order-picker.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
	> .v-input__control
	> .v-input__slot {
	box-shadow: unset;
}
.program-order-picker {
	.v-select__selections {
		text-align: right;
		font-size: 14px !important;
	}
	.v-select.v-input--dense .v-select__selection--comma {
		padding-left: 7px;
	}
	.v-select.v-input--dense .v-select__selection--comma {
		margin: 0 !important;
	}
}
.program-order-picker-options .v-list-item__content .v-list-item__title {
	font-size: 14px !important;
}
</style>
