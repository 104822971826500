<template>
	<div>
		<v-row align="center" no-gutters class="pa-3">
			<v-col cols="2">
				<v-btn @click="goBack" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
			</v-col>
			<v-col cols="8">
				<h1 class="text-center text-body-1 font-weight-regular">
					{{ this.$t('간편 로그인') }}
				</h1>
			</v-col>
		</v-row>
		<section class="pa-5">
			<h2
				class="text-h6 mb-8 font-weight-bold"
				v-html="$t('SNS 계정을 연결해<br />더욱 빠르게 로그인하세요.')"
			></h2>
			<KakaoSwitch @connect="$store.dispatch('member/get')" />
			<v-divider></v-divider>
			<FacebookSwitch @connect="$store.dispatch('member/get')" />
			<v-divider></v-divider>
		</section>
	</div>
</template>

<script>
import KakaoSwitch from './KakaoSwitch'
import FacebookSwitch from './FacebookSwitch'

export default {
	components: {
		KakaoSwitch,
		FacebookSwitch,
	},
	methods: {
		goBack() {
			this.$router.push({ name: 'Setting' })
		},
	},
}
</script>

<style lang="scss" scoped></style>
