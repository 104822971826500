var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"white pa-3 d-flex align-center",class:{ fixed: _vm.fixed === true },attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-inline-block text-body-1 font-weight-bold type package",class:{
					active: _vm.type === 'package',
					'grey--text text--darken-2': _vm.type !== 'package',
				},on:{"click":function($event){return _vm.setType('package')}}},[_vm._v(" "+_vm._s(_vm.$t('plan.type-picker.package'))+" ")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"text-body-1 font-weight-medium type talk",class:{
					active: _vm.type === 'talk',
					'grey--text text--darken-2': _vm.type !== 'talk',
				},on:{"click":function($event){return _vm.setType('talk')}}},[_vm._v(" "+_vm._s(_vm.$t('plan.type-picker.talk'))+" ")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"d-inline-block text-body-1 font-weight-medium type call",class:{
					active: _vm.type === 'call',
					'grey--text text--darken-2': _vm.type !== 'call',
				},on:{"click":function($event){return _vm.setType('call')}}},[_vm._v(" "+_vm._s(_vm.$t('plan.type-picker.call'))+" ")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.fixed === true),expression:"fixed === true"}],staticStyle:{"height":"60px"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }