<template>
	<div>
		<div v-if="showControllBtn" class="d-flex pa-4 align-center">
			<h1 class="font-15 font-weight-regular">프로그램 정보</h1>
			<v-spacer></v-spacer>

			<v-icon @click="show = !show" data-test="controller" small
				>mdi-chevron-{{ show === false ? 'down' : 'up' }}</v-icon
			>
		</div>
		<div v-if="show">
			<div class="px-4 d-flex align-center">
				<div class="stat-box grey lighten-3">
					<h2 class="font-13 font-weight-regular primary--text">
						총
						<span class="font-24 font-weight-bold" data-test="expressions">
							{{ numberOfTotalExpressions }}
						</span>
						개
					</h2>
					<div
						class="
							stat-sub-title
							d-flex
							align-center
							justify-center
							font-13 font-weight-regular
							grey--text
							text--darken-1
						"
					>
						학습할 표현 수
					</div>
				</div>
				<div style="width: 6px"></div>
				<div class="stat-box grey lighten-3">
					<h2 class="font-13 font-weight-regular primary--text">
						<span class="font-24 font-weight-bold" data-test="time">
							{{ listOfUnits.length * timeOfLessonType }}
						</span>
						분
					</h2>
					<div
						class="
							stat-sub-title
							d-flex
							align-center
							justify-center
							font-13 font-weight-regular
							grey--text
							text--darken-1
						"
					>
						영어로<br />생각하는 시간
					</div>
				</div>
				<div style="width: 6px"></div>
				<div class="stat-box grey lighten-3">
					<h2 class="font-13 font-weight-regular primary--text">
						약
						<span class="font-24 font-weight-bold" data-test="sentences">
							{{ listOfUnits.length * 12 }}
						</span>
						개
					</h2>
					<div
						class="
							stat-sub-title
							d-flex
							align-center
							justify-center
							font-13 font-weight-regular
							grey--text
							text--darken-1
						"
					>
						문장 만들기
					</div>
				</div>
			</div>
			<v-divider class="my-6"></v-divider>
			<section data-test="recommended" class="px-4 pb-6">
				<h1 class="mb-2 font-16 font-weight-medium">이런 분들께 추천해요!</h1>
				<ul v-if="recommended" class="font-14 recommended-list">
					<li
						class="pb-1"
						v-for="item in recommended.replace('||', '|').split('|')"
						:key="item"
					>
						{{ item }}
					</li>
				</ul>
			</section>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		listOfUnits: {
			required: true,
			type: Array,
		},
		lessonType: {
			required: true,
			type: Number,
		},
		recommended: {
			required: true,
			type: String,
		},
		showControllBtn: {
			required: true,
			type: Boolean,
		},
	},
	data() {
		return {
			show: true,
		}
	},
	computed: {
		numberOfTotalExpressions() {
			return this.listOfUnits.reduce(
				(a, c) => a + c.numberOfTargetExpressions,
				0,
			)
		},
		timeOfLessonType() {
			return this.lessonType === 1 ? 15 : 25
		},
	},
	created() {
		if (this.showControllBtn === true) this.show = false
	},
}
</script>

<style lang="scss" scoped>
.stat-box {
	padding-top: 10px;
	padding-bottom: 12px;
	border-radius: 8px;
	width: 33%;
	text-align: center;
	.stat-sub-title {
		height: 39px;
	}
}

.recommended-list {
	list-style: none; /* Remove default bullets */
	padding-left: 1em !important;
}
.recommended-list li::before {
	content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
	color: #3172f6;
	font-weight: bold; /* If you want it to be bold */
	display: inline-block; /* Needed to add space between the bullet and the text */
	width: 1em; /* Also needed for space (tweak if needed) */
	margin-left: -1.2em; /* Also needed for space (tweak if needed) */
}
</style>
