<template>
	<v-avatar
		:class="{
			primary: type === 0,
			emerald: type === 1,
		}"
		:size="size"
	>
		<img
			:src="`${baseUrl}/v1/tutor/profile/${id}.png`"
			:alt="name"
			onerror="(this.src = `https://image.tella.co.kr/v1/tutor/profile/none.png`)"
		/>
	</v-avatar>
	<!-- <div
		:style="`border-color:#${typeColor};width:${avatarSize + 8}px;height:${
			avatarSize + 8
		}px;`"
		class="avatar-wrap"
	>
		
	</div> -->
</template>

<script>
export default {
	props: {
		id: {
			require: true,
			type: Number,
		},
		name: {
			require: true,
			type: String,
		},
		type: {
			require: true,
			type: Number,
		},
		size: {
			type: Number,
			defualt: 50,
		},
	},
	data: () => ({
		baseUrl: 'https://image.tella.co.kr',
	}),
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		avatarSize() {
			return this.small === false
				? this.userDevice === 'mobile'
					? 50
					: 60
				: this.userDevice === 'mobile'
				? 29
				: 48
		},
		typeColor() {
			return this.type === 0 ? '3b6eff' : '49ccb2'
		},
	},
}
</script>

<style lang="scss" scoped>
.avatar-wrap {
	border: 2px solid;
	padding: 2px;
	background-color: #fff;
	border-radius: 50% !important;
}
</style>
