<template>
	<div>
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('수강내역') }}</h1>
				</v-col>
			</v-row>
		</section>
		<section class="pa-5">
			<div class="d-flex align-center">
				<h1 class="text-body-1">{{ $t('수강권 코드를 받으셨나요?') }}</h1>
				<v-spacer></v-spacer>
				<Register @registered="get" />
			</div>
		</section>
		<section v-if="isLoading === false">
			<PointGroup
				class="px-5"
				:group="group"
				v-for="(group, index) in groupedPoints"
				:key="`point-${index}`"
			/>
		</section>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import Api from '@/services/index'
import PointGroup from './PointGroup'
import Register from './Register'
const R = require('ramda')

export default {
	components: {
		Register,
		PointGroup,
	},
	data: () => ({
		isLoading: false,
		points: [],
		payments: [],
		passCouns: [],
		groupedPoints: [],
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		paymentIds() {
			return R.pipe(
				R.filter(p => p.payment_id !== null),
				R.map(p => p.payment_id),
				R.uniq(),
			)(this.points)
		},
		groupPointTypeIds() {
			return R.pipe(
				R.filter(p => p.group_point_type_id !== null),
				R.map(p => p.group_point_type_id),
				R.uniq(),
			)(this.points)
		},
		passCouponMemberIds() {
			return R.pipe(
				R.filter(p => p.pass_coupon_member_id !== null),
				R.map(p => p.pass_coupon_member_id),
				R.uniq(),
			)(this.points)
		},
	},
	created() {
		this.get()
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			// const accessDeniedPages = [
			// 	null,
			// 	'BookTimezone',
			// 	'TimeFirstStep2',
			// 	'LessonProgramLanding',
			// 	'LessonProgramBooked',
			// ]
			beforeName !== 'MyPage'
				? this.$router.push({ name: 'MyPage' })
				: this.$router.go(-1)
		},
		async get() {
			try {
				this.isLoading = true
				await this.getPoint()
				this.mappingPoint()
			} catch (err) {
				alert(
					this.$t(
						'결제 내역 및 수강권 쿠폰 내역을 불러오는 데에 실패하였습니다. 고객센터로 문의주세요. \nERROR CODE: 9VNW12',
					),
				)
			} finally {
				this.isLoading = false
			}
		},
		async getPoint() {
			const res = await Api.get(`points?member-id=${this.memberId}&active=1`)
			this.points = res.data
		},
		mappingPoint() {
			const groupedPoints = []

			this.points.forEach(p => {
				const createdDate = moment(p.created_at).format('YYYY-MM-DD')
				let index = -1
				if (p.payment_id !== null) {
					index = R.findIndex(R.propEq('paymentId', p.payment_id))(
						groupedPoints,
					)
				} else if (p.pass_coupon_member_id !== null) {
					index = R.findIndex(
						R.propEq('passCouponMemberId', p.pass_coupon_member_id),
					)(groupedPoints)
				} else if (p.group_point_type_id !== null) {
					index = R.findIndex(
						R.propEq(
							'groupPointTypeId',
							`${p.group_point_type_id}@${createdDate}`,
						),
					)(groupedPoints)
				}

				if (index >= 0) {
					groupedPoints[index].points.push(p)
					if (groupedPoints[index].startDate > p.start_date)
						groupedPoints[index].startDate = p.start_date
					if (groupedPoints[index].endDate < p.expiry_date)
						groupedPoints[index].endDate = p.expiry_date
					if (groupedPoints[index].createdAt < p.created_at)
						groupedPoints[index].createdAt = p.created_at
					if (p.remaining_points > 0) groupedPoints[index].remaining = true
				} else {
					groupedPoints.push({
						startDate: p.start_date,
						endDate: p.expiry_date,
						createdAt: p.created_at,
						remaining: p.remaining_points > 0,
						points: [p],
						paymentId: p.payment_id,
						passCouponMemberId: p.pass_coupon_member_id,
						groupPointTypeId: p.group_point_type_id
							? `${p.group_point_type_id}@${createdDate}`
							: null,
					})
				}
			})

			this.groupedPoints = R.sortWith([
				R.descend(R.prop('endDate')),
				R.descend(R.prop('startDate')),
			])(groupedPoints)
		},
	},
}
</script>

<style lang="scss" scoped></style>
