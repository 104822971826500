var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.lesson)?_c('div',{staticClass:"d-flex align-center"},[_c('h3',{staticClass:"px-2 my-title font-12 font-weight-bold rounded-pill mr-1",class:{
			'primary--text primary--1':
				_vm.lesson.status !== 'analyzed-absent' && _vm.lesson.lessonType === 0,
			'emerald--text emrald--1':
				_vm.lesson.status !== 'analyzed-absent' && _vm.lesson.lessonType === 1,
			'grey--text text--darken-3 grey': _vm.lesson.status === 'analyzed-absent',
		},staticStyle:{"padding-top":"2px","padding-bottom":"2px"},attrs:{"data-test":"typeTag"}},[_vm._v(" "+_vm._s(_vm.lesson.lessonType === 0 ? 'Talk' : 'Call')+" ")]),_c('p',{staticClass:"font-15 my-text font-weight-medium",class:{
			'text-decoration-line-through': _vm.lesson.status === 'analyzed-absent',
		}},[_c('span',{attrs:{"data-test":"startTime"}},[_vm._v(" "+_vm._s(_vm.formattedDate)+"("+_vm._s(_vm.lesson.dt.toFormat('ccc'))+") "+_vm._s(_vm.lesson.dt.toFormat('H:mm')))]),(_vm.lesson.status === 'appointed')?_c('span',{attrs:{"data-test":"endTime"}},[_vm._v(" - "+_vm._s(_vm.lesson.endDt.toFormat('H:mm'))+" ")]):_vm._e(),_c('span',{attrs:{"data-test":"timezone"}},[_vm._v(" ("+_vm._s(_vm.timezone)+")")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }