import Api from './index'
const baseUrl = '/study'

export default {
	async getActivePrograms() {
		try {
			let url = `${baseUrl}/programs`
			const result = await Api.get(url)
			return result.data
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)

			throw new Error(err)
		}
	},
	async getProgramDetails(programId) {
		try {
			let url = `${baseUrl}/programs/${programId}`
			const result = await Api.get(url)
			return result.data
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)

			throw new Error(err)
		}
	},
	async getProgresses({ programId, gteLessonDatetime }) {
		try {
			let url = `${baseUrl}/progresses`
			if (programId) url += `?program-id=${programId}`
			if (gteLessonDatetime) url += `?gte-lesson-datetime=${gteLessonDatetime}`
			const result = await Api.get(url)
			return result.data
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)

			throw new Error(err)
		}
	},
	async getUnit(unitId) {
		try {
			let url = `${baseUrl}/program-unit/${unitId}`
			const result = await Api.get(url)
			return result.data
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)

			throw new Error(err)
		}
	},
}
