<template>
	<img
		@click="$router.push({ name: 'LessonsCalendar' })"
		style="width: 34px"
		src="@/assets/images/schedule/calendar-icon-white-primary-circle.png"
		alt=""
	/>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
