<template>
	<div>
		<v-btn
			@click="signup"
			:disabled="isLoading"
			class="mb-3"
			block
			large
			depressed
			color="kakao"
		>
			<img
				src="@/assets/images/logo/kakao_blank.png"
				alt=""
				style="width: 25px"
				class="mr-1"
			/>
			<p class="text-body-1" style="line-height: unset !important">
				{{ $t('sign-up.button.sign-up-with-kakao') }}
			</p>
			<v-spacer></v-spacer>
		</v-btn>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="errDialogPersistent"
			:numberOfButton="errNumberOfButton"
			:title="errTitle"
			:text="errMsg"
			:actionColor="errActionColor"
			:action="errAction"
			:actionText="errActionText"
		/>
	</div>
</template>

<script>
import Api from '@/services/index'
import kakao from '@/services/kakao'
import Dialog from '@/components/common/Dialog'

export default {
	components: {
		Dialog,
	},
	props: {
		isLoading: {
			required: true,
			type: Boolean,
		},
	},
	data() {
		return {
			kakaoToken: null,
			authKakaoId: null,
			emailFromSocial: null,
			nameFromSocial: null,
			// === 에러
			errorDialog: false,
			errTitle: '',
			errMsg: '',
			errNumberOfButton: 1,
			errDialogPersistent: false,
			errActionColor: 'primary',
			errAction: this.closeErrDialog,
			errActionText: this.$t('common.modal.button.confirm'),
		}
	},
	methods: {
		setInitial() {
			this.emailFromSocial = null
			this.nameFromSocial = null
			this.authKakaoId = null
			this.kakaoToken = null
		},
		gtagEvent(category) {
			const params = {
				event_path: `my.tella.co.kr${this.$route.path}`,
				event_category: category,
			}
			// eslint-disable-next-line no-undef
			gtag('event', 'try_signup', params)
		},
		/**
		 * 1. 카톡으로 로그인한다.
		 * 2. 받아온 정보를 토대로 서버에서 재인증을 진행한다.
		 * 3. 이메일과 kakaoUserId 중복검사를 진행한다.
		 * 	-> 이메일이 이미 있는 경우
		 *  -> kakaoUserId가 이미 있는 경우
		 * 4. 회원가입을 진행한다.
		 */
		async signup() {
			try {
				this.gtagEvent('kakao')
				const { token, email, name, authKakaoId, countryCode, phone } =
					await kakao.get()
				this.$emit('loading', true)
				// 이메일, auth id 중복검사
				const [emailCountRes, authKakaoCountRes] = await Promise.all([
					Api.get(`members/count?email=${email}`),
					Api.get(`members/count?auth-kakao-id=${authKakaoId}`),
				])
				if (emailCountRes.data > 0) throw new Error('email duplicated')
				if (authKakaoCountRes.data > 0)
					throw new Error('authKakaoId duplicated')

				this.$router.push({
					name: 'SignUpFormWithKakao',
					params: {
						kakaoToken: token,
						authKakaoId: authKakaoId,
						countryCodeFromSocial: countryCode,
						phoneFromSocial: phone,
						emailFromSocial: email,
						nameFromSocial: name,
					},
				})
			} catch (err) {
				const errCode = err.message && err.message ? err.message : 'FK20BV9'
				this.errorHandler(errCode)
			} finally {
				this.$emit('loading', false)
			}
		},
		errorHandler(errorCode) {
			switch (errorCode) {
				case 'kakao error':
					this.errTitle = this.$t(
						'sign-up-kakao.error.error-from-kakao-auth-server',
					)
					break
				case 'email duplicated':
					this.errTitle = this.$t('sign-up-kakao.error.email-duplicated')
					break
				case 'authKakaoId duplicated':
					this.errTitle = this.$t('sign-up-kakao.error.kakao-id-duplicated')
					break
				default:
					this.errTitle = this.$t('sign-up-kakao.error.default-error-message', {
						errorCode,
					})
			}
			this.errorDialog = true
		},
		closeErrDialog() {
			this.errorDialog = !this.errorDialog
		},
	},
}
</script>

<style lang="scss" scoped></style>
