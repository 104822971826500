import i18n from '@/i18n'
import moment from 'moment-timezone'

export const LOAD = 'LOAD'
export const INITIALIZE = 'INITIALIZE'
export const STORE = 'STORE'
export const GUESS_TIMEZONE = 'GUESS_TIMEZONE'
export const GUESS_COUNTRY_CODE = 'GUESS_COUNTRY_CODE'
export const GUESS_LANGUAGE = 'GUESS_LANGUAGE'
export const SET_TIMEZONE = 'SET_TIMEZONE'
export const SET_NATIONALITY = 'SET_NATIONALITY'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_IPINFO = 'SET_IPINFO'

export default {
	[LOAD](state, payload) {
		state.isLoading = payload
	},
	[INITIALIZE](state) {
		state.email = null
		state.phone = null
		state.kakaoId = null
		state.loginDate = null
		state.nameKr = null
		state.nameEn = null
		state.regDate = null
		state.authFacebook = false
		state.authKakao = false
		state.status = null
		state.bonusCash = 0
		state.group = 1
		// state.numberOfLessons = 0
	},
	[STORE](state, payload) {
		state.email = payload.Email
		state.phone = payload.Hp
		state.kakaoId = payload.KaKaoId
		state.loginDate = payload.LoginDate
		state.nameKr = payload.MemberName
		state.nameEn = payload.MemberNameEn
		state.regDate = payload.RegDate
		state.countryCode = payload.country_code
		state.authFacebook = payload.facebook_id
		state.authKakao = payload.kakao_id
		state.status = payload.status
		state.timezone = payload.timezone
		state.language = payload.language
		state.nationality = payload.nationality
		state.bonusCash = payload.bonus_cash
		state.group = payload.group
		// state.numberOfLessons = payload.numberOfLessons

		i18n.locale = payload.language
	},
	[SET_TIMEZONE](state, payload) {
		state.timezone = payload
	},
	[SET_LANGUAGE](state, payload) {
		state.language = payload
		i18n.locale = payload
	},
	[SET_NATIONALITY](state, payload) {
		state.nationality = payload
	},
	[GUESS_TIMEZONE](state) {
		const storageTimezone = localStorage.getItem('_timezone')
		if (storageTimezone) {
			state.timezone = localStorage.getItem('_timezone')
			return
		}

		state.timezone = moment.tz.guess()
	},
	[GUESS_COUNTRY_CODE](state) {
		const storageCountryCode = localStorage.getItem('_country_code')
		if (storageCountryCode) {
			state.countryCode = localStorage.getItem('_country_code')
			return
		}

		const guessTimezone = moment.tz.guess()
		state.countryCode = guessTimezone === 'Asia/Tokyo' ? '81' : '82'
	},
	[GUESS_LANGUAGE](state) {
		state.language = navigator.language.slice(0, 2) === 'ja' ? 'ja' : 'ko'
		i18n.locale = state.language
	},
	[SET_IPINFO](state, payload) {
		state.ipinfo = payload
	},
}
