<template>
	<div v-if="isLoading === false">
		<section class="fixed-bar-wrap">
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">
						{{
							program.lessonType === 0
								? `💬&nbsp;&nbsp;${$t('25분톡')}`
								: `📞&nbsp;&nbsp;${$t('15분콜')}`
						}}
						|
						{{ program.name }}
					</h1>
				</v-col>
				<v-col cols="2">
					<TranslateButton
						v-if="notShowTransProgramIds.includes(this.programId) === false"
						@clickTranslateBtn="translate = !translate"
						:translate="translate"
					/>
				</v-col>
			</v-row>
		</section>
		<section class="px-5 pb-3" style="margin-top: 60px">
			<div class="d-flex align-start">
				<v-img
					max-width="50"
					class="rounded"
					:src="`https://image.tella.co.kr/v1/program/thumbnail/portrait/${language}/${lesson.programId}.jpg`"
				>
				</v-img>
				<div class="pl-3 pl-sm-5 text-left">
					<div class="text-body-1 font-weight-medium">
						{{ guide.unit }}. {{ title }}
					</div>
					<div class="text-body-2 text-sm-body-1">{{ learningObjective }}</div>
				</div>
			</div>
		</section>
		<div class="pa-3" v-if="isLoading === false">
			<div class="px-3">
				<p class="text-body-1 font-weight-bold mb-2">{{ $t('표현카드') }}</p>
				<v-img
					:src="expressCardUrl"
					alt
					style="border-radius: 10px !important; width: 100%; max-width: 350px"
				></v-img>
			</div>
			<Guide
				:guide="guide"
				:translate="translate"
				:programUnitId="lesson.programUnitId"
				v-if="isLoading === false"
			/>
		</div>
	</div>
</template>

<script>
import scheduleApi from '@/services/schedule'
import studyApi from '@/services/study'
import TranslateButton from '@/components/program/guide/TranslateButton'
import Guide from '@/components/program/guide/Guide'

export default {
	components: {
		TranslateButton,
		Guide,
	},
	data: () => ({
		isLoading: false,
		lesson: null,
		programId: null,
		programUnitId: null,
		// ======
		program: null,
		unit: null,
		// =====
		translate: true,
	}),
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		lessonId() {
			return this.$route.params.lessonId
		},
		notShowTransProgramIds() {
			return [33, 153, 120, 167]
		},
		title() {
			const titles =
				this.unit && this.unit.unitTitle
					? JSON.parse(this.unit.unitTitle)
					: { ko: [], ja: [] }
			return titles[this.language]
		},

		learningObjective() {
			const learningObjectives =
				this.unit && this.unit.learningObjective
					? JSON.parse(this.unit.learningObjective)
					: { ko: [], ja: [] }
			return learningObjectives[this.language]
		},
		expressCardUrl() {
			return `https://image.tella.co.kr/v1/express-card/${this.language}/${this.lesson.programUnitId}.jpg`
		},
		guide() {
			const guides =
				this.unit && this.unit.guide
					? JSON.parse(this.unit.guide)
					: { ko: [], ja: [] }
			return guides[this.language].sort((a, b) => a.sequence - b.sequence)
		},
	},
	created() {
		this.getLesson()
	},
	methods: {
		async getLesson() {
			this.isLoading = true
			this.lesson = await scheduleApi.findLesson(this.lessonId)
			if (!this.lesson) {
				alert('수업 번호를 확인해주세요.')
				this.$router.go(-1)
				return
			}
			this.programId = this.lesson.programId
			this.programUnitId = this.lesson.programUnitId
			if (!this.programId || !this.programUnitId) {
				alert('프로그램을 먼저 선택해주세요.')
				this.$router.go(-1)
				return
			}
			this.getPreview()
			// eslint-disable-next-line no-undef
			amplitude.getInstance().logEvent('view_preview', {
				lessonId: this.$route.params.lessonId,
				lessonType: this.lesson.lessonType === 0 ? 'talk' : 'call',
			})
		},
		async getPreview() {
			const [program, unit] = await Promise.all([
				studyApi.getProgramDetails(this.programId),
				studyApi.getUnit(this.programUnitId),
			])
			this.program = program
			this.unit = unit
			this.isLoading = false
		},
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			if (
				!beforeName ||
				beforeName === 'LessonProgramLanding' ||
				beforeName === 'BookCompleted'
			) {
				this.$router.push({ name: 'Home' })
				return
			}
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped></style>
