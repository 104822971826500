<template>
	<div @click="goToDetail(list.id)">
		<p class="text-body-1"></p>
		{{ list.subject }}
		<p class="text-body-2 grey--text text--darken-1 text-right">
			{{ createdDate }}
		</p>
	</div>
</template>

<script>
import moment from 'moment-timezone'

export default {
	props: {
		list: {
			required: true,
			type: Object,
		},
	},
	computed: {
		createdDate() {
			return moment.tz(this.list.created_at, 'Asia/Seoul').format('YYYY.MM.DD')
		},
	},
	methods: {
		goToDetail(id) {
			this.$router.push({ name: 'NoticeDetail', params: { id } })
		},
	},
}
</script>

<style lang="scss" scoped></style>
