<template>
	<div class="">
		<p
			class="
				text-body-1
				d-inline-block
				grey--text
				text--darekn-2
				font-weight-medium
			"
			@click="reportSheet = true"
		>
			<v-icon class="text-h6" style="margin-right: 2px"
				>mdi-help-circle-outline</v-icon
			>
			<u>{{ $t('튜터에게 연락이 안왔어요!') }}</u>
		</p>
		<v-bottom-sheet
			v-model="reportSheet"
			max-width="398"
			content-class="rounded-t-xl"
		>
			<v-sheet class="text-center" height="250px" rounded="xl">
				<div style="padding-top: 50px">
					<h1 class="mb-1 text-body-1">
						<span class="mb-1 text-h4">🚨</span><br />
						{{ $t('수업이 진행되지 않았나요?') }}
					</h1>
					<p class="text-body-2 grey--text text--darken-1">
						{{ $t('텔라팀이 직접 연락 드리고 수강권을 보상해드려요.') }}
					</p>
				</div>
				<section class="fixed-button-wrap" style="margin-left: -1px !important">
					<v-row no-gutters>
						<v-col cols="5">
							<v-btn
								:disabled="isLoading"
								color="primary"
								style="background-color: #fff !important"
								dark
								outlined
								large
								block
								height="56"
								tile
								@click="reportSheet = false"
							>
								<strong>{{ $t('아니오') }}</strong>
							</v-btn>
						</v-col>
						<v-col cols="7">
							<v-btn
								:loading="isLoading"
								color="primary"
								dark
								depressed
								large
								block
								height="56"
								tile
								@click="report"
							>
								<strong>{{ $t('네') }}</strong>
							</v-btn>
						</v-col>
					</v-row>
				</section>
			</v-sheet>
		</v-bottom-sheet>
		<v-bottom-sheet v-model="landingSheet" persistent max-width="398">
			<v-sheet
				class="text-center transition-item"
				:height="expansion === false ? '340px' : '590px'"
				rounded="xl"
			>
				<section style="padding-top: 50px" class="pb-4">
					<h1 class="mb-1 text-body-1">
						<span class="mb-1 text-h4">✅</span><br />
						{{ $t('신고가 접수되었습니다.') }}
					</h1>
					<p class="text-body-2 grey--text text--darken-1">
						{{ $t('영업일 기준 24시간 내로 연락드리겠습니다.') }}
					</p>
				</section>
				<section class="text-left py-5 px-5 grey lighten-3">
					<div class="text-body-2 grey--text text--darken-1">
						<strong class="d-block mb-2 font-weight-bold black--text"
							>💌 {{ $t('텔라팀의 편지') }}</strong
						>
						<span
							v-html="
								$t(
									'%{name}님, <br />튜터에게 연락이 오지 않아 당황스러우셨죠?<br />',
									{ name },
								)
							"
						>
						</span>

						<v-expand-transition mode="linear">
							<v-card
								flat
								class="
									mt-2
									grey
									lighten-3
									text-body-2
									grey--text
									text--darken-1
								"
								v-show="expansion === true"
								style="height"
								v-html="
									$t(
										'귀중한 시간 내어주셨는데<br />오래 기다리시게해서 진심으로 죄송합니다.<br /><br />학습에 차질이 없도록<br />영업일 기준 1일 내로 연락드리고, <br />2회 수업 수강권을 지급해드리겠습니다.(수강권 만료일 + 7일까지사용 가능)<br /><br />또한 원인 파악 후 재발 방지를 위해 최선을 다하겠습니다. <br /><br />수업과 관련해 추가로 의견을 주시고 싶으시다면 <br />고객센터로 문의주세요.',
									)
								"
							>
							</v-card>
						</v-expand-transition>
					</div>

					<div
						@click="expansion = !expansion"
						class="mt-2 text-center transition-item"
						:class="{ rotate: expansion === true }"
					>
						<v-icon>mdi-chevron-down</v-icon>
					</div>
				</section>
				<section class="fixed-button-wrap">
					<v-row no-gutters>
						<v-col cols="5">
							<v-btn
								:disabled="isLoading"
								color="primary"
								style="background-color: #fff !important"
								dark
								outlined
								large
								block
								height="56"
								tile
							>
								<strong>{{ $t('고객센터') }}</strong>
							</v-btn>
						</v-col>
						<v-col cols="7">
							<v-btn
								color="primary"
								dark
								depressed
								large
								block
								height="56"
								tile
								@click="$emit('report')"
							>
								<strong>{{ $t('완료') }}</strong>
							</v-btn>
						</v-col>
					</v-row>
				</section>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	props: {
		lesson: {
			require: true,
			type: Object,
		},
	},
	data: () => ({
		isLoading: false,
		reportSheet: false,
		landingSheet: false,
		expansion: false,
	}),
	computed: {
		name() {
			return this.$store.getters['member/nameKr']
		},
	},
	methods: {
		async report() {
			try {
				this.isLoading = true
				await Api.post(`lessons/issue`, {
					category: 1,
					memberId: this.lesson.member_id,
					lessonId: this.lesson.id,
				})
				this.landingSheet = true
			} catch (err) {
				let msg = this.$t('에러가 발생했습니다. 고객센터로 문의주세요.')
				const code = err.response.data.code || 'F01K2I'
				if (code === '40400' || code === '40401')
					msg = this.$t('평가를 할 수 없는 수업입니다.')
				if (code === '40402')
					msg = this.$t(
						'수업의 평가, 혹은 튜터 결석 신고는 24시간 이내에만 가능합니다. 그 이외의 경우 상담톡을 이용해주세요:)',
					)
				if (code === '40403') msg = this.$t('이미 평가 완료된 수업입니다.')
				if (code === '40404')
					msg = this.$t('이미 튜터의 결석이 보고된 수업입니다.')

				alert(`${msg}\n\nERROR CODE: ${code}`)

				this.$emit('report', 'fail')
			} finally {
				this.isLoading = false
				this.reportSheet = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.transition-item {
	transition: 0.2s all linear;
}
.rotate {
	transform: rotate(180deg);
}
</style>
