<template>
	<div class="grey lighten-4" style="height: 100vh">
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('시간 선택') }}</h1>
				</v-col>
				<!-- <v-col cols="2" class="text-right">
					<v-btn fab icon text small>
						<v-icon>mdi-help-circle-outline</v-icon>
					</v-btn>
				</v-col> -->
			</v-row>
			<v-progress-linear :value="progress"></v-progress-linear>
		</section>
		<section class="pa-5">
			<SelectedCard />
		</section>
		<section>
			<div class="d-flex align-center pt-2 pb-4 px-7">
				<p class="text-body-2">
					{{ timezone }}
				</p>
				<v-spacer></v-spacer>
				<p
					@click="$router.push({ name: 'BookTimezone' }).catch(() => {})"
					class="hover-pointer text-body-2"
				>
					{{ $t('타임존 변경') }}<v-icon class="pb-1">mdi-chevron-right</v-icon>
				</p>
			</div>
			<DatePicker class="mb-5" />
			<div class="px-5">
				<h1
					class="
						text-body-1
						font-weight-bold
						grey--text
						text--darken-3
						px-3
						mb-3
					"
				>
					{{ localeSelectedDate }}
				</h1>
				<TimePicker
					v-if="selectedDate"
					style="overflow-y: auto; height: calc(var(--vh, 1vh) * 100 - 388px)"
				/>
			</div>
		</section>
		<div class="white fixed-button-wrap">
			<v-btn
				height="55px"
				:disabled="isLoading === true || selectedMoment === null"
				large
				tile
				block
				color="primary"
				@click="next()"
				>{{ $t('다음') }}</v-btn
			>
		</div>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import TimePicker from './TimePicker'
import DatePicker from './DatePicker'
import SelectedCard from './SelectedCard'

export default {
	components: {
		DatePicker,
		TimePicker,
		SelectedCard,
	},
	data: () => ({
		progress: 33.3,
	}),
	computed: {
		typeEn() {
			return this.$route.params.typeEn
		},
		isLoading() {
			return this.$store.getters['book/time/isLoading']
		},
		selectedDate() {
			return this.$store.getters['book/time/selectedDate']
		},
		selectedMoment() {
			return this.$store.getters['book/selectedMoment']
		},
		dateformat() {
			return this.$store.getters['member/dateformat']
		},
		language() {
			return this.$store.getters['member/language']
		},
		localeSelectedDate() {
			return moment
				.tz(this.selectedDate, this.timezone)
				.locale(this.language)
				.format(this.dateformat)
		},
		timezone() {
			return this.$store.getters['member/timezone']
		},
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			const accessDeniedPages = [
				null,
				'BookTimezone',
				'TimeFirstStep2',
				'LessonProgramLanding',
			]
			accessDeniedPages.includes(beforeName)
				? this.$router.push({ name: 'Home' })
				: this.$router.go(-1)
		},

		next() {
			this.$router.push({
				name: 'TimeFirstStep2',
				params: { typeEn: this.typeEn },
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
