<template>
	<div>
		<LessonTitle class="mb-1" :lesson="lesson" />
		<div class="box pa-4">
			<div class="d-flex align-center mb-3">
				<TutorAvatar
					:id="lesson.tutorId"
					:color="`grey lighten-1`"
					:size="25"
					class="mr-1"
				/>
				<h2 class="font-14 my-text font-weight-regular">
					{{ lesson.tutorName }}
				</h2>
				<v-spacer></v-spacer>
				<div
					data-test="absentText"
					v-if="lesson.status === 'analyzed-absent'"
					class="font-13 font-weight-regular grey--text text--darken-1"
				>
					{{ $t('component.lessonCard.title.학생 결석') }}
				</div>
				<div v-else-if="lesson.ratingScore" class="font-13 primary--text">
					<v-icon
						style="font-size: 20px; margin-top: -3px; color: #f4b448"
						class="mr-1"
						>mdi-star</v-icon
					><span class="grey--text text--darken-3">{{
						lesson.ratingScore
					}}</span>
				</div>
			</div>
			<div>
				<div class="mb-2" v-if="lesson.programName">
					<h1 class="my-text font-14 font-weight-medium">
						{{ lesson.programName }}
					</h1>
					<p
						class="
							my-text
							font-13
							my-text
							grey--text
							text--darken-1
							font-weight-regular
						"
					>
						<span v-if="lesson.programId === 110">{{
							$t('programName.레벨테스트')
						}}</span>
						<span v-else-if="lesson.unitTitle"
							>Unit {{ lesson.unit }}. {{ lesson.unitTitle[language] }}</span
						>
						<span v-else>''</span>
					</p>
				</div>
				<div
					v-else
					style="min-height: 40.5px"
					class="font-14 grey--text text--darken-1"
				>
					{{ $t('component.lessonCard.text.프로그램 미선택') }}
				</div>
				<div class="text-right">
					<v-btn
						class="mr-1"
						color="grey"
						rounded
						outlined
						height="32"
						@click="goToGuide"
						v-if="lesson.programId !== 110 && lesson.programName"
					>
						<span
							class="
								my-text
								grey--text
								text--darken-3
								font-13 font-weight-regular
							"
							>{{ $t('component.lessonCard.button.교재보기') }}
						</span>
					</v-btn>
					<div
						class="d-inline"
						style="position: relative"
						v-if="
							lesson.programId !== 110 &&
							lesson.status === 'analyzed' &&
							canBeRate === true
						"
					>
						<Tooltip />
						<v-btn
							color="grey darken-3"
							rounded
							depressed
							height="32"
							data-test="rateButton"
							@click="goToRate"
							><span
								class="white--text text--darken-3 font-13 font-weight-bold"
								>{{ $t('component.lessonCard.button.수업평가') }}</span
							></v-btn
						>
					</div>
					<v-btn
						v-if="lesson.status === 'analyzed' && canBeRate === false"
						color="grey darken-3"
						rounded
						depressed
						height="32"
						data-test="reviewButton"
						@click="goToReview"
						><span
							class="white--text text--darken-3 font-13 font-weight-bold"
							>{{ $t('component.lessonCard.button.복습하기') }}</span
						></v-btn
					>
					<LevelReportButton
						class="ml-1"
						v-if="lesson.status === 'analyzed' && lesson.programId === 110"
						:lesson="lesson"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { DateTime } from 'luxon'
import TutorAvatar from '../TutorAvatar'
import LessonTitle from '../LessonTitle'
import LevelReportButton from './LevelReportButton'
import Tooltip from './Tooltip'

export default {
	components: {
		TutorAvatar,
		LessonTitle,
		LevelReportButton,
		Tooltip,
	},
	props: {
		lesson: {
			type: Object,
		},
	},
	data: () => ({}),
	computed: {
		phone() {
			return this.$store.getters['member/phone']
		},
		language() {
			return this.$store.getters['member/language']
		},
		countryCode() {
			return this.$store.getters['member/countryCode']
		},
		canBeRate() {
			return (
				!this.lesson.ratingScore &&
				this.lesson.dt.plus({ hours: 24 }) > DateTime.now()
			)
		},
	},
	methods: {
		goToReview() {
			this.$router.push({
				name: 'LessonReview',
				params: {
					lessonId: this.lesson.id,
				},
			})
		},
		goToRate() {
			this.$router.push({
				name: 'Rating',
				params: { lessonId: this.lesson.id },
			})
		},
		goToGuide() {
			this.$router.push({
				name: 'ProgramGuide',
				params: {
					programId: this.lesson.programId,
					unitId: this.lesson.programUnitId,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.box {
	background-color: #fff;
	min-height: 160px;
	border-radius: 12px;
}
</style>
