<template>
	<div>
		<v-row align="center" no-gutters class="pa-3">
			<v-col cols="2">
				<v-btn @click="goBack" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
			</v-col>
			<v-col cols="8">
				<h1 class="text-center text-body-1 font-weight-regular">
					{{ $t('수업 알림') }}
				</h1>
			</v-col>
		</v-row>
		<section class="pa-5">
			<h2
				class="text-h6 mb-1 font-weight-bold"
				v-html="$t('이벤트·혜택 정보를<br />알려드려요.')"
			></h2>
			<p
				class="text-body-2 mb-8 grey--text text--darken-3"
				v-html="
					$t(
						'수업 수강에 도움이 되는 할인 쿠폰·프로모션, <br />이벤트 정보를 알려드려요.',
					)
				"
			></p>

			<div class="d-flex align-center">
				<p @click="sms = !sms" class="text-body-1 font-weight-bold">SMS</p>
				<v-spacer></v-spacer>
				<v-switch
					@change="changeProfile"
					v-model="sms"
					inset
					:disabled="isLoading"
				></v-switch>
			</div>
			<v-divider></v-divider>

			<div class="d-flex align-center">
				<p @click="email = !email" class="text-body-1 font-weight-bold">
					EMAIL
				</p>
				<v-spacer></v-spacer>
				<v-switch
					@change="changeProfile"
					v-model="email"
					inset
					:disabled="isLoading"
				></v-switch>
			</div>
			<v-divider class="mb-8"></v-divider>

			<ul class="grey--text text--darken-1 text-body-2">
				<li>
					{{ $t('결제 정보, 수업 전/후 알림, 잔여 수강권 알림은') }}<br />
					{{ $t('이벤트·혜택 정보 수신 동의 여부와 무관하게 전송됩니다.') }}
				</li>
			</ul>
		</section>
	</div>
</template>

<script>
// import EditProfile from '@/components/myinfo/Profile'
import Api from '@/services/index'

export default {
	components: {
		// EditProfile,
	},
	data: () => ({
		isLoading: false,
		sms: false,
		email: false,
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			this.isLoading = true
			const res = await Api.get(`members/${this.memberId}`)
			this.sms = res.data.marketing_sms === 1
			this.email = res.data.marketing_email === 1
			this.isLoading = false
		},
		async changeProfile() {
			try {
				this.isLoading = true
				let params = {
					marketingSms: this.sms,
					marketingEmail: this.email,
				}
				await Api.patch(`members/${this.memberId}`, params)
				// this.$router.push({ name: 'myhome-info' })
			} catch (err) {
				alert(this.$t('회원 정보 변경이 실패하였습니다. 다시 시도해주세요.'))
			} finally {
				this.isLoading = false
			}
		},
		goBack() {
			this.$router.push({ name: 'Setting' })
		},
	},
}
</script>

<style scoped></style>
