<template>
	<div
		class="d-flex align-center py-3"
		style="border-bottom: 1px solid #dadada"
		:class="{
			add: history.operator === 'ADD',
			sub: history.operator === 'SUB',
		}"
	>
		<div class="operator mr-3" outlined>
			{{ history.operator === 'SUB' ? $t('사용') : $t('적립') }}
		</div>
		<div style="max-width: calc(100% - 120px)">
			<p class="text-caption grey--text text--darken-1">
				{{ history.created_at }}
			</p>
			<p class="text-body-2 font-weight-bold">
				{{ history.content }}
			</p>
		</div>
		<v-spacer></v-spacer>
		<div class="text-right won text-body-2" style="width: 60px">
			{{ numberWithCommas(history.cash) }}<br v-if="language === 'ja'" />
			{{ $t('cash') }}
		</div>
	</div>
</template>

<script>
import { numberWithCommas } from '@/helpers/number-format'

export default {
	props: {
		history: {
			required: true,
			type: Object,
		},
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
	},
	methods: {
		numberWithCommas: number => numberWithCommas(number),
	},
}
</script>

<style lang="scss" scoped>
.operator {
	border-radius: 15px;
	border-width: 2px;
	border-style: solid;
	padding: 0 5px;
	font-size: 14px;
	font-weight: bold;
}
.add .operator {
	border-color: #1e78ff;
	color: #1e78ff;
}
.sub .operator {
	border-color: #ff8a8a;
	color: #ff8a8a;
}
.won {
	font-weight: medium;
}
.add .won {
	color: #1e78ff;
}
.sub .won {
	color: #ff8a8a;
}
</style>
