<template>
	<div>
		<v-app-bar
			id="program-detail-header"
			fixed
			color="white"
			flat
			height="55"
			style="
				max-width: 400px;
				margin: 0 auto;
				border-bottom: 1px solid #f2f3f5 !important;
			"
			class="mx-auto px-0"
		>
			<v-row align="center" no-gutters class="mx-n3">
				<v-col cols="1">
					<v-btn fab icon text small @click="goBack">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col
					cols="10"
					class="text-truncate font-16 font-weight-medium text-center"
				>
					{{ $t('복습노트 인쇄/다운로드') }}
				</v-col>
			</v-row>
		</v-app-bar>
	</div>
</template>

<script>
export default {
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
	},

	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			if (beforeName !== this.$route.name) this.$router.go(-1)
			else {
				this.$router.push({
					name: 'LessonReview',
					params: { lessonId: this.id },
				})
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
