import moment from 'moment-timezone'
import { momentToCode } from '@/helpers/date-format'
const R = require('ramda')
// import i18n from '@/i18n'

export default {
	isLoading: state => state.isLoading,
	selectedDate: state => state.selectedDate,
	startMoment: (state, getters, rootState, rootGetters) =>
		state.selectedDate
			? moment
					.tz(state.selectedDate, rootGetters['member/timezone'])
					.startOf('day')
			: null,
	endMoment: (state, getters, rootState, rootGetters) =>
		state.selectedDate
			? moment
					.tz(state.selectedDate, rootGetters['member/timezone'])
					.endOf('day')
			: null,
	times: (state, getters, rootGetters) =>
		state.times.map(time => {
			// 현재 예약된 수업과 같은 slot 찾기
			time.isDuplicated = isDuplicated(
				time,
				rootGetters['book/selectedType'],
				rootGetters['book/selectedTutor'],
				rootGetters['book/appointedLessons'],
			)
			return time
		}),
	groupByDate: (state, getters, rootState, rootGetters) => {
		const byDate = R.groupBy(function (time) {
			const date = moment(time.moment)
				.tz(rootGetters['member/timezone'])
				.format(`YYYY-MM-DD`)
			return date
		})
		return byDate(getters.times)
	},
}

// function isNotCancelled(lesson) {
// 	return lesson.status !== 'cancelled' && lesson.status !== 'admin-cancelled'
// }

// function isAppointed(lesson) {
// 	return lesson.status === 'appointed' && lesson.moment >= moment()
// }
// function isTalkLesson(lesson) {
// 	return lesson.lesson_type === 0
// }
// function isCallLesson(lesson) {
// 	return lesson.lesson_type === 1
// }

function isDuplicated(time, selectedType, selectedTutor, lessons) {
	const timeMoment = moment(time.moment)
	const timeDate = timeMoment.format('YYYY-MM-DD')
	const timeCode = momentToCode(timeMoment, selectedType)
	if (selectedTutor) {
		const appointedLessonsWithSameTutorAndSameTime = R.filter(
			R.allPass([
				R.propEq('lesson_date', timeDate),
				R.propEq('lesson_time', timeCode),
				// R.propEq('lesson_type', selectedType),
				R.propEq('tutor_id', selectedTutor.id),
			]),
			lessons,
		)
		if (appointedLessonsWithSameTutorAndSameTime.length > 0) return true
	}
	return false
}
