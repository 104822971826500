import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
	default: { props: { ripple: false } },
	theme: {
		themes: {
			light: {
				primary: '#0073ff', // sky
				emerald: '#00cca1', // emerald
				pink: '#ff2b78', // pink
				'pagoda-red': '#A72124', // pink
				kakao: '#ffe200',
				facebook: '#1778F2',
				'indigo darken-6': '#1c2187',
				'indigo darken-5': '#4626ff',
				'call-cyan': '#00bdbf',
				'package-purple': '#8400ff',
				'dark-yellow': '#efc45e',
				'primary--0': '#F2F9FF',
				'primary--1': '#CCE6FF',
				'primary--2': '#87C3FF',
				'primary--3': '#4DA6FF',
				'primary--4': '#2186FF',
				'primary--5': '#0074FF',
				'primary--6': '#0062D9',
				'primary--7': '#0058C2',
				'emrald--1': '#D6EFEA',
				red: '#FF6060',
				'from-today-primary': '#DBFF73',
			},
		},
	},
})
