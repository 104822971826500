<template>
	<v-container
		style="max-width: 400px"
		class="px-5 pt-5 pt-sm-12 mt-sm-12 mt-0"
	>
		<v-row align="center" justify="center">
			<v-card flat>
				<v-img
					class="mb-8"
					max-width="120"
					src="@/assets/images/logo/default.png"
				></v-img>
				<h1 class="text-h5 mb-5">
					{{ email || '*****@****.***' }}
				</h1>
				<p class="text-body-1 mb-5">
					<strong class="font-weight-bold">{{
						$t(`회원님의 계정은 휴면 상태로 전환 되었습니다.`)
					}}</strong
					><br />
					{{ $t('auth.activate') }}
				</p>
				<div class="text-left">
					<v-btn
						class="mr-2"
						:disabled="isLoading"
						@click="signOut"
						depressed
						x-large
						>{{ $t('취소하기') }}
					</v-btn>
					<v-btn
						:loading="isLoading"
						:disabled="isLoading"
						@click="activate"
						color="primary"
						depressed
						x-large
						>{{ $t('휴면계정 해제하기') }}
					</v-btn>
				</div>
			</v-card>
		</v-row>
	</v-container>
</template>

<script>
import Api from '@/services/index'

export default {
	data: () => ({
		isLoading: false,
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		email() {
			return this.$store.getters['member/email']
		},
	},
	methods: {
		async signOut() {
			this.isLoading = true
			await this.$store.dispatch('auth/signOut')
			this.$router.push({ name: 'SignIn' })
			this.isLoading = false
		},
		async activate() {
			try {
				this.isLoading = true
				await Api.post(`/auth/activate/${this.memberId}`)
				alert(this.$t('정상적으로 휴면계정이 해제되었습니다.'))
			} catch (err) {
				alert(this.$t('휴면계정 해제에 실패하였습니다. 고객센터로 문의주세요.'))
			} finally {
				await this.signOut()
				this.isLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
