<template>
	<div>
		<Timezone />
	</div>
</template>

<script>
import Timezone from '@/components/book/time-first/Timezone'

export default {
	components: {
		Timezone,
	},
}
</script>

<style lang="scss" scoped></style>
