<template>
	<div
		id="faq"
		class="pt-14 pb-sm-15 pb-12 px-5"
		style="background-color: #f1f5ff"
	>
		<div class="pt-sm-6 pb-sm-10 pb-6 text-center">
			<h1 class="text--h2 mb-10 text-weight-bold">
				{{ $t('trial.confirmation.title.FAQ') }}
			</h1>

			<v-expansion-panels
				accordion
				style="max-width: 730px; overflow: hidden"
				class="rounded-lg mx-auto"
				flat
			>
				<v-expansion-panel v-for="item in list" :key="item.id">
					<v-expansion-panel-header
						class="py-2 pl-4 pr-1 py-sm-5 pl-sm-9 pr-sm-5"
						><h1 class="pt-1 title">
							<strong class="primary--text">Q. </strong>{{ item.q }}
						</h1>
						<template v-slot:actions>
							<v-img
								:style="`width:${userDevice === 'mobile' ? 40 : 60}px;`"
								src="@/assets/images/trial-landings/ic-arrow-dropdown-d.svg"
							></v-img>
						</template>
					</v-expansion-panel-header>
					<v-expansion-panel-content
						class="pa-4 pt-sm-6 pb-sm-7 px-sm-10 text-left white-space-pre"
						style="background-color: #f8faff"
					>
						<p class="text mb-2">
							{{ item.a }}
						</p>
						<v-btn
							rounded
							outlined
							color="primary"
							v-if="item.id === 1 || item.id === 2"
							:small="userDevice === 'mobile'"
							@click="
								$router.push({
									name: 'TrialReschedule',
									params: { trialId },
									query: {
										'country-code': countryCode,
										phone: phone,
									},
								})
							"
						>
							<p class="button-text primary--text">
								{{
									item.id === 1
										? $t('trial.confirmation.button.change-schedule-or-cancel')
										: $t('trial.confirmation.button.change-info')
								}}
								<v-icon style="margin-top: -4px">mdi-chevron-right</v-icon>
							</p>
						</v-btn>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		trialId: {
			required: true,
			type: Number,
		},
		countryCode: {
			required: true,
			type: Number,
		},
		phone: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			list: [
				{
					id: 1,
					q: this.$t('trial.confirmation.faq.1-question'),
					a: this.$t('trial.confirmation.faq.1-answer'),
				},
				{
					id: 2,
					q: this.$t('trial.confirmation.faq.2-question'),
					a: this.$t('trial.confirmation.faq.2-answer'),
				},
				{
					id: 3,
					q: this.$t('trial.confirmation.faq.3-question'),
					a: this.$t('trial.confirmation.faq.3-answer'),
				},
			],
		}
	},
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-size: 22.4px !important;
	font-weight: normal !important;
	line-height: 1.56 !important;
	letter-spacing: -0.7px !important;
	color: #191f28 !important;
}
.text {
	font-size: 19px !important;
	font-weight: 500 !important;
	line-height: 1.84 !important;
	letter-spacing: -0.59px !important;
	color: #191f28 !important;
}
.button-text {
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 2.19 !important;
	letter-spacing: -0.5px !important;
}
@media screen and (max-width: 600px) {
	.title {
		font-size: 15px !important;
		line-height: 1.79 !important;
		letter-spacing: -0.44px !important;
	}
	.text {
		font-size: 14px !important;
		line-height: 2.92 !important;
		letter-spacing: -0.38px !important;
	}
	.button-text {
		font-size: 11.7px !important;
		line-height: 2.18 !important;
		letter-spacing: -0.37px !important;
	}
}
</style>

<style>
#faq .theme--light.v-expansion-panels .v-expansion-panel:not(:last-child) {
	border-bottom: 1.4px solid rgba(151, 151, 151, 0.08);
}

#faq .v-expansion-panel-header .v-image__image {
	transition: transform 0.2s ease-in-out;
}

#faq .v-expansion-panel-header--active .v-image__image {
	transform: rotate(0.5turn);
}

#faq .v-btn.v-btn.v-btn--outlined {
	background-color: #fff !important;
}
</style>
