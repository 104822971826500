import { render, staticRenderFns } from "./ReportIssue.vue?vue&type=template&id=eb5f43d4&scoped=true&"
import script from "./ReportIssue.vue?vue&type=script&lang=js&"
export * from "./ReportIssue.vue?vue&type=script&lang=js&"
import style0 from "./ReportIssue.vue?vue&type=style&index=0&id=eb5f43d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb5f43d4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VBtn,VCard,VCol,VExpandTransition,VIcon,VRow,VSheet})
