<template>
	<v-menu offset-y v-if="readonly === false">
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				:background-color="backgroundColor"
				v-bind="attrs"
				v-on="on"
				outlined
				readonly
				:singleLine="singleLine"
				:value="`+${selectedCountry.dialCode}`"
				:label="$t('국가번호')"
				:hide-details="hideDetails"
			>
				<template v-slot:prepend-inner>
					<v-img
						style="margin-top: 2px"
						width="16"
						height="12"
						:src="
							require(`@/assets/images/flag/country-4x3/${selectedCountry.iso2}.svg`)
						"
					/>
				</template>
			</v-text-field>
		</template>
		<div style="height: 300px; overflow-y: scroll">
			<v-list>
				<v-subheader>Most Searched</v-subheader>
				<v-list-item
					dense
					ripple
					v-for="country in mostSearchedCountries"
					:key="`most-searched-${country.iso2}`"
					@click="selectedCountry = country"
				>
					<v-list-item-icon>
						<div>
							<v-img
								style="width: 16px; height: 12px"
								:src="
									require(`@/assets/images/flag/country-4x3/${country.iso2}.svg`)
								"
							/>
						</div>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title>
							{{ country.name }}
						</v-list-item-title>
					</v-list-item-content>
					<v-list-item-icon>
						<p class="text-right text-body-2">+{{ country.dialCode }}</p>
					</v-list-item-icon>
				</v-list-item>
				<v-subheader class="mt-5">All</v-subheader>
				<v-list-item
					dense
					:key="`all-${country.iso2}`"
					v-for="country in allCountries"
					@click="selectedCountry = country"
				>
					<v-list-item-icon>
						<div>
							<v-img
								style="width: 16px; height: 12px"
								:src="
									require(`@/assets/images/flag/country-4x3/${country.iso2}.svg`)
								"
							/>
						</div>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>
							{{ country.name }}
						</v-list-item-title>
					</v-list-item-content>
					<v-list-item-icon>
						<p class="text-right text-body-2">+{{ country.dialCode }}</p>
					</v-list-item-icon>
				</v-list-item>
			</v-list>
		</div>
	</v-menu>
	<v-text-field
		v-else
		:background-color="backgroundColor"
		outlined
		readonly
		:singleLine="singleLine"
		:value="`+${selectedCountry.dialCode}`"
		:label="$t('국가번호')"
		:hide-details="hideDetails"
	>
		<template v-slot:prepend-inner>
			<v-img
				style="margin-top: 2px"
				width="16"
				height="12"
				:src="
					require(`@/assets/images/flag/country-4x3/${selectedCountry.iso2}.svg`)
				"
			/>
		</template>
	</v-text-field>
</template>

<script>
import allCountries from '@/assets/all-country.js'
import mostSearchedCountries from '@/assets/most-searched-countries.js'
const R = require('ramda')

export default {
	props: {
		hideDetails: {
			required: false,
			default: false,
		},
		backgroundColor: {
			required: false,
		},
		readonly: {
			required: false,
			type: Boolean,
			default: false,
		},
		singleLine: {
			required: false,
			type: Boolean,
			default: false,
		},
		originCountryCode: {
			type: [Number, String],
		},
	},
	data() {
		return {
			countryDialog: false,
			selectedCountry: null,
		}
	},
	computed: {
		countryCode() {
			return (
				this.originCountryCode.toString() ||
				this.$store.getters['member/countryCode']
			)
		},
		allCountries() {
			return allCountries
		},
		mostSearchedCountries() {
			return mostSearchedCountries
		},
	},
	watch: {
		selectedCountry(val) {
			if (val) {
				localStorage.setItem('_country_code', val.dialCode)
				this.$emit('selectCountry', val)
			}
		},
	},
	created() {
		this.findCountry()
	},
	methods: {
		findCountry() {
			this.selectedCountry =
				R.find(R.propEq('dialCode', this.countryCode))(this.allCountries) ||
				this.mostSearchedCountries[0]
		},
	},
}
</script>

<style lang="scss" scoped>
.text-field {
	height: 51px;
	width: 80px;
	border-radius: 10px;
}
</style>
