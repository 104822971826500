<template>
	<div>
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-window-close</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">영수증</h1>
				</v-col>
			</v-row>
		</section>
		<section v-if="isLoading === false">
			<iframe
				ref="ifram"
				:src="this.url"
				frameborder="0"
				style="width: 100%; height: calc(var(--vh, 1vh) * 100 - 65px)"
			></iframe>
		</section>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	data: () => ({
		isLoading: false,
		payment: null,
	}),
	computed: {
		paymentId() {
			return this.$route.query['payment-id']
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		url() {
			const url = this.payment.receipt_url
			return url.includes('https://pg-web.kakao.com')
				? url.replace('/m/', '/p/')
				: url
		},
	},
	created() {
		if (!this.paymentId) {
			alert('결제 번호를 찾을 수 없습니다.')
			this.goBack()
		} else this.get()
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			let query = {}
			if (this.paymentId) query['payment-id'] = this.paymentId
			else if (this.groupPointTypeId)
				query['group-point-type-id'] = this.groupPointTypeId
			else if (this.passCouponMemberId)
				query['pass-coupon-member-id'] = this.passCouponMemberId
			else query['point-id'] = this.points[0].id
			beforeName !== 'PassDetail'
				? this.$router.push({ name: 'PassDetail', query })
				: this.$router.go(-1)
		},
		async get() {
			this.isLoading = true
			const res = await Api.get(
				`payments?member-id=${this.memberId}&id=${this.paymentId}&status=paid`,
			)
			if (res.data.length === 0) {
				alert(`결제를 찾을 수 없습니다.\n구매번호: ${this.paymentId}`)
				this.goBack()
			} else if (!res.data[0].receipt_url) {
				alert(
					`구매 영수증을 찾을 수 없습니다. 고객센터로 연락주세요.\n구매번호: ${this.paymentId}`,
				)
				this.goBack()
			} else {
				this.payment = res.data[0]
				this.isLoading = false
				// window.open(this.url, '_blank').focus()
			}
			// this.goBack()
		},
	},
}
</script>

<style lang="scss" scoped></style>
