<template>
	<div>
		<v-btn
			color="grey"
			rounded
			outlined
			height="32"
			data-test="previewButton"
			@click="checkForChange"
		>
			<span
				class="my-text grey--text text--darken-3 font-13 font-weight-regular"
				>{{ $t('component.lessonCard.button.프로그램 선택하기') }}
			</span>
		</v-btn>
		<v-dialog v-model="programRejectDialog" width="310">
			<v-card data-test="programRejectDialog" class="rounded-lg">
				<div class="px-4 py-5">
					<h1 class="my-title font-19 mb-5">
						{{
							$t(
								'component.lessonCard.title.프로그램은 수업 10분전까지 변경하실 수 있습니다.',
							)
						}}
					</h1>

					<v-btn
						depressed
						block
						color="primary"
						@click="programRejectDialog = false"
						>{{ $t('component.lessonCard.button.확인') }}</v-btn
					>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
	props: {
		lesson: {
			required: true,
			type: Object,
		},
	},
	data: () => ({
		programRejectDialog: false,
	}),
	methods: {
		checkForChange() {
			if (this.lesson.dt <= DateTime.now().plus({ minutes: 10 })) {
				this.programRejectDialog = true
				return
			}

			// this.$router.push({
			// 	name: 'ProgramChange',
			// 	params: { lessonId: this.lesson.id },
			// })
			this.$router.push({
				name: 'LessonProgramSelect',
				params: { lessonId: this.lesson.id },
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
