// import { createNamespacedHelpers } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import tutor from './tutor/index'
import time from './time/index'

const state = {
	isLoading: false,
	selectedType: null,
	selectedMoment: null,
	selectedTutor: null,
}

// const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(NAMESPACE)

// export {
//   mapGetters,
//   mapMutations,
//   mapActions
// }

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
	modules: {
		tutor,
		time,
	},
}
