<template>
	<div>
		<v-img id="preview-mobile" src="@/assets/images/trial-landings/pc-2-bg.svg">
			<div class="pt-13 pb-13">
				<h1
					class="text--h1 mb-7 text-center"
					v-html="$t('trial-confirmation.preview-mobile.title')"
				></h1>
				<div
					style="overflow-x: scroll; scroll-behavior: smooth"
					id="scroll-target"
				>
					<div
						class="d-flex align-start px-3 pt-1"
						style="width: 670px; height: 80px; position: relative"
					>
						<div
							class="rounded-step-back"
							:style="`left:${leftPosition}px;width:${width}px`"
						></div>
						<div
							style="z-index: 1; position: relative"
							v-for="item in list"
							:key="item.id"
							class="step-wrap d-flex align-center"
							:class="{
								active: item.id === active,
								'ice-breaking': item.class === 'ice-breaking',
								'today-expression': item.class === 'today-expression',
								'role-playing': item.class === 'role-playing',
								feedback: item.class === 'feedback',
							}"
							@click="selectActive(item.id)"
						>
							<div class="step" :class="{ active: active === item.id }">
								{{ item.id + 1 }}
							</div>
							<p class="title">
								{{ item.text }}
							</p>
						</div>
					</div>
				</div>
				<swiper
					@slideChangeTransitionStart="onSwiperSlideChangeTransitionStart"
					class="mb-10 swiper"
					:options="swiperOption"
					ref="swiper"
				>
					<swiperSlide v-for="item in list" :key="`img-${item.id}`">
						<v-img width="100%" max-width="300px" :src="item.src"></v-img>
					</swiperSlide>
				</swiper>
				<div
					style="width: 80px"
					class="mx-auto"
					:class="{
						'ice-breaking': active === 0,
						'today-expression': active === 1,
						'role-playing': active === 2,
						feedback: active === 3,
					}"
				>
					<div class="swiper-pagination" slot="pagination"></div>
				</div>
			</div>
		</v-img>
	</div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
	components: {
		swiper,
		swiperSlide,
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: 1.3,
				centeredSlides: true,
				spaceBetween: 15,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			},
			active: 0,
		}
	},
	computed: {
		swiper() {
			return this.$refs.swiper
		},
		language() {
			return this.$store.getters['member/language']
		},
		leftPosition() {
			const positions =
				this.language === 'ja' ? [17, 182, 315, 467] : [17, 177, 307, 422]
			return positions[this.active]
		},
		width() {
			const widths =
				this.language === 'ja' ? [165, 135, 155, 180] : [160, 135, 115, 195]

			return widths[this.active]
		},
		jaImgTag() {
			return this.language === 'ja' ? '-ja' : ''
		},
		list() {
			return [
				{
					id: 0,
					text: this.$t('trial-confirmation.preview-mobile.ice-breaking'),
					class: 'ice-breaking',
					src: require(`@/assets/images/trial-landings/mo-card-01-${this.language}.png`),
					srcset: `@/assets/images/trial-landings/mo-card-01-${this.language}@2x.png 2x,
             @/assets/images/trial-landings/mo-card-01-${this.language}@3x.png 3x`,
				},
				{
					id: 1,
					text: this.$t('trial-confirmation.preview-mobile.today-expression'),
					class: 'today-expression',
					src: require(`@/assets/images/trial-landings/mo-card-02-${this.language}.png`),
					srcset: `@/assets/images/trial-landings/mo-card-02-${this.language}@2x.png 2x,
             @/assets/images/trial-landings/mo-card-02-${this.language}@3x.png 3x`,
				},
				{
					id: 2,
					text: this.$t('trial-confirmation.preview-mobile.role-playing'),
					class: 'role-playing',
					src: require(`@/assets/images/trial-landings/mo-card-03-${this.language}.png`),
					srcset: `@/assets/images/trial-landings/mo-card-03-${this.language}@2x.png 2x,
             @/assets/images/trial-landings/mo-card-03-${this.language}@3x.png 3x`,
				},
				{
					id: 3,
					text: this.$t('trial-confirmation.preview-mobile.real-time-feedback'),
					class: 'feedback',
					src: require(`@/assets/images/trial-landings/mo-card-04-${this.language}.png`),
					srcset: `@/assets/images/trial-landings/mo-card-04-${this.language}@2x.png 2x,
             @/assets/images/trial-landings/mo-card-04-${this.language}@3x.png 3x`,
				},
			]
		},
	},
	watch: {
		active() {
			var elmnt = document.getElementById('scroll-target')
			elmnt.scrollLeft = this.leftPosition - 10
		},
	},
	methods: {
		selectActive(id) {
			this.active = id
			this.$refs.swiper.swiper.slideTo(id)
			// this.$vuetify.goTo('#step-3')
		},
		onSwiperSlideChangeTransitionStart() {
			this.active = this.$refs.swiper.swiper.activeIndex
		},
	},
}
</script>

<style lang="scss" scoped>
.step-wrap {
	// width: 256px;
	// height: 54px;
	padding: 10px 15px;

	.step {
		transition: all 0.3s ease-in-out;
		width: 22px;
		height: 22px;
		margin-top: -2px;
		padding: 0 8px 0 7px;
		border-radius: 50%;
		text-align: center;
		font-size: 16px !important;
		font-weight: 600 !important;
		line-height: 1.56 !important;
		letter-spacing: -0.5px !important;
		color: #78879d !important;
	}
	.step.active {
		background-color: #006cf0;
		font-size: 14px !important;
		font-weight: bold !important;
		letter-spacing: -0.44px !important;
		padding-top: 1px !important;
		color: #fff !important;
	}
	p.title {
		transition: all 0.3s ease-in-out;
		border-radius: 50%;
		margin-left: 5px !important;
		font-size: 16px !important;
		font-weight: 600 !important;
		line-height: 1.56 !important;
		letter-spacing: -0.5px !important;
		color: #78879d !important;
	}
}
.step-wrap.active {
	p.title {
		font-size: 16px;
		font-weight: bold;
		line-height: 1.56;
		letter-spacing: -0.5px;
	}
}
.step-wrap.active.ice-breaking {
	.step {
		border-color: #006cf0;
		background-color: #006cf0;
	}
	p.title {
		color: #006cf0 !important;
	}
}
.step-wrap.active.today-expression {
	.step {
		border-color: #7354ff;
		background-color: #7354ff;
	}
	// p {
	// 	color: #7354ff !important;
	// }
}
.step-wrap.active.role-playing {
	.step {
		border-color: #00bbad;
		background-color: #00bbad;
	}
	p.title {
		color: #00bbad !important;
	}
}
.step-wrap.active.feedback {
	.step {
		border-color: #ff851a;
		background-color: #ff851a;
	}
	p.title {
		color: #ff851a !important;
	}
}

.rounded-step-back {
	transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
	position: absolute;
	top: 2px;
	height: 45px;
	object-fit: contain;
	border-radius: 22.5px;
	box-shadow: 0 10px 30px 0 rgba(33, 51, 82, 0.1);
	background-color: #fff;
}
</style>

<style lang="scss">
#preview-mobile {
	.swiper-pagination-bullet {
		margin: 0 5px;
		width: 10px;
		height: 10px;
		border-radius: 5px;
		transition: width 0.3s ease-in-out;
	}
	.swiper-pagination-bullet.swiper-pagination-bullet-active {
		width: 20px;
	}
	.today-expression .swiper-pagination-bullet.swiper-pagination-bullet-active {
		background-color: #7354ff;
	}
	.role-playing .swiper-pagination-bullet.swiper-pagination-bullet-active {
		background-color: #00bbad;
	}
	.feedback .swiper-pagination-bullet.swiper-pagination-bullet-active {
		background-color: #ff851a;
	}
}
</style>
