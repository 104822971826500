<template>
	<div class="box">
		<h1 class="font-13 font-weight-bold grey--text text--darken-1">
			예약중인 프로그램
		</h1>
		<h2 class="font-13 font-weight-medium grey--text text--darken-3">
			{{ lesson.programName }}
		</h2>
		<p class="font-13 font-weight-regular grey--text text--darken-3">
			<span v-if="lesson.programId === 110">레벨테스트</span>
			<span v-else-if="lesson.unitTitle"
				>unit {{ lesson.unit }}. {{ lesson.unitTitle.ko }}</span
			>
			<span v-else>프로그램 미선택</span>
		</p>
	</div>
</template>

<script>
export default {
	props: {
		lesson: {
			required: true,
		},
	},
}
</script>

<style lang="scss" scoped>
.box {
	background: #f7f7f7;
	border: 1px solid #eeeeee;
	border-radius: 4px;
	padding: 12px 15px;
}
</style>
