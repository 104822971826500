<template>
	<div>
		<div
			:class="{
				'mb-5': !logs[i + 1] || log.type !== logs[i + 1].type,
				'mb-2': logs[i + 1] && log.type === logs[i + 1].type,
			}"
			v-for="(log, i) in logs"
			:key="log.id"
		>
			<div
				class="d-flex align-end"
				:class="{
					'justify-start': log.type === 'Tutor',
					'justify-end': log.type === 'Tutee',
				}"
			>
				<div
					class="chat-bubble"
					v-html="log.content"
					:class="{
						'order-1 tutee': log.type === 'Tutee',
						tutor: log.type === 'Tutor',
					}"
				></div>
				<!-- <div
					v-if="
						i === 0 ||
						!logs[i + 1] ||
						logs[i].type !== logs[i + 1].type ||
						logs[i].content_time !== logs[i + 1].content_time
					"
					class="mx-1 font-12 grey--text"
					:class="{ 'order-0': log.type === 'Tutee' }"
				>
					{{ timeFormat(log.content_time) }}
				</div> -->
			</div>
			<div
				class="mt-3 d-flex"
				v-if="log.editings && log.editings.length > 0"
				:class="{
					'justify-start': log.type === 'Tutor',
					'justify-end': log.type === 'Tutee',
				}"
			>
				<div class="editing-wrap">
					<h2 class="font-weight-bold font-12 mb-2">
						<img
							src="@/assets/images/emoji/pencil.png"
							style="width: 15px; vertical-align: middle; margin-right: 2px"
							alt=""
						/>
						{{ $t('튜터 첨삭') }}
					</h2>
					<div class="mb-1" v-for="editing in log.editings" :key="editing.id">
						<div class="editing font-14">
							<span
								v-html="
									afterDiff(
										editing.tutee_chat_log_content,
										editing.tutor_chat_log_content,
									)
								"
							></span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { DateTime } from 'luxon'
const jsdiff = require('diff')

export default {
	props: {
		lessonDt: {
			required: true,
		},
		logs: {
			default: () => {},
			type: Array,
		},
	},
	methods: {
		timeFormat(time) {
			const mm = time.substring(14, 16)

			return DateTime.fromFormat(
				`${this.lessonDt.toFormat('yyyy-MM-dd HH')}:${mm}`,
				'yyyy-MM-dd HH:mm',
			).toFormat('HH:mm')
		},
		afterDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.added) {
					span === null
						? (span = `<span class='font-weight-bold after'>${part.value}</span>`)
						: (span += `<span class='font-weight-bold after'>${part.value}</span>`)
				} else if (!part.removed) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
	},
}
</script>

<style lang="scss" scoped>
.chat-bubble {
	padding: 12px 20px;
	max-width: 90%;
	word-wrap: break-word;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
}
.chat-bubble.tutor {
	border-radius: 5px 15px 15px 15px;
	background-color: #fff;
	color: #404a59;
}
.chat-bubble.tutee {
	background: #404a59;
	border-radius: 15px 5px 15px 15px;
	color: #fff;
}
.editing-wrap {
	max-width: 90%;
}
.editing {
	border-left: 2px solid #404a59;
	padding-left: 10px;
	color: #404a59;
	.after {
		font-weight: 700;
		color: #00cba0 !important;
	}
}
</style>

<style lang="scss">
.editing {
	.after {
		color: #00cba0 !important;
	}
}
</style>
