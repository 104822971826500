<template>
	<div class="background pt-12 pb-15">
		<v-toolbar
			style="
				width: 100%;
				max-width: 400px;
				position: fixed;
				top: 0px;
				z-index: 3;
			"
			elevation="0"
			height="60"
			class="px-1"
		>
			<v-row no-gutters>
				<v-col cols="2">
					<v-icon @click="goBack()">mdi-arrow-left</v-icon>
				</v-col>
				<v-col class="text-center font-16 font-weight-medium" cols="8">{{
					$t('page.calendar.title.캘린더')
				}}</v-col>
			</v-row>
		</v-toolbar>
		<v-row
			no-gutters
			class="white px-4"
			style="height: 40px"
			align="center"
			justify="center"
		>
			<v-col cols="3"></v-col>
			<v-col cols="6" class="d-flex align-center justify-center">
				<img
					style="width: 16px; margin-top: -1.5px"
					@click="addMonth(-1)"
					src="@/assets/images/icon/chevron-left.png"
					alt=""
				/>
				<div
					style="width: 120px"
					class="font-16 font-weight-medium text-center"
				>
					{{ startDtOfMonth.toFormat('ff').substr(0, 10) }}
				</div>
				<img
					style="width: 16px; margin-top: -1.5px; transform: rotate(180deg)"
					@click="addMonth(1)"
					src="@/assets/images/icon/chevron-left.png"
					alt=""
				/>
			</v-col>
			<v-col cols="3" class="d-flex justify-end">
				<div
					@click="setToday()"
					class="
						d-inline-block
						ml-2
						font-13
						primary--text primary--1
						rounded-lg
						hover-pointer
					"
					style="padding: 1px 6px"
				>
					{{ $t('page.calendar.button.오늘') }}
				</div>
			</v-col>
		</v-row>

		<div class="px-4 py-2 white" style="height: 300px">
			<div class="d-flex align-center justify-center">
				<div
					v-for="day in week"
					:key="day"
					class="
						date-wrap
						text-center
						font-12 font-weight-bold
						grey--text
						text--darken-3
						pt-2
					"
				>
					{{ day }}
				</div>
			</div>
			<div
				v-if="isLoading === false && isMapping === false"
				class="d-flex flex-wrap align-center justify-center"
			>
				<div v-for="date in dates" :key="date.toISO()" class="date-wrap">
					<div
						class="date-box hover-pointer"
						@click="selectDate(date)"
						:class="{
							active: selectedDt && date.toISODate() === selectedDt.toISODate(),
							today: date.toISODate() === today,
							'grey--text': startDtOfMonth.toFormat('L') !== date.toFormat('L'),
						}"
					>
						{{ date.toFormat('d') }}

						<div
							class="lesson-dot-wrap d-flex justify-center align-center"
							v-if="date.lessons"
						>
							<div
								v-for="lesson in date.lessons.slice(0, 3)"
								:key="`dot-${lesson.id}`"
								class="lesson-dot"
								:class="{
									primary:
										lesson.status !== 'analyzed-absent' &&
										lesson.lessonType === 0,
									emerald:
										lesson.status !== 'analyzed-absent' &&
										lesson.lessonType === 1,
									'grey darken-2': lesson.status === 'analyzed-absent',
								}"
							></div>
							<div
								v-if="date.lessons.length > 3"
								class="font-8 grey--text text--darken-1"
							>
								+{{ date.lessons.length - 3 }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div
			class="px-4 pt-8"
			v-if="isLoading === false && selectedLessons.length > 0"
		>
			<div v-for="lesson in selectedLessons" :key="lesson.id" class="mb-8">
				<AppointedLessonCard
					:lesson="lesson"
					v-if="lesson.status === 'appointed'"
				/>
				<PassedLessonCard :lesson="lesson" v-else />
			</div>
		</div>
		<div
			v-if="isLoading === false && selectedLessons.length === 0"
			class="mt-16 text-center"
		>
			<img
				style="width: 65px"
				src="@/assets/images/schedule/calendar-icon-grey.png"
				alt=""
			/>
			<p class="text-center font-16 grey--text text--darken-1">
				{{ $t('page.calendar.text.수업 기록이 없습니다.') }}
			</p>
		</div>
		<!-- <CancelledSnackBar /> -->
	</div>
</template>

<script>
import { DateTime } from 'luxon'
import scheduleApi from '@/services/schedule'
import AppointedLessonCard from '@/components/lesson-card/appointed/Wrap'
import PassedLessonCard from '@/components/lesson-card/passed/Wrap'
// import CancelledSnackBar from '@/components/common/CancelledSnackBar'
import EventBus from '@/lib/eventBus'

export default {
	components: {
		AppointedLessonCard,
		PassedLessonCard,
		// CancelledSnackBar,
	},
	data: () => ({
		isLoading: false,
		isMapping: false,
		startDtOfMonth: null,
		dates: [],
		today: DateTime.now().toISODate(),
		lessons: [],
		// selectedDt: null,
		// selectedLessons: [],
	}),
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		week() {
			return this.language === 'ko'
				? ['일', '월', '화', '수', '목', '금', '토']
				: ['日', '月', '火', '水', '木', '金', '土']
		},
		date() {
			return this.$route.query.date
				? this.$route.query.date
				: DateTime.now().toISODate()
		},
		selectedDt() {
			if (!this.date) return null
			const dt = this.dates.find(d => d.toISODate() === this.date)
			if (dt) return dt
			return null
		},
		selectedLessons() {
			if (!this.selectedDt) return []
			const lessons = this.selectedDt.lessons
				? this.selectedDt.lessons.slice()
				: []
			return lessons.sort(function (a, b) {
				return a.lessonTime - b.lessonTime
			})
		},
		startDt() {
			return this.startDtOfMonth.startOf('week').minus({ day: 1 })
		},
		endDt() {
			return this.startDtOfMonth.endOf('month').endOf('week').minus({ day: 1 })
		},
	},
	watch: {
		date() {
			if (this.startDtOfMonth.toISODate().slice(0, 7) !== this.date.slice(0, 7))
				this.selectMonth()
		},
	},
	created() {
		EventBus.$on('lessonIsCancelled', this.setInitial)
		this.setInitial()
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-3', {
			root: true,
		})
	},
	beforeDestroy() {
		EventBus.$off('lessonIsCancelled', this.setInitial)
		this.$store.commit('device/SET_BACKGROUND', 'white', {
			root: true,
		})
	},
	methods: {
		async setInitial() {
			this.startDtOfMonth = DateTime.fromISO(this.date)
				.startOf('month')
				.setLocale(this.language)
			this.makeDates()
			await this.getLessons()
		},
		async addMonth(number) {
			// this.startDtOfMonth = this.startDtOfMonth.plus({ months: number })
			// const date = this.startDtOfMonth.toISODate()
			// this.$router.push({ name: this.$route.name, query: { date } })
			// this.makeDates()
			// await this.getLessons()
			const date = this.startDtOfMonth.plus({ months: number }).startOf('month')
			this.$router.push({
				name: this.$route.name,
				query: { date: date.toISODate() },
			})
			this.selectMonth()
		},
		async selectMonth() {
			this.startDtOfMonth = DateTime.fromISO(this.date).startOf('month')
			this.makeDates()
			await this.getLessons()
		},
		makeDates() {
			this.dates = []
			let d = this.startDt
			while (d <= this.endDt) {
				this.dates.push(d)
				d = d.plus({ day: 1 })
			}
		},
		async getLessons() {
			// this.selectedLessons = []
			this.lessons = []
			this.isLoading = true
			this.lessons = await scheduleApi.getLessons({
				gteLessonDatetime: this.startDt.toISO(),
				lteLessonDatetime: this.endDt.toISO(),
				status: 'analyzed,analyzed-absent,appointed',
			})

			const lessonIds = this.lessons.map(l => l.id).toString()
			const ratings = await scheduleApi.getRatings({ lessonIds })

			this.lessons.forEach(l => {
				const alreadyRated = ratings.find(r => r.lessonId === l.id)
				l.ratingScore = alreadyRated ? alreadyRated.score : null
			})
			this.isLoading = false
			await this.mapLessons()
		},
		mapLessons() {
			this.isMapping = true
			this.lessons.forEach(l => {
				const index = this.dates.findIndex(d => {
					return d.toISODate() === l.dt.toISODate()
				})
				if (index >= 0)
					this.dates[index].lessons
						? this.dates[index].lessons.push(l)
						: (this.dates[index].lessons = [l])
			})
			this.isMapping = false
		},
		selectDate(date) {
			if (this.date === date) return
			this.$router.replace({
				naem: this.$route.name,
				query: { date: date.toISODate() },
			})
		},
		setToday() {
			this.$router.push({
				naem: this.$route.name,
				query: { date: DateTime.now().toISODate() },
			})
			this.setInitial()
		},
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			if (!beforeName) {
				this.$router.push({ name: 'AppointedLessons' })
				return
			}
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped>
.background {
	background-color: #f2f4f6;
	min-height: calc(var(--vh, 1vh) * 100);
	overflow-y: auto;
}
/* iOS only */
// @supports (-webkit-touch-callout: none) {
// 	.background {
// 		height: -webkit-fill-available;
// 	}
// }
.date-wrap {
	width: 14.2857%;
	height: 40px;
}
.date-box {
	width: 40px;
	height: 40px;
	text-align: center;
	margin: 0 auto;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #444444;
	padding: 12.5px 10px 10px;
	border-radius: 10px;
	position: relative;
}
.lesson-dot-wrap {
	position: absolute;
	bottom: 7px;
	height: 0px;
	left: 0px;
	width: 100%;
}
.lesson-dot {
	width: 5px;
	height: 5px;
	margin: 0 1px;
	border-radius: 50%;
}

.date-box.active {
	background: #f0f0f0;
}
.date-box.today {
	background: #cce6ff;
}
</style>
