/* global Kakao */
// import Api from '@/services/index'

export default {
	/**
	 * 서버로 아이디와 토큰 보냄.
	 * 토큰 검증.
	 * 카카오 아이디 찾기
	 */
	// async findMember() {
	// 	let loginRes, getMeRes, kakaoToken, authKakaoId
	// 	try {
	// 		loginRes = await this.login()
	// 		kakaoToken = loginRes.access_token
	// 	} catch (err) {
	// 		const e = new Error('kakao login error')
	// 		e.name = 'kakaoLoginError'
	// 		throw e
	// 	}

	// 	try {
	// 		getMeRes = await this.getMe()
	// 		authKakaoId = getMeRes.id
	// 	} catch (err) {
	// 		const e = new Error('kakao get me error')
	// 		e.name = 'kakaoGetMeError'
	// 		throw e
	// 	}

	// 	try {
	// 		const res = await Api.get(`auth/kakao/${authKakaoId}`, {
	// 			headers: { 'x-kakao-token': kakaoToken },
	// 		})
	// 		const { memberId, email } = res.data
	// 		return { existingMember: { memberId, email }, authKakaoId, kakaoToken }
	// 	} catch (err) {
	// 		// eslint-disable-next-line no-undef
	// 		const e = new Error(err)
	// 		e.name = err.response.data.code
	// 			? err.response.data.code
	// 			: err.response.data.status
	// 		throw e
	// 	}
	// },
	async get() {
		try {
			const loginRes = await this.login()
			const res = await this.getMe()
			const phoneNumber = res.kakao_account.phone_number

			const countryCode = phoneNumber
				? phoneNumber.split(' ')[0].replace(/\+/g, '')
				: null
			const phone = phoneNumber
				? phoneNumber.split(' ')[1].replace(/-/g, '')
				: null

			return {
				email: res.kakao_account.email,
				name: res.kakao_account.profile.nickname,
				countryCode,
				phone,
				authKakaoId: res.id,
				token: loginRes.access_token,
			}
		} catch (err) {
			throw new Error('kakao error')
		}
	},
	login() {
		return new Promise((resolve, reject) => {
			Kakao.Auth.login({
				success: response => {
					resolve(response)
				},
				fail: err => {
					reject(err)
				},
			})
		})
	},
	getMe() {
		return new Promise((resolve, reject) => {
			Kakao.API.request({
				// 카카오 인증
				url: '/v2/user/me',
				success: res => {
					resolve(res)
				},
				fail: err => {
					reject(err)
				},
			})
		})
	},
}
