<template>
	<div class="pt-12">
		<div class="px-3 mt-7">
			<h1 class="text-h2 font-weight-bold mb-3">
				서비스를 이용하실<br />
				카카오톡 ID를 입력해주세요.
			</h1>
			<p class="mb-9 text-body-2">
				카카오톡 ID는<br />
				텔라 튜터가 카카오톡에서 {{ name }}님을 찾기 위해 사용돼요.
			</p>
			<v-form ref="form" v-model="valid" @submit.prevent="updateMember">
				<v-text-field
					class="tella-form"
					:label="$t('카카오톡 ID')"
					ref="kakaoId"
					v-model="kakaoId"
					outlined
					:rules="kakaoIdRules"
					single-line
					:disabled="isLoading"
					background-color="white"
				>
				</v-text-field>
				<HowToCheckKakaoId :text="true" />
				<div
					class="text-center"
					style="position: fixed; bottom: 15px; width: 100%; max-width: 370px"
				>
					<v-btn
						type="submit"
						depressed
						block
						large
						color="primary"
						:disabled="!valid"
						class="mb-1"
						>다음</v-btn
					>
					<v-btn plain @click="goNext" text color="grey darken-3">
						<u class="text-decoration-underline grey--text text--darken-3">
							나중에 할래요
						</u>
					</v-btn>
				</div>
			</v-form>
		</div>
	</div>
</template>

<script>
import Api from '@/services/index'
import HowToCheckKakaoId from '@/components/common/HowToCheckKakaoId'

export default {
	components: {
		HowToCheckKakaoId,
	},
	data() {
		return {
			valid: false,
			kakaoId: null,
			isLoading: false,
			kakaoIdRules: [
				v => !!v || this.$t('카카오톡 ID을 입력해주세요.'),
				v => !/[ ]/.test(v) || this.$t('공란을 확인해주세요.'),
				v =>
					/^[0-9a-zA-Z-._]*$/.test(v) ||
					this.$t('카카오톡 ID는 영어와 숫자만 입력하실 수 있습니다.'),
			],
		}
	},
	computed: {
		name() {
			return this.$store.getters['member/name']
		},
		memberKakaoId() {
			return this.$store.getters['member/kakaoId']
		},
		redir() {
			return this.$route.query.redir
				? JSON.parse(this.$route.query.redir)
				: null
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
	},
	methods: {
		// goBack() {
		// 	const fromRouterName = this.$store.getters['device/fromRouter'].name

		// 	fromRouterName === null
		// 		? this.$router.push({ name: 'Home' })
		// 		: this.$router.go(-1)
		// },
		goNext() {
			const fromRouterName = this.$store.getters['device/fromRouter'].name

			fromRouterName === null
				? this.$router.push({ name: 'Home' })
				: this.$router.go(-1)
		},

		async updateMember() {
			try {
				await Api.patch(`members/${this.memberId}`, {
					kakaoId: this.kakaoId,
				})
				await this.$store.dispatch('member/get', { root: true })
				const routeObj = this.redir
					? this.redir
					: {
							name: 'Home',
							params: { locale: this.$i18n.locale },
					  }
				this.$router.push(routeObj)
			} catch (err) {
				alert('회원 정보 업데이트에 실패했습니다. 고객센터로 문의주세요.')
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
