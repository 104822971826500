<template>
	<div>
		<v-main>
			<slot />
		</v-main>
	</div>
</template>

<script>
/**
 * memberId가 null이거나 loading이면
 */
export default {}
</script>

<style></style>
