<template>
	<div id="evaluate-wrap">
		<section class="px-5">
			<p class="text-body-1 font-weight-bold">
				{{
					$t('튜터 %{tutorName}와의 수업 어떠셨어요?', {
						tutorName: lesson.tutor_name_en,
					})
				}}
			</p>
			<div class="d-flex justify-center">
				<v-rating
					class="text-xs-center mt-3 mb-7"
					v-model="rating"
					dense
					color="yellow darken-1"
					large
					background-color="grey"
					empty-icon="$vuetify.icons.ratingFull"
					half-increments
					:disabled="isLoading"
				></v-rating>
			</div>
			<div v-if="rating > 0">
				<p class="text-body-1 font-weight-bold mb-1">
					<span v-if="rating <= 3.5">
						{{ $t('어떤 점이 아쉬우셨나요? 😥') }}
					</span>
					<span v-else>
						{{ $t('어떤 점이 좋으셨나요?') }}
					</span>
				</p>
				<Form
					:rating="rating"
					:lesson="lesson"
					:isRating="isLoading"
					@reasonChange="reasonChange"
				/>
				<div class="rating-to-tutor">
					<p class="mt-8 mb-1 text-body-1 font-weight-bold">
						{{ $t('튜터 평가') }}
					</p>
					<v-textarea
						outlined
						flat
						class="mt-0"
						singline-line
						hide-details
						counter="80"
						v-model="reasons.etc"
						height="60"
						:disabled="isLoading"
						:placeholder="
							rating > 3.5
								? $t('좋았던 점을 자유롭게 입력하세요.')
								: $t(
										'위 항목에서 선택할 수 없는 아쉬웠던 점이 있다면 남겨주세요.',
								  )
						"
					>
					</v-textarea>
					<p class="mt-8 mb-1 text-body-1 font-weight-bold">
						{{ $t('튜터에게 남기는 메세지 💌') }}
					</p>
					<v-textarea
						outlined
						flat
						class="mt-0"
						single-line
						hide-details
						height="70"
						:disabled="isLoading"
						counter="80"
						v-model="toTutor"
						:placeholder="
							$t(
								'남겨주신 피드백과 격려의 글은 튜터가 더 좋은 수업을 준비하는데 도움이 돼요!',
							)
						"
					>
					</v-textarea>
				</div>
			</div>
		</section>
		<section class="fixed-button-wrap">
			<v-btn
				v-show="rating > 0"
				:loading="isLoading"
				color="primary"
				dark
				depressed
				large
				block
				height="56"
				tile
				@click="rate"
			>
				<strong>{{ $t('보내기') }}</strong>
			</v-btn>
		</section>
		<v-dialog v-model="completeDialog" width="350" content-class="rounded-xl">
			<v-card rounded="xl" class="pt-5">
				<v-card-text>
					<h1 class="text-body-1 mb-5 font-weight-bold">
						{{ $t('캐시 적립') }}
					</h1>
					<div class="text-center">
						<img
							style="width: 60px"
							src="@/assets/images/icon/cash@3x.png"
							alt=""
						/>
						<p class="text-body-2 grey--text text--darken-1 mb-5">
							<span class="font-weight-bold">{{ $t(`수업 평가 완료`) }}</span
							><br />
							<span class="primary--text">+ 200 {{ $t(`원`) }}</span>
						</p>
					</div>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="black" text @click="completeDialog = false">
						{{ $t('확인') }}
					</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Api from '@/services/index'
import Form from './Form'
const R = require('ramda')
export default {
	components: {
		Form,
	},
	props: {
		lesson: {
			type: Object,
			required: true,
		},
		ratings: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			completeDialog: false,
			isLoading: false,
			rating: null,
			toTutor: null,
			reasons: {
				1: null,
				2: null,
				3: null,
				4: null,
				5: null,
				6: null,
				7: null,
				8: null,
				9: null,
				10: null,
				11: null,
				12: null,
				13: null,
				14: null,
				15: null,
				etc: null,
			},
		}
	},
	computed: {
		selectedReasonId() {
			return R.pipe(
				R.filter(r => r !== false && r !== null),
				R.keys(),
				R.filter(key => key !== 'etc'),
			)(this.reasons)
		},
		selectedReasonName() {
			return R.pipe(
				R.filter(r => r !== false && r !== null),
				R.values(),
			)(this.reasons)
		},
	},
	methods: {
		reasonChange(reasons) {
			this.reasons = { ...this.reasons, ...reasons }
		},

		async rate() {
			if (!this.rating) alert(this.$t('별점을 입력해주세요.'))
			else if (this.selectedReasonId.length === 0)
				alert(this.$t('이유를 선택해주세요.'))
			else {
				try {
					this.isLoading = true
					let params = {
						lessonId: this.lesson.id,
						memberId: this.lesson.member_id,
						tutorId: this.lesson.tutor_id,
						score: this.rating,
						toTutor: this.toTutor,
						reason: R.join('||', this.selectedReasonName),
						reasons: this.selectedReasonId,
						etc: this.reasons.etc,
					}

					await Api.post('/lesson-ratings', params)
					this.completeDialog = true
					// alert(this.$t('평가가 완료되었습니다. 200 캐시가 지급되었습니다.'))

					this.$emit('rate', 'success')
				} catch (err) {
					let msg = this.$t(
						'평가를 작성하는 데 문제가 발생하였습니다. 이런 오류가 계속 발생할 시, 고객센터로 문의주세요.',
					)
					const code = err.response.data.code || 'F29VKQ'
					if (code === '40400' || code === '40401')
						msg = this.$t('평가를 할 수 없는 수업입니다.')
					if (code === '40402')
						msg = this.$t('24시간이 지난 수업은 평가를 진행하실 수 없습니다.')
					if (code === '40403') msg = this.$t('이미 평가 완료된 수업입니다.')
					if (code === '40404')
						msg = this.$t('이미 튜터의 결석이 보고된 수업입니다.')

					alert(`${msg}\n\nERROR CODE: ${code}`)
					this.$emit('rate', 'fail')
				} finally {
					this.isLoading = false
				}
			}
		},
	},
	// mixins: [format],
}
</script>

<style lang="scss" scoped>
#evaluate-wrap .v-input--selection-controls.v-input .v-label {
	font-size: 14px;
}
</style>

<style>
.rating-to-tutor textarea {
	font-size: 14px !important;
	line-height: 1.4em !important;
}
</style>
