<template>
	<div style="overflow-y: hidden; overflow-x: auto">
		<!-- <div class="d-flex align-center px-1 pb-1 mb-2">
			<div
				class="pa-1 tab-btn mr-3"
				v-for="item in categories"
				:key="item.value"
				:class="{ active: category === item.value }"
				@click="SELECT_CATEGORY(item.value)"
			>
				<h1 class="text-body-1">{{ item.title }}</h1>
			</div>
		</div> -->
		<swiper class="px-1 mb-2 swiper" :options="swiperOption">
			<swiperSlide
				v-for="item in categories"
				:key="item.value"
				style="width: auto"
			>
				<div
					class="cursor-pointer pa-1 tab-btn"
					:class="{ active: category === item.value }"
					@click="SELECT_CATEGORY(item.value)"
				>
					<h1 class="text-body-1">{{ item.title }}</h1>
				</div>
			</swiperSlide>
		</swiper>
		<!-- <v-slide-group center-active v-if="slideShow">
			<v-slide-item
				v-for="item in subCategories[category]"
				:key="`sub-${item.value}`"
			>
				<v-btn
					small
					:outlined="subCategory !== item.value"
					:color="
						subCategory !== item.value ? 'grey lighten-1' : 'grey darken-4'
					"
					:dark="subCategory === item.value"
					class="mx-1 text-caption"
					active-class="purple white--text"
					depressed
					rounded
					height="25"
					@click="SELECT_SUB_CATEGORY(item.value)"
				>
					{{ item.title }}
				</v-btn>
			</v-slide-item>
		</v-slide-group> -->
		<swiper
			v-if="slideShow"
			class="swiper"
			:options="{ ...swiperOption, spaceBetween: 1 }"
		>
			<swiperSlide
				v-for="item in subCategories[category]"
				:key="`sub-${item.value}`"
				style="width: auto"
			>
				<v-btn
					small
					:outlined="subCategory !== item.value"
					:color="subCategory !== item.value ? 'grey' : 'grey darken-4'"
					:dark="subCategory === item.value"
					class="mx-1 text-caption"
					active-class="purple white--text"
					depressed
					rounded
					height="25"
					style="min-width: unset"
					@click="SELECT_SUB_CATEGORY(item.value)"
				>
					{{ item.title }}
				</v-btn>
			</swiperSlide>
		</swiper>
		<div style="height: 25px" v-else></div>
	</div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapMutations } = createNamespacedHelpers('program')

export default {
	components: {
		swiper,
		swiperSlide,
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: 'auto',
				spaceBetween: 15,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			},
			slideShow: true,
			categories: [
				{
					value: 'level',
					title: this.$t('레벨별'),
				},
				{
					value: 'topic',
					title: this.$t('주제별'),
				},
			],
			subCategories: {
				inProgress: [
					{ value: 'all', title: this.$t('전체') },
					{ value: 'appointed', title: this.$t('예약중') },
					{ value: 'recent', title: this.$t('최근') },
				],
				level: [
					{ value: 'all', title: this.$t('전체') },
					{ value: '3', title: this.$t('레벨3') },
					{ value: '4', title: this.$t('레벨4') },
					{ value: '5', title: this.$t('레벨5') },
					{ value: '6', title: this.$t('레벨6') },
					{ value: '7', title: this.$t('레벨7') },
				],
				topic: [
					{ value: 'all', title: this.$t('전체') },
					{ value: this.$t('비즈니스'), title: this.$t('비즈니스') },
					{ value: this.$t('일상생활'), title: this.$t('일상생활') },
					{ value: this.$t('시험대비'), title: this.$t('시험대비') },
					{ value: this.$t('시사토픽'), title: this.$t('시사토픽') },
				],
			},
		}
	},
	computed: {
		...mapGetters(['category', 'subCategory', 'inProgressProgramIds']),
	},
	watch: {
		inProgressProgramIds() {
			this.makeListAndSelectDefault()
		},
		category() {
			this.slideShow = false
			setTimeout(() => {
				this.slideShow = true
			}, 1)
		},
	},
	created() {
		this.makeListAndSelectDefault()
	},
	methods: {
		...mapMutations(['SELECT_CATEGORY', 'SELECT_SUB_CATEGORY']),
		makeListAndSelectDefault() {
			if (this.inProgressProgramIds.length > 0) {
				this.categories.unshift({
					value: 'inProgress',
					title: this.$t('수업 중 프로그램'),
				})
			}
			this.SELECT_CATEGORY(this.categories[0].value)
			this.SELECT_SUB_CATEGORY('all')
		},
	},
}
</script>

<style lang="scss" scoped></style>
