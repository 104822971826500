<template>
	<div>
		<Wrap />
	</div>
</template>

<script>
import Wrap from '@/components/lesson-program/Wrap'

export default {
	components: {
		Wrap,
	},
}
</script>

<style lang="scss" scoped></style>
