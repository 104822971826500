<template>
	<div>
		<h2 class="mb-3 text-body-1 font-weight-bold">
			{{ $t('내가 가장 좋아한 튜터 TOP 3 !') }}
		</h2>
		<div>
			<v-card
				v-for="(tutor, i) in tutors"
				:key="tutor.id"
				flat
				:class="{
					'talk-card': lessonType === 0,
					'call-card': lessonType === 1,
				}"
				class="mb-5 px-5 py-5 d-flex align-center"
			>
				<v-img
					max-width="30px"
					alt="내가 가장 좋아한 튜터 TOP 3"
					:src="`https://image.tella.co.kr/v2/designs/crown${i + 1}.png`"
				></v-img>
				<!-- <div class="text-h4"></div> -->
				<TutorAvatar
					class="ml-3"
					:id="tutor.id"
					:name="'kate'"
					:type="0"
					:size="57"
				/>
				<div class="ml-3">
					<div
						:class="{
							talk: lessonType === 0,
							call: lessonType === 1,
						}"
						class="d-inline-block count-chip text-caption font-weight-medium"
					>
						{{ tutor.count }}{{ $t('회 수업') }}
					</div>
					<div class="text-rlh text-body-1 font-weight-bold">
						{{ tutor.name }}
					</div>
					<div
						v-if="tutor.rating.length > 0"
						class="text-body-2 grey--text text--darken-1 font-weigt-medium"
					>
						<v-icon class="text-body-1 mb-1" color="grey darken-1"
							>mdi-star </v-icon
						>{{ averageRating(tutor.rating) }} ({{ tutor.rating.length
						}}{{ $t('회 평가') }})
					</div>
				</div>

				<v-spacer></v-spacer>
				<TutorNation :nation="tutor.nation" />
			</v-card>
		</div>
	</div>
</template>

<script>
import TutorAvatar from '@/components/common/TutorAvatar'
import TutorNation from '@/components/common/TutorNation'
const R = require('ramda')

export default {
	components: {
		TutorAvatar,
		TutorNation,
	},
	props: {
		lessons: {
			required: true,
			type: Array,
		},
		ratings: {
			required: true,
			type: Array,
		},
		lessonType: {
			required: true,
			type: Number,
		},
	},
	computed: {
		tutors() {
			let tutors = []
			const analyzedLessons = R.filter(l => l.status === 'analyzed')(
				this.lessons,
			)
			analyzedLessons.forEach(l => {
				let i = R.findIndex(R.propEq('id', l.tutor_id))(tutors)
				if (i < 0) {
					tutors.push({
						id: l.tutor_id,
						name: l.tutor_name_en,
						nation: l.tutor_nation,
						rating: R.filter(r => r.tutor_id === l.tutor_id)(this.ratings),
						lessons: [l],
						count: 1,
					})
				} else {
					tutors[i].lessons.push(l)
					tutors[i].count++
				}
			})
			tutors = R.sortWith([R.descend(R.prop('count'))])(tutors)
			return tutors.slice(0, 3)
		},
	},
	methods: {
		averageRating(list) {
			return R.pipe(
				R.map(r => r.score),
				R.lift(R.divide)(R.sum, R.length),
			)(list)
		},
	},
}
</script>

<style lang="scss" scoped>
.talk-card {
	background: rgba(30, 120, 255, 0.05);
}
.call-card {
	background: rgba(0, 204, 161, 0.05);
}
.count-chip {
	border-radius: 5px;
	padding: 0 5px;
}
.count-chip.talk {
	color: #1e78ff;
	background: rgba(30, 120, 255, 0.2);
}
.count-chip.call {
	color: #00cca1;
	background: rgba(0, 204, 161, 0.1);
}
</style>
