import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import guard from './guard'
import BookTimeFirst from '../views/book/TimeFirst.vue'
import LessonProgram from '../views/LessonProgram.vue'
import ProgramChange from '../views/program-change/Wrap'
import Program from '../views/Program.vue'

if (!process || process.env.NODE_ENV !== 'test') {
	Vue.use(VueRouter)
}

const routes = [
	{
		path: '/home',
		name: 'Home',
		meta: { layout: 'default' },
		beforeEnter: guard.authOnly,
		component: () =>
			import(/* webpackChunkName: "home" */ '../views/home/Wrap.vue'),
	},
	{
		path: '/lessons',
		redirect: '/lessons/review/all',
	},
	{
		path: '/lessons/appointed',
		name: 'AppointedLessons',
		meta: { layout: 'default' },
		beforeEnter: guard.authOnly,
		component: () =>
			import(/* webpackChunkName: "home" */ '../views/lessons/Appointed.vue'),
	},
	{
		path: '/lessons/review/all',
		name: 'ReviewLessonsAll',
		meta: { layout: 'default' },
		beforeEnter: guard.authOnly,
		component: () =>
			import(/* webpackChunkName: "home" */ '../views/lessons/ReviewAll.vue'),
	},
	{
		path: '/lessons/review/talk',
		name: 'ReviewLessonsTalk',
		meta: { layout: 'default' },
		beforeEnter: guard.authOnly,
		component: () =>
			import(/* webpackChunkName: "home" */ '../views/lessons/ReviewTalk.vue'),
	},
	{
		path: '/lessons/review/call',
		name: 'ReviewLessonsCall',
		meta: { layout: 'default' },
		beforeEnter: guard.authOnly,
		component: () =>
			import(/* webpackChunkName: "home" */ '../views/lessons/ReviewCall.vue'),
	},
	{
		path: '/lessons/review/level-test',
		name: 'ReviewLessonsLevelTest',
		meta: { layout: 'default' },
		beforeEnter: guard.authOnly,
		component: () =>
			import(
				/* webpackChunkName: "home" */ '../views/lessons/ReviewLevelTest.vue'
			),
	},
	{
		path: '/lessons/calendar',
		name: 'LessonsCalendar',
		meta: { layout: 'default' },
		beforeEnter: guard.authOnly,
		component: () =>
			import(/* webpackChunkName: "home" */ '../views/lessons/Calendar.vue'),
	},
	{
		path: '/lessons/:lessonId',
		name: 'LessonReview',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			// import(/* webpackChunkName: "lesson" */ '../views/LessonReview.vue'),
			import(
				/* webpackChunkName: "lesson" */ '../views/lessons/review/Index.vue'
			),
	},
	{
		path: '/lessons/preview/:lessonId',
		name: 'LessonPreview',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "lesson" */ '../views/lessons/Preview.vue'),
	},
	{
		path: '/lessons/print/:lessonId',
		name: 'LessonPrint',
		beforeEnter: guard.authOnly,
		meta: { layout: 'print' },
		component: () =>
			import(
				/* webpackChunkName: "lesson" */ '../views/lessons/print/Index.vue'
			),
	},
	{
		path: '/book/time',
		component: BookTimeFirst,
		beforeEnter: guard.authOnly,
		children: [
			{
				path: ':typeEn',
				name: 'TimeFirstStep1',
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "book" */ '../components/book/time-first/Step1.vue'
					),
			},
			{
				path: ':typeEn/tutor',
				name: 'TimeFirstStep2',
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "book" */ '../components/book/time-first/Step2.vue'
					),
			},
		],
	},
	{
		path: '/book/tutor/:typeEn',
		name: 'TutorFirst',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "book" */ '../views/book/TutorFirst.vue'),
	},
	{
		path: '/book/timezone',
		name: 'BookTimezone',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "book" */ '../views/book/Timezone.vue'),
	},
	// {
	// 	path: '/book',
	// 	component: () =>
	// 		import(/* webpackChunkName: "book" */ '../views/book/Wrap.vue'),
	// 	beforeEnter: guard.authOnly,
	// 	children: [
	// 		{
	// 			path: 'programs',
	// 			component: () =>
	// 				import(
	// 					/* webpackChunkName: "book" */ '../views/book/programs/Wrap.vue'
	// 				),
	// 			beforeEnter: guard.authOnly,
	// 			children: [
	// 				{
	// 					path: '',
	// 					name: 'BookProgram',
	// 					meta: { layout: 'simple' },
	// 					component: () =>
	// 						import(
	// 							/* webpackChunkName: "book" */ '../views/book/programs/list/Wrap.vue'
	// 						),
	// 				},
	// 				{
	// 					path: 'search',
	// 					name: 'BookProgramSearch',
	// 					meta: { layout: 'simple' },
	// 					component: () =>
	// 						import(
	// 							/* webpackChunkName: "book" */ '../views/book/programs/search/Wrap.vue'
	// 						),
	// 				},
	// 				{
	// 					path: ':programId',
	// 					name: 'BookUnit',
	// 					meta: { layout: 'simple' },
	// 					component: () =>
	// 						import(
	// 							/* webpackChunkName: "book" */ '../views/book/programs/unit/Wrap.vue'
	// 						),
	// 				},
	// 			],
	// 		},
	// 		{
	// 			path: 'slots',
	// 			component: () =>
	// 				import(/* webpackChunkName: "book" */ '../views/book/slots/Wrap.vue'),
	// 			beforeEnter: guard.authOnly,
	// 			children: [
	// 				{
	// 					path: 'time',
	// 					name: 'BookSlotTimeFirstStep1',
	// 					meta: { layout: 'simple' },
	// 					component: () =>
	// 						import(
	// 							/* webpackChunkName: "book" */ '../views/book/slots/time-first/Step1.vue'
	// 						),
	// 				},
	// 				{
	// 					path: 'time/:lessonDate/:lessonTime',
	// 					name: 'BookSlotTimeFirstStep2',
	// 					meta: { layout: 'simple' },
	// 					component: () =>
	// 						import(
	// 							/* webpackChunkName: "book" */ '../views/book/slots/time-first/Step2.vue'
	// 						),
	// 				},
	// 				{
	// 					path: 'tutor',
	// 					name: 'BookSlotTutorFirstStep1',
	// 					meta: { layout: 'simple' },
	// 					component: () =>
	// 						import(
	// 							/* webpackChunkName: "book" */ '../views/book/slots/tutor-first/Step1.vue'
	// 						),
	// 				},
	// 				{
	// 					path: 'tutor/:tutorId',
	// 					name: 'BookSlotTutorFirstStep2',
	// 					meta: { layout: 'simple' },
	// 					component: () =>
	// 						import(
	// 							/* webpackChunkName: "book" */ '../views/book/slots/tutor-first/Step2.vue'
	// 						),
	// 				},
	// 			],
	// 		},
	// 	],
	// },
	// {
	// 	path: '/booke-completed/:lessonId',
	// 	name: 'BookCompleted',
	// 	beforeEnter: guard.authOnly,
	// 	meta: { layout: 'simple' },
	// 	component: () =>
	// 		import(/* webpackChunkName: "lesson" */ '../views/book/Completed.vue'),
	// },
	{
		path: '/policy',
		name: 'Policy',
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/Policy.vue'),
	},
	{
		path: '/sign-up',
		name: 'SignUp',
		meta: { layout: 'simple' },
		beforeEnter: guard.authReject,
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/SignUp.vue'),
	},
	{
		path: '/sign-up/form',
		name: 'SignUpForm',
		meta: { layout: 'simple' },
		beforeEnter: guard.authReject,
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/SignUpForm.vue'),
	},
	{
		path: '/sign-up/form/with-kakao',
		name: 'SignUpFormWithKakao',
		meta: { layout: 'simple' },
		beforeEnter: guard.authReject,
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/SignUpFormWithKakao.vue'),
	},
	{
		path: '/sign-up/form/with-facebook',
		name: 'SignUpFormWithFacebook',
		meta: { layout: 'simple' },
		beforeEnter: guard.authReject,
		component: () =>
			import(
				/* webpackChunkName: "auth" */ '../views/SignUpFormWithFacebook.vue'
			),
	},
	{
		path: '/sign-up/landing',
		name: 'SignUpLanding',
		meta: { layout: 'simple' },
		beforeEnter: guard.authOnly,
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/SignUpLanding.vue'),
	},
	{
		path: '/sign-in',
		name: 'SignIn',
		meta: { layout: 'simple' },
		beforeEnter: guard.authReject,
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/SignIn.vue'),
	},
	{
		path: '/find-password',
		name: 'FindPassword',
		meta: { layout: 'simple' },
		beforeEnter: guard.authReject,
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/FindPassword.vue'),
	},
	{
		path: '/auth/reset-password/:code',
		name: 'ResetPassword',
		meta: { layout: 'simple' },
		beforeEnter: guard.authReject,
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/ResetPassword.vue'),
	},
	{
		path: '/find-email',
		name: 'FindEmail',
		meta: { layout: 'simple' },
		beforeEnter: guard.authReject,
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/FindEmail.vue'),
	},
	{
		path: '/find-etc',
		name: 'FindEtc',
		meta: { layout: 'simple' },
		beforeEnter: guard.authReject,
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/FindEtc.vue'),
	},
	{
		path: '/activate',
		name: 'Activate',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/auth/Activate.vue'),
	},
	{
		path: '/more-info',
		name: 'MoreInfo',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "more-info" */ '../components/MoreInfo.vue'),
	},
	// =======
	// v2 테스트
	// {
	// 	path: '/book/time',
	// 	component: BookTimeFirst,
	// 	beforeEnter: guard.authOnly,
	// 	children: [
	// 		{
	// 			path: ':typeEn',
	// 			name: 'TimeFirstStep1',
	// 			meta: { layout: 'simple' },
	// 			component: () =>
	// 				import(
	// 					/* webpackChunkName: "book" */ '../components/book/time-first/Step1.vue'
	// 				),
	// 		},
	// 		{
	// 			path: ':typeEn/tutor',
	// 			name: 'TimeFirstStep2',
	// 			meta: { layout: 'simple' },
	// 			component: () =>
	// 				import(
	// 					/* webpackChunkName: "book" */ '../components/book/time-first/Step2.vue'
	// 				),
	// 		},
	// 	],
	// },
	// {
	// 	path: '/book/tutor/:typeEn',
	// 	name: 'TutorFirst',
	// 	beforeEnter: guard.authOnly,
	// 	meta: { layout: 'simple' },
	// 	component: () =>
	// 		import(/* webpackChunkName: "book" */ '../views/book/TutorFirst.vue'),
	// },
	// =======
	// {
	// 	path: '/book/timezone',
	// 	name: 'BookTimezone',
	// 	beforeEnter: guard.authOnly,
	// 	meta: { layout: 'simple' },
	// 	component: () =>
	// 		import(/* webpackChunkName: "book" */ '../views/book/Timezone.vue'),
	// },
	// {
	// 	path: '/lessons',
	// 	name: 'Lessons',
	// 	beforeEnter: guard.authOnly,
	// 	meta: { layout: 'default' },
	// 	component: () =>
	// 		import(/* webpackChunkName: "lesson" */ '../views/Lessons.vue'),
	// },
	{
		path: '/rating/:lessonId',
		name: 'Rating',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "lesson" */ '../views/Rating.vue'),
	},
	{
		path: '/quest',
		name: 'Quest',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "lesson" */ '../views/Quest.vue'),
	},
	{
		path: '/program-change/:lessonId',
		component: ProgramChange,
		beforeEnter: guard.authOnly,
		children: [
			{
				path: '',
				name: 'ProgramChange',
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "book" */ '../views/program-change/list/Wrap.vue'
					),
			},
			{
				path: 'program/:programId',
				name: 'ProgramChangeUnit',
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "book" */ '../views/program-change/unit/Wrap.vue'
					),
			},
			{
				path: 'program/:programId/unit/:programUnitId',
				name: 'ProgramChangeConfirm',
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "book" */ '../views/program-change/list/Wrap.vue'
					),
			},
		],
	},
	{
		path: '/lesson-program/:lessonId',
		component: LessonProgram,
		beforeEnter: guard.authOnly,
		children: [
			{
				path: '',
				name: 'LessonProgramBooked',
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "book" */ '../components/lesson-program/booked/Wrap.vue'
					),
			},
			{
				path: 'select',
				name: 'LessonProgramSelect',
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "book" */ '../components/lesson-program/select/Wrap.vue'
					),
			},
			{
				path: 'search',
				name: 'LessonProgramSearch',
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "book" */ '../components/lesson-program/search/Wrap.vue'
					),
			},
			{
				path: 'complete',
				name: 'LessonProgramLanding',
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "book" */ '../components/lesson-program/complete/Wrap.vue'
					),
			},
		],
	},
	{
		path: '/program',
		component: Program,
		beforeEnter: guard.authOnly,
		children: [
			{
				path: '',
				name: 'Programs',
				meta: { layout: 'default' },
				component: () =>
					import(
						/* webpackChunkName: "program" */ '../components/program/list/Wrap.vue'
					),
			},
			{
				path: 'search',
				name: 'ProgramSearch',
				meta: { layout: 'default' },
				component: () =>
					import(
						/* webpackChunkName: "program" */ '../components/program/search/Wrap.vue'
					),
			},
			{
				path: 'recommend',
				name: 'ProgramRecommend',
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "program" */ '../components/program/recommend/Wrap.vue'
					),
			},
			{
				path: ':programId',
				name: 'ProgramUnit',
				beforeEnter: guard.authOnly,
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "program" */ '../components/program/unit/Wrap.vue'
					),
			},
			{
				path: ':programId/unit/:unitId',
				name: 'ProgramGuide',
				beforeEnter: guard.authOnly,
				meta: { layout: 'simple' },
				component: () =>
					import(
						/* webpackChunkName: "program" */ '../components/program/guide/Wrap.vue'
					),
			},
		],
	},
	{
		path: '/my',
		name: 'MyPage',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () => import(/* webpackChunkName: "my" */ '../views/MyPage.vue'),
	},
	{
		path: '/pass',
		name: 'Pass',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () => import(/* webpackChunkName: "my" */ '../views/Pass.vue'),
	},
	{
		path: '/plans',
		name: 'Plans',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () => import(/* webpackChunkName: "my" */ '../views/Plans.vue'),
	},
	// {
	// 	path: '/event',
	// 	name: 'Event',
	// 	beforeEnter: guard.authOnly,
	// 	meta: { layout: 'simple' },
	// 	component: () => import(/* webpackChunkName: "my" */ '../views/XEvent.vue'),
	// },
	{
		path: '/event',
		name: 'Event',
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "event" */ '../views/event/Index.vue'),
	},
	{
		path: '/event/2022/from-today',
		name: 'Event2022FromToday',
		meta: { layout: 'simple' },
		component: () =>
			import(
				/* webpackChunkName: "event" */ '../views/event/detail/FromToday.vue'
			),
	},
	{
		path: '/event/2022/coupon-pack',
		name: 'Event2022CouponPack',
		meta: { layout: 'simple' },
		component: () =>
			import(
				/* webpackChunkName: "event" */ '../views/event/detail/CouponPack.vue'
			),
	},
	{
		path: '/event/friend-recommend',
		name: 'EventFriendRecommend',
		meta: { layout: 'simple' },
		component: () =>
			import(
				/* webpackChunkName: "event" */ '../views/event/detail/FriendRecommend.vue'
			),
	},
	{
		path: '/event/reveiw',
		name: 'EventReview',
		meta: { layout: 'simple' },
		component: () =>
			import(
				/* webpackChunkName: "event" */ '../views/event/detail/Review.vue'
			),
	},
	{
		path: '/review',
		name: 'Review',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () => import(/* webpackChunkName: "my" */ '../views/Review.vue'),
	},
	{
		path: '/order',
		name: 'Order',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "order" */ '../views/Order.vue'),
	},
	{
		path: '/order/pre-landing',
		name: 'OrderPreLanding',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "order" */ '../views/OrderPreLanding.vue'),
	},
	{
		path: '/order/:paymentId',
		name: 'OrderLanding',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "order" */ '../views/OrderLanding.vue'),
	},
	{
		path: '/pass-detail',
		name: 'PassDetail',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "my" */ '../views/PassDetail.vue'),
	},
	{
		path: '/pass-history',
		name: 'PassHistory',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "my" */ '../views/PassHistory.vue'),
	},
	{
		path: '/certificate',
		name: 'Certificate',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "my" */ '../views/Certificate.vue'),
	},
	{
		path: '/receipt',
		name: 'Receipt',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "my" */ '../views/Receipt.vue'),
	},
	{
		path: '/attendance',
		name: 'Attendance',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "my" */ '../views/Attendance.vue'),
	},
	{
		path: '/cash',
		name: 'Cash',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () => import(/* webpackChunkName: "my" */ '../views/Cash.vue'),
	},
	{
		path: '/coupon',
		name: 'Coupon',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () => import(/* webpackChunkName: "my" */ '../views/Coupon.vue'),
	},

	{
		path: '/setting',
		name: 'Setting',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/Setting.vue'),
	},
	{
		path: '/setting/name',
		name: 'SettingName',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/SettingName.vue'),
	},
	{
		path: '/setting/phone',
		name: 'SettingPhone',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/SettingPhone.vue'),
	},
	{
		path: '/setting/kakao-id',
		name: 'SettingKakaoId',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/SettingKakaoId.vue'),
	},
	{
		path: '/setting/password',
		name: 'SettingPassword',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/SettingPassword.vue'),
	},
	{
		path: '/setting/social-login',
		name: 'SettingSocialLogin',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/SettingSocialLogin.vue'),
	},
	{
		path: '/setting/name-en',
		name: 'SettingNameEn',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/SettingNameEn.vue'),
	},
	{
		path: '/setting/notify',
		name: 'SettingNotify',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/SettingNotify.vue'),
	},
	{
		path: '/setting/marketing',
		name: 'SettingMarketing',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/SettingMarketing.vue'),
	},
	{
		path: '/setting/style/talk',
		name: 'SettingStyleTalk',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/SettingStyleTalk.vue'),
	},
	{
		path: '/setting/style/call',
		name: 'SettingStyleCall',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/SettingStyleCall.vue'),
	},
	{
		path: '/setting/timezone',
		name: 'SettingTimezone',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/SettingTimezone.vue'),
	},
	{
		path: '/monthly-report',
		name: 'MonthlyReport',
		beforeEnter: guard.authOnly,
		meta: { layout: 'default' },
		component: () =>
			import(/* webpackChunkName: "lesson" */ '../views/MonthlyReport.vue'),
	},
	{
		path: '/review-write',
		name: 'ReviewWrite',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/ReviewWrite.vue'),
	},
	{
		path: '/notice',
		name: 'Notice',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/Notice.vue'),
	},
	{
		path: '/notice/:id',
		name: 'NoticeDetail',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/NoticeDetail.vue'),
	},
	{
		path: '/recommend',
		name: 'Recommend',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/Recommend.vue'),
	},
	{
		path: '/faq',
		name: 'Faq',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () => import(/* webpackChunkName: "info" */ '../views/Faq.vue'),
	},
	{
		path: '/update-log',
		name: 'UpdateLog',
		beforeEnter: guard.authOnly,
		meta: { layout: 'simple' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/UpdateLog.vue'),
	},
	{
		path: '/trial',
		name: 'TrialBook',
		meta: { layout: 'empty' },
		beforeEnter: (to, from, next) => {
			store.getters['auth/memberId']
				? next({ name: 'TrialBookForMember' })
				: next()
		},
		component: () =>
			import(/* webpackChunkName: "trial" */ '../views/TrialBook.vue'),
	},
	{
		path: '/trial-2212b',
		name: 'TrialBook',
		meta: { layout: 'empty' },
		beforeEnter: (to, from, next) => {
			store.getters['auth/memberId']
				? next({ name: 'TrialBookForMember' })
				: next()
		},
		component: () =>
			import(/* webpackChunkName: "trial" */ '../views/TrialBookB.vue'),
	},
	{
		path: '/trial-2212c',
		name: 'TrialBook',
		meta: { layout: 'empty' },
		beforeEnter: (to, from, next) => {
			store.getters['auth/memberId']
				? next({ name: 'TrialBookForMember' })
				: next()
		},
		component: () =>
			import(/* webpackChunkName: "trial" */ '../views/TrialBookC.vue'),
	},
	{
		path: '/trial-jp',
		name: 'TrialBookJP',
		meta: { layout: 'empty' },
		beforeEnter: (to, from, next) => {
			store.getters['auth/memberId']
				? next({ name: 'TrialBookForMemberJP' })
				: next()
		},
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/TrialBookJP.vue'),
	},
	{
		path: '/trial-for-member',
		name: 'TrialBookForMember',
		meta: { layout: 'empty' },
		beforeEnter: guard.authOnly,
		component: () =>
			import(/* webpackChunkName: "auth" */ '../views/TrialBookForMember.vue'),
	},
	{
		path: '/trial-for-member-jp',
		name: 'TrialBookForMemberJP',
		meta: { layout: 'empty' },
		beforeEnter: guard.authOnly,
		component: () =>
			import(
				/* webpackChunkName: "auth" */ '../views/TrialBookForMemberJP.vue'
			),
	},
	{
		path: '/trial/:trialId',
		name: 'TrialConfirmation',
		meta: { layout: 'empty' },
		component: () =>
			import(/* webpackChunkName: "trial" */ '../views/TrialConfirmation.vue'),
	},
	{
		path: '/trial-reschedule/:trialId',
		name: 'TrialReschedule',
		meta: { layout: 'empty' },
		component: () =>
			import(/* webpackChunkName: "trial" */ '../views/TrialReschedule.vue'),
	},
	{
		path: '/trial-report/:trialId',
		name: 'TrialReport',
		meta: { layout: 'empty' },
		component: () =>
			import(/* webpackChunkName: "trial" */ '../views/TrialReport.vue'),
	},
	{
		path: '/design-system',
		name: 'DesignSystem',
		meta: { layout: 'empty' },
		component: () =>
			import(/* webpackChunkName: "info" */ '../views/DesignSystem.vue'),
	},
	{
		path: '/404',
		meta: { layout: 'simple' },
		beforeEnter: guard.authOnly,
		name: 'NotFound',
		component: () =>
			import(/* webpackChunkName: "home" */ '../views/NotFound.vue'),
	},
	{
		path: '*',
		redirect: '/home',
		// 아래처럼 바로 NotFound 경로를 매칭해도 됨
		// component: NotFound
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	},
})

router.beforeEach(async (to, from, next) => {
	try {
		const isAuthChecked = store.getters['auth/isAuthChecked']
		if (!isAuthChecked) {
			store.commit('member/GUESS_TIMEZONE')
			store.commit('member/GUESS_LANGUAGE')
			store.commit('member/GUESS_COUNTRY_CODE')

			await store.dispatch('member/getCountryByIp', to.query)
			await store.dispatch('auth/get')
			await store.dispatch('member/get')
			await store.dispatch('group/get')
		}
	} catch (err) {
		await store.dispatch('auth/signOut')
	} finally {
		if (
			(!to.name === 'Attendance' || from.name !== to.name) &&
			(!to.name === 'Certificate' || from.name !== to.name)
		)
			store.commit('device/STORE_FROM_ROUTER', from)
		next()
	}
})

export default router
