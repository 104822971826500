<template>
	<div id="review-carousel">
		<!-- interval="3000" -->
		<!-- cycle -->
		<v-carousel
			class="mx-auto mb-2"
			continuous
			height="auto"
			hide-delimiter-background
			show-arrows-on-hover
			hide-delimiters
		>
			<!-- <v-carousel-item v-for="i in 10" :key="i" class="pl-2">
				<v-img
					style=""
					class="mx-auto"
					aspect-ratio="1"
					:src="
						require(`../../assets/images/reviews/main-${reviews[i - 1]}.png`)
					"
				></v-img>
			</v-carousel-item> -->
			<v-carousel-item
				class="px-2"
				v-for="review in sampleReviews"
				:key="review.name"
			>
				<v-card
					class="rounded-lg pa-4 mt-8"
					:height="language === 'ko' ? 310 : 350"
				>
					<v-img
						:src="require(`@/assets/images/reviews/${review.src}.png`)"
						width="110"
						height="110"
						style="
							border-radius: 50%;
							position: absolute;
							top: -20px;
							left: 20px;
						"
						class="grey"
					></v-img>
					<div class="text-right" style="height: 100px">
						<p class="text-body-2 grey--text text--darken-1 font-weight-medium">
							{{ review.name }}<br v-if="language === 'ja'" />({{
								review.age
							}}
							/ {{ review.job }})
						</p>
						<p class="text-body-2 primary--text font-weight-medium">
							{{ review.use }}
						</p>
					</div>
					<div class="text-left text-body-1" v-html="review.comment"></div>
				</v-card>
			</v-carousel-item>
		</v-carousel>
	</div>
</template>
<script>
export default {
	props: {
		backgroundColor: {
			default: '#fff',
		},
	},
	data() {
		return {
			sampleReviews: [
				{
					src: 'euna',
					name: this.$t('review.euna.name'),
					age: this.$t('review.euna.age'),
					job: this.$t('review.euna.job'),
					use: this.$t('review.euna.use'),
					comment: this.$t('review.euna.comment'),
				},
				{
					src: 'kyungsun',
					name: this.$t('review.kyungsun.name'),
					age: this.$t('review.kyungsun.age'),
					job: this.$t('review.kyungsun.job'),
					use: this.$t('review.kyungsun.use'),
					comment: this.$t('review.kyungsun.comment'),
				},
				{
					src: 'sunjeong',
					name: this.$t('review.sunjeong.name'),
					age: this.$t('review.sunjeong.age'),
					job: this.$t('review.sunjeong.job'),
					use: this.$t('review.sunjeong.use'),
					comment: this.$t('review.sunjeong.comment'),
				},
				{
					src: 'jiyoung',
					name: this.$t('review.jiyoung.name'),
					age: this.$t('review.jiyoung.age'),
					job: this.$t('review.jiyoung.job'),
					use: this.$t('review.jiyoung.use'),
					comment: this.$t('review.jiyoung.comment'),
				},
				{
					src: 'hyungho',
					name: this.$t('review.hyungho.name'),
					age: this.$t('review.hyungho.age'),
					job: this.$t('review.hyungho.job'),
					use: this.$t('review.hyungho.use'),
					comment: this.$t('review.hyungho.comment'),
				},
				{
					src: 'eunjeong',
					name: this.$t('review.eunjeong.name'),
					age: this.$t('review.eunjeong.age'),
					job: this.$t('review.eunjeong.job'),
					use: this.$t('review.eunjeong.use'),
					comment: this.$t('review.eunjeong.comment'),
				},
				{
					src: 'harin',
					name: this.$t('review.harin.name'),
					age: this.$t('review.harin.age'),
					job: this.$t('review.harin.job'),
					use: this.$t('review.harin.use'),
					comment: this.$t('review.harin.comment'),
				},
				{
					src: 'genie',
					name: this.$t('review.genie.name'),
					age: this.$t('review.genie.age'),
					job: this.$t('review.genie.job'),
					use: this.$t('review.genie.use'),
					comment: this.$t('review.genie.comment'),
				},
				{
					src: 'hannah',
					name: this.$t('review.hannah.name'),
					age: this.$t('review.hannah.age'),
					job: this.$t('review.hannah.job'),
					use: this.$t('review.hannah.use'),
					comment: this.$t('review.hannah.comment'),
				},
				{
					src: 'jin',
					name: this.$t('review.jin.name'),
					age: this.$t('review.jin.age'),
					job: this.$t('review.jin.job'),
					use: this.$t('review.jin.use'),
					comment: this.$t('review.jin.comment'),
				},
			],
		}
	},
	computed: {
		userDevice() {
			return this.$store.getters['userDevice']
		},
		language() {
			return this.$store.getters['member/language']
		},
		reviews() {
			return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
		},
	},
}
</script>

<style scoped>
#reviews-wrap .v-expansion-panel::before {
	box-shadow: unset;
}
.arrow-up {
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	margin-bottom: -5px;
	border-bottom: 8px solid white;
}
</style>

<style>
#review-carousel .highlight {
	background-color: #fff8b5;
}
</style>
