var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex algin-center"},[_c('div',{staticClass:"lesson-menu mr-1",class:{
			active: _vm.$route.name === 'ReviewLessonsAll',
			'font-13': _vm.language === 'ko',
			'font-10': _vm.language !== 'ko',
		},on:{"click":function($event){_vm.$router.push({ name: 'ReviewLessonsAll' }).catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t('page.reviewList.menu.전체'))+" ")]),_c('div',{staticClass:"lesson-menu mr-1",class:{
			active: _vm.$route.name === 'ReviewLessonsTalk',
			'font-13': _vm.language === 'ko',
			'font-10': _vm.language !== 'ko',
		},on:{"click":function($event){_vm.$router.push({ name: 'ReviewLessonsTalk' }).catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t('page.reviewList.menu.25분톡'))+" ")]),_c('div',{staticClass:"lesson-menu mr-1",class:{
			active: _vm.$route.name === 'ReviewLessonsCall',
			'font-13': _vm.language === 'ko',
			'font-10': _vm.language !== 'ko',
		},on:{"click":function($event){_vm.$router.push({ name: 'ReviewLessonsCall' }).catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t('page.reviewList.menu.15분콜'))+" ")]),_c('div',{staticClass:"lesson-menu",class:{
			active: _vm.$route.name === 'ReviewLessonsLevelTest',
			'font-13': _vm.language === 'ko',
			'font-10': _vm.language !== 'ko',
		},on:{"click":function($event){_vm.$router
				.push({
					name: 'ReviewLessonsLevelTest',
					'font-13': _vm.language === 'ko',
					'font-10': _vm.language !== 'ko',
				})
				.catch(function () {})}}},[_vm._v(" "+_vm._s(_vm.$t('page.reviewList.menu.레벨 진단 리포트'))+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }