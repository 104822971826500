import moment from 'moment-timezone'
import Api from '@/services/index'
import { SET } from './mutations'
// import router from '../../../router'

// import * as actions from './mutation-types'

export default {
	get: async ({ commit, rootGetters }) => {
		commit(SET, [])
		const memberId = rootGetters['auth/memberId']
		let query = `?member-id=${memberId}&sort-by=-expiry_date&active=1`

		const res = await Api.get(`points${query}`)
		res.data.forEach(point => {
			point.start_moment = moment.tz(point.start_date, 'Asia/Seoul')
			point.expiry_moment = moment
				.tz(point.expiry_date, 'Asia/Seoul')
				.endOf('day')
		})
		const result = res.data
		commit(SET, result)
	},
}
