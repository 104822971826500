import Api from '@/services/index'
import { STORE, STORE_PROGRESSES } from './mutations'
// const R = require('ramda')

export default {
	get: async ({ commit, rootGetters }) => {
		commit(STORE, [])
		const language = rootGetters['member/language']
		let query = `?id-not-in=110,165&language=${language}&active=1`
		const res = await Api.get(`programs${query}`)
		commit(STORE, res.data)
	},
	getProgresses: async ({ commit, rootGetters }, programId) => {
		commit(STORE_PROGRESSES, [])

		const memberId = rootGetters['auth/memberId']
		let progressQuery = `?active=1&member-id=${memberId}&sort-by=+lesson_datetime`
		if (programId !== undefined) progressQuery += `&program-id=${programId}`
		const res = await Api.get(`progresses${progressQuery}`)

		commit(STORE_PROGRESSES, res.data)
	},
}
