<template>
	<div
		class="d-flex align-center py-3 px-5"
		style="border-bottom: 1px solid #dadada"
	>
		<div style="max-width: calc(100% - 50px)">
			<p class="text-caption mb-1">
				{{ history.created_at }}
			</p>
			<p v-if="history.lesson_id" class="text-body-2 grey--text text-darken-3">
				{{
					history.type === 0
						? `💬&nbsp;&nbsp;${$t('25분톡 수업')}`
						: `📞&nbsp;&nbsp;${$t('15분콜 수업')}`
				}}
				(#{{ history.lesson_id }})
			</p>
			<p v-else>{{ history.content }}</p>
		</div>
		<v-spacer></v-spacer>
		<div
			v-if="history.lesson_id"
			class="text-body-1 font-weight-bold"
			:class="{ 'grey--text text-darken-1': history.operator === 'ADD' }"
		>
			{{ history.operator === 'SUB' ? $t('예약') : $t('예약취소') }}
		</div>
		<div
			v-else
			class="text-body-1 font-weight-bold"
			:class="{ 'grey--text text-darken-1': history.operator === 'ADD' }"
		>
			{{ history.operator === 'SUB' ? $t('사용') : $t('등록') }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		history: {
			required: true,
			type: Object,
		},
	},
}
</script>

<style lang="scss" scoped></style>
