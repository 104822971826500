<template>
	<div>
		<v-row
			no-gutters
			class="white pa-3 d-flex align-center"
			:class="{ fixed: fixed === true }"
		>
			<v-col cols="4">
				<div
					@click="setType('package')"
					:class="{
						active: type === 'package',
						'grey--text text--darken-2': type !== 'package',
					}"
					class="d-inline-block text-body-1 font-weight-bold type package"
				>
					{{ $t('plan.type-picker.package') }}
				</div>
			</v-col>
			<v-col cols="4">
				<div
					@click="setType('talk')"
					:class="{
						active: type === 'talk',
						'grey--text text--darken-2': type !== 'talk',
					}"
					class="text-body-1 font-weight-medium type talk"
				>
					{{ $t('plan.type-picker.talk') }}
				</div>
			</v-col>
			<v-col cols="4">
				<div
					@click="setType('call')"
					:class="{
						active: type === 'call',
						'grey--text text--darken-2': type !== 'call',
					}"
					class="d-inline-block text-body-1 font-weight-medium type call"
				>
					{{ $t('plan.type-picker.call') }}
				</div>
			</v-col>
		</v-row>
		<div v-show="fixed === true" style="height: 60px"></div>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			fixed: false,
		}
	},
	created() {
		window.addEventListener('scroll', this.handleScroll)
	},
	destroyed: function () {
		window.removeEventListener('scroll', this.handleScroll)
	},
	methods: {
		setType(type) {
			this.$emit('setType', type)
		},
		handleScroll() {
			if (window.top.scrollY >= 311) {
				this.fixed = true
			} else this.fixed = false
		},
	},
}
</script>

<style lang="scss" scoped>
.fixed {
	position: fixed;
	top: 0px;
	width: 100%;
	max-width: 400px;
	z-index: 3;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
}
.type {
	border-radius: 20px;
	padding: 6.5px 20px;
	text-align: center;
	width: 100%;
}
.type:hover {
	cursor: default;
}
.type.active {
	color: #fff;
}
.type.package.active {
	background-image: linear-gradient(to right, #846aff 0%, #a58eff 100%);
}
.type.talk.active {
	background-image: linear-gradient(89deg, #5193ff 0%, #76baff 100%);
}
.type.call.active {
	background-image: linear-gradient(to right, #20cebc -38%, #53e28b 115%);
}
</style>
