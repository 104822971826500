<template>
	<div v-if="isLoading === false">
		<Bar />
		<Menu />
		<BannerDialog class="white" />
		<!-- <MainRollingBanner class="white" v-if="language === 'ko'" /> -->
		<section class="white mt-3 py-3" style="position: static">
			<h1 class="px-3 mb-1 font-weight-bold grey--text text--darken-3 text-h2">
				{{ $t('plan.title') }}
			</h1>
			<TypePicker class="pb-2 white" :type="type" @setType="setType" />
			<TypeBanner class="mx-3" :type="type" />
		</section>
		<section class="white px-3 pb-2">
			<PlanGroup
				:type="type"
				:plans="plans[type]"
				:selectedPlanId="selectedPlanId"
				@selectPlan="selectPlan"
			/>
		</section>
		<section class="py-7" style="background-color: #f2f5f9">
			<h1 class="mb-4 px-3 font-weight-bold grey--text text--darken-3 text-h2">
				{{ $t('plan.title.faq') }}
			</h1>
			<FAQPannels />
		</section>
		<!-- <BottomAction :selectedPlan="selectedPlan" :type="type" /> -->
	</div>
</template>

<script>
import Api from '@/services/index'
import Bar from '@/components/common/plan-review-event/Bar'
import Menu from '@/components/common/plan-review-event/Menu'
import TypePicker from './TypePicker'
import TypeBanner from './TypeBanner'
import PlanGroup from './PlanGroup'
import BannerDialog from './BannerDialog'
// import MainRollingBanner from './MainRollingBanner'
import FAQPannels from './FAQPannels'
// import BottomAction from './BottomAction'
// import ReviewTab from './ReviewTab'

export default {
	components: {
		Bar,
		TypePicker,
		TypeBanner,
		PlanGroup,
		BannerDialog,
		FAQPannels,
		Menu,
		// BottomAction,
		// ReviewTab,
	},
	data: () => ({
		tab: 0,
		type: 'package',
		plans: [],
		isLoading: false,
		selectedPlan: null,
	}),
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		category() {
			return this.$route.query.category
		},
		selectedPlanId() {
			return this.selectedPlan !== null ? this.selectedPlan.id : null
		},
	},
	created() {
		if (this.category && ['package', 'talk', 'call'].includes(this.category))
			this.type = this.category
		this.getPlan()
	},
	methods: {
		setType(type) {
			this.type = type
			this.selectedPlan = null
		},
		gtagClick(planId) {
			const params = {
				event_path: `${window.location.hostname}${this.$route.path}`,
				event_category: this.type,
				event_label: planId,
			}
			// eslint-disable-next-line no-undef
			gtag('event', 'click_plan_2', params)
		},
		selectPlan(plan) {
			if (this.selectedPlanId !== plan.id) {
				this.selectedPlan = plan

				this.gtagClick(plan.id)
			} else this.selectedPlan = null
		},
		async getPlan() {
			try {
				this.isLoading = true
				const res = await Api.get('/plans?active=1')
				this.plans = {
					package: res.data.filter(p => p.group === 13),
					talk: res.data.filter(p => p.group === 0),
					call: res.data.filter(p => p.group === 1),
				}
				this.isLoading = false
			} catch (err) {
				console.log(err)
			}
		},
	},
}
</script>

<style scoped lang="scss"></style>
