<template>
	<Wrap />
</template>

<script>
import Wrap from '@/components/sign-up/Wrap'

export default {
	components: {
		Wrap,
	},
	created() {
		this.gtagEvent()
	},
	methods: {
		gtagEvent() {
			// eslint-disable-next-line no-undef
			gtag('event', 'signup_2_bridge', {
				event_path: `my.tella.co.kr${this.$route.path}`,
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
