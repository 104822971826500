<template>
	<div>
		<v-btn
			color="grey"
			class="mt-3 font-weight-regular text-rls"
			text
			@click="sheet = true"
		>
			{{ $t('단어/문장 수 증가 추이 그래프 보기') }} >
		</v-btn>
		<v-bottom-sheet v-model="sheet" fullscreen>
			<v-sheet class="text-center" height="calc(var(--vh, 1vh) * 100)">
				<section style="max-width: 400px" class="mx-auto">
					<v-row class="white py-2" no-gutters align="center">
						<v-col cols="2">
							<v-btn fab icon text small @click="sheet = false">
								<v-icon>mdi-window-close</v-icon>
							</v-btn>
						</v-col>
						<v-col cols="8">
							<h1 class="text-center text-body-1">
								{{
									$t('%{year}년 %{month}월 단어/문장 수 증가 추이', {
										year: selectedYear,
										month: selectedMonth,
									})
								}}
							</h1>
						</v-col>
					</v-row>
				</section>
				<section
					class="pt-3 pb-8"
					style="height: calc(var(--vh, 1vh) * 100 - 60px); overflow-y: auto"
				>
					<ChartOfWord
						:stats="stats"
						style="max-width: 300px"
						class="mx-auto mb-12"
					/>
					<ChartOfSentence
						:stats="stats"
						style="max-width: 300px"
						class="mx-auto"
					/>

					<!-- <SentenceChart /> -->
				</section>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
import ChartOfWord from './ChartOfWord.vue'
import ChartOfSentence from './ChartOfSentence.vue'

export default {
	components: {
		ChartOfWord,
		ChartOfSentence,
	},
	props: {
		stats: {
			type: Array,
			required: true,
		},
		selectedYear: {
			type: Number,
			required: true,
		},
		selectedMonth: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			sheet: false,
		}
	},
}
</script>

<style lang="scss" scoped></style>
