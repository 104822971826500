<template>
	<div class="pb-15">
		<v-row align="center" no-gutters class="pa-3">
			<v-col cols="2">
				<v-btn @click="goBack" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
			</v-col>
			<v-col cols="8">
				<h1 class="text-center text-body-1 font-weight-regular">
					{{ $t('25분톡 수업 스타일') }}
				</h1>
			</v-col>
		</v-row>
		<section class="pa-5">
			<h2
				class="text-h6 mb-1 font-weight-bold"
				v-html="$t('25분톡 수업,<br />맞춤형으로 만들어보세요.')"
			></h2>
			<p
				class="text-body-2 mb-8 grey--text text--darken-3"
				v-html="
					$t(
						'튜터가 학습자님의 선택을 반영하여<br />25분톡 수업을 진행할거예요.',
					)
				"
			></p>
		</section>
		<section class="pa-5">
			<div class="mb-11">
				<h3 class="mb-3 text-body-1">{{ $t('수업방식') }}</h3>
				<v-row no-gutters class="mb-4">
					<v-col cols="6" class="pr-1">
						<div
							class="style-btn"
							@click="lessonStyle = '1'"
							:class="{ active: lessonStyle === '1' }"
						>
							{{ $t('교재에 집중하여') }}
						</div>
					</v-col>
					<v-col cols="6" class="pl-1">
						<div
							class="style-btn"
							@click="lessonStyle = '0'"
							:class="{ active: lessonStyle === '0' }"
						>
							{{ $t('대화하듯 편하게') }}
						</div>
					</v-col>
				</v-row>
				<p class="grey--text text-body-2" v-html="lessonStyleKr"></p>
			</div>
			<div class="mb-11">
				<h3 class="mb-3 text-body-1">
					{{ $t('자기 소개 / 아이스 브레이킹') }}
				</h3>
				<v-row no-gutters class="mb-4">
					<v-col cols="6" class="pr-1">
						<div
							class="style-btn"
							@click="iceBreak = '1'"
							:class="{ active: iceBreak === '1' }"
						>
							{{ $t('서로 소개') }}
						</div>
					</v-col>
					<v-col cols="6" class="pl-1">
						<div
							class="style-btn"
							@click="iceBreak = '0'"
							:class="{ active: iceBreak === '0' }"
						>
							{{ $t('자기 소개 없이') }}
						</div>
					</v-col>
				</v-row>
				<p class="grey--text text-body-2" v-html="iceBreakKr"></p>
			</div>
			<div class="mb-11">
				<h3 class="mb-3 text-body-1">{{ $t('첨삭에 대한 설명') }}</h3>
				<v-row no-gutters class="mb-4">
					<v-col cols="6" class="pr-1">
						<div
							class="style-btn"
							@click="grammarExplanations = '1'"
							:class="{ active: grammarExplanations === '1' }"
						>
							{{ $t('튜터가 알아서') }}
						</div>
					</v-col>
					<v-col cols="6" class="pl-1">
						<div
							class="style-btn"
							@click="grammarExplanations = '0'"
							:class="{ active: grammarExplanations === '0' }"
						>
							{{ $t('내가 질문할 때만') }}
						</div>
					</v-col>
				</v-row>
				<p class="grey--text text-body-2" v-html="grammarExplanationsKr"></p>
			</div>
			<v-btn
				class="mb-3"
				block
				x-large
				@click.native="saveStyle"
				color="grey darken-3 white--text"
				depressed
				:disabled="!valid || isLoading"
				>{{ $t('변경') }}</v-btn
			>
		</section>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	data() {
		return {
			isLoading: false,
			id: null,
			lessonStyle: null,
			iceBreak: null,
			grammarExplanations: null,
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		lessonStyleKr() {
			return this.lessonStyle === '0'
				? `${this.$t(
						'교재 내용을 다 끝내지 못할 수 있지만,<br />수업 주제에 대한 이야기를 자유롭게 나눌 수 있어요.',
				  )}`
				: this.lessonStyle === '1'
				? `${this.$t(
						'다른 대화보다는<br />교재의 내용을 충분히 익히는데 집중해요.',
				  )}`
				: ''
		},
		iceBreakKr() {
			return this.iceBreak === '0'
				? `${this.$t(
						'짧은 인사 후 바로 수업을 시작해<br />수업 진도에 속도를 높여요.',
				  )}`
				: this.iceBreak === '1'
				? `${this.$t(
						'3분동안 서로 간단하게 소개하거나<br />아이스 브레이킹을 한 후에 수업을 시작해요.',
				  )}`
				: ''
		},
		grammarExplanationsKr() {
			return this.grammarExplanations === '0'
				? this.$t('내가 튜터에게 질문한 문장에 대해서만 설명을 들어요')
				: this.grammarExplanations === '1'
				? `${this.$t(
						'튜터가 문장 첨삭을 해드린 후<br />부연 설명이 필요하다고 판단한 내용을 설명해드려요.',
				  )}`
				: ''
		},
		valid() {
			return this.lessonStyle !== null &&
				this.iceBreak !== null &&
				this.grammarExplanations !== null
				? true
				: false
		},
	},
	/**
	 * 수업 스타일을 불러온다.
	 * 수업 스타일이 있다면 style에 저장한다.
	 * 선택을 끝낸 뒤 저장한다.
	 * 서버에서 해당 멤버로 디비를 뒤져서 있으면 update, 없으면 insert 한다.
	 */
	created() {
		this.getStudentInfo()
	},
	methods: {
		async getStudentInfo() {
			const res = await Api.get(`lesson-style/talk?member-id=${this.memberId}`)
			const style = res.data
			if (style) {
				this.id = style.id
				this.lessonStyle = style.lesson_style.toString()
				this.iceBreak = style.ice_break.toString()
				this.grammarExplanations = style.grammar_explanations.toString()
			}
		},
		async saveStyle() {
			try {
				this.isLoading = true
				let params = {
					lessonStyle: this.lessonStyle,
					iceBreak: this.iceBreak,
					grammarExplanations: this.grammarExplanations,
				}
				if (this.id) {
					await Api.patch(
						`lesson-style/talk/${this.id}?member-id=${this.memberId}`,
						params,
					)
				} else {
					params.memberId = this.memberId
					await Api.post(`lesson-style/talk`, params)
				}

				alert(this.$t('텔라톡 수업 스타일이 설정되었습니다.'))
			} catch (err) {
				alert(
					this.$t(
						'수업 스타일을 설정하는 데 오류가 발생했습니다. 고객센터로 문의주세요.',
					),
				)
			} finally {
				this.isLoading = false
			}
		},
		goBack() {
			this.$router.push({ name: 'Setting' })
		},
	},
}
</script>

<style scoped>
.style-btn {
	text-align: center;
	padding: 15px;
	background: #ffffff;
	border: 1px solid #c4c4c4;
	box-sizing: border-box;
	border-radius: 10px;
	color: #838383;
}
.style-btn.active {
	background: rgba(30, 120, 255, 0.05);
	border-color: #1e78ff;
	color: #1e78ff;
}
</style>
