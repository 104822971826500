<template>
	<div>
		<section>
			<table class="mb-3" width="100%" cellspacing="0">
				<tr>
					<td class="table-title">{{ $t('수강생 명') }}</td>
					<td>{{ memberNameKr }}</td>
				</tr>
				<tr>
					<td class="table-title">{{ $t('수강권 명') }}</td>
					<td>{{ passName }}</td>
				</tr>
				<tr>
					<td class="table-title">{{ $t('등록일') }}</td>
					<td>{{ firstCreatedMoment }}</td>
				</tr>
				<tr v-if="includeTerm === true">
					<td class="table-title">{{ $t('수강 기간') }}</td>
					<td class="text-nls">
						{{ startMoment.format(yeardateformat) }} ~
						{{ endMoment.format(yeardateformat) }}
					</td>
				</tr>

				<tr v-if="includePrice === true">
					<td class="table-title">{{ $t('결제 금액') }}</td>
					<td>
						{{ payMethod === 'Paypal' || payMethod === '페이팔' ? 'USD ' : ''
						}}{{ wonCommaFormat(partialPrice) }}
						{{
							payMethod !== 'Paypal' && payMethod !== '페이팔' ? $t('원') : ''
						}}
					</td>
				</tr>
				<tr v-if="includePrice === true">
					<td class="table-title">{{ $t('결제 방법') }}</td>
					<td>{{ payMethod }}</td>
				</tr>
			</table>
			<table width="100%" cellspacing="0">
				<tr>
					<td class="table-title">{{ $t('법인명') }}</td>
					<td>{{ $t('주식회사 텔라') }}</td>
				</tr>
				<tr>
					<td class="table-title">{{ $t('대표이사') }}</td>
					<td>{{ $t('진유하') }}</td>
				</tr>
				<tr>
					<td class="table-title text-nls">{{ $t('사업자등록번호') }}</td>
					<td>114-87-15235</td>
				</tr>
			</table>
		</section>
		<section>
			<p class="my-5 text-center text-body-1">
				{{ $t('위의 사항은 사실과 같음을 증명합니다.') }}<br />{{ today }}
			</p>

			<div class="d-flex justify-center">
				<v-img
					max-width="120"
					src="@/assets/images/certificate/ceo-sign.png"
				></v-img>
			</div>
			<p class="text-center text-body-1">
				{{ $t('(주) 텔라 대표이사 진유하') }}
			</p>
		</section>
	</div>
</template>

<script>
import moment from 'moment-timezone'

export default {
	props: {
		passName: {
			required: true,
		},
		firstCreatedAt: {
			required: true,
		},
		includeTerm: {
			required: true,
		},
		startDate: {
			required: true,
		},
		endDate: {
			required: true,
		},
		includePrice: {
			required: true,
		},
		payPrice: {
			required: true,
		},
		payMethod: {
			required: true,
		},
		availableMoments: {
			required: true,
		},
	},
	computed: {
		today() {
			return moment.tz('Asia/Seoul').format(this.yeardateformat)
		},
		startMoment() {
			return moment(this.startDate)
		},
		endMoment() {
			return moment(this.endDate)
		},
		yeardateformat() {
			return this.$store.getters['member/yeardateformat']
		},
		memberNameKr() {
			return this.$store.getters['member/nameKr']
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		paymentId() {
			return this.$route.query['payment-id']
		},
		groupPointTypeId() {
			return this.$route.query['group-point-type-id']
		},
		passCouponMemberId() {
			return this.$route.query['pass-coupon-member-id']
		},
		pointId() {
			return this.$route.query['point-id']
		},
		firstCreatedMoment() {
			return moment
				.tz(this.firstCreatedAt, 'Asia/Seoul')
				.format(this.yeardateformat)
		},
		partialPrice() {
			const startMoment = this.availableMoments[0]
			const endMoment = this.availableMoments[1]

			const totalDays = endMoment.diff(startMoment, 'days') + 1
			const partioalDays = this.endMoment.diff(this.startMoment, 'days') + 1
			return Math.round((this.payPrice * (partioalDays / totalDays)) / 10) * 10
		},
	},
	methods: {
		wonCommaFormat: won => won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
	},
}
</script>

<style lang="scss" scoped>
table {
	border-top: 2px solid #000;
	border-bottom: 2px solid #000;
	tr {
		td {
			box-sizing: border-box;
			padding: 10px;
			border-bottom: 1px solid #c4c4c4;
			font-size: 14px !important;
			font-weight: medium !important;
		}

		td.table-title {
			background-color: #f2f2f2;
			width: 110px;
		}
	}

	tr:last-child {
		td {
			border-bottom: unset;
		}
	}
}
</style>
