export const AUTH_CHECKED = 'AUTH_CHECKED'
export const INITIALIZE = 'INITIALIZE'
export const STORE_ID = 'STORE_ID'
export const STORE_KAKAO_TOKEN = 'STORE_KAKAO_TOKEN'
export const STORE_KAKAO_ME = 'STORE_KAKAO_ME'
export const STORE_FACEBOOK_TOKEN = 'STORE_FACEBOOK_TOKEN'
export const STORE_FACEBOOK_ME = 'STORE_FACEBOOK_ME'

export default {
	[AUTH_CHECKED](state) {
		state.isAuthChecked = true
	},
	[STORE_ID](state, payload) {
		state.memberId = payload
	},
	[INITIALIZE](state) {
		state.memberId = null
	},
	[STORE_KAKAO_TOKEN](state, payload) {
		state.kakaoToken = payload
	},
	[STORE_KAKAO_ME](state, payload) {
		state.kakaoMe = payload
	},
	[STORE_FACEBOOK_TOKEN](state, payload) {
		state.facebookToken = payload
	},
	[STORE_FACEBOOK_ME](state, payload) {
		state.facebookMe = payload
	},
}
