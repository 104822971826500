<template>
	<v-dialog v-model="dialog" width="310" content-class="rounded-lg">
		<v-card rounded="lg" class="py-3 px-4">
			<div class="pt-2 pb-3">
				<h1 class="modal-h1 font-weight-bold">
					{{ this.$t('order-landing.title.do-you-want-change-start-date') }}
				</h1>
			</div>
			<div class="py-2">
				<p class="modal-text">
					{{
						$t('order-landing.title.change-start-date-info', {
							formattedStartDate,
							today,
						})
					}}
				</p>
			</div>
			<v-row no-gutters class="py-3">
				<v-col cols="6" class="pr-1">
					<v-btn
						class="primary--0"
						depressed
						block
						@click="$emit('dialogControl')"
					>
						{{ $t('order-landing.button.no') }}
					</v-btn>
				</v-col>
				<v-col cols="6" class="pl-1">
					<v-btn color="primary" depressed block @click="setPoints"
						>{{ $t('order-landing.button.yes') }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card>
	</v-dialog>
</template>

<script>
import Api from '@/services/index'
import moment from 'moment-timezone'

export default {
	props: {
		dialogControl: { required: true, type: Boolean },
		startDate: { required: true, type: String },
		paymentId: { required: true, type: [Number, String] },
	},
	data() {
		return {
			dialog: false,
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		today() {
			return moment().locale(this.language).format('ll')
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		formattedStartDate() {
			return moment(this.startDate).locale(this.language).format('ll')
		},
	},
	watch: {
		dialogControl() {
			this.dialog = !this.dialog
		},
	},
	methods: {
		async setPoints() {
			await Api.patch(
				`/payments/start-date?payment-id=${this.paymentId}&member-id=${this.memberId}`,
			)
			this.$router.push({ name: 'Home' })
			setTimeout(() => {
				this.$store.commit('device/BOOK_ACTION_SHEET_CONTROL')
			}, 500)
		},
	},
}
</script>

<style lang="scss" scoped></style>
