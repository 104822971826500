const mostSearchedCountries = [
	['South Korea (대한민국)', 'kr', '82'],
	['Japan (日本)', 'jp', '81'],
	['United States', 'us', '1', 0],
	['Australia', 'au', '61', 0],
	[
		'Canada',
		'ca',
		'1',
		1,
		[
			'204',
			'226',
			'236',
			'249',
			'250',
			'289',
			'306',
			'343',
			'365',
			'387',
			'403',
			'416',
			'418',
			'431',
			'437',
			'438',
			'450',
			'506',
			'514',
			'519',
			'548',
			'579',
			'581',
			'587',
			'604',
			'613',
			'639',
			'647',
			'672',
			'705',
			'709',
			'742',
			'778',
			'780',
			'782',
			'807',
			'819',
			'825',
			'867',
			'873',
			'902',
			'905',
		],
	],
	['New Zealand', 'nz', '64'],
	['Vietnam (Việt Nam)', 'vn', '84'],
	['Indonesia', 'id', '62'],
	['Philippines', 'ph', '63'],
	['Uganda', 'ug', '256'],
]

export default mostSearchedCountries.map(country => ({
	name: country[0].includes('(') ? country[0].split('(')[0] : country[0],
	iso2: country[1],
	dialCode: country[2],
	priority: country[3] || 0,
	areaCodes: country[4] || null,
}))
