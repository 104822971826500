const R = require('ramda')

export default {
	isLoading: state => state.isLoading,
	selectedType: state => state.selectedType,
	lessons: state => R.sortWith([R.ascend(R.prop('moment'))])(state.lessons),
	programIds(state) {
		const isNotNull = lesson => lesson.program_id !== null
		return R.pipe(
			R.filter(isNotNull),
			R.map(x => x.program_id),
			R.dropRepeats(),
		)(state.lessons)
	},
	programUnitIds(state) {
		const isNotNull = lesson => lesson.program_unit_id !== null
		return R.pipe(
			R.filter(isNotNull),
			R.map(x => x.program_unit_id),
			R.dropRepeats(),
		)(state.lessons)
	},
	talkLessons: (state, getters) =>
		R.filter(lesson => lesson.lesson_type === 0, getters.lessons),
	callLessons: (state, getters) =>
		R.filter(lesson => lesson.lesson_type === 1, getters.lessons),
}
