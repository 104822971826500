var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"px-5 d-flex align-center py-3",staticStyle:{"border-bottom":"1px solid #dadada"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"mb-1 d-day px-3 text-caption font-weight-medium",class:{ unavailable: _vm.available === false }},[_vm._v(" "+_vm._s(_vm.dayDiff)+" ")]),_c('div',[_c('p',{staticClass:"text-body-1 font-weight-bold",class:{
					'grey--text text--lighten-1': _vm.available === false,
				}},[_vm._v(" "+_vm._s(_vm.coupon.coupon_type)+" ")]),_c('p',{staticClass:"text-caption",class:{
					'grey--text text--lighten-1': _vm.available === false,
					'grey--text text--darken-1': _vm.available === true,
				}},[_vm._v(" ~"+_vm._s(_vm.$t('%{expirationDate}까지 사용 가능', { expirationDate: _vm.coupon.expiration_date, }))+" ")])])]),_c('v-col',{staticClass:"text-right font-weight-bold text-h6",class:{
			'grey--text text--lighten-2': _vm.available === false,
			'primary--text': _vm.available === true,
		},attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.numberWithCommas(_vm.coupon.discount))+" "+_vm._s(_vm.coupon.discount_type)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }