<template>
	<div class="d-flex align-center justify-center">
		<img
			:class="{ 'd-none': pageGroup === 0 }"
			@click="addPage(-5)"
			style="vertical-align: middle"
			src="@/assets/images/icon/pagiantion-prev.png"
			alt=""
		/>
		<div v-if="pageGroup > 0">
			<div @click="clickPage(1)" class="pagination-item">1</div>
		</div>
		<div v-if="pageGroup > 0" class="pagination-item">...</div>
		<div v-for="i in visiblePageCounts" :key="i">
			<div
				:data-test="`pagination${i}`"
				@click="clickPage(pageGroup * 5 + i)"
				class="pagination-item"
				:class="{
					'primary--text': page === pageGroup * 5 + i,
				}"
			>
				{{ pageGroup * 5 + i }}
			</div>
		</div>
		<div v-if="pageGroup < totalPageGroup" class="pagination-item">...</div>
		<div v-if="pageGroup < totalPageGroup">
			<div @click="clickPage(totalPage)" class="pagination-item">
				{{ totalPage }}
			</div>
		</div>
		<img
			:class="{ disabled: page >= totalPage }"
			@click="addPage(5)"
			style="
				vertical-align: middle;
				transform: rotate(180deg);
				margin-top: -2px;
			"
			src="@/assets/images/icon/pagiantion-prev.png"
			alt=""
		/>
	</div>
</template>

<script>
export default {
	props: {
		page: {
			required: true,
			type: Number,
		},
		totalPage: {
			required: true,
			type: Number,
		},
	},
	computed: {
		visiblePageCounts() {
			return Math.min(5, this.totalPage - this.pageGroup * this.maxCount)
		},
		maxCount() {
			return Math.min(5, this.totalPage)
		},
		pageGroup() {
			return parseInt((this.page - 1) / this.maxCount)
		},
		totalPageGroup() {
			return parseInt((this.totalPage - 1) / this.maxCount)
		},
	},
	methods: {
		addPage(n) {
			this.clickPage(this.page + n)
		},
		clickPage(page) {
			if (page <= 0 || page > this.totalPage) return
			this.$emit('changePage', page)
		},
	},
}
</script>

<style lang="scss" scoped>
.pagination-item {
	margin: 0 8px;
	font-size: 14px;
	font-weight: 500;
	color: #111111;
}
.pagination-item:hover {
	cursor: pointer;
}
</style>
