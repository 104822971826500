<template>
	<v-container fluid>
		<v-row>
			<v-col cols="12" sm="6" lg="4">
				<div>
					<v-btn large depressed color="primary">PRIMARY</v-btn>
					<v-btn depressed color="primary">PRIMARY</v-btn>
					<v-btn small depressed color="primary">PRIMARY</v-btn>
					<v-btn x-small depressed color="primary">PRIMARY</v-btn>
					<v-btn depressed color="primary" disabled>PRIMARY</v-btn>
				</div>
				<div>
					<v-btn large depressed color="primary--0">PRIMARY</v-btn>
					<v-btn depressed color="primary--0">PRIMARY</v-btn>
					<v-btn small depressed color="primary--0">PRIMARY</v-btn>
					<v-btn x-small depressed color="primary--0">PRIMARY</v-btn>
					<v-btn depressed color="primary--0" disabled>PRIMARY</v-btn>
				</div>
				<div>
					<v-btn large depressed color="grey lighten-3">PRIMARY</v-btn>
					<v-btn depressed color="grey lighten-3">PRIMARY</v-btn>
					<v-btn small depressed color="grey lighten-3">PRIMARY</v-btn>
					<v-btn x-small depressed color="grey lighten-3">PRIMARY</v-btn>
					<v-btn depressed color="grey lighten-3" disabled>PRIMARY</v-btn>
				</div>
				<div>
					<v-btn large outlined color="primary">PRIMARY</v-btn>
					<v-btn outlined color="primary">PRIMARY</v-btn>
					<v-btn small outlined color="primary">PRIMARY</v-btn>
					<v-btn x-small outlined color="primary">PRIMARY</v-btn>

					<v-btn outlined color="primary" disabled>PRIMARY</v-btn>
				</div>
				<div>
					<v-btn class="slot" outlined x-large>
						<v-icon class="mr-2">mdi-check-circle</v-icon> TIME SLOT
					</v-btn>
					<v-btn class="slot selected" outlined x-large>
						<v-icon class="mr-2">mdi-check-circle</v-icon> TIME SLOT
					</v-btn>
				</div>
				<div>
					<v-btn class="slot" outlined x-small>13:00</v-btn>
					<v-btn class="slot selected" outlined x-small>13:00</v-btn>
					<v-btn class="slot disabled" outlined x-small>13:00</v-btn>
				</div>
			</v-col>
			<v-col>
				<v-text-field
					outlined
					class="mb-3"
					label="Example Text"
					hint="Hint message"
				></v-text-field>
				<v-text-field
					outlined
					class="mb-3"
					label="Example Text"
					hint="Hint message"
					persistent-hint
				></v-text-field>

				<v-text-field
					outlined
					class="mb-3"
					label="Example Text"
					hint="Hint message"
					error-messages="error message"
					error
				></v-text-field>
				<v-text-field
					outlined
					label="Example Text"
					value="Disabled"
					hint="Hint message"
					disabled
				></v-text-field>
				<v-text-field
					outlined
					label="Example Text"
					hint="Hint message"
					disabled
				></v-text-field>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
