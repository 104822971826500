<template>
	<div style="position: relative">
		<v-fade-transition v-if="showTooltip">
			<div
				v-click-outside.native="closeTooltip"
				class="text-caption text-left tooltip white-space-pre"
			>
				{{ $t('plan.tooltip.text.plna-info') }}

				<div class="chat-tail"></div>
			</div>
		</v-fade-transition>
		<!-- @click="showTooltip = true" -->
		<div
			@mouseleave="showTooltip = false"
			@mouseover="showTooltip = true"
			class="
				d-flex
				align-center
				text-body-2
				grey--text
				text--darken-2
				font-weight-medium
			"
		>
			<img
				class="mr-1"
				:src="require(`@/assets/images/plans/ic-notice-b-${type}.png`)"
				alt=""
			/>
			{{
				$t('plan.tooltip.button.plna-info', {
					countOfWeek,
				})
			}}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		countOfWeek: { required: true, type: Number },
		type: { required: true, type: String },
	},
	data() {
		return {
			showTooltip: false,
		}
	},
	methods: {
		closeTooltip() {
			this.showTooltip = false
		},
	},
}
</script>

<style lang="scss" scoped>
.tooltip {
	z-index: 3;
	color: #fff;
	line-height: 1.3em;
	border-radius: 5px;
	position: absolute;
	top: -87px;
	left: -10px;
	width: 300px;
	padding: 10px 10px;
	object-fit: contain;
	background-color: #3c3b41;

	.chat-tail {
		width: 0;
		height: 0;
		position: absolute;
		bottom: -5px;
		left: 10px;
		margin-left: 2.5px;
		border-left: 3px solid transparent;
		border-right: 3px solid transparent;

		border-top: 5px solid #353535;
	}
}
</style>
