<template>
	<div class="grey lighten-3">
		<!-- height: calc(var(--vh, 1vh) * 100); -->
		<v-main
			:class="background"
			:style="shadow"
			style="
				min-height: calc(var(--vh, 1vh) * 100);
				overflow-y: auto;
				max-width: 400px;
				margin: 0 auto;
			"
		>
			<slot />
		</v-main>
	</div>
</template>

<script>
/**
 * memberId가 null이거나 loading이면
 */
export default {
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		shadow() {
			return this.userDevice === 'desktop'
				? 'box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);'
				: ''
		},
		background() {
			return this.$store.getters['device/background']
		},
	},
}
</script>

<style></style>
