const R = require('ramda')

export default {
	ratings: state => state.ratings,
	isLoading: state => state.isLoading,
	talkRatings: (state, getters) =>
		R.filter(rating => rating.lesson_type === 0, getters.ratings),
	callRatings: (state, getters) =>
		R.filter(rating => rating.lesson_type === 1, getters.ratings),
}
