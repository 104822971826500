<template>
	<div>
		<section class="py-5 px-3 text-center">
			<v-row no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="$router.push({ name: 'Home' })">
						<v-icon>mdi-window-close</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-body-1 font-weight-bold">{{ $t('예약 완료') }}</h1>
				</v-col>
			</v-row>
		</section>
		<section class="pa-8">
			<SelectedCard class="mb-5" :lesson="lesson" />
			<ul
				class="text-caption grey--text text--darken-1"
				style="line-height: 1.3em"
			>
				<li>{{ $t('예약 취소는 수업 1시간전까지 가능합니다.') }}</li>
			</ul>
		</section>
		<section style="width: 100%; position: absolute; bottom: 0; bottom: 0">
			<v-btn
				height="55px"
				large
				tile
				block
				color="primary"
				@click="
					$router.push({
						name: 'LessonProgramSelect',
						params: { lessonId },
					})
				"
				>{{ $t('프로그램 선택하기') }}</v-btn
			>
		</section>
	</div>
</template>
<script>
import SelectedCard from '../common/SelectedCard'
const R = require('ramda')

/**
 * 프로그램 전체 불러오기(App 1번)
 * 프로그래스 group-by 프로그램별(페이지별 1번)
 * 미래(appointed) 수업 불러오기(페이지별 1번)
 */
export default {
	components: {
		SelectedCard,
	},
	computed: {
		lessonId() {
			return Number(this.$route.params.lessonId)
		},
		lesson() {
			return R.find(R.propEq('id', this.lessonId))(
				this.$store.getters['appointed/lessons'],
			)
		},
	},
}
</script>

<style lang="scss" scoped></style>
