<template>
	<v-dialog
		v-model="dialog"
		fullscreen
		hide-overlay
		transition="dialog-bottom-transition"
	>
		<v-card class="rounded-t-xl">
			<div
				class="mx-auto"
				style="position: relative; max-width: 400px"
				:style="
					userDevice === 'desktop'
						? 'border-right:1px solid #dadada;border-left:1px solid #dadada;'
						: ''
				"
			>
				<section class="mb-3">
					<v-row class="white py-2" no-gutters align="center">
						<v-col cols="8" offset="2">
							<h1 class="text-center text-body-1">{{ $t('유닛 목록') }}</h1>
						</v-col>
						<v-col cols="2" class="text-right">
							<v-btn fab icon text small @click="clickUnit(null)">
								<v-icon>mdi-chevron-down</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</section>
				<section class="px-5 mb-8">
					<div class="d-flex align-center" v-if="selectedProgram">
						<v-img max-width="40" class="rounded-lg" :src="programImgUrl">
						</v-img>
						<div class="pl-2">
							<h1 class="font-weight-bold text-body-1">
								{{ selectedProgram.name }}
							</h1>
							<p class="text-body-2">
								{{ selectedProgram.description }}
							</p>
						</div>
					</div>
				</section>
				<p class="px-5 mb-3 text-right text-caption grey--text text--darken-1">
					{{ $t('수강한 유닛 수') }}
					<span class="primary--text font-weight-medium">{{
						completedCount
					}}</span
					>/{{ units.length }}
				</p>
				<section
					v-if="unitsWithStatus.length > 0"
					class="px-5 pb-5"
					style="overflow-y: auto; height: calc(var(--vh, 1vh) * 100 - 255px)"
				>
					<div
						v-for="unit in unitsWithStatus"
						:key="unit.id"
						@click="clickUnit(unit)"
						:class="{ active: selectedUnit && selectedUnit.id === unit.id }"
						class="d-flex align-center px-3 py-1 mb-3 unit-box"
					>
						<div>
							<h2 class="text-body-2">
								Unit {{ unit.unit }}.
								<span
									class="
										status-chip
										text-caption
										grey
										darken-4
										rounded-pill
										white--text
									"
									v-if="unit.appointed"
									>{{ $t('예약') }}</span
								>
								<span
									class="
										status-chip
										text-caption
										primary
										rounded-pill
										white--text
									"
									v-else-if="unit.completed"
									>{{ $t('완료') }}</span
								>
								<span
									class="
										status-chip
										text-caption
										primary
										rounded-pill
										white--text
									"
									v-else-if="unit.onGoing"
									>{{ $t('진행 중') }}</span
								>
							</h2>
							<h3 class="text-caption">{{ title(unit.unit_title) }}</h3>
						</div>
						<v-spacer></v-spacer>
						<div>
							<v-icon
								v-if="selectedUnit && unit.id === selectedUnit.id"
								class="primary--text"
								>mdi-check-circle-outline</v-icon
							>
							<v-icon v-else class="grey--text text--lighten-2"
								>mdi-check-circle-outline</v-icon
							>
						</div>
					</div>
				</section>
				<div
					class="white"
					style="
						position: fixed;
						bottom: 0;
						width: 100%;
						z-index: 4;
						max-width: 400px;
					"
				>
					<v-btn
						height="55px"
						:disabled="isLoading === true || selectedUnit === null"
						large
						tile
						block
						color="primary"
						@click="selectUnit()"
						>{{ $t('선택 완료 🎉') }}</v-btn
					>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
import Api from '@/services/index'
import moment from 'moment-timezone'
const R = require('ramda')

export default {
	props: {
		selectedProgram: {
			require: true,
		},
		lesson: {
			require: true,
		},
	},
	data: () => ({
		isLoading: false,
		dialog: false,
		units: [],
		selectedUnit: null,
		isChanging: false,
	}),
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		progresses() {
			return this.$store.getters['program/progresses']
		},
		isBook() {
			return this.$route.query['is-book']
		},
		completedCount() {
			const completedProgresses = R.pipe(
				R.filter(
					p =>
						this.selectedProgram &&
						p.status === 0 &&
						p.program_id === this.selectedProgram.id,
				),
				R.map(p => p.program_unit_id),
				R.uniq(),
			)(this.progresses)

			return completedProgresses.length
		},
		unitsWithStatus() {
			return this.units.map(u => {
				u.completed = false
				u.onGoing = false
				u.appointed = false
				const progresses = R.filter(p => p.program_unit_id === u.id)(
					this.$store.getters['program/progresses'],
				)
				if (progresses.length > 0) {
					u.completed = R.find(p => p.status === 0)(progresses) !== undefined
					u.onGoing = R.find(p => p.status === 1)(progresses) !== undefined
				}

				u.appointed =
					R.find(l => l.program_unit_id === u.id)(
						this.$store.getters['appointed/lessons'],
					) !== undefined
				return u
			})
		},

		language() {
			return this.$store.getters['member/language']
		},
		programImgUrl() {
			return this.selectedProgram
				? `https://image.tella.co.kr/v1/program/thumbnail/portrait/${this.language}/${this.selectedProgram.id}.jpg`
				: ''
		},
	},
	watch: {
		selectedProgram(val) {
			if (val !== null) this.getUnits()
		},
	},
	methods: {
		clickUnit(unit) {
			unit === null ? (this.dialog = false) : (this.selectedUnit = unit)
			this.$emit('selectUnit', unit)
		},
		async getUnits() {
			try {
				this.isLoading = true
				this.dialog = true
				this.selectedUnit = null
				this.units = []
				// const memberId = this.$store.getters['auth/memberId']
				const unitQuery = `?active=1&sort_by=+unit&program-id=${this.selectedProgram.id}&language=${this.language}`

				const res = await Api.get(`programs/units${unitQuery}`)

				this.units = R.sortWith([
					R.ascend(R.prop('program_id')),
					R.ascend(R.prop('unit')),
				])(res.data)

				this.isLoading = false
			} catch (err) {
				this.$emit('getError', err)
			}
		},

		async selectUnit() {
			try {
				this.isChanging = true
				if (this.lesson.moment <= moment()) {
					this.rejectChange('alreadyPassed')
					return
				}

				const query = this.isBook === true ? '?is-book=true' : ''
				await Api.patch(`lessons/${this.lesson.id}${query}`, {
					programId: this.selectedUnit.program_id,
					programUnitId: this.selectedUnit.id,
				})

				this.$router.push({ name: 'LessonProgramLanding' })
			} catch (err) {
				const errCode = err.response.data.code
				const errMsg = err.response.data.message
				if (errCode === '40004') {
					this.rejectChange('Status Not Match')
					return
				}
				if (errCode === '40005') {
					this.rejectChange('Already Passed')
					return
				}

				const alertMsg = this.$t(
					`에러가 발생했습니다.\n\nERROR CODE: %{errCode}\nERROR MESSAGE: %{errMsg}`,
					{ errCode, errMsg },
				)
				alert(alertMsg)

				this.isChanging = false
			}
		},
		rejectChange(x) {
			let msg
			switch (x) {
				case 'No Lesson':
					msg = this.$t('잘못된 수업 번호입니다.')
					break
				case 'Already Passed':
					msg = this.$t('프로그램 변경은 수업 시작 10분전까지 가능합니다.')
					break
				case 'Status Not Match':
					msg = this.$t(
						'수업이 이미 취소되었거나 업로드가 완료되었습니다. 수업의 상태를 확인해주세요.',
					)
					break
				default:
					msg = this.$t(
						'알 수 없는 에러가 발생했습니다.\n고객센터로 문의주세요.',
					)
			}
			alert(msg)
			this.$router.push({ name: 'Home' })
		},

		title(unitTitle) {
			const titles = unitTitle ? JSON.parse(unitTitle) : { ko: [], ja: [] }
			return titles[this.language]
		},
	},
}
</script>

<style lang="scss" scoped>
.unit-box {
	border: 1px solid #f2f2f2;
	box-sizing: border-box;
	border-radius: 10px;
}
.unit-box.active {
	border-color: #1e78ff;
}

.status-chip {
	padding: 2px 6px;
}
</style>
