<template>
	<Wrap />
</template>

<script>
import Wrap from '@/components/plan/Wrap'

export default {
	components: {
		Wrap,
	},
	created() {
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-3', {
			root: true,
		})
	},
	beforeRouteLeave(to, from, next) {
		this.$store.commit('device/SET_BACKGROUND', 'white', {
			root: true,
		})
		next()
	},
}
</script>

<style lang="scss" scoped></style>
