import { DateTime } from 'luxon'
import store from '../store'

function codeToTime(timeCode) {
	let timeStr = timeCode.toString()
	let timeHour = timeStr.slice(0, -1)
	if (timeHour.length === 1) timeHour = `0${timeHour}`
	let lastNumber = timeStr.substring(timeStr.length - 1, timeStr.length)
	let timeMin
	if (lastNumber === '0') {
		timeMin = '00'
	} else if (lastNumber === '1') {
		timeMin = '00'
	} else if (lastNumber === '2') {
		timeMin = '20'
	} else if (lastNumber === '3') {
		timeMin = '40'
	} else if (lastNumber === '5') {
		timeMin = '30'
	}
	return timeHour + ':' + timeMin
}

function makeDatetime(date, timecode) {
	const timezone = store.getters['member/timezone'] ?? 'Asia/Seoul'
	const language = store.getters['member/language'] ?? 'ko'
	const isoFormat = `${date}T${codeToTime(timecode)}:00`
	return DateTime.fromISO(isoFormat, { zone: 'Asia/Seoul' })
		.setZone(timezone)
		.setLocale(language)
}

function timeToCode(dt, lessonType) {
	const hour = dt.tz('Asia/Seoul').format('H')
	const min = dt.tz('Asia/Seoul').format('mm')
	let last = '0'
	if (min === '00' && lessonType === 0) last = 0
	if (min === '30' && lessonType === 0) last = 5
	if (min === '00' && lessonType === 1) last = 1
	if (min === '20' && lessonType === 1) last = 2
	if (min === '40' && lessonType === 1) last = 3
	/* time = H:mm */

	return `${hour}${last}`
}
export { makeDatetime, timeToCode }
