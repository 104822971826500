<template>
	<div>
		<v-btn fab icon text small @click="sheet = true">
			<v-icon>mdi-tune-vertical-variant</v-icon>
		</v-btn>
		<v-bottom-sheet v-model="sheet" scrollable inset width="400">
			<v-sheet rounded="xl" class="pt-3" :height="sheetHeight">
				<v-row no-gutters align="center">
					<v-col cols="8" offset="2">
						<h1 class="text-body-1 my-3 text-center">
							{{ $t('맞춤형 수강증') }}
						</h1>
					</v-col>

					<v-col cols="2" class="text-center">
						<v-btn fab text small @click="sheet = false"
							><v-icon>mdi-window-close</v-icon></v-btn
						>
					</v-col>
				</v-row>
				<section
					class="px-5"
					style="
						overflow-y: auto;
						max-height: calc(var(--vh, 1vh) * 90 - 118px);
					"
				>
					<section class="my-5">
						<h2 class="text-body-1 font-weight-bold mb-2">
							{{ $t('선택한 수강권') }}
						</h2>
						<div class="button">
							<div class="text-body-2">
								{{ passName }}
							</div>
							<div class="text-caption grey--text text--darken-1">
								{{ $t('유효기간') }}:
								{{ availableMoments[0].format('YYYY. MM. DD.') }} ~
								{{ availableMoments[1].format('YYYY. MM. DD.') }}
							</div>
						</div>
					</section>
					<section class="my-5">
						<h2 class="text-body-1 font-weight-bold mb-2">
							{{ $t('수강 기간') }}
						</h2>
						<v-row no-gutters class="mb-3">
							<v-col cols="6" class="pr-1">
								<div
									class="button text-body-2"
									:class="{ active: includeTerm === true }"
									@click="includeTerm = true"
								>
									수강기간 포함
								</div>
							</v-col>
							<v-col cols="6" class="pl-1">
								<div
									class="button text-body-2"
									:class="{ active: includeTerm === false }"
									@click="includeTerm = false"
								>
									{{ $t('수강기간 미포함') }}
								</div>
							</v-col>
						</v-row>
						<div class="d-flex align-center">
							<v-menu
								v-model="menu1"
								:disabled="includeTerm === false"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										hide-details
										dense
										outlined
										:height="42"
										:disabled="includeTerm === false"
										v-model="startDate"
										append-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									no-title
									v-model="startDate"
									:allowed-dates="allowedDates"
									@input="menu1 = false"
								></v-date-picker>
							</v-menu>
							<div class="px-2">~</div>
							<v-menu
								v-model="menu2"
								:disabled="includeTerm === false"
								:close-on-content-click="false"
								:nudge-right="40"
								transition="scale-transition"
								offset-y
								left
								min-width="auto"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										hide-details
										dense
										outlined
										:height="42"
										v-model="endDate"
										:disabled="includeTerm === false"
										append-icon="mdi-calendar"
										readonly
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-date-picker
									no-title
									v-model="endDate"
									:allowed-dates="allowedDates"
									@input="menu2 = false"
								></v-date-picker>
							</v-menu>
						</div>
					</section>
					<section class="my-5">
						<h2 class="mb-2 text-body-1 font-weight-bold">{{ $t('언어') }}</h2>
						<v-row no-gutters class="mb-3">
							<v-col cols="6" class="pr-1">
								<div
									class="button text-body-2"
									:class="{ active: certificateLanguage === 'kr' }"
									@click="certificateLanguage = 'kr'"
								>
									{{ $t('한글 증명서') }}
								</div>
							</v-col>
							<v-col cols="6" class="pl-1">
								<div
									class="button text-body-2"
									:class="{ active: certificateLanguage === 'en' }"
									@click="certificateLanguage = 'en'"
								>
									{{ $t('영문 증명서') }}
								</div>
							</v-col>
						</v-row>
					</section>
					<section class="my-5" v-if="payment">
						<h2 class="text-body-1 font-weight-bold mb-2">
							{{ $t('결제 금액') }}
						</h2>
						<v-row no-gutters class="mb-3">
							<v-col cols="6" class="pr-1">
								<div
									class="button text-body-2"
									:class="{ active: includePrice === true }"
									@click="includePrice = true"
								>
									{{ $t('포함') }}
								</div>
							</v-col>
							<v-col cols="6" class="pl-1">
								<div
									class="button text-body-2"
									:class="{ active: includePrice === false }"
									@click="includePrice = false"
								>
									{{ $t('미포함') }}
								</div>
							</v-col>
						</v-row>
					</section>
				</section>
				<section class="fixed-button-wrap">
					<v-btn
						@click="issueCertificate"
						tile
						height="56"
						block
						color="primary"
					>
						{{ $t('발급하기') }}</v-btn
					>
				</section>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
import moment from 'moment-timezone'
export default {
	props: {
		payment: {
			required: true,
		},
		query: {
			default: {},
		},
		availableMoments: {
			type: Array,
			required: true,
		},
		passName: {
			required: true,
		},
	},
	data: () => ({
		sheet: false,
		menu1: false,
		startDate: null,
		menu2: false,
		endDate: null,
		selectedPoint: null,
		includeTerm: true,
		certificateLanguage: 'kr',
		includePrice: false,
	}),
	computed: {
		sheetHeight() {
			return this.payment ? '600px' : '520px'
		},
		yeardateformat() {
			return this.$store.getters['member/yeardateformat']
		},
		startMoment() {
			return moment.tz(this.availableMoments[0], 'Asia/Seoul')
		},
		expiryMoment() {
			const oneMonthLater = moment
				.tz(this.startDate, 'Asia/Seoul')
				.add(29, 'days')
			return this.availableMoments[1] >= oneMonthLater
				? oneMonthLater
				: this.availableMoments[1].clone()
		},
	},
	watch: {
		sheet(val) {
			if (val === true) this.readQuery()
		},
	},
	created() {
		this.readQuery()
		this.issueCertificate()
	},
	methods: {
		readQuery() {
			this.startDate = this.availableMoments[0].format('YYYY-MM-DD')
			const startDateOfQeury = this.query['start-date']
			if (
				startDateOfQeury &&
				startDateOfQeury > this.availableMoments[0].format('YYYY-MM-DD')
			) {
				this.startDate = startDateOfQeury
			}

			this.endDate = this.availableMoments[1].format('YYYY-MM-DD')
			const endDateOfQeury = this.query['end-date']
			if (
				endDateOfQeury &&
				endDateOfQeury < this.availableMoments[1].format('YYYY-MM-DD')
			) {
				this.end = endDateOfQeury
			}

			// 수강기간 포함 미포함 설정
			const includeTermQuery = this.query['include-term']
			this.includeTerm =
				includeTermQuery == true || includeTermQuery == false
					? includeTermQuery == true
					: true

			// 한글/영문 선택
			const certLangQuery = this.query['certificate-language']
			this.certificateLanguage =
				certLangQuery === 'kr' || certLangQuery === 'en' ? certLangQuery : 'kr'

			// 결제금액 타입 선택
			const includePriceQuery = this.query['include-price']
			this.includePrice = this.payment
				? includePriceQuery == true || includePriceQuery == false
					? includePriceQuery == true
					: true
				: false
		},
		allowedDates(val) {
			return (
				val >= this.availableMoments[0].format('YYYY-MM-DD') &&
				val <= this.availableMoments[1].format('YYYY-MM-DD')
			)
		},
		issueCertificate() {
			this.sheet = false
			this.$emit('issueCertificate', {
				startDate: this.startDate,
				endDate: this.endDate,
				includeTerm: this.includeTerm,
				certificateLanguage: this.certificateLanguage,
				includePrice: this.includePrice,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.button {
	background: #ffffff;
	border: 0.8px solid #e7e7e7;
	box-sizing: border-box;
	border-radius: 10px;
	text-align: center;
	padding: 12px 0px;
}
.button.active {
	font-family: Noto Sans;
	font-weight: bold;
	color: #1e78ff;
	background: rgba(30, 120, 255, 0.05);
	border: 0.8px solid #1e78ff;
}

.selection,
.select-item {
	width: 100%;
}
.selection {
	padding: 5px 0;
}
.select-item {
	padding: 10px 0;
	border-bottom: 1px solid #e5e5e5;
}
.select-item:last-child {
	border-bottom: unset;
}
</style>
