var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMaximumDiscountCouponSelected),expression:"isMaximumDiscountCouponSelected"}],staticClass:"text-right text-body-2 primary--text narrow-line-height",staticStyle:{"position":"absolute","top":"0","right":"0"}},[_vm._v(" "+_vm._s(_vm.$t('order.coupon-picer.text.in-maximum-discount'))+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-1",attrs:{"src":require("@/assets/icons/L.png"),"alt":""}}),_c('h1',{staticClass:"text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('order.coupon-picer.title.coupon-discount'))+" ")]),_c('img',{staticClass:"ml-1 chevron",class:{ show: _vm.show },attrs:{"src":require("@/assets/icons/chevron-down.png"),"alt":""},on:{"click":function($event){_vm.show = !_vm.show}}}),_c('v-spacer'),_c('p',{staticClass:"text-right text-body-1 grey--text text--darken-1 price"},[_vm._v(" "+_vm._s(_vm.$numberWithCommas(_vm.discountCouponAmount))+" "+_vm._s(_vm.currencyString)+" ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"pt-2"},[_c('v-select',{staticClass:"text-body-1 font-weight-medium",attrs:{"outlined":"","single-line":"","hide-details":"","items":_vm.available,"item-value":"id","item-text":"text","no-data-text":_vm.$t('order.coupon-picker.select.no-coupon-available'),"placeholder":_vm.available.length === 0
					? _vm.$t('order.coupon-picker.select.no-coupon-available')
					: '',"return-object":"","menu-props":{ contentClass: 'order-price-picker-item' }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
					var item = ref.item;
					var attrs = ref.attrs;
					var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',{staticClass:"text-body-1 font-weight-medium grey--text align-center",class:{
									'grey--text font-weight-medium': item.disabled === true,
									'primary--text':
										item.disabled === false &&
										_vm.selectedCoupon &&
										item.id === _vm.selectedCoupon.id,
									'text--darken-3':
										(item.disabled === false && !_vm.selectedCoupon) ||
										(item.disabled === false &&
											item.id !== _vm.selectedCoupon.id),
								},staticStyle:{"width":"100%","line-height":"1.5em !important"}},[_c('span',{staticClass:"coupon-name mr-1"},[_vm._v(" "+_vm._s(item.name)+" ")]),_vm._v(" "+_vm._s(item.discount)+_vm._s(item.discountType)+" "),(_vm.selectedCoupon && item.id === _vm.selectedCoupon.id)?_c('img',{staticClass:"ml-1",attrs:{"src":require("@/assets/icons/check-primary.png"),"alt":""}}):_vm._e(),(item.minOrderAmount && item.minOrderAmount > 0)?_c('span',{staticClass:"text-body-2 grey--text",class:{ 'text--darken-3': item.disabled === false }},[_c('br'),_vm._v("( "+_vm._s(_vm.$t('order.couponPicker.useCondition', { priceLimit: ("" + (_vm.$numberWithCommas( item.minOrderAmount )) + _vm.currencyString), }))+")")]):_vm._e()])]),_c('v-list-item-subtitle',[_c('p',{staticClass:"text-body-2 grey--text texx--darken-1",domProps:{"innerHTML":_vm._s(
									_vm.$t(
										'order.coupon-picer.text.coupon-remaining-days',

										{
											days: item.days,
											date: item.expirationDate,
										}
									)
								)}})])],1),(
							_vm.maximumDiscountCouponId && _vm.maximumDiscountCouponId === item.id
						)?_c('v-list-item-action',[_c('div',{staticClass:"maximum-discount text-body-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('order.coupon-picer.text.maximum-discount'))+" ")])]):_vm._e()],1)]}}]),model:{value:(_vm.selectedCoupon),callback:function ($$v) {_vm.selectedCoupon=$$v},expression:"selectedCoupon"}}),_c('CouponRegister',{staticClass:"mt-1",on:{"registeredCoupon":function($event){return _vm.$emit('registeredCoupon')}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }