<template>
	<div>
		<section class="fixed-bar-wrap">
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">
						{{
							program.lesson_type === 0
								? `💬&nbsp;&nbsp;${$t('25분톡')}`
								: `📞&nbsp;&nbsp;${$t('15분콜')}`
						}}
						|
						{{ program.name }}
					</h1>
				</v-col>
				<v-col cols="2">
					<TranslateButton
						v-if="notShowTransProgramIds.includes(this.programId) === false"
						@clickTranslateBtn="translate = !translate"
						:translate="translate"
					/>
				</v-col>
			</v-row>
		</section>
		<section class="px-5 pb-3" style="margin-top: 60px">
			<div class="d-flex align-start">
				<v-img
					max-width="50"
					class="rounded"
					:src="`${baseUrl}/v1/program/thumbnail/portrait/${language}/${unit.program_id}.jpg`"
				>
				</v-img>
				<div class="pl-3 pl-sm-5 text-left">
					<div class="text-body-1 font-weight-medium">
						{{ unit.unit }}. {{ title }}
					</div>
					<div class="text-body-2 text-sm-body-1">{{ learningObjective }}</div>
				</div>
			</div>
		</section>
		<div class="pa-3" v-if="isLoading === false">
			<div class="px-3">
				<p class="text-body-1 font-weight-bold mb-2">{{ $t('표현카드') }}</p>
				<v-img
					:src="expressCardUrl"
					alt
					style="border-radius: 10px !important; width: 100%; max-width: 350px"
				></v-img>
			</div>
			<Guide
				:guide="guide"
				:translate="translate"
				:programUnitId="unitId"
				v-if="isLoading === false"
			/>
		</div>
	</div>
</template>

<script>
/**
 * 유닛정보와 가이드를 불러온다.
 */
import Api from '@/services/index'
import Guide from './Guide'
import TranslateButton from './TranslateButton'
const R = require('ramda')

export default {
	components: {
		TranslateButton,
		Guide,
	},
	data: () => ({
		isLoading: false,
		translate: false,
		unit: {},
		baseUrl: 'https://image.tella.co.kr',
	}),
	computed: {
		notShowTransProgramIds() {
			return [33, 153, 120, 167]
		},
		unitId() {
			return parseInt(this.$route.params.unitId)
		},
		programId() {
			return parseInt(this.$route.params.programId)
		},
		program() {
			return R.find(
				R.propEq('id', this.programId),
				this.$store.getters['program/programs'],
			)
		},
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		language() {
			return this.$store.getters['member/language']
		},
		expressCardUrl() {
			// return this.language === 'ja'
			// 	? `https://image.tella.co.kr/v1/express-card/${this.unitId}.jpg`
			// 	: `https://image.tella.co.kr/v2/word-card/${
			// 			this.unit.origin_talk_program_id
			// 				? this.unit.origin_talk_program_id
			// 				: this.unit.program_id
			// 	  }_${this.unit.unit}.jpg`
			return `https://image.tella.co.kr/v1/express-card/${this.language}/${this.unitId}.jpg`
		},
		title() {
			const titles =
				this.unit && this.unit.unit_title
					? JSON.parse(this.unit.unit_title)
					: { ko: [], ja: [] }
			return titles[this.language]
		},
		guide() {
			const guides =
				this.unit && this.unit.guide
					? JSON.parse(this.unit.guide)
					: { ko: [], ja: [] }
			return guides[this.language].sort((a, b) => a.sequence - b.sequence)
		},

		learningObjective() {
			const learningObjectives =
				this.unit && this.unit.learning_objective
					? JSON.parse(this.unit.learning_objective)
					: { ko: [], ja: [] }
			return learningObjectives[this.language]
		},
	},
	created() {
		if (isNaN(parseInt(this.unitId)) === true) {
			alert(this.$t('프로그램 유닛 정보를 불러올 수 없습니다.'))
			this.$router.go(-1)
		}
		this.translate =
			this.notShowTransProgramIds.includes(this.programId) === true
				? false
				: true
		this.get()
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			if (!beforeName) {
				this.$router.push({ name: 'ProgramUnit' })
				return
			}
			if (beforeName === 'LessonProgramLanding') {
				this.$router.push({ name: 'Home' })
				return
			}
			this.$router.go(-1)
		},
		async get() {
			this.isLoading = true
			const res = await Api.get(
				`programs/units?id=${this.unitId}&language=${this.language}`,
			)
			this.unit = res.data[0]
			if (this.unit.group_id !== 1) {
				// 그룹이 설정된 프로그램인데
				const groupIds = this.$store.getters['group/groups']
				if (groupIds.includes(this.unit.group_id) === false) {
					// 그룹원으로 선택이 안되어 있다.
					alert(
						this.$t(
							'해당 프로그램은 스페셜 패키지로 판매된 프로그램으로 해당 수강권을 구매하신 학습자님들에게만 제공되는 컨텐츠입니다.',
						),
					)
					this.$router.push({ name: 'Programs' })
					return
				}
			}
			this.isLoading = false
		},
	},
}
</script>

<style scoped>
.wrap {
	padding: 15px 12px;
	box-shadow: 0px 3px 20px -5px rgba(0, 0, 0, 0.1) !important;
	background-color: #fff;
}
</style>
