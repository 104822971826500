<template>
	<div style="position: relative">
		<p
			style="position: absolute; top: 0; right: 0"
			v-show="isMaximumDiscountCouponSelected"
			class="text-right text-body-2 primary--text narrow-line-height"
		>
			{{ $t('order.coupon-picer.text.in-maximum-discount') }}
		</p>
		<div class="d-flex align-center">
			<img class="mr-1" src="@/assets/icons/L.png" alt="" />
			<h1 class="text-body-1">
				{{ $t('order.coupon-picer.title.coupon-discount') }}
			</h1>
			<img
				@click="show = !show"
				:class="{ show }"
				class="ml-1 chevron"
				src="@/assets/icons/chevron-down.png"
				alt=""
			/>
			<v-spacer></v-spacer>

			<p class="text-right text-body-1 grey--text text--darken-1 price">
				{{ $numberWithCommas(discountCouponAmount) }} {{ currencyString }}
			</p>
		</div>
		<div class="pt-2" v-show="show">
			<v-select
				v-model="selectedCoupon"
				outlined
				single-line
				hide-details
				:items="available"
				item-value="id"
				item-text="text"
				:no-data-text="$t('order.coupon-picker.select.no-coupon-available')"
				:placeholder="
					available.length === 0
						? $t('order.coupon-picker.select.no-coupon-available')
						: ''
				"
				return-object
				class="text-body-1 font-weight-medium"
				:menu-props="{ contentClass: 'order-price-picker-item' }"
			>
				<template v-slot:item="{ item, attrs, on }">
					<v-list-item v-on="on" v-bind="attrs">
						<v-list-item-content>
							<v-list-item-title>
								<div
									style="width: 100%; line-height: 1.5em !important"
									class="text-body-1 font-weight-medium grey--text align-center"
									:class="{
										'grey--text font-weight-medium': item.disabled === true,
										'primary--text':
											item.disabled === false &&
											selectedCoupon &&
											item.id === selectedCoupon.id,
										'text--darken-3':
											(item.disabled === false && !selectedCoupon) ||
											(item.disabled === false &&
												item.id !== selectedCoupon.id),
									}"
								>
									<span class="coupon-name mr-1">
										{{ item.name }}
									</span>
									{{ item.discount }}{{ item.discountType }}

									<img
										class="ml-1"
										src="@/assets/icons/check-primary.png"
										alt=""
										v-if="selectedCoupon && item.id === selectedCoupon.id"
									/>
									<span
										class="text-body-2 grey--text"
										:class="{ 'text--darken-3': item.disabled === false }"
										v-if="item.minOrderAmount && item.minOrderAmount > 0"
										><br />(
										{{
											$t('order.couponPicker.useCondition', {
												priceLimit: `${$numberWithCommas(
													item.minOrderAmount,
												)}${currencyString}`,
											})
										}})</span
									>
								</div>
							</v-list-item-title>

							<v-list-item-subtitle>
								<p
									class="text-body-2 grey--text texx--darken-1"
									v-html="
										$t(
											'order.coupon-picer.text.coupon-remaining-days',

											{
												days: item.days,
												date: item.expirationDate,
											},
										)
									"
								></p>
							</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action
							v-if="
								maximumDiscountCouponId && maximumDiscountCouponId === item.id
							"
						>
							<div class="maximum-discount text-body-2 font-weight-bold">
								{{ $t('order.coupon-picer.text.maximum-discount') }}
							</div>
						</v-list-item-action>
					</v-list-item>
				</template>
			</v-select>
			<CouponRegister
				class="mt-1"
				@registeredCoupon="$emit('registeredCoupon')"
			/>
		</div>
	</div>
</template>

<script>
import CouponRegister from './CouponRegister'
import moment from 'moment-timezone'
const R = require('ramda')

export default {
	components: {
		CouponRegister,
	},
	props: {
		coupons: {
			required: true,
			type: Array,
		},
		realPrice: {
			required: true,
			type: Number,
		},
		planId: {
			required: true,
			type: Number,
		},
		discountCouponAmount: {
			required: true,
			type: Number,
		},
		currencyString: {
			required: true,
			type: String,
		},
		currency: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			show: true,
			selectedCoupon: null,
		}
	},
	computed: {
		available() {
			return this.coupons
				.filter(
					c =>
						c.used_date === null &&
						c.active === 1 &&
						c.expiration_date >= moment.tz('Asia/Seoul').format('YYYY-MM-DD'),
				)
				.map(c => {
					const minOrderAmount =
						c.min_order_amount === null
							? 0
							: JSON.parse(c.min_order_amount)[this.currency]

					const isApplicablePlan =
						!c.applicable_plans ||
						(c.applicable_plans &&
							c.applicable_plans.split(',').includes(this.planId.toString()))
					return {
						id: c.id,
						couponId: c.coupon_id,
						name: c.coupon_type,
						text: `${c.coupon_type} ${c.discount}${c.discount_type}`,
						issuedDate: c.issued_date,
						expirationDate: c.expiration_date,
						days: moment(c.expiration_date).diff(
							moment().tz('Asia/Seoul'),
							'days',
						),
						minOrderAmount,
						applicablePlans: c.applicable_plans,
						disabled:
							this.realPrice < minOrderAmount || isApplicablePlan === false,
						// info: `${}일 남음`,
						discount: c.discount,
						discountType: c.discount_type,
						discountAmount:
							c.discount_type === '%'
								? (this.realPrice * c.discount) / 100
								: c.discount,
					}
				})
				.sort((a, b) => {
					return a.disabled - b.disabled
				})
		},

		maximumDiscountCouponId() {
			const filtered = this.available.filter(c => c.disabled === false)
			const sorted = R.sortWith([R.descend(R.prop('discountAmount'))])(filtered)
			return sorted.length > 0 ? sorted[0].id : undefined
		},
		isMaximumDiscountCouponSelected() {
			return (
				this.maximumDiscountCouponId !== null &&
				this.maximumDiscountCouponId !== undefined &&
				this.maximumDiscountCouponId === this.selectedCoupon.id
			)
		},
	},
	watch: {
		selectedCoupon: {
			// This will let Vue know to look inside the array
			deep: true,

			// We have to move our method to a handler field
			handler(val) {
				this.selectCoupon(val)
			},
		},
	},
	created() {
		this.selectMaximumDiscountCoupon()
	},
	methods: {
		selectMaximumDiscountCoupon() {
			this.selectedCoupon = this.available.find(
				c => c.id === this.maximumDiscountCouponId && c.disabled === false,
			)

			// this.selectedCoupon = R.find(
			// 	R.propEq('id', this.maximumDiscountCouponId),
			// 	R.propEq('disabled', false),
			// )(this.available)
		},
		selectCoupon(val) {
			this.$emit('selectCoupon', val)
		},
	},
}
</script>

<style lang="scss">
.order-price-picker-item {
	.theme--light.v-list-item--active::before {
		background-color: unset;
	}
	.maximum-discount {
		padding: 10px 10px 10px 11px;
		color: #0074ff;
		border-radius: 10px;
		background-color: rgba(0, 115, 255, 0.08);
	}
}
</style>

<style lang="scss" scoped>
.chevron {
	transition: transform 0.2s ease-in-out;
}
.chevron.show {
	transform: rotate(180deg);
}
.coupon-name {
	overflow: hidden;
	max-width: 175px;
	text-overflow: ellipsis;
}
</style>
