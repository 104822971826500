<template>
	<div class="correction-wrap">
		<div class="mb-1">
			<span v-html="beforeDiff(beforeContent, afterContent)"></span>
		</div>
		<img
			src="@/assets/images/lesson/lesson-arrow.png"
			style="width: 10px; margin-right: 3px"
			alt=""
		/>

		<span v-for="(word, i) in corrected" :key="`${afterContent}-${i}`">
			<span v-if="word.added === true">
				<SelfTestBlank
					class="d-inline-block"
					:word="word"
					:style="
						corrected[i + 1] &&
						corrected[i + 1].value !== ',' &&
						corrected[i + 1].value !== '.'
							? 'padding-right: 3px'
							: ''
					"
				/>
			</span>
			<span
				class="d-inline-block"
				v-else
				:style="
					corrected[i + 1] &&
					corrected[i + 1].value !== ',' &&
					corrected[i + 1].value !== '.'
						? 'padding-right: 3px'
						: ''
				"
				>{{ word.value }}</span
			>
		</span>
	</div>
</template>

<script>
import SelfTestBlank from './SelfTestBlank'
const jsdiff = require('diff')

export default {
	components: {
		SelfTestBlank,
	},
	props: {
		beforeContent: {
			required: true,
			type: String,
		},
		afterContent: {
			required: true,
			type: String,
		},
	},
	computed: {
		corrected() {
			let diff = jsdiff.diffWords(this.beforeContent, this.afterContent)
			const filtered = diff.filter(d => !d.removed)
			let words = []
			filtered.forEach(w => {
				const word = w.value.split(' ')
				word.forEach(x => {
					words.push({ value: x, added: w.added })
				})
			})
			return words
		},
	},
	methods: {
		beforeDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.removed) {
					span === null
						? (span = `<span class='font-weight-bold before'>${part.value}</span>`)
						: (span += `<span class='font-weight-bold before'>${part.value}</span>`)
				} else if (!part.added) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
		afterDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.added) {
					const count = part.value.length
					let blank = ''
					for (let i = 0; i < count; i++) {
						blank += '_'
					}
					span === null
						? (span = `<span class='font-weight-bold after'>${blank}</span>`)
						: (span += `<span class='font-weight-bold after'>${blank}</span>`)
				} else if (!part.removed) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
	},
}
</script>

<style lang="scss">
.correction-wrap {
	.after {
		font-style: italic;
		color: #00cba0;
	}
	.before {
		color: #ff6060;
	}
}
</style>
