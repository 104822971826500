<template>
	<v-dialog
		:persistent="persistent"
		v-model="dialog"
		width="310"
		content-class="rounded-lg"
	>
		<v-card rounded="lg" class="py-3 px-4">
			<div class="pt-2 pb-3">
				<h1 class="modal-h1 font-weight-bold">
					{{ title }}
				</h1>
			</div>
			<div class="py-2" v-if="text">
				<p class="modal-text">
					{{ text }}
				</p>
			</div>
			<v-row no-gutters class="py-3" v-if="numberOfButton === 1">
				<v-btn :color="actionColor" depressed block @click="action">
					{{ actionText }}
				</v-btn>
			</v-row>
			<v-row no-gutters class="py-3" v-if="numberOfButton === 2">
				<v-col cols="6">
					<v-btn
						:color="secondActionColor"
						depressed
						block
						@click="secondAction"
					>
						{{ secondActionText }}
					</v-btn>
				</v-col>
				<v-col cols="6">
					<v-btn :color="actionColor" depressed block @click="action">
						{{ actionText }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		dialogControl: { required: true, type: Boolean },
		persistent: { type: Boolean, default: false },
		numberOfButton: { required: true, type: Number },
		title: { required: true, type: String },
		text: { type: String },
		actionColor: { required: true, type: String },
		action: { required: true, type: Function },
		actionText: { required: true, type: String },
		secondActionColor: String,
		secondAction: Function,
		secondActionText: String,
	},
	data() {
		return {
			dialog: false,
		}
	},
	watch: {
		dialogControl() {
			this.dialog = !this.dialog
		},
	},
}
</script>

<style lang="scss" scoped></style>
