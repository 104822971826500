<template>
	<div class="box">
		<h1 class="font-13 font-weight-bold primary--text">변경할 프로그램</h1>
		<h2
			v-if="!selectedProgramUnitId"
			class="
				py-3
				text-center
				font-13 font-weight-medium
				grey--text
				text--darken-1
			"
		>
			변경할 프로그램을 선택하세요.
		</h2>
		<div v-else>
			<h2 class="font-13 font-weight-medium grey--text text--darken-3">
				{{ unit.programName }}
			</h2>

			<p class="font-13 font-weight-regular grey--text text--darken-3">
				unit {{ unit.unit }}. {{ title }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		selectedProgramUnitId: {
			required: true,
		},
		unit: {
			required: true,
		},
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		title() {
			return JSON.parse(this.unit.unitTitle)[this.language]
		},
	},
}
</script>

<style lang="scss" scoped>
.box {
	border: 1px solid #eeeeee;
	border-radius: 4px;
	padding: 12px 15px;
}
</style>
