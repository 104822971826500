var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading === false)?_c('div',[_c('section',[_c('v-row',{staticClass:"white py-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"fab":"","icon":"","text":"","small":""},on:{"click":function($event){return _vm.goBack()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('h1',{staticClass:"text-center text-body-1"},[_vm._v(_vm._s(_vm.$t('나의 수강권')))])])],1)],1),_c('section',{staticClass:"grey lighten-5 pa-5"},[_c('div',{staticClass:"white--text d-inline-block rounded-pill text-body-2 px-3",class:{
				grey: _vm.status !== _vm.$t('수강중'),
				primary: _vm.status === _vm.$t('수강중'),
			},staticStyle:{"padding-top":"2px","padding-bottom":"2px"}},[_vm._v(" "+_vm._s(_vm.status)+" ")]),_c('div',{staticClass:"pa-3"},[_c('h2',{staticClass:"text-h6 font-weight-bold mb-5"},[_vm._v(" "+_vm._s(_vm.name)+" ")]),_vm._l((_vm.points),function(point){return _c('div',{key:point.id,staticClass:"py-3"},[_c('div',{staticClass:"mb-1 text-body-2 font-weight-medium",domProps:{"innerHTML":_vm._s(_vm.pointTitle(point))}}),_c('div',{staticClass:"d-flex align-center"},[_c('p',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t('잔여 %{reaminingPoints}개 / 총 %{assignedPoints}회', { reaminingPoints: point.remaining_points, assignedPoints: point.assigned_points, }))+" ")]),_c('v-spacer'),_c('p',{staticClass:"text-caption grey--text text--darken-1"},[_vm._v(" "+_vm._s(point.start_date)+"~"+_vm._s(point.expiry_date)+" ")])],1)])})],2)]),_c('section',{staticClass:"pa-5"},[_c('h2',{staticClass:"text-h6 mb-1 font-weight-medium"},[_vm._v(_vm._s(_vm.$t('상세 정보')))]),_c('div',{staticClass:"d-flex align-center py-4",staticStyle:{"border-bottom":"1px solid #e5e5e5"},on:{"click":function($event){
			var _obj;
return _vm.$router.push({
					name: 'PassHistory',
					query: ( _obj = {}, _obj['payment-id'] = _vm.paymentId, _obj['pass-coupon-member-id'] = _vm.passCouponMemberId, _obj['group-point-type-id'] = _vm.groupPointTypeId, _obj['point-id'] = _vm.pointId, _obj ),
				})}}},[_c('h3',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('예약 내역')))]),_c('v-spacer'),_c('v-icon',{staticClass:"grey--text text--lighten-2"},[_vm._v("mdi-chevron-right")])],1)]),(
			(_vm.paymentId || _vm.passCouponMemberId || _vm.groupPointTypeId) &&
			_vm.name !== _vm.$t('보너스 수강권')
		)?_c('section',{staticClass:"pa-5"},[_c('h2',{staticClass:"text-h6 mb-1 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('증명 서류 발급'))+" ")]),(_vm.paymentId || _vm.passCouponMemberId)?_c('div',{staticClass:"d-flex align-center py-4",staticStyle:{"border-bottom":"1px solid #e5e5e5"},on:{"click":function($event){return _vm.goTo('Certificate')}}},[_c('h3',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('수강증')))]),_c('v-spacer'),_c('v-icon',{staticClass:"grey--text text--lighten-2"},[_vm._v("mdi-chevron-right")])],1):_vm._e(),(_vm.paymentId)?_c('div',{staticClass:"d-flex align-center py-4",staticStyle:{"border-bottom":"1px solid #e5e5e5"},on:{"click":function($event){return _vm.goTo('Receipt')}}},[_c('h3',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('영수증')))]),_c('v-spacer'),_c('v-icon',{staticClass:"grey--text text--lighten-2"},[_vm._v("mdi-chevron-right")])],1):_vm._e(),_c('div',{staticClass:"d-flex align-center py-4",staticStyle:{"border-bottom":"1px solid #e5e5e5"},on:{"click":function($event){return _vm.goTo('Attendance')}}},[_c('h3',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('출석증')))]),_c('v-spacer'),_c('v-icon',{staticClass:"grey--text text--lighten-2"},[_vm._v("mdi-chevron-right")])],1)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }