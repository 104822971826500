var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"corrected-wrap pa-4",class:("correction-wrap-" + (_vm.correction.id))},[_c('p',{staticClass:"font-16 mb-2 grey--text text--darken-1 mb-2"},[_c('span',{domProps:{"innerHTML":_vm._s(
				_vm.beforeDiff(
					_vm.correction.tutee_chat_log_content,
					_vm.correction.tutor_chat_log_content
				)
			)}})]),_c('p',{staticClass:"mb-3 font-16 after-wrap"},[_c('img',{staticStyle:{"width":"12.85px","margin-right":"3px"},attrs:{"src":require("@/assets/images/lesson/lesson-arrow.png"),"alt":""}}),_vm._l((_vm.corrected),function(word,i){return _c('span',{key:((_vm.corrected.id) + "-" + i)},[(word.added === true)?_c('span',[_c('CorrectionBlank',{staticClass:"d-inline-block",style:(_vm.corrected[i + 1] &&
						_vm.corrected[i + 1].value !== ',' &&
						_vm.corrected[i + 1].value !== '.'
							? 'padding-right: 3px'
							: ''),attrs:{"word":word,"index":i,"correctionId":_vm.correction.id},on:{"showWord":_vm.showWord}})],1):_c('span',{staticClass:"d-inline-block",style:(_vm.corrected[i + 1] &&
					_vm.corrected[i + 1].value !== ',' &&
					_vm.corrected[i + 1].value !== '.'
						? 'padding-right: 3px'
						: '')},[_vm._v(_vm._s(word.value))])])})],2),_c('v-divider',{staticClass:"mb-1"}),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('div',{staticClass:"mr-3 text-center",staticStyle:{"width":"20px"}},[(_vm.isShowAll === false)?_c('i',{staticClass:"fa-solid fa-eye-slash",staticStyle:{"color":"#404a59"},on:{"click":function($event){return _vm.showAll(true)}}}):_c('i',{staticClass:"fa-solid fa-eye",staticStyle:{"color":"#404a59"},on:{"click":function($event){return _vm.showAll(false)}}})]),_c('AudioPlayer',{staticClass:"d-inline-block ml-1",attrs:{"script":_vm.correction.tutor_chat_log_content,"color":'primary--text',"text":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }