<template>
	<v-container style="max-width: 375px">
		<section class="pt-5 pb-10">
			<a href="https://tella.co.kr" @click="gtagEvent()">
				<img
					src="@/assets/images/logo/default.png"
					style="width: 50px"
					:alt="$t('img.alt.텔라 홈페이지')"
					class="mb-4"
				/>
			</a>
			<h1 class="text-h2 font-weight-bold white-space-pre mb-5">
				1:1 원어민 채팅 영어회화<br />
				지금 무료수업 신청하세요!
			</h1>
			<div class="benefit-box">
				<h2 class="text-body-1 px-3 pt-3 pb-2">
					100% 드리는
					<span class="font-weight-bold primary--text">무료수업 패키지</span>
				</h2>
				<v-divider></v-divider>
				<div class="px-3 py-3">
					<h3 class="font-weight-regular text-body-2 mb-2">
						<span class="benefit-title">혜택1</span> 영구 소장 가능한
						<strong class="font-weight-bold">오늘의 표현 카드</strong>
					</h3>
					<h3 class="font-weight-regular text-body-2 mb-2">
						<span class="benefit-title">혜택2</span> 내가 쓴 모든 문장
						<strong class="font-weight-bold"
							>무료 첨삭 & 원어민 표현 추천</strong
						>
					</h3>
					<h3 class="font-weight-regular text-body-2">
						<span class="benefit-title">혜택3</span> 취약점을 강점으로 바꾸는
						<strong class="font-weight-bold">1:1 정밀 진단 리포트</strong>
					</h3>
				</div>
			</div>
		</section>
		<v-slide-y-transition v-if="selectedMoment === null">
			<TimePicker
				:timezone="timezone"
				style="max-width: 450px"
				@selectMoment="selectMoment"
				@selectTimezone="selectTimezone"
			/>
		</v-slide-y-transition>
		<section>
			<TrialForm
				:originPhone="phone"
				:originCountryCode="countryCode"
				:originName="name"
				:originTimezone="timezone"
				:selectedMoment="selectedMoment"
				:timezone="timezone"
				@selectMoment="selectMoment"
				@selectTimezone="selectTimezone"
			/>
		</section>
	</v-container>
</template>

<script>
import { initializeApp } from 'firebase/app'
import { getRemoteConfig } from 'firebase/remote-config'
import { getValue } from 'firebase/remote-config'
import { fetchAndActivate } from 'firebase/remote-config'
import { getAnalytics } from 'firebase/analytics'

import moment from 'moment-timezone'
import TimePicker from '../common/TimePicker'
import TrialForm from './Form'

export default {
	components: {
		TimePicker,
		TrialForm,
	},
	data() {
		return {
			h1: this.$t('title.book-trial'),
			selectedMoment: null,
			timezone: null,
		}
	},
	computed: {
		phone() {
			return this.$route.query.phone || ''
		},
		name() {
			return this.$route.query.phone || ''
		},
		countryCode() {
			return this.$route.query['country-code'] || ''
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
	},
	created() {
		console.log('dd')
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-3', {
			root: true,
		})
		if (this.$store.getters['member/nationality'] === 'jp')
			this.$router.push({
				name: 'TrialBookJP',
			})
		this.guessTimezone()
		this.setRemoteConfig()
	},
	beforeRouteLeave() {
		this.$store.commit('device/SET_BACKGROUND', null, {
			root: true,
		})
	},
	methods: {
		async setRemoteConfig() {
			// Your web app's Firebase configuration
			const firebaseConfig = {
				apiKey: 'AIzaSyA8kE3DnlXEVLyhxEJUV5SoJxAAYGK5SXc',
				authDomain: 'tella-for-client.firebaseapp.com',
				databaseURL: 'https://tella-for-client.firebaseio.com',
				projectId: 'tella-for-client',
				storageBucket: 'tella-for-client.appspot.com',
				messagingSenderId: '347012322857',
				appId: '1:347012322857:web:39c9c513eb893a7753ae38',
				measurementId: 'G-HTM9X25Z4B',
			}

			// Initialize Firebase
			const app = initializeApp(firebaseConfig)
			const analytics = getAnalytics(app)
			console.log(analytics)
			const remoteConfig = getRemoteConfig(app)
			remoteConfig.settings.minimumFetchIntervalMillis = 3600000
			remoteConfig.defaultConfig = {
				welcome_message: 'Welcome',
			}

			let val = getValue(remoteConfig, 'first_ab_test')
			console.log(val)

			await fetchAndActivate(remoteConfig)
			val = getValue(remoteConfig, 'first_ab_test')
			console.log(val)
		},
		gtagEvent() {
			// eslint-disable-next-line no-undef
			gtag('event', 'form_to_home')
		},
		selectMoment(m) {
			this.selectedMoment = m
		},
		guessTimezone() {
			if (this.memberId) {
				this.timezone = this.$store.getters['member/timezone']
				return
			}

			const storageTimezone = localStorage.getItem('_timezone')
			if (storageTimezone) {
				this.timezone = localStorage.getItem('_timezone')
				return
			}

			this.timezone = moment.tz.guess()
		},
		selectTimezone(timezone) {
			this.timezone = timezone
		},
	},
}
</script>

<style lang="scss" scoped>
.benefit-box {
	background: #ffffff;
	border: 1px solid #0074ff;
	border-radius: 10px;
}
.benefit-title {
	background: #f2f9ff;
	border-radius: 5px;
	padding: 5px 7px;
	color: #0074ff;
	margin-right: 3px;
}
</style>
