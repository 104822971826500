<template>
	<div id="scroll-target">
		<v-expand-transition>
			<div
				v-show="appToobar"
				style="
					background-color: #fff;
					border-bottom: 1px solid #dadada;
					z-index: 10;
					position: fixed;
					top: 0;
					width: 100%;
				"
			>
				<v-row no-gutters class="desktop-width-limit">
					<v-col cols="4" class="text-center">
						<div
							class="d-inline-block py-4 tab-btn hover-pointer"
							@click="moveTo(0)"
							:class="{ active: offsetTop <= 2600 }"
						>
							<p>내 레벨평가</p>
						</div>
					</v-col>
					<v-col cols="4" class="text-center">
						<div
							class="d-inline-block py-4 tab-btn hover-pointer"
							@click="moveTo(1)"
							:class="{ active: (offsetTop > 2600) & (offsetTop <= 5150) }"
						>
							<p>데이터분석</p>
						</div>
					</v-col>
					<v-col cols="4" class="text-center">
						<div
							class="d-inline-block py-4 tab-btn hover-pointer"
							@click="moveTo(2)"
							:class="{ active: offsetTop >= 5150 }"
						>
							<p>프로그램 추천</p>
						</div>
					</v-col>
				</v-row>
			</div>
		</v-expand-transition>
		<v-container class="pa-0 fill-height black" fluid style="height: 350px">
			<v-row no-gutters>
				<v-col cols="12">
					<div class="white--text text-center text-sm-left desktop-width-limit">
						<h1 class="nls font-weight-bold" style="line-height: 1.3em">
							<span class="primary--text">{{ trial.name || '체험자' }}님!</span>
							<br />
							<span v-if="userDevice === 'desktop'" class="pr-2"
								>무료체험수업</span
							>레벨진단결과를 <br class="d-block d-sm-none" />확인하세요!
						</h1>
						<h2 class="my-5" style="line-height: 1.3em">
							리포트 하단의
							<br class="d-block d-sm-none" />
							<span
								class="emerald--text"
								style="border-bottom: 1px solid #00cca1"
								>특별할인 혜택</span
							>도 <br class="d-block d-sm-none" />놓치지 마세요!
						</h2>
						<h3>
							{{ trial.moment.format('YYYY.MM.DD(ddd) a h:mm') }}에 진행된
							<br class="d-block d-sm-none" />무료체험수업에 대한 리포트입니다.
						</h3>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<div class="white">
			<v-row no-gutters class="desktop-width-limit">
				<v-col cols="4" class="text-center">
					<div
						class="d-inline-block py-4 tab-btn hover-pointer active"
						@click="moveTo(0)"
					>
						<p>내 레벨평가</p>
					</div>
				</v-col>
				<v-col cols="4" class="text-center">
					<div
						class="d-inline-block py-4 tab-btn hover-pointer"
						@click="moveTo(1)"
					>
						<p>데이터분석</p>
					</div>
				</v-col>
				<v-col cols="4" class="text-center">
					<div
						class="d-inline-block py-4 tab-btn hover-pointer"
						@click="moveTo(2)"
					>
						<p>프로그램 추천</p>
					</div>
				</v-col>
			</v-row>
		</div>
		<v-divider class="my-0" id="first"></v-divider>
		<!--종합평가-->
		<v-row
			no-gutters
			align="center"
			justify="center"
			class="text-center py-100"
		>
			<v-col cols="12" sm="7">
				<h1 class="mb-10 font-weight-bold">종합평가</h1>
				<div class="d-inline-block">
					<div
						class="d-flex justify-center align-center py-3 px-5 mb-5"
						style="border: 2px solid #999; border-radius: 3px"
					>
						<div>
							<img
								src="https://d37egnlosshd9l.cloudfront.net/v2/courses/opic-grey.png"
								alt
								style="width: 40px"
							/>
							<p class="narrow-line-height mb-0 grey--text text--darken-3">
								{{ opicLevel }}
							</p>
						</div>
						<div class="px-5 pt-4">
							<div
								class="grey lighten-2 white--text"
								style="border-radius: 50%; width: 20px; height: 20px"
							>
								<v-icon small>mdi-equal</v-icon>
							</div>
						</div>
						<div>
							<img
								src="https://d37egnlosshd9l.cloudfront.net/v2/logo/blue.png"
								alt
								style="width: 60px"
							/>
							<p class="mt-1 strong mb-0 primary--text">Level {{ level }}</p>
						</div>
						<div class="px-5 pt-4">
							<div
								class="grey lighten-2 white--text"
								style="border-radius: 50%; width: 20px; height: 20px"
							>
								<v-icon small>mdi-equal</v-icon>
							</div>
						</div>
						<div>
							<img
								src="https://d37egnlosshd9l.cloudfront.net/v2/courses/toeicspeaking-grey.png"
								alt
								style="width: 40px"
							/>
							<p class="narrow-line-height mb-0 grey--text text--darken-3">
								{{ toeicSpeakingLevel }}
							</p>
						</div>
					</div>
				</div>

				<div>
					<div class="py-4 text-xs-center">
						<p class="nls font-weight-medium" v-html="levelComment"></p>
					</div>
					<p
						@click="levelExampleDialog = true"
						class="px-2 strong text-xs-center grey--text hover-pointer"
						style="text-decoration: underline"
					>
						<small>*공인 점수 대비 텔라 레벨 비교 보기</small>
					</p>
				</div>
			</v-col>
		</v-row>
		<v-divider class="my-0"></v-divider>
		<!--점수-->
		<v-row align="center" justify="center" class="text-center py-50">
			<v-col cols="12">
				<div class="px-5 mb-8" style="max-width: 450px; margin: 0 auto">
					<div class="d-flex justify-center mb-5">
						<h2 class="pr-5 font-weight-bold">Total Score</h2>
						<h1 class="font-weight-bold white-space:normal;">
							{{
								Math.round(
									((trial.expression_score +
										trial.structure_score +
										trial.fluency_score) *
										500) /
										15,
								)
							}}
							<small class="grey--text text--lighten-1" style="font-size: 0.6em"
								>/500</small
							>
						</h1>
					</div>
					<div style="position: relative" class="pb-8">
						<div
							v-for="n in graphY"
							:key="`graph-${n}`"
							class="d-flex justify-center align-center mt-8"
						>
							<div
								class="text-center grey--text"
								style="line-height: 1em; width: 30px; vertical-align: middle"
							>
								<p class="small font-weight-bold">{{ n }}</p>
							</div>
							<div
								style="
									width: calc(100% - 30px);
									border-top: 1.2px dashed #dadada;
									vertical-align: middle;
								"
							></div>
						</div>
						<div
							style="
								position: absolute;
								bottom: 3px;
								right: 0;
								width: calc(100% - 30px);
							"
						>
							<v-row class="text-center">
								<v-col
									cols="4"
									v-for="item in carousels"
									:key="`graph-${item.title}`"
								>
									<div
										class="d-flex align-end justify-center mb-2"
										style="height: 270px"
									>
										<div
											:class="item.color"
											style="width: 20px"
											:style="item.scoreGraphHeight"
										></div>
										<div
											class="grey lighten-1"
											style="width: 20px"
											:style="item.avergHeight"
										></div>
									</div>
									<p
										class="small"
										:class="{
											'black--text':
												item.title === carousels[carousel].title ||
												userDevice === 'desktop',
											'grey--text text--lighten-1':
												item.title !== carousels[carousel].title &&
												userDevice === 'mobile',
										}"
									>
										{{ item.title }}
									</p>
								</v-col>
							</v-row>
						</div>
					</div>
					<v-row align-center jusify-center no-gutters class="px-8 mb-8">
						<v-col cols="6">
							<p class="nls small grey--text text--darken-1">
								<v-icon small class="primary--text"
									>mdi-checkbox-blank-circle</v-icon
								>
								<v-icon small class="emerald--text" style="margin-left: -4px"
									>mdi-checkbox-blank-circle</v-icon
								>

								<v-icon small class="pink--text mr-1" style="margin-left: -4px"
									>mdi-checkbox-blank-circle</v-icon
								>

								내 점수
							</p>
						</v-col>
						<v-col cols="6">
							<p class="nls small grey--text text--darken-1">
								<v-icon small class="grey--text"
									>mdi-checkbox-blank-circle</v-icon
								>
								회원 평균점수
							</p>
						</v-col>
					</v-row>
				</div>
				<div v-if="userDevice === 'mobile'">
					<div class="mls d-flex mb-3 align-center justify-center">
						<v-btn
							text
							icon
							small
							@click="carousel--"
							:disabled="carousel <= 0"
						>
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
						<h1 class="title2 nls font-weight-bold" style="width: 260px">
							{{ carousels[carousel].title }}&nbsp;&nbsp;&nbsp;
							<span :class="carousels[carousel].textColor"
								>{{ carousels[carousel].score }}점&nbsp;</span
							>
							<small style="font-size: 0.7em">/10점</small>
						</h1>
						<v-btn
							text
							icon
							small
							@click="carousel++"
							:disabled="carousel >= 2"
						>
							<v-icon>mdi-chevron-right</v-icon>
						</v-btn>
					</div>
					<v-carousel
						v-model="carousel"
						height="120"
						:show-arrows="false"
						hide-delimiters
					>
						<v-carousel-item v-for="(slide, i) in carousels" :key="i">
							<v-sheet color="white" height="100%">
								<v-row
									no-gutters
									class="fill-height"
									align="start"
									justify="center"
								>
									<div class="black--text px-5 text-left">
										<p>{{ carousels[carousel].comment }}</p>
									</div>
								</v-row>
							</v-sheet>
						</v-carousel-item>
					</v-carousel>
					<h1
						class="d-flex justify-center align-center"
						style="font-size: 40px !important; line-height: 1em"
					>
						<span
							class="px-1 grey--text"
							:class="{ 'primary--text': carousel === 0 }"
							>.</span
						>
						<span
							class="px-1 grey--text"
							:class="{ 'emerald--text': carousel === 1 }"
							>.</span
						>
						<span
							class="px-1 grey--text"
							:class="{ 'pink--text': carousel === 2 }"
							>.</span
						>
					</h1>
				</div>
				<div v-else>
					<v-row style="max-width: 900px; margin: 0 auto">
						<v-col cols="4" v-for="carousel in carousels" :key="carousel.title">
							<h2 class="nls font-weight-bold" style="width: 260px">
								{{ carousel.title }}&nbsp;&nbsp;&nbsp;
								<span :class="carousel.textColor"
									>{{ carousel.score }}점&nbsp;</span
								>
								<small style="font-size: 0.7em">/10점</small>
							</h2>
							<p class="px-3 text-left">{{ carousel.comment }}</p>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-row>
		<!--튜터의 한마디-->
		<v-row justify-center align-center no-gutters class="py-50">
			<v-col cols="12" class="text-center">
				<div>
					<div class="text-xs-center">
						<h2 class="font-weight-bold text-center text-left mb-3">
							튜터의 한마디
						</h2>
						<TutorAvatar
							:size="100"
							:id="trial.tutor_id"
							:name="trial.tutor_name"
							:type="1"
						/>
						<!-- <TutorAvatar
							:id="trial.tutor_id"
							:name="trial.tutor_name"
							:type="1"
							:xLarge="true"
						/> -->
						<h3 class="mb-0">{{ trial.tutor_name }}</h3>
					</div>
				</div>
				<div
					:style="{ 'width:calc(100% - 150px)': userDevice === 'desktop' }"
					:class="{ 'd-inline-flex ml-4': userDevice === 'desktop' }"
				>
					<div :class="{ 'px-3': userDevice === 'mobile' }">
						<div class="program-comment-arrow-up mt-2"></div>
						<div
							class="yellow darken-1 text-left pa-3"
							style="border-radius: 3px; max-width: 500px; margin: 0 auto"
						>
							<p>
								Hey {{ trial.name }}! It was such a pleasure talking to you.
								<br />
								{{ tutorComment }}
							</p>
						</div>
					</div>
				</div>
			</v-col>
		</v-row>
		<!-- <v-divider></v-divider>
     <div class="white">
      <v-row no-gutters class="desktop-width-limit">
        <v-col cols="4" class="text-center">
          <div class="d-inline-block py-4 tab-btn hover-pointer" @click="moveTo(0)">
            <p>내 레벨평가</p>
          </div>
        </v-col>
        <v-col cols="4" class="text-center">
          <div class="d-inline-block py-4 tab-btn hover-pointer active" @click="moveTo(1)">
            <p>데이터분석</p>
          </div>
        </v-col>
        <v-col cols="4" class="text-center">
          <div class="d-inline-block py-4 tab-btn hover-pointer" @click="moveTo(2)">
            <p>프로그램 추천</p>
          </div>
        </v-col>
      </v-row>
    </div>-->
		<v-divider></v-divider>
		<v-container id="second" px-0 class="py-100">
			<v-row justify-center align-center>
				<v-col cols="12" class="text-center">
					<h1 class="font-weight-bold mb-8">채팅 데이터 분석</h1>
					<div class="px-3 mb-5">
						<img
							:src="`https://image.tella.co.kr/v2/trials/how-to-analyze${mobileImg}.png`"
							style="width: 100%; max-width: 500px"
							alt
						/>
					</div>
					<p>
						수업만 하면, AI가 나의 성과를 객관적으로 진단해드려요!
						<br />
						<span class="primary--text">{{ trial.name }}님</span>의 채팅
						데이터를 분석하여 영어실력을 <br />다방면으로 진단하며, 학습 방향을
						제시합니다.
					</p>
				</v-col>
			</v-row>
		</v-container>
		<v-divider></v-divider>
		<v-row justify-center align-center no-gutters class="py-50">
			<v-col cols="12" class="text-center">
				<h2 class="font-weight-bold mb-5">영어 사용량</h2>
				<v-row
					:no-gutters="userDevice === 'mobile'"
					style="width: 70%; margin: 0 auto"
				>
					<v-col cols="12" sm="4" class="mb-3">
						<div
							class="d-flex align-center primary white--text pa-3"
							style="border: 3px solid #076cfa"
						>
							<p style="width: 60px" class="text-left">
								수업에서
								<br />사용한 <br />단어
							</p>
							<h1
								style="width: calc(100% - 60px)"
								class="text-right text-weight-bold"
							>
								{{ stat.tutee_number_of_words }}개
							</h1>
						</div>
					</v-col>
					<v-col cols="12" sm="4" class="mb-3">
						<div
							class="d-flex align-center primary--text pa-3"
							style="border: 3px solid #076cfa"
						>
							<p style="width: 60px" class="text-left">
								수업에서
								<br />작문한 <br />문장
							</p>
							<h1
								style="width: calc(100% - 60px)"
								class="text-right text-weight-bold"
							>
								{{ stat.tutee_number_of_sentences }}개
							</h1>
						</div>
					</v-col>
					<v-col cols="12" sm="4" class="mb-3">
						<div
							class="d-flex align-center primary white--text pa-3"
							style="border: 3px solid #076cfa"
						>
							<p style="width: 60px" class="text-left">
								수업에서
								<br />첨삭받은 <br />문장
							</p>
							<h1
								style="width: calc(100% - 60px)"
								class="text-right text-weight-bold"
							>
								{{ corrections.length }}개
							</h1>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row justify-center align-center no-gutters class="py-50">
			<v-col cols="12" class="text-center">
				<h2 class="font-weight-bold">영어 유창성</h2>
				<p class="grey--text text--darken-1 mb-8 font-weight-bold">
					* 문장길이 기준
				</p>
				<p>
					{{ trial.name }}님이 무료체험에서
					<br />
					작성한 문장의 평균길이는
					{{ stat.tutee_length_of_sentences }} 단어입니다.
				</p>
				<p style="line-height: 2em" class="my-4">
					Example:
					<br />
					<span class="primary--text nanum-pen">{{ example }}</span>
					<strong>({{ stat.tutee_length_of_sentences }} 단어)</strong>
				</p>
				<div class="pa-3">
					<div
						class="yellow lighten-4 pa-3"
						style="max-width: 400px; margin: 0 auto"
					>
						<p class="text-left">{{ lengthComment }}</p>
					</div>
				</div>
			</v-col>
		</v-row>
		<v-row justify-center align-center no-gutters class="py-50">
			<v-col cols="12" class="text-center">
				<h2 class="font-weight-bold nls mb-8">한달 후 나의 예상 학습량은?</h2>
				<div class="desktop-width-limit">
					<v-row no-gutters class="white--text grey ligthen-1 py-2">
						<v-col cols="3"></v-col>
						<v-col cols="3">
							<p class="small">
								수업에서
								<br />사용한 단어
							</p>
						</v-col>
						<v-col cols="3">
							<p class="small">
								수업에서
								<br />작성한 문장
							</p>
						</v-col>
						<v-col cols="3">
							<p class="small">
								수업에서
								<br />첨삭 받은 문장
							</p>
						</v-col>
					</v-row>
					<template v-for="i in 4">
						<v-row no-gutters class="py-3" :key="`oneMonthLater-${i}`">
							<v-col cols="3">
								<p class>{{ i * 5 }}회</p>
							</v-col>
							<v-col cols="3">
								<p class>
									{{
										numberWithCommas(
											stat.tutee_number_of_distinct_words * i * 5,
										)
									}}개
								</p>
							</v-col>
							<v-col cols="3">
								<p class>
									{{
										numberWithCommas(stat.tutee_number_of_sentences * i * 5)
									}}개
								</p>
							</v-col>
							<v-col cols="3">
								<p class>
									{{ numberWithCommas(corrections.length * i * 5) }}개
								</p>
							</v-col>
						</v-row>
						<v-divider :key="`oneMonthLater-divider-${i}`"></v-divider>
					</template>
				</div>
				<p class="py-3 small font-wegiht-bold">
					* 한달 수강권 기준 구매횟수별 평균 예상치
				</p>
				<a
					class="text-decoration-none"
					href="https://tella.co.kr/plans/package"
				>
					<v-btn x-large color="pink white--text mt-8" rounded depressed
						>할인 받고 수강신청 하러가기</v-btn
					>
				</a>
			</v-col>
		</v-row>
		<v-row class="py-50 grey lighten-5" no-gutters>
			<v-col cols="12" class="text-center">
				<h1 class="mb-3 font-weight-bold">수업 돌아보기</h1>
				<p class="mb-8">
					정규 수업을 수강하시면 더 자세한
					<br />분석을 받아보실 수 있습니다.
				</p>
				<v-row no-gutters class="desktop-width-limit">
					<v-col cols="12" sm="6">
						<div class="grey lighten-2">
							<h3 class="font-weight-bold pt-5 pb-3">Lesson Review</h3>
							<div class="px-3" :style="chatLogWrapStyle">
								<div v-for="log in logs" :key="log.id">
									<div class="bubble-wrap">
										<div
											class="bubble"
											:class="{
												'bubble-tutor': log.type === 'Tutor',
												'bubble-student': log.type === 'Tutee',
											}"
										>
											<p class="mb-0" v-html="log.content"></p>
											<div class="triangle"></div>
										</div>
										<div style="clear: both"></div>
									</div>
								</div>
							</div>
							<div
								class="mt-4 pb-2 primary--text text-center hover-pointer"
								v-if="userDevice === 'mobile'"
							>
								<p
									class="mb-0 primary--text small nlh hover-pointer"
									@click="chatLogDialog = true"
								>
									<strong>전체 보기</strong>
									<br />
									<v-icon class="primary--text">mdi-chevron-down</v-icon>
								</p>
							</div>
						</div>
					</v-col>
					<v-col cols="12" sm="6">
						<div class="white">
							<h3 class="font-weight-bold pt-5 pb-3">
								Corrections & Suggestions
							</h3>
							<div v-if="corrections.length > 0" :style="chatLogWrapStyle">
								<div
									style="border-bottom: 1px solid #dadada"
									v-for="correction in corrections"
									:key="correction.id"
									class="text-left px-4 py-2"
								>
									<p class>
										<span v-html="correction.html"></span>
									</p>
									<AudioPlayer
										style="float: right"
										:text="true"
										:small="true"
										:script="correction.origin"
										:voiceCategory="'F'"
									></AudioPlayer>
									<div style="clear: both"></div>
								</div>
							</div>
							<div
								class="mt-4 pb-2 primary--text text-center hover-pointer"
								v-if="userDevice === 'mobile'"
							>
								<p
									class="mb-0 primary--text small nlh hover-pointer"
									@click="correctionDialog = true"
								>
									<strong>전체 보기</strong>
									<br />
									<v-icon class="primary--text">mdi-chevron-down</v-icon>
								</p>
							</div>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-divider></v-divider>
		<!-- <div class="white">
      <v-row no-gutters class="desktop-width-limit">
        <v-col cols="4" class="text-center">
          <div class="d-inline-block py-4 tab-btn hover-pointer" @click="moveTo(0)">
            <p>내 레벨평가</p>
          </div>
        </v-col>
        <v-col cols="4" class="text-center">
          <div class="d-inline-block py-4 tab-btn hover-pointer" @click="moveTo(1)">
            <p>데이터분석</p>
          </div>
        </v-col>
        <v-col cols="4" class="text-center">
          <div class="d-inline-block py-4 tab-btn hover-pointer active" @click="moveTo(2)">
            <p>프로그램 추천</p>
          </div>
        </v-col>
      </v-row>
    </div>-->
		<v-divider></v-divider>
		<v-container id="third" px-0 fill-height class="py-100">
			<v-row no-gutters justify-center align-center>
				<v-col cols="12" class="text-center">
					<div class="mb-12">
						<h1 class="font-weight-bold mb-8">토기의 추천!</h1>
						<img
							src="https://image.tella.co.kr/v2/designs/toki/green_toki.png"
							alt
							class="mb-3"
						/>
						<div class="px-3">
							<div class="program-comment-arrow-up"></div>
							<div
								class="yellow darken-1 pa-3 text-left"
								style="max-width: 500px; margin: 0 auto"
							>
								<p v-html="programComment"></p>
							</div>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<v-row justify-center no-gutters align-center class="py-50">
			<v-col cols="12" class="text-center">
				<h2 class="nls primary--text font-weight-bold mb-5">
					[Level {{ level }} 학습자를 위한 추천]
				</h2>
				<!-- <h3 class="mb-5 nls" v-html="programRecommandComment"></h3> -->
				<div class="d-flex justify-center mb-8">
					<div
						class="program-tag-btn px-2 py-1 mx-1 hover-pointer"
						@click="selectedTag = 0"
						:class="{
							'primary white--text': selectedTag === 0,
							'primary--text': selectedTag !== 0,
						}"
					>
						#생활영어
					</div>
					<div
						class="program-tag-btn px-2 py-1 mx-1 hover-pointer"
						@click="selectedTag = 1"
						:class="{
							'primary white--text': selectedTag === 1,
							'primary--text': selectedTag !== 1,
						}"
					>
						#비즈니스영어
					</div>
					<div
						class="program-tag-btn px-2 py-1 mx-1 hover-pointer"
						@click="selectedTag = 2"
						:class="{
							'primary white--text': selectedTag === 2,
							'primary--text': selectedTag !== 2,
						}"
					>
						#텔라추천
					</div>
				</div>
				<img
					:src="`https://image.tella.co.kr/v2/program/cover/${userDevice}/${programRecommend}.jpg`"
					style="width: 100%; max-width: 450px"
					alt
				/>
				<br /><br />
				<!-- <div
					class="grey lighten-3 mb-8"
					style="max-width: 450px; margin: 0 auto; min-height: 300px"
				>
					<ProgramGuide
						:programUnitId="unitRecommendObject.id"
						:translate="false"
						:style="chatLogWrapStyle"
					/>
					<div
						class="mt-4 pb-2 primary--text text-center hover-pointer"
						v-if="userDevice === 'mobile'"
					>
						<p
							class="mb-0 primary--text small nlh hover-pointer"
							@click="programGuideDialog = true"
						>
							<strong>전체 보기</strong>
							<br />
							<i class="far fa-angle-down"></i>
						</p>
					</div>
				</div> -->

				<a
					class="text-decoration-none"
					href="https://tella.co.kr/plans/package"
				>
					<v-btn x-large color="pink white--text" rounded depressed
						>할인 받고 수강신청 하러가기</v-btn
					>
				</a>
			</v-col>
		</v-row>
		<v-row no-gutters class="py-50">
			<v-col cols="12" class="text-center">
				<h2>그 외 LEVEL {{ level }}의 프로그램</h2>
				<v-container :class="{ 'grid-list-lg': userDevice !== 'mobile' }">
					<v-row>
						<v-col
							cols="4"
							v-for="program in anotherPrograms"
							:key="`another-${program}`"
						>
							<img
								:src="`https://image.tella.co.kr/v2/program/cover/${
									userDevice === 'mobile' ? 'mobile' : 'desktop'
								}/${program}.jpg`"
								style="width: 100%"
								alt
							/>
						</v-col>
						<v-col cols="12">
							<i class="far fa-ellipsis-v fa-2x"></i>
							<br />
							<a
								class="text-decoration-none"
								href="https://tella.co.kr/programs?page=1"
							>
								<v-btn
									x-large
									rounded
									depressed
									color="primary"
									class="mt-4"
									style="min-width: 280px"
									>프로그램 더보기</v-btn
								>
							</a>
						</v-col>
					</v-row>
				</v-container>
			</v-col>
		</v-row>

		<v-container fill-height fluid class="secret-promo-container">
			<v-row no-gutters>
				<v-col cols="12" class="text-center">
					<h1
						class="keep-word white--text font-weight-bold"
						style="line-height: 1.2em; letter-spacing: 0em"
					>
						무료체험을 완료한
						<br />
						{{ trial.kakao_id }}님에게만 드리는
						<!-- <br />7% 시크릿 코드 -->
						<br />10% 시크릿 할인 쿠폰
					</h1>
					<div class="my-5 px-5 py-2 promotion-code-box">
						<h3>시크릿할인 쿠폰번호</h3>
						<h1 class="font-weight-bold emerald--text">POSSIBILITY</h1>
					</div>
					<p class="white--text mb-5">
						무료체험 후 7일 이내에만 사용이 가능합니다.
						<br />로그인 후 “쿠폰&쿠폰등록"에 등록하고 사용해주세요.
						<!-- <br />로그인 후 “시크릿코드"를 등록하고 사용해주세요. -->
					</p>
					<a
						class="text-decoration-none"
						href="https://tella.co.kr/plans/package"
					>
						<v-btn color="pink white--text" depressed x-large rounded
							>할인 받고 수강신청 하러가기</v-btn
						>
					</a>
					<p class="mt-5 white--text small">
						* 회원가입 시 정확한 정보를 기재해주세요
					</p>
				</v-col>
			</v-row>
		</v-container>
		<div class="black py-3 text-center">
			<p class="white--text mobile">할인 쿠폰 만료까지</p>
			<!-- <p class="white--text mobile">시크릿코드 만료까지</p> -->
			<h2 class="pink--text font-weight-black">
				<span
					class="desktop white--text font-weight-regular pr-1"
					style="font-size: 1rem"
					>시크릿코드 만료까지</span
				>
				<!-- <span
          class="desktop white--text font-weight-regular pr-1"
          style="font-size:1rem;"
        >할인 쿠폰 만료까지</span>-->
				남은시간 {{ timer }}
			</h2>
		</div>
		<div class="indigo darken-4 text-center py-3">
			<img
				:src="`https://image.tella.co.kr/v2/courses/zing-coupon${mobileImg}.${
					userDevice === 'mobile' ? 'jpg' : 'png'
				}`"
				alt
				style="width: 100%"
				class="desktop-width-limit"
			/>
		</div>
		<v-container
			fill-height
			fluid
			style="height: 600px; background-color: #050b23"
			pa-0
			class="refund-container"
		>
			<v-row align-center justify-center>
				<v-col cols="12" class="text-center white--text">
					<div class="pb-4">
						<img
							src="https://image.tella.co.kr/banner/first-step-1.png"
							alt
							style="max-width: 50%"
						/>
					</div>
					<h1 class="font-weight-bold nls mb-4">100% 환불 프로모션!</h1>
					<h3 class="nls mb-4">
						정규 수업 1회 진행 후에도
						<br class="d-block d-sm-none" />텔라 수업에 대한 확신이 서지
						않으시면
						<br />
						<strong class="emerald--text"
							>남은 수업을 100% 환불해 드립니다.</strong
						>
						<br />
						<small>(*결제 후 3일 이내 신청 시)</small>
					</h3>
					<a
						class="text-decoration-none"
						href="https://tella.co.kr/plans/package"
					>
						<v-btn
							color="pink white--text"
							rounded
							x-large
							depressed
							class="mb-4"
						>
							<strong>할인받고 수강신청 하러가기</strong>
						</v-btn>
					</a>
					<p>카카오톡, 페이스북을 이용해 편리하게 가입하세요:)</p>
				</v-col>
			</v-row>
		</v-container>
		<!-- <v-container fluid fill-height class="secret-promo-container2">
      <v-row justify-center align-center>
        <v-col cols="12" class="text-center">
          <h1 class="white--text font-weight-bold" style="line-height:1.2em;">
            {{trial.name}}님에게만
            <br class="d-block d-sm-none" />알려드리는
            <br />7% 시크릿 할인 코드
          </h1>
          <div class="my-5 px-5 py-2 promotion-code-box emerald--text">
            <h1 class="font-weight-bold">POSSIBILITY</h1>
          </div>
          <p class="white--text mb-5">
            로그인 후 수강신청 시 '할인코드'란에 입력하시면
            <br />바로 7% 할인이 적용됩니다.
            <br />
            <span style="border-bottom:1px solid #fff;">첫 수강 할인쿠폰(20%)과 중복 적용 가능!</span>
          </p>
          <v-btn
            color="pink white--text"
            x-large
            rounded
            @click="$router.push({name: 'plans-package'})"
          >할인 받고 수강신청 하러가기</v-btn>
          <p class="mt-5 white--text small">*회원가입 시 정확한 정보를 기재해주세요</p>
        </v-col>
      </v-row>
    </v-container>-->
		<v-dialog
			v-model="programGuideDialog"
			width="500"
			:fullscreen="userDevice === 'mobile'"
			:hide-overlay="userDevice === 'mobile'"
			:transition="
				userDevice === 'mobile'
					? 'dialog-bottom-transition'
					: 'dialog-transition'
			"
		>
			<v-card style="position: relative">
				<v-toolbar
					dark
					style="z-index: 10; position: fixed; top: 0; width: 100%"
					class="primary white--text"
				>
					<v-btn icon dark @click="programGuideDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>
						<h3 class="font-weight-bold d-inline-block">프로그램 맛보기</h3>
					</v-toolbar-title>
					<div class="flex-grow-1"></div>
				</v-toolbar>
				<div class="grey lighten-3" style="padding-top: 65px">
					<!-- <ProgramGuide :selectedUnit="unitRecommendObject" /> -->
				</div>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="chatLogDialog"
			width="500"
			:fullscreen="userDevice === 'mobile'"
			:hide-overlay="userDevice === 'mobile'"
			:transition="
				userDevice === 'mobile'
					? 'dialog-bottom-transition'
					: 'dialog-transition'
			"
		>
			<v-card style="position: relative">
				<v-toolbar
					dark
					style="z-index: 10; position: fixed; top: 0; width: 100%"
					class="primary white--text"
				>
					<v-btn icon dark @click="chatLogDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>
						<h3 class="font-weight-bold d-inline-block">Lesson Review</h3>
					</v-toolbar-title>
					<div class="flex-grow-1"></div>
				</v-toolbar>
				<div class="grey lighten-3" style="padding-top: 65px">
					<div class="px-3">
						<div v-for="log in logs" :key="log.id">
							<div class="bubble-wrap">
								<div
									class="bubble"
									:class="{
										'bubble-tutor': log.type === 'Tutor',
										'bubble-student': log.type === 'Tutee',
									}"
								>
									<p class="mb-0" v-html="log.content"></p>
									<div class="triangle"></div>
								</div>
								<div style="clear: both"></div>
							</div>
						</div>
					</div>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog
			v-model="correctionDialog"
			width="500"
			:fullscreen="userDevice === 'mobile'"
			:hide-overlay="userDevice === 'mobile'"
			:transition="
				userDevice === 'mobile'
					? 'dialog-bottom-transition'
					: 'dialog-transition'
			"
		>
			<v-card style="position: relative">
				<v-toolbar
					dark
					style="z-index: 10; position: fixed; top: 0; width: 100%"
					class="primary white--text"
				>
					<v-btn icon dark @click="correctionDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>
						<h3 class="font-weight-bold d-inline-block">
							Corrections & Suggestions
						</h3>
					</v-toolbar-title>
					<div class="flex-grow-1"></div>
				</v-toolbar>
				<div class="white" style="padding-top: 65px">
					<div v-if="corrections.length > 0">
						<div
							style="border-bottom: 1px solid #dadada"
							v-for="(correction, index) in corrections"
							:key="`correction-dialog-${index}`"
							class="text-left px-4 py-2"
						>
							<p class>
								<span v-html="correction.html"></span>
							</p>
							<AudioPlayer
								style="float: right"
								:text="true"
								:small="true"
								:script="correction.origin"
								:voiceCategory="'F'"
							></AudioPlayer>
							<div style="clear: both"></div>
						</div>
					</div>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog v-model="levelExampleDialog" width="500">
			<v-card style="position: relative">
				<v-toolbar flat color="white">
					<v-toolbar-title>
						<h3 class="font-weight-bold d-inline-block">레벨비교 표</h3>
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon @click="levelExampleDialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<div class="pa-3 text-center">
					<v-row align="center">
						<v-col cols="2">
							<img
								src="https://image.tella.co.kr/v2/logo/blue.png"
								style="width: 30px"
								alt
							/>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 sky--text strong">
								Level
								<br />3
							</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 sky--text strong">
								Level
								<br />4
							</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 sky--text strong">
								Level
								<br />5
							</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 sky--text strong">
								Level
								<br />6
							</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 sky--text strong">
								Level
								<br />7
							</p>
						</v-col>
					</v-row>
					<v-divider></v-divider>
					<v-row align="center">
						<v-col cols="2">
							<img
								src="https://image.tella.co.kr/logo/opic-100.png"
								style="width: 36px"
								alt
							/>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 narrow-letter-spacing">NH</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 narrow-letter-spacing">IL</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 narrow-letter-spacing">IM1~2</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 narrow-letter-spacing">IM3</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 narrow-letter-spacing">IH</p>
						</v-col>
					</v-row>
					<v-divider></v-divider>
					<v-row align="center">
						<v-col cols="2">
							<img
								src="https://image.tella.co.kr/logo/toeicspeaking.png"
								style="width: 36px"
								class="pt-1"
							/>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 narrow-letter-spacing">
								Level
								<br />1~2
							</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 narrow-letter-spacing">
								Level
								<br />3~4
							</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 narrow-letter-spacing">
								Level
								<br />5~6
							</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 narrow-letter-spacing">
								Level
								<br />6~7
							</p>
						</v-col>
						<v-col cols="2">
							<p class="small mb-0 narrow-letter-spacing">
								Level
								<br />8
							</p>
						</v-col>
					</v-row>
					<p class="pt-5 small text-xs-right grey--text">
						* 대략적인 비교를 위한 참고용입니다.
					</p>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
// import trialsApi from '@/api/trials.js'
// import format from '@/mixins/format'
import moment from 'moment-timezone'
import { numberWithCommas } from '@/helpers/number-format'
import TutorAvatar from '@/components/common/TutorAvatar'
import AudioPlayer from './AudioPlayer'

// import TutorAvatar from '@/components/common/TutorAvatar'
// import ProgramGuide from '@/components/program/guide/Guide'

export default {
	components: {
		TutorAvatar,
		// TutorAvatar,
		AudioPlayer,
		// ProgramGuide,
	},
	props: {
		trial: {
			required: true,
		},
		report: {
			required: true,
		},
		stat: {
			required: true,
		},
		logs: {
			required: true,
		},
	},
	data: () => ({
		offsetTop: 0,
		appToobar: false,
		scrollOptions: {
			duration: 300,
			offset: 10,
			easing: 'easeInOutCubic',
		},
		publicKey: null,
		activeTab: 1,
		graphY: [10, 8, 6, 4, 2, 0],
		carousel: 0,
		timer: null,
		selectedTag: 0,
		programGuideDialog: false,
		chatLogDialog: false,
		correctionDialog: false,
		levelExampleDialog: false,
	}),
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		mobileImg() {
			return this.userDevice === 'mobile' ? '-mobile' : ''
		},
		level() {
			let totalScore =
				this.trial.expression_score +
				this.trial.structure_score +
				this.trial.fluency_score
			let level = 3
			if (totalScore > 5) level = 4
			if (totalScore > 7) level = 5
			if (totalScore > 10) level = 6
			if (totalScore > 14) level = 7
			return level
		},
		opicLevel() {
			return this.level === 3
				? 'NH'
				: this.level === 4
				? 'IL'
				: this.level === 5
				? 'IM1~2'
				: this.level === 6
				? 'IM3'
				: 'IH'
		},
		toeicSpeakingLevel() {
			return this.level === 3
				? 'Lv 2-3'
				: this.level === 4
				? 'Lv 3-4'
				: this.level === 5
				? 'Lv 4-5'
				: 'Lv 5-6'
		},
		levelComment() {
			return this.level === 3
				? '<span class="primary--text">Level 3</span>은 친숙한 질문에<br>매우 제한된 단어를 사용하여<br>간단한 답변 정도만 할 수 있는<br>문법상 조금은 불완전한 문장을 만드는 레벨입니다.'
				: this.level === 4
				? '<span class="primary--text">Level 4</span>는 짧은 문장으로 대화하는데<br>큰 어려움을 느끼지 않지만,<br>충분히 자신의 생각을 전달하는 데는<br>표현력의 한계를 보이는 레벨입니다.'
				: this.level === 5
				? '<span class="primary--text">Level 5</span>는 자유롭게 대화하며<br>다양한 표현을 사용하지만,<br>복잡하고 긴 문장에서 오류가 있는 문장을 보여<br>세심한 표현력이 요구되는 레벨입니다.'
				: this.level === 6
				? '<span class="primary--text">Level 6</span>은 정교한 답변이 가능하며<br>자발적인 의사표현이 가능하지만,<br>심도 있는 주제에 대한 토론에 있어서는<br>훈련이 필요한 레벨입니다.'
				: '<span class="primary--text">Level 7</span>은 추상적인 주제로도<br>상황에 맞는 어투로 의견을 내고<br>다양한 표현을 자유롭게 사용하는,<br>문법 오류가 적고 문장 구사력이 높은 레벨입니다.'
		},
		structureComment() {
			let comment = [
				'주로 명사와 동사의 조합으로만 대화하시는 것 같습니다 (문장당 평균 3-5단어). 형용사와 부사를 사용해서 문장을 더 풍부하게 만들어보세요.',
				'단순한 문장으로 기초적인 의사전달이 가능합니다(문장당 평균 4-6단어). 복합적인 문장 구성이 가능하도록 여러 형태의 문장을 익혀 보세요.',
				'대화의 요점을 정확히 이해하고 간혹 접속사를 붙이는 등 긴 문장도 문제없이 만드실 수 있으시네요(문장당 평균 5-7단어). 자신감을 갖고 접속사 등을 활용하여 더 풍성한 문장을 만들어 보세요.',
				'비교적 복합적인 구성의 문장을 자주 구사할 수 있습니다(문장당 평균  6-8단어). 관계사, to부정사 등을 통해 의도를 더 정확하게 전달하는 연습을 해 보세요.',
				'복합적인 문장의 형태로 문장을 자유자재로 구사하실 수 있네요(문장당 7-10단어). 이제는 실수하지 않도록, 정확한 문장을 더 빠르게 구성하는 연습을 해 보세요.',
			]
			return comment[this.level - 3]
		},
		fluencyComment() {
			let comment = [
				'대화의 흐름보다는 문장을 만드는 것 자체에 더 집중하시는 것 같아요. 튜터 질문의 의도를 더 정확하게 파악하며 대화하려 노력해 보세요.',
				'대화의 흐름보다는 문장을 만드는 것 자체에 더 집중하시는 것 같아요. 간단한 질문에는 대답할 수 있지만, 약간이라도 변형되면 어려워하시네요. 빠르게 화자의 의도를 파악할 수 있도록 여러가지 상황에서의 대화에 노출되어 보는 것도 좋을 것 같아요.',
				'대화의 흐름에 따라 간단한 질문에는 대답할 수 있지만, 부연설명이나 심도 있는 대화는 아직 어려워하시는 것 같아요. 조금 더 구체적으로 자기 의견을 표현하는 연습을 해 보는 건 어때요?',
				'원어민 튜터와도 대화 흐름과 의도에 맞게 자유롭게 대화할 수 있습니다. 이제 대화를 이끌어 가는 연습을 해 볼까요?',
				'토의나 토론을 능수능란하게 진행하고 가끔은 이끌기도 합니다. 논리적 비약 없이 자신의 의견을 개진해 보아요.',
			]
			return comment[this.level - 3]
		},
		expressionComment() {
			let comment = [
				'표현력이 부족합니다. 하고 싶은 말을 막힘없이 할 수 있도록 기초 어휘력을 늘려가세요.',
				'기초 어휘력은 다져져 있으나, 단어의 다양성이 부족합니다. 이미 알고 있는 단어들을 기반으로 유의어, 반의어를 익혀 보세요.',
				'알고 있는 단어를 의도에 맞게 사용하실 수 있네요. 이제 개별 단어 뿐만 아니라 구와 숙어의 형태로도 자유자재로 사용해 보세요.',
				'한가지 말도 여러 방식으로 표현하실 수 있네요! 더 다양한 주제로 대화할 수 있도록 주제별 단어와 표현을 익혀 보는 건 어떨까요?',
				'다양한 분야의 표현을 이해하고 구별하여 자유자재로 사용하시는 군요. 이젠 자신의 전문분야에서도 영어로 능수능란하게 대화할 수 있게 전문용어들도 익혀 보는 건 어때요?',
			]
			return comment[this.level - 3]
		},
		carousels() {
			return [
				{
					title: '문장구성',
					score: this.trial.expression_score * 2,
					comment: this.structureComment,
					color: 'primary',
					textColor: 'primary--text',
					avergHeight: 'height:192px;',
					scoreGraphHeight: `height:${
						((this.trial.expression_score * 2) / 10) * 250
					}px`,
				},
				{
					title: '유창성',
					score: this.trial.structure_score * 2,
					comment: this.fluencyComment,
					color: 'emerald',
					textColor: 'emerald--text',
					avergHeight: 'height:198px;',
					scoreGraphHeight: `height:${
						((this.trial.structure_score * 2) / 10) * 250
					}px`,
				},
				{
					title: '어휘/표현',
					score: this.trial.fluency_score * 2,
					comment: this.expressionComment,
					color: 'pink',
					textColor: 'pink--text',
					avergHeight: 'height:210px;',
					scoreGraphHeight: `height:${
						((this.trial.fluency_score * 2) / 10) * 250
					}px`,
				},
			]
		},
		tutorComment() {
			let tmp = [
				'While you frequently make mistakes with your sentences, you can still communicate by using simple vocabulary and grammar skills. You are able to answer simple questions and give basic information about yourself, but your responses are sometimes very limited.',
				'You will be able survive in an English-speaking country using simple sentences! You can express your needs and and describe your surroundings with limited vocabulary.',
				'You can sufficiently grasp the main idea of a conversation and connect simple sentences by using transition words and phrases. It is not too difficult for you to carry on a conversation with an English speaker, to state your opinions on familiar topics, or to respond to simple requests. ',
				'Although you occasionally make minor mistakes, you have a sound foundation of basic English grammar and vocabulary! It is quite impressive that you can state your opinions on various topics, discuss the pros and cons of an issue, and stay engaged in social and occupational discourses.',
				'You can comprehend complicated sentences and discuss abstract and technical topics in depth.  Your proficiency allows you to stay engaged in prolonged discourses with native speakers and to actively partake in problem-solving and decision-making situations.',
			]
			return tmp[this.level - 3]
		},
		example() {
			let example = [
				'They are smart students.',
				'You can go home now.',
				'I am not sure about that.',
				'I will include you in the project.',
				'My teacher told him to lower his voice.',
				'Once you finish your homework, you can watch TV.',
				'My chocolate ice-cream melted and fell on the concrete ground.',
				'Unfortunately, I missed several calls while I was working this afternoon.',
				'Jennifer, whose mother you met yesterday, would like to talk to you.',
				'We had to take a moment together and look at the big picture.',
				'The first thing we assumed is that they might have lots of useful tips.',
				'My personal favorite, which makes sense on some level, is to synthesize all the ideas.',
			]
			if (this.stat.tutee_length_of_sentences < 5) return example[0]
			else if (this.stat.tutee_length_of_sentences < 15)
				return example[Math.floor(this.stat.tutee_length_of_sentences) - 4]
			else return example[11]
		},
		lengthComment() {
			let comments = [
				'주로 명사와 동사의 조합으로 대화합니다. 형용사와 부사를 사용해서 문장을 더 풍부하게 만들어보세요.',
				'단순한 문장으로 기초적인 의사전달이 가능합니다. to 부정사나 동명사를 사용하여 여러 형태의 문장을 익혀 보세요.',
				'대화의 요점을 정확히 이해하고 간혹 접속사를 붙이는 등 긴 문장도 문제없이 만드실 수 있으시네요. 다양한 접속사를 활용하여 복합적인 문장을 만들어 보세요.',
				'비교적 복합적인 구성의 문장을 자주 구사할 수 있습니다. 관계대명사와 특수문자 등을 활용하여 더 다양한 문장 형태를 연습해 보세요.',
				'복합적인 문장의 형태로 문장을 자유자재로 구사하실 수 있네요. 실수를 최소화 하여 정확한 문장을 더 빠르게 구성하는 연습을 해 보세요.',
				'Keep up the good work!',
			]
			if (this.stat.tutee_length_of_sentences < 4) return comments[0]
			else if (this.stat.tutee_length_of_sentences < 6) return comments[1]
			else if (this.stat.tutee_length_of_sentences < 8) return comments[2]
			else if (this.stat.tutee_length_of_sentences < 10) return comments[3]
			else if (this.stat.tutee_length_of_sentences < 12) return comments[4]
			else return comments[5]
		},
		corrections() {
			let corrections = []
			this.logs.forEach(log => {
				let html, origin
				if (
					log.type === 'Tutor' &&
					log.content.indexOf('>>>') !== -1 &&
					log.content.indexOf('오류 수정 알아보기!') === -1 &&
					log.content.indexOf('https://goo.gl/J5MW2N') === -1
				) {
					html = log.content
					html = html.replace(
						'Or >>>',
						"<span class='red--text'>첨삭</span> : ",
					)
					html = html.replace('>>>>', "<span class='red--text'>첨삭</span> : ")
					html = html.replace('>>>', "<span class='red--text'>첨삭</span> : ")
					html = html.replace('>>', "<span class='red--text'>첨삭</span> : ")
					html = html.replace(/\*/g, "<span class='red--text'>*</span>")
					origin = log.content
					origin = origin.replace('Or >>>', '')
					origin = origin.replace('>>>>', '')
					origin = origin.replace('>>>', '')
					origin = origin.replace('>>', '')
					origin = origin.replace(/\*/g, '')
					corrections.push({ origin: origin, html: html })
				}
			})
			return corrections
		},
		programComment() {
			let comments = [
				'Level 3은 문법상 조금은 불완전한 문장으로 간단한 답변을 할 수 있는 레벨의 사용자입니다.<br>텔라의 목적별 추천 프로그램으로 좀 더 완전한 문장을 만드는 연습을 함께 해요!',
				'Level 4는 짧은 대화는 가능하지만, 충분히 자신의 생각을 전달하기에는 어려움이 있는 레벨의 사용자입니다.<br>텔라의 목적별 추천 프로그램으로 자신의 생각을 충분히 표현하는 연습을 해보세요!',
				'Level 5는 다양한 표현으로 자유로운 대화가 가능하지만, 복잡하고 긴 문장에서는 간혹 오류가 발생하기도 하는 레벨의 사용자입니다.<br>텔라의 목적별 추천 프로그램으로 오류를 줄이고, 수준 높은 문장을 만들어 보세요!',
				'Level 6은 정교한 답변과 자발적인 의사 표현이 가능하지만, 심도 있는 주제를 놓고 대화하기 위해서는 훈련이 필요한 레벨의 사용자입니다.<br>텔라의 목적별 추천 프로그램으로 심도 깊은 대화를 나눠보세요!',
				'Level 7은 추상적인 주제에 대한 대화도 가능한, 텔라 기준 문장 구사력이 가장 높은 레벨의 사용자입니다.<br>텔라의 목적별 추천 프로그램으로 영어의 감을 놓지 않고, 실력을 더 키워보세요!',
			]
			return comments[this.level - 3]
		},
		programRecommandComment() {
			let comments = [
				'[Hello, English!] 코스<br>영어와의 수줍은 첫만남, 두근두근!<br>기본기부터 다지고 싶은 분들을 위한 추천 코스',
				'[영어의 스펙트럼을 넓혀라] 코스<br>아..영어로 뭐더라? 답답함은 노노!<br>표현의 폭을 더 넓히고 싶은 분들을 위한 코스',
				'[영어 자신감 뿜뿜] 코스<br>조금 더 수준 높은 단어 깊이 있는 표현으로<br>이야기하고픈 분들을 위한 코스',
				'[영어는 재밌어! 짜릿해!] 코스<br>다양한 토픽, 전문적인 주제로<br>풍성한 대화를 나누고 싶은 분들을 위한 코스',
				'[To Infinity and Beyond!] 코스<br>높은 수준을 유지하면서<br>더 실력을 높이고자 하는 분들을 위한 추천 코스',
			]
			return comments[this.level - 3]
		},
		anotherPrograms() {
			let tmp = [
				[103, 22, 20],
				[107, 123, 21],
				[23, 74, 68],
				[124, 77, 71],
				[120, 34, 129],
			]
			return tmp[this.level - 3]
		},
		programRecommend() {
			let tmp = [
				[20, 30, 123],
				[22, 30, 132],
				[23, 31, 119],
				[24, 31, 29],
				[68, 32, 122],
			]
			return tmp[this.level - 3][this.selectedTag]
		},
		unitRecommendObject() {
			let tmp = [
				// 30 -> 44, 20 ->1 123->221
				[44, 1, 221],
				[44, 258, 578],
				[64, 395, 10],
				[64, 420, 161],
				[36, 145, 276],
			]
			return {
				id: tmp[this.level - 3][this.selectedTag],
			}
		},
		chatLogWrapStyle() {
			return this.userDevice === 'mobile'
				? 'max-height:300px;overflow-y:hidden;'
				: 'max-height:500px;overflow-y:auto;'
		},
	},
	watch: {
		offsetTop(newVal) {
			this.appToobar = newVal > 500
		},
	},
	created() {
		// this.getTrialInfo()
		this.calcTimer()
		window.onscroll = e => {
			this.scrolling(e)
		}
	},
	methods: {
		scrolling() {
			// this.appToobar = this.offsetTop < document.documentElement.scrollTop
			this.offsetTop = document.documentElement.scrollTop
			// this.offsetTop = e.target.scrollTop
		},
		// async getTrialInfo() {
		// 	try {
		// 		this.isLoaded = false
		// 		const result = await trialService.getReport(
		// 			this.$route.params.encryptedId,
		// 		)

		// 		this.trial = result.trial
		// 		this.chatLogs = result.logs
		// 		this.stat = result.stat
		// 		this.trial.moment = this.momentFormat(
		// 			this.trial.lesson_date,
		// 			this.trial.lesson_time,
		// 		)
		// 		if (this.trial.status === 'appointed') {
		// 			alert(
		// 				'아직 수업이 분석되지 않았습니다. 분석이 완료되는대로 알림톡을 보내드리니 조금만 기다려주세요!',
		// 			)
		// 			this.$router.push({
		// 				name: 'trial-list',
		// 				query: {
		// 					phone: this.trial.phone,
		// 					kakaoId: this.trial.kakao_id,
		// 					countryCode: this.trial.country_code,
		// 				},
		// 			})
		// 		}
		// 		this.calcTimer()
		// 		this.isLoaded = true
		// 	} catch (err) {
		// 		alert('체험 정보를 불러오지 못했습니다. 고객센터로 문의주세요.')
		// 		this.$router.push({ name: 'trial-search' })
		// 	}
		// 	// trialsApi
		// 	// 	.getByEncryptedId(
		// 	// 		`?encrypted_id=${encodeURIComponent(this.$route.params.encryptedId)}`,
		// 	// 	)
		// 	// 	.then(res => {
		// 	// 		res.data.moment = this.momentFormat(
		// 	// 			res.data.trial_date,
		// 	// 			res.data.lesson_time,
		// 	// 		)
		// 	// 		this.trial = res.data
		// 	// 		// this.$ga.event({
		// 	// 		//   eventCategory: 'trial-user',
		// 	// 		//   eventAction: 'reported',
		// 	// 		//   eventLabel: this.trial.id
		// 	// 		// })
		// 	// 		if (this.trial.status === 'appointed') {
		// 	// 			alert(
		// 	// 				'아직 수업이 분석되지 않았습니다. 분석이 완료되는대로 알림톡을 보내드리니 조금만 기다려주세요!',
		// 	// 			)
		// 	// 			this.$router.push({
		// 	// 				name: 'trial-list',
		// 	// 				query: { phone: this.trial.phone, kakaoId: this.trial.kakao_id },
		// 	// 			})
		// 	// 		}
		// 	// 		this.calcTimer()
		// 	// 	})
		// 	// 	.catch(() => {
		// 	// 		alert('체험 정보를 불러오지 못했습니다. 고객센터로 문의주세요.')
		// 	// 		this.$router.push({ name: 'trial-search' })
		// 	// 	})
		// 	// 	.finally(() => {
		// 	// 		this.isLoaded = true
		// 	// 	})
		// },
		numberWithCommas(number) {
			return numberWithCommas(number)
		},
		serachTrials() {
			this.isLoaded = false
		},
		calcTimer() {
			let expireDate, today, duration
			if (this.trial) {
				expireDate = moment
					.tz(this.trial.lesson_date, 'YYYY-MM-DD', 'Asia/Seoul')
					.add(8, 'days')
				today = moment.tz('Asia/Seoul')
				duration = moment.duration(expireDate.diff(today))
				this.timer = `${duration.get('days')}일 ${duration.get(
					'hours',
				)}시간 ${duration.get('minutes')}분 ${duration.get('seconds')}초`
				if (this.$route.name === 'TrialReport') {
					setTimeout(() => {
						this.calcTimer()
					}, 1000)
				}
			}
		},
		moveTo(to) {
			let tmp =
				this.userDevice === 'mobile' ? [420, 2770, 5750] : [425, 2805, 5455]
			this.$vuetify.goTo(tmp[to], this.scrollOptions)
		},
	},
	// mixins: [format],
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css?family=Nanum+Pen+Script');

.tab-btn {
	margin: 0 auto;
	/* transition: color 0.3s ease-in; */
	border-bottom: 2px solid #fff;
}
.tab-btn.active {
	color: #076cfa;
	border-color: #076cfa;
}
.tab-btn.active p {
	font-weight: 600 !important;
}
.opacity {
	opacity: 0.6;
}

.secret-promo-container {
	position: relative;
	background: url('https://image.tella.co.kr/v2/secret/foot-mobile.jpg')
		no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 500px;
}
.secret-promo-container2 {
	position: relative;
	background: url('https://image.tella.co.kr/v2/secret/main-mobile.jpg')
		no-repeat center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 600px;
}

@media screen and (min-width: 600px) {
	.secret-promo-container {
		height: 500px;
		background-image: url('https://image.tella.co.kr/v2/secret/foot.jpg');
	}
	.secret-promo-container2 {
		height: 600px;
		background-image: url('https://image.tella.co.kr/v2/secret/main.jpg');
	}
}

.promotion-code-box {
	display: inline-block;
	margin: 0 auto;
	border: 4px solid #00cca1;
	border-radius: 5px;
	background-color: #fff;
}

.nanum-pen {
	font-family: 'Nanum Pen Script', cursive;
	font-size: 1.7em;
}

/*로그*/
.bubble-wrap {
	width: 100%;
	padding: 10px 5px 10px 5px;
}

.bubble {
	position: relative;
	padding: 10px;
	max-width: 80%;
	border-radius: 5px;
}

.bubble-tutor {
	text-align: left;
	float: left;
	background-color: #fff;
}

.bubble-student {
	text-align: right;
	float: right;
	background-color: #f4c720;
}

.bubble-tutor .triangle {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.bubble-tutor .triangle::after {
	content: '';
	position: absolute;
	top: 10px;
	right: 0;
	border-color: transparent;
	border-style: solid;
	border-width: 5px;
	border-right-color: #fff;
	border-top-color: #fff;
}

.bubble-student .triangle {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 0;
}

.bubble-student .triangle::after {
	content: '';
	position: absolute;
	top: 10px;
	right: -10px;
	border-color: transparent;
	border-style: solid;
	border-width: 5px;
	border-left-color: #f4c720;
	border-top-color: #f4c720;
}

.program-comment-arrow-up {
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	margin: 0 auto;
	border-bottom: 10px solid #fdd835;
}

.program-tag-btn {
	transition: background-color 0.2s ease-in;
	border-radius: 5px;
}
.refund-container {
	position: relative;
	height: 550px;
}

@media screen and (min-width: 600px) {
	.refund-container {
		height: 650px;
	}
}

.desktop-width-limit {
	max-width: 750px;
	margin: 0 auto;
}

.py-50 {
	padding-top: 50px;
	padding-bottom: 50px;
}

.py-100 {
	padding-top: 100px;
	padding-bottom: 100px;
}
</style>
