<template>
	<div class="d-flex align-center">
		<v-img
			class="mr-2"
			max-width="22"
			src="@/assets/images/logo/kakao.png"
		></v-img>
		<p class="text-body-1 font-weight-bold">{{ $t('카카오톡') }}</p>
		<v-spacer></v-spacer>
		<v-switch
			@click="clickSwitch"
			v-model="isConnected"
			inset
			:disabled="isLoading"
		></v-switch>
	</div>
</template>

<script>
import Api from '@/services/index'
import maskingFunc from '@/helpers/masking'
import SlackMsg from '@/services/slack'

// import kakao from '@/services/kakao'

export default {
	data: () => ({
		kakaoLoginPass: false,
		isLoading: false,
		isConnected: false,
		existingMember: null,
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		email() {
			return this.$store.getters['member/email']
		},
		authKakao() {
			return this.$store.getters['member/authKakao']
		},
		kakaoToken() {
			return this.$store.getters['auth/kakaoToken']
		},
		kakaoMe() {
			return this.$store.getters['auth/kakaoMe']
		},
	},
	created() {
		this.setSwitch()
		// this.detectKakaoPopup()
	},
	methods: {
		// detectKakaoPopup() {
		// 	const orgOpen = window.open
		// 	let openkakao
		// 	const vm = this
		// 	window.open = function (...args) {
		// 		var getArgs = args
		// 		if (getArgs[0].indexOf('kakao.com') >= 0) {
		// 			openkakao = orgOpen(...args)
		// 			var timer = setInterval(function () {
		// 				if (openkakao.closed) {
		// 					if (vm.kakaoLoginPass === false && vm.isLoading === true) {
		// 						alert(vm.$t('카카오 로그인을 취소하셨습니다.'))
		// 						vm.isLoading = false
		// 						vm.isConnected = false
		// 					}
		// 					if (
		// 						document.querySelector('html').style.overflow == 'hidden' ||
		// 						document.querySelector('body').style.overflow == 'hidden'
		// 					) {
		// 						document.querySelector('html').removeAttribute('style')
		// 						document.querySelector('body').removeAttribute('style')
		// 					} else {
		// 						clearInterval(timer)
		// 					}
		// 				}
		// 			}, 1000)
		// 		} else {
		// 			return orgOpen(...args)
		// 		}
		// 	}
		// },
		async clickSwitch() {
			try {
				if (this.isConnected === true) {
					this.isLoading = true
					if (this.kakaoToken === null) {
						await this.$store.dispatch('auth/getKakaoToken')
					}

					this.kakaoLoginPass = true
					await this.$store.dispatch('auth/getKakaoMe')
					const res = await Api.get(`auth/kakao/${this.kakaoMe.authKakaoId}`, {
						headers: { 'x-kakao-token': this.kakaoToken },
					})
					this.existingMember = res.data

					let forcedConfirm
					if (this.existingMember.memberId) {
						if (this.existingMember.email === this.email) {
							alert(
								this.$t(
									'해당 이메일로 이미 카카오 로그인이 등록되어 있습니다.',
								),
							)
							return
						}
						// 멤버아이디가 있다. 강제할지여부
						forcedConfirm = this.confirmReconnect()
						if (forcedConfirm === false) {
							this.isConnected = false
							return
						}
					}
					if (forcedConfirm === true || !this.existingMember.memberId) {
						// 기존 멤버아이디가 없거나 있더라도 강제 컨펌을 받은 경우
						await this.connect()
						this.$emit('connect')
					}
				}
				if (this.isConnected === false) {
					const deleteConfirm = confirm(
						this.$t('정말로 카카오 로그인을 해제하시겠습니까?'),
					)
					if (deleteConfirm === true) {
						let params = {
							authKakaoId: null,
						}
						await Api.patch(`members/${this.memberId}`, params)
						alert(this.$t('카카오 연동이 해제되었습니다.'))
					}
				}
			} catch (err) {
				SlackMsg.post('', {
					text: `NAME: ${err.name}, Message: ${
						err.message
					}, STRING: ${JSON.stringify(err).substring(0, 1000)},`,
				})
				// SlackMsg.post('', { text: JSON.stringify(err.stack.substring(0, 500)) })
				let msg = this.$t(
					'카카오 연동/해제에 실패하였습니다. 고객센터로 문의주세요.\nERROR CODE: KN91EA',
				)
				if (err.name === 'kakaoLoginError')
					msg = this.$t('카카오 로그인을 취소하셨습니다.')
				if (err.name === 'kakaoGetMeError')
					msg = this.$t('카카오에서 정보를 불러오지 못했습니다.')
				if (err.name === '10006')
					msg = this.$t(
						'유효하지 않은 카카오 토큰이 사용되었습니다. 카카오 재로그인 후 다시 한번 시도해주세요.',
					)
				alert(msg)
			} finally {
				this.isLoading = false
			}
		},

		confirmReconnect() {
			const maskingEmail = maskingFunc.email(this.existingMember.email)
			const msg = this.$t(
				'해당 카톡 로그인이 연결되어 있던 기존의 텔라 아이디(%{maskingEmail})가 있습니다. 이의 연결을 끊고 지금의 아이디(%{email})로 카톡 로그인을 하시겠습니까?',
				{ maskingEmail, email: this.email },
			)
			const forcedConfirm = confirm(msg)

			return forcedConfirm
		},
		async connect() {
			try {
				await Api.patch(
					`auth/kakao/${this.kakaoMe.authKakaoId}`,
					{ memberId: this.memberId },
					{ headers: { 'x-kakao-token': this.kakaoToken } },
				)
				alert(this.$t('카카오 소셜 로그인 연동이 완료되었습니다.'))
			} catch (err) {
				const e = new Error(err)
				e.name = err.response.data.code
					? err.response.data.code
					: err.response.data.status
				throw e
			}
		},
		setSwitch() {
			this.isConnected = this.authKakao
		},
		goBack() {
			this.$router.push({ name: 'Setting' })
		},
	},
}
</script>

<style lang="scss" scoped></style>
