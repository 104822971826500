<template>
	<section class="white">
		<v-row no-gutters class="text-center tab-wrap">
			<v-col cols="4">
				<p
					@click="gtagClick('수강권'), goTo('Plans')"
					:class="{
						'primary--text': routeName === 'Plans',
						'gre--text text--darken-3': routeName !== 'Plans',
					}"
					class="
						d-inline-block
						text-center text-body-1
						font-weight-bold
						py-1
						menu
					"
				>
					{{ $t('plan.menu.plan') }}
				</p>
			</v-col>
			<v-col cols="4" @click="gtagClick('수강후기'), goTo('Review')">
				<p
					:class="{
						'primary--text': routeName === 'Review',
						'gre--text text--darken-3': routeName !== 'Review',
					}"
					class="
						d-inline-block
						text-center text-body-1
						font-weight-bold
						py-1
						menu
					"
				>
					{{ $t('plan.menu.review') }}
				</p>
			</v-col>
			<v-col cols="4" @click="gtagClick('이벤트'), goTo('Event')">
				<p
					:class="{
						'primary--text': routeName === 'Event',
						'gre--text text--darken-3': routeName !== 'Event',
					}"
					class="
						d-inline-block
						text-center text-body-1
						font-weight-bold
						py-1
						menu
					"
				>
					{{ $t('plan.menu.event') }}
				</p>
			</v-col>
		</v-row>
		<Dialog
			:dialogControl="dialog"
			:numberOfButton="1"
			:title="$t('아직 준비 중입니다^^ 조금만 기다려주세요.')"
			:actionColor="'grey lighten-1'"
			:action="action"
			:actionText="$t('modal.button.confirm')"
		/>
	</section>
</template>

<script>
import Dialog from '@/components/common/Dialog'

export default {
	components: {
		Dialog,
	},
	data() {
		return {
			dialog: false,
			action: this.closeDialog,
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		routeName() {
			return this.$route.name
		},
	},
	methods: {
		gtagClick(category) {
			// eslint-disable-next-line no-undef
			gtag('event', 'click_2home_plan_menu', {
				event_path: `my.tella.co.kr${this.$route.path}`,
				event_category: category,
			})
		},
		goTo(routeName) {
			if (this.language === 'ja' && routeName === 'Review') {
				this.dialog = !this.dialog
				return
			}
			if (this.language === 'ja' && routeName === 'Event') {
				this.dialog = !this.dialog
				return
			}

			this.$router
				.push({
					name: routeName,
				})
				.catch(() => {})
		},
		closeDialog() {
			this.dialog = !this.dialog
		},
	},
}
</script>

<style lang="scss" scoped>
.tab-wrap {
	border-bottom: 1px solid #f2f3f5;
	.primary--text {
		border-bottom: 2px solid #0074ff;
	}
}
.menu:hover {
	cursor: default;
}
</style>
