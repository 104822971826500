<template>
	<div v-if="isLoading === false" class="landing-wrap px-3">
		<v-btn
			color="white"
			fab
			icon
			small
			style="position: absolute; left: 0; top: 0"
			@click="$router.push({ name: 'Home' })"
			><v-icon>mdi-window-close</v-icon></v-btn
		>
		<div class="text-center white--text" style="position: relative; z-index: 1">
			<img class="mb-3" src="@/assets/icons/checkbox-circle-white.png" alt="" />

			<h1 class="mb-4 text-h2 font-weight-bold">
				{{ $t('order-landing.title.paid-complete') }}
			</h1>
			<h2 class="mb-6 text-body-1 font-weight-medium white-space-pre">
				{{ $t('order-landing.text.with-tella', { name }) }}
			</h2>
			<div class="info-box black--text text-left mb-9">
				<div class="d-flex align-start mb-1">
					<div class="payment-title text-body-1">
						{{ $t('order-landing.title.plan-name') }}
					</div>
					<div class="payment-info text-body-1">{{ payment.pay_name }}</div>
				</div>
				<div class="d-flex align-center mb-1">
					<div class="payment-title text-body-1">
						{{ $t('order-landing.title.start-date') }}
					</div>
					<div class="payment-info text-body-1 primary--text font-weight-bold">
						{{ payment.start_date }}
						<span v-if="leftDay > 0"
							>,
							<span
								v-html="$t('order-landing.text.remaining-dates', { leftDay })"
							></span
						></span>
					</div>
				</div>
				<div class="d-flex align-center mb-1">
					<div class="payment-title text-body-1">
						{{ $t('order-landing.title.expiration-date') }}
					</div>
					<div class="payment-info text-body-1">
						{{ payment.start_date }} ~ {{ expiryDate }}
					</div>
				</div>
				<div class="d-flex align-center mb-1">
					<div class="payment-title text-body-1">
						{{ $t('order-landing.title.price') }}
					</div>
					<div class="payment-info text-body-1">
						{{ $numberWithCommas(payment.pay_price) }} {{ payment.currency }}
					</div>
				</div>
				<v-divider class="my-3 grey lighten-1"></v-divider>
				<div
					class="d-flex align-center mb-1"
					v-if="payment && payment.status === 'paid'"
				>
					<div class="payment-title text-body-1">
						{{ $t('order-landing.title.pay-date') }}
					</div>
					<div class="payment-info text-body-1">{{ payment.pay_date }}</div>
				</div>
				<div
					class="d-flex align-start mb-1"
					v-if="payment.pay_method === 'vbank'"
				>
					<div class="payment-title text-body-1">
						{{ $t('order-landing.title.account-number') }}
					</div>
					<div class="payment-info text-body-1">
						{{ payment.vbank_num }}({{ payment.vbank_name }})<br />
						{{ $t('order-landing.title.account-name') }}:
						{{ payment.vbank_holder }}
					</div>
				</div>
				<div class="d-flex align-center mb-1">
					<div class="payment-title text-body-1">
						{{ $t('order-landing.title.pay-method') }}
					</div>
					<div class="payment-info text-body-1">{{ payMethod }}</div>
				</div>
				<v-divider class="my-3 grey lighten-1"></v-divider>
				<div class="d-flex align-start" v-if="payment.pay_method === 'vbank'">
					<img class="mr-1" src="@/assets/icons/info-circle.png" alt="" />
					<div
						class="text-body-2"
						v-html="$t('order-landing.text.vbank-info', { today })"
					></div>
				</div>
				<div class="d-flex align-start">
					<img class="mr-1" src="@/assets/icons/info-circle.png" alt="" />
					<div class="text-body-2">
						{{ $t('order-landing.text.receipt-info') }}
					</div>
				</div>
			</div>
			<h2
				class="mb-5 text-h2 font-weight-bold"
				v-if="payMethod !== '무통장입금'"
			>
				{{ $t('order-landing.title.book-now') }}
			</h2>
			<div class="text-center">
				<v-btn
					large
					class="book-button mb-12"
					v-if="payment.pay_method === 'vbank'"
					@click="$router.push({ name: 'Home' })"
					><div class="button-text font-weight-bold text-body-1">
						{{ $t('order-landing.button.go-to-home') }}
					</div>
				</v-btn>
				<v-btn
					v-else
					large
					class="book-button mb-12"
					@click="$router.push({ name: 'Home' })"
				>
					<div class="button-text font-weight-bold text-body-1">
						<!-- {{ $t('order-landing.button.book') }} -->
						{{ $t('order-landing.button.go-to-home') }}
					</div>
				</v-btn>
			</div>
		</div>
		<Dialog
			:paymentId="paymentId"
			:dialogControl="dialog"
			@dialogControl="dialog = !dialog"
			:startDate="payment.start_date"
		/>
		<v-img
			style="position: absolute; right: 0; bottom: 0; z-index: 0"
			width="200"
			src="@/assets/images/orders/back-component.png"
		></v-img>
	</div>
</template>

<script>
import Api from '@/services/index'
import moment from 'moment-timezone'
import Dialog from './Dialog'

export default {
	components: {
		Dialog,
	},
	data() {
		return {
			isLoading: false,
			payment: null,
			dialog: false,
		}
	},
	computed: {
		paymentId() {
			return this.$route.params.paymentId
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		name() {
			return this.$store.getters['member/nameKr']
		},
		leftDay() {
			return this.payment
				? moment(this.payment.start_date).diff(moment(), 'days')
				: 0
		},
		expiryDate() {
			return moment(this.payment.start_date)
				.add(30 * this.payment.duration - 1, 'days')
				.format('YYYY-MM-DD')
		},
		payMethod() {
			return this.payment.pg_provider === 'html5_inicis' &&
				this.payment.pay_method === 'card'
				? this.$t('order-landing.text.card')
				: this.payment.pay_method === 'vbank'
				? this.$t('order-landing.text.vbank')
				: this.payment.pg_provider === 'html5_inicis' &&
				  this.payment.pay_method === 'point'
				? this.$t('order-landing.text.point')
				: this.payment.pg_provider === 'html5_inicis' &&
				  this.payment.pay_method === 'trans'
				? this.$t('order-landing.text.trans')
				: this.payment.pg_provider === 'kakaopay'
				? this.$t('order-landing.text.kakaopay')
				: this.payment.pg_provider === 'paypal'
				? this.$t('order-landing.text.paypal')
				: this.$t('order-landing.text.card')
		},
		today() {
			return moment().format('YYYY-MM-DD')
		},
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			try {
				this.isLoading = true
				const res = await Promise.all([
					Api.get(`/payments?id=${this.paymentId}&member-id=${this.memberId}`),
					this.$store.dispatch('point/get'),
				])
				if (res[0].data.length === 0) throw new Error('invalid payment id')
				this.payment = res[0].data[0]
				this.isLoading = false
			} catch (err) {
				alert(this.$t('order-landing.error-msg.cant-find-error-msg'))
			}
		},
		async goToBook() {
			const startDate = this.payment.start_date
			// 지금 결제한 수강권 시작날이 오늘이 아니면서 현재 사용가능한 수강권이 없는 경우
			if (
				startDate !== moment.tz('Asia/Seoul').format('YYYY-MM-DD') &&
				this.$store.getters['point/availablePoints'].length === 0 &&
				[106, 107, 108].includes(this.payment.plan_id) === false
			) {
				this.dialog = true
				return
			}
			this.$router.push({ name: 'Home' })
			setTimeout(() => {
				this.$store.commit('device/BOOK_ACTION_SHEET_CONTROL')
			}, 500)
		},
	},
}
</script>

<style lang="scss" scoped>
.landing-wrap {
	height: calc(var(--vh, 1vh) * 100);
	background: linear-gradient(to left, #088fe9, #08afd1);
	padding-top: 55px;
}
.info-box {
	padding: 30px 25px;
	border-radius: 20px;
	background-color: rgba(255, 255, 255, 0.95);
}
.payment-title {
	width: 90px;
}
.payment-info {
	padding-left: 15px;
	width: calc(100% - 105px);
}
.book-button {
	margin: 0 auto;
	width: 219px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 10px;
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.2);
	background-color: #fff;

	.button-text {
		padding-top: 6px;

		background-image: linear-gradient(to right, #0786ef 4%, #08b9c9 100%);
		-webkit-background-clip: text;
		background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}
</style>
