<template>
	<div>
		<v-form ref="form" @submit.prevent="search">
			<section class="py-2 d-flex align-center">
				<v-btn fab icon text small @click="goBack()">
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>
				<v-text-field
					dense
					hide-details
					single-line
					solo
					flat
					:placeholder="$t('프로그램 이름을 검색하세요.')"
					class="mx-1 rounded-lg"
					background-color="grey lighten-3"
					clearable
					v-model="keyword"
				></v-text-field>
				<v-btn fab icon text small @click="search()">
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
			</section>
		</v-form>
		<section
			v-if="filteredPrograms && filteredPrograms.length > 0"
			class="px-5 py-3"
			style="overflow-y: auto; height: calc(100vh - 60px)"
		>
			<ProgramCard
				v-for="program in filteredPrograms"
				:key="program.id"
				:selectedProgramId="null"
				:program="program"
				@selectProgram="selectProgram"
				class="mb-3"
			/>
		</section>
		<section
			v-else-if="filteredPrograms && filteredPrograms.length === 0"
			class="px-5 py-3 text-center"
		>
			👀
			<h1 class="text-body-1 grey--text text--darken-2">
				{{ $t('검색 결과가 없습니다.') }}
			</h1>

			<p class="text-body-2 grey--text text--darken-2">
				{{ $t('제안하고 싶은 프로그램이 있다면 1:1 문의를 이용해주세요.') }}
			</p>
		</section>
		<UnitPicker
			:lesson="lesson"
			@selectUnit="selectUnit"
			:selectedProgram="selectedProgram"
		/>
	</div>
</template>

<script>
import UnitPicker from '../common/UnitPicker'
import ProgramCard from '../common/ProgramCard'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions, mapMutations } =
	createNamespacedHelpers('program')
const R = require('ramda')

export default {
	components: {
		ProgramCard,
		UnitPicker,
	},
	data: () => ({
		keyword: null,
		filteredPrograms: null,
		selectedProgram: null,
	}),
	computed: {
		...mapGetters(['programsFilteredType', 'programsInCategory', 'progresses']),
		lessonId() {
			return Number(this.$route.params.lessonId)
		},
		lesson() {
			return R.find(R.propEq('id', this.lessonId))(
				this.$store.getters['appointed/lessons'],
			)
		},
	},
	created() {
		this.getAll()
	},
	methods: {
		...mapMutations(['STORE_LESSON_TYPE']),
		...mapActions(['get', 'getProgresses']),
		async getAll() {
			this.isLoading = true
			let promises = []
			if (this.progresses.length === 0) promises.push(this.getProgresses())
			if (this.programsFilteredType.length === 0) promises.push(this.get())
			if (promises.length > 0) await Promise.all(promises)

			this.STORE_LESSON_TYPE(this.lesson.lesson_type)

			this.isLoading = false
		},
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			beforeName !== 'LessonProgramSelect'
				? this.$router.push({
						name: 'LessonProgramSelect',
						params: { lessonId: this.lessonId },
				  })
				: this.$router.go(-1)
		},
		search() {
			if (!this.keyword || this.keyword.length === 0) {
				alert(this.$t('검색하실 프로그램 이름을 입력해주세요.'))
				return
			}

			this.filteredPrograms = R.filter(
				x => this.keyword.length > 0 && x.name.includes(this.keyword),
			)(this.programsFilteredType)
		},
		selectProgram(program) {
			this.selectedProgram = program
		},
		selectUnit(unit) {
			if (unit === null) {
				this.selectedProgram = null
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
