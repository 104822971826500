import Api from './index'
import { makeDatetime } from '@/helpers/datetime'

const baseUrl = '/schedule'

export default {
	async getPoints() {
		try {
			const res = await Api.get('schedule/points')
			return res.data
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)
		}
	},
	async getCount({ status, lessonType, programId }) {
		let query = ''
		if (status) query += `&status=${status}`
		if (lessonType === 0 || lessonType === 1)
			query += `&lesson-type=${lessonType}`
		if (programId) query += `&program-id=${programId}`

		try {
			const res = await Api.get(`${baseUrl}/lessons/count?${query.slice(1)}`)
			return res.data
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)
		}
	},
	async getLessons({
		gteLessonDatetime,
		lteLessonDatetime,
		status,
		page,
		limit,
		lessonType,
		programId,
	}) {
		try {
			let query = ''
			if (status) query += `&status=${status}`
			if (gteLessonDatetime)
				query += `&gte-lesson-datetime=${encodeURIComponent(gteLessonDatetime)}`
			if (lteLessonDatetime)
				query += `&lte-lesson-datetime=${encodeURIComponent(lteLessonDatetime)}`
			if (page) query += `&page=${page}`
			if (limit) query += `&limit=${limit}`
			if (lessonType === 0 || lessonType === 1)
				query += `&lesson-type=${lessonType}`
			if (programId) query += `&program-id=${programId}`

			const res = await Api.get(`${baseUrl}/lessons?${query.slice(1)}`)
			const lessons = res.data.map(l => {
				const time = l.lessonType === 1 ? 15 : 25
				l.unitTitle = l.unitTitle ? JSON.parse(l.unitTitle) : null
				l.dt = makeDatetime(l.lessonDate, l.lessonTime)
				l.endDt = l.dt.plus({ minutes: time })
				return l
			})
			return lessons
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)
		}
	},
	async findLesson(lessonId) {
		try {
			const res = await Api.get(`${baseUrl}/lessons/${lessonId}`)
			const lesson = res.data

			lesson.unitTitle = lesson.unitTitle ? JSON.parse(lesson.unitTitle) : null
			lesson.dt = makeDatetime(lesson.lessonDate, lesson.lessonTime)

			return lesson
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)
			throw new Error(err)
		}
	},
	async getRatings({ lessonIds }) {
		try {
			let query = ''
			if (lessonIds) query += `&lesson-ids=${lessonIds}`

			const res = await Api.get(`${baseUrl}/lessons/ratings?${query.slice(1)}`)

			return res.data
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)
		}
	},
	async getTrialInformation(lessonId) {
		try {
			const res = await Api.get(
				`${baseUrl}/lessons/trial?lesson-id=${lessonId}`,
			)

			return res.data
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)
		}
	},
	async cancelLesson(lessonId) {
		try {
			await Api.patch(`${baseUrl}/lessons/cancel/${lessonId}`)
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)
		}
	},
	async getTimeTable(startISO, endISO, lessonType, tutorId) {
		try {
			let query = `${baseUrl}/slots/time-table?start-iso=${encodeURIComponent(
				startISO,
			)}&end-iso=${encodeURIComponent(endISO)}&lesson-type=${lessonType}`
			if (tutorId) query += `&tutor-id=${tutorId}`
			const res = await Api.get(query)
			return res.data
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)
		}
	},
	async getBookableTutor(lessonType, { lessonDate, lessonTime }) {
		try {
			let query = `${baseUrl}/slots/tutor?lesson-type=${lessonType}`
			if (lessonDate) query += `&lesson-date=${lessonDate}`
			if (lessonTime) query += `&lesson-time=${lessonTime}`
			const res = await Api.get(query)
			return res.data
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)
		}
	},
	async book({
		memberId,
		lessonType,
		tutorId,
		datetime,
		programUnitId,
		programId,
	}) {
		try {
			const res = await Api.post(`lessons`, {
				memberId,
				lessonType,
				tutorId,
				datetime,
				programUnitId,
				programId,
			})
			return res.data
		} catch (err) {
			console.log('errrrrrrrr')
			console.log(err)
		}
	},
}
