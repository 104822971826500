<template>
	<v-btn
		color="grey darken-3"
		rounded
		depressed
		height="32"
		data-test="reportButton"
		@click="reportButton"
	>
		<span class="white--text text--darken-3 font-13 font-weight-bold">
			{{ $t('component.lessonCard.button.레벨 진단 리포트') }}
		</span>
	</v-btn>
</template>

<script>
import scheduleApi from '@/services/schedule'

export default {
	props: {
		lesson: {
			type: Object,
		},
	},
	methods: {
		async reportButton() {
			const trial = await scheduleApi.getTrialInformation(this.lesson.id)
			this.$router.push({
				name: 'TrialReport',
				params: { trialId: trial.id },
				query: {
					'country-code': trial.countryCode,
					phone: trial.phone,
					'in-lesson': true,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
