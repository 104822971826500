<template>
	<div>
		<div class="mb-3 d-flex align-center">
			<h1 class="my-title font-18">
				{{ $t('page.home.bookLesson.title.수업 예약하기') }}
			</h1>

			<v-spacer></v-spacer>
			<h2
				class="my-text font-14 hover-pointer"
				@click="$router.push({ name: 'MyPage' })"
			>
				{{ $t('page.home.bookLesson.button.나의 수강권') }}
				<img
					src="@/assets/images/icon/chevron-right-light.png"
					style="width: 15px; margin-top: -3px; vertical-align: middle"
					alt=""
				/>
			</h2>
		</div>
		<v-row no-gutters class="pb-2">
			<v-col cols="6" class="pr-1">
				<BookButton
					class="hover-pointer"
					:availablePoints="availablePoints"
					:isLoading="isLoading"
					:type="0"
					ref="talkBookButton"
					@noPlan="noPlan"
					@startBook="startBook"
				/>
			</v-col>
			<v-col cols="6" class="pl-1">
				<BookButton
					class="hover-pointer"
					ref="callBookButton"
					:availablePoints="availablePoints"
					:isLoading="isLoading"
					:type="1"
					@noPlan="noPlan"
					@startBook="startBook"
				/>
			</v-col>
		</v-row>
		<v-btn
			height="60"
			block
			class="pass-button"
			v-if="isLoading === false && haveAvailablePoints === false"
			@click="$router.push({ name: 'Plans', params: { category: 'package' } })"
			><img
				src="@/assets/images/design/ticket-white.png"
				alt=""
				style="margin-top: 3px; margin-right: 9px"
			/>
			<span class="font-15 my-title">
				{{ $t('page.home.bookLesson.button.수강권 구매하기') }}</span
			></v-btn
		>

		<v-dialog v-model="noPlanDialog" width="310">
			<v-card data-test="warningAlert" class="rounded-lg">
				<div class="px-4 py-5">
					<h1 class="my-title font-19 mb-5">
						{{ $t('page.home.bookLesson.수강신청 후 이용하세요.') }}
					</h1>
					<p class="my-text font-14 mb-5">
						{{
							$t(
								'page.home.bookLesson.text.%{name}님을 위한 할인 혜택이 준비되어 있어요. 편하게 수강권을 둘러보세요!',
								{ name },
							)
						}}
					</p>
					<v-row no-gutters>
						<v-col cols="6" class="pr-1">
							<v-btn
								depressed
								block
								color="primary--text"
								style="background-color: #f2f9ff"
								@click="noPlanDialog = false"
								>{{ $t('page.home.bookLesson.button.취소') }}</v-btn
							>
						</v-col>
						<v-col cols="6" class="pl-1">
							<v-btn
								@click="$router.push({ name: 'Plans' })"
								depressed
								block
								color="primary"
								>{{ $t('page.home.bookLesson.button.수강권 둘러보기') }}</v-btn
							>
						</v-col>
					</v-row>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog v-model="lessonTypeDialog" width="310">
			<v-card data-test="lessonTypeDialog" class="grey lighten-1 rounded-lg">
				<div class="px-4 py-5">
					<h1 class="my-title font-19 mb-5">
						{{ $t('page.home.bookLesson.title.예약 방식을 선택하세요.') }}
					</h1>

					<v-row no-gutters class="text-center">
						<v-col cols="6" class="pr-1">
							<v-sheet
								data-test="tutor-first-button"
								class="px-1 py-6"
								style="border-radius: 12px"
								@click="selectBookType('tutor')"
							>
								<img
									class="first-img mx-auto"
									src="@/assets/images/home/tutor-first.png"
									alt=""
								/>
								<h2 class="mt-1 font-16 font-weight-bold">
									{{ $t('page.home.bookLesson.button.튜터 먼저 선택')
									}}<v-icon class="mr-n2" style="margin-top: -3px"
										>mdi-chevron-right</v-icon
									>
								</h2>
							</v-sheet>
						</v-col>
						<v-col cols="6" class="pl-1">
							<v-sheet
								data-test="time-first-button"
								class="px-1 py-6"
								style="border-radius: 12px"
								@click="selectBookType('time')"
								><img
									class="first-img mx-auto"
									src="@/assets/images/home/time-first.png"
									alt=""
								/>
								<h2 class="mt-1 font-16 font-weight-bold">
									{{ $t('page.home.bookLesson.button.시간 먼저 선택')
									}}<v-icon class="mr-n2" style="margin-top: -3px"
										>mdi-chevron-right</v-icon
									>
								</h2>
							</v-sheet>
						</v-col>
					</v-row>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { DateTime } from 'luxon'
import BookButton from './BookButton'
import scheduleApi from '@/services/schedule'
import EventBus from '@/lib/eventBus'

export default {
	components: {
		BookButton,
	},
	data: () => ({
		isLoading: false,
		points: [],
		noPlanDialog: false,
		lessonTypeDialog: false,
		lessonType: null,
	}),
	computed: {
		name() {
			return this.$store.getters['member/nameKr']
		},
		availablePoints() {
			const isAvailable = p => {
				const KSTDT = DateTime.local().setZone('Asia/Seoul')
				const KSTDate = KSTDT.toFormat('y-LL-dd')

				return (
					p.startDate <= KSTDate &&
					p.expiryDate >= KSTDate &&
					p.active === 1 &&
					p.remainingPoints >= 1
				)
			}
			return this.points.filter(isAvailable)
		},
		haveAvailablePoints() {
			return this.availablePoints.length > 0 ? true : false
		},
	},
	created() {
		EventBus.$on('lessonIsCancelled', this.getPoints)
		this.getPoints()
	},
	beforeDestroy() {
		EventBus.$off('lessonIsCancelled', this.getPoints)
	},
	methods: {
		noPlan() {
			this.noPlanDialog = true
		},
		startBook(lessonType) {
			this.lessonType = lessonType
			this.lessonTypeDialog = true
		},
		selectBookType(bookType) {
			const name = bookType === 'tutor' ? 'TutorFirst' : 'TimeFirstStep1'
			this.$router.push({ name, params: { typeEn: this.lessonType } })
		},
		async getPoints() {
			this.isLoading = true
			this.points = await scheduleApi.getPoints()
			this.isLoading = false
		},
	},
}
</script>

<style lang="scss" scoped>
.pass-button {
	background: linear-gradient(102.03deg, #684af0 19.04%, #6e6bff 80.79%);
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	color: #fff;
}
.first-img {
	width: 40px;
	height: 40px;
}
</style>
