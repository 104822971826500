<template>
	<v-card class="program-card">
		<v-img
			class="program-image"
			:src="`https://image.tella.co.kr/v1/program/thumbnail/portrait/${language}/${id}.jpg`"
		>
		</v-img>
		<div class="pt-4 px-3">
			<p class="program-name">{{ program.name }}</p>
			<p class="program-description">{{ program.description }}</p>
		</div>
	</v-card>
</template>

<script>
const R = require('ramda')

export default {
	props: {
		id: {
			required: true,
			type: Number,
		},
	},
	computed: {
		program() {
			return R.find(R.propEq('id', this.id))(
				this.$store.getters['program/programs'],
			)
		},
		language() {
			return this.$store.getters['member/language']
		},
	},
}
</script>

<style lang="scss" scoped>
.program-card {
	width: 181px;
	padding: 0 0 20px;
	border-radius: 16px;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1) !important;
}
.program-image {
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}
.program-name {
	font-size: 15px !important;
	font-weight: bold !important;
	line-height: 1.4 !important;
	letter-spacing: -0.47px !important;
	color: #191f28 !important;
}
.program-description {
	font-size: 12px !important;
	font-weight: normal !important;
	line-height: 1.5 !important;
	letter-spacing: -0.38px !important;
	color: #485363 !important;
}
</style>
