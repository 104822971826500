<template>
	<v-container class="pa-0 mt-2 mt-sm-12" style="max-width: 700px">
		<v-card rounded="xl" class="pa-sm-8 pa-3 elevation-card">
			<div class="d-flex justify-center">
				<v-img
					class="mb-8"
					max-width="120"
					src="@/assets/images/logo/default.png"
				></v-img>
			</div>

			<div class="mb-8">
				<p class="font-weight-bold text-body-1">{{ $t('이용약관') }}</p>
				<div
					style="max-height: 120px; overflow-y: auto; border: 1px solid #dadada"
					class="pa-3 mt-1"
				>
					<Policy />
				</div>
			</div>
			<div class="mb-8">
				<p class="font-weight-bold text-body-1">
					{{ $t('개인정보 처리방침') }}
				</p>

				<div
					style="max-height: 120px; overflow-y: auto; border: 1px solid #dadada"
					class="pa-3 mt-1"
				>
					<Privacy />
				</div>
			</div>
		</v-card>
	</v-container>
</template>

<script>
import Policy from '@/components/common/Policy'
import Privacy from '@/components/common/Privacy'

export default {
	components: {
		Policy,
		Privacy,
	},
}
</script>

<style lang="scss" scoped></style>
