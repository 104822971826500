<template>
	<div>
		<v-btn
			v-if="quest"
			class="align-center"
			depressed
			rounded
			height="45"
			:disabled="isAchievableQuest === false"
			color="primary"
			:loading="isLoading"
			@click="acheiveQuest"
		>
			<img
				class="mr-2"
				style="vertical-align: middle"
				:src="
					require(`@/assets/icons/coin${
						isAchievableQuest === false ? '-disabled' : ''
					}.png`)
				"
				alt=""
			/>
			{{ quest.reward_amount }} {{ $t('캐시 받기') }}
		</v-btn>
		<v-dialog
			@click:outside="checkLater()"
			persistent
			v-model="dialog"
			width="350"
			content-class="rounded-xl"
		>
			<v-card rounded="xl" class="pt-5">
				<v-card-text>
					<h1
						class="text-rlh text-h6 mb-3 font-weight-bold"
						v-html="
							$t(
								'500 캐시가 적립되었습니다 🙌<br />적립 내역을 확인하시겠어요?',
							)
						"
					></h1>
					<p
						class="text-body-2 grey--text text--darken-1"
						v-html="
							$t(
								'[ 마이페이지 > 적립금 ]에서<br/>지금까지의 캐시 적립 내역을 확인하실 수 있어요.',
							)
						"
					></p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-btn
						color="grey"
						text
						:disabled="isLoading"
						@click="dialog = false"
					>
						{{ $t('나중에 할게요') }}
					</v-btn>
					<v-spacer></v-spacer>

					<v-btn
						color="primary"
						text
						@click="$router.push({ name: 'Cash' })"
						:loading="isLoading"
					>
						{{ $t('적립 내역 확인하기') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Api from '@/services/index'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('quest')

export default {
	props: {
		quest: {
			required: true,
		},
		lessonCount: {
			required: true,
		},
	},
	data: () => ({
		dialog: false,
		isLoading: false,
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		isAchievableQuest() {
			return this.quest &&
				this.quest.goal !== 0 &&
				typeof this.quest.goal === 'number' &&
				this.lessonCount / this.quest.goal >= 1 &&
				!this.quest.acheivements
				? true
				: false
		},
	},
	methods: {
		...mapActions(['getGroups']),
		checkLater() {
			this.dialog = false
			this.$emit('achieve')
		},
		async acheiveQuest() {
			try {
				this.isLoading = true

				await Api.post(`quests/achievements`, {
					questId: this.quest.id,
					memberId: this.memberId,
				})

				this.dialog = true
			} catch (err) {
				const errCode = err.response.data.code
				const errMsg = err.response.data.message
				let alertMsg = this.$t(
					`에러가 발생했습니다.\n\nERROR CODE: %{errCode}\nERROR MESSAGE: %{errMsg}`,
					{ errCode, errMsg },
				)

				if (errCode === '30000')
					alertMsg = this.$t('이미 달성하여 보상을 받은 퀘스트입니다.')

				if (errCode === '30002')
					alertMsg = this.$t(
						'아직 퀘스트 달성을 위한 수업 갯수를 채우지 못했습니다.',
					)

				alert(alertMsg)
			} finally {
				this.isLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
