<template>
	<div>
		<v-dialog v-model="dialog" width="350" content-class="rounded-xl">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					small
					dark
					rounded
					depressed
					color="grey darken-3"
				>
					{{ $t('코드 등록') }}
				</v-btn>
			</template>

			<v-card rounded="xl">
				<v-form ref="form" @submit.prevent="register">
					<section class="px-5 pt-5 pb-12">
						<p class="text-body-1 mb-3">
							{{ $t('할인 쿠폰 코드를 입력해주세요.') }}
						</p>
						<v-text-field
							v-model="couponNumber"
							type="text"
							class="mb-3"
							solo
							flat
							hide-details
							background-color="grey lighten-3"
							:placeholder="$t('코드 입력')"
						></v-text-field>
					</section>
					<v-divider></v-divider>
					<div class="d-flex align-center px-5 py-3">
						<v-btn
							:disabled="isLoading"
							text
							color="black"
							@click="dialog = false"
							>{{ $t('취소') }}</v-btn
						>
						<v-spacer></v-spacer>
						<v-btn
							:loading="isLoading"
							text
							color="primary"
							type="submit"
							@click.native="loader = 'loading'"
							>{{ $t('등록') }}</v-btn
						>
					</div>
				</v-form>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	data: () => ({
		isLoading: false,
		dialog: false,
		couponNumber: null,
	}),
	watch: {
		dialog() {
			this.couponNumber = null
		},
	},
	methods: {
		async register() {
			try {
				this.isLoading = true

				await Api.post(`coupons/${encodeURIComponent(this.couponNumber)}`)
				alert('쿠폰이 등록되었습니다.')
				this.$emit('registered')
				this.dialog = false
				this.couponNumber = null
			} catch (err) {
				let errMsg = this.$t(
					'알 수 없는 에러가 발생했습니다. 고객센터로 문의주세요.\nERROR CODE: BV910S',
				)
				if (err.response.status === 400) {
					const code = err.response.data.code.toString()
					if (code === '70000')
						errMsg = this.$t(
							'유효한 쿠폰 번호가 아닙니다. 쿠폰 번호를 확인하세요.',
						)
					if (code === '70001') errMsg = this.$t('이미 등록된 쿠폰입니다.')
					if (code === '70002') errMsg = this.$t('이미 만료된 쿠폰입니다.')
					if (code === '70003')
						errMsg = this.$t('아직 사용기간이 시작되지 않은 쿠폰입니다.')
					if (code === '70004')
						// errMsg = this.$t('이미 체험수업 쿠폰을 발급 받으셨습니다.')
						errMsg = this.$t('이미 등록된 쿠폰입니다.')
					if (code === '70005')
						errMsg = this.$t(
							'가입 시 입력하신 전화번호로 진행된 체험수업 정보를 확인할 수 없습니다.',
						)
				}
				alert(errMsg)
			} finally {
				this.isLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
