<template>
	<div>
		<router-view
			v-if="isLoading === false"
			:nextLessons="nextLessons"
			:programs="filteredPrograms"
			:lessonType="lessonType"
			:progresses="progresses"
			:lesson="lesson"
			:beforeRouteInfo="beforeRouteInfo"
		></router-view>
		<v-dialog persistant v-model="noLessonDialog" width="310">
			<v-card data-test="lackOfPointsDialog" class="rounded-lg">
				<div class="px-4 py-5">
					<h1 class="font-19 mb-4">수업을 찾을 수 없습니다.</h1>
					<!-- <p class="grey--text text--darken-1 font-14 mb-5">
						{{ name }}님을 위한 할인 혜택이 준비되어 있어요. 편하게 수강권을
						둘러보세요!
					</p> -->

					<v-btn depressed block color="primary" @click="goBack()">확인</v-btn>
				</div>
			</v-card>
		</v-dialog>
		<v-dialog persistant v-model="statusNotMatchedDialog" width="310">
			<v-card data-test="lackOfPointsDialog" class="rounded-lg">
				<div class="px-4 py-5">
					<h1 class="font-19 mb-4">
						프로그램은 수업 10분전까지 변경하실 수 있습니다.
					</h1>
					<!-- <p class="grey--text text--darken-1 font-14 mb-5">
						{{ name }}님을 위한 할인 혜택이 준비되어 있어요. 편하게 수강권을
						둘러보세요!
					</p> -->

					<v-btn depressed block color="primary" @click="goBack()">확인</v-btn>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { DateTime } from 'luxon'
import scheduleApi from '@/services/schedule'
import studyApi from '@/services/study'

export default {
	data: () => ({
		programs: [],
		programIdsNotReadyForJA: [207],
		isLoading: false,
		progresses: [],
		lessons: [],
		lesson: null,
		noLessonDialog: false,
		statusNotMatchedDialog: false,
		beforeRouteInfo: false,
	}),
	computed: {
		lessonId() {
			return this.$route.params.lessonId
		},
		language() {
			return this.$store.getters['member/language']
		},
		groups() {
			return this.$store.getters['group/groups']
		},
		filteredPrograms() {
			// 유저언어가 일본어라면 일본어 번역 안된 프로그램 제외
			// 프로그램의 group이 1이거나
			// 		해당 멤버의 groups안에 해당 프로그램의 group이 포함되거나.
			return this.programs.filter(
				p =>
					p.id !== 110 &&
					p.lessonType === this.lessonType &&
					(this.language === 'ko' ||
						(this.language === 'ja' &&
							this.programIdsNotReadyForJA.includes(p.id) === false)) &&
					(p.groupId === 1 || this.groups.includes(p.groupId) === true),
			)
		},
		lessonType() {
			return this.lesson ? this.lesson.lessonType : 1
		},
		name() {
			return this.$store.getters['member/nameKr']
		},
		nextLessons() {
			return this.lessons
				.filter(l => {
					return (
						l.dt >= DateTime.now() &&
						l.status === 'appointed' &&
						l.lessonType === this.lessonType
					)
				})
				.sort(function (a, b) {
					return a.dt - b.dt
				})
		},
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			if (
				from.name !== 'ProgramChange' &&
				from.name !== 'ProgramChangeUnit' &&
				from.name !== 'ProgramChangeConfirm'
			)
				vm.beforeRouteInfo = from
			else {
				vm.beforeRouteInfo = null
			}
		})
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			try {
				this.isLoading = true
				const dt = DateTime.now().toISO()
				const result = await Promise.all([
					studyApi.getActivePrograms(),
					scheduleApi.getLessons({
						gteLessonDatetime: dt,
					}),
					studyApi.getProgresses({
						gteLessonDatetime: `${DateTime.now()
							.minus({ days: 30 })
							.toISODate()} 00:00:00`,
					}),
					scheduleApi.findLesson(this.lessonId),
				])
				this.programs = result[0]
				this.lessons = result[1]
				this.progresses = result[2]
				this.lesson = result[3]

				if (!this.lesson) {
					this.noLessonDialog = true
					return
				}
				if (
					this.lesson.dt <= DateTime.now().plus({ minutes: 10 }) ||
					this.lesson.status !== 'appointed'
				) {
					this.statusNotMatchedDialog = true
					return
				}
				this.isLoading = false
			} catch (err) {
				console.log(err)
				this.noLessonDialog = true
			}
		},
		goBack() {
			this.$router.push(-1)
		},
	},
}
</script>

<style lang="scss" scoped></style>
