<template>
	<div id="book-program-list">
		<v-row no-gutters style="min-height: 464px">
			<v-col
				cols="6"
				class="px-1 mb-5"
				:class="{ 'left-image': i % 2 === 0, 'right-image': i % 2 === 1 }"
				v-for="(program, i) in programsOnPage"
				:key="program.id"
			>
				<img
					@click="$emit('selectProgram', program.id)"
					data-test="program-image"
					class="program-card"
					:src="`https://image.tella.co.kr/v1/program/thumbnail/portrait/${language}/${program.id}.jpg`"
				/>
			</v-col>
		</v-row>
		<v-pagination
			class="mt-3"
			v-model="page"
			:length="totalPage"
			:total-visible="6"
		></v-pagination>
	</div>
</template>

<script>
import { popularity } from '@/assets/programs'

export default {
	props: {
		programs: {
			required: true,
			type: Array,
		},
		selectedLevel: {
			required: true,
			type: String,
		},
		selectedTopic: {
			required: true,
			type: String,
		},
		orderBy: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			page: 1,
			totalPage: 0,
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		filteredPrograms() {
			return this.programs.filter(p => {
				const isLevelMatched =
					(p.programLevel && p.programLevel.includes(this.selectedLevel)) ||
					this.selectedLevel === '전체'
				const isTopicMatched =
					(p.topic && p.topic.includes(this.selectedTopic)) ||
					this.selectedTopic === 'All'
				return isLevelMatched === true && isTopicMatched === true
			})
		},
		programsOnPage() {
			const programs = this.filteredPrograms.slice()
			const orderedPrograms =
				this.orderBy === 'recent'
					? programs.sort((a, b) => b.id - a.id)
					: programs.sort((a, b) => {
							if (popularity.indexOf(a.id) === -1) return 1
							return popularity.indexOf(a.id) - popularity.indexOf(b.id)
					  })
			return orderedPrograms.slice(4 * (this.page - 1), 4 * this.page)
		},
	},
	watch: {
		selectedTopic() {
			this.setPaginationInit()
		},
		selectedLevel() {
			this.setPaginationInit()
		},
		orderBy() {
			this.setPaginationInit()
		},
	},
	created() {
		this.setPaginationInit()
	},
	methods: {
		setPaginationInit() {
			this.totalPage = parseInt(this.filteredPrograms.length / 4) + 1 || 0

			this.page = 1
		},
	},
}
</script>

<style lang="scss" scoped>
.program-card {
	width: 100%;
	border-radius: 4px;
	vertical-align: middle;
}
.program-card:hover {
	cursor: pointer;
}
.left-image {
	padding-right: 7.5px !important;
}
.right-image {
	padding-left: 7.5px !important;
}
</style>

<style>
#book-program-list .theme--light.v-pagination .v-pagination__item {
	background-color: unset;
	color: #111111;
	min-width: 25px;
	font-weight: 400;
}
#book-program-list .theme--light.v-pagination .v-pagination__item.primary {
	background-color: unset !important;
	color: #0074ff;
	font-weight: 500;
}
#book-program-list .theme--light.v-pagination .v-pagination__navigation {
	background-color: #fff;
	color: #666;
	border: 1px solid #f0f0f0;
	border-radius: 4px;
}
</style>
