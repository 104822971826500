<template>
	<div>
		<div
			v-if="[106, 107, 108, '106', '107', '108'].includes(plan.id)"
			class="mx-1 mt-3"
		>
			<img
				v-if="isEarlyBird"
				style="width: 100%"
				src="@/assets/images/orders/from-today-early-bird.png"
				alt=""
			/>
			<img
				v-else
				style="width: 100%"
				src="@/assets/images/orders/from-today.png"
				alt=""
			/>
		</div>
		<div
			v-if="[112, 113, 114, '112', '113', '114'].includes(plan.id)"
			class="mx-1 mt-3"
		>
			<img
				style="width: 100%"
				src="@/assets/images/orders/from-today-basic.png"
				alt=""
			/>
		</div>
		<div
			v-if="[109, 110, 111, '109', '110', '111'].includes(plan.id)"
			class="mx-1 mt-3"
		>
			<img
				style="width: 100%"
				src="@/assets/images/orders/from-today-business.png"
				alt=""
			/>
		</div>
		<div class="py-4">
			<h1 class="text-body-1 font-weight-bold">
				{{ $t('order.info.title.goods-on-order') }}
			</h1>
		</div>
		<div class="py-2 d-flex">
			<h2 class="text-body-1" style="width: 65px">
				{{ $t('order.info.title.plan-name') }}
			</h2>
			<div style="width: calc(100% - 65px)" class="pl-6">
				{{ planName }}
				<!-- {{ plan.order_title }}
				톡 12회 & 콜 4회 수강권 (1개월) -->
			</div>
		</div>
		<div class="py-2 d-flex">
			<h2 class="text-body-1" style="width: 65px">
				{{ $t('order.info.title.lesson-date') }}
			</h2>
			<div style="width: calc(100% - 65px)" class="pl-6">
				<p class="text-body-1">
					{{ $t('order.info.text.lesson-date') }}
				</p>
				<p class="text-body-2 grey--text text--darken-1">
					{{ $t('order.info.text.free-use') }}
				</p>
			</div>
		</div>
		<div class="py-2 d-flex">
			<h2 class="text-body-1" style="width: 65px">
				{{ $t('order.info.title.course-period') }}
			</h2>
			<div style="width: calc(100% - 65px)" class="pl-6">
				<p class="text-body-1">
					{{
						$t('order.info.text.month', {
							month: plan.month === 12 ? '9+3' : plan.month,
						})
					}}
				</p>
				<p class="text-body-2 grey--text text--darken-1">
					<span v-if="startDate">
						{{ startDate }} ~
						{{ endDate }}
					</span>
					<span v-else>{{ $t('order.info.text.select-start-date') }}</span>
				</p>
			</div>
		</div>
		<div
			v-if="[106, 107, 108, '106', '107', '108'].includes(plan.id)"
			class="grey lighten-3 px-2 py-3 d-flex align-start"
			style="border-radius: 6px"
		>
			<img
				class="mr-1"
				src="@/assets/icons/info-circle.png"
				:alt="$t('order.pay-method.img-alt.paypal-info')"
			/>
			<p class="font-14 font-weight-regular grey--text text--darken-1">
				이 수강권은
				<span class="font-weight-bold" style="color: #ff6060"
					>2023년 1월 4일부터 180일간 사용</span
				>하실 수 있습니다.
			</p>
		</div>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import { DateTime } from 'luxon'

export default {
	props: {
		planName: {
			required: true,
			type: String,
		},
		plan: {
			required: true,
			type: Object,
		},
		startDate: {
			required: true,
			type: String,
		},
	},
	computed: {
		endDate() {
			return moment(this.startDate)
				.add(30 * this.plan.month - 1, 'days')
				.format('YYYY-MM-DD')
		},
		isEarlyBird() {
			return DateTime.now().setZone('Asia/Seoul').toISODate() < '2022-12-16'
		},
	},
}
</script>

<style lang="scss" scoped></style>
