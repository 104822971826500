<template>
	<div>
		<Bar
			:eventName="'오늘부터 180일-비즈니스회화 집중코스'"
			:prevRoute="prevRoute"
		/>
		<div class="main-wrap">
			<v-img
				class="mx-auto"
				min-height="540"
				src="@/assets/images/events/2022/from-today/main-mobile.png"
			></v-img>
		</div>

		<div class="wrap-1 text-center px-4">
			<div style="max-width: 680px" class="mx-auto">
				<h1 class="highlight-text-color headline-number">01</h1>
				<h2 class="mb-4 font-20 font-weigh-bold white--text">
					오늘부터 180일 프로젝트란?
				</h2>

				<v-img
					class="mb-5 d-block"
					src="@/assets/images/events/2022/from-today/01-mobile.png"
				></v-img>
				<v-img
					class="mb-5 d-block"
					src="@/assets/images/events/2022/from-today/02-mobile.png"
				></v-img>
				<v-img
					class="mb-5 d-block"
					src="@/assets/images/events/2022/from-today/03-mobile.png"
				></v-img>
				<v-img
					class="mb-5 d-block"
					src="@/assets/images/events/2022/from-today/04-mobile.png"
				></v-img>

				<v-img
					class="mb-5 d-block"
					src="@/assets/images/events/2022/from-today/05-mobile.png"
				></v-img>

				<h2 class="mt-8 white--text font-16 font-weight-bold">
					⭐<span class="highlight-text-color">23% 스킬업 쿠폰</span> 추가
					지급⭐
				</h2>
				<div>
					<img
						style="width: 100%; max-width: 528px; margin-left: -41px"
						class="mt-2"
						src="@/assets/images/events/2022/from-today/coupon-desktop.png"
					/>
				</div>
				<p class="mb-4 font-14 white--text">지출 부담으로 고민된다면?</p>
				<h2 class="mb-4 font-weight-bold font-16 white--text">
					180일 프로젝트 수강생이라면<br />
					<span class="highlight-text-color">누구나 23% 더 할인!</span>
				</h2>
				<p class="font-14 white--text">결제 시, 위 쿠폰 코드를 입력하세요</p>
			</div>
		</div>

		<div class="wrap-2 text-center">
			<h1 class="headline-number primary--text">02</h1>
			<h2 class="mb-5 font-20 font-weigh-bold">
				비즈니스 영어 독립, 180일이면 충분!
			</h2>
			<h3 class="mb-5 font-18 font-weight-bold" style="line-height: 1.25em">
				<span class="primary--text">라이팅 스피킹 동시 완성</span><br />
				비즈니스 톡 + 콜 플랜
			</h3>

			<v-row no-gutters style="max-width: 680px" class="mx-automb-9 px-4">
				<v-col cols="12" class="px-2">
					<!-- @click="$router.push({ name: 'Order', query: { 'plan-id': 106 } })" -->
					<a @click="endDialog = true" class="d-block">
						<v-img
							class="mb-4"
							src="@/assets/images/events/2022/from-today/plan-1-mobile.png"
						></v-img>
					</a>
				</v-col>
				<v-col cols="12" class="px-2">
					<a @click="endDialog = true" class="d-block">
						<v-img
							class="mb-4"
							src="@/assets/images/events/2022/from-today/plan-2-mobile.png"
						></v-img>
					</a>
				</v-col>
				<v-col cols="12" class="px-2">
					<a @click="endDialog = true" class="d-block">
						<v-img
							class="mb-4"
							src="@/assets/images/events/2022/from-today/plan-3-mobile.png"
						></v-img>
					</a>
				</v-col>
			</v-row>
			<h3 class="font-18 font-weight-bold" style="line-height: 120%">
				영어로 말하고 싶다면 일단<br />
				<span class="primary--text"
					>내 생각을 문장으로<br />
					만들 수 있어야 합니다</span
				>
			</h3>

			<img
				class="d-inline-block mt-n4 mb-4"
				src="@/assets/images/events/2022/from-today/down-arrow.png"
				alt=""
				style="width: 79px"
			/>
			<h3 class="font-14 font-weight-medium" style="line-height: 120%">
				채팅으로 부담 없이
				<strong class="font-weight-bold">라이팅을 배우세요</strong><br />
				그리고 전화를 통해
				<strong class="font-weight-bold">스피킹을 완성하세요</strong>
			</h3>

			<img
				class="d-inline-block mt-9 mb-10"
				src="@/assets/images/events/2022/from-today/sub_img-mobile.png"
				alt=""
				style="max-width: 80%"
			/>
			<div class="mb-10 font-weight-medium">
				<div class="font-18 pb-4">대면, 비대면 그 어떠한 상황에서도</div>
				<div
					style="line-height: 1.5rem"
					class="font-20 primary--text font-weight-bold"
				>
					이제 완전한 문장으로<br class="d-block" />대화하세요
				</div>
			</div>
			<div class="px-12">
				<ActionButton :btnClass="'black-btn'" :contentClass="''" />
			</div>
		</div>

		<div class="wrap-3 text-center">
			<div style="max-width: 680px" class="mx-auto">
				<h1 class="highlight-text-color headline-number">03</h1>
				<h2 class="mb-5 font-20 font-weigh-bold white--text">
					모든 플랜 특별가 월 77,000원!
				</h2>
				<h3 class="mt-5 mb-5 white--text font-16 font-weight-bold">
					모든 수강권

					<span class="d-inline-block">정가 대비</span>
					<br class="d-block" />
					<span class="highlight-text-color d-inline-block">35% OFF</span
					><br /><br class="d-block" />
					월 77,000원으로 최적의<br />플랜을 경험하세요
				</h3>
				<v-img
					width="320"
					class="mb-6 d-block mx-auto"
					src="@/assets/images/events/2022/from-today/sub_img03-mobile.png"
				></v-img>

				<div class="px-12">
					<ActionButton
						:btnClass="'green-btn'"
						:contentClass="'text-decoration-underline'"
					/>
				</div>
			</div>
		</div>
		<div class="wrap-4 text-center">
			<h1 class="highlight-text-color headline-number">04</h1>
			<h2 class="mb-5 font-20 font-weigh-bold white--text">수강권 선택 TIP</h2>
			<h3
				class="font-18 white--text font-weight-bold mb-4"
				style="line-height: 125%"
			>
				플랜 선택이 고민된다면?<br /><br class="d-block" />
				<span class="highlight-text-color"
					>아래<br class="d-block" />
					추천 팁을 참고해 보세요</span
				>
			</h3>
			<div class="mb-8 pl-7 pr-0 text-center">
				<v-img
					class="mx-auto"
					width="360"
					src="@/assets/images/events/2022/from-today/plan-recommend-mobile.png"
				/>
			</div>
			<div class="px-12">
				<ActionButton :btnClass="'green-btn'" :contentClass="''" />
			</div>
		</div>

		<div class="wrap-5 text-center">
			<h1 class="primary--text headline-number">05</h1>
			<h2 class="mb-3 font-20 font-weigh-bold">
				커리어력 급상승!<br class="d-block" />오리지널 독점 콘텐츠
			</h2>
			<v-container style="max-width: 813px">
				<v-row class="mb-7">
					<v-col cols="12">
						<v-img
							width="255"
							min-height="300"
							class="d-block mx-auto"
							src="@/assets/images/events/2022/from-today/content-1-mobile.png"
						></v-img>
					</v-col>
					<v-col cols="12">
						<v-img
							width="255"
							min-height="300"
							class="d-block mx-auto"
							src="@/assets/images/events/2022/from-today/content-2-mobile.png"
						></v-img>
					</v-col>
					<v-col cols="12">
						<v-img
							width="255"
							min-height="300"
							class="d-block mx-auto"
							src="@/assets/images/events/2022/from-today/content-3-mobile.png"
						></v-img>
					</v-col>
				</v-row>
				<div class="px-12">
					<v-btn
						:max-width="userDevice === 'desktop' ? 471 : 215"
						:height="userDevice === 'desktop' ? 61 : 44"
						x-large
						rounded
						depressed
						class="action-button"
						href="https://tella.notion.site/180-958fa90d88a7470d8d85cc5b34c0af73"
						><span class="font-16 font-weight-bold highlight-text-color"
							>오리지널 콘텐츠 더 알아보기</span
						>
					</v-btn>
				</div>
			</v-container>
		</div>

		<div class="wrap-6 text-center">
			<h1 class="highlight-text-color headline-number">06</h1>
			<h2 class="mb-7 font-20 font-weigh-bold white--text">
				프로젝트 일정 공개
			</h2>
			<div
				style="max-width: 460px; width: 80%; min-width: 300px"
				class="white mx-auto dates-box font-16 mb-12"
			>
				<div class="mb-7">
					<div class="d-flex align-center">
						<div class="font-weight-bold">🔸 수강신청 기간</div>
						<div class="ml-1 d-inline-block limited font-12 font-weight-medium">
							기간 한정
						</div>
					</div>
					<div class="pl-6">22년 12월 1일(목)~ 23년 1월 3일(화)</div>
				</div>
				<div>
					<div class="font-weight-bold">🔸 수업 시작일 / 종료일</div>
					<div class="pl-6">23년 1월 4일(수) / 23년 7월 2일(일)</div>
				</div>
			</div>
			<div class="px-12">
				<ActionButton :btnClass="'green-btn'" :contentClass="''" />
			</div>
		</div>

		<div class="footer-wrap px-4">
			<div style="max-width: 580px; width: 100%" class="mx-auto">
				<h3 class="white--text mb-1 font-10 font-weight-bold">[유의사항]</h3>
				<ul class="font-8 font-weight-regular">
					<li>
						본 수강권은 2022년 12월 1일(목) ~ 2023년 1월 3일(화)까지 판매되는
						한정 상품이며 수강기간은 2023년 1월 4일(수)~2023년 7월 2일(일) 180일
						입니다.
					</li>
					<li>
						본 수업은 예약제로 진행되며 수강 시작일부터 수업 예약 및 수강이
						가능합니다.
					</li>
					<li>
						수강권 결제 완료 시 [180일 프로젝트] 이용 및 혜택 콘텐츠 관련 상세
						안내가 이메일 및 문자로 발송될 예정입니다.
					</li>
					<li>
						얼리버드 판매는 2022년 12월 15일에 마감되며 3회 무료 수강권이
						추가됩니다.
					</li>
					<li>
						학습자의 결석이나 지각 등으로 발생한 손해에 대해서는 별도의 보상을
						제공하지 않습니다.
					</li>
					<li>
						상황에 따라 사전 공지 없이 수강권 판매를 조기마감 하거나 판매 기한
						등이 연장될 수 있습니다.
					</li>
					<li>
						천재지변, 폐업 등으로 중단이 불가피한 상황에는 서비스가 종료될 수
						있습니다.
					</li>
					<li>
						환불 시에는 1개월(30일) [오늘부터 180일] 수강권 정가 119,000원을
						기준으로 환불 금액이 결정됩니다.
					</li>
					<li>자세한 사항은 이용약관의 환불규정을 참고해주세요.</li>
					<li>기타 문의사항은 고객센터로 문의 부탁 드립니다.</li>
				</ul>
			</div>
		</div>
		<v-dialog v-model="endDialog" width="310">
			<v-card class="py-5 px-3">
				<h1 class="font-19 font-weight-bold mb-3">
					수강신청이 마감되었습니다.
				</h1>
				<p class="mb-5 font-14 font-weight-regular grey--text text--darken-1">
					수강을 원하시는 경우 고객센터로 문의해주세요.
				</p>

				<v-row no-gutters>
					<v-col cols="6" class="pr-1">
						<v-btn
							@click="endDialog = false"
							block
							depressed
							color="grey lighten-3"
							><strong class="font-weight-bold grey--text text--darken-3"
								>닫기</strong
							></v-btn
						>
					</v-col>
					<v-col cols="6" class="pl-1">
						<v-btn
							href="https://tella.channel.io/support-bots/30776"
							block
							depressed
							color="primary"
							><strong class="font-weight-bold">1:1 채팅상담</strong></v-btn
						></v-col
					>
				</v-row>
			</v-card>
		</v-dialog>
		<FixedButtonMobile />
	</div>
</template>

<script>
import Bar from './Bar'
import ActionButton from './from-today/ActionButton'
import FixedButtonMobile from './from-today/FixedButtonMobile'

export default {
	components: {
		Bar,
		ActionButton,
		FixedButtonMobile,
	},
	data: () => ({
		prevRoute: null,
		endDialog: false,
	}),
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},
	computed: {
		userDevice() {
			return this.$store.getters.userDevice
		},
	},
}
</script>

<style lang="scss" scoped>
.main-wrap {
	background-color: #f8f8f8;
}

.wrap-1 {
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #242424;
}

.headline-number {
	font-weight: 700;
	font-size: 28px;
}
.highlight-text-color {
	color: #dbff73;
}

.action-button {
	width: 100%;
}

.wrap-2 {
	padding-top: 50px;
	padding-bottom: 40px;
	color: #222222;
	background-color: #f8f8f8;
	.plan-button {
		background-color: #0d0d0d;
		font-weight: 700;
		font-size: 20px;
	}
	.plan-button.plan-1 {
		color: #dbff73;
	}
	.plan-button.plan-2 {
		color: #86ff73;
	}
	.plan-button.plan-3 {
		color: #2fcdff;
	}
	.action-button {
		background-color: #0d0d0d;
	}
}

.wrap-3 {
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #242424;
	.action-button {
		background-color: #dbff73;
		color: #242424 !important;
	}
}

.early-bird-wrap {
	background: #f8f8f8;
	padding-top: 45px;
	padding-bottom: 60px;
}

.wrap-4 {
	padding-top: 51px;
	padding-bottom: 78px;
	background: #242424;
	.action-button {
		background-color: #dbff73;
		color: #242424 !important;
	}
}

.wrap-5 {
	padding-top: 40px;
	padding-bottom: 60px;
	background-color: #f8f8f8;
	.action-button {
		background-color: #0d0d0d;
	}
}

.wrap-6 {
	padding-top: 101px;
	padding-bottom: 126px;
	background-color: #242424;
	.action-button {
		background-color: #dbff73;
	}
	.dates-box {
		padding: 27px 56px;
		text-align: left;
		line-height: 160%;
		padding: 21px 24px;
	}
}

.footer-wrap {
	padding-top: 50px;
	padding-bottom: 60px;
	background: #383838;
	ul li {
		color: rgb(165, 165, 165);
		line-height: 238.34%;
	}
}
.limited {
	background: #2970ff;
	color: #fff;
	padding: 3px 13px 1.5px;
	border-radius: 12.5px;
	line-height: 1rem;
	margin-top: -4px;
}
</style>
