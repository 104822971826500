export const SET = 'SET'
export const SET_TOTALCOUNT = 'SET_TOTALCOUNT'
export const LOADING = 'LOADING'

export default {
	[LOADING](state, payload) {
		state.isLoading = payload
	},
	[SET](state, payload) {
		state.points = payload
	},
	[SET_TOTALCOUNT](state, payload) {
		state.totalCount = payload
	},
}
