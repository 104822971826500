<template>
	<div id="monthly-report-month-picker" v-if="isLoading === false">
		<v-select
			dense
			height="32"
			rounded
			:items="months"
			:item-text="concatMonthAndYear"
			return-object
			hide-details
			single-line
			outlined
			v-model="selectedMonth"
			class="text-caption px-1"
		>
		</v-select>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import Api from '@/services/index'
const R = require('ramda')

export default {
	data: () => ({
		isLoading: false,
		months: [],
		selectedMonth: null,
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		yearMonthformat() {
			return this.$store.getters['member/yearMonthformat']
		},
	},
	watch: {
		selectedMonth(val) {
			this.$emit('selectMonth', val)
		},
	},
	created() {
		this.getMonths()
	},
	methods: {
		/**
		 * group by month
		 * 지난달, 라스트 데이 이전 lesson_date
		 * 상태는 analyzed
		 */
		async getMonths() {
			this.isLoading = true
			// const lteLessonDatetime = moment()
			// 	.add(-1, 'months')
			// 	.endOf('month')
			// 	.toISOString()

			const res = await Api.get(
				`/lessons/groups/month?member-id=${this.memberId}&status-in=analyzed`,
			)

			this.months = R.pipe(
				R.filter(
					m =>
						moment().endOf('month') > moment(`${m.year}-${m.month}`, 'YYYY-M'),
				),
				R.sortWith([R.descend(R.prop('year')), R.descend(R.prop('month'))]),
			)(res.data)
			this.isLoading = false
			if (this.months.length > 0) this.selectedMonth = this.months[0]
			else this.$emit('empty')
		},
		concatMonthAndYear(obj) {
			return moment(`${obj.year}-${obj.month}`, 'YYYY-M').format(
				this.yearMonthformat,
			)
		},
	},
}
</script>

<style lang="scss">
#monthly-report-month-picker {
	.v-text-field--rounded > .v-input__control > .v-input__slot {
		padding: 0 24px;
	}

	.v-input__icon {
		min-width: unset !important;
		width: 12px;
	}

	.v-text-field--outlined.v-input--dense.v-text-field--single-line
		> .v-input__control
		> .v-input__slot,
	.v-text-field--outlined.v-input--dense.v-text-field--outlined
		> .v-input__control
		> .v-input__slot {
		min-height: unset !important;
	}

	.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
		.v-input__append-inner {
		margin-top: 5px;
	}
}
</style>
