<template>
	<div>
		<div v-for="item in groups" :key="item.countOfWeek" class="mb-10">
			<ExampleSchedule
				:countOfWeek="item.countOfWeek"
				:type="type"
				class="mb-14"
			/>
			<h1 class="mb-3 font-weight-bold grey--text text--darken-3 text--h2">
				{{ $t('plan.plan-group.title') }}
				<span :class="`${type}--text`">{{ item.plans.length }}</span>
			</h1>
			<v-row no-gutters>
				<v-col class="px-1" cols="12" v-for="plan in item.plans" :key="plan.id">
					<div
						@click="
							$router.push({
								name: 'Order',
								query: { 'plan-id': plan.id },
							}),
								gtagClick(plan.id, plan.talkPoints, plan.callPoints)
						"
					>
						<PlanCard
							:type="type"
							:title="plan.title"
							:selected="selectedPlanId === plan.id"
							:biggestBenefit="plan.discount === item.biggestBenefit"
							:recommended="plan.recommended"
							:monthString="plan.monthString"
							:month="plan.month"
							:realPricePerMonth="plan.realPricePerMonth"
							:originPrice="plan.originPrice"
							:realPrice="plan.realPrice"
							:discount="plan.discount"
							:planId="plan.id"
						/>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import PlanCard from './PlanCard'
import ExampleSchedule from './ExampleSchedule'

export default {
	components: {
		PlanCard,
		ExampleSchedule,
	},
	props: {
		plans: {
			required: true,
			tyue: Array,
		},
		type: {
			required: true,
			tyue: String,
		},
		selectedPlanId: {
			required: true,
			tyue: Number,
		},
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		userDevice() {
			return this.$store.getters.userDevice
		},
		groups() {
			let list = []
			this.plans.forEach(p => {
				const originPrice =
					this.language === 'ja'
						? p.origin_talk_price_JPY + p.origin_call_price_JPY
						: p.origin_talk_price + p.origin_call_price
				const realPrice =
					this.language === 'ja'
						? p.real_talk_price_JPY + p.real_call_price_JPY
						: p.real_talk_price + p.real_call_price
				const plan = {
					id: p.id,
					title: this.planName(p.talk_points, p.call_points),
					biggestBenefit: p.biggestBenefit || false,
					recommended: p.month === 3,
					monthString: p.month_string,
					month: p.month,
					talkPoints: p.talk_points,
					callPoints: p.call_points,
					realPricePerMonth: realPrice / p.month,
					originPrice: originPrice,
					realPrice: realPrice,
					discount: ((originPrice - realPrice) / originPrice) * 100,
				}

				const index = list.findIndex(
					item => item.countOfWeek === p.count_of_week,
				)

				if (index >= 0) {
					list[index].plans.push(plan)
				} else {
					list.push({
						countOfWeek: p.count_of_week,
						plans: [plan],
					})
				}
			})
			list.forEach(item => {
				item.biggestBenefit = item.plans.reduce(
					(acc, val) => (acc > val.discount ? acc : val.discount),
					0,
				)
				item.plans.sort((a, b) => {
					return a.month - b.month
				})
			})
			return list
		},
	},
	methods: {
		planName(talkPoints, callPoints) {
			const packageName = this.$t('plan.plan-group.plan-name.package', {
				talkPoints,
				callPoints,
			})
			const talkName = this.$t('plan.plan-group.plan-name.talk', { talkPoints })
			const callName = this.$t('plan.plan-group.plan-name.call', { callPoints })
			if (talkPoints > 0 && callPoints > 0) return packageName
			if (talkPoints > 0) return talkName
			if (callPoints > 0) return callName
		},
		gtagClick(id, talkPoints, callPoints) {
			const type =
				talkPoints > 0 && callPoints > 0
					? 'package'
					: callPoints > 0
					? 'call'
					: 'talk'
			const params = {
				event_path: `${window.location.hostname}${this.$route.path}`,
				event_category: type,
				event_label: id,
			}
			// eslint-disable-next-line no-undef
			gtag('event', 'order_try_plan_2', params)
		},
	},
}
</script>

<style lang="scss" scoped></style>
