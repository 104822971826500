<template>
	<div id="reviews-table">
		<div class="text-center" v-if="isLoading === false">
			<div id="reviews-wrap">
				<v-container class="pa-0" style="max-width: 1024px; margin: 0 auto">
					<v-expansion-panels v-model="panel" multiple>
						<v-expansion-panel v-for="(review, i) in reviews" :key="i">
							<v-expansion-panel-header hide-actions>
								<template v-slot:default="open">
									<div style="width: 100%" class="d-flex align-center">
										<div
											style="width: calc(100% - 35px)"
											class="text-body-1 d-flex align-center"
										>
											<div class="pr-1 text-body-2">
												{{ totalCount - (page - 1) * 10 - i }}.
											</div>
											<v-chip
												v-if="
													today.clone().add(-7, 'days').format('YYYY-MM-DD') <
													review.created_at
												"
												small
												class="text-caption mr-2 pl-2 pr-3 primary text-center"
											>
												<strong>new</strong>
											</v-chip>
											<p
												style="
													width: calc(100%);
													white-space: nowrap;
													overflow: hidden;
													text-overflow: ellipsis;
												"
											>
												{{ review.subject }}
											</p>
										</div>
										<v-spacer></v-spacer>
										<div style="width: 25px">
											<v-icon v-if="open.open === false">mdi-plus</v-icon>
											<v-icon v-else>mdi-minus</v-icon>
										</div>
									</div>
								</template>
							</v-expansion-panel-header>
							<v-expansion-panel-content
								style="background-color: #fafafa; border-top: 1px solid #dadada"
								class="pt-5"
							>
								<div
									class="text-left"
									style="word-break: break-word"
									v-html="review.content"
								></div>
								<div class="text-right pt-5">
									<p class="small">
										{{ $t('plan.reviews.write-date') }}:
										{{ review.created_at.substr(0, 11) }}
										<br />
										{{ $t('plan.reviews.writer') }}:
										<span v-if="review.name">
											<span> {{ review.name.substr(0, 1) }}</span>
											<span
												v-for="i in review.name.length - 1"
												:key="`${review.id}-name-${i}`"
												>*</span
											>
										</span>
										({{
											review.email ? review.email.substr(0, 2) : '****'
										}}****@****)
									</p>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-container>
				<v-pagination
					class="pt-8 elevation-0"
					v-model="page"
					:length="pageCount"
					:total-visible="7"
					:disabled="isLoading"
				></v-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import Api from '@/services/index'
import moment from 'moment-timezone'

export default {
	data: () => ({
		page: 1,
		totalCount: 0,
		isLoading: false,
		reviews: [],
		panel: [],
		pageCount: null,
		today: moment.tz('Asia/Seoul'),
	}),
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
	},
	watch: {
		page() {
			this.get()
		},
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			this.isLoading = true
			this.reviews = []
			let query = `boards/page/${this.page}?board-type-id=4&sort-by=-id&limit=10&language=${this.language}`

			const res = await Api.get(query)
			this.reviews = res.data
			this.pageCount = parseInt(res.headers['x-page-count'])
			this.totalCount = parseInt(res.headers['x-total-count'])
			this.panel = [0]
			this.isLoading = false
		},
		momentFormat(timecode) {
			return moment.tz(timecode, 'Asia/Seoul')
		},
	},
}
</script>

<style scoped>
#reviews-wrap .v-expansion-panel::before {
	box-shadow: unset;
}
.main-container {
	padding: 0;
	height: 150px;
	background: url('https://image.tella.co.kr/v2/reviews/main-mobile.jpg')
		no-repeat left center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
@media screen and (min-width: 600px) {
	.main-container {
		height: 250px;
		background-image: url('https://image.tella.co.kr/v2/reviews/main.jpg');
	}
}
.arrow-up {
	width: 0;
	height: 0;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	margin-bottom: -5px;
	border-bottom: 8px solid white;
}
</style>

<style>
#reviews-table .v-expansion-panels .v-expansion-panel {
	margin: 10px 0;
	border: 1px solid #dadada !important;
	border-radius: 5px;
}

#reviews-table .v-expansion-panels .v-expansion-panel:not(:first-child)::after {
	border-color: rgba(0, 0, 0, 0);
}

#reviews-table .v-expansion-panel--active > .v-expansion-panel-header {
	min-height: 50px;
	padding: 10px 24px;
}
</style>
