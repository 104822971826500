<template>
	<div>
		<LessonTitle class="mb-1" :lesson="lesson" />

		<div class="box pa-4">
			<div class="d-flex align-center mb-3" data-test="lessonCardHead">
				<TutorAvatar
					:id="lesson.tutorId"
					:color="`amber darken-1`"
					:size="25"
					class="mr-1"
				/>
				<h2 class="font-14 my-text font-weight-regular">
					{{ lesson.tutorName }}
				</h2>
				<v-spacer></v-spacer>
				<div
					v-if="lesson.programId !== 110"
					data-test="changeButton"
					@click="changeBottomSheet = !changeBottomSheet"
					class="
						my-text
						grey--text
						text--darken-1
						font-13 font-weight-regular
						hover-pointer
					"
				>
					{{ $t('component.lessonCard.button.변경/취소') }}
				</div>
				<div
					v-if="lesson.programId === 110"
					@click="changeTrial"
					class="
						hover-pointer
						grey--text
						text--darken-1
						font-13 font-weight-regular
					"
				>
					{{ $t('component.lessonCard.button.시간 변경') }}
				</div>
			</div>
			<div v-if="lesson.programName">
				<div class="mb-2">
					<h1 class="my-text font-14 font-weight-medium">
						{{ lesson.programName }}
					</h1>
					<p
						class="
							my-text
							font-13
							my-text
							grey--text
							text--darken-1
							font-weight-regular
						"
					>
						<span v-if="lesson.programId === 110">{{
							$t('programName.레벨테스트')
						}}</span>
						<span v-else-if="lesson.unitTitle"
							>Unit {{ lesson.unit }}. {{ lesson.unitTitle[language] }}</span
						>
						<span v-else>''</span>
					</p>
				</div>
				<div class="text-right">
					<v-btn
						color="grey"
						rounded
						outlined
						height="32"
						data-test="previewButton"
						@click="goToPreview"
					>
						<span
							class="
								my-text
								grey--text
								text--darken-3
								font-13 font-weight-regular
							"
							>{{ $t('component.lessonCard.button.예습하기') }}
						</span>
					</v-btn>
				</div>
			</div>
			<div v-else class="font-14 grey--text text--darken-3 font-weight-medium">
				<div style="min-height: 50px">
					{{ $t('component.lessonCard.text.프로그램과 유닛을 선택하세요.') }}
				</div>

				<div class="text-right">
					<ChangeProgramButtonForNotSelected :lesson="lesson" />
				</div>
			</div>

			<v-bottom-sheet
				v-model="changeBottomSheet"
				inset
				max-width="400"
				content-class="rounded-t-xl"
			>
				<v-sheet class="px-4 py-2 rounded-t-xl text-center" height="182px">
					<ChangeProgramButton :lesson="lesson" />
					<v-divider></v-divider>
					<CancelButton
						:lesson="lesson"
						@cancelled="cancelled"
						ref="cancelButtonBox"
					/>
				</v-sheet>
			</v-bottom-sheet>
		</div>
	</div>
</template>

<script>
import TutorAvatar from '../TutorAvatar'
import ChangeProgramButton from './ChangeProgramButton'
import CancelButton from './CancelButton'
import ChangeProgramButtonForNotSelected from './ChangeProgramButtonForNotSelected'
import LessonTitle from '../LessonTitle'
import scheduleApi from '@/services/schedule'
import EventBus from '@/lib/eventBus'

export default {
	components: {
		TutorAvatar,
		ChangeProgramButton,
		ChangeProgramButtonForNotSelected,
		CancelButton,
		LessonTitle,
	},
	props: {
		lesson: {
			type: Object,
		},
	},
	data: () => ({
		changeBottomSheet: false,
		cancelledNotify: false,
		cancelledMsg: null,
	}),
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
	},
	methods: {
		async changeTrial() {
			const trial = await scheduleApi.getTrialInformation(this.lesson.id)
			this.$router.push({
				name: 'TrialReschedule',
				params: { trialId: trial.id },
				query: {
					'country-code': trial.countryCode,
					phone: trial.phone,
					'in-lesson': true,
				},
			})
			return
		},
		async goToPreview() {
			if (this.lesson.programId === 110) {
				const trial = await scheduleApi.getTrialInformation(this.lesson.id)
				this.$router.push({
					name: 'TrialConfirmation',
					params: { trialId: trial.id },
					query: {
						'country-code': trial.countryCode,
						phone: trial.phone,
						'in-lesson': true,
					},
				})
				return
			}
			this.$router.push({
				name: 'LessonPreview',
				params: {
					lessonId: this.lesson.id,
				},
			})
		},
		cancelled(msg) {
			this.changeBottomSheet = false
			EventBus.$emit('lessonIsCancelled', msg)
		},
	},
}
</script>

<style lang="scss" scoped>
.box {
	background-color: #fff;
	min-height: 160px;
	border-radius: 12px;
}
</style>
