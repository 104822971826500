<template>
	<div
		class="py-6 d-flex align-center px-4 text-center"
		style="border-bottom: 1px solid #eee"
	>
		<div class="font-14" style="width: 55px">나의 진도</div>
		<div class="font-14 primary--text font-weight-medium" style="width: 45px">
			{{ completedProgresses.length }}/{{ totalUnitLength }}
		</div>
		<div class="rounded grey lighten-3" style="width: calc(100% - 100px)">
			<div
				class="rounded primary"
				:style="`height:10px;width: ${activeBarWidth}%`"
			></div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		completedProgresses: {
			required: true,
			type: Array,
		},
		totalUnitLength: {
			required: true,
			type: Number,
		},
	},
	computed: {
		activeBarWidth() {
			return (this.completedProgresses.length / this.totalUnitLength) * 100
		},
	},
}
</script>

<style lang="scss" scoped></style>
