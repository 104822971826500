<template>
	<v-bottom-sheet v-model="sheet" v-if="isLoading === false" max-width="400">
		<v-row no-gutters align="center" class="py-3 sheet-title">
			<v-col cols="8" offset="2">
				<p class="white--text text-center">{{ $t('예약하기') }}</p>
			</v-col>
			<v-col cols="2">
				<v-btn @click="sheet = false" color="white" text
					><v-icon>mdi-chevron-down</v-icon></v-btn
				>
			</v-col>
		</v-row>
		<v-sheet>
			<section class="pa-5">
				<h1 class="text-body-1 font-weight-bold mb-3">
					{{ $t('수업 유형') }}
				</h1>
				<v-row no-gutters class="text-center">
					<v-col cols="6" class="pr-2">
						<div
							class="sheet-button"
							@click="selectLessonType(0)"
							:class="{ active: lessonType === 0 }"
						>
							💬&nbsp;&nbsp; {{ $t('25분톡') }}
							<span
								class="
									remaining-chip
									d-inline-block
									ml-1
									text-caption
									font-weight-medium
									white--text
								"
								:class="{
									primary: lessonType === 0,
									'grey lighten-1': lessonType !== 0,
								}"
								v-if="availableTalk.remaining > 0"
							>
								{{ availableTalk.remaining }}
							</span>
						</div>
					</v-col>
					<v-col cols="6" class="pl-2">
						<div
							class="sheet-button"
							@click="selectLessonType(1)"
							:class="{ active: lessonType === 1 }"
						>
							📞&nbsp;&nbsp; {{ $t('15분콜') }}
							<div
								class="
									remaining-chip
									d-inline-block
									ml-1
									text-caption
									font-weight-medium
									white--text
								"
								:class="{
									emerald: lessonType === 1,
									'grey lighten-1': lessonType !== 1,
								}"
								v-if="availableCall.remaining > 0"
							>
								{{ availableCall.remaining }}
							</div>
						</div>
					</v-col>
				</v-row>
			</section>

			<section class="pa-5">
				<h1 class="text-body-1 font-weight-bold mb-3">
					{{ $t('예약 방식') }}
				</h1>
				<div
					class="sheet-button d-flex align-center mb-2 pl-3"
					@click="goToBook('TimeFirstStep1')"
				>
					{{ $t('시간 먼저 선택') }}
					<div
						style="border-radius: 15px"
						class="ml-2 primary white--text px-3 text-caption"
					>
						{{ $t('추천') }}
					</div>
					<v-spacer></v-spacer>
					<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
				</div>
				<div
					class="sheet-button d-flex align-center pl-3"
					@click="goToBook('TutorFirst')"
				>
					{{ $t('튜터 먼저 선택') }}<v-spacer></v-spacer>
					<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
				</div>
			</section>

			<v-divider></v-divider>
			<div class="py-3 text-center">
				<v-btn
					@click="sheet = false"
					small
					class="gradient-button white--text"
					fab
					icon
				>
					<v-icon>mdi-window-close</v-icon>
				</v-btn>
			</div>
		</v-sheet>
	</v-bottom-sheet>
</template>

<script>
export default {
	data: () => ({
		isLoading: false,
		sheet: false,
		lessonType: null,
		routeName: null,
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		availableTalk() {
			return this.$store.getters['point/availableTalk']
		},
		availableCall() {
			return this.$store.getters['point/availableCall']
		},
		bookActionSheet() {
			return this.$store.getters['device/bookActionSheet']
		},
	},
	watch: {
		memberId(val) {
			if (val) this.$store.dispatch('point/get')
		},
		bookActionSheet() {
			this.sheet = !this.sheet
		},
		sheet(val) {
			if (val === true) this.typeSelect()
		},
	},
	created() {
		this.$store.dispatch('point/get')
	},
	methods: {
		typeSelect() {
			if (this.availableTalk.remaining > 0) this.lessonType = 0
			else if (this.availableCall.remaining > 0) this.lessonType = 1
			else this.lessonType = null
		},
		selectLessonType(type) {
			if (type === 0 && this.availableTalk.remaining > 0) this.lessonType = 0
			if (type === 1 && this.availableCall.remaining > 0) this.lessonType = 1
		},
		goToBook(routeName) {
			if (this.lessonType !== null) {
				this.routeName = routeName
				// this.$store.commit('book/SET_INITIAL')
				const typeEn = this.lessonType === 0 ? 'talk' : 'call'
				this.routeName = null
				this.$router
					.push({ name: routeName, params: { typeEn } })
					.catch(() => {})
				this.sheet = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.gradient-button {
	color: #fff;
	background: linear-gradient(136.67deg, #466bf8 14.07%, #9e53e9 80.15%);
}
.sheet-title {
	background: linear-gradient(90deg, #466bf8 0%, #9e53e9 100%);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
	border-radius: 30px 30px 0px 0px;
}
.sheet-button {
	width: 100%;
	border: 1px solid #e7e7e7;
	border-radius: 10px;
	color: #000;
	padding: 15px 0;
	height: 52px;
	transition: all 0.1s ease-in-out;

	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	letter-spacing: -0.03em;
}
.sheet-button.active {
	border-color: #466bf8;
	font-weight: bold;
	color: #466bf8;
	background: rgba(30, 120, 255, 0.05);
}

.remaining-chip {
	min-width: 40px;
	padding: 2px 9px;
	border-radius: 18px;
}
</style>
