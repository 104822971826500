<template>
	<div
		:class="`${type}-schedule`"
		v-if="countOfWeek === 4 && type === 'package'"
		style="border-radius: 8px; overflow: hidden"
	>
		<div
			class="px-4 py-3 px-sm-5 py-sm-4 d-flex align-center"
			:class="`${type}-header`"
		>
			<v-img
				max-width="20"
				class="mr-1"
				:src="require(`@/assets/images/plans/ic-4-dot-${type}.png`)"
			></v-img>
			<h1 class="text-body-1 font-weight-bold grey--text text--darken-3">
				{{ $t('plan.example-schedule.title.four-times-a-week') }}
			</h1>
		</div>
		<v-row no-gutters class="px-5 py-4" align="center">
			<v-col cols="12">
				<p
					class="text-body-2 grey--text text--darken-2 white-space-pre"
					v-html="$t('plan.example-schedule.text.four-times-a-week')"
				></p>
			</v-col>
			<v-col cols="12">
				<div class="mt-3 text-body-2 grey--text text--darken-2">
					{{ $t('plan.example-schedule.text.example') }}<br />
					<img
						style="width: 100%; max-width: 363px"
						class="mt-2"
						:src="require(`@/assets/images/plans/img-schedule-${language}.png`)"
					/>
				</div>
				<Tooltip class="mt-2" :countOfWeek="countOfWeek" :type="type" />
			</v-col>
		</v-row>
	</div>
	<div
		v-else
		:class="`${type}-schedule`"
		style="border-radius: 8px; overflow: hidden"
	>
		<div class="px-4 py-3 d-flex align-center" :class="`${type}-header`">
			<v-img
				max-width="20"
				class="mr-1"
				:src="
					require(`@/assets/images/plans/ic-${countOfWeek}-dot-${type}.png`)
				"
			></v-img>
			<h1 class="text-body-1 font-weight-bold grey--text text--darken-3">
				{{ $t('plan.example-schedule.title.count-of-week', { countOfWeek }) }}
				{{ typeOfStudy }}
			</h1>
		</div>
		<div class="px-5 py-4">
			<p class="text-body-2 grey--text text--darken-2 white-space-pre">
				{{ msg }}
			</p>
			<Tooltip class="mt-4" :countOfWeek="countOfWeek" :type="type" />
		</div>
	</div>
</template>

<script>
import Tooltip from './Tooltip'

export default {
	components: {
		Tooltip,
	},
	props: {
		countOfWeek: { required: true, type: Number },
		type: { required: true, type: String },
	},
	data() {
		return {
			panel: 0,
		}
	},
	computed: {
		typeOfStudy() {
			if (this.countOfWeek === 3 && this.type === 'talk')
				return this.$t(
					'plan.example-schedule.type-of-sutdy.three-times-talk-a-week',
				)
			else if (this.countOfWeek === 5 && this.type === 'talk')
				return this.$t(
					'plan.example-schedule.type-of-sutdy.five-times-talk-a-week',
				)
			else if (this.countOfWeek === 2 && this.type === 'call')
				return this.$t(
					'plan.example-schedule.type-of-sutdy.two-times-call-a-week',
				)
			else
				return this.$t(
					'plan.example-schedule.type-of-sutdy.three-times-call-a-week',
				)
		},
		msg() {
			if (this.countOfWeek === 3 && this.type === 'talk')
				return this.$t('plan.example-schedule.text.three-times-talk-a-week')
			else if (this.countOfWeek === 5 && this.type === 'talk')
				return this.$t('plan.example-schedule.text.five-times-talk-a-week')
			else if (this.countOfWeek === 2 && this.type === 'call')
				return this.$t('plan.example-schedule.text.two-times-call-a-week')
			else return this.$t('plan.example-schedule.text.three-times-call-a-week')
		},
		language() {
			return this.$store.getters['member/language']
		},
	},
}
</script>

<style lang="scss">
.package-schedule {
	background-color: rgb(245, 243, 254);
}
.package-header {
	background-color: #e8e4ff;
}
.talk-schedule {
	background-color: rgb(239, 246, 254);
}
.talk-header {
	background-color: #d2e5ff;
}
.call-schedule {
	background-color: rgb(246, 253, 252);
}
.call-header {
	background-color: #dff5f3;
}
</style>
