<template>
	<div class="py-14 px-5">
		<h1 class="title2 mb-10 white-space-pre">
			{{ $t('trial-report.tutor-comment.title') }}
		</h1>
		<v-card flat class="mb-4 tutor-wrap d-flex align-center">
			<TutorAvatar
				:size="50"
				style="margin-right: 18px"
				:id="tutorId"
				:name="tutorName"
				:type="1"
			/>
			<div>
				<p>
					{{ $t('trial-report.tutor-comment.title2') }}
				</p>
				<p class="tutor-name">{{ tutorName }}</p>
			</div>
		</v-card>
		<div class="triangle-up"></div>
		<div class="comment-wrap white--text">
			<h2 class="mb-2 comment-title">
				{{ $t('trial-report.tutor-comment.title3') }}&nbsp;&nbsp;💬
			</h2>
			<p class="comment">
				{{ comment }}
			</p>
		</div>
	</div>
</template>

<script>
import TutorAvatar from '@/components/common/TutorAvatar'

const R = require('ramda')

export default {
	components: {
		TutorAvatar,
	},
	props: {
		level: {
			required: true,
			type: Number,
		},
		tutorId: {
			required: true,
			type: Number,
		},
		tutorName: {
			required: true,
			type: String,
		},
	},
	computed: {
		comment() {
			const list = [
				{
					level: 3,
					comment: `Hey ! It was such a pleasure talking to you.
While you frequently make mistakes with your sentences, you can still communicate by using simple vocabulary and grammar skills. You are able to answer simple questions and give basic information about yourself, but your responses are sometimes very limited.
I encourage you to continue practicing the language and working on your skills. I look forward to your improvement and hope to see you again soon!`,
				},
				{
					level: 4,
					comment: `Hey ! It was such a pleasure talking to you.
You will be able survive in an English-speaking country using simple sentences! You can express your needs and and describe your surroundings with limited vocabulary.
I encourage you to continue practicing the language and working on your skills. I look forward to your improvement and hope to see you again soon!`,
				},
				{
					level: 5,
					comment: `Hey ! It was such a pleasure talking to you.
You can sufficiently grasp the main idea of a conversation and connect simple sentences by using transition words and phrases. It is not too difficult for you to carry on a conversation with an English speaker, to state your opinions on familiar topics, or to respond to simple requests.
I encourage you to continue practicing the language and working on your skills. I look forward to your improvement and hope to see you again soon!`,
				},
				{
					level: 6,
					comment: `Hey ! It was such a pleasure talking to you.
Although you occasionally make minor mistakes, you have a sound foundation of basic English grammar and vocabulary! It is quite impressive that you can state your opinions on various topics, discuss the pros and cons of an issue, and stay engaged in social and occupational discourses.
You did a great job. Keep up the good work! I look forward to your improvement and hope to see you again soon!`,
				},
				{
					level: 7,
					comment: `Hey ! It was such a pleasure talking to you.
You can comprehend complicated sentences and discuss abstract and technical topics in depth. Your proficiency allows you to stay engaged in prolonged discourses with native speakers and to actively partake in problem-solving and decision-making situations.
You did an excellent job. Keep up the good work! I look forward to your improvement and hope to see you again soon!`,
				},
			]
			return R.find(R.propEq('level', this.level))(list).comment
		},
	},
}
</script>

<style lang="scss" scoped>
.title {
	font-size: 24px !important;
	font-weight: bold;
	line-height: 1.38 !important;
	letter-spacing: -0.96px !important;
	color: #191f28;
}
.tutor-wrap {
	padding: 15px 86px 15px 17px;
	border-radius: 16px;
	background-color: #f2f3f6;
	font-size: 14px !important;
	letter-spacing: -0.44px !important;
	color: #434e5c;
}
.tutor-wrap .tutor-name {
	font-size: 16px !important;
	font-weight: bold !important;
	letter-spacing: normal !important;
}
.triangle-up {
	margin-left: calc(50% - 5px);
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;

	border-bottom: 5px solid #00c1d0;
}
.comment-wrap {
	padding: 43px 18px 38px 21px;
	object-fit: contain;
	border-radius: 20px;
	background-image: linear-gradient(150deg, #05e7de -62%, #00c1d0 59%);
}
.comment-title {
	font-size: 16px !important;
	font-weight: bold;
	line-height: 1.56 !important;
	letter-spacing: -0.5px !important;
}
.comment {
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 1.75 !important;
	letter-spacing: -0.2px !important;
}
</style>
