<template>
	<div>
		<MenuBar :isLoading="isLoading" :routeName="'Quest'" />

		<!-- <section class="d-flex align-center pa-4 white">
			<div
				@click="$router.push({ name: 'Lessons' })"
				class="cursor-pointer pa-1"
				style="border-bottom: 4px solid #fff"
			>
				<h1 class="font-weight-regular grey--text text-body-1">
					{{ $t('수업 내역') }}
				</h1>
			</div>
			<div class="pa-1 ml-3" style="border-bottom: 4px solid #000">
				<h1 class="font-weight-bold text-body-1">{{ $t('퀘스트') }}</h1>
			</div>
			<div
				@click="$router.push({ name: 'MonthlyReport' })"
				class="cursor-pointer pa-1 ml-3"
				style="border-bottom: 4px solid #fff"
			>
				<h1 class="font-weight-regular grey--text text-body-1">
					{{ $t('월별 리포트') }}
				</h1>
			</div>
		</section> -->
		<section class="px-5 pt-5">
			<div class="mb-3 d-flex align-center">
				<h1 class="text-h5 font-weight-medium">Quest</h1>
				<v-spacer></v-spacer>
				<GroupSelector
					:isLoading="isGroupLoading"
					:groups="groups"
					@selectGroup="selectGroup"
					style="max-width: 130px"
				/>
			</div>
			<div>
				<Card
					:groupId="selectedGroup.id"
					:startDate="selectedGroup.start_date"
					:endDate="selectedGroup.end_date"
				/>
			</div>
		</section>
		<v-dialog v-model="dialog" width="350" content-class="rounded-xl">
			<v-card rounded="xl" class="pt-5">
				<v-card-text>
					<h1 class="text-h6 mb-3 font-weight-bold">
						{{ $t('준비 중입니다.') }}
					</h1>
					<p
						class="text-body-2 grey--text text--darken-1"
						v-html="
							$t('업데이트가 되면 공지 드릴게요!<br />조금만 기다려주세요.🙏')
						"
					></p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="black" text @click="dialog = false">
						{{ $t('확인') }}
					</v-btn>
					<v-spacer></v-spacer>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import Api from '@/services/index'
import GroupSelector from './GroupSelector'
import Card from './Card'
import MenuBar from '@/components/common/LessonReportMenuBar'

export default {
	components: {
		GroupSelector,
		Card,
		MenuBar,
	},
	data() {
		return {
			isGroupLoading: false,
			dialog: false,
			groups: [],
			selectedGroup: {},
			isLoading: false,
		}
	},
	created() {
		this.getGroup()
	},
	methods: {
		async getGroup() {
			this.isGroupLoading = true
			const datetime = moment().toISOString()

			const res = await Api.get(
				`quests/groups?type=1&sort-by=+start_date&lte-start-datetime=${datetime}`,
			)
			this.groups = res.data
			this.isGroupLoading = false
		},
		selectGroup(val) {
			this.selectedGroup = val
		},
		goToMonthlyReport() {
			this.dialog = true
		},
	},
}
</script>

<style lang="scss" scoped></style>
