<template>
	<div class="corrected-wrap pa-4" :class="`correction-wrap-${correction.id}`">
		<p class="font-16 mb-2 grey--text text--darken-1 mb-2">
			<span
				v-html="
					beforeDiff(
						correction.tutee_chat_log_content,
						correction.tutor_chat_log_content,
					)
				"
			></span>
		</p>

		<p class="mb-3 font-16 after-wrap">
			<img
				src="@/assets/images/lesson/lesson-arrow.png"
				style="width: 12.85px; margin-right: 3px"
				alt=""
			/>
			<span v-for="(word, i) in corrected" :key="`${corrected.id}-${i}`">
				<span v-if="word.added === true">
					<CorrectionBlank
						class="d-inline-block"
						:word="word"
						:index="i"
						:correctionId="correction.id"
						@showWord="showWord"
						:style="
							corrected[i + 1] &&
							corrected[i + 1].value !== ',' &&
							corrected[i + 1].value !== '.'
								? 'padding-right: 3px'
								: ''
						"
					/>
				</span>
				<span
					class="d-inline-block"
					v-else
					:style="
						corrected[i + 1] &&
						corrected[i + 1].value !== ',' &&
						corrected[i + 1].value !== '.'
							? 'padding-right: 3px'
							: ''
					"
					>{{ word.value }}</span
				>
			</span>
			<!-- <span
				@click="showWord"
				v-html="
					afterDiff(
						correction.id,
						correction.tutee_chat_log_content,
						correction.tutor_chat_log_content,
					)
				"
			></span> -->
		</p>
		<v-divider class="mb-1"></v-divider>
		<div class="d-flex align-center justify-end">
			<div class="mr-3 text-center" style="width: 20px">
				<i
					v-if="isShowAll === false"
					@click="showAll(true)"
					style="color: #404a59"
					class="fa-solid fa-eye-slash"
				></i>
				<i
					v-else
					@click="showAll(false)"
					style="color: #404a59"
					class="fa-solid fa-eye"
				></i>
			</div>
			<AudioPlayer
				class="d-inline-block ml-1"
				:script="correction.tutor_chat_log_content"
				:color="'primary--text'"
				:text="true"
			/>
		</div>
	</div>
</template>

<script>
import CorrectionBlank from './CorrectionBlank'
import AudioPlayer from './AudioPlayer'
const jsdiff = require('diff')
import EventBus from '@/lib/eventBus'

export default {
	components: {
		CorrectionBlank,
		AudioPlayer,
	},
	props: {
		correction: {
			required: true,
			type: Object,
		},
	},
	data: () => ({
		isShowAll: false,
		showWords: [],
	}),
	computed: {
		corrected() {
			let diff = jsdiff.diffWords(
				this.correction.tutee_chat_log_content,
				this.correction.tutor_chat_log_content,
			)
			const filtered = diff.filter(d => !d.removed)
			let words = []
			filtered.forEach(w => {
				const word = w.value.split(' ')
				word.forEach(x => {
					words.push({ value: x, added: w.added })
				})
			})
			return words
		},
		blankCount() {
			const added = this.corrected.filter(
				c => c.added === true && c.value !== '',
			)
			return added.length
		},
		showWordsLength() {
			return this.showWords.length
		},
	},
	methods: {
		beforeDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.removed) {
					span === null
						? (span = `<span class='font-weight-bold before'>${part.value}</span>`)
						: (span += `<span class='font-weight-bold before'>${part.value}</span>`)
				} else if (!part.added) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
		// afterDiff(id, a, b) {
		// 	let index = 0
		// 	let diff = jsdiff.diffWords(a, b)
		// 	let span = null

		// 	diff.forEach(part => {
		// 		const count = part.value.length
		// 		let blank = ''
		// 		for (let i = 0; i < count; i++) {
		// 			blank += '_'
		// 		}
		// 		let firstClickedBlank = part.value.substring(0, 1)
		// 		if (firstClickedBlank === ' ') {
		// 			firstClickedBlank = part.value.substring(1, 2)
		// 		}
		// 		for (let i = 0; i < count - 1; i++) {
		// 			firstClickedBlank += '_'
		// 		}

		// 		const tag = `<span id="${id}-${index}-${part.value}" class='after-${id} font-weight-bold after'>
		// 				<span class="non-clicked-show">${blank}</span>
		// 				<span class="clicked-show">${firstClickedBlank}</span>
		// 				<span class="double-clicked-show">${part.value}</span>
		// 				</span>`
		// 		if (part.added) {
		// 			span === null ? (span = tag) : (span += tag)
		// 		} else if (!part.removed) {
		// 			span === null
		// 				? (span = '<span>' + part.value + '</span>')
		// 				: (span = span + '<span>' + part.value + '</span>')
		// 		}
		// 		index++
		// 	})
		// 	return span
		// },
		// showWord(e) {
		// 	const clickedId = e.path[1].id
		// 	if (clickedId) {
		// 		const element = document.getElementById(clickedId)
		// 		const hasClicked = element.classList.contains('clicked')
		// 		const hasDoubleClicked = element.classList.contains('double-clicked')
		// 		if (hasDoubleClicked) {
		// 			element.classList.remove('double-clicked')
		// 		} else if (hasClicked) {
		// 			element.classList.remove('clicked')
		// 			element.classList.add('double-clicked')
		// 		} else {
		// 			element.classList.add('clicked')
		// 		}
		// 	}
		// 	this.checkIsShowAll()
		// },
		showWord(status, wordObj) {
			const wordsIndex = this.showWords.findIndex(
				w => w.index === wordObj.index,
			)
			if (status === 'show' && wordsIndex < 0) {
				this.showWords.push(wordObj)
			}
			if (status === 'hide' && wordsIndex >= 0) {
				this.showWords = this.showWords.filter(w => w.index !== wordObj.index)
			}
			this.checkIsShowAll()
		},
		checkIsShowAll() {
			if (
				this.blankCount === this.showWordsLength &&
				this.isShowAll === false
			) {
				this.isShowAll = true
				// eslint-disable-next-line no-undef
				amplitude.getInstance().logEvent('click_review_correction_showAll', {
					lessonId: this.id,
				})
			}
			if (this.blankCount !== this.showWordsLength && this.isShowAll === true) {
				this.isShowAll = false
			}
		},
		showAll(status) {
			this.isShowAll = status
			EventBus.$emit('clickShowAllInReviewCorrection', {
				correctionId: this.correction.id,
				status: status,
			})
			if (status === true) {
				// eslint-disable-next-line no-undef
				amplitude.getInstance().logEvent('click_review_correction_showAll', {
					lessonId: this.id,
				})
			}
		},
		// checkIsShowAll() {
		// 	const elements = document.getElementsByClassName(
		// 		`after-${this.correction.id}`,
		// 	)
		// 	let trueCount = 0
		// 	elements.forEach(e => {
		// 		if (e.classList.contains('double-clicked')) trueCount++
		// 	})
		// 	if (trueCount === elements.length) this.isShowAll = true
		// },
	},
}
</script>

<style lang="scss">
.corrected-wrap {
	.after-wrap {
		color: #404a59;
	}
	.higilight {
		border-radius: 5px !important;
		padding: 2px 5px 1px 6px;
	}
	.before-highlight {
		background-color: #feeeed;
	}
	.before {
		color: #ff6060;
	}
	.after-highlight {
		background: rgba(193, 233, 206, 0.5);
	}
	.after {
		cursor: pointer;
		color: #00cba0;
	}

	.non-clicked-show {
		display: inline-block;
	}
	.clicked-show {
		display: none;
	}
	.double-clicked-show {
		display: none;
	}
	.clicked {
		.non-clicked-show {
			display: none;
		}
		.clicked-show {
			display: inline-block;
		}
		.double-clicked-show {
			display: none;
		}
	}
	.double-clicked {
		.non-clicked-show {
			display: none;
		}
		.clicked-show {
			display: none;
		}
		.double-clicked-show {
			display: inline-block;
		}
	}
}
</style>
