<template>
	<v-form>
		<div class="d-flex align-start">
			<v-text-field
				class="mr-1"
				background-color="white"
				:label="$t('form.label.수업일시')"
				ref="formattedDatetime"
				v-model="formattedDatetime"
				outlined
				:disabled="isLoading"
				readonly
				@click="$emit('selectMoment', null)"
				hide-details="auto"
			>
			</v-text-field>
			<v-btn
				depressed
				large
				color="primary"
				@click="$emit('selectMoment', null)"
				:disabled="selectedMoment === null"
				>{{ $t('form.button.일시변경') }}</v-btn
			>
		</div>
		<p
			class="mb-6 mt-2 px-1 grey--text text--darken-1 text-body-2 grey--4--text"
		>
			{{
				$t('trial.text.타임존-표기-안내', {
					timezone,
				})
			}}
		</p>
		<v-text-field
			class="tella-form"
			:label="$t('form.label.name')"
			ref="name"
			v-model="name"
			outlined
			readonly
			background-color="white"
		>
		</v-text-field>
		<v-text-field
			class="tella-form"
			:label="$t('form.label.email')"
			ref="email"
			v-model="email"
			outlined
			readonly
			background-color="white"
		>
		</v-text-field>
		<div class="mb-5 d-flex align-start">
			<div
				style="max-width: 100px; width: 40%"
				class="flex-grow-0 flex-shrink-1"
			>
				<CountryPicker
					:readonly="true"
					:originCountryCode="countryCode"
					:backgroundColor="'white'"
					:hideDetails="true"
				/>
			</div>

			<v-text-field
				style="max-width: 250px; width: 60%"
				class="tella-form ml-2 flex-grow-1 flex-shrink-0"
				:label="$t('form.label.phone')"
				ref="phone"
				v-model="phone"
				outlined
				readonly
				type="tel"
				:disabled="isLoading"
				background-color="white"
				hide-details="auto"
			>
			</v-text-field>
		</div>
		<BookButton
			:memberId="memberId"
			:name="name"
			:email="email"
			:phone="phone"
			:nationality="nationality"
			:countryCode="countryCode"
			:datetime="(selectedMoment && selectedMoment.toISOString()) || null"
			:language="language"
			:timezone="timezone"
			:isValid="isValid"
		/>
	</v-form>
</template>

<script>
import BookButton from '../common/BookButtonForMember'
import CountryPicker from '@/components/common/CountryPicker'

export default {
	components: {
		BookButton,
		CountryPicker,
	},
	props: {
		selectedMoment: {
			required: true,
		},
	},
	data() {
		return {
			isLoading: false,
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		language() {
			return this.$store.getters['member/language']
		},
		nationality() {
			return this.$store.getters['member/nationality']
		},
		phone() {
			return this.$store.getters['member/phone']
		},
		countryCode() {
			return this.$store.getters['member/countryCode']
		},
		name() {
			return (
				this.$store.getters['member/nameKr'] ||
				this.$store.getters['member/nameEn']
			)
		},
		email() {
			return this.$store.getters['member/email']
		},
		timezone() {
			return this.$store.getters['member/timezone']
		},
		formattedDatetime() {
			if (this.selectedMoment === null) return null
			const m = this.selectedMoment
			const date = m
				.clone()
				.tz(this.timezone)
				.locale('ko')
				.format('ll')
				.substr(5)
			const day = m.clone().tz(this.timezone).locale('ko').format('(ddd)')
			const time = m.clone().tz(this.timezone).locale('en').format('h:mm A')
			return `${date} ${day} ${time}`
		},
		isValid() {
			return this.selectedMoment ? true : false
		},
	},
	methods: {
		selectCountry(country) {
			this.countryCode = country.dialCode
		},
		selectTimezone(timezone) {
			this.$emit('selectTimezone', timezone)
		},
	},
}
</script>

<style lang="scss" scoped></style>
