<template>
	<v-dialog v-model="dialog" width="350">
		<template v-slot:activator="{ on, attrs }">
			<div
				class="white px-5 pb-14 mb-3 d-flex align-center"
				v-bind="attrs"
				v-on="on"
			>
				<v-img
					width="37"
					max-width="37"
					src="@/assets/images/trials/ic-chat-log.svg"
				></v-img>
				<div style="margin-left: 18px">
					<h2 class="chat-title">
						{{ $t('trial-report.chat-logs-modal.button.title') }}
					</h2>
					<p class="chat-text">
						{{ $t('trial-report.chat-logs-modal.button.text') }}
					</p>
				</div>
				<v-spacer></v-spacer>
				<v-icon large>mdi-chevron-right</v-icon>
			</div>
		</template>

		<v-card class="pt-5 trial-report">
			<div class="px-5 mb-4 d-flex align-center">
				<h1 class="title2">
					{{ $t('trial-report.chat-logs-modal.log') }}
				</h1>
				<v-spacer></v-spacer>
				<v-btn text icon @click="dialog = false">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
			<div
				class="px-5 pb-5"
				style="height: 80%; max-height: 450px; overflow-y: auto"
			>
				<div
					v-for="log in logs"
					:key="log.id"
					class="d-flex"
					:class="{
						'justify-start': log.type === 'Tutor',
						'justify-end': log.type === 'Tutee',
					}"
				>
					<div
						class="chat-bubble mb-3"
						:class="log.type.toLowerCase()"
						v-html="log.content"
					></div>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		logs: {
			default: () => {},
			type: Array,
		},
	},
	data() {
		return {
			dialog: false,
		}
	},
}
</script>

<style lang="scss" scoped>
.chat-bubble {
	border-radius: 20px;
	padding: 12px 20px;
	max-width: 90%;
	word-wrap: break-word;
}
.chat-bubble.tutor {
	border: 1px solid #dedede;
}
.chat-bubble.tutee {
	background-color: #1e78ff;
	color: #fff;
}
</style>
