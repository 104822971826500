<template>
	<div class="white pb-9">
		<div style="max-width: 300px" class="mx-auto">
			<div class="d-flex align-center mb-4">
				<h2 class="title3 title-color">
					{{ $t('trial-report.vocabulary') }}
				</h2>
				<v-spacer></v-spacer>
				<div class="rectangle">
					<div class="d-inline-block dot"></div>
					<p class="d-inline-block sub-title2" style="color: #8748ec">
						{{
							$t('trial-report.score', {
								score: vocabularyScore,
							})
						}}
					</p>
				</div>
			</div>
			<p class="text default-color mb-5">
				{{ description }}
				<!-- 기초 어휘를 충분히 알고 있어 일상 생활에 필요한 기초 회화는 자신감있게
				할 수 있으시네요. 동의어 나 반의어, 구동사등을 추가로 학습하면서 같은
				표현도 다양한 방식으로 말해보세요. -->
			</p>
			<v-row no-gutters class="d-flex align-center">
				<v-col cols="6">
					<div class="pa-4 rectangle mr-1">
						<p
							class="
								font-weight-medium
								text-body-1
								mb-6
								voca-card-title
								white-space-pre
							"
						>
							{{ $t('trial-report.vocabulary.체험자님이 사용한 단어')
							}}<img src="@/assets/images/trials/ic-chat.svg" class="ic_chat" />
						</p>
						<h1
							class="text-right voca-card-h1"
							style="color: #7533e0"
							v-html="
								$t('trial-report.vocabulary.number-of-words', {
									numberOfWords,
								})
							"
						></h1>
					</div>
				</v-col>
				<v-col cols="6">
					<div class="pa-4 rectangle ml-1">
						<p
							class="
								font-weight-medium
								text-body-1
								mb-6
								voca-card-title
								white-space-pre
							"
						>
							{{ $t('trial-report.vocabulary.average-word-title', { level }) }}

							<img src="@/assets/images/trials/ic-chat.svg" class="ic_chat" />
						</p>
						<h1
							class="text-right voca-card-h1"
							style="color: #5d6571"
							v-html="
								$t('trial-report.vocabulary.average-word-text', {
									averageNumberOfLevel,
								})
							"
						></h1>
					</div>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
const R = require('ramda')

export default {
	props: {
		level: {
			required: true,
			type: Number,
		},
		vocabularyScore: {
			required: true,
			type: Number,
		},
		numberOfWords: {
			required: true,
			type: Number,
		},
	},
	computed: {
		description() {
			const list = [
				{
					score: 2,
					text: this.$t('trial-report.vocabulary.description-1'),
				},
				{
					score: 4,
					text: this.$t('trial-report.vocabulary.description-2'),
				},
				{
					score: 6,
					text: this.$t('trial-report.vocabulary.description-3'),
				},
				{
					score: 8,
					text: this.$t('trial-report.vocabulary.description-4'),
				},
				{
					score: 10,
					text: this.$t('trial-report.vocabulary.description-5'),
				},
			]
			const find = R.find(R.propEq('score', this.vocabularyScore))(list)
			return find ? find.text : 'N/A'
		},
		averageNumberOfLevel() {
			const list = [
				{ level: 3, length: 73 },
				{ level: 4, length: 116 },
				{ level: 5, length: 147 },
				{ level: 6, length: 179 },
				{ level: 7, length: 231 },
			]

			return R.find(R.propEq('level', this.level))(list).length
		},
	},
}
</script>

<style lang="scss" scoped>
.dot {
	margin-right: 6px;
	margin-bottom: 1px;
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background-color: #8748ec;
}
.rectangle {
	padding: 6px 17px 4px 17px;
	border-radius: 10px;
	background-color: #f2f3f6;
}
img.ic_chat {
	margin-left: 5px;
	vertical-align: middle;
	width: 14px;
	height: 13.8px;
	object-fit: contain;
}
.voca-card-title {
	line-height: 1.33 !important;
	letter-spacing: -0.47px;
	color: #404a59;
}
.voca-card-h1 {
	font-size: 33px;
	font-weight: 600;
	line-height: 0.64;
	letter-spacing: -1.03px;
}
.voca-card-h1 > ::v-deep .voca-card-p {
	padding-left: 3px;
	line-height: 1.4;
	letter-spacing: -0.47px;
	font-size: 15px !important;
	font-weight: 500;
}
</style>
