<template>
	<v-card rounded="lg" class="elevation-card corrected-card pa-5">
		<h1
			class="
				rounded-0
				mb-1
				d-inline-block
				pl-1
				pr-2
				text-body-2
				font-weight-bold
				before-highlight before
			"
		>
			✏️&nbsp;&nbsp;{{ $t('내가 쓴 문장') }}
		</h1>
		<p class="mb-4 text-body-2">
			<span
				v-html="
					beforeDiff(
						correction.tutee_chat_log_content,
						correction.tutor_chat_log_content,
					)
				"
			></span>
		</p>
		<h1
			class="
				rounded-0
				mb-1
				d-inline-block
				pl-1
				pr-2
				text-body-2
				font-weight-bold
				after-highlight after
			"
		>
			🙌&nbsp;&nbsp;{{ $t('첨삭 받은 문장') }}
		</h1>
		<p class="mb-4 text-body-2">
			<span
				v-html="
					afterDiff(
						correction.tutee_chat_log_content,
						correction.tutor_chat_log_content,
					)
				"
			></span>
			<AudioPlayer
				class="d-inline-block ml-1"
				:script="correction.tutor_chat_log_content"
				:color="'primary--text'"
				:text="true"
			/>
		</p>
		<div class="mt-6">
			<p class="text-h6 font-weight-light grey--text text-right">
				{{ i + 1 }}/{{ totalLength }}
			</p>
		</div>
	</v-card>
</template>

<script>
import AudioPlayer from './AudioPlayer'
const jsdiff = require('diff')

export default {
	components: {
		AudioPlayer,
	},
	props: {
		correction: {
			required: true,
			type: Object,
		},
		i: {
			required: true,
			type: Number,
		},
		totalLength: {
			required: true,
			type: Number,
		},
	},
	methods: {
		beforeDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.removed) {
					span === null
						? (span = `<span class='font-weight-bold before'>${part.value}</span>`)
						: (span += `<span class='font-weight-bold before'>${part.value}</span>`)
				} else if (!part.added) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
		afterDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.added) {
					span === null
						? (span = `<span class='font-weight-bold after'>${part.value}</span>`)
						: (span += `<span class='font-weight-bold  after'>${part.value}</span>`)
				} else if (!part.removed) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
	},
}
</script>

<style lang="scss" scoped></style>
