<template>
	<v-form v-model="valid">
		<div class="d-flex align-start">
			<v-text-field
				class="mr-1"
				background-color="white"
				:label="$t('form.label.수업일시')"
				ref="formattedDatetime"
				v-model="formattedDatetime"
				outlined
				:disabled="isLoading"
				readonly
				@click="$emit('selectMoment', null)"
				hide-details="auto"
				required
			>
			</v-text-field>
			<v-btn
				depressed
				large
				color="primary"
				@click="$emit('selectMoment', null)"
				:disabled="selectedMoment === null"
				>{{ $t('form.button.일시변경') }}</v-btn
			>
		</div>
		<p
			v-if="selectedMoment !== null"
			class="mt-2 px-1 grey--text text--darken-1 text-body-2 grey--4--text"
		>
			{{
				$t('trial.text.타임존-표기-안내', {
					timezone,
				})
			}}
			<TimezonePicker
				:originTimezone="timezone"
				@selectTimezone="selectTimezone"
			/>
		</p>
		<v-text-field
			class="mt-6 tella-form"
			:label="$t('form.label.name')"
			ref="name"
			v-model="name"
			outlined
			:rules="nameRules"
			:disabled="isLoading"
			background-color="white"
			required
		>
		</v-text-field>
		<v-text-field
			class="tella-form"
			:label="$t('form.label.email')"
			ref="email"
			v-model="email"
			outlined
			:rules="emailRules"
			:disabled="isLoading"
			background-color="white"
			required
			placeholder="somebody@example.com"
		>
		</v-text-field>
		<div class="d-flex align-start">
			<div
				style="max-width: 100px; width: 40%"
				class="flex-grow-0 flex-shrink-1"
			>
				<CountryPicker
					@selectCountry="selectCountry"
					:originCountryCode="countryCode"
					:backgroundColor="'white'"
					:hideDetails="true"
				/>
			</div>

			<v-text-field
				style="max-width: 250px; width: 60%"
				class="tella-form ml-2 flex-grow-1 flex-shrink-0"
				:label="$t('form.label.phone')"
				ref="phone"
				v-model="phone"
				outlined
				type="tel"
				:rules="phoneRules"
				:disabled="isLoading"
				background-color="white"
				hide-details="auto"
				required
			>
			</v-text-field>
		</div>
		<div
			style="background-color: #f2f9ff"
			class="mt-2 grey--text text--darken-2 text-body-2 pa-2"
		>
			<div class="d-inline-block" style="width: 24px; vertical-align: top">
				<v-img width="20" src="@/assets/images/trials/error.png"></v-img>
			</div>
			<div class="d-inline-block" style="width: calc(100% - 24px)">
				{{ $t('trial-jp.form.info.why-we-need-phone-number') }}
			</div>
		</div>
		<BookButton
			:name="name"
			:phone="phone"
			:countryCode="countryCode"
			:email="email"
			:datetime="(selectedMoment && selectedMoment.toISOString()) || null"
			:language="language"
			:timezone="timezone"
			:nationality="nationality"
			:isValid="isValid"
			class="mb-3 mt-10"
		/>

		<div
			class="mb-10 text-body-2 text-center grey--text text--darken-1"
			@click.prevent="policyDialogControl = !policyDialogControl"
		>
			<a class="text-decoration-underline grey--text text--darken-1">
				{{ $t('form.info.trial-policy-agree-1') }}
			</a>
			{{ $t('form.info.trial-policy-agree-2') }}
		</div>
		<PolicyDialog :policyDialogControl="policyDialogControl" />
	</v-form>
</template>

<script>
import CountryPicker from '@/components/common/CountryPicker'
import BookButton from '../common/BookButton'
import PolicyDialog from './PolicyDialog'
import TimezonePicker from '../common/TimezonePicker'

export default {
	components: {
		CountryPicker,
		PolicyDialog,
		BookButton,
		TimezonePicker,
	},
	props: {
		originName: {
			required: true,
			type: String,
		},
		originPhone: {
			required: true,
			type: String,
		},
		originCountryCode: {
			required: true,
			type: String,
		},
		selectedMoment: {
			required: true,
		},
		timezone: {
			required: true,
		},
	},
	data() {
		return {
			isLoading: false,
			valid: false,
			policyDialogControl: false,
			name: null,
			nameRules: [v => !!v || this.$t('form.error.name-required')],
			email: null,
			emailRules: [
				v => !!v || this.$t('form.error.email-required'),
				v => (v && v.length <= 100) || this.$t('form.error.email-too-long'),
				v => /.+@.+\..+/.test(v) || this.$t('form.error.email-format'),
			],
			countryCode: null,
			phone: null,
			phoneRules: [
				v => !!v || this.$t('form.error.phone-required'),
				v => /^[0-9]*$/.test(v) || this.$t('form.error.phone-only-number'),
				v =>
					(v &&
						v.length >= this.minNumberLength &&
						v.length <= this.maxNumberLength) ||
					this.$t('form.error.phone-min-and-max'),
			],
			policyAgree: true,
		}
	},
	computed: {
		minNumberLength() {
			return this.countryCode === '81' ? 10 : this.countryCode === '82' ? 9 : 6
		},
		maxNumberLength() {
			return this.countryCode === '81'
				? 14
				: this.countryCode === '82'
				? 11
				: 16
		},
		language() {
			return this.$store.getters['member/language']
		},
		nationality() {
			return this.$store.getters['member/nationality']
		},
		formattedDatetime() {
			if (this.selectedMoment === null) return null
			const m = this.selectedMoment
			const date = m
				.clone()
				.tz(this.timezone)
				.locale(this.language)
				.format('ll')
				.substr(5)
			const day = m
				.clone()
				.tz(this.timezone)
				.locale(this.language)
				.format('(ddd)')
			const time = m.clone().tz(this.timezone).locale('en').format('h:mm A')
			return `${date} ${day} ${time}`
		},
		isValid() {
			let valid = false
			if (
				this.valid === true &&
				this.selectedMoment &&
				this.name &&
				this.email &&
				this.phone &&
				this.countryCode &&
				this.policyAgree &&
				/^[0-9]*$/.test(this.countryCode) &&
				/^[0-9]*$/.test(this.phone)
			)
				valid = true
			return valid
		},
	},
	created() {
		this.name = this.originName
		this.countryCode = this.originCountryCode
		this.phone = this.originPhone
	},
	methods: {
		selectCountry(country) {
			this.countryCode = country.dialCode
		},
		selectTimezone(timezone) {
			this.$emit('selectTimezone', timezone)
		},
	},
}
</script>

<style lang="scss" scoped></style>
