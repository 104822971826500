<template>
	<v-img src="@/assets/images/trial-landings/pc-1-bg.svg">
		<div
			class="
				pt-sm-14 pt-11
				pb-sm-15 pb-12
				white--text
				d-flex
				align-center
				justify-center
				text-center
			"
		>
			<div>
				<h2
					class="text-center text--h3 mb-2"
					v-html="
						$t('trial.confirmation.title.please-check-before', {
							name: name || $t('trial-user', '체험자'),
						})
					"
				></h2>
				<h1 class="text-center text--h0 font-weight-bold mb-sm-0 mb-1">
					{{
						trialMoment
							.clone()
							.tz(timezone)
							.locale($i18n.locale)
							.format('MM.DD(ddd)')
					}}
					{{ trialMoment.clone().tz(timezone).locale('en').format('H:mmA') }}
				</h1>
				<p class="white--text mb-5 text--body-1 font-weight-light text-right">
					({{ timezone }})
				</p>
				<v-card
					max-width="350"
					rounded="lg"
					class="text-left pl-5 pr-3 pt-3 pb-3 elevation-0"
				>
					<div class="text-right">
						<v-btn
							@click="goToReschedule"
							outlined
							x-small
							color="grey"
							style="border-radius: 7px !important; font-size: 10px !important"
							class="mb-1"
						>
							<span class="grey--text text--darken-1">
								<v-icon x-small>mdi-square-edit-outline</v-icon>&nbsp;{{
									$t('trial.confirmation.button.change-schedule-or-info')
								}}
							</span>
						</v-btn>
					</div>
					<div class="mb-1 d-flex align-center">
						<p
							class="text--caption"
							:style="`width: ${language === 'ja' ? '105' : '85'}px`"
						>
							{{ $t('trial.confirmation.text.담당튜터') }}
						</p>
						<div class="d-flex align-center">
							<TutorAvatar
								class="mr-1"
								:size="20"
								:id="tutorId"
								:name="tutorName"
								:type="1"
							/>
							<p class="text--body-2 font-weight-bold primary--text">
								{{ tutorName }}
							</p>
						</div>
					</div>
					<div class="mb-2 d-flex align-center">
						<p
							class="text--caption"
							:style="`width: ${language === 'ja' ? '105' : '85'}px`"
						>
							{{ $t('trial.confirmation.text.phone') }}
						</p>
						<p class="text--body-2 font-weight-bold">
							+{{ countryCode }}) {{ phone }}
						</p>
					</div>
				</v-card>
			</div>
		</div>
		<Dialog
			:dialogControl="errDialog"
			:persistent="false"
			:numberOfButton="1"
			:title="$t('modal.title.trial-edit-only-in-60-minutes')"
			:text="''"
			:actionColor="'primary'"
			:action="closeErrDialog"
			:actionText="$t('modal.button.confirm')"
		/>
	</v-img>
</template>

<script>
import moment from 'moment-timezone'
import Dialog from '@/components/common/Dialog'
import TutorAvatar from '@/components/common/TutorAvatar'

export default {
	components: {
		TutorAvatar,
		Dialog,
	},
	props: {
		id: {
			required: true,
			type: Number,
		},
		countryCode: {
			required: true,
			type: Number,
		},
		phone: {
			required: true,
			type: [Number, String],
		},
		name: {
			required: true,
			type: String,
		},
		timezone: {
			required: true,
			type: String,
		},
		tutorId: {
			required: true,
			type: Number,
		},
		tutorName: {
			required: true,
			type: String,
		},
		trialMoment: {
			required: true,
			type: Object,
		},
		language: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			errDialog: false,
		}
	},
	methods: {
		goToReschedule() {
			if (this.trialMoment < moment().add(60, 'minutes')) {
				this.errDialog = !this.errDialog
				return
			}
			this.$router.push({
				name: 'TrialReschedule',
				params: { trialId: this.id },
				query: {
					'country-code': this.countryCode,
					phone: this.phone,
				},
			})
		},
		closeErrDialog() {
			this.errDialog = !this.errDialog
		},
	},
}
</script>

<style lang="scss" scoped></style>
