<template>
	<div>
		<div class="d-flex align-center">
			<img class="mr-1" src="@/assets/icons/L.png" alt="" />
			<h1 class="text-body-1">{{ $t('order.recommend.title') }}</h1>
			<img
				@click="show = !show"
				:class="{ show }"
				class="ml-1 chevron"
				src="@/assets/icons/chevron-down.png"
				alt=""
			/>
			<v-spacer></v-spacer>
			<p
				v-if="isChecked && recommendDiscount > 0"
				class="text-body-1 grey--text text--darken-1 price"
			>
				{{ $numberWithCommas(recommendDiscount) }} {{ currencyString }}
			</p>
		</div>
		<div class="pt-2" v-show="show">
			<v-form ref="form" v-model="isValid" @submit.prevent="checkRecommender">
				<v-text-field
					type="email"
					v-model.trim="email"
					outlined
					single-line
					hide-details="auto"
					placeholder="example@tella.co.kr"
					class="order-picker"
					:rules="emailRules"
					:disabled="isLoading || isChecked"
					:error-messages="
						emailNotMatched === true
							? $t('order.recommend.form-error-msg.check-email-again')
							: email === memberEmail
							? $t('order.recommend.form-error-msg.cant-use-your-email')
							: ''
					"
				>
					<template v-slot:append>
						<v-btn
							v-if="isChecked === false"
							type="submit"
							color="primary"
							class="ma-0 font-weight-bold"
							depressed
							:disabled="isLoading || !email"
							>{{ $t('order.recommend.button.register') }}
						</v-btn>
						<div
							style="margin-top: 12px; margin-right: 10px"
							v-else
							class="font-weight-bold primary--text text-body-2"
						>
							{{ $t('order.recommend.text.completed') }}
						</div>
					</template>
				</v-text-field>
			</v-form>
		</div>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	props: {
		recommendDiscount: {
			required: true,
			type: Number,
		},
		currencyString: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			show: true,
			isValid: false,
			email: null,
			emailRules: [
				v =>
					!v ||
					(v && v.length <= 100) ||
					this.$t('common.form.error.email-too-long'),
				v =>
					!v ||
					/.+@.+\..+/.test(v) ||
					this.$t('common.form.error.email-format'),
			],
			isChecked: false,
			isLoading: false,
			emailNotMatched: false,
		}
	},
	computed: {
		memberEmail() {
			return this.$store.getters['member/email']
		},
	},
	methods: {
		async checkRecommender() {
			this.emailNotMatched = false
			await this.$refs.form.validate()

			if (this.isValid === false) {
				return
			}

			try {
				this.isLoading = true
				const res = await Api.get(`/members/count?email=${this.email}`)
				if (res.data === 0) {
					this.emailNotMatched = true
					return
				}
				this.$emit('selectRecommender', this.email)
				this.isChecked = true
			} catch (err) {
				alert(err)
			} finally {
				this.isLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.chevron {
	transition: transform 0.2s ease-in-out;
}
.chevron.show {
	transform: rotate(180deg);
}
</style>
