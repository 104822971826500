var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.quest)?_c('v-btn',{staticClass:"align-center",attrs:{"depressed":"","rounded":"","height":"45","disabled":_vm.isAchievableQuest === false,"color":"primary","loading":_vm.isLoading},on:{"click":_vm.acheiveQuest}},[_c('img',{staticClass:"mr-2",staticStyle:{"vertical-align":"middle"},attrs:{"src":require(("@/assets/icons/coin" + (_vm.isAchievableQuest === false ? '-disabled' : '') + ".png")),"alt":""}}),_vm._v(" "+_vm._s(_vm.quest.reward_amount)+" "+_vm._s(_vm.$t('캐시 받기'))+" ")]):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","width":"350","content-class":"rounded-xl"},on:{"click:outside":function($event){return _vm.checkLater()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"pt-5",attrs:{"rounded":"xl"}},[_c('v-card-text',[_c('h1',{staticClass:"text-rlh text-h6 mb-3 font-weight-bold",domProps:{"innerHTML":_vm._s(
						_vm.$t(
							'500 캐시가 적립되었습니다 🙌<br />적립 내역을 확인하시겠어요?'
						)
					)}}),_c('p',{staticClass:"text-body-2 grey--text text--darken-1",domProps:{"innerHTML":_vm._s(
						_vm.$t(
							'[ 마이페이지 > 적립금 ]에서<br/>지금까지의 캐시 적립 내역을 확인하실 수 있어요.'
						)
					)}})]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"grey","text":"","disabled":_vm.isLoading},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('나중에 할게요'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.isLoading},on:{"click":function($event){return _vm.$router.push({ name: 'Cash' })}}},[_vm._v(" "+_vm._s(_vm.$t('적립 내역 확인하기'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }