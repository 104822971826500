<template>
	<div v-if="isLoading === false">
		<!-- <FastestSlot
			v-if="isExperimentalGroup"
			:selectedSlot="selectedSlot"
			@selectTime="selectTime"
		/> -->
		<!-- <h1 class="text-body-1 font-weight-bold grey--text text--darken-3 mb-3">
			{{ localeSelectedDate }}
		</h1> -->
		<div
			v-for="(group, date) in groupByDate"
			:key="toMoment(date).format('MM-DD')"
		>
			<v-row no-gutters class="pb-10">
				<v-col
					cols="3"
					class="text-center"
					v-for="slot in group"
					:key="`${date}-${slot.time_code}`"
				>
					<v-btn
						text
						style="height: 30px"
						:disabled="
							slot.lessons_count >= slot.capacity || slot.isDuplicated === true
						"
						class="slot text-body-2"
						:class="{
							disabled:
								slot.lessons_count >= slot.capacity ||
								slot.isDuplicated === true,
							selected: slot === selectedSlot,
						}"
						@click="selectTime(slot)"
					>
						<span class="slot-text">
							{{ localTime(slot.moment) }}
						</span>
					</v-btn>
				</v-col>
			</v-row>
		</div>
		<div v-if="Object.keys(groupByDate).length === 0">
			<p class="text-body-1 font-italic">
				{{ $t('죄송합니다. 현재 선택 가능한 시간이 없습니다.') }}
			</p>
		</div>
	</div>

	<Loader v-else />
	<!-- <v-container  style="max-width: 200px" :style="`height:50vh`">
		<v-row
			class="fill-height text-center"
			align-content="center"
			justify="center"
		>
			<v-col>
				<p class="text-body-2 mb-3">Loading...</p>
				<v-progress-linear
					color="deep-purple accent-4"
					indeterminate
					rounded
					height="8"
				></v-progress-linear>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import moment from 'moment-timezone'
import { momentToCode } from '@/helpers/date-format'
import Loader from '@/components/common/Loader'
import { createNamespacedHelpers } from 'vuex'
const { mapMutations } = createNamespacedHelpers('book')
const R = require('ramda')
// import FastestSlot from './FastestSlot'

export default {
	components: {
		// FastestSlot,
		Loader,
	},
	data: function () {
		return {
			selectedSlot: null,
		}
	},
	computed: {
		isLoading() {
			return this.$store.getters['book/time/isLoading']
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		isExperimentalGroup() {
			return this.memberId % 2 === 1 ? true : false
		},
		selectedMoment() {
			return this.$store.getters['book/selectedMoment']
		},
		selectedDate() {
			return this.$store.getters['book/time/selectedDate']
		},
		dateformat() {
			return this.$store.getters['member/dateformat']
		},
		selectedType() {
			return this.$store.getters['book/selectedType']
		},
		groupByDate() {
			return this.$store.getters['book/time/groupByDate']
		},
		appointedLessons() {
			return this.$store.getters['appointed/lessons']
		},
		haveSameTimeLessons() {
			const f = R.allPass([
				R.propEq(
					'lesson_date',
					moment(this.selectedSlot.moment)
						.tz('Asia/Seoul')
						.format('YYYY-MM-DD'),
				),
				R.propEq(
					'lesson_time',
					parseInt(
						momentToCode(
							moment(this.selectedSlot.moment).tz('Asia/Seoul'),
							this.selectedType,
						),
					),
				),
			])
			return R.filter(f, this.appointedLessons).length > 0
		},
		language() {
			return this.$store.getters['member/language']
		},
		timezone() {
			return this.$store.getters['member/timezone']
		},
		localeSelectedDate() {
			return moment
				.tz(this.selectedDate, this.timezone)
				.locale(this.language)
				.format(this.dateformat)
		},
	},
	destroyed() {
		this.$store.commit('book/time/STORE', [])
	},
	watch: {
		selectedMoment() {
			if (
				this.selectedSlot &&
				moment(this.selectedSlot.moment)
					.tz(this.timezone)
					.format('YYYY-MM-DD H:mm') !==
					moment(this.selectedMoment)
						.tz(this.timezone)
						.format('YYYY-MM-DD H:mm')
			)
				this.selectedSlot = null
		},
	},
	methods: {
		...mapMutations(['SELECT_MOMENT']),
		toMoment(date) {
			return moment.tz(date, this.timezone)
		},
		selectTime(slot) {
			const slotMoment = moment(slot.moment).tz(this.timezone)
			if (slotMoment < moment().add(10, 'minutes')) {
				alert(
					this.$t(
						'해당 시간의 접수가 마감되었습니다. 다른 시간을 선택해주세요.',
					),
				)
				return
			}
			this.selectedSlot = slot

			if (this.haveSameTimeLessons === true) {
				const confirmSameTime = confirm(
					this.$t(
						'이미 같은 시간에 예약된 수업이 있습니다. 그래도 진행하시겠습니까?',
					),
				)
				if (confirmSameTime === false) {
					this.selectedSlot = null
					return
				}
			}
			this.SELECT_MOMENT(slotMoment)
		},
		localTime(iso) {
			return moment(iso).tz(this.timezone).format('HH:mm')
		},
	},
}
</script>

<style lang="scss" scoped>
.date-btn {
	border-radius: 50%;
	width: 31px;
	height: 31px;
	margin: 0 auto;
}
.date-btn.active {
	color: #fff;
	background-color: #1e78ff;
}

.slot {
	letter-spacing: normal;
	font-weight: normal;
	max-width: 95%;
	height: 30px;
	margin: 0 auto;
	margin-top: 5px;
	padding-top: 3px;
	margin-bottom: 5px;
	text-align: center;
	border-radius: 5px;
	/* border: solid 1px #e9e9e9; */
	background-color: #ffffff;
}
.slot .slot-text {
	color: #3d3d3d !important;
	opacity: 1 !important;
}
.slot:hover {
	cursor: pointer;
}
.disabled.slot .slot-text {
	color: #b5b8d2 !important;
	text-decoration: line-through;
}
.disabled.slot:hover {
	cursor: unset;
}
.selected.slot {
	background: rgba(30, 120, 255, 0.05);
	border: 1px solid #1e78ff;
	box-sizing: border-box;
}
</style>
