<template>
	<div style="background-color: #f2f4f6">
		<div v-if="editings.length > 0">
			<div v-if="corrected.length > 0">
				<Card
					v-for="correction in corrected"
					:key="correction.id"
					:correction="correction"
					class="white mb-2 px-4"
				/>
			</div>
			<div v-else class="px-3 pb-3">
				<h1 class="text-body-1 font-weight-bold">{{ $t('편집 중...') }}</h1>
				<p class="grey--text text-body-2 font-weight-medium">
					{{
						$t(
							'현재 첨삭 모아보기 편집 작업 중입니다. 편집 완료에는 로그 업로드 이후최대 24시간이 소요됩니다.',
						)
					}}
				</p>
			</div>
		</div>
		<div v-if="pronounciations.length > 0">
			<h2 class="white px-4 font-16 font-weight-bold pb-3">{{ $t('발음') }}</h2>
			<div
				v-for="pronounciation in pronounciations"
				:key="pronounciation"
				class="white mb-2 pa-4"
			>
				<p class="font-16 mb-3 grey--text text--darken-1 mb-2">
					<span>{{ pronounciation }}</span>
				</p>
				<v-divider class="mb-1"></v-divider>
				<div class="d-flex justify-end">
					<AudioPlayer
						class="d-inline-block ml-1"
						:script="pronounciation"
						:small="true"
						:color="'primary--text'"
						:text="true"
					/>
				</div>
			</div>
		</div>
		<div v-if="newExpressions.length > 0">
			<h2 class="white pt-7 px-4 font-16 font-weight-bold pb-3">
				{{ $t('추천단어') }}
			</h2>
			<div
				v-for="newExpression in newExpressions"
				:key="newExpression"
				class="white mb-2 pa-4"
			>
				<p class="font-16 mb-3 grey--text text--darken-1 mb-2">
					<span>{{ newExpression }}</span>
				</p>
				<v-divider class="mb-1"></v-divider>
				<div class="d-flex justify-end">
					<AudioPlayer
						class="d-inline-block ml-1"
						:script="newExpression"
						:small="true"
						:color="'primary--text'"
						:text="true"
					/>
				</div>
			</div>
		</div>

		<div v-if="callCorrections.length > 0">
			<h2 class="white pt-7 px-4 font-16 font-weight-bold pb-3">
				{{ $t('첨삭') }}
			</h2>
			<div
				v-for="correction in callCorrections"
				:key="correction"
				class="white mb-2 pa-4"
			>
				<p class="font-16 mb-3 grey--text text--darken-1 mb-2">
					1<span>{{ correction }}</span>
				</p>
				<v-divider class="mb-1"></v-divider>
				<div class="d-flex justify-end">
					<AudioPlayer
						class="d-inline-block ml-1"
						:script="correction"
						:small="true"
						:color="'primary--text'"
						:text="true"
					/>
				</div>
			</div>
		</div>

		<div
			v-else-if="
				editings.lenght === 0 &&
				pronounciations.length === 0 &&
				newExpressions.length === 0 &&
				callCorrections.length === 0
			"
			class="pa-3"
		>
			<h1 class="text-body-1 font-weight-bold">
				{{ $t('내역이 없습니다') }}
			</h1>
		</div>
	</div>
</template>

<script>
import jsDiff from '@/mixins/jsDiff'
import AudioPlayer from './AudioPlayer'
import Card from './CorrectionCard'
// import editingService from '@/services/editing'

export default {
	components: {
		AudioPlayer,
		Card,
	},
	props: {
		editings: {
			required: true,
			type: Array,
		},
		pronounciations: {
			required: true,
			type: Array,
		},
		newExpressions: {
			required: true,
			type: Array,
		},
		callCorrections: {
			required: true,
			type: Array,
		},
	},
	computed: {
		corrected() {
			let tmp = []
			this.editings.forEach(correction => {
				if (correction.tutee_chat_log_content) tmp.push(correction)
			})
			return tmp
		},
	},
	mixins: [jsDiff],
}
</script>

<style lang="scss" scoped>
.correction-count {
	font-weight: 700;
	font-size: 18px;
	background: #444444;
	border-radius: 20px;
	line-height: 15px;
	padding: 4px 5px;
}
.corrected-card {
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1) !important;
}
</style>
