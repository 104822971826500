<template>
	<div class="pb-16">
		<Bar />
		<Menu />

		<v-container style="max-width: 1200px" class="mb-5">
			<v-row>
				<v-col
					cols="12"
					sm="12"
					v-for="event in list"
					:key="event.routeName"
					class="px-sm-4 py-sm-7 pb-10"
				>
					<EventCard :event="event" />
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import Bar from './Bar'
import Menu from './Menu'
import EventCard from './EventCard'
import { DateTime } from 'luxon'

export default {
	components: {
		EventCard,
		Bar,
		Menu,
	},
	data: () => ({
		list: [
			{
				routeName: 'Event2022CouponPack',
				src: 'oh-my-couponpack.png',
				mobileTitle: '부담 없는 텔라의 시작, 오 마이 쿠폰팩!',
				desktopTitle:
					'가입하신 모든 분께 드려요! 총 12만원 혜택의 오 마이 쿠폰팩',
				// startDt: DateTime.fromISO('2022-10-17'),
				// endDt: DateTime.fromISO('2022-12-31'),
			},
			{
				routeName: 'EventFriendRecommend',
				src: 'recommend.png',
				mobileTitle: '우정UP 혜택UP 친구추천 프로모션',
				desktopTitle: '우정과 혜택을 동시에, 친구추천 프로모션',
			},
			{
				routeName: 'EventReview',
				src: 'review.png',
				mobileTitle: '지금 수강후기 남기면? 최대 8천 캐시 적립',
				desktopTitle: '지금 수강후기 남기면? 최대 8천 캐시 바로 적립',
			},
			{
				routeName: 'Event2022FromToday',
				src: 'from-today.png',
				mobileTitle: '오늘부터 목표 달성! 180일 비즈니스 회화',
				desktopTitle:
					'생각한 대로 말하게 될 거예요, 오늘부터 180일 비즈니스 회화',
				startDt: DateTime.fromISO('2022-12-01'),
				endDt: DateTime.fromISO('2023-01-01'),
			},
		],
	}),
}
</script>

<style lang="scss" scoped></style>
