var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading === false)?_c('div',[_c('section',[_c('v-row',{staticClass:"white py-5 px-3 mb-5",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"fab":"","icon":"","text":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'Home' })}}},[_c('v-icon',[_vm._v("mdi-window-close")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('h1',{staticClass:"text-center text-body-1"},[_vm._v(" "+_vm._s(_vm.$t('프로그램 선택 완료'))+" ")])])],1)],1),_c('section',{staticClass:"px-5 mb-3"},[_c('SelectedCard',{attrs:{"lesson":_vm.lesson}})],1),_c('section',{staticClass:"px-5"},[_c('ul',{staticClass:"text-caption grey--text"},[_c('li',[_vm._v(_vm._s(_vm.$t('예약 취소는 수업 1시간전까지 가능합니다.')))])])]),_c('section',{staticStyle:{"position":"absolute","bottom":"0","width":"100%"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"height":"55","large":"","tile":"","block":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
							name: 'Home',
							params: { autoBookSheetOpen: true },
						})}}},[_vm._v(_vm._s(_vm.$t('다음 수업 예약하기')))])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"height":"55","large":"","tile":"","block":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({
							name: 'ProgramGuide',
							params: {
								programId: _vm.lesson.program_id,
								unitId: _vm.lesson.program_unit_id,
							},
						})}}},[_vm._v(_vm._s(_vm.$t('예습하기')))])],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }