import Api from '@/services/index'
import Kakao from '@/services/kakao'
import Facebook from '@/services/facebook'
import {
	AUTH_CHECKED,
	STORE_ID,
	STORE_KAKAO_TOKEN,
	STORE_KAKAO_ME,
	STORE_FACEBOOK_TOKEN,
	STORE_FACEBOOK_ME,
} from './mutations'

export default {
	get: async ({ commit }) => {
		try {
			commit(STORE_ID, null)
			commit(AUTH_CHECKED)
			const res = await Api.get(`/auth`)
			if (res) {
				const memberId = res.data
				commit(STORE_ID, memberId)
			}
		} catch (err) {
			throw new Error(err)
		}
	},
	signOut: async ({ commit }) => {
		await Api.delete('auth')
		commit(STORE_ID, null)
		commit('member/INITIALIZE', null, { root: true })
		commit('point/SET', [], { root: true })
	},
	getKakaoToken: async ({ commit }) => {
		const res = await Kakao.login()
		commit(STORE_KAKAO_TOKEN, res.access_token)
	},
	getKakaoMe: async ({ commit }) => {
		const res = await Kakao.getMe()

		commit(STORE_KAKAO_ME, {
			email: res.kakao_account.email,
			name: res.kakao_account.profile.nickname,
			authKakaoId: res.id,
		})
	},
	getFacebookToken: async ({ commit }) => {
		let res = await Facebook.getLoginStatus()
		if (res.status !== 'connected') {
			await Facebook.login()
			res = await Facebook.getLoginStatus()
			if (res.status !== 'connected') throw new Error()
		}

		commit(STORE_FACEBOOK_TOKEN, res.authResponse.accessToken)
	},
	getFacebookMe: async ({ commit }) => {
		const getMeRes = await Facebook.getMe()
		commit(STORE_FACEBOOK_ME, {
			authFacebookId: getMeRes.id,
			email: getMeRes.email,
			name: getMeRes.name,
		})
	},
}
