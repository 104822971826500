<template>
	<div class="grey lighten-3">
		<!-- <Nav /> -->
		<v-main
			:class="background"
			:style="shadow"
			style="
				max-width: 400px;
				margin: 0 auto;
				overflow-y: auto;
				height: calc(var(--vh, 1vh) * 100);
			"
		>
			<div class="pb-sm-0">
				<slot />
			</div>
		</v-main>
		<Footer style="max-width: 400px; margin: 0 auto" />
		<BookActionSheet />
		<KakaoInputDialog />
	</div>
</template>

<script>
import Footer from './Footer'
import BookActionSheet from '@/components/common/BookActionSheet'
import KakaoInputDialog from '@/components/common/KakaoInputDialog'

export default {
	name: 'Default',
	components: {
		Footer,
		BookActionSheet,
		KakaoInputDialog,
		// Nav,
	},
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		border() {
			return this.userDevice === 'desktop'
				? 'border-left: 1px solid #dadada!important;border-right: 1px solid #dadada!important;'
				: ''
		},
		shadow() {
			return this.userDevice === 'desktop'
				? 'box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);'
				: ''
		},
		background() {
			return this.$store.getters['device/background']
		},
	},
}
</script>

<style scoped></style>
