const jsdiff = require('diff')

export default {
	methods: {
		beforeDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.removed) {
					span === null
						? (span =
								"<span style='color:#fc0000; text-decoration: line-through'>" +
								part.value +
								'</span>')
						: (span =
								span +
								"<span style='color:#fc0000; text-decoration: line-through'>" +
								part.value +
								'</span>')
				} else if (!part.added) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
		afterDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.added) {
					span === null
						? (span = "<span style='color:#009640;'>" + part.value + '</span>')
						: (span =
								span + "<span style='color:#009640;'>" + part.value + '</span>')
				} else if (!part.removed) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
	},
}
