<template>
	<div class="background home pb-12">
		<v-app-bar
			elevation="0"
			height="55"
			style="background-color: unset !important"
		>
			<v-toolbar-title
				><img
					src="@/assets/images/logo/default.png"
					style="width: 40px"
					alt="텔라 홈"
			/></v-toolbar-title>
			<v-spacer></v-spacer>
			<PlanButton />
			<!-- <div
				class="hover-pointer"
				@click="
					$router.push({ name: 'Plans', params: { category: 'package' } })
				"
			>
				<img
					src="@/assets/images/home/tiecket.png"
					style="width: 23px; vertical-align: middle"
					alt=""
				/>
				<h3
					class="ml-1 d-inline-block font-14 primary--text font-weight-medium"
				>
					수강권 구매
				</h3>
			</div> -->
		</v-app-bar>
		<RollingBanner v-if="language === 'ko'" />
		<SectionBookLesson class="py-5 px-4" />
		<SectionNextLesson class="py-5 px-4" />
		<!-- <FromTodayPopUp /> -->
		<!-- <Tutorial v-if="showTutorial" /> -->
		<!-- <OhMyCouponPack :originSheet="couponPackSheet" /> -->
	</div>
</template>

<script>
import { DateTime } from 'luxon'
import PlanButton from '@/components/common/PlanButton'
import SectionBookLesson from './SectionBookLesson'
import SectionNextLesson from './SectionNextLesson'
import RollingBanner from './RollingBanner'
// import FromTodayPopUp from './FromTodayPopUp'
// import Tutorial from './Tutorial'
// import OhMyCouponPack from './OhMyCouponPack'

export default {
	components: {
		PlanButton,
		SectionBookLesson,
		SectionNextLesson,
		RollingBanner,
		// FromTodayPopUp,
		// Tutorial,
		// OhMyCouponPack,
	},
	data() {
		return {
			isLoading: false,
			couponPackSheet: false,
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		showTutorial() {
			return DateTime.now() < DateTime.fromISO('2022-12-01')
		},
	},
	created() {
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-3', {
			root: true,
		})
	},
	beforeDestroy() {
		this.$store.commit('device/SET_BACKGROUND', 'white', {
			root: true,
		})
	},
}
</script>

<style lang="scss" scoped>
.background {
	min-height: calc(var(--vh, 1vh) * 100);
	overflow-y: auto;
	background-color: #f2f4f6;
}
// /* iOS only */
// @supports (-webkit-touch-callout: none) {
// 	.background {
// 		height: -webkit-fill-available;
// 	}
// }
</style>
