const R = require('ramda')

export default {
	isLoading: state => state.isLoading,
	tutors: (state, getters, rootState, rootGetters) => {
		const ratings =
			rootGetters['book/selectedType'] === 0
				? rootGetters['tutorRating/talkRatings']
				: rootGetters['tutorRating/callRatings']
		return state.tutors.map(t => {
			let rating = R.find(R.propEq('tutor_id', t.id))(ratings)
			t.ratingCount = rating ? rating.count : 0
			t.rating = rating ? rating.average_rating : 0
			return t
		})
	},
	sortBy: state => state.sortBy,
	keyword: state => state.keyword,
	rowsPerPage: state => state.rowsPerPage,
	totalPage: state => Math.ceil(state.totalCount / state.rowsPerPage),
	category: state => state.category,
	pastTutorIds: state => state.pastTutorIds,
	pastTutorIdsForMonth: state => state.pastTutorIdsForMonth,
}
