<template>
	<div>
		<v-btn
			:disabled="isAllLoading"
			color="facebook"
			depressed
			fab
			@click="signIn"
		>
			<img
				src="@/assets/images/logo/facebook_blank.png"
				alt=""
				style="width: 25px"
			/>
		</v-btn>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="errDialogPersistent"
			:numberOfButton="errNumberOfButton"
			:title="errTitle"
			:text="errMsg"
			:actionColor="errActionColor"
			:action="errAction"
			:actionText="errActionText"
		/>
	</div>
</template>

<script>
import facebook from '@/services/facebook'
import Api from '@/services/index'
import Dialog from '@/components/common/Dialog'

export default {
	components: {
		Dialog,
	},
	props: {
		isAllLoading: {
			required: true,
			type: Boolean,
		},
	},
	data() {
		return {
			facebookToken: null,
			authFacebookId: null,
			// === 에러
			errorDialog: false,
			errTitle: '',
			errMsg: '',
			errNumberOfButton: 1,
			errDialogPersistent: false,
			errActionColor: 'primary',
			errAction: this.closeErrDialog,
			errActionText: this.$t('common.modal.button.confirm'),
		}
	},
	methods: {
		gtagEvent() {
			const params = {
				event_category: 'facebook',
				event_path: `my.tella.co.kr${this.$route.path}`,
			}
			// eslint-disable-next-line no-undef
			gtag('event', 'click_login_btn', params)
		},
		async facebookSignIn() {
			try {
				this.gtagEvent()
				let loginStatus = await facebook.getLoginStatus()

				if (loginStatus.status !== 'connected') {
					await facebook.login()
					loginStatus = await facebook.getLoginStatus()
					if (loginStatus.status !== 'connected') throw new Error()
				}
				this.facebookToken = loginStatus.authResponse.accessToken

				const getMeRes = await facebook.getMe()
				this.authFacebookId = getMeRes.id
			} catch (err) {
				throw new Error('facebookError')
			}
		},
		async signIn() {
			try {
				this.$emit('loading', true)
				this.isLoading = true
				await this.facebookSignIn()
				const params = {
					authFacebookId: this.authFacebookId,
					facebookToken: this.facebookToken,
				}
				await Api.post('/auth', params)
				this.$emit('tokenIssued')
			} catch (err) {
				const errorCode =
					err.message === 'facebookError'
						? 'facebookError'
						: err.response && err.response.data
						? err.response.data.code
						: '0000'
				this.errorHandler(errorCode)
			} finally {
				this.$emit('loading', false)
				this.isLoading = false
			}
		},
		errorHandler(errorCode) {
			switch (errorCode) {
				case 'facebookError':
					this.errTitle = this.$t('login.error.error-from-facebook-auth-server')
					break
				case '10001':
					this.errTitle = this.$t('login.error.facebook-token-and-id-wrong')
					break
				case '10008':
					this.errTitle = this.$t('login.error.deleted-member')
					break
				default:
					this.errTitle = this.$t('login.error.default-error-message', {
						errorCode,
					})
			}
			this.errorDialog = true
		},
		closeErrDialog() {
			this.errorDialog = !this.errorDialog
		},
	},
}
</script>

<style lang="scss" scoped></style>
