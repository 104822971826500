<template>
	<div>
		<div class="d-flex align-center px-1 pb-1">
			<div
				class="pa-1 tab-btn"
				:class="{ active: category === 'all' && pastTutorIds.length > 0 }"
				@click="selectCategory('all')"
			>
				<h1 class="text-body-1">
					{{ $route.name === 'TutorFirst' ? $t('전체 튜터') : $t('예약 가능') }}
				</h1>
			</div>
			<div
				v-if="pastTutorIds.length > 0"
				class="pa-1 tab-btn ml-3"
				:class="{ active: category === 'past' }"
				@click="selectCategory('past')"
			>
				<h1 class="text-body-1">{{ $t('수업한 튜터') }}</h1>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		category() {
			return this.$store.getters['book/tutor/category']
		},
		pastTutorIds() {
			return this.$store.getters['book/tutor/pastTutorIds']
		},
	},
	destroyed() {
		this.selectCategory('all')
	},
	methods: {
		selectCategory(category) {
			this.$store.commit('book/tutor/SELECT_CATEGORY', category)
		},
	},
}
</script>

<style lang="scss" scoped>
.tab-btn {
	transition: all 0.1s ease-in-out;
	border-bottom: 3px solid rgba(0, 0, 0, 0);
	h1 {
		transition: all 0.1s ease-in-out;
		color: #c4c4c4;
		font-weight: normal;
	}
}
.tab-btn.active {
	border-color: #000;
	h1 {
		font-weight: bold;
		color: #000;
	}
}
</style>
