<template>
	<v-row
		no-gutters
		class="px-5 d-flex align-center py-3"
		style="border-bottom: 1px solid #dadada"
	>
		<v-col cols="8">
			<div
				:class="{ unavailable: available === false }"
				class="mb-1 d-day px-3 text-caption font-weight-medium"
			>
				{{ dayDiff }}
			</div>
			<div>
				<p
					:class="{
						'grey--text text--lighten-1': available === false,
					}"
					class="text-body-1 font-weight-bold"
				>
					{{ coupon.coupon_type }}
				</p>
				<p
					class="text-caption"
					:class="{
						'grey--text text--lighten-1': available === false,
						'grey--text text--darken-1': available === true,
					}"
				>
					~{{
						$t('%{expirationDate}까지 사용 가능', {
							expirationDate: coupon.expiration_date,
						})
					}}
				</p>
			</div>
		</v-col>
		<v-col
			cols="4"
			:class="{
				'grey--text text--lighten-2': available === false,
				'primary--text': available === true,
			}"
			class="text-right font-weight-bold text-h6"
		>
			{{ numberWithCommas(coupon.discount) }} {{ coupon.discount_type }}
		</v-col>
	</v-row>
</template>

<script>
import moment from 'moment-timezone'
import { numberWithCommas } from '@/helpers/number-format'

export default {
	props: {
		coupon: {
			require: true,
			type: Object,
		},
	},
	computed: {
		available() {
			return (
				this.coupon.used_date === null &&
				this.coupon.active === 1 &&
				this.coupon.expiration_date >=
					moment.tz('Asia/Seoul').format('YYYY-MM-DD')
			)
		},
		dayDiff() {
			const moment1 = moment(this.coupon.expiration_date)
			const moment2 = moment()
			const dayDiff = moment1.diff(moment2, 'days')
			const monthDiff = moment1.diff(moment2, 'months')
			const yearDiff = moment1.diff(moment2, 'years')
			return yearDiff > 1
				? this.$t(`%{yearDiff}년 후 만료`, { yearDiff })
				: monthDiff > 1
				? this.$t(`%{monthDiff}개월 후 만료`, { monthDiff })
				: dayDiff > 1
				? `D-${dayDiff}`
				: 'D-Day'
		},
	},
	methods: {
		numberWithCommas: number => numberWithCommas(number),
	},
}
</script>

<style lang="scss" scoped>
.d-day {
	display: inline-block;
	border: 1px solid #ff8a8a;
	color: #ff8a8a;
	box-sizing: border-box;
	border-radius: 10px;
}
.d-day.unavailable {
	border: 1px solid #c4c4c4;
	color: #c4c4c4;
}
</style>
