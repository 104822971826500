<template>
	<div>
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="confirmDialog = true">
						<v-icon>mdi-window-close</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('수업 평가') }}</h1>
				</v-col>
			</v-row>
		</section>
		<section v-if="isLoading === false" style="padding-bottom: 80px">
			<LessonCard :lesson="lesson" class="mb-8 px-5" />

			<Rating :lesson="lesson" :ratings="ratings" @rate="goBack" />

			<ReportIssue class="mt-5 px-5" :lesson="lesson" @report="goBack" />
		</section>
		<v-dialog v-model="confirmDialog" width="350" content-class="rounded-xl">
			<v-card rounded="xl" class="pt-5">
				<v-card-text>
					<h1 class="text-body-1 mb-3 font-weight-bold">
						{{ $t('수업 평가 페이지를 정말 나가시겠어요?') }}
					</h1>
					<p
						class="text-body-2 grey--text text--darken-1 mb-5"
						v-html="
							$t(
								`수업 평가는 수업 후 24시간 동안만 가능해요.<br />지금 작성을 마치시고 <span class='font-weight-bold primary--text'>캐시 200원</span>을 받아보세요. 🙆‍♀️`,
							)
						"
					></p>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-btn color="black" text @click="goBack()">
						{{ $t('나갈래요') }}
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="confirmDialog = false">
						{{ $t('계속 작성할래요') }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import Api from '@/services/index'
import { codeToMoment } from '@/helpers/date-format'
import LessonCard from './LessonCard'
import Rating from './Rating'
import ReportIssue from './ReportIssue'

export default {
	components: {
		LessonCard,
		ReportIssue,
		Rating,
	},
	data: () => ({
		isLoading: false,
		lesson: null,
		ratings: [],
		issues: [],
		confirmDialog: false,
		forcedLeave: false,
	}),
	computed: {
		lessonId() {
			return this.$route.params.lessonId
		},
	},

	created() {
		this.get()
	},
	methods: {
		/**
		 * 수업 불러온다.
		 * 평가가 되어있는 지 확인
		 */
		async get() {
			try {
				this.isLoading = true
				const res = await Api.get(`lessons/${this.lessonId}`)
				const { lesson, ratings, issues } = res.data

				this.lesson = lesson

				this.lesson.moment = codeToMoment(
					this.lesson.lesson_date,
					this.lesson.lesson_time,
				)

				if (this.lesson.program_unit_id) {
					let unitQuery = `?active=1&sort_by=+unit&ids=${this.lesson.program_unit_id}&language=${this.language}`
					const unitResult = await Api.get(`programs/units${unitQuery}`)
					const unit = unitResult.data[0]
					this.lesson.program_name = unit.program_name
					this.lesson.unit = unit.unit
					this.lesson.unit_title = unit.title
				}

				this.ratings = ratings
				this.issues = issues
				if (
					moment() < this.lesson.moment ||
					this.lesson.moment.clone().add(24, 'hours') < moment()
				)
					throw new Error('F40402')
				if (this.ratings.length > 0) throw new Error('F40403')
				if (this.issues.length > 0) throw new Error('F40404')

				this.isLoading = false
			} catch (err) {
				let msg = this.$t(
					'알수 없는 에러가 발생했습니다.\n고객 센터로 문의주세요.',
				)
				const code = err.response ? err.response.data.code : err.message
				if (code === '40300') msg = this.$t('수업을 찾을 수 없습니다.')

				if (code === 'F40402')
					msg = this.$t('24시간이 지난 수업은 평가를 진행하실 수 없습니다.')
				if (code === 'F40403') msg = this.$t('이미 평가 완료된 수업입니다.')
				if (code === 'F40404')
					msg = this.$t('이미 튜터의 결석이 보고된 수업입니다.')
				alert(`${msg}\n\nERROR CODE: ${code}`)
				this.goBack()
			}
		},
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			if (moment() > this.lesson.moment || this.lesson.status !== 'analyzed') {
				this.$router.push({
					name: 'ReviewLessonsAll',
				})
			} else {
				if (beforeName) {
					this.$router.go(-1)
					return
				} else {
					this.$router.push({ name: 'Lessons' })
				}
				// beforeName !== 'LessonReview'
				// 	? this.$router.push({
				// 			name: 'LessonReview',
				// 			params: { lessonId: this.lessonId },
				// 	  })
				// 	: this.$router.go(-1)
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
