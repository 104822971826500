<template>
	<v-toolbar
		style="width: 100%; max-width: 400px"
		elevation="0"
		height="60"
		class="px-1"
	>
		<div
			v-for="menu in list"
			:key="menu.name"
			@click="gtagEvent(menu.gtagCategory), goTo(menu.routeName)"
			:class="{
				disabled: isLoading === true,
				'cursor-pointer grey--text': routeName !== menu.routeName,
			}"
			class="mr-4 font-16 font-weight-medium hover-pointer"
		>
			{{ menu.name }}
		</div>
		<v-spacer></v-spacer>
		<PlanButton />
	</v-toolbar>

	<!-- <section class="d-flex align-center pt-4 px-4 white">
	
		<div
			v-for="menu in list"
			:key="menu.name"
			@click="gtagEvent(menu.gtagCategory), goTo(menu.routeName)"
			:class="{
				disabled: isLoading === true,
				'cursor-pointer': routeName !== menu.routeName,
			}"
			class="cursor-pointer pa-1 mr-3"
			:style="`border-bottom: 4px solid #${
				routeName !== menu.routeName ? 'fff' : '000'
			}`"
		>
			<h1
				:class="{
					'font-weight-bold ': routeName === menu.routeName,
					'font-weight-regular grey--text': routeName !== menu.routeName,
				}"
				class="text-body-1"
			>
				{{ menu.name }}
			</h1>
		</div>
		<v-spacer></v-spacer>
		<PlanButton class="mb-2" v-if="routeName === 'Lessons'" />
	</section> -->
</template>

<script>
import PlanButton from '@/components/common/PlanButton'

export default {
	components: {
		PlanButton,
	},
	props: {
		isLoading: {
			type: Boolean,
			required: true,
			default: false,
		},
		routeName: {
			type: String,
			required: true,
			default: 'Lessons',
		},
	},
	data() {
		return {
			list: [
				// {
				// 	name: this.$t('수업 내역'),
				// 	routeName: 'Lessons',
				// 	gtagCategory: '수업 내역',
				// },

				{
					name: this.$t('월별 리포트'),
					routeName: 'MonthlyReport',
					gtagCategory: '월별 리포트',
				},
				{ name: this.$t('퀘스트'), routeName: 'Quest', gtagCategory: '퀘스트' },
			],
		}
	},
	methods: {
		gtagEvent(category) {
			// eslint-disable-next-line no-undef
			gtag('event', 'click_2home_lesson_menu', {
				event_path: `my.tella.co.kr${this.$route.path}`,
				event_category: category,
			})
		},
		goTo(name) {
			if (this.isLoading === true) {
				return
			}
			if (name === this.routeName) {
				return
			}
			this.$router.push({ name })
		},
	},
}
</script>

<style lang="scss" scoped></style>
