<template>
	<div class="px-3">
		<div class="py-3 d-flex">
			<h2 class="text-body-1">
				{{ $t('order.date-picker.title.start-date') }}
			</h2>
			<v-spacer></v-spacer>
			<StartDateTooltip />
		</div>
		<v-menu
			v-model="menu"
			:close-on-content-click="false"
			:nudge-right="40"
			transition="scale-transition"
			offset-y
			min-width="auto"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-text-field
					v-model="startDate"
					readonly
					v-bind="attrs"
					v-on="on"
					outlined
					single-line
					:placeholder="$t('order.date-picker.placeholder.select-start-date')"
					:error-messages="
						startDateError === true
							? $t('order.date-picker.form-error-msg.select-start-date')
							: ''
					"
				>
					<img
						@click="menu = true"
						slot="append"
						src="@/assets/icons/calendar-check.png"
						style="margin-top: -5px; width: 22px"
						:alt="$t('order.date-picker.img-alt.start-date-info')"
					/>
				</v-text-field>
			</template>
			<v-date-picker
				color="primary"
				no-title
				v-model="startDate"
				@input="menu = false"
				:allowed-dates="allowedDates"
			></v-date-picker>
		</v-menu>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import StartDateTooltip from './StartDateTooltip'
const R = require('ramda')

export default {
	components: {
		StartDateTooltip,
	},
	props: {
		points: {
			required: true,
			type: Array,
		},
		startDateError: {
			required: true,
			type: Boolean,
		},
	},
	data() {
		return {
			startDate: '',
			menu: false,
		}
	},
	computed: {
		lastExpiryDate() {
			const sorted = R.sortWith([R.descend(R.prop('expiry_date'))])(this.points)
			return sorted.length > 0 ? sorted[0].expiry_date : null
		},
	},
	watch: {
		startDate(val) {
			this.$emit('selectStartDate', val)
		},
	},
	methods: {
		allowedDates(val) {
			return (
				val <=
					moment(this.lastExpiryDate).add(7, 'days').format('YYYY-MM-DD') &&
				val >= moment().format('YYYY-MM-DD')
			)
		},
	},
}
</script>

<style lang="scss">
#tella-web .v-date-picker-table .v-btn.v-btn--disabled {
	color: rgba(0, 0, 0, 0.26) !important;
}
</style>
