<template>
	<div>
		<Bar />
		<Menu />
		<section>
			<v-img class="text-center" src="@/assets/images/reviews/back-mobile.png">
				<div class="pt-12" style="position: relative; z-index: 2">
					<img
						style="width: 150px"
						src="@/assets/images/reviews/stars.png"
						alt=""
					/>
					<h1
						style="margin-top: -10px"
						class="font-weight-bold white--text text-h5"
					>
						{{ $t('plan.review.title') }}
					</h1>
					<Carousel :backgroundColor="null" />
					<v-btn
						class="mt-3"
						outlined
						rounded
						x-large
						:width="language === 'ko' ? 290 : 310"
						height="60"
						color="indigo darken-6"
						style="border-width: 4px; background-color: #fff !important"
						@click="$router.push({ name: 'TrialBook' })"
						><div style="width: 100%" class="d-flex text-h5 font-weight-bold">
							<div
								v-if="language === 'ko'"
								style="
									width: 4px;
									border-radius: 3px;
									margin-top: 3px;
									margin-left: 5px;
									height: 25px;
									transform: rotate(45deg);
									background-color: #1c2187;
								"
							></div>
							<v-spacer></v-spacer>
							{{ $t('plan.review.button.book-trial') }}
						</div></v-btn
					>
				</div>
			</v-img>
		</section>
		<section class="px-3 pb-12">
			<Reviews />
			<!-- <RatingTable
				style="max-width: 750px"
				class="mx-auto px-4"
				:backgroundColor="'#fafafa'"
				:itemsPerPage="5"
			/> -->
		</section>
		<PostButton />
	</div>
</template>

<script>
import Bar from '@/components/common/plan-review-event/Bar'
import Menu from '@/components/common/plan-review-event/Menu'
import Carousel from './Carousel'
import Reviews from './Reviews'
import PostButton from './PostButton'

export default {
	components: { Bar, Menu, Carousel, Reviews, PostButton },
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
	},
	methods: {
		goToTrial() {
			window.open(`https://trial.tella.co.kr`)
		},
	},
}
</script>

<style lang="scss" scoped></style>
