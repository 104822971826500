<template>
	<div>
		<div class="d-flex align-center">
			<img class="mr-1" src="@/assets/icons/L.png" alt="" />
			<h1 class="text-body-1">
				{{ $t('order.cash-picker.title') }}
			</h1>
			<img
				@click="show = !show"
				:class="{ show }"
				class="ml-1 chevron"
				src="@/assets/icons/chevron-down.png"
				alt=""
			/>
			<v-spacer></v-spacer>
			<p class="text-body-1 grey--text text--darken-1 price">
				{{ $numberWithCommas(cashToCurrency) }} {{ currencyString }}
			</p>
		</div>
		<div class="pt-2" v-show="show">
			<v-text-field
				type="number"
				v-model.number="cash"
				outlined
				single-line
				hide-details
				class="order-picker"
				@blur="checkCash"
			>
				<template v-slot:append>
					<v-btn
						@click.native="useMax"
						color="primary"
						class="ma-0 font-weight-bold"
						depressed
					>
						{{ $t('order.cash-picker.button.use-max') }}</v-btn
					>
				</template>
			</v-text-field>
			<div class="d-flex pt-1 align-center pl-1">
				<p class="grey--text text--darken-1 text-body-2">
					{{ $t('order.cash-picker.text.available-cash') }}
				</p>
				<BonusCashTooltip :currency="currency" />
				<p class="text-body-2 grey--text text--darken-3">
					{{
						$t('order.cash-picker.text.cash', {
							cash: $numberWithCommas(bonusCash),
						})
					}}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import BonusCashTooltip from './BonusCashTooltip'
// import _ from 'lodash'

export default {
	components: {
		BonusCashTooltip,
	},
	props: {
		price: {
			required: true,
			type: Number,
		},
		currency: {
			required: true,
			type: String,
		},
		currencyString: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			show: true,
			cash: 0,
		}
	},
	computed: {
		bonusCash() {
			return this.$store.getters['member/bonusCash']
		},
		cashToCurrency() {
			return this.currency === 'JPY'
				? this.cash / 10
				: this.currency === 'USD'
				? this.cash / 1000
				: this.cash
		},
		limit() {
			const minimumPrice = Math.round(this.price * 0.6, 2)
			const rate =
				this.currency === 'JPY' ? 10 : this.currency === 'USD' ? 1000 : 1

			return Math.min(
				(this.price - minimumPrice).toFixed(2) * rate,
				this.bonusCash,
			)
		},
	},
	watch: {
		currency() {
			this.cash = 0
			this.$emit('selectCash', this.cash)
		},
		// cash() {
		// 	this.checkCash()
		// },
		limit() {
			this.cash = 0
			this.$emit('selectCash', this.cash)
		},
	},
	methods: {
		checkCash() {
			if (this.cash > this.limit) {
				this.cash = this.limit
			}
			if (this.cash < 0 || !this.cash) {
				this.cash = 0
			}
			if (this.currency === 'USD' && this.cash % 10 !== 0) {
				alert(this.$t('order.cash-picker.error-msg.usd-minimum'))
				this.cash = Math.floor(this.cash / 10, 0) * 10
			}
			if (this.currency === 'JPY' && this.cash % 10 !== 0) {
				alert(this.$t('order.cash-picker.error-msg.jpy-minimum'))
				this.cash = Math.floor(this.cash / 100, 0) * 100
			}
			this.$emit('selectCash', this.cash)
		},
		useMax() {
			this.cash = this.limit
		},
	},
}
</script>

<style lang="scss" scoped>
.chevron {
	transition: transform 0.2s ease-in-out;
}
.chevron.show {
	transform: rotate(180deg);
}
</style>
