<template>
	<div class="pb-14 new-report">
		<div
			class="pt-2 pl-3"
			v-if="fromName === 'Lessons' || inLesson === true"
			style="position: absolute; top: 0; z-index: 2"
		>
			<v-btn @click="goBack" color="white" icon
				><v-icon>mdi-arrow-left</v-icon></v-btn
			>
		</div>
		<Header
			class="white"
			:trialName="trial.name || this.$t('trial-reschedule.alternative-name')"
			:trialMoment="trial.moment"
			:trialTimezone="trial.timezone"
			:trialLanguage="trial.language"
		/>
		<Level
			class="white mb-3"
			:levelTable="levelTable"
			:trialNationality="trial.nationality"
		/>
		<Diagnosis
			class="white"
			:deliveryScore="deliveryScore"
			:comprehensionScore="comprehensionScore"
			:vocabularyScore="vocabularyScore"
			:grammarScore="grammarScore"
			:level="levelTable.level"
		/>
		<Delivery class="white" :deliveryScore="deliveryScore" />
		<Comprehension class="white" :comprehensionScore="comprehensionScore" />
		<Vocabulary
			:level="levelTable.level"
			:vocabularyScore="vocabularyScore"
			:trialName="trial.name || this.$t('trial-reschedule.alternative-name')"
			:numberOfWords="stat.tutee_number_of_words"
		/>
		<Grammar
			class="mb-3"
			:grammarScore="grammarScore"
			:sentenceStructureTable="sentenceStructureTable"
			:averageLength="stat.tutee_length_of_sentences"
			:level="levelTable.level"
		/>
		<TutorComment
			class="white mb-3"
			:tutorId="trial.tutor_id"
			:tutorName="trial.tutor_name"
			:level="levelTable.level"
		/>
		<Correction class="white" :logs="logs" />
		<LogsDialog :logs="logs" />
		<Program
			class="white mb-3"
			:level="levelTable.level"
			:purpose="report.purpose_1"
			:language="trial.language"
		/>
		<Benefit
			v-if="isPaid === false"
			class="white"
			:trialNationality="trial.nationality"
			:trialMoment="trial.moment"
			:trialName="trial.name || this.$t('trial-reschedule.alternative-name')"
		/>
		<FixedFooter :trialMoment="trial.moment" />
	</div>
</template>

<script>
import Header from './Header'
import Level from './Level'
import Diagnosis from './Diagnosis'
import Delivery from './Delivery'
import Comprehension from './Comprehension'
import Vocabulary from './Vocabulary'
import Grammar from './Grammar'
import TutorComment from './TutorComment'
import Correction from './Correction'
import LogsDialog from './LogsDialog'
import Program from './Program'
import Benefit from './Benefit'
import FixedFooter from './FixedFooter'
import Api from '@/services/index'

const R = require('ramda')

export default {
	components: {
		Header,
		Level,
		Diagnosis,
		Delivery,
		Comprehension,
		Vocabulary,
		Grammar,
		TutorComment,
		Correction,
		LogsDialog,
		Program,
		Benefit,
		FixedFooter,
	},
	props: {
		trial: {
			required: true,
		},
		report: {
			required: true,
		},
		stat: {
			required: true,
		},
		logs: {
			required: true,
		},
		isPaid: {
			required: true,
			type: Boolean,
		},
	},
	computed: {
		fromName() {
			const fromRouter = this.$store.getters['device/fromRouter']
			return fromRouter ? fromRouter.name : ''
		},
		inLesson() {
			const query = this.$route.query['in-lesson']
			return query === true || query === 'true'
		},
		sentenceStructureTable() {
			const count = Math.max(
				Math.min(15, Math.floor(this.stat.tutee_length_of_sentences)),
				3,
			)
			const list = [
				{
					score: 1,
					count: 3,
					example: 'I like strawberries.',
					description: this.$t('trial-report.sentense-structure.description-1'),
				},
				{
					score: 2,
					count: 4,
					example: 'They are smart students.',
					description: this.$t('trial-report.sentense-structure.description-2'),
				},
				{
					score: 2,
					count: 5,
					example: 'You can go home now.',
					description: this.$t('trial-report.sentense-structure.description-3'),
				},
				{
					score: 3,
					count: 6,
					example: 'I am not sure about that.',
					description: this.$t('trial-report.sentense-structure.description-4'),
				},
				{
					score: 3,
					count: 7,
					example: 'I will include you in the project.',
					description: this.$t('trial-report.sentense-structure.description-5'),
				},
				{
					score: 4,
					count: 8,
					example: 'My teacher told him to lower his voice.',
					description: this.$t('trial-report.sentense-structure.description-6'),
				},
				{
					score: 4,
					count: 9,
					example: 'Once you finish your homework, you can watch TV.',
					description: this.$t('trial-report.sentense-structure.description-7'),
				},
				{
					score: 5,
					count: 10,
					example:
						'My chocolate ice-cream melted and fell on the concrete ground.',
					description: this.$t('trial-report.sentense-structure.description-8'),
				},
				{
					score: 5,
					count: 11,
					example:
						'Unfortunately, I missed several calls while I was working this afternoon.',
					description: this.$t('trial-report.sentense-structure.description-9'),
				},
				{
					score: 5,
					count: 12,
					example:
						'Jennifer, whose mother you met yesterday, would like to talk to you.',
					description: this.$t(
						'trial-report.sentense-structure.description-10',
					),
				},
				{
					score: 5,
					count: 13,
					example:
						'We had to take a moment together and look at the big picture.',
					description: this.$t(
						'trial-report.sentense-structure.description-11',
					),
				},
				{
					score: 5,
					count: 14,
					example:
						'The first thing we assumed is that they might have lots of useful tips.',
					description: this.$t(
						'trial-report.sentense-structure.description-12',
					),
				},
				{
					score: 5,
					count: 15,
					example:
						'My personal favorite, which makes sense on some level, is to synthesize all the ideas.',
					description: this.$t(
						'trial-report.sentense-structure.description-13',
					),
				},
			]
			const find = R.find(R.propEq('count', count))(list)
			return find ? find : list[0]
		},
		deliveryScore() {
			return 2 * this.report.delivery
		},
		comprehensionScore() {
			return 2 * this.report.comprehension
		},
		vocabularyScore() {
			return 2 * this.report.vocabulary
		},
		grammarScore() {
			return this.report.grammar + this.sentenceStructureTable.score
		},
		levelTable() {
			const sum =
				12.5 * this.deliveryScore +
				12.5 * this.comprehensionScore +
				12.5 * this.vocabularyScore +
				12.5 * this.grammarScore
			if (sum >= 475)
				return {
					score: sum,
					level: 7,
					name: 'Lower Advanced',
					description1: this.$t(
						'trial-report.level-description.7-description-1',
					),
					description2: this.$t(
						'trial-report.level-description.7-description-2',
					),
					opic: 'IH~AL',
					toeicSpeaking: 'Level 6~7',
					toeic: '880~945',
					percentage: this.$t('trial-report.level.top-percentage', {
						number: 11,
					}),
				}
			if (sum >= 375)
				return {
					score: sum,
					level: 6,
					name: 'Upper Intermediate',
					description1: this.$t(
						'trial-report.level-description.6-description-1',
					),
					description2: this.$t(
						'trial-report.level-description.6-description-2',
					),
					opic: 'IM3',
					toeicSpeaking: 'Level 6-7',
					toeic: '745~875',
					percentage: this.$t('trial-report.level.top-percentage', {
						number: 23,
					}),
				}

			if (sum >= 275)
				return {
					score: sum,
					level: 5,
					name: 'Intermediate',
					description1: this.$t(
						'trial-report.level-description.5-description-1',
					),
					description2: this.$t(
						'trial-report.level-description.5-description-2',
					),
					opic: 'IM1-2',
					toeicSpeaking: 'Level 5',
					toeic: '535~740',
					percentage: this.$t('trial-report.level.top-percentage', {
						number: 40,
					}),
				}
			if (sum >= 175)
				return {
					score: sum,
					level: 4,
					name: 'Lower Intermediate',
					description1: this.$t(
						'trial-report.level-description.4-description-1',
					),
					description2: this.$t(
						'trial-report.level-description.4-description-2',
					),
					opic: 'IL',
					toeicSpeaking: 'Level 4-5',
					toeic: '350~600',
					percentage: this.$t('trial-report.level.top-percentage', {
						number: 60,
					}),
				}
			return {
				score: sum,
				level: 3,
				name: 'Elementary',
				description1: this.$t('trial-report.level-description.3-description-1'),
				description2: this.$t('trial-report.level-description.3-description-2'),
				opic: 'NH',
				toeicSpeaking: 'Level 3-4',
				toeic: '250~530',
				percentage: this.$t('trial-report.level.top-percentage', {
					number: 77,
				}),
			}
		},
	},
	created() {
		this.$store.commit('device/SET_BACKGROUND', 'grey lighten-1', {
			root: true,
		})
		this.checkReport()
	},
	destroyed() {
		this.$store.commit('device/SET_BACKGROUND', 'white', {
			root: true,
		})
	},
	methods: {
		async checkReport() {
			await Api.patch(`trials/reports/${this.report.id}`, { isChecked: 1 })
		},
		goBack() {
			if (!this.fromName) {
				this.$router.push({ name: 'ReviewLessonsLevelTest' })
			}
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped>
.new-report {
	width: 100%;
	max-width: 375px;
	margin: 0 auto;
}
</style>
