<template>
	<div v-if="lesson" class="d-flex align-center">
		<h3
			class="px-2 my-title font-12 font-weight-bold rounded-pill mr-1"
			:class="{
				'primary--text primary--1':
					lesson.status !== 'analyzed-absent' && lesson.lessonType === 0,
				'emerald--text emrald--1':
					lesson.status !== 'analyzed-absent' && lesson.lessonType === 1,
				'grey--text text--darken-3 grey': lesson.status === 'analyzed-absent',
			}"
			style="padding-top: 2px; padding-bottom: 2px"
			data-test="typeTag"
		>
			{{ lesson.lessonType === 0 ? 'Talk' : 'Call' }}
		</h3>
		<p
			class="font-15 my-text font-weight-medium"
			:class="{
				'text-decoration-line-through': lesson.status === 'analyzed-absent',
			}"
		>
			<span data-test="startTime">
				{{ formattedDate }}({{ lesson.dt.toFormat('ccc') }})
				{{ lesson.dt.toFormat('H:mm') }}</span
			>
			<span data-test="endTime" v-if="lesson.status === 'appointed'">
				- {{ lesson.endDt.toFormat('H:mm') }}
			</span>
			<span data-test="timezone"> ({{ timezone }})</span>
		</p>
	</div>
</template>

<script>
export default {
	props: {
		lesson: {
			type: Object,
		},
	},
	computed: {
		formattedDate() {
			return this.lesson.status === 'appointed'
				? this.lesson.dt.toFormat('DD').substr(5)
				: this.lesson.dt.toFormat('DD')
		},
		language() {
			return this.$store.getters['member/language']
		},
		timezone() {
			return this.$store.getters['member/timezone']
		},
		lessonMinutes() {
			return this.lesson ? (this.lesson.lessonType === 0 ? 25 : 15) : 0
		},
	},
}
</script>

<style lang="scss" scoped>
.absent {
}
</style>
