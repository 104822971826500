<template>
	<div>
		<div id="trial-correction" class="pt-14 pb-10">
			<h1 class="title2 mb-4 px-5">
				{{ $t('trial-report.correction.title') }}
			</h1>
			<p class="text px-5 white-space-pre">
				{{ $t('trial-report.correction.text') }}
			</p>
			<swiper class="swiper" :options="swiperOption">
				<swiperSlide
					v-for="(correction, i) in corrections"
					:key="`correction-${i}`"
				>
					<div class="py-5">
						<v-card class="correction-card">
							<p class="correction-text">
								<span class="after" v-html="correction.html"></span>
								<AudioPlayer
									class="d-inline-block ml-1"
									:script="correction.origin"
									:color="'after'"
									:text="true"
								/>
							</p>
						</v-card>
					</div>
				</swiperSlide>
			</swiper>
		</div>
	</div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import AudioPlayer from './AudioPlayer'
// const jsdiff = require('diff')

export default {
	components: {
		swiper,
		swiperSlide,
		AudioPlayer,
	},
	props: {
		logs: {
			required: true,
			type: Array,
		},
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: 1.2,

				centeredSlides: true,
				spaceBetween: 15,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			},
		}
	},
	computed: {
		corrections() {
			const isCorrected = log =>
				log.type === 'Tutor' &&
				log.content.indexOf('>>>') !== -1 &&
				log.content.indexOf('오류 수정 알아보기!') === -1 &&
				log.content.indexOf('https://goo.gl/J5MW2N') === -1

			const makeBeforeAndAfter = log => {
				let html, origin
				html = log.content
				html = html.replace('Or >>>', '')
				html = html.replace('>>>>', '')
				html = html.replace('>>>', '')
				html = html.replace('>>', '')
				html = html.replace(/\*/g, "<span class='red--text'>*</span>")
				origin = log.content
				origin = origin.replace('Or >>>', '')
				origin = origin.replace('>>>>', '')
				origin = origin.replace('>>>', '')
				origin = origin.replace('>>', '')
				origin = origin.replace(/\*/g, '')
				return { origin, html }
			}
			return this.logs
				.filter(log => isCorrected(log))
				.map(log => makeBeforeAndAfter(log))
		},
	},
	methods: {
		// beforeDiff(a, b) {
		// 	let diff = jsdiff.diffWords(a, b)
		// 	let span = null
		// 	diff.forEach(function (part) {
		// 		if (part.removed) {
		// 			span === null
		// 				? (span = `<span class='before'>${part.value}</span>`)
		// 				: (span += `<span class='before'>${part.value}</span>`)
		// 		} else if (!part.added) {
		// 			span === null
		// 				? (span = '<span>' + part.value + '</span>')
		// 				: (span = span + '<span>' + part.value + '</span>')
		// 		}
		// 	})
		// 	return span
		// },
		// afterDiff(a, b) {
		// 	let diff = jsdiff.diffWords(a, b)
		// 	let span = null
		// 	diff.forEach(function (part) {
		// 		if (part.added) {
		// 			span === null
		// 				? (span = `<span class='after'>${part.value}</span>`)
		// 				: (span += `<span class='after'>${part.value}</span>`)
		// 		} else if (!part.removed) {
		// 			span === null
		// 				? (span = '<span>' + part.value + '</span>')
		// 				: (span = span + '<span>' + part.value + '</span>')
		// 		}
		// 	})
		// 	return span
		// },
	},
}
</script>

<style lang="scss" scoped>
.correction-card {
	padding: 29px 7px 36px 24px;
	border-radius: 16px;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1) !important;
	background-color: #fff;
}

.before-title {
	font-size: 14px !important;
	font-weight: bold !important;
	line-height: 1.79 !important;
	letter-spacing: -0.44px !important;
	color: #191f28 !important;
}
.correction-text {
	font-size: 13px !important;
	font-weight: normal;
	line-height: 1.85 !important;
	letter-spacing: -0.3px !important;
}

.after-title {
	font-size: 13px !important;
	font-weight: bold !important;
	line-height: 1.92 !important;
	letter-spacing: -0.41px !important;
	text-align: center;
	color: #fff;
	padding: 1px 13px 0px 11px;
	border-radius: 12.5px;
	background-color: #0abe8b;
	margin-bottom: 11px;
}
.chat-title {
	font-size: 18px !important;
	font-weight: 600 !important;
	line-height: 1.17 !important;
	letter-spacing: -0.56px !important;
	color: #191f28 !important;
}
.chat-text {
	font-size: 14px !important;
	font-weight: normal !important;
	line-height: 1.79 !important;
	letter-spacing: -0.44px !important;
	color: #485363 !important;
}
</style>

<style lang="scss">
// #trial-correction {
// .correction-text .before {
// 	color: #e06a66 !important;
// 	font-weight: 600 !important;
// }
// .correction-text .after {
// 	color: #00a882 !important;
// 	font-weight: 600 !important;
// }
// }
</style>
