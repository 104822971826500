<template>
	<div>
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('예약 내역') }}</h1>
				</v-col>
			</v-row>
		</section>
		<section class="pt-5">
			<div v-for="item in history" :key="item.id">
				<List :history="item" />
			</div>
			<v-btn depressed block v-if="page < totalPage" @click="getHistory()">
				더 보기
			</v-btn>
			<div v-if="noHistory">
				<p class="text-body-2 grey--text pt-10 text-center font-weight-medium">
					{{ $t('예약하신 수업 내역이 없습니다.') }}
				</p>
			</div>
		</section>
	</div>
</template>

<script>
// import moment from 'moment-timezone'
import Api from '@/services/index'
import List from './List'
const R = require('ramda')

export default {
	components: {
		List,
	},
	data: () => ({
		points: [],
		history: [],
		page: 0,
		totalPage: 0,
		noHistory: false,
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		paymentId() {
			return this.$route.query['payment-id']
		},
		groupPointTypeId() {
			return this.$route.query['group-point-type-id']
		},
		passCouponMemberId() {
			return this.$route.query['pass-coupon-member-id']
		},
		pointId() {
			return this.$route.query['point-id']
		},
		pointIds() {
			return R.pipe(
				R.map(p => p.id),
				R.uniq(),
			)(this.points)
		},
	},
	created() {
		this.get()
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			const query = {
				['payment-id']: this.paymentId,
				['pass-coupon-member-id']: this.passCouponMemberId,
				['group-point-type-id']: this.groupPointTypeId,
				['point-id']: this.pointId,
			}
			beforeName !== 'PassDetail'
				? this.$router.push({ name: 'PassDetail', query })
				: this.$router.go(-1)
		},
		async get() {
			this.isLoading = true
			await this.getPoints()
			await this.getHistory()
			this.isLoading = false
		},
		async getHistory() {
			this.page++
			const result = await Api.get(
				`points/history/page/${this.page}?limit=10&member-id=${this.memberId}&point-ids=${this.pointIds}&sort-by=-point_history.created_at`,
			)

			this.totalPage = result.headers['x-page-count']
			if (result.data.length > 0) {
				this.history = R.concat(this.history, result.data)
			}
			if (this.page === 0 && result.date.length === 0) {
				this.noHistory = true
			}
		},
		async getPoints() {
			let query = `?member-id=${this.memberId}&active=1`
			if (this.paymentId) query += `&payment-id=${this.paymentId}`
			else if (this.groupPointTypeId)
				query += `&group-point-type-id=${this.groupPointTypeId}`
			else if (this.passCouponMemberId)
				query += `&pass-coupon-member-id=${this.passCouponMemberId}`
			if (this.pointId) query += `&id=${this.pointId}`
			const res = await Api.get(`points${query}`)
			this.points = res.data

			if (this.pointIds.length === 0) {
				alert(this.$t('수강권 목록을 찾지 못했습니다.'))
				this.$router.push({
					name: 'Pass',
				})
			}

			this.isLoading = false
		},
	},
}
</script>

<style lang="scss" scoped></style>
