<template>
	<div>
		<v-dialog v-if="userDevice === 'desktop'" v-model="dialog" width="360">
			<v-card style="height: 800px">
				<template v-if="isLoading === false && unit">
					<div class="pb-4 pt-3 pl-4 pr-2 d-flex align-center">
						<h1
							class="font-16 font-weight-medium text-truncate"
							style="width: calc(100% - 40px)"
						>
							{{ unit.unit }}. {{ title }}
						</h1>
						<v-btn small icon fab @click="dialog = false"
							><v-icon>mdi-window-close</v-icon></v-btn
						>
					</div>
					<Guide
						style="height: calc(100% - 150px); overflow-y: scroll"
						:unit="unit"
						class="px-4 pb-4"
					/>
					<div class="px-4 pb-4">
						<v-btn
							@click="$emit('goNext', unitId)"
							depressed
							height="55"
							block
							color="primary"
							><span class="font-16 font-weight-medium"
								>유닛 선택하기</span
							></v-btn
						>
					</div>
				</template>
			</v-card>
		</v-dialog>
		<v-bottom-sheet v-else v-model="dialog" scrollable>
			<v-sheet class="rounded-t-lg">
				<template v-if="isLoading === false && unit">
					<div class="pb-4 pt-4 pl-4 d-flex align-center">
						<h1
							style="width: calc(100% - 50px)"
							class="font-16 font-weight-medium text-truncate"
						>
							{{ unit.unit }}. {{ title }}
						</h1>
						<v-btn small icon fab @click="dialog = false"
							><v-icon>mdi-chevron-down</v-icon></v-btn
						>
					</div>
					<Guide
						style="height: calc(100% - 150px); overflow-y: scroll"
						:unit="unit"
						class="px-4 pb-4"
					/>
					<div class="px-4 pb-4">
						<v-btn
							@click="$emit('goNext', unitId)"
							depressed
							height="55"
							block
							color="primary"
							><span class="font-16 font-weight-medium"
								>유닛 선택하기</span
							></v-btn
						>
					</div>
				</template>
			</v-sheet>
		</v-bottom-sheet>
	</div>
</template>

<script>
import Guide from './guide/Wrap'
import studyApi from '@/services/study'

export default {
	components: {
		Guide,
	},
	props: {
		originDialog: {
			required: true,
			type: Boolean,
		},
		unitId: {
			type: Number,
		},
	},
	data: () => ({
		dialog: false,
		unit: null,
		isLoading: false,
	}),
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		language() {
			return this.$store.getters['member/language']
		},
		title() {
			return JSON.parse(this.unit.unitTitle)[this.language]
		},
	},
	watch: {
		dialog(val) {
			if (val === true) this.getGuide()
		},
		originDialog() {
			this.dialog = !this.dialog
		},
	},
	methods: {
		async getGuide() {
			this.isLoading = true
			this.unit = await studyApi.getUnit(this.unitId)

			this.isLoading = false
		},
	},
}
</script>

<style lang="scss" scoped></style>
