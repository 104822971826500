<template>
	<div class="background pt-12 pb-15" id="scroll-target">
		<v-toolbar
			style="
				width: 100%;
				max-width: 400px;
				position: fixed;
				top: 0px;
				z-index: 3;
			"
			elevation="0"
			height="60"
			class="px-1 white"
		>
			<div
				@click="$router.push({ name: 'AppointedLessons' })"
				class="mr-4 font-16 grey--text font-weight-medium hover-pointer"
			>
				{{ $t('page.reviewList.toolbar.menu.예약') }}
			</div>
			<div class="font-16 font-weight-medium hover-pointer">
				{{ $t('page.reviewList.toolbar.menu.복습') }}
			</div>
			<v-spacer></v-spacer>
			<CalendarButton />
		</v-toolbar>

		<Menu class="px-4 mt-3 mb-8" />
		<div class="px-4" v-if="isLoading === true">
			<LoadingLessonCard v-for="i in 2" :key="`loading-${i}`" class="mb-8" />
		</div>
		<div class="px-4" v-if="isLoading === false && isCountLoading === false">
			<div v-for="lesson in lessons" :key="lesson.id">
				<PassedLessonCard :lesson="lesson" class="mb-8" />
			</div>
		</div>

		<Empty
			:category="'all'"
			v-if="
				isLoading === false && isCountLoading === false && lessons.length === 0
			"
			data-test="empty"
		/>
		<Pagination
			class="mt-8 mb-5"
			v-if="isCountLoading === false && isLoading === false && totalPage > 0"
			ref="pagination"
			@changePage="changePage"
			:page="page"
			:totalPage="totalPage"
		/>
	</div>
</template>

<script>
import scheduleApi from '@/services/schedule'
import PassedLessonCard from '@/components/lesson-card/passed/Wrap'
import LoadingLessonCard from '@/components/lesson-card/Loading'
import Menu from './Menu'
import Pagination from './Pagination'
import Empty from './Empty'
import CalendarButton from './CalendarButton'

export default {
	components: {
		Menu,
		PassedLessonCard,
		LoadingLessonCard,
		Pagination,
		Empty,
		CalendarButton,
	},
	data: () => ({
		totalCount: 0,
		totalPage: 0,
		limit: 10,
		// pageGroup: 0,
		isCountLoading: false,
		isLoading: false,
		lessons: [],
		scrollTarget: null,
	}),
	computed: {
		page() {
			const initPage = parseInt(this.$route.query.page)
			if (isNaN(initPage) === true) {
				return 1
			}
			if (initPage > this.totalPage) {
				return 1
			}
			return initPage
		},
	},
	watch: {
		page() {
			this.getLessons()
		},
	},
	created() {
		this.getCount()
	},
	mounted() {
		this.scrollTarget = document.getElementById('scroll-target')
	},
	methods: {
		async getCount() {
			this.isCountLoading = true
			this.totalCount = await scheduleApi.getCount({
				status: 'analyzed,analyzed-absent',
			})
			this.totalPage = Math.ceil(this.totalCount / this.limit)
			this.isCountLoading = false
			this.getLessons()
		},
		async getLessons() {
			this.isLoading = true
			this.lessons = await scheduleApi.getLessons({
				status: 'analyzed,analyzed-absent',
				page: this.page,
				limit: this.limit,
			})

			const lessonIds = this.lessons.map(l => l.id).toString()
			const ratings = await scheduleApi.getRatings({ lessonIds })

			this.lessons.forEach(l => {
				const alreadyRated = ratings.find(r => r.lessonId === l.id)
				l.ratingScore = alreadyRated ? alreadyRated.score : null
			})
			this.isLoading = false
			this.goTop()
		},
		changePage(page) {
			this.$router.push({ name: this.$route.name, query: { page } })
		},
		goTop() {
			if (this.scrollTarget) {
				this.scrollTarget.scrollTop = 0
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.background {
	background-color: #f2f4f6;
	min-height: calc(var(--vh, 1vh) * 100);
	overflow-y: auto;
}

// /* iOS only */
// @supports (-webkit-touch-callout: none) {
// 	.background {
// 		height: -webkit-fill-available;
// 	}
// }
</style>
