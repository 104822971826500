<template>
	<v-card flat rounded="xl" color="primary" class="">
		<div class="px-5 py-2">
			<v-chip small color="white" v-if="selectedType === 0"
				>💬&nbsp;&nbsp;Talk</v-chip
			>
			<v-chip small color="white" v-else>📞&nbsp;&nbsp;Call</v-chip>
			<div>
				<v-icon color="white" class="text-h6">mdi-clock-outline</v-icon>
				<span
					v-if="selectedMoment === null"
					class="text-body-2 white--text mt-1 font-weight-bold"
				>
					{{ $t('시간을 선택하세요.') }}
				</span>
				<span class="text-body-2 white--text mt-1 font-weight-bold" v-else>
					{{ selectedMoment.format(this.dateformat) }}
					{{ selectedMoment.format('H:mm') }}
					~
					{{
						selectedMoment
							.clone()
							.add(selectedType === 0 ? 25 : 15, 'minutes')
							.format('H:mm')
					}}
				</span>
			</div>
		</div>
		<div class="px-5 py-2 white" v-if="$route.name === 'TimeFirstStep2'">
			<div class="selected-tutor-wrap">
				<div class="d-flex align-center" v-if="selectedTutor === null">
					<v-avatar size="25" color="grey"></v-avatar>
					<p class="text-body-2 ml-1">{{ $t('튜터를 선택하세요.') }}</p>
				</div>
				<div class="d-flex align-center" v-else>
					<TutorAvatar
						:size="25"
						:id="selectedTutor.id"
						:name="selectedTutor.name"
						:type="selectedType"
					/>
					<p class="text-body-2 ml-1">{{ selectedTutor.name_en }}</p>
				</div>
			</div>
		</div>
	</v-card>
</template>

<script>
import TutorAvatar from '@/components/common/TutorAvatar'

export default {
	components: {
		TutorAvatar,
	},
	computed: {
		selectedType() {
			return this.$store.getters['book/selectedType']
		},
		selectedTutor() {
			return this.$store.getters['book/selectedTutor']
		},
		selectedMoment() {
			return this.$store.getters['book/selectedMoment']
		},
		dateformat() {
			return this.$store.getters['member/dateformat']
		},
	},
}
</script>

<style lang="scss" scoped></style>
