<template>
	<div class="d-flex align-center grey lighten-3 py-2 px-4">
		<div
			:class="{ 'primary--text': selectedLevel === '전체' }"
			class="font-14 cursor-pointer"
			data-test="전체"
			@click="selectLevel('전체')"
		>
			전체
		</div>
		<div
			:class="{ 'primary--text': selectedLevel === level }"
			v-for="level in levelList"
			:key="level"
			:data-test="level"
			@click="selectLevel(level)"
			class="ml-4 font-14 cursor-pointer"
		>
			레벨 {{ level }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		programs: {
			required: true,
			type: Array,
		},
	},
	data() {
		return {
			selectedLevel: '전체',
		}
	},
	computed: {
		levelList() {
			let result = []
			this.programs
				.map(p => p.programLevel.split('|'))
				.forEach(levels => {
					result.push(...levels)
				})
			const set = new Set(result)
			const deduplicated = [...set]
			// console.log(deduplicated)
			return deduplicated
		},
	},
	watch: {
		levelList() {
			this.selectLevel('전체')
		},
	},
	created() {
		this.selectLevel('전체')
	},
	methods: {
		selectLevel(level) {
			this.selectedLevel = level
			this.$emit('selectLevel', level)
		},
	},
}
</script>

<style lang="scss" scoped></style>
