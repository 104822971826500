<template>
	<div>
		<v-btn
			@click="signup"
			block
			large
			depressed
			color="facebook"
			:disabled="isLoading"
		>
			<p class="text-body-1 white--text" style="line-height: unset !important">
				<img
					src="@/assets/images/logo/facebook_blank.png"
					alt=""
					style="width: 25px; margin-right: 2px; margin-bottom: -3px"
				/>
				{{ $t('sign-up.button.sign-up-with-facebook') }}
			</p>
			<v-spacer></v-spacer>
		</v-btn>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="errDialogPersistent"
			:numberOfButton="errNumberOfButton"
			:title="errTitle"
			:text="errMsg"
			:actionColor="errActionColor"
			:action="errAction"
			:actionText="errActionText"
		/>
	</div>
</template>

<script>
import facebook from '@/services/facebook'
import Api from '@/services/index'
import Dialog from '@/components/common/Dialog'

export default {
	components: {
		Dialog,
	},
	props: {
		isLoading: {
			required: true,
			type: Boolean,
		},
	},
	data() {
		return {
			facebookToken: null,
			authFacebookId: null,
			// === 에러
			errorDialog: false,
			errTitle: '',
			errMsg: '',
			errNumberOfButton: 1,
			errDialogPersistent: false,
			errActionColor: 'primary',
			errAction: this.closeErrDialog,
			errActionText: this.$t('common.modal.button.confirm'),
		}
	},
	methods: {
		gtagEvent(category) {
			const params = {
				event_path: `my.tella.co.kr${this.$route.path}`,
				event_category: category,
			}
			// eslint-disable-next-line no-undef
			gtag('event', 'try_signup', params)
		},
		async signup() {
			try {
				this.gtagEvent('facebook')
				this.$emit('loading', true)
				const { token, email, name, authFacebookId } = await facebook.get()
				// 이메일, auth id 중복검사
				const [emailCountRes, authFacebookCountRes] = await Promise.all([
					Api.get(`members/count?email=${email}`),
					Api.get(`members/count?auth-facebook-id=${authFacebookId}`),
				])
				if (emailCountRes.data > 0) throw new Error('email duplicated')
				if (authFacebookCountRes.data > 0)
					throw new Error('authFacebookId duplicated')

				this.$router.push({
					name: 'SignUpFormWithFacebook',
					params: {
						facebookToken: token,
						authFacebookId: authFacebookId,
						emailFromSocial: email,
						nameFromSocial: name,
					},
				})
			} catch (err) {
				const errCode = err.message && err.message ? err.message : 'FK20BV9'
				this.errorHandler(errCode)
			} finally {
				this.$emit('loading', false)
			}
		},
		errorHandler(errorCode) {
			switch (errorCode) {
				case 'facebook error':
					this.errTitle = this.$t(
						'sign-up-facebook.error.error-from-facebook-auth-server',
					)
					break
				case 'email duplicated':
					this.errTitle = this.$t('sign-up-facebook.error.email-duplicated')
					break
				case 'authFacebookId duplicated':
					this.errTitle = this.$t(
						'sign-up-facebook.error.facebook-id-duplicated',
					)
					break
				default:
					this.errTitle = this.$t(
						'sign-up-facebook.error.default-error-message',
						{ errorCode },
					)
			}
			this.errorDialog = true
		},
		closeErrDialog() {
			this.errorDialog = !this.errorDialog
		},
	},
}
</script>

<style lang="scss" scoped></style>
