<template>
	<v-row class="py-2" no-gutters align="center">
		<v-col cols="2">
			<v-btn fab icon text small @click="goBack()">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
		</v-col>
		<v-col cols="8">
			<h1
				class="
					text-center text-body-1
					font-weight-bold
					grey--text
					text--darken-3
				"
			>
				{{ $t('order.bar-title.pay') }}
			</h1>
		</v-col>
	</v-row>
</template>

<script>
export default {
	methods: {
		goBack() {
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped></style>
