<template>
	<div v-if="isLoading === false">
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('나의 수강권') }}</h1>
				</v-col>
			</v-row>
		</section>
		<section class="grey lighten-5 pa-5">
			<div
				:class="{
					grey: status !== $t('수강중'),
					primary: status === $t('수강중'),
				}"
				style="padding-top: 2px; padding-bottom: 2px"
				class="white--text d-inline-block rounded-pill text-body-2 px-3"
			>
				{{ status }}
			</div>
			<div class="pa-3">
				<h2 class="text-h6 font-weight-bold mb-5">
					{{ name }}
				</h2>
				<div v-for="point in points" :key="point.id" class="py-3">
					<div
						class="mb-1 text-body-2 font-weight-medium"
						v-html="pointTitle(point)"
					></div>
					<div class="d-flex align-center">
						<p class="text-body-2">
							{{
								$t('잔여 %{reaminingPoints}개 / 총 %{assignedPoints}회', {
									reaminingPoints: point.remaining_points,
									assignedPoints: point.assigned_points,
								})
							}}
						</p>
						<v-spacer></v-spacer>
						<p class="text-caption grey--text text--darken-1">
							{{ point.start_date }}~{{ point.expiry_date }}
						</p>
					</div>
				</div>
			</div>
		</section>

		<section class="pa-5">
			<h2 class="text-h6 mb-1 font-weight-medium">{{ $t('상세 정보') }}</h2>
			<div
				@click="
					$router.push({
						name: 'PassHistory',
						query: {
							['payment-id']: paymentId,
							['pass-coupon-member-id']: passCouponMemberId,
							['group-point-type-id']: groupPointTypeId,
							['point-id']: pointId,
						},
					})
				"
				class="d-flex align-center py-4"
				style="border-bottom: 1px solid #e5e5e5"
			>
				<h3 class="text-body-1">{{ $t('예약 내역') }}</h3>
				<v-spacer></v-spacer>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
		</section>
		<section
			class="pa-5"
			v-if="
				(paymentId || passCouponMemberId || groupPointTypeId) &&
				name !== $t('보너스 수강권')
			"
		>
			<h2 class="text-h6 mb-1 font-weight-medium">
				{{ $t('증명 서류 발급') }}
			</h2>

			<div
				class="d-flex align-center py-4"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('Certificate')"
				v-if="paymentId || passCouponMemberId"
			>
				<h3 class="text-body-1">{{ $t('수강증') }}</h3>
				<v-spacer></v-spacer>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
				<!-- <p class="text-body-1 grey--text pr-1">준비중</p> -->
			</div>
			<div
				v-if="paymentId"
				class="d-flex align-center py-4"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('Receipt')"
			>
				<h3 class="text-body-1">{{ $t('영수증') }}</h3>
				<v-spacer></v-spacer>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
				<!-- <p class="text-body-1 grey--text pr-1">준비중</p> -->
			</div>
			<div
				class="d-flex align-center py-4"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('Attendance')"
			>
				<h3 class="text-body-1">{{ $t('출석증') }}</h3>
				<v-spacer></v-spacer>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
				<!-- <p class="text-body-1 grey--text pr-1">준비중</p> -->
			</div>
		</section>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import Api from '@/services/index'
const R = require('ramda')

export default {
	data: () => ({
		isLoading: false,
		points: [],
	}),
	computed: {
		status() {
			const kstMoment = moment.tz('Asia/Seoul')
			const startDate = R.sortWith([R.ascend(R.prop('start_date'))])(
				this.points,
			)[0].start_date
			const endDate = R.sortWith([R.descend(R.prop('expiry_date'))])(
				this.points,
			)[0].expiry_date

			return startDate < kstMoment.format('YYYY-MM-DD') &&
				endDate > kstMoment.format('YYYY-MM-DD')
				? this.$t('pass.name.present')
				: startDate > kstMoment.format('YYYY-MM-DD') &&
				  endDate > kstMoment.format('YYYY-MM-DD')
				? this.$t('pass.name.future')
				: this.$t('pass.name.past')
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		paymentId() {
			return this.$route.query['payment-id']
		},
		groupPointTypeId() {
			return this.$route.query['group-point-type-id']
				? this.$route.query['group-point-type-id'].split('@')[0]
				: undefined
		},
		createdDate() {
			return this.$route.query['group-point-type-id']
				? this.$route.query['group-point-type-id'].split('@')[1]
				: undefined
		},
		passCouponMemberId() {
			return this.$route.query['pass-coupon-member-id']
		},
		pointId() {
			return this.$route.query['point-id']
		},
		talkAssigned() {
			return R.pipe(
				R.filter(p => p.type === 0 && p.is_bonus === 0),
				R.map(p => p.assigned_points),
				R.sum(),
			)(this.points)
		},
		callAssigned() {
			return R.pipe(
				R.filter(p => p.type === 1 && p.is_bonus === 0),
				R.map(p => p.assigned_points),
				R.sum(),
			)(this.points)
		},
		name() {
			return this.talkAssigned > 0 && this.callAssigned > 0
				? this.$t(`톡 %{talkAssigned}회 & 콜 %{callAssigned}회 수강권`, {
						talkAssigned: this.talkAssigned,
						callAssigned: this.callAssigned,
				  })
				: this.talkAssigned > 0
				? this.$t(`25분톡 %{talkAssigned}회 수강권`, {
						talkAssigned: this.talkAssigned,
				  })
				: this.callAssigned > 0
				? this.$t(`15분콜 %{callAssigned}회 수강권`, {
						callAssigned: this.callAssigned,
				  })
				: this.$t('보너스 수강권')
		},
	},
	created() {
		if (
			!this.paymentId &&
			!this.passCouponMemberId &&
			!this.groupPointTypeId &&
			!this.pointId
		) {
			alert(this.$t('접근할 수 없는 경로입니다.'))
			this.goBack()
		}
		this.get()
	},
	methods: {
		pointTitle(p) {
			return p.type === 0
				? p.is_bonus === 1
					? `💬&nbsp;&nbsp;${this.$t('25분톡 보너스 수강권')}`
					: `💬&nbsp;&nbsp;${this.$t('25분톡 수강권')}`
				: p.is_bonus === 1
				? `📞&nbsp;&nbsp;${this.$t('15분콜 보너스 수강권')}`
				: `📞&nbsp;&nbsp;${this.$t('15분콜 수강권')}`
		},
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			beforeName !== 'Pass'
				? this.$router.push({ name: 'Pass' })
				: this.$router.go(-1)
		},
		async get() {
			this.isLoading = true
			let query = `?member-id=${this.memberId}&active=1`
			if (this.paymentId) query += `&payment-id=${this.paymentId}`
			else if (this.groupPointTypeId)
				query += `&group-point-type-id=${this.groupPointTypeId}&created-date=${this.createdDate}`
			else if (this.passCouponMemberId)
				query += `&pass-coupon-member-id=${this.passCouponMemberId}`
			if (this.pointId) query += `&id=${this.pointId}`
			const res = await Api.get(`points${query}`)
			this.points = res.data
			this.isLoading = false
		},
		goTo(routeName) {
			let query = {}
			if (this.paymentId) query['payment-id'] = this.paymentId
			else if (this.groupPointTypeId)
				query['group-point-type-id'] = this.groupPointTypeId
			else if (this.passCouponMemberId)
				query['pass-coupon-member-id'] = this.passCouponMemberId
			else query['point-id'] = this.points[0].id

			routeName === 'Receipt'
				? this.$router.push({
						name: 'Receipt',
						query: {
							'payment-id': this.paymentId,
						},
				  })
				: this.$router.push({
						name: routeName,
						query,
				  })
		},
	},
}
</script>

<style lang="scss" scoped></style>
