<template>
	<div class="correction-wrap">
		<div class="mb-1">
			<span v-html="beforeDiff(beforeContent, afterContent)"></span>
		</div>
		<img
			src="@/assets/images/lesson/lesson-arrow.png"
			style="width: 10px; margin-right: 3px"
			alt=""
		/>
		<span v-html="afterDiff(beforeContent, afterContent)"></span>
	</div>
</template>

<script>
const jsdiff = require('diff')

export default {
	props: {
		beforeContent: {
			required: true,
			type: String,
		},
		afterContent: {
			required: true,
			type: String,
		},
	},
	methods: {
		beforeDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.removed) {
					span === null
						? (span = `<span class='font-weight-bold before'>${part.value}</span>`)
						: (span += `<span class='font-weight-bold before'>${part.value}</span>`)
				} else if (!part.added) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
		afterDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.added) {
					span === null
						? (span = `<span class='font-weight-bold after'>${part.value}</span>`)
						: (span += `<span class='font-weight-bold after'>${part.value}</span>`)
				} else if (!part.removed) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
	},
}
</script>

<style lang="scss">
.correction-wrap {
	.after {
		font-style: italic;
		color: #00cba0;
	}
	.before {
		color: #ff6060;
	}
}
</style>
