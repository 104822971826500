<template>
	<div class="grey lighten-4" style="height: 100vh">
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('튜터 선택') }}</h1>
				</v-col>
				<!-- <v-col cols="2" class="text-right">
					<v-btn fab icon text small>
						<v-icon>mdi-help-circle-outline</v-icon>
					</v-btn>
				</v-col> -->
			</v-row>
			<v-progress-linear :value="progress"></v-progress-linear>
		</section>
		<section class="pa-5">
			<SelectedCard />
		</section>
		<section class="px-5 pt-1">
			<TutorCategoryPicker />
			<div style="height: calc(100vh - 350px); overflow-y: auto">
				<TutorPicker />
			</div>
		</section>
		<div class="white fixed-button-wrap">
			<BookButton :isLoading="isLoading" />
		</div>
	</div>
</template>

<script>
import SelectedCard from './SelectedCard'
import TutorPicker from '../common/TutorPicker'
import TutorCategoryPicker from '../common/TutorCategoryPicker'
import BookButton from '../common/BookButton'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('book')

export default {
	components: {
		TutorCategoryPicker,
		TutorPicker,
		SelectedCard,
		BookButton,
	},
	data: () => ({
		progress: 66.7,
		isLoading: false,
		tutorCategory: 'all',
	}),
	computed: {
		...mapGetters(['selectedMoment']),
		typeEn() {
			return this.$route.params.typeEn
		},
	},
	created() {
		if (!this.selectedMoment) {
			this.goBack()
		}
	},
	methods: {
		goBack() {
			this.$router.push({
				name: 'TimeFirstStep1',
				params: { typeEn: this.typeEn },
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.selected-tutor-wrap {
	padding: 5px 7px;
	background: #ffffff;
	border: 0.5px dashed #1e78ff;
	box-sizing: border-box;
	border-radius: 10px;
}
.tab-btn {
	transition: all 0.1s ease-in-out;
	border-bottom: 3px solid rgba(0, 0, 0, 0);
	h1 {
		transition: all 0.1s ease-in-out;
		color: #c4c4c4;
		font-weight: normal;
	}
}
.tab-btn.active {
	border-color: #000;
	h1 {
		font-weight: bold;
		color: #000;
	}
}
</style>
