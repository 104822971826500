<template>
	<v-img src="@/assets/images/trial-landings/pc-3-bg.svg">
		<div class="pt-14 pb-sm-15 pb-12 white--text text-center">
			<h1 class="text--h1 mb-7 font-weight-bold white-space-pre">
				{{ $t('tria-confirmation.title.report') }}
			</h1>
			<p
				class="white--text mb-9 text--h4 font-weight-regular"
				v-html="$t('tria-confirmation.text.report')"
			></p>
			<img
				style="width: 60px"
				src="@/assets/images/trial-landings/tella-color.png"
				srcset="
					@/assets/images/trial-landings/tella-color@2x.png 2x,
					@/assets/images/trial-landings/tella-color@3x.png 3x
				"
				class="tella_color"
			/>
		</div>
	</v-img>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
