<template>
	<div>
		<v-skeleton-loader
			type="image"
			style="height: 28px; width: 85%"
			class="mb-1"
		></v-skeleton-loader>
		<v-skeleton-loader height="162.5" type="image"></v-skeleton-loader>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
