import moment from 'moment-timezone'
const R = require('ramda')

export const STORE = 'STORE'
export const LOADING = 'LOADING'
export const STORE_LESSON_TYPE = 'STORE_LESSON_TYPE'
export const SELECT_PROGRAM_ID = 'SELECT_PROGRAM_ID'
export const SELECT_KEYWORD = 'SELECT_KEYWORD'
export const SELECT_LEVEL = 'SELECT_LEVEL'
export const SELECT_TAGS = 'SELECT_TAGS'
export const REMOVE_TAG = 'REMOVE_TAG'
export const STORE_PROGRESSES = 'STORE_PROGRESSES'
export const LOAD_PROGRESS = 'LOAD_PROGRESS'
export const SELECT_CATEGORY = 'SELECT_CATEGORY'
export const SELECT_SUB_CATEGORY = 'SELECT_SUB_CATEGORY'

export default {
	[STORE](state, payload) {
		state.programs = payload
		state.storeAt = moment()
	},
	[STORE_LESSON_TYPE](state, payload) {
		state.selectedLessonType = payload
	},
	[SELECT_PROGRAM_ID](state, payload) {
		state.selectedProgramId = payload
	},
	[SELECT_KEYWORD](state, payload) {
		state.selectedKeyword = payload
	},
	[SELECT_LEVEL](state, payload) {
		state.selectedLevel = payload
	},
	[SELECT_TAGS](state, payload) {
		state.selectedTags = payload
	},
	[REMOVE_TAG](state, payload) {
		state.selectedTags = R.without([payload], state.selectedTags)
	},
	[STORE_PROGRESSES](state, payload) {
		state.progresses = payload
		state.storeProgressesAt = moment()
	},
	[SELECT_CATEGORY](state, payload) {
		state.category = payload
		state.subCategory = 'all'
	},
	[SELECT_SUB_CATEGORY](state, payload) {
		state.subCategory = payload
	},
}
