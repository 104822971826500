<template>
	<div id="find" style="min-height: 650px">
		<v-app-bar color="white" height="59" elevation="0">
			<v-btn plain @click="$router.push({ name: 'SignIn' })" icon
				><v-icon>mdi-arrow-left</v-icon></v-btn
			>
		</v-app-bar>
		<v-divider></v-divider>
		<v-tabs grow v-model="tab">
			<v-tab
				:ripple="false"
				class="text-body-2 primary--text font-weight-bold"
				>{{ $t('find-password.tab.password') }}</v-tab
			>
			<v-tab
				:ripple="false"
				@click="$router.push({ name: 'FindEmail' })"
				class="text-body-2 grey--text text--darken-3 font-weight-bold"
				>{{ $t('find-email.tab.email') }}</v-tab
			>
			<v-tab
				:ripple="false"
				@click="$router.push({ name: 'FindEtc' })"
				class="text-body-2 grey--text text--darken-3 font-weight-bold"
				>{{ $t('find-etc.tab.etc') }}</v-tab
			>
		</v-tabs>
		<v-container v-if="isSent === false">
			<h1 class="pt-7 pb-3 text-h1 font-weight-bold">
				{{ $t('find-password.title.find-password') }}
			</h1>
			<p class="text-body-1 pb-12">
				{{
					$t(
						'find-password.text.I`ll-send-you-a-password-reset-link-by-e-mail.',
					)
				}}
			</p>
			<v-form
				ref="form"
				v-model="isValid"
				@submit.prevent="sendPasswordResetMail"
			>
				<v-text-field
					:label="$t('common.form.label.email')"
					required
					background-color="white"
					v-model.trim="email"
					:rules="emailRules"
					outlined
					:disabled="isLoading"
					style="ime-mode: inactive"
				></v-text-field>
				<v-btn
					:disabled="isValid === false"
					class="mt-3"
					depressed
					color="primary"
					block
					large
					type="submit"
					:loading="isLoading"
					@click.native="loader = 'loading'"
					>{{ $t('find-password.button.send-password-reset-link') }}</v-btn
				>
			</v-form>
		</v-container>
		<v-container v-else>
			<h1 class="pt-7 pb-3 text-h1 font-weight-bold white-space-pre">
				{{ $t('find-password.title.check-email-and-reset-password') }}
			</h1>
			<p class="text-body-1 pb-3">
				{{ $t('find-password.text.sent-reset-link-by-email', { email }) }}
			</p>
			<div class="grey lighten-1 pa-3 text-body-2 grey--text text--darken-1">
				<v-icon class="pr-1 grey--text text--darken-1">mdi-check</v-icon>
				{{ $t('find-password.text.mailing-can-take-up-to-5-minutes') }}<br />
				<v-icon class="pr-1 grey--text text--darken-1">mdi-check</v-icon>
				{{ $t('find-password.text.check-spam-mail') }}<br />
			</div>
		</v-container>
		<div
			class="text-body-2 text-center"
			style="
				position: fixed;
				bottom: 0;
				width: 100%;
				max-width: 400px;
				height: 60px;
				padding-top: 22px;
				border-top: 1px solid #f0f0f0;
			"
		>
			{{ $t('find-password.text.remember-password?') }}
			&nbsp;&nbsp;&nbsp;&nbsp;<a
				href="#"
				@click="$router.push({ name: 'SignIn' })"
				>{{ $t('common.button.sign-in') }}</a
			>
		</div>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="false"
			:numberOfButton="1"
			:title="errTitle"
			:text="''"
			:actionColor="'primary'"
			:action="errAction"
			:actionText="$t('common.modal.button.confirm')"
		/>
	</div>
</template>

<script>
import Api from '@/services/index'
import Dialog from '@/components/common/Dialog'

export default {
	components: {
		Dialog,
	},
	data() {
		return {
			tab: 0,
			isLoading: false,
			isSent: false,
			isValid: false,
			email: null,
			emailRules: [
				v => !!v || this.$t('common.form.error.email-required'),
				v =>
					(v && v.length <= 100) || this.$t('common.form.error.email-too-long'),
				v => /.+@.+\..+/.test(v) || this.$t('common.form.error.email-format'),
			],
			// === 에러
			errorDialog: false,
			errTitle: '',
			errAction: this.closeErrDialog,
		}
	},
	methods: {
		async sendPasswordResetMail() {
			try {
				this.isLoading = true
				await Api.get(`/auth/reset-password?email=${this.email}`)
				this.isSent = true
			} catch (err) {
				const code = err.response.data.code
					? err.response.data.code
					: err.response.status

				this.errorHandler(code)
				this.errorDialog = !this.errorDialog
			} finally {
				this.isLoading = false
			}
		},
		errorHandler(code) {
			if (code === '10020') {
				this.errTitle = this.$t('find-password.error.title.can`t-find-email')
				return
			}
			this.errTitle = this.$t('find-password.error.title.default-error-message')
		},
		closeErrDialog() {
			this.errorDialog = !this.errorDialog
		},
	},
}
</script>

<style lang="scss">
#find .theme--light.v-tabs .v-tab--active:hover::before,
#find .theme--light.v-tabs .v-tab--active::before {
	display: none !important;
}
#find .v-tabs .v-tab:hover::before,
#find .v-tabs .v-tab::before {
	display: none !important;
}
</style>
