import Api from '@/services/index'
import { SET, LOADING } from './mutations'

export default {
	get: async ({ commit }) => {
		commit(LOADING, true)
		commit(SET, [])

		const res = await Api.get(`tutors/ratings/latest`)

		const result = res.data
		commit(SET, result)
		commit(LOADING, false)
	},
}
