// import { createNamespacedHelpers } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
	email: null,
	phone: null,
	kakaoId: null,
	loginDate: null,
	nameKr: null,
	nameEn: null,
	regDate: null,
	countryCode: '82',
	authFacebook: false,
	authKakao: false,
	status: null,
	timezone: 'Asia/Seoul',
	language: 'ko',
	nationality: 'kr',
	bonusCash: 0,
	coupons: [],
	group: 1,
	ipinfo: null,
	// numberOfLessons: 0,
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
