<template>
	<div>
		<div class="trial-report" v-if="isLoading === false">
			<NewReprot
				v-if="showReport === 'new'"
				:trial="trial"
				:report="report"
				:stat="stat"
				:logs="logs"
				:isPaid="isPaid"
			/>
			<OldReprot
				v-if="showReport === 'old'"
				:trial="trial"
				:report="report"
				:stat="stat"
				:logs="logs"
			/>
		</div>
		<Dialog
			:dialogControl="notAllowedDialog"
			:persistent="true"
			:numberOfButton="1"
			:title="$t('trial-report.wrap.error-title')"
			:text="$t('trial-report.wrap.error-text')"
			:actionColor="'primary'"
			:action="goBack"
			:actionText="$t('common.button.confirm')"
		/>
	</div>
</template>

<script>
import { codeToMoment } from '@/helpers/date-format'
import Api from '@/services/index'
import NewReprot from './new/Wrap'
import OldReprot from './old/Wrap'
import Dialog from '@/components/common/Dialog'

export default {
	components: {
		NewReprot,
		OldReprot,
		Dialog,
	},
	data() {
		return {
			isLoading: false,
			notAllowedDialog: false,
			trial: null,
			report: null,
			stat: null,
			isPaid: false,
			logs: [],
			showReport: null,
		}
	},
	computed: {
		trialId() {
			return this.$route.params.trialId
		},
		countryCode() {
			return this.$route.query['country-code']
		},
		phone() {
			return this.$route.query.phone
		},
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			try {
				this.isLoading = true
				const res = await Api.get(
					`trials/${this.trialId}?country-code=${this.countryCode}&phone=${this.phone}`,
				)
				const { trial, report, stat, logs, isPaid } = res.data

				this.trial = trial
				this.report = report
				this.stat = stat
				this.logs = logs
				this.isPaid = isPaid

				this.trial.moment = codeToMoment(
					this.trial.lesson_date,
					this.trial.lesson_time,
					this.$i18n.locale,
				)

				this.showReport = this.report ? 'new' : 'old'
				// this.showReport = 'old'
				if (this.showReport === 'new') {
					await this.$store.dispatch('program/get')
				}

				if (!this.$store.getters['auth/memberId']) {
					this.$store.commit('member/SET_LANGUAGE', this.trial.language)
				}
			} catch (err) {
				this.notAllowedDialog = !this.notAllowedDialog
				return
			} finally {
				this.isLoading = false
			}
		},
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			if (!beforeName) {
				this.$router.push({ name: 'Home' })
				return
			}
			this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss">
.trial-report {
	.title {
		font-size: 26px !important;
		font-weight: 800 !important;
		line-height: 1.35 !important;
		letter-spacing: -0.5px !important;
	}
	.title2 {
		font-size: 24px !important;
		font-weight: bold !important;
		line-height: 1.38 !important;
		letter-spacing: -0.96px !important;
		color: #191f28 !important;
	}
	.title3 {
		font-size: 19px !important;
		font-weight: bold !important;
		line-height: 1.32 !important;
		letter-spacing: -0.59px !important;
	}
	.title4 {
		font-size: 16px !important;
		font-weight: 600 !important;
		line-height: 1.25 !important;
		letter-spacing: -0.5px !important;
	}
	.sub-title {
		font-size: 20px !important;
		font-weight: bold !important;
		line-height: 1.25 !important;
		letter-spacing: -0.63px !important;
	}
	.sub-title2 {
		font-size: 17px !important;
		font-weight: 600 !important;
		line-height: 1.47 !important;
		letter-spacing: -0.53px !important;
	}
	.text {
		font-size: 16px !important;
		font-weight: normal !important;
		line-height: 1.56 !important;
		letter-spacing: -0.5px !important;
	}
	.text2 {
		font-size: 17px !important;
		font-weight: 600 !important;
		line-height: 1.24 !important;
		letter-spacing: -0.53px !important;
		color: #191f28 !important;
	}
	.text3 {
		font-size: 13px !important;
		font-weight: normal !important;
		line-height: 1.92 !important;
		letter-spacing: -0.41px !important;
		text-align: center;
	}
	.text4 {
		font-size: 12px !important;
		font-weight: normal !important;
		line-height: 1.5 !important;
		letter-spacing: -0.38px !important;
	}
	.title-color {
		color: #191f28;
	}
	.default-color {
		color: #485363;
	}
	.light-color {
		color: #888f99;
	}
}
</style>
