<template>
	<div>
		<v-dialog v-model="dialog" fullscreen hide-overlay transition="">
			<v-card>
				<div class="pt-12 mx-auto" style="max-width: 400px">
					<div class="px-3 mt-7">
						<h1 class="text-h2 font-weight-bold mb-3 white-space-pre">
							{{
								$t(
									'kakao-id-input-dialog.title.please-enter-your-kakao-id-to-use-the-service',
								)
							}}
						</h1>
						<p class="mb-9 text-body-2 white-space-pre">
							{{
								$t(
									'kakao-id-input-dialog.text.kakao-id-is-used-by-a-tutor-to-find-you-on-kakao-talk',
									{ name },
								)
							}}
						</p>
						<v-form ref="form" v-model="valid" @submit.prevent="updateMember">
							<v-text-field
								class="tella-form"
								:label="$t('카카오톡 ID')"
								ref="kakaoId"
								v-model="kakaoId"
								outlined
								:rules="kakaoIdRules"
								single-line
								:disabled="isLoading"
								background-color="white"
							>
							</v-text-field>
							<HowToCheckKakaoId :text="true" />
							<div
								class="text-center"
								style="
									position: fixed;
									bottom: 15px;
									width: calc(100% - 30px);
									max-width: 370px;
								"
							>
								<v-btn
									type="submit"
									depressed
									block
									large
									color="primary"
									:disabled="!valid"
									class="mb-1"
									>{{ $t('kakao-id-input-dialog.button.complete') }}</v-btn
								>
								<v-btn plain @click="dialog = false" text color="grey darken-3">
									<u
										class="text-decoration-underline grey--text text--darken-3"
									>
										{{ $t('kakao-id-input-dialog.button.do-it-later') }}
									</u>
								</v-btn>
							</div>
						</v-form>
					</div>
				</div>
			</v-card>
		</v-dialog>
		<v-snackbar
			elevation="0"
			v-model="snackbar"
			rounded="xl"
			bottom
			timeout="1500"
			min-width="0"
		>
			{{
				$t('kakao-id-input-dialog.snackbar.your-kakao-id-has-been-registered')
			}}
		</v-snackbar>
	</div>
</template>

<script>
import Api from '@/services/index'
import HowToCheckKakaoId from '@/components/common/HowToCheckKakaoId'

export default {
	components: {
		HowToCheckKakaoId,
	},
	props: {
		originDialog: {
			type: Boolean,
		},
	},
	data() {
		return {
			dialog: false,
			snackbar: false,
			valid: false,
			kakaoId: null,
			isLoading: false,
			kakaoIdRules: [
				v => !!v || this.$t('카카오톡 ID을 입력해주세요.'),
				v => !/[ ]/.test(v) || this.$t('공란을 확인해주세요.'),
				v =>
					/^[0-9a-zA-Z-._]*$/.test(v) ||
					this.$t('카카오톡 ID는 영어와 숫자만 입력하실 수 있습니다.'),
			],
		}
	},
	computed: {
		bookActionSheet() {
			return this.$store.getters['device/bookActionSheet']
		},
		name() {
			return this.$store.getters['member/name']
		},
		memberKakaoId() {
			return this.$store.getters['member/kakaoId']
		},
		redir() {
			return this.$route.query.redir
				? JSON.parse(this.$route.query.redir)
				: null
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
	},
	watch: {
		originDialog() {
			this.dialog = !this.dialog
		},
		bookActionSheet(val) {
			if (val && this.memberKakaoId === null) this.dialog = true
		},
	},
	methods: {
		async updateMember() {
			try {
				await Api.patch(`members/${this.memberId}`, {
					kakaoId: this.kakaoId,
				})
				await this.$store.dispatch('member/get', { root: true })
				this.snackbar = true
				this.dialog = false
			} catch (err) {
				alert('회원 정보 업데이트에 실패했습니다. 고객센터로 문의주세요.')
			}
		},
	},
}
</script>

<style lang="scss">
#kakao-id-complete-snackbar .v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
	min-width: unset;
}
</style>
