<template>
	<div>
		<div class="d-flex align-center mb-1">
			<h1 class="font-weight-bold text-body-1">{{ $t('유닛 목록') }}</h1>
			<v-spacer></v-spacer>
			<p class="px-5 mb-3 text-right text-caption grey--text text--darken-1">
				{{ $t('수강한 유닛 수') }}
				<span class="primary--text font-weight-medium">{{
					completedCount
				}}</span
				>/{{ units.length }}
			</p>
		</div>

		<section v-if="unitsWithStatus.length > 0">
			<div
				v-for="unit in unitsWithStatus"
				:key="unit.id"
				@click="clickUnit(unit)"
				class="cursor-pointer d-flex align-center px-3 py-2 mb-3 unit-box"
			>
				<h2
					class="text-body-2 font-weight-regular pr-2"
					:class="{ 'pr-2': unit.appointed || unit.completed || unit.onGoing }"
					:style="
						unit.appointed || unit.completed
							? 'width: calc(100% - 42px)'
							: unit.onGoing
							? 'width: calc(100% - 50px)'
							: ''
					"
				>
					{{ unit.unit }}. {{ getTitleByLanguage(unit.unit_title) }}
				</h2>
				<div
					class="status-chip grey darken-4 rounded-pill white--text"
					v-if="unit.appointed"
				>
					{{ $t('예약') }}
				</div>
				<div
					class="status-chip primary rounded-pill white--text"
					v-else-if="unit.completed"
				>
					{{ $t('완료') }}
				</div>
				<div
					class="status-chip primary rounded-pill white--text"
					v-else-if="unit.onGoing"
				>
					{{ $t('진행중') }}
				</div>
			</div>
		</section>
	</div>
</template>

<script>
const R = require('ramda')

export default {
	props: {
		selectedProgramId: {
			required: true,
			type: Number,
		},
		units: {
			required: true,
			type: Array,
		},
	},
	data: () => ({
		isLoading: false,
	}),
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		progresses() {
			return this.$store.getters['program/progresses']
		},
		storeProgressesAt() {
			return this.$store.getters['program/storeProgressesAt']
		},
		isBook() {
			return this.$route.query['is-book']
		},
		completedCount() {
			const completedProgresses = R.pipe(
				R.filter(
					p => p.program_id === this.selectedProgramId && p.status === 0,
				),
				R.map(p => p.program_unit_id),
				R.uniq(),
			)(this.progresses)

			return completedProgresses.length
		},
		unitsWithStatus() {
			return this.units.map(u => {
				u.completed = false
				u.onGoing = false
				u.appointed = false
				const progresses = R.filter(p => p.program_unit_id === u.id)(
					this.$store.getters['program/progresses'],
				)
				if (progresses.length > 0) {
					u.completed = R.find(p => p.status === 0)(progresses) !== undefined
					u.onGoing = R.find(p => p.status === 1)(progresses) !== undefined
				}

				u.appointed =
					R.find(l => l.program_unit_id === u.id)(
						this.$store.getters['appointed/lessons'],
					) !== undefined
				return u
			})
		},
	},
	methods: {
		clickUnit(unit) {
			this.$router.push({
				name: 'ProgramGuide',
				params: { programId: this.selectedProgramId, unitId: unit.id },
			})
		},
		getTitleByLanguage(unitTitle) {
			const title = JSON.parse(unitTitle)
			return title[this.language] ? title[this.language] : title['ko']
		},
	},
}
</script>

<style lang="scss" scoped>
.unit-box {
	border: 1px solid #f2f2f2;
	box-sizing: border-box;
	border-radius: 10px;
}
.unit-box.active {
	border-color: #1e78ff;
}
.status-chip {
	font-size: 11px;
	padding: 3px 6px 2px 6px;
}
</style>
