<template>
	<div>
		<v-row
			no-gutters
			v-for="(date, index) in expiryDates"
			:key="`point-${index}`"
			class="text-body-2 my-2 grey--text text--darken-4"
		>
			<v-col cols="6">
				{{ title(date) }}
			</v-col>
			<v-col cols="6" class="text-right">
				~{{ date.expiryMoment.format('YYYY. MM. DD.') }} (D-{{
					dDayCalculate(date)
				}})</v-col
			>
		</v-row>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('point')

export default {
	computed: {
		...mapGetters(['expiryDates']),
	},
	methods: {
		title(date) {
			return date.talkSum > 0 && date.callSum > 0
				? this.$t(`톡 %{talkSum}회 & 콜 %{callSum}회`, {
						talkSum: date.talkSum,
						callSum: date.callSum,
				  })
				: date.talkSum > 0
				? this.$t(`25분톡 %{talkSum}회`, {
						talkSum: date.talkSum,
				  })
				: this.$t(`15분콜 %{callSum}회`, {
						callSum: date.callSum,
				  })
		},
		dDayCalculate(date) {
			return date.expiryMoment.diff(
				moment().startOf('day').tz('Asia/Seoul'),
				'days',
			)
		},
	},
}
</script>

<style lang="scss" scoped></style>
