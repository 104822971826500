<template>
	<div class="pb-16 mx-auto" style="max-width: 400px">
		<Bar :eventName="'친구는 2만원 할인, 나는 3만원 캐시 받는 방법!'" />
		<v-container
			style="max-width: 600px"
			data-link-color="#1188E6"
			data-body-style="font-size:14px; font-family:arial,helvetica,sans-serif; color:#000000; background-color:#FFFFFF;"
		>
			<v-img
				width="600"
				src="http://cdn.mcauto-images-production.sendgrid.net/64b559a93ea89743/0a7fbae8-3945-4ab3-bfe8-7ecd315b69a5/1000x750.png"
			/>
			<h1 class="mt-10 mb-3 font-23 font-weight-bold">
				친구는 2만원 할인, 나는 3만원 캐시 받는 방법!
			</h1>
			<h2 class="font-16 mb-16">
				*텔라에 관심 있는 친구에게 아래 방법을 소개해주세요
			</h2>

			<h3 class="font-20 font-weight-bold mb-3">
				1. 수강권 페이지에서 구매를 원하는 수강권을 선택합니다. (톡/콜/패키지)
			</h3>
			<v-img
				width="600"
				class="mb-10"
				src="http://cdn.mcauto-images-production.sendgrid.net/64b559a93ea89743/531dff45-eb22-4b36-ae37-a08698a70ef0/1141x640.png"
			/>
			<h3 class="font-20 font-weight-bold mb-3">
				2. 가지고 있는 쿠폰 및 캐시 등을 적용해 주세요.
			</h3>
			<v-img
				width="600"
				class="mb-10"
				src="http://cdn.mcauto-images-production.sendgrid.net/64b559a93ea89743/253db4c5-9ad6-47b3-a971-8ce6c8ac3a05/499x514.png"
			/>
			<h3 class="font-20 font-weight-bold mb-3">
				3. 추천인 부분에 추천인의 이메일 계정을 입력합니다.
			</h3>
			<v-img
				border="0"
				width="600"
				class="mb-10"
				src="http://cdn.mcauto-images-production.sendgrid.net/64b559a93ea89743/01a88087-4cf7-4253-b194-725004e9cd94/499x612.png"
			/>

			<h3 class="font-20 font-weight-bold mb-3">
				4. 입력을 완료하면, 친구(신규 구매자)는 즉시 2만원 할인 적용!
			</h3>
			<v-img
				width="600"
				class="mb-10"
				src="http://cdn.mcauto-images-production.sendgrid.net/64b559a93ea89743/89c45ca5-10b3-423b-85bb-260fe64a7c21/499x612.png"
			/>

			<h3 class="font-20 font-weight-bold mb-3">
				5. 그리고 추천인(기이용자)에게는 3만 캐시가 적립됩니다!
			</h3>

			<div class="grey lighten-1 pa-4">
				<h1 class="font-18 mb-3">몇 가지 기억할 점!</h1>
				<p class="font-16" style="line-height: 1.75rem">
					1. 구매자가 신규 구매인 경우에만 추천인 입력이 가능합니다. <br />
					2. 친구는 쿠폰 및 캐시 할인의 혜택도 중복으로 받아볼 수 있습니다.
					<br />
					3. 다른 친구가 추가로 신규 구매를 할 경우 '나'는 중복으로 캐시 적립
					혜택을 받을 수 있습니다. <br />
					4. 추천인란에는 반드시 '나'가 회원가입한 이메일 계정을 입력해주셔야
					합니다.
				</p>
			</div>
			<div></div>
		</v-container>
	</div>
</template>

<script>
import Bar from './Bar'

export default {
	components: {
		Bar,
	},
	data: () => ({
		prevRoute: null,
	}),
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},
}
</script>

<style lang="scss" scoped>
body,
p,
div {
	font-family: arial, helvetica, sans-serif;
	font-size: 14px;
}
body {
	color: #000000;
}
body a {
	color: #1188e6;
	text-decoration: none;
}
p {
	margin: 0;
	padding: 0;
}
table.wrapper {
	width: 100% !important;
	table-layout: fixed;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}
img.max-width {
	max-width: 100% !important;
}
.column.of-2 {
	width: 50%;
}
.column.of-3 {
	width: 33.333%;
}
.column.of-4 {
	width: 25%;
}
ul ul ul ul {
	list-style-type: disc !important;
}
ol ol {
	list-style-type: lower-roman !important;
}
ol ol ol {
	list-style-type: lower-latin !important;
}
ol ol ol ol {
	list-style-type: decimal !important;
}
@media screen and (max-width: 480px) {
	.preheader .rightColumnContent,
	.footer .rightColumnContent {
		text-align: left !important;
	}
	.preheader .rightColumnContent div,
	.preheader .rightColumnContent span,
	.footer .rightColumnContent div,
	.footer .rightColumnContent span {
		text-align: left !important;
	}
	.preheader .rightColumnContent,
	.preheader .leftColumnContent {
		font-size: 80% !important;
		padding: 5px 0;
	}
	table.wrapper-mobile {
		width: 100% !important;
		table-layout: fixed;
	}
	img.max-width {
		height: auto !important;
		max-width: 100% !important;
	}
	a.bulletproof-button {
		display: block !important;
		width: auto !important;
		font-size: 80%;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.columns {
		width: 100% !important;
	}
	.column {
		display: block !important;
		width: 100% !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
	.social-icon-column {
		display: inline-block !important;
	}
}
</style>
