<template>
	<div>
		<v-img id="preview" src="@/assets/images/trial-landings/pc-2-bg.svg">
			<div class="pt-16 mt-6 pb-16">
				<h1
					class="text--h1 mb-15 text-center"
					v-html="$t('trial-confirmation.preview-pc.title')"
				></h1>
				<v-row no-gutters style="width: 717px" class="mx-auto">
					<v-col cols="6">
						<div style="width: 256px; position: relative">
							<div
								style="
									position: absolute;
									z-index: 0;
									left: 25.5px;
									top: 40px;
									width: 4px;
									height: 241px;
									border: solid 2px #bfddff;
								"
							></div>
							<div
								class="rounded-step-back"
								:style="`top:${(active - 1) * 89}px;`"
							></div>
							<div
								style="z-index: 1; position: relative"
								v-for="item in list"
								:key="item.id"
								class="mb-7 step-wrap d-flex align-center"
								:class="{
									active: item.id === active,
									'ice-breaking': item.class === 'ice-breaking',
									'today-expression': item.class === 'today-expression',
									'role-playing': item.class === 'role-playing',
									feedback: item.class === 'feedback',
								}"
								@click="active = item.id"
							>
								<div class="step">{{ item.id }}</div>
								<p class="text--h4">{{ item.text }}</p>
							</div>
						</div>
					</v-col>
					<v-col cols="6" style="height: 428px">
						<v-img :src="imgSrc"></v-img>
					</v-col>
				</v-row>
			</div>
		</v-img>
	</div>
</template>

<script>
export default {
	data() {
		return {
			list: [
				{
					id: 1,
					text: this.$t('trial-confirmation.preview-pc.ice-breaking'),
					class: 'ice-breaking',
				},
				{
					id: 2,
					text: this.$t('trial-confirmation.preview-pc.today-expression'),
					class: 'today-expression',
				},
				{
					id: 3,
					text: this.$t('trial-confirmation.preview-pc.role-playing'),
					class: 'role-playing',
				},
				{
					id: 4,
					text: this.$t('trial-confirmation.preview-pc.real-time-feedback'),
					class: 'feedback',
				},
			],
			active: 1,
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		imgSrc() {
			return require(`@/assets/images/trial-landings/pc-card-0${this.active}${
				this.language === 'ja' ? '-ja' : '-ko'
			}.png`)
		},
	},
}
</script>

<style lang="scss" scoped>
.step-wrap {
	width: 256px;
	height: 54px;
	padding: 13.5px 13.5px 12.5px 13.5px;
	border-radius: 27px;

	.step {
		transition: all 0.3s ease-in-out;
		border-radius: 50%;
		text-align: center;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: -0.44px;
		width: 28px;
		height: 28px;
		border: solid 4px #bfddff;
		color: #006cf0;
		background-color: #fff;
	}
	p {
		transition: all 0.3s ease-in-out;
		margin-left: 18.5px;
		font-weight: 500;
	}
}
.step-wrap.active {
	.step {
		color: #fff;
	}
	p {
		font-weight: bold;
	}
}
.step-wrap.active.ice-breaking {
	.step {
		border-color: #006cf0;
		background-color: #006cf0;
	}
	p {
		color: #006cf0 !important;
	}
}
.step-wrap.active.today-expression {
	.step {
		border-color: #7354ff;
		background-color: #7354ff;
	}
	p {
		color: #7354ff !important;
	}
}
.step-wrap.active.role-playing {
	.step {
		border-color: #00bbad;
		background-color: #00bbad;
	}
	p {
		color: #00bbad !important;
	}
}
.step-wrap.active.feedback {
	.step {
		border-color: #ff851a;
		background-color: #ff851a;
	}
	p {
		color: #ff851a !important;
	}
}

.rounded-step-back {
	transition: top 0.3s ease-in-out;
	position: absolute;
	left: 0;
	width: 256px;
	height: 54px;
	border-radius: 27px;
	box-shadow: 0 10px 30px 0 rgba(33, 51, 82, 0.1);
	background-color: #fff;
}
</style>

<style>
#preview .v-image__image--cover {
	transition: all 0.2s ease-in-out;
}
</style>
