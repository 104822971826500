<template>
	<div style="max-width: 400px" class="mx-auto">
		<Bar :eventName="'오 마이 쿠폰팩'" />
		<div class="main-wrap">
			<v-img
				class="mx-auto d-block"
				min-width="360"
				min-height="576"
				lazy-src="@/assets/images/events/2022/coupon-pack/main-mobile-min.png"
				src="@/assets/images/events/2022/coupon-pack/main-mobile.png"
			></v-img>
		</div>
		<div class="start-wrap">
			<v-img
				class="mx-auto d-block"
				min-width="360"
				min-height="806"
				lazy-src="@/assets/images/events/2022/coupon-pack/start-mobile-min.png"
				src="@/assets/images/events/2022/coupon-pack/start-mobile.png"
			></v-img>
		</div>
		<div class="signup-wrap px-5 pt-12 pb-16">
			<v-img
				class="mx-auto d-block"
				min-width="300"
				lazy-src="@/assets/images/events/2022/coupon-pack/signup-mobile-min.png"
				src="@/assets/images/events/2022/coupon-pack/signup-mobile.png"
			></v-img>
			<v-row class="pt-9 mx-auto" style="max-width: 836px" no-gutters>
				<v-col sm="12" cols="12">
					<a
						@click="clickTrial"
						href="https://my.tella.co.kr/trial?utm_source=couponpack&utm_medium=All&utm_campaign=pack_trial&utm_content=pack_trial"
						style="text-decoration: unset"
					>
						<v-img
							class="d-flex mb-7 mx-auto"
							width="200"
							src="@/assets/images/events/2022/coupon-pack/button.png"
						>
							<h1 class="text-center pt-2 font-18 font-weight-bold">
								무료수업 체험하기
							</h1>
						</v-img>
					</a>
				</v-col>
				<v-col sm="12" cols="12">
					<a
						@click="clickSignup"
						href="https://my.tella.co.kr/sign-up?utm_source=couponpack&utm_medium=All&utm_campaign=pack_signup&utm_content=pack_signup"
						style="text-decoration: unset"
					>
						<v-img
							class="d-flex mb-7 mx-auto"
							width="200"
							src="@/assets/images/events/2022/coupon-pack/button.png"
						>
							<h1 class="text-center pt-2 font-18 font-weight-bold">
								회원가입 하러가기
							</h1>
						</v-img>
					</a>
				</v-col>
			</v-row>
		</div>
		<div class="notice-wrap py-9 px-2">
			<div style="max-width: 850px" class="white--text mx-auto my-4">
				<h1 class="font-14 font-weight-bold">[유의사항]<br /><br /></h1>

				<h2 class="font-12 font-weight-medium ls-basic">
					- 회원가입 완료 즉시 혜택이 적용되며 마이페이지에서 확인이
					가능합니다.<br />
					<br />
					- 모든 쿠폰의 유효기간은 발행일 기준 1년 이며 결제 조건에 따라 결제 시
					적용 가능합니다. <br /><br />
					- 오 마이 쿠폰팩은 계정당 최초 1회 발급되며, 쿠폰 사용 후 환불 시
					재발급 되지 않습니다. <br /><br />
					- 본 혜택은 당사 사정에 의해 사전 동의 없이 종료 / 변경 될 수
					있습니다. <br /><br />
					- 기타 문의사항은 고객센터로 문의 부탁 드립니다.
				</h2>
			</div>
		</div>
	</div>
</template>

<script>
import Bar from './Bar'

export default {
	components: {
		Bar,
	},
	data: () => ({
		prevRoute: null,
	}),
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},
	methods: {
		clickTrial() {
			// eslint-disable-next-line no-undef
			amplitude.getInstance().logEvent('click_tellGoToTrial', {
				page: this.$route.name,
				component: 'Button',
				label: '무료수업 체험하기',
			})
		},
		clickSignup() {
			// eslint-disable-next-line no-undef
			amplitude.getInstance().logEvent('click_tellGoToSignUp', {
				page: this.$route.name,
				component: 'Button',
				label: '회원가입 하러가기',
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.main-wrap {
	background-color: #fcf7f4;
}
.start-wrap {
	background-color: #ffa9da;
}
.signup-wrap {
	background-color: #fcf7f4;
}
.notice-wrap {
	background-color: #383838;
}
</style>
