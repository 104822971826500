export const STORE = 'STORE'
export const SELECT_TYPE = 'SELECT_TYPE'
export const LOAD = 'LOAD'
export const MAPPING_UNIT = 'MAPPING_UNIT'
const R = require('ramda')

export default {
	[LOAD](state, payload) {
		state.isLoading = payload
	},
	[STORE](state, payload) {
		state.lessons = payload
	},
	[SELECT_TYPE](state, payload) {
		state.selectedType = payload
	},
	[MAPPING_UNIT](state, payload) {
		const mappingUnit = lesson => {
			lesson.unit = R.find(R.propEq('id', lesson.program_unit_id))(payload)
			return lesson
		}
		state.lessons = R.map(mappingUnit, state.lessons)
	},
}
