<template>
	<v-dialog v-model="dialog" width="300">
		<template v-slot:activator="{ on, attrs }">
			<v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
		</template>

		<v-card class="px-4 pt-4 pb-6 trial-report">
			<div class="mb-4 d-flex align-end">
				<h1 class="mt-2 sub-title title-color">
					{{ $t('trial-report.level-modal.레벨-비교-표') }}
				</h1>
				<v-spacer></v-spacer>
				<v-btn text icon @click="dialog = false"
					><v-icon>mdi-close</v-icon></v-btn
				>
			</div>
			<v-row no-gutters class="text-center" justify="center" align="center">
				<v-col cols="4" class="pb-6">
					<v-img
						class="mx-auto"
						max-width="40"
						src="@/assets/images/trials/tella-color@3x.png"
					></v-img>
				</v-col>
				<v-col cols="4" class="pb-6">
					<v-img
						class="mx-auto"
						:max-width="trialNationality === 'jp' ? 45 : 38"
						:src="
							require(`@/assets/images/trials/${
								trialNationality === 'jp' ? 'toeic-color@3x' : 'opic-color@3x'
							}.png`)
						"
					></v-img>
				</v-col>
				<v-col cols="4" class="pb-6">
					<v-img
						class="mx-auto"
						:max-width="trialNationality === 'jp' ? 55 : 37"
						:src="
							require(`@/assets/images/trials/${
								trialNationality === 'jp'
									? 'toeic-spk-color@3x'
									: 'ets-color@3x'
							}.png`)
						"
					></v-img>
				</v-col>
			</v-row>
			<v-row
				no-gutters
				v-for="item in levels"
				:key="item.id"
				:class="item.color"
				class="level-table text-center py-1"
			>
				<v-col cols="4">
					<p class="text3 default-color">
						{{ item.tella }}
					</p>
				</v-col>
				<v-col cols="4">
					<p class="text3 default-color">
						{{ trialNationality === 'kr' ? item.opic : item.toeic }}
					</p>
				</v-col>
				<v-col cols="4">
					<p class="text3 default-color">
						{{ item.ets }}
					</p>
				</v-col>
			</v-row>
			<p class="mt-5 text-center text4 light-color">
				{{ $t('trial-report.level-modal.참고용입니다') }}
			</p>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		trialNationality: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			dialog: false,
			levels: [
				{
					id: 1,
					tella: 'Level 3',
					opic: 'NH',
					ets: 'Level 3~4',
					toeic: `250~530`,
					color: 'grey',
				},
				{
					id: 2,
					tella: 'Level 4',
					opic: 'IL',
					ets: 'Level 4~5',
					toeic: `350~600`,
					color: 'white',
				},
				{
					id: 3,
					tella: 'Level 5',
					opic: 'IM 1~2',
					ets: 'Level 5',
					toeic: `535~740`,
					color: 'grey',
				},
				{
					id: 4,
					tella: 'Level 6',
					opic: 'IM3',
					ets: 'Level 6~7',
					toeic: `745~875`,
					color: 'white',
				},
				{
					id: 5,
					tella: 'Level 7',
					opic: 'IH~AL',
					ets: 'Level 6~7',
					toeic: `880~945`,
					color: 'grey',
				},
			],
		}
	},
}
</script>

<style lang="scss" scoped>
.level-table.grey {
	background-color: #f2f3f6 !important;
}
</style>
