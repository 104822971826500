<template>
	<v-bottom-sheet max-width="400" v-model="sheet" inset>
		<template v-slot:activator="{ on, attrs }">
			<div v-bind="attrs" v-on="on" class="d-flex align-center">
				<img
					style="width: 20px; vertical-align: text-bottom"
					src="@/assets/images/trials/ic-notice.svg"
					:alt="$t('how-to-check-kakao-id.image-alt.way-to-check-kakao-id')"
					srcset=""
				/>
				<p class="ml-1 grey--text text--darken-1 text-body-2" v-if="text">
					{{ $t('how-to-check-kakao-id.text-button.how-to-check-kakao-id') }}
				</p>
			</div>
		</template>
		<v-sheet
			class="pt-7 text-center grey lighten-3"
			style="
				border-top-left-radius: 32px;
				border-top-right-radius: 32px;
				position: relative;
			"
		>
			<h1
				class="mb-3 text-body-1 font-weight-bold"
				v-html="
					$t(
						'how-to-check-kakao-id.title.you-can-check-your-kakao-id-like-this',
					)
				"
			></h1>

			<v-window v-model="onboarding">
				<v-window-item v-for="n in 3" :key="`card-${n}`">
					<v-card color="grey lighten-3" min-height="350" max-height="370">
						<v-img
							class="mx-auto"
							max-width="340"
							:src="require(`@/assets/images/trials/kakao_${n}@1x.png`)"
						></v-img>
					</v-card>
				</v-window-item>
			</v-window>

			<v-card-actions class="pt-3 justify-center" id="kakao-info">
				<v-item-group v-model="onboarding" class="text-center" mandatory>
					<v-item v-for="n in 3" :key="`btn-${n}`" v-slot="{ active, toggle }">
						<div
							class="d-inline-block pagination-dot mx-1 cursor-pointer"
							:input-value="active"
							icon
							@click="toggle"
						></div>
					</v-item>
				</v-item-group>
			</v-card-actions>

			<v-btn
				block
				depressed
				x-large
				color="primary"
				style="border-radius: 0px !important"
				@click="check()"
				>{{ $t('how-to-check-kakao-id.button.confirm') }}</v-btn
			>
		</v-sheet>
	</v-bottom-sheet>
</template>

<script>
export default {
	props: {
		text: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			sheet: false,
			length: 3,
			onboarding: 0,

			list: [
				{
					id: 1,
					src: require(`@/assets/images/trials/kakao_1@1x.png`),
				},
				{
					id: 2,
					src: require(`@/assets/images/trials/kakao_2@1x.png`),
				},
				{
					id: 3,
					src: require(`@/assets/images/trials/kakao_3@1x.png`),
				},
			],
		}
	},
	methods: {
		check() {
			this.$emit('check')
			this.sheet = false
		},
	},
}
</script>

<style lang="scss" scoped>
#kakao-info {
	.pagination-dot {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: solid 1px #534739;
	}
	.v-item--active.pagination-dot {
		background-color: #534739;
	}
	// .theme--light.v-btn--active::before {
	// 	display: none;
	// }
}
</style>
<style>
.v-dialog:not(.v-dialog--fullscreen) {
	max-height: 95%;
}
</style>
