<template>
	<div>
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-window-close</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">{{ $t('업데이트 로그') }}</h1>
				</v-col>
			</v-row>
		</section>
		<iframe
			style="width: 100%; height: calc(var(--vh, 1vh) * 100 - 65px)"
			src="https://tellaupdates.oopy.io"
			frameborder="0"
		></iframe>
	</div>
</template>

<script>
export default {
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			beforeName !== 'MyPage' &&
			beforeName !== 'Notice' &&
			beforeName !== 'Home'
				? this.$router.push({ name: 'Home' })
				: this.$router.go(-1)
		},
	},
}
</script>

<style lang="scss" scoped></style>
