<template>
	<div class="pb-12" style="padding-top: 60px; padding-bottom: 100px">
		<v-row align="center" no-gutters class="pa-3 fixed-bar-wrap white">
			<v-col cols="4">
				<v-btn @click="goTo('MyPage')" icon
					><v-icon>mdi-arrow-left</v-icon></v-btn
				>
			</v-col>
			<v-col cols="4">
				<h1 class="text-center text-body-1 font-weight-regular">
					{{ $t('설정') }}
				</h1>
			</v-col>
		</v-row>
		<section class="pa-5">
			<h2 class="text-h6 mb-1 font-weight-medium">{{ $t('계정') }}</h2>
			<div
				class="d-flex align-center py-4"
				style="border-bottom: 1px solid #e5e5e5"
			>
				<h3 class="text-body-1">{{ $t('이메일') }}</h3>
				<v-spacer></v-spacer>
				<p class="d-flex align-center text-body-1 grey--text">
					<v-img
						v-if="authFacebook === true"
						class="mx-1"
						max-width="20"
						src="@/assets/images/logo/fb.png"
					></v-img>
					<v-img
						v-if="authKakao === true"
						class="mx-1"
						max-width="22"
						src="@/assets/images/logo/kakao.png"
					></v-img>
					{{ email }}
				</p>
			</div>
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('SettingName')"
			>
				<h3 class="text-body-1">{{ $t('이름') }}</h3>
				<v-spacer></v-spacer>
				<p class="text-body-1">{{ nameKr }}</p>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('SettingPhone')"
			>
				<h3 class="text-body-1">{{ $t('휴대폰 번호') }}</h3>
				<v-spacer></v-spacer>
				<p class="text-body-1">{{ globalNumber }}</p>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('SettingKakaoId')"
			>
				<h3 class="text-body-1">{{ $t('카카오톡 아이디') }}</h3>
				<v-spacer></v-spacer>
				<p class="text-body-1 primary--text">{{ kakaoId }}</p>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('SettingPassword')"
			>
				<h3 class="text-body-1">{{ $t('비밀번호 변경') }}</h3>
				<v-spacer></v-spacer>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('SettingSocialLogin')"
			>
				<h3 class="text-body-1">{{ $t('간편 로그인') }}</h3>
				<v-spacer></v-spacer>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
		</section>
		<section class="pa-5">
			<h2 class="text-h6 mb-1 font-weight-medium">{{ $t('학습') }}</h2>
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('SettingNameEn')"
			>
				<h3 class="text-body-1">{{ $t('영어 이름') }}</h3>
				<v-spacer></v-spacer>
				<p class="d-flex align-center text-body-1">
					{{ nameEn }}
				</p>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('SettingStyleTalk')"
			>
				<h3 class="text-body-1">{{ $t('25분톡 수업 스타일') }}</h3>
				<v-spacer></v-spacer>
				<!-- <p class="text-body-1 grey--text pr-1">{{ $t('준비중') }}</p> -->
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('SettingStyleCall')"
			>
				<h3 class="text-body-1">{{ $t('15분콜 수업 스타일') }}</h3>
				<v-spacer></v-spacer>
				<!-- <p class="text-body-1 grey--text pr-1">{{ $t('준비중') }}</p> -->
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('SettingTimezone')"
			>
				<h3 class="text-body-1">{{ $t('타임존 변경') }}</h3>
				<v-spacer></v-spacer>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
		</section>
		<section class="pa-5">
			<h2 class="text-h6 mb-1 font-weight-medium">{{ $t('수신') }}</h2>
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('SettingNotify')"
			>
				<h3 class="text-body-1">{{ $t('수업 알림') }}</h3>
				<v-spacer></v-spacer>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="goTo('SettingMarketing')"
			>
				<h3 class="text-body-1">{{ $t('이벤트·혜택 알림') }}</h3>
				<v-spacer></v-spacer>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
		</section>
		<section class="px-5 pt-5" style="padding-bottom: 150px">
			<div
				class="d-flex align-center py-4 cursor-pointer"
				style="border-bottom: 1px solid #e5e5e5"
				@click="signOutDialog = true"
			>
				<h3 class="text-body-1">{{ $t('로그 아웃') }}</h3>
				<v-spacer></v-spacer>
				<v-icon class="grey--text text--lighten-2">mdi-chevron-right</v-icon>
			</div>
			<v-dialog v-model="signOutDialog" width="350">
				<v-card rounded="xl">
					<p class="pa-8 text-body-1 mb-5">
						{{ $t('로그아웃 하시겠습니까?') }}
						<br />
						<br />
						<br />
						<br />
						<br />
					</p>
					<v-divider></v-divider>
					<div class="d-flex align-center px-5 py-3">
						<v-btn color="black" text @click="signOutDialog = false">{{
							$t('취소')
						}}</v-btn>
						<v-spacer></v-spacer>
						<v-btn color="primary" text @click="signOut">{{
							$t('로그아웃')
						}}</v-btn>
					</div>
				</v-card>
			</v-dialog>
		</section>
	</div>
</template>

<script>
export default {
	data: function () {
		return {
			isLoading: false,
			signOutDialog: false,
		}
	},
	computed: {
		nameEn() {
			return this.$store.getters['member/nameEn']
		},
		nameKr() {
			return this.$store.getters['member/nameKr']
		},
		email() {
			return this.$store.getters['member/email']
		},
		phone() {
			return this.$store.getters['member/phone']
		},
		countryCode() {
			return this.$store.getters['member/countryCode']
		},
		globalNumber() {
			return `+${this.countryCode}-${this.phone}`
		},
		kakaoId() {
			return this.$store.getters['member/kakaoId']
		},
		authFacebook() {
			return this.$store.getters['member/authFacebook']
		},
		authKakao() {
			return this.$store.getters['member/authKakao']
		},
	},
	created() {
		this.getMember()
	},
	methods: {
		async getMember() {
			this.socialDialog = false
			this.isLoading = true
			await this.$store.dispatch('member/get')
			this.isLoading = false
			// 멤버정보, 쿠폰정보
		},
		async signOut() {
			await this.$store.dispatch('auth/signOut')
			this.$router.push({ name: 'SignIn' })
		},
		goTo(routeName) {
			this.$router.push({ name: routeName })
		},
	},
}
</script>
