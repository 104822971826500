<template>
	<div id="find">
		<v-app-bar color="white" height="59" elevation="0">
			<v-btn plain @click="$router.push({ name: 'SignIn' })" icon
				><v-icon>mdi-arrow-left</v-icon></v-btn
			>
		</v-app-bar>
		<v-divider></v-divider>
		<v-tabs grow v-model="tab">
			<v-tab
				:ripple="false"
				@click="$router.push({ name: 'FindPassword' })"
				class="text-body-2 grey--text text--darken-3 font-weight-bold"
				>{{ $t('find-password.tab.password') }}</v-tab
			>
			<v-tab
				:ripple="false"
				@click="$router.push({ name: 'FindEmail' })"
				class="text-body-2 grey--text text--darken-3 font-weight-bold"
				>{{ $t('find-email.tab.email') }}</v-tab
			>
			<v-tab
				:ripple="false"
				class="text-body-2 primary--text font-weight-bold"
				>{{ $t('find-etc.tab.etc') }}</v-tab
			>
		</v-tabs>
		<v-container>
			<h1 class="pt-7 pb-3 text-h1 font-weight-bold">
				{{ $t('find-etc.title.any-problem?') }}
			</h1>
			<p class="text-body-1 pb-12 white-space-pre">
				{{ $t('find-etc.text.please-contack-us') }}
			</p>
			<v-btn
				depressed
				color="primary"
				block
				large
				:href="csUrl"
				target="_blank"
				>{{ $t('find-etc.button.go-to-cs') }}</v-btn
			>
		</v-container>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tab: 2,
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		csUrl() {
			return this.language === 'ja'
				? 'https://tella.channel.io/support-bots/25548'
				: 'https://tella.channel.io/support-bots/30776'
		},
	},
}
</script>

<style lang="scss">
#find .theme--light.v-tabs .v-tab--active:hover::before,
#find .theme--light.v-tabs .v-tab--active::before {
	display: none !important;
}
#find .v-tabs .v-tab:hover::before,
#find .v-tabs .v-tab::before {
	display: none !important;
}
</style>
