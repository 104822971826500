<template>
	<div v-if="isLoading === false">
		<section>
			<v-row class="white py-5 px-3 mb-5" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="$router.push({ name: 'Home' })">
						<v-icon>mdi-window-close</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">
						{{ $t('프로그램 선택 완료') }}
					</h1>
				</v-col>
			</v-row>
		</section>
		<section class="px-5 mb-3">
			<SelectedCard :lesson="lesson" />
		</section>
		<section class="px-5">
			<ul class="text-caption grey--text">
				<li>{{ $t('예약 취소는 수업 1시간전까지 가능합니다.') }}</li>
			</ul>
		</section>
		<section style="position: absolute; bottom: 0; width: 100%">
			<v-row no-gutters>
				<v-col cols="6">
					<v-btn
						@click="
							$router.push({
								name: 'Home',
								params: { autoBookSheetOpen: true },
							})
						"
						height="55"
						large
						tile
						block
						outlined
						color="primary"
						>{{ $t('다음 수업 예약하기') }}</v-btn
					>
				</v-col>
				<v-col cols="6">
					<v-btn
						@click="
							$router.push({
								name: 'ProgramGuide',
								params: {
									programId: lesson.program_id,
									unitId: lesson.program_unit_id,
								},
							})
						"
						height="55"
						large
						tile
						block
						depressed
						color="primary"
						>{{ $t('예습하기') }}</v-btn
					>
				</v-col>
			</v-row>
		</section>
	</div>
</template>

<script>
import SelectedCard from '../common/SelectedCard'
const R = require('ramda')

export default {
	components: {
		SelectedCard,
	},
	data: () => ({
		isLoading: false,
	}),
	computed: {
		lessonId() {
			return Number(this.$route.params.lessonId)
		},
		lesson() {
			return R.find(l => l.id === this.lessonId)(
				this.$store.getters['appointed/lessons'],
			)
		},
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			this.isLoading = true
			await this.$store.dispatch('appointed/get', true)
			this.isLoading = false
		},
	},
}
</script>

<style lang="scss" scoped></style>
