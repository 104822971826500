<template>
	<v-container class="pa-0 pb-16" style="max-width: 400px">
		<Bar :eventName="'수강후기 작성하면 3,000캐시 드려요!'" />
		<v-img
			class="mb-8"
			src="@/assets/images/events/thumbnail/review.png"
		></v-img>
		<div class="px-4">
			<h1 class="mb-5 font-weight-bold font-24">
				수강후기 작성하면 3,000캐시 드려요!
			</h1>
			<p class="mb-10 font-16" style="line-height: 2rem">
				1. 텔라 수업(25분톡, 15분콜)을 수강합니다. <br />
				2. 수강을 하며 느낀 점을 솔직하게 수강후기에 남겨주세요. <br />
				3. 후기 작성 후 바로
				<strong class="font-weight-bold">3,000 캐시가 적립</strong>됩니다! 💰<br />
			</p>
			<div class="mb-10 text-center">
				<v-btn
					depressed
					color="primary"
					x-large
					@click="$router.push('/review-write')"
					>이용후기 작성하기</v-btn
				>
			</div>
			<div class="pa-4 grey lighten-1 d-flex align-start font-16">
				<div class="mr-3">💡</div>
				<div>
					<div class="mb-3">
						* 적립된 캐시를 사용하여 수강권 구매 시에 추가 할인을 받아볼 수
						있어요!
					</div>
					* 월별 베스트 리뷰어에게는 5,000 캐시를 더 적립해드려요! (개별 연락)
				</div>
			</div>
		</div>
	</v-container>
</template>

<script>
import Bar from './Bar'

export default {
	components: {
		Bar,
	},
	data: () => ({
		prevRoute: null,
	}),
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.prevRoute = from
		})
	},
}
</script>

<style lang="scss" scoped></style>
