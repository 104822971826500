<template>
	<v-sheet
		class="py-4 pl-4 pr-6 plan-banner"
		:class="{
			package: type === 'package',
			talk: type === 'talk',
			call: type === 'call',
		}"
	>
		<div class="d-flex align-center">
			<div>
				<h2 class="text-body-1 font-weight-bold">{{ list[type].title }}</h2>
				<p class="text-body-2 white-space-pre">{{ list[type].msg }}</p>
			</div>
			<v-spacer></v-spacer>
			<v-img
				max-width="100"
				:src="require(`@/assets/images/plans/${type}-img.png`)"
			></v-img>
		</div>
	</v-sheet>
</template>

<script>
export default {
	props: {
		type: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			list: {
				package: {
					title: this.$t('plan.type-banner.title1'),
					msg: this.$t('plan.type-banner.msg1'),
				},
				talk: {
					title: this.$t('plan.type-banner.title2'),
					msg: this.$t('plan.type-banner.msg2'),
				},
				call: {
					title: this.$t('plan.type-banner.title3'),
					msg: this.$t('plan.type-banner.msg3'),
				},
			},
		}
	},
}
</script>

<style lang="scss" scoped>
.plan-banner {
	color: #fff;
	border-radius: 10px;
}
.package {
	background-image: linear-gradient(to right, #846aff 0%, #a58eff 100%);
}
.talk {
	background-image: linear-gradient(89deg, #5193ff 0%, #76baff 100%);
}
.call {
	background-image: linear-gradient(to right, #20cebc -38%, #53e28b 115%);
}
</style>
