var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"correction-wrap"},[_c('div',{staticClass:"mb-1"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.beforeDiff(_vm.beforeContent, _vm.afterContent))}})]),_c('img',{staticStyle:{"width":"10px","margin-right":"3px"},attrs:{"src":require("@/assets/images/lesson/lesson-arrow.png"),"alt":""}}),_vm._l((_vm.corrected),function(word,i){return _c('span',{key:(_vm.afterContent + "-" + i)},[(word.added === true)?_c('span',[_c('SelfTestBlank',{staticClass:"d-inline-block",style:(_vm.corrected[i + 1] &&
					_vm.corrected[i + 1].value !== ',' &&
					_vm.corrected[i + 1].value !== '.'
						? 'padding-right: 3px'
						: ''),attrs:{"word":word}})],1):_c('span',{staticClass:"d-inline-block",style:(_vm.corrected[i + 1] &&
				_vm.corrected[i + 1].value !== ',' &&
				_vm.corrected[i + 1].value !== '.'
					? 'padding-right: 3px'
					: '')},[_vm._v(_vm._s(word.value))])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }