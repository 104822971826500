<template>
	<div>
		<div v-if="stat">
			<h2 class="font-16 font-weight-bold mb-3">{{ $t('문장') }}</h2>
			<div class="analyze-box d-flex align-center mb-5">
				<div
					class="
						font-12
						orange--text
						text--darken-3
						orange
						lighten-4
						d-inline-block
						text-center
					"
					style="
						width: 24px;
						height: 24px;
						border-radius: 12px;
						padding-top: 3px;
						margin-right: 8px;
					"
				>
					<i class="fa-solid fa-pen-to-square"></i>
				</div>
				<span class="font-18 font-weight-medium">
					<span class="orange--text text--darken-3 font-weight-bold">{{
						$t('%{count}개 문장', {
							count: stat.tutee_number_of_sentences,
						})
					}}</span
					>{{ $t('을 말했어요.') }}
				</span>
			</div>
			<div class="analyze-box mb-4">
				<div class="font-18 font-weight-medium mb-2">
					<div
						v-html="
							$t('lesson.review.analyze.averageWords', {
								name,
								count: stat.tutee_length_of_sentences,
							})
						"
					></div>
				</div>
				<div class="white font-12 px-2 py-1" style="border-radius: 14px">
					<span style="color: #485363; opacity: 0.45" class="pr-2"
						>{{ $t('trial-report.sentenceStructure.example') }}
					</span>
					<span class="font-weight-medium"
						>{{ sentenceStructureTable.example }}
					</span>
				</div>
			</div>
			<p class="mb-10 font-14 grey--text text--darken-1">
				“{{ sentenceStructureTable.description }}”
			</p>
			<div class="d-flex align-center">
				<h2 class="font-16 font-weight-bold mb-3">{{ $t('첨삭') }}</h2>
				<v-spacer></v-spacer>
				<div
					@click="$emit('goToCorrection')"
					class="hover-pointer grey--text text--darken-1 font-14"
				>
					{{ $t('첨삭 탭 바로가기') }}
					<i class="font-12 fa-solid fa-angle-right"></i>
				</div>
			</div>
			<div class="analyze-box d-flex align-center mb-7">
				<div
					class="font-12 d-inline-block text-center"
					style="
						width: 24px;
						height: 24px;
						border-radius: 12px;
						padding-top: 3px;
						margin-right: 8px;
						background: #bfd7f8;
						color: #4e62fa;
					"
				>
					<i class="fa-solid fa-pen-to-square"></i>
				</div>
				<span
					class="font-18 font-weight-medium"
					v-html="
						$t(`lesson.review.analyze.correction-count`, {
							count: stat.number_of_corrections,
						})
					"
				>
				</span>
			</div>
			<h2 class="font-16 font-weight-bold mb-3">{{ $t('단어') }}</h2>
			<div class="analyze-box d-flex align-center">
				<v-row no-gutters>
					<v-col cols="6" class="pr-4" style="border-right: 1px solid #e2e6ed">
						<div class="mb-7 font-14 font-weight-medium white-space-pre">
							{{ $t('lesson.review.analyze.today-words-count') }}
							<i style="color: #aeb8c1" class="pl-1 fa-solid fa-comment"></i>
						</div>
						<div class="text-right emerald--text font-weight-medium">
							<span class="font-weight-bold font-33">{{
								stat.tutee_number_of_words
							}}</span
							>{{ $t('개') }}
						</div>
					</v-col>
					<v-col cols="6" class="pl-4 pr-1">
						<div class="mb-7 font-14 font-weight-medium white-space-pre">
							{{ $t('lesson.review.analyze.today-distinct-words-count')
							}}<i style="color: #aeb8c1" class="pl-1 fa-solid fa-comment"></i>
						</div>
						<div class="text-right emerald--text font-weight-medium">
							<span class="font-weight-bold font-33">{{
								stat.tutee_number_of_distinct_words
							}}</span
							>{{ $t('개') }}
						</div>
					</v-col>
				</v-row>
			</div>
			<!-- <v-card
				flat
				color="grey lighten-4"
				class="grey--text text--darken-3 py-5 px-3"
			>
				<v-row no-gutters>
					<v-col cols="6" class="px-3 py-1">
						<div class="d-flex align-center">
							<p class="text-body-2 font-weight-medium">
								{{ $t('대화 횟수') }}
							</p>
							<v-spacer></v-spacer>
							<p class="text-body-2 font-weight-medium">
								{{ stat.tutee_number_of_response }}{{ $t('회') }}
							</p>
						</div>
					</v-col>
					<v-col cols="6" class="px-3 py-1">
						<div class="d-flex align-center">
							<p class="text-body-2 font-weight-medium">
								{{ $t('문장 길이') }}
							</p>
							<v-spacer></v-spacer>
							<p class="text-body-2 font-weight-medium">
								{{ stat.tutee_length_of_sentences }}{{ $t('단어') }}
							</p>
						</div>
					</v-col>
					<v-col cols="6" class="px-3 py-1">
						<div class="d-flex align-center">
							<p class="text-body-2 font-weight-medium">
								{{ $t('첨삭 문장 수') }}
							</p>
							<v-spacer></v-spacer>
							<p class="text-body-2 font-weight-medium">
								{{ stat.number_of_corrections }}{{ $t('회') }}
							</p>
						</div>
					</v-col>
					<v-col cols="6" class="px-3 py-1">
						<div class="d-flex align-center">
							<p class="text-body-2 font-weight-medium">
								{{ $t('배운 표현 갯수') }}
							</p>
							<v-spacer></v-spacer>
							<p class="text-body-2 font-weight-medium">
								{{ expressCounts }}{{ $t('개') }}
							</p>
						</div>
					</v-col>
					<v-col cols="6" class="px-3 py-1">
						<div class="d-flex align-center">
							<p class="text-body-2 font-weight-medium">
								{{ $t('총 어휘량') }}
							</p>
							<v-spacer></v-spacer>
							<p class="text-body-2 font-weight-medium">
								{{ stat.tutee_number_of_words }}{{ $t('개') }}
							</p>
						</div>
					</v-col>
					<v-col cols="6" class="px-3 py-1">
						<div class="d-flex align-center">
							<p class="text-body-2 font-weight-medium">
								{{ $t('고유 어휘량') }}
							</p>
							<v-spacer></v-spacer>
							<p class="text-body-2 font-weight-medium">
								{{ stat.tutee_number_of_distinct_words }}{{ $t('개') }}
							</p>
						</div>
					</v-col>
				</v-row>
			</v-card> -->
		</div>
		<div v-else class="px-3 py-2">
			<p class="text-center grey--text text--darken-1 font-italic">
				{{ $t('수업 통계 자료를 찾을 수 없습니다.') }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		name: {
			required: true,
		},
		stat: {
			required: true,
		},
		progresses: {
			required: true,
			type: Array,
		},
	},
	computed: {
		expressCounts() {
			let sum = 0
			this.progresses.forEach(progress => {
				sum += progress.number_of_target_expressions
			})
			return sum
		},

		sentenceStructureTable() {
			const count = Math.max(
				Math.min(15, Math.floor(this.stat.tutee_length_of_sentences)),
				3,
			)
			const list = [
				{
					count: 3,
					example: 'I like strawberries.',
					description: this.$t('trial-report.sentense-structure.description-1'),
				},
				{
					count: 4,
					example: 'They are smart students.',
					description: this.$t('trial-report.sentense-structure.description-2'),
				},
				{
					count: 5,
					example: 'You can go home now.',
					description: this.$t('trial-report.sentense-structure.description-3'),
				},
				{
					count: 6,
					example: 'I am not sure about that.',
					description: this.$t('trial-report.sentense-structure.description-4'),
				},
				{
					count: 7,
					example: 'I will include you in the project.',
					description: this.$t('trial-report.sentense-structure.description-5'),
				},
				{
					count: 8,
					example: 'My teacher told him to lower his voice.',
					description: this.$t('trial-report.sentense-structure.description-6'),
				},
				{
					count: 9,
					example: 'Once you finish your homework, you can watch TV.',
					description: this.$t('trial-report.sentense-structure.description-7'),
				},
				{
					count: 10,
					example:
						'My chocolate ice-cream melted and fell on the concrete ground.',
					description: this.$t('trial-report.sentense-structure.description-8'),
				},
				{
					count: 11,
					example:
						'Unfortunately, I missed several calls while I was working this afternoon.',
					description: this.$t('trial-report.sentense-structure.description-9'),
				},
				{
					count: 12,
					example:
						'Jennifer, whose mother you met yesterday, would like to talk to you.',
					description: this.$t(
						'trial-report.sentense-structure.description-10',
					),
				},
				{
					count: 13,
					example:
						'We had to take a moment together and look at the big picture.',
					description: this.$t(
						'trial-report.sentense-structure.description-11',
					),
				},
				{
					count: 14,
					example:
						'The first thing we assumed is that they might have lots of useful tips.',
					description: this.$t(
						'trial-report.sentense-structure.description-12',
					),
				},
				{
					count: 15,
					example:
						'My personal favorite, which makes sense on some level, is to synthesize all the ideas.',
					description: this.$t(
						'trial-report.sentense-structure.description-13',
					),
				},
			]
			const find = list.find(l => l.count === count)
			return find ? find : list[0]
		},
	},
}
</script>

<style lang="scss" scoped>
.analyze-box {
	color: #191f28;
	background-color: #f2f3f6;
	border-radius: 15px;
	padding: 17px 20px;
}
</style>
