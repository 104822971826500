<template>
	<div class="pb-15">
		<TypePicker />
		<section class="px-5 pt-2 pb-1">
			<SearchInput />
		</section>
		<section class="pt-3 white" style="position: sticky; top: 0; z-index: 3">
			<ProgramCategory />
		</section>
		<section v-if="category === 'recommend'" class="px-5 pt-5">
			<!-- style="overflow-y: auto; height: calc(var(--vh, 1vh) * 100 - 232px)" -->
			<Recommend />
		</section>
		<section v-else class="px-5 pt-3">
			<!-- style="overflow-y: auto; height: calc(var(--vh, 1vh) * 100 - 270px)" -->
			<ProgramCard
				v-for="program in programsInCategory"
				:key="program.id"
				:program="program"
				:selectedProgramId="selectedProgram ? selectedProgram.id : null"
				@selectProgram="selectProgram"
				class="mb-3"
			/>
		</section>
	</div>
</template>

<script>
// import TutorAvatar from '@/components/common/TutorAvatar'
import TypePicker from './TypePicker'
import ProgramCategory from './CategoryPicker'
import SearchInput from './SearchInput'
import ProgramCard from '../common/Card'
import Recommend from './Recommend'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('program')

/**
 * 프로그램 전체 불러오기(App 1번)
 * 프로그래스 group-by 프로그램별(페이지별 1번)
 * 미래(appointed) 수업 불러오기(페이지별 1번)
 */
export default {
	components: {
		TypePicker,
		SearchInput,
		ProgramCategory,
		ProgramCard,
		Recommend,
	},
	data: () => ({
		dialog: false,
		isLoading: false,
		selectedProgram: null,
	}),
	computed: {
		...mapGetters(['programsInCategory', 'category']),
	},
	methods: {
		selectProgram(programObj) {
			this.$router.push({
				name: 'ProgramUnit',
				params: { programId: programObj.id },
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
