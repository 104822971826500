<template>
	<v-dialog v-model="dialog" width="310">
		<template v-slot:activator="{ on, attrs }">
			<span
				style="white-space: nowrap"
				v-bind="attrs"
				v-on="on"
				class="cursor-pointer text-decoration-underline"
				>{{ $t('trial.button.change-timezone') }} ></span
			>
		</template>
		<v-card rounded="lg" class="pt-5 px-3 pb-3">
			<div class="pt-1 pb-2">
				<h1 class="text-h2 grey--text text--darken-3 font-weight-bold">
					{{ $t('trial.title.change-timezone') }}
				</h1>
			</div>
			<div class="py-3 mx-auto" style="max-width: 350px">
				<v-autocomplete
					outlined
					v-model="timezone"
					:items="allTimezones"
					:label="$t('form.label.timezone')"
				></v-autocomplete>
				<v-btn
					block
					@click="changeTimezone"
					color="primary"
					depressed
					:disabled="isLoading"
					>{{ $t('button.change-timezone') }}</v-btn
				>
			</div>
		</v-card>
		<Dialog
			:dialogControl="errorDialog"
			:numberOfButton="1"
			:title="$t('modal.title.trial-timezone-change-error')"
			:text="$t('modal.text.trial-timezone-change-error')"
			:actionColor="'primary'"
			:action="goToCs"
			:actionText="$t('modal.button.trial-timezone-change-error')"
		/>
	</v-dialog>
</template>

<script>
import Api from '@/services/index'
import allTimezones from '@/assets/time-zone'
import Dialog from '@/components/common/Dialog'

export default {
	components: {
		Dialog,
	},
	props: {
		originTimezone: {
			required: false,
			type: String,
			defualt: 'Asia/Seoul',
		},
	},
	data() {
		return {
			isLoading: false,
			dialog: false,
			timezone: null,
			allTimezones: [],
			errorDialog: false,
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
	},
	watch: {
		dialog(val) {
			if (val === true) this.setTimezoneInitial()
		},
	},
	created() {
		this.allTimezones = allTimezones
		this.setTimezoneInitial()
	},
	methods: {
		setTimezoneInitial() {
			this.timezone = this.originTimezone
			if (this.allTimezones.includes(this.timezone) === false) {
				this.timezone = 'Asia/Seoul'
				localStorage.setItem('_timezone', this.timezone)
				this.$emit('selectTimezone', this.timezone)
			}
		},
		async changeTimezone() {
			try {
				localStorage.setItem('_timezone', this.timezone)
				if (this.memberId) {
					await Api.patch(`members/${this.memberId}`, {
						timezone: this.timezone,
					})
					await this.$store.dispatch('member/get', { root: true })
				}
				this.$emit('selectTimezone', this.timezone)
			} catch (err) {
				this.errorDialog = !this.errorDialog
			} finally {
				this.dialog = false
			}
		},
		goToCs() {
			const url =
				this.language === 'ja'
					? 'https://tella.channel.io/support-bots/25548'
					: 'https://tella.channel.io/support-bots/30776'
			window.open(url, '_blank').focus()
		},
	},
}
</script>

<style lang="scss" scoped></style>
