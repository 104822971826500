<template>
	<div class="pt-14 px-6 pb-13">
		<h1 class="mb-7 title2 white-space-pre">
			{{ $t('trial-report.level.title') }}
		</h1>
		<div class="d-flex align-center mb-8">
			<h2 class="sub-title primary--text">
				{{ $t('trial-report.level.my-level') }}
			</h2>
			<v-spacer></v-spacer>
			<LevelDialog :trialNationality="trialNationality" />
		</div>
		<div
			class="mx-auto"
			style="position: relative; width: 209px; height: 142px"
		>
			<v-img
				width="209"
				style="z-index: 1; position: absolute; left: 0; bottom: 0"
				:src="
					require(`@/assets/images/trials/graph-level-${this.levelTable.level}.png`)
				"
			></v-img>

			<div
				class="text-center"
				style="z-index: 0; position: absolute; left: 0; bottom: 0; width: 100%"
			>
				<h2 class="mb-1 text-center score default-color">
					<strong class="font-weight-bold primary--text">{{
						levelTable.score
					}}</strong
					>/500
				</h2>
				<h1 class="mb-3 title-color text-center level">
					Level {{ levelTable.level }}
				</h1>
			</div>
		</div>
		<h3 class="text-center default-color percentage">
			{{ levelTable.percentage }}
		</h3>
		<v-card flat rounded="lg" color="grey lighten-2" class="py-5 mt-5 mb-8">
			<div style="width: 100%" class="d-flex align-center">
				<v-spacer></v-spacer>
				<div>
					<v-img
						:width="trialNationality === 'kr' ? 30.3 : 38"
						class="mx-auto mb-1"
						:src="
							require(`@/assets/images/trials/${
								trialNationality === 'kr' ? 'opic-gray' : 'toeic-gray'
							}.png`)
						"
					></v-img>
					<p
						class="
							text-center
							grey--text
							text--darken-3 text-body-1
							font-weight-bold
						"
					>
						{{ trialNationality === 'kr' ? levelTable.opic : levelTable.toeic }}
					</p>
				</div>
				<v-spacer></v-spacer>
				<v-divider width="1" style="height: 45px" vertical></v-divider>

				<v-spacer></v-spacer>
				<div>
					<v-img
						class="mx-auto mb-1"
						:width="trialNationality === 'kr' ? 30.3 : 50"
						:src="
							require(`@/assets/images/trials/${
								trialNationality === 'kr' ? 'ets-gray' : 'toeic-spk-gray'
							}.png`)
						"
					></v-img>

					<p
						class="
							text-center
							grey--text
							text--darken-3 text-body-1
							font-weight-bold
						"
					>
						{{ levelTable.toeicSpeaking }}
					</p>
				</div>
				<v-spacer></v-spacer>
			</div>
		</v-card>
		<p class="text default-color mb-1">
			{{ levelTable.description1 }}
		</p>
		<p class="text default-color mb-1">
			{{ levelTable.description2 }}
		</p>
		<p class="text default-color">
			{{
				$t('trial-report.level.my-level-in-opic-and-toeic-speaking', {
					opic: levelTable.opic,
					toeicSpeaking: levelTable.toeicSpeaking,
				})
			}}
		</p>
	</div>
</template>

<script>
import LevelDialog from './LevelDialog'

export default {
	components: {
		LevelDialog,
	},
	props: {
		levelTable: {
			required: true,
			type: Object,
		},
		trialNationality: {
			required: true,
			type: String,
		},
	},
	computed: {
		levelCirclePosition() {
			return this.levelTable.level === 3
				? 2
				: this.levelTable.level === 4
				? 4
				: this.levelTable.level === 5
				? 'left: 135.1px; bottom:131.9px;'
				: this.levelTable.level === 6
				? 8
				: this.levelTable.level === 7
				? 10
				: 1
		},
	},
}
</script>

<style lang="scss" scoped>
.score {
	font-size: 18px !important;
	font-weight: normal !important;
	line-height: 1.94 !important;
	letter-spacing: -0.51px !important;
}
.level {
	font-size: 35px !important;
	font-weight: 600 !important;
	line-height: 1 !important;
	letter-spacing: -1px !important;
}
.percentage {
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 2.19 !important;
	letter-spacing: -0.92px !important;
	text-align: center !important;
}
</style>
