<template>
	<div v-if="isLoading === false">
		<div class="mb-2 white">
			<Bar style="border-bottom: 1px solid #f7f7f7 !important" />
			<Info
				:planName="planName"
				:plan="plan"
				:startDate="startDate"
				class="px-3"
			/>
			<v-divider class="px-3 mx-3 mt-6 mb-1 grey lighten-2"></v-divider>
			<DatePicker
				class="px-3"
				v-if="
					points.length > 0 &&
					[
						106,
						107,
						108,
						'106',
						'107',
						'108',
						109,
						110,
						111,
						112,
						113,
						114,
						'109',
						'110',
						'111',
						'112',
						'113',
						'114',
					].includes(this.plan.id) === false
				"
				:points="points"
				@selectStartDate="selectStartDate"
				:startDateError="startDateError"
			/>
		</div>
		<PriceBox
			class="px-3 white mb-2"
			:plan="plan"
			:coupons="coupons"
			:currency="currency"
			:currencyString="currencyString"
			:nthPayment="nthPayment"
			@selectCoupon="selectCoupon"
			@selectCash="selectCash"
			@selectRecommender="selectRecommender"
			@selectCurrency="selectCurrency"
			@updatePayPrice="updatePayPrice"
			@registeredCoupon="getPlanAndPoints"
		/>
		<PayMethod
			class="mb-2"
			:currency="currency"
			@selectPayMethod="selectPayMethod"
		/>
		<ActionButton
			class="white"
			:plan="plan"
			:orderTitle="planName"
			:payPrice="payPrice"
			:couponMemberId="selectedCoupon ? selectedCoupon.id : null"
			:bonusCash="selectedCash"
			:currencyString="currencyString"
			:recommenderEmail="recommenderEmail"
			:planId="planId"
			:startDate="startDate"
			:currency="currency"
			:payMethod="payMethod"
			@startDateError="startDateError = true"
		/>
	</div>
</template>

<script>
import moment from 'moment-timezone'
import Api from '@/services/index'
import Bar from './Bar'
import Info from './Info'
import DatePicker from './DatePicker'
import PriceBox from './PriceBox'
import PayMethod from './PayMethod'
import ActionButton from './ActionButton'

export default {
	components: {
		Bar,
		Info,
		DatePicker,
		PriceBox,
		PayMethod,
		ActionButton,
	},
	data() {
		return {
			isLoading: false,
			plan: {},
			coupons: [],
			startDate: '',
			selectedCash: 0,
			selectedCoupon: null,
			recommenderEmail: null,
			currency: 'KRW',
			payMethod: 'card',
			payPrice: 0,
			startDateError: false,
			nthPayment: 0,
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		language() {
			return this.$store.getters['member/language']
		},
		planId() {
			return this.$route.query['plan-id']
		},
		points() {
			return this.$store.getters['point/points'].filter(
				p =>
					p.is_bonus === 0 &&
					p.active === 1 &&
					p.expiry_date >= moment().format('YYYY-MM-DD'),
			)
		},
		paidPoints() {
			return this.$store.getters['point/paidPoints']
		},
		expiryDates() {
			return this.$store.getters['point/expiryDates']
		},
		currencyString() {
			return this.currency === 'USD' ? 'USD' : this.$t('order.currency.krw')
		},
		planName() {
			// 오늘부터 프로모션 분기
			if (
				[106, 107, 108, '106', '107', '108'].includes(this.plan.id) === true
			) {
				return this.plan.order_title
			}
			const talkPoints = this.plan.talk_points
			const callPoints = this.plan.call_points
			const month = this.plan.month === 12 ? '9+3' : this.plan.month
			const packageName = this.$t('order.info.order-name-package', {
				talkPoints,
				callPoints,
				month,
			})
			const talkName = this.$t('order.info.order-name-talk', {
				talkPoints,
				month,
			})
			const callName = this.$t('order.info.order-name-call', {
				callPoints,
				month,
			})
			if (talkPoints > 0 && callPoints > 0) return packageName
			if (talkPoints > 0) return talkName
			return callName
		},
	},
	created() {
		this.getPlanAndPoints()
		this.currency = this.language === 'ja' ? 'JPY' : 'KRW'
	},
	methods: {
		async getPlanAndPoints() {
			try {
				this.isLoading = true
				const result = await Promise.all([
					this.$store.dispatch('point/get'),
					Api(`plans/${this.planId}`),
					Api.get(`coupons?member-id=${this.memberId}`),
					Api.get(`payments?member-id=${this.memberId}&status=paid`),
				])
				this.plan = result[1].data
				this.coupons = result[2].data
				// 오늘부터 프로모션 분기
				if (
					[106, 107, 108, '106', '107', '108'].includes(this.plan.id) === true
				) {
					this.selectStartDate('2023-01-04')
				} else if (
					this.points.length === 0 ||
					[
						109,
						110,
						111,
						112,
						113,
						114,
						'109',
						'110',
						'111',
						'112',
						'113',
						'114',
					].includes(this.plan.id) === true
				)
					this.selectStartDate(moment().format('YYYY-MM-DD'))
				this.nthPayment = result[3].data.length
				// if (this.paidPoints.length > 0) this.addRepurchaseCoupon()

				this.isLoading = false
			} catch (err) {
				console.log(err)
			}
		},
		selectStartDate(val) {
			if (val) this.startDateError = false

			this.startDate = val
		},
		// addRepurchaseCoupon() {
		// 	const expirationDate =
		// 		this.expiryDates.length > 0
		// 			? this.expiryDates[this.expiryDates.length - 1].expiryDate
		// 			: null

		// 	this.coupons.unshift({
		// 		active: 1,
		// 		coupon_id: 0,
		// 		coupon_no: '-',
		// 		coupon_type: this.$t('재구매할인 쿠폰'),
		// 		discount: 5,
		// 		discount_type: '%',
		// 		duration: null,
		// 		expiration_date: expirationDate,
		// 		id: -1,
		// 		issued_date: null,
		// 		member_id: this.memberId,
		// 		used_date: null,
		// 	})
		// },
		selectCoupon(val) {
			this.selectedCoupon = val
		},
		selectCash(val) {
			this.selectedCash = val
		},
		selectRecommender(recommenderEmail) {
			this.recommenderEmail = recommenderEmail
		},
		selectCurrency(currency) {
			this.currency = currency
		},
		selectPayMethod(val) {
			this.payMethod = val
		},
		updatePayPrice(val) {
			this.payPrice = val
		},
	},
}
</script>

<style lang="scss" scoped></style>
