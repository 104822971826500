<template>
	<div>
		<v-row align="center" no-gutters class="pa-3">
			<v-col cols="2">
				<v-btn @click="goBack" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
			</v-col>
			<v-col cols="8">
				<h1 class="text-center text-body-1 font-weight-regular">
					{{ $t('카카오톡 아이디') }}
				</h1>
			</v-col>
		</v-row>
		<section class="pa-5">
			<h2
				class="text-h6 mb-1 font-weight-bold"
				v-html="$t('수업을 수강할<br /> 카카오톡 아이디를<br /> 알려주세요.')"
			></h2>
			<p class="text-body-2 mb-8 grey--text text--darken-3">
				{{ $t('튜터가 학습자님을 카카오톡에서 찾는데 사용합니다') }}
			</p>

			<v-form v-model="valid" @submit.prevent="changeProfile">
				<v-text-field
					v-model="kakaoId"
					:label="$t('카카오톡 아이디')"
					:rules="kakaoIdRules"
					class="tella-form"
					single-line
					:disabled="isLoading"
					filled
				>
				</v-text-field>
				<v-btn
					class="mb-3"
					block
					x-large
					type="submit"
					@click.native="loader = 'loading'"
					color="grey darken-3 white--text"
					depressed
					:disabled="!valid || isLoading"
					>{{ $t('변경') }}</v-btn
				>
			</v-form>
			<!-- <p class="text-body-2 grey--text text--darken-3">
				<v-icon>mdi-help-circle-outline</v-icon>
				카카오톡 아이디 찾는 법
			</p> -->
		</section>
	</div>
</template>

<script>
import Api from '@/services/index'

export default {
	data: function () {
		return {
			isLoading: false,
			valid: false,
			kakaoId: null,
			kakaoIdRules: [
				v => !!v || this.$t('카카오톡 ID을 입력해주세요.'),
				v => !/[ ]/.test(v) || this.$t('공란을 확인해주세요.'),
				v =>
					/^[0-9a-zA-Z-._]*$/.test(v) ||
					this.$t('카카오톡 ID는 특수문자를 입력하실 수 없습니다.'),
			],
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
	},
	created() {
		this.kakaoId = this.$store.getters['member/kakaoId']
	},
	methods: {
		async changeProfile() {
			try {
				this.isLoading = true
				await Api.patch(`members/${this.memberId}`, { kakaoId: this.kakaoId })
				alert(this.$t('회원 정보 변경이 완료되었습니다.'))
				this.goBack()
			} catch (err) {
				alert(this.$t('회원 정보 변경이 실패하였습니다. 다시 시도해주세요.'))
			} finally {
				this.isLoading = false
			}
		},
		goBack() {
			this.$router.push({ name: 'Setting' })
		},
	},
}
</script>

<style lang="scss" scoped></style>
