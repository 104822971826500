<template>
	<v-dialog
		v-model="dialog"
		max-width="400"
		:fullscreen="userDevice === 'mobile'"
		:content-class="userDevice === 'desktop' ? 'rounded-lg' : ''"
	>
		<template v-slot:activator="{ on, attrs }">
			<div class="white pa-3">
				<div
					class="background py-3 px-4 rounded-lg d-flex align-center"
					v-bind="attrs"
					v-on="on"
				>
					<h1
						class="text-h6 font-weight-bold white--text"
						style="
							line-height: 1.3em !important;
							letter-spacing: -0.05em !important;
						"
						v-html="$t('plan.banner-dialog.title')"
					></h1>
					<v-spacer></v-spacer>
					<v-img
						max-width="70"
						class="mt-n1 mr-2"
						src="@/assets/images/plans/3-box.png"
					></v-img>
				</div>
			</div>
		</template>
		<v-card tile :rounded="userDevice === 'desktop' ? 'lg' : ''">
			<v-row no-gutters class="background px-3 pt-2 pb-14">
				<v-col cols="12" class="mb-6">
					<v-btn class="" dark icon large @click="dialog = false">
						<v-img
							width="40"
							src="@/assets/images/plans/ic-navi-closed-w.png"
						></v-img>
					</v-btn>
				</v-col>
				<v-col cols="9">
					<h1
						class="text--h1 white--text"
						style="line-height: 1.3em !important"
						v-html="$t('plan.banner-dialog.title')"
					></h1>
				</v-col>
				<v-col cols="3">
					<v-img
						width="96"
						class="mt-n5"
						src="@/assets/images/plans/3-box.png"
					></v-img>
				</v-col>
			</v-row>
			<div class="px-3 pb-3">
				<v-card
					v-for="(item, i) in points"
					:key="item.title"
					class="promise-card d-flex align-center mb-3"
					:style="i === 0 ? `margin-top: -30px` : ''"
				>
					<h1 class="font-weight-bold" v-html="item.title"></h1>
					<v-spacer></v-spacer>
					<v-img :src="require(`@/assets/images/plans/${item.img}`)"></v-img>
				</v-card>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialog: false,
			points: [
				{
					title: this.$t('plan.banner-dialog.title.first-pay'),
					img: `img-popups-01.png`,
				},
				{
					title: this.$t('plan.banner-dialog.title.cancel-info'),
					img: `img-popups-02.png`,
				},
				{
					title: this.$t('plan.banner-dialog.title.refund-100%'),
					img: `img-popups-03.png`,
				},
			],
		}
	},
	computed: {
		language() {
			return this.$store.getters.language
		},
		userDevice() {
			return this.$store.getters.userDevice
		},
	},
}
</script>

<style lang="scss" scoped>
.background {
	background-image: linear-gradient(102deg, #5193ff -103%, #743af8 91%);
}
.background-desktop {
	padding-top: 70px;
	height: 450px;
}

.promise-card {
	border-radius: 10px !important;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.13) !important;
	background-color: #fff;
	max-width: 343px;
	width: 100%;
	margin: 0 auto;
	padding: 15px 15px 15px 30px;

	h1 {
		font-size: 19px;
	}
}
</style>
