<template>
	<div>
		<section>
			<table class="mb-3" width="100%" cellspacing="0">
				<tr>
					<td class="table-title">Student Name</td>
					<td>{{ memberNameKr }}</td>
				</tr>
				<tr>
					<td class="table-title">Course</td>
					<td>{{ passName }}</td>
				</tr>
				<tr v-if="includeTerm === true">
					<td class="table-title">Duration</td>
					<td class="text-nls">
						{{ start }} ~
						{{ end }}
					</td>
				</tr>
				<tr>
					<td class="table-title">
						Attendance<br />
						Rate
					</td>
					<td class="text-nls">
						<p class="text-body-2" v-if="bookedTalk > 0">
							25min Talk : {{ talkAttendanceRate }}%({{ talkLessonLength }}/{{
								bookedTalk
							}}
							lessons)
						</p>
						<p class="text-body-2" v-if="bookedCall > 0">
							15min Call : {{ callAttendanceRate }}%({{ callLessonLength }}/{{
								bookedCall
							}}
							lessons)
						</p>
					</td>
				</tr>
			</table>
			<table width="100%" cellspacing="0">
				<tr>
					<td class="table-title">Company Name</td>
					<td>Tella Co., Inc</td>
				</tr>
				<tr>
					<td class="table-title">CEO</td>
					<td>Yuha Jin</td>
				</tr>
				<tr>
					<td class="table-title text-nls">
						Business Reg.<br />
						Number
					</td>
					<td>114-87-15235</td>
				</tr>
			</table>
		</section>

		<section>
			<p class="my-5 text-center text-body-1">
				This is to certify that the above is true.<br />{{ today }}
			</p>

			<div class="d-flex justify-center">
				<v-img
					max-width="120"
					src="@/assets/images/certificate/ceo-sign.png"
				></v-img>
			</div>
			<p class="text-center text-body-1">Tella Co., Inc, CEO Yuha Jin</p>
		</section>
	</div>
</template>

<script>
import moment from 'moment-timezone'
const R = require('ramda')

export default {
	props: {
		points: {
			required: true,
		},
		lessons: {
			required: true,
		},
		selectedPointId: {
			required: true,
			type: Number,
		},
		passName: {
			required: true,
		},
		includeTerm: {
			required: true,
		},
		startDate: {
			required: true,
		},
		endDate: {
			required: true,
		},
	},
	computed: {
		today() {
			return moment.tz('Asia/Seoul').format('MMM Do, YYYY')
		},
		start() {
			return moment(this.startDate).format('MMM Do, YYYY')
		},
		end() {
			return moment(this.endDate).format('MMM Do, YYYY')
		},
		memberNameKr() {
			return this.$store.getters['member/nameKr']
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		selectedPoints() {
			return this.selectedPointId === 0
				? this.points
				: R.filter(p => p.id === this.selectedPointId)(this.points)
		},
		bookedTalk() {
			return R.filter(
				l => l.lesson_type === 0 && l.status.includes('analyzed'),
			)(this.lessons).length
		},
		bookedCall() {
			return R.filter(
				l => l.lesson_type === 1 && l.status.includes('analyzed'),
			)(this.lessons).length
		},
		talkLessonLength() {
			return R.filter(
				l =>
					(l.point_id === this.selectedPointId || this.selectedPointId === 0) &&
					l.lesson_type === 0,
				this.lessons,
			).length
		},
		callLessonLength() {
			return R.filter(
				l =>
					(l.point_id === this.selectedPointId || this.selectedPointId === 0) &&
					l.lesson_type === 1,
				this.lessons,
			).length
		},
		talkAttendanceRate() {
			return this.bookedTalk > 0
				? Math.round((this.talkLessonLength / this.bookedTalk) * 100)
				: 0
		},
		callAttendanceRate() {
			return this.bookedCall > 0
				? Math.round((this.callLessonLength / this.bookedCall) * 100)
				: 0
		},
		talkStatement() {
			return this.$t(
				`%{rate}% (총 %{booked}회 수업 중, %{lessonCount}회 출석)`,
				{
					rate: this.talkAttendanceRate,
					booked: this.bookedTalk,
					lessonCount: this.talkLessonLength,
				},
			)
		},
		callStatement() {
			return this.$t(
				`%{rate}% (총 %{booked}회 수업 중, %{lessonCount}회 출석)`,
				{
					rate: this.callAttendanceRate,
					booked: this.bookedCall,
					lessonCount: this.callLessonLength,
				},
			)
		},
	},
	methods: {
		wonCommaFormat: won => won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
	},
}
</script>

<style lang="scss" scoped>
table {
	border-top: 2px solid #000;
	border-bottom: 2px solid #000;
	tr {
		td {
			box-sizing: border-box;
			padding: 10px;
			border-bottom: 1px solid #c4c4c4;
			font-size: 14px !important;
			font-weight: medium !important;
		}

		td.table-title {
			background-color: #f2f2f2;
			width: 110px;
		}
	}

	tr:last-child {
		td {
			border-bottom: unset;
		}
	}
}
</style>
