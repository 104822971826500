<template>
	<div>
		<div v-for="item in group" :key="item.title" class="mb-10">
			<div class="mb-3">
				<h2 class="mb-1 text-body-1 font-weight-bold d-flex">
					{{ item.title }}
					<v-spacer></v-spacer>
					<v-icon
						@click="
							$router.push({ name: 'ProgramRecommend', query: item.routeQuery })
						"
						class="cursor-pointer"
						>mdi-chevron-right</v-icon
					>
				</h2>
				<p
					v-if="item.description"
					class="grey--text text--darken-1 text-body-2"
				>
					{{ item.description }}
				</p>
			</div>
			<swiper class="swiper" :options="swiperOption">
				<swiperSlide v-for="program in item.programs" :key="program.id">
					<div class="">
						<ProgramCard
							:program="program"
							:selectedProgramId="selectedProgram ? selectedProgram.id : null"
							@selectProgram="selectProgram"
						/>
					</div>
				</swiperSlide>
			</swiper>
		</div>
	</div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import ProgramCard from '../common/Card'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('program')
const R = require('ramda')

export default {
	components: { swiper, swiperSlide, ProgramCard },
	data: () => ({
		isLoading: false,
		selectedProgram: null,
		swiperOption: {
			slidesPerView: 1.35,

			spaceBetween: 15,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
		},
	}),
	computed: {
		...mapGetters(['programs', 'selectedLessonType']),
		group() {
			const talkGroup = [
				{
					title: '✨ 새로운 프로그램이 나왔어요!',
					description: null,
					routeQuery: { category: 'new', type: 'talk' },
					programIds: [225, 224, 223, 222, 220, 219],
					programs: R.pipe(
						R.filter(
							p => [225, 224, 223, 222, 220, 219].includes(p.id) === true,
						),
						R.sortWith([R.descend(R.prop('id'))]),
					)(this.programs),
				},
				{
					title: '💻 비대면 업무 환경에서 유용한 표현!',
					description: 'Slack, Notion, Zoom에서 영어로 유창하게 말해봐요.',
					routeQuery: { category: 'business', type: 'talk' },
					programIds: [203, 204, 205, 206],
					programs: R.filter(
						p => [203, 204, 205, 206].includes(p.id) === true,
						this.programs,
					),
				},
				{
					title: '🛫 해외유학/취직을 꿈꾸는 당신을 위해',
					description:
						'해외에서 공부하고, 일하고, 생활하기 위해 필요한 표현을 알아보고, 대화를 나눠봐요.',
					routeQuery: { category: 'abroad', type: 'talk' },
					programIds: [27, 28, 30, 31],
					programs: R.filter(
						p => [27, 28, 30, 31].includes(p.id) === true,
						this.programs,
					),
				},
				{
					title: '🤸‍♀️ 즐겁게 VOCA 공부하기',
					description:
						'무작정 단어 암기는 이제 그만! 채팅으로 자연스럽게 어휘력을 길러보세요.',
					programIds: [74, 123, 33, 133, 63, 77],
					routeQuery: { category: 'voca', type: 'talk' },
					programs: R.filter(
						p => [74, 123, 33, 133, 63, 77].includes(p.id) === true,
						this.programs,
					),
				},
				{
					title: '🤔 아직 문장 만들기가 어렵다면?',
					description: '패턴 연습부터 차근차근 시작해봐요!',
					routeQuery: { category: 'pattern', type: 'talk' },
					programIds: [20, 21, 103, 107],
					programs: R.filter(
						p => [20, 21, 103, 107].includes(p.id) === true,
						this.programs,
					),
				},
			]
			const callGroup = [
				{
					title: '🎤 OPIc 완전정복',
					description: '나만의 맞춤 답안을 만들고 실전처럼 연습해보세요!',
					routeQuery: { category: 'opic', type: 'call' },
					programIds: [170, 171, 172, 173, 174, 175],
					programs: R.filter(
						p => [170, 171, 172, 173, 174, 175].includes(p.id) === true,
						this.programs,
					),
				},
				{
					title: '🤔 아직 영어로 말하기 두렵다면?',
					description: '패턴 연습부터 차근차근 시작해봐요!',
					routeQuery: { category: 'pattern', type: 'call' },
					programIds: [141, 142, 163, 164],
					programs: R.filter(
						p => [141, 142, 163, 164].includes(p.id) === true,
						this.programs,
					),
				},
				{
					title: '👊 영어 면접 한 방에 끝내기!',
					description: '영어 면접이 걱정되는 분들을 위한 토탈 솔루션',
					routeQuery: { category: 'interview', type: 'call' },
					programIds: [160, 155, 167],
					programs: R.filter(
						p => [160, 155, 167].includes(p.id) === true,
						this.programs,
					),
				},
			]
			return this.selectedLessonType === 0 ? talkGroup : callGroup
		},
	},
	methods: {
		programFilter(programIds) {
			return R.filter(p => programIds.includes(p.id) === true, this.programs)
		},
		selectProgram(programObj) {
			this.$router.push({
				name: 'ProgramUnit',
				params: { programId: programObj.id },
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
