<template>
	<div
		class="d-flex align-center py-1"
		style="border-bottom: 1px solid #dadada"
	>
		<div
			class="text-body-2 text-nls text-center mr-3 status-box font-weight-bold"
			:class="{
				'primary white--text': status === $t('수강중'),
				'grey white--text': status !== $t('수강중'),
			}"
		>
			{{ status }}
		</div>
		<div>
			<p class="text-body-2 font-weight-bold">
				{{ name }}
				<span v-if="tag" class="primary--text text-caption pl-1"
					>#{{ tag }}</span
				>
			</p>
			<p class="text-caption grey--text">
				{{ group.startDate }}~{{ group.endDate }}
			</p>
		</div>
		<v-spacer></v-spacer>
		<div>
			<v-btn @click="goToDetail()" icon text
				><v-icon>mdi-chevron-right</v-icon></v-btn
			>
		</div>
	</div>
</template>

<script>
import moment from 'moment-timezone'
const R = require('ramda')

export default {
	props: {
		group: {
			required: true,
			type: Object,
		},
	},
	computed: {
		status() {
			const kstMoment = moment.tz('Asia/Seoul')
			if (this.group.endDate < kstMoment.format('YYYY-MM-DD'))
				return this.$t('pass.name.past')
			if (this.group.startDate > kstMoment.format('YYYY-MM-DD'))
				return this.$t('pass.name.future')
			return this.$t('pass.name.present')
		},
		tag() {
			return this.group.paymentId !== null
				? this.$t('결제')
				: this.group.passCouponMemberId
				? this.$t('수강권코드')
				: this.group.groupPointTypeId
				? this.$t('그룹수강권')
				: null
		},
		name() {
			return this.talkAssigned > 0 && this.callAssigned > 0
				? this.$t(`톡 %{talkAssigned}회 & 콜 %{callAssigned}회 수강권`, {
						talkAssigned: this.talkAssigned,
						callAssigned: this.callAssigned,
				  })
				: this.talkAssigned > 0
				? this.$t(`25분톡 %{talkAssigned}회 수강권`, {
						talkAssigned: this.talkAssigned,
				  })
				: this.callAssigned > 0
				? this.$t(`15분콜 %{callAssigned}회 수강권`, {
						callAssigned: this.callAssigned,
				  })
				: this.$t('보너스 수강권')
		},
		talkAssigned() {
			return R.pipe(
				R.filter(p => p.type === 0 && p.is_bonus === 0),
				R.map(p => p.assigned_points),
				R.sum(),
			)(this.group.points)
		},
		callAssigned() {
			return R.pipe(
				R.filter(p => p.type === 1 && p.is_bonus === 0),
				R.map(p => p.assigned_points),
				R.sum(),
			)(this.group.points)
		},
	},
	methods: {
		goToDetail() {
			let query = {}
			if (this.group.paymentId) query['payment-id'] = this.group.paymentId
			else if (this.group.groupPointTypeId)
				query['group-point-type-id'] = this.group.groupPointTypeId
			else if (this.group.passCouponMemberId)
				query['pass-coupon-member-id'] = this.group.passCouponMemberId
			else query['point-id'] = this.group.points[0].id

			this.$router.push({
				name: 'PassDetail',
				query,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.status-box {
	width: 50px;
	height: 50px;
	padding-top: 14px;
	border-radius: 10px;
}
</style>
