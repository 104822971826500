<template>
	<Wrap />
</template>

<script>
import Wrap from '@/components/trial/report/Wrap'

export default {
	components: {
		Wrap,
	},
	// beforeRouteEnter(to, from, next) {
	// 	console.log(to)
	// 	if (!to.params.trialId || !to.query.phone || !to.query['country-code']) {
	// 		alert('체험 정보를 조회할 수 없습니다.\n\nERROR CODE: 92X02B')
	// 		next({ name: 'Trial' })
	// 	} else next()
	// },
}
</script>

<style lang="scss" scoped></style>
