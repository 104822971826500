<template>
	<!-- <v-btn
		@click="
			gtagClick(),
				$router.push({ name: 'Plans', params: { category: 'package' } })
		"
		outlined
		small
		color="black"
		>{{ $t('수강권') }}</v-btn
	> -->
	<div
		class="hover-pointer"
		@click="$router.push({ name: 'Plans', params: { category: 'package' } })"
	>
		<img
			src="@/assets/images/icon/ticket.png"
			style="
				width: 28px;
				vertical-align: middle;
				margin-top: -2px;
				margin-right: 3px;
			"
			alt=""
		/>
		<h3 class="d-inline-block font-14 primary--text font-weight-medium">
			{{ $t('component.planButton.button.수강권 구매') }}
		</h3>
	</div>
</template>

<script>
export default {
	methods: {
		gtagClick() {
			// eslint-disable-next-line no-undef
			gtag('event', 'click_2home_plan_btn', {
				event_path: `my.tella.co.kr${this.$route.path}`,
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
