export const SET_ERROR = 'SET_ERROR'
export const CLOSE_DIALOG = 'CLOSE_DIALOG'

export default {
	[SET_ERROR](state, payload) {
		state.dialog = true
		state.error = payload
	},
	[CLOSE_DIALOG](state) {
		state.dialog = false
		setTimeout(() => {
			state.error = null
		}, 500)
	},
}
