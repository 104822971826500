<template>
	<v-expand-transition>
		<v-row class="white py-2" no-gutters align="center">
			<v-col cols="2">
				<v-btn fab icon text small @click="goBack()">
					<v-icon>mdi-arrow-left</v-icon>
				</v-btn>
			</v-col>
			<v-col cols="8">
				<h1
					class="
						text-center text-body-1
						font-weight-bold
						grey--text
						text--darken-3
					"
				>
					{{ $t('plan.bar.title') }}
				</h1>
			</v-col>
		</v-row>
	</v-expand-transition>
</template>

<script>
export default {
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			beforeName === 'Plans' ||
			beforeName === 'Event' ||
			beforeName === 'Review'
				? this.$router.push({ name: 'Home' })
				: this.$router.go(-1)
		},
	},
	// created() {
	// 	window.addEventListener('scroll', this.handleScroll)
	// },
	// destroyed: function () {
	// 	window.removeEventListener('scroll', this.handleScroll)
	// },
	// methods: {
	// 	handleScroll() {
	// 		if (this.scrollY > window.top.scrollY + 10 || window.top.scrollY < 60) {
	// 			this.show = true
	// 		} else this.show = false
	// 		this.scrollY = window.top.scrollY
	// 	},
	// },
}
</script>

<style lang="scss" scoped></style>
