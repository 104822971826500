import getters from './getters'
import mutations from './mutations'

const state = {
	dialog: false,
	error: null,
	name: null,
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
}
