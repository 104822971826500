var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-img',{attrs:{"id":"preview-mobile","src":require("@/assets/images/trial-landings/pc-2-bg.svg")}},[_c('div',{staticClass:"pt-13 pb-13"},[_c('h1',{staticClass:"text--h1 mb-7 text-center",domProps:{"innerHTML":_vm._s(_vm.$t('trial-confirmation.preview-mobile.title'))}}),_c('div',{staticStyle:{"overflow-x":"scroll","scroll-behavior":"smooth"},attrs:{"id":"scroll-target"}},[_c('div',{staticClass:"d-flex align-start px-3 pt-1",staticStyle:{"width":"670px","height":"80px","position":"relative"}},[_c('div',{staticClass:"rounded-step-back",style:(("left:" + _vm.leftPosition + "px;width:" + _vm.width + "px"))}),_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"step-wrap d-flex align-center",class:{
							active: item.id === _vm.active,
							'ice-breaking': item.class === 'ice-breaking',
							'today-expression': item.class === 'today-expression',
							'role-playing': item.class === 'role-playing',
							feedback: item.class === 'feedback',
						},staticStyle:{"z-index":"1","position":"relative"},on:{"click":function($event){return _vm.selectActive(item.id)}}},[_c('div',{staticClass:"step",class:{ active: _vm.active === item.id }},[_vm._v(" "+_vm._s(item.id + 1)+" ")]),_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(item.text)+" ")])])})],2)]),_c('swiper',{ref:"swiper",staticClass:"mb-10 swiper",attrs:{"options":_vm.swiperOption},on:{"slideChangeTransitionStart":_vm.onSwiperSlideChangeTransitionStart}},_vm._l((_vm.list),function(item){return _c('swiperSlide',{key:("img-" + (item.id))},[_c('v-img',{attrs:{"width":"100%","max-width":"300px","src":item.src}})],1)}),1),_c('div',{staticClass:"mx-auto",class:{
					'ice-breaking': _vm.active === 0,
					'today-expression': _vm.active === 1,
					'role-playing': _vm.active === 2,
					feedback: _vm.active === 3,
				},staticStyle:{"width":"80px"}},[_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }