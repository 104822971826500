<template>
	<div class="correction-wrap">
		<div v-html="editings[0].tutee_chat_log_content"></div>
		<div class="mt-2" v-for="editing in editings" :key="editing.id">
			<img
				src="@/assets/images/lesson/lesson-arrow.png"
				style="width: 10px; margin-right: 3px"
				alt=""
			/>
			<span
				v-html="
					afterDiff(
						editings[0].tutee_chat_log_content,
						editing.tutor_chat_log_content,
					)
				"
			></span>
		</div>
	</div>
</template>

<script>
const jsdiff = require('diff')

export default {
	props: {
		editings: {
			required: true,
			type: Array,
		},
	},
	methods: {
		beforeDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.removed) {
					span === null
						? (span = `<span class='font-weight-bold before'>${part.value}</span>`)
						: (span += `<span class='font-weight-bold before'>${part.value}</span>`)
				} else if (!part.added) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
		afterDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.added) {
					span === null
						? (span = `<span class='font-weight-bold after'>${part.value}</span>`)
						: (span += `<span class='font-weight-bold after'>${part.value}</span>`)
				} else if (!part.removed) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
	},
}
</script>

<style lang="scss">
.correction-wrap {
	.after {
		font-style: italic;
		color: #00cba0;
	}
}
</style>
