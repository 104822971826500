<template>
	<div
		id="trial-correction"
		style="background-color: #f1f5ff"
		class="pt-sm-16 mt-sm-6 pb-sm-16 pt-15 pb-12"
	>
		<h1 class="text--h1 mb-sm-12 mb-9 text-center">
			<strong class="font-weight-bold">{{
				$t('trial.confirmation.title.첨삭 문장 모아보기')
			}}</strong>
		</h1>
		<div
			class="mx-auto"
			style="max-width: 880px; width: 100%; position: relative"
		>
			<div class="d-none d-sm-block fade-out fade-out-left"></div>
			<div class="d-none d-sm-block fade-out fade-out-right"></div>
			<swiper class="swiper" :options="swiperOption">
				<swiperSlide
					v-for="(correction, i) in corrections"
					:key="`correction-${i}`"
				>
					<v-card class="correction-card">
						<h1 class="mb-1 before-title">
							{{ $t('trial.confirmation.title.내가 쓴 문장') }}
							<img
								style="vertical-align: text-bottom"
								src="@/assets/images/trial-landings/ic-ios-writing.png"
								alt=""
							/>
						</h1>
						<p
							class="mb-6 correction-text"
							v-html="
								beforeDiff(
									correction.tutee_chat_log_content,
									correction.tutor_chat_log_content,
								)
							"
						></p>
						<h1 class="d-inline-block after-title">
							{{ $t('trial.confirmation.text.첨삭 받은 문장') }}
						</h1>
						<p>
							<span
								class="correction-text"
								v-html="
									afterDiff(
										correction.tutee_chat_log_content,
										correction.tutor_chat_log_content,
									)
								"
							></span>
							<!-- <span class="after" v-html="correction.html"></span> -->
							<AudioPlayer
								class="d-inline-block ml-1"
								:script="correction.origin"
								:color="'after'"
								:text="true"
							/>
						</p>
					</v-card>
				</swiperSlide>
			</swiper>
		</div>
	</div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import AudioPlayer from './AudioPlayer'
const jsdiff = require('diff')

export default {
	components: {
		swiper,
		swiperSlide,
		AudioPlayer,
	},
	data() {
		return {
			corrections: [
				{
					id: 1,
					tutee_chat_log_content: 'I eat pasta a without stop.',
					tutor_chat_log_content: 'I can’t get enough of pasta.',
				},
				{
					id: 2,
					tutee_chat_log_content:
						'It was only experience that I saw you were frustrated.',
					tutor_chat_log_content:
						'It was the only time (that) I saw you frustrated.',
				},
				{
					id: 3,
					tutee_chat_log_content: 'Do you overwork a lot?',
					tutor_chat_log_content: 'Do you work overtime a lot?',
				},
				{
					id: 4,
					tutee_chat_log_content:
						'Can you borrow me some money? My credit card limit is over.',
					tutor_chat_log_content:
						'Can you spot me? I maxed out my credit card.',
				},
				{
					id: 5,
					tutee_chat_log_content:
						'I usually have a meeting, at that time my parents often call me.',
					tutor_chat_log_content:
						'My parents would often call me when I have a meeting.',
				},
			],
		}
	},
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		swiperOption() {
			return {
				slidesPerView: this.userDevice === 'mobile' ? 1.2 : 3.5,
				centeredSlides: true,
				spaceBetween: 15,
				// loop: true,
			}
		},
	},
	methods: {
		beforeDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.removed) {
					span === null
						? (span = `<span class='before'>${part.value}</span>`)
						: (span += `<span class='before'>${part.value}</span>`)
				} else if (!part.added) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
		afterDiff(a, b) {
			let diff = jsdiff.diffWords(a, b)
			let span = null

			diff.forEach(function (part) {
				if (part.added) {
					span === null
						? (span = `<span class='after'>${part.value}</span>`)
						: (span += `<span class='after'>${part.value}</span>`)
				} else if (!part.removed) {
					span === null
						? (span = '<span>' + part.value + '</span>')
						: (span = span + '<span>' + part.value + '</span>')
				}
			})
			return span
		},
	},
}
</script>

<style lang="scss" scoped>
.fade-out-left {
	left: 0;
	background: linear-gradient(
		to right,
		rgba(241, 245, 255, 1),
		rgba(241, 245, 255, 0.7),
		rgba(241, 245, 255, 0)
	);
}
.fade-out-right {
	right: 0;
	background: linear-gradient(
		to left,
		rgba(241, 245, 255, 1),
		rgba(241, 245, 255, 0.7),
		rgba(241, 245, 255, 0)
	);
}
.fade-out {
	position: absolute;
	top: 0px;
	width: 50px;
	height: 100%;
	z-index: 2;
}
.correction-card {
	padding: 29px 7px 36px 24px;
	border-radius: 16px;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1) !important;
	background-color: #fff;
}

.before-title {
	font-size: 14px !important;
	font-weight: bold !important;
	line-height: 1.79 !important;
	letter-spacing: -0.44px !important;
	color: #191f28 !important;
}
.correction-text {
	font-size: 13px !important;
	font-weight: normal;
	line-height: 1.85 !important;
	letter-spacing: -0.3px !important;
}

.after-title {
	font-size: 13px !important;
	font-weight: bold !important;
	line-height: 1.92 !important;
	letter-spacing: -0.41px !important;
	text-align: center;
	color: #fff;
	padding: 1px 13px 0px 11px;
	border-radius: 12.5px;
	background-color: #0abe8b;
	margin-bottom: 11px;
}
.chat-title {
	font-size: 18px !important;
	font-weight: 600 !important;
	line-height: 1.17 !important;
	letter-spacing: -0.56px !important;
	color: #191f28 !important;
}
.chat-text {
	font-size: 14px !important;
	font-weight: normal !important;
	line-height: 1.79 !important;
	letter-spacing: -0.44px !important;
	color: #485363 !important;
}
</style>

<style lang="scss">
#trial-correction {
	.correction-text .before {
		color: #e06a66 !important;
		font-weight: 600 !important;
	}
	.correction-text .after {
		color: #00a882 !important;
		font-weight: 600 !important;
	}
}
</style>
