<template>
	<div class="text-body-2" style="line-height: 20px">
		<img
			:src="require(`@/assets/images/flag/${nation.toLowerCase()}.png`)"
			style="width: 11px; height: 11px; margin-top: 1px"
			alt
		/>
		{{ name }}
	</div>
</template>

<script>
export default {
	props: {
		nation: {
			required: true,
			type: String,
		},
	},
	computed: {
		name() {
			return this.nation === 'UGA'
				? this.$t('우간다')
				: this.nation === 'PHL'
				? this.$t('필리핀')
				: this.nation === 'GHA'
				? this.$t('가나')
				: this.$t('국적 미상')
		},
	},
}
</script>

<style lang="scss" scoped></style>
