<template>
	<div>
		<v-btn
			:disabled="isValid === false"
			:loading="isLoading"
			large
			depressed
			block
			color="primary"
			@click="book"
		>
			{{ $t('button.book-trial') }}
		</v-btn>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="errDialogPersistent"
			:numberOfButton="errNumberOfButton"
			:title="errTitle"
			:text="errMsg"
			:actionColor="errActionColor"
			:action="errAction"
			:actionText="errActionText"
		/>
	</div>
</template>

<script>
import Api from '@/services/index'
import Dialog from '@/components/common/Dialog'

export default {
	components: {
		Dialog,
	},
	props: {
		memberId: { required: true, type: Number },
		name: { required: true },
		email: { required: false },
		phone: { required: true },
		countryCode: { required: true },
		datetime: { required: true },
		language: { required: true },
		timezone: { required: true },
		nationality: { required: true },
		isValid: { required: true, type: Boolean, default: false },
	},
	data() {
		return {
			isLoading: false,
			errorDialog: false,
			errTitle: '',
			errMsg: '',
			errNumberOfButton: 1,
			errDialogPersistent: false,
			errActionColor: 'primary',
			errAction: this.closeErrDialog,
			errActionText: this.$t('modal.button.confirm'),
		}
	},
	methods: {
		gtagClick() {
			// eslint-disable-next-line no-undef
			gtag('event', 'click_trial_complete', {
				event_path: `my.tella.co.kr${this.$route.path}`,
			})
		},
		async book() {
			try {
				this.gtagClick()
				this.isLoading = true
				const params = {
					lessonType: 0,
					name: this.name,
					email: this.email,
					phone: this.phone,
					countryCode: this.countryCode,
					datetime: this.datetime,
					language: this.language,
					timezone: this.timezone,
					memberId: this.memberId,
					nationality: this.nationality,
				}
				const res = await Api.post(`trials`, params)
				const trialId = res.data
				this.$conversionTrialComplete(trialId, {
					name: this.name,
					phone: this.phone,
					countryCode: this.countryCode,
				})
				this.$router.push({
					name: 'TrialConfirmation',
					params: { trialId: trialId },
					query: { phone: this.phone, 'country-code': this.countryCode },
				})
			} catch (err) {
				this.errController(err.response.data.code)
			} finally {
				this.isLoading = false
			}
		},
		errController(errCode) {
			switch (errCode) {
				case '110001':
					// errMsg = '90일 이내에 같은 번호로 3회 이상 결석 혹은 번호 검색 안됨'
					this.errTitle = this.$t(
						'modal.title.trial-book-error-3-times-no-show',
					)
					this.errMsg = this.$t('modal.text.trial-book-error-3-times-no-show')
					this.errAction = this.goToCs
					this.errActionText = this.$t('modal.button.go-to-cs')
					break
				case '110004':
					// errMsg = '90일 이내에 리포트를 받은 적 있음'
					this.errTitle = this.$t('modal.title.trial-book-error-already-done')
					this.errMsg = this.$t('modal.text.trial-book-error-already-done')
					this.errAction = this.goToLessons
					this.errActionText = this.$t('modal.button.confirm')
					break
				case '110005':
					// errMsg = '이미 예약되어 있는 수업이 있음'
					this.errTitle = this.$t(
						'modal.title.trial-book-error-already-appointed',
					)
					this.errMsg = this.$t('modal.text.trial-book-error-already-appointed')
					this.errAction = this.goToHome
					this.errActionText = this.$t('modal.button.confirm')
					break
				case '110003':
					// errMsg = '슬랏 부족'
					this.errTitle = this.$t('modal.title.trial-book-error-no-slot')
					this.errMsg = ''
					this.errAction = this.closeErrDialog
					this.errActionText = this.$t('modal.button.confirm')
					break
				case '110002':
					// errMsg = '30분 이내에 시작되는 수업은 예약할 수 없음'
					this.errTitle = this.$t('modal.title.trial-book-error-in-30-minutes')
					this.errMsg = ''
					this.errAction = this.closeErrDialog
					this.errActionText = this.$t('modal.button.confirm')
					break
				default:
					this.errTitle = this.$t('modal.title.unexpected-error')
					this.errMsg = this.$t('modal.text.unexpected-error')
					this.errAction = this.goToCs
					this.errActionText = this.$t('modal.button.go-to-cs')
			}
			this.errorDialog = !this.errorDialog
		},
		goToCs() {
			const url =
				this.language === 'ja'
					? 'https://tella.channel.io/support-bots/25548'
					: 'https://tella.channel.io/support-bots/30776'
			window.open(url, '_blank').focus()
		},
		goToHome() {
			this.$router.push({ name: 'Home' })
		},
		goToLessons() {
			this.$router.push({ name: 'Lessons', query: { category: 'levelTest' } })
		},
		closeErrDialog() {
			this.errorDialog = !this.errorDialog
		},
	},
}
</script>

<style lang="scss" scoped></style>
