<template>
	<v-row
		style="max-width: 717px"
		no-gutters
		class="align-center mx-auto pt-14 pb-13 px-5 px-sm-0"
	>
		<v-col cols="12" sm="7" class="pr-0 pr-sm-3">
			<h1 class="mb-5 title2 white-space-pre">
				{{ $t('trial-report.kakao-talk-info.title') }}
			</h1>
			<p class="mb-7 mb-sm-0 text default-color white-space-pre">
				{{ $t('trial-report.kakao-talk-info.text') }}
			</p>
		</v-col>
		<v-col cols="12" sm="5" class="pl-0 pl-sm-3">
			<div class="d-flex justify-end" style="width: 100%">
				<v-img
					style="width: 38%"
					src="@/assets/images/trials/kakao-logo.png"
				></v-img>
				<div class="pl-3" style="width: 62%">
					<a href="https://apple.co/3subdO8" target="_blank">
						<v-img
							class="mb-2"
							src="@/assets/images/trials/button_app_store.png"
						></v-img>
					</a>
					<a href="https://bit.ly/3JbZWIm" target="_blank">
						<v-img src="@/assets/images/trials/button_google_play.png"></v-img>
					</a>
				</div>
			</div>
		</v-col>
	</v-row>
</template>

<script>
export default {
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
	},
}
</script>

<style></style>
