import Vue from 'vue'
import Vuex from 'vuex'

import device from './modules/device/index'
import auth from './modules/auth/index'
import member from './modules/member/index'
import appointed from './modules/appointed/index'
import point from './modules/point/index'
import group from './modules/group/index'
import book from './modules/book/index'
import tutorRating from './modules/tutor-rating/index'
import program from './modules/program/index'
import error from './modules/error/index'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		device,
		auth,
		member,
		appointed,
		point,
		group,
		book,
		tutorRating,
		program,
		error,
	},
})
