var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white pb-14"},[_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"300px"}},[_c('div',{staticClass:"d-flex align-center mb-6"},[_c('h2',{staticClass:"title3 title-color"},[_vm._v(" "+_vm._s(_vm.$t('trial-report.grammar'))+" ")]),_c('v-spacer'),_c('div',{staticClass:"score"},[_c('div',{staticClass:"d-inline-block dot"}),_c('p',{staticClass:"d-inline-block sub-title2",staticStyle:{"color":"#ef872c"}},[_vm._v(" "+_vm._s(_vm.$t('trial-report.score', { score: _vm.grammarScore, }))+" ")])])],1),_c('div',[_c('p',{staticClass:"text mb-8 default-color"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('p',{staticClass:"mb-3 title4 d-inline-block rounded-xl chip title-color"},[_vm._v(" "+_vm._s(_vm.$t('trial-report.sentenceStructure'))+" ")]),_c('p',{staticClass:"text mb-8 default-color"},[_vm._v(" "+_vm._s(_vm.sentenceStructureTable.description)+" ")])]),_c('div',{staticClass:"average-card mb-3"},[_c('p',{staticClass:"average-text mb-2",domProps:{"innerHTML":_vm._s(
					_vm.$t('trial-report.grammar.average-length-info', { averageLength: _vm.averageLength })
				)}}),_c('div',{staticClass:"example-wrap d-flex"},[_c('div',{staticClass:"example"},[_vm._v(" "+_vm._s(_vm.$t('trial-report.sentenceStructure.example'))+" ")]),_c('div',[_vm._v(_vm._s(_vm.sentenceStructureTable.example))])])]),_c('div',{staticClass:"average-card pb-4"},[_c('p',{staticClass:"average-text",domProps:{"innerHTML":_vm._s(
					_vm.$t('trial-report.grammar.user-length-info', {
						level: _vm.level,
						averageLengthOfLevel: _vm.averageLengthOfLevel,
					})
				)}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }