<template>
	<div>
		<v-card rounded="xl" class="elevation-card">
			<section class="px-5 py-4">
				<div
					:class="{
						talk: lesson.lesson_type === 0,
						call: lesson.lesson_type === 1,
					}"
					class="d-inline-block lesson-chip text-caption font-weight-medium"
				>
					<span v-if="lesson.lesson_type === 0"> 💬&nbsp;&nbsp;Talk </span>
					<span v-if="lesson.lesson_type === 1"> 📞&nbsp;&nbsp;Call </span>
				</div>
				<p class="text-body-1">
					{{ lesson.moment.clone().locale(language).format('ll (ddd)') }}
					{{ lesson.moment.clone().locale('en').format('hh:mm A') }}
				</p>
			</section>
			<v-divider></v-divider>
			<section class="pa-5">
				<div class="mb-5 d-flex align-start">
					<v-img
						:max-width="70"
						style="border-radius: 5px"
						:src="`${baseUrl}/v1/program/thumbnail/portrait/${language}/${program.id}.jpg`"
					>
					</v-img>
					<div class="pl-3" style="width: 100%">
						<h1 class="text-body-1 font-weight-bold">
							{{ program.name }}
						</h1>
						<h2 class="text-body-2 text-nlh">
							{{ program.title }}
						</h2>
						<v-divider class="my-3"></v-divider>
						<div class="d-flex align-center">
							<TutorAvatar
								:type="lesson.lesson_type"
								:id="lesson.tutor_id"
								:name="lesson.tutor_name"
								:size="30"
								class="mr-1"
							/>
							{{ lesson.tutor_name_en }}
						</div>
					</div>
				</div>
			</section>
		</v-card>
	</div>
</template>

<script>
import TutorAvatar from '@/components/common/TutorAvatar'

export default {
	components: {
		TutorAvatar,
	},
	props: {
		lesson: {
			type: Object,
			required: true,
		},
	},
	data: () => ({
		baseUrl: 'https://image.tella.co.kr',
	}),
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		/**
		 * 1순위. 수업에서 제일 처음 선택한 교재 (예약한 교재 포괄)
		 * 2순위. 교재 없으면 '프리토킹' 교재 표시
		 */
		program() {
			if (this.lesson.program_unit_id) {
				return {
					name: this.lesson.program_name,
					id: this.lesson.program_id,
					unit_id: this.lesson.program_unit_id,
					title: `Unit ${this.lesson.unit}. ${this.lesson.unit_title}`,
				}
			} else {
				return {
					name: this.$t('프리토킹'),
					id: this.lesson.lesson_type === 0 ? 120 : 167,
					unit_id: null,
					title: this.$t(`교재 없이 자유대화를 진행합니다.`),
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.lesson-chip {
	box-sizing: border-box;
	border-radius: 12px 12px !important;
	padding: 2px 8px;

	letter-spacing: 0 !important;
}
.lesson-chip.talk {
	background: #1e78ff;
	color: #fff;
}
.lesson-chip.call {
	background: #00cca1;
}
</style>
