<template>
	<div>
		<Dialog
			:dialogControl="errorDialog"
			:persistent="true"
			:numberOfButton="2"
			:title="$t('sign-up-landing.modal.title.marketing-agree')"
			:text="$t('sign-up-landing.modal.text.marketing-agree', { name })"
			:actionColor="'primary'"
			:action="action"
			:actionText="$t('sign-up-landing.modal.button.marketing-agree-yes')"
			:secondAction="secondAction"
			:secondActionText="$t('sign-up-landing.modal.button.marketing-agree-no')"
		/>
	</div>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import Api from '@/services/index'

export default {
	components: {
		Dialog,
	},
	data() {
		return {
			errorDialog: false,
			action: this.update,
			secondAction: this.goToHome,
		}
	},
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		name() {
			return this.$store.getters['member/name']
		},
	},
	mounted() {
		this.errorDialog = true
	},
	methods: {
		async update() {
			try {
				this.isLoading = true
				let params = {
					marketingSms: 1,
					marketingEmail: 1,
				}
				await Api.patch(`members/${this.memberId}`, params)
				// this.$router.push({ name: 'myhome-info' })
			} catch (err) {
				alert(this.$t('회원 정보 변경이 실패하였습니다. 다시 시도해주세요.'))
			} finally {
				this.goToHome()
				this.isLoading = false
			}
		},
		goToHome() {
			this.$router.push({ name: 'Home' })
		},
	},
}
</script>

<style lang="scss" scoped></style>
