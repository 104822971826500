<template>
	<v-card flat rounded="xl" color="primary" class="">
		<div class="px-5 py-2" style="position: relative">
			<v-chip small color="white" v-if="lesson.lesson_type === 0"
				>💬&nbsp;&nbsp;Talk</v-chip
			>
			<v-chip small color="white" v-else>📞&nbsp;&nbsp;Call</v-chip>
			<div>
				<v-icon color="white" class="text-h6">mdi-clock-outline</v-icon>

				<span class="text-body-2 white--text mt-1 font-weight-bold">
					{{ lesson.moment.locale(this.language).format(this.dateformat) }}
					{{ lesson.moment.format('H:mm') }}
					~
					{{
						lesson.moment
							.clone()
							.add(lesson.lesson_type === 0 ? 25 : 15, 'minutes')
							.format('H:mm')
					}}
				</span>
			</div>
			<div
				style="position: absolute; top: 18px; right: 15px"
				class="white--text text-body-2"
			>
				{{ $t('예약확정') }}
			</div>
		</div>
		<div class="px-5 py-2 white">
			<div
				class="selected-tutor-wrap mb-1"
				:class="{ 'active pa-1': $route.name === 'LessonProgramBooked' }"
			>
				<div class="d-flex align-center">
					<TutorAvatar
						:size="25"
						:id="lesson.tutor_id"
						:name="lesson.tutor_name_en"
						:type="lesson.lesson_type"
					/>
					<p class="text-body-2 ml-1">{{ lesson.tutor_name_en }}</p>
				</div>
			</div>
			<div
				v-if="!lesson.unit && $route.name !== 'LessonProgramBooked'"
				class="d-flex align-center selected-program-wrap pa-1"
			>
				<div
					class="rounded-lg grey lighten-1"
					style="width: 40px; height: 50px"
				></div>
				<div class="pa-3" style="width: calc(100% - 50px)">
					<h1 class="text-body-2">{{ $t('프로그램과 유닛을 선택하세요.') }}</h1>
				</div>
			</div>
			<div
				v-else-if="$route.name !== 'LessonProgramBooked'"
				class="d-flex align-center selected-program-wrap pa-1"
			>
				<v-img class="rounded-lg" width="40" :src="programImgUrl"></v-img>
				<div class="pl-3" style="width: calc(100% - 50px)">
					<h1 class="text-body-2 font-weight-medium">
						{{ lesson.unit.program_name }}
					</h1>
					<p class="text-caption">
						Unit {{ lesson.unit.unit }}. {{ title(lesson.unit.unit_title) }}
					</p>
				</div>
			</div>
		</div>
	</v-card>
</template>

<script>
import TutorAvatar from '@/components/common/TutorAvatar'

export default {
	components: {
		TutorAvatar,
	},
	props: {
		lesson: {
			require: true,
		},
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		dateformat() {
			return this.$store.getters['member/dateformat']
		},
		programImgUrl() {
			return `https://image.tella.co.kr/v1/program/thumbnail/portrait/${this.language}/${this.lesson.program_id}.jpg`
		},
	},
	methods: {
		title(unitTitle) {
			const titles = unitTitle ? JSON.parse(unitTitle) : { ko: [], ja: [] }
			return titles[this.language]
		},
	},
}
</script>

<style lang="scss" scoped>
.selected-tutor-wrap.active,
.selected-program-wrap {
	border: 0.5px dashed #1e78ff;
	box-sizing: border-box;
	border-radius: 10px;
}
</style>
