<template>
	<div>
		<h1 data-test="title" class="pl-4 mb-3 font-18 font-weight-bold">
			{{ title }}
		</h1>
		<swiper class="swiper pl-4" :options="swiperOption">
			<swiperSlide v-for="(program, i) in programs" :key="program.id">
				<div class="img-wrap cursor-pointer">
					<v-img
						v-if="label === 'rank'"
						data-test="label"
						class="program-rank-label text-center"
						src="@/assets/images/design/program-black-label.png"
						><span class="white--text font-13 font-weight-medium">
							{{ i + 1 }}
						</span></v-img
					>
					<p
						v-else-if="label === 'new'"
						data-test="label"
						class="program-new-label font-12 font-roboto font-weight-medium"
					>
						NEW
					</p>
					<img
						@click="$emit('selectProgram', program.id)"
						class="program-card"
						:src="`https://image.tella.co.kr/v1/program/thumbnail/portrait/${language}/${program.id}.jpg`"
					/>
				</div>
			</swiperSlide>
		</swiper>
	</div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
	components: { swiper, swiperSlide },
	props: {
		title: {
			required: true,
			type: String,
		},
		programs: {
			required: true,
			type: Array,
		},
		label: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: 2.35,

				spaceBetween: 15,
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
			},
		}
	},
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
	},
}
</script>

<style lang="scss" scoped>
.img-wrap {
	padding-top: 5px;
}
.program-card {
	width: 100%;
	border-radius: 4px;
}
.program-rank-label {
	position: absolute;
	top: 0px;
	left: 5px;
}
.program-new-label {
	color: #ff6060;
	position: absolute;
	top: 10px;
	left: 5px;
	padding: 5px 8px;
	background: #ffffff;
	border: 1px solid #ff6060;
	border-radius: 4px;
}
</style>
