var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-footer',{staticClass:"white text-center pa-0 no-gutters",staticStyle:{"border-top":"1px solid #dadada !important","height":"59px"},attrs:{"id":"footer","fixed":"","flat":""}},[_c('div',{staticClass:"foot-menu",on:{"click":function($event){_vm.gtagEvent('홈'), _vm.goTo('Home')}}},[_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require(("@/assets/images/icon/home" + (_vm.$route.name === 'Home' ? '-active' : '') + ".png")),"alt":""}}),_c('p',{staticClass:"menu-title grey--text",class:{
				'text--darken-3 font-weight-medium': _vm.$route.name === 'Home',
				'text--darken-1 font-weight-regular': _vm.$route.name !== 'Home',
			}},[_vm._v(" "+_vm._s(_vm.$t('홈'))+" ")])]),_c('div',{staticClass:"foot-menu",on:{"click":function($event){_vm.gtagEvent('프로그램'), _vm.goTo('Programs')}}},[_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require(("@/assets/images/icon/program" + (_vm.programRoutes.includes(_vm.$route.name) === true ? '-active' : '') + ".png")),"alt":""}}),_c('p',{staticClass:"menu-title grey--text",class:{
				'text--darken-3 font-weight-medium':
					_vm.programRoutes.includes(_vm.$route.name) === true,
				'text--darken-1 font-weight-regular':
					_vm.programRoutes.includes(_vm.$route.name) === false,
			}},[_vm._v(" "+_vm._s(_vm.$t('프로그램'))+" ")])]),_c('div',{staticClass:"foot-menu",on:{"click":function($event){_vm.gtagEvent('나의 수업'), _vm.goTo('AppointedLessons')}}},[_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require(("@/assets/images/icon/schedule" + (_vm.lessonRoutes.includes(_vm.$route.name) === true ? '-active' : '') + ".png")),"alt":""}}),_c('p',{staticClass:"menu-title grey--text",class:{
				'text--darken-3 font-weight-medium':
					_vm.lessonRoutes.includes(_vm.$route.name) === true,
				'text--darken-1 font-weight-regular':
					_vm.lessonRoutes.includes(_vm.$route.name) === false,
			}},[_vm._v(" "+_vm._s(_vm.$t('footer.menu-name.나의 수업'))+" ")])]),_c('div',{staticClass:"foot-menu",on:{"click":function($event){_vm.gtagEvent('학습리포트'), _vm.goTo('MonthlyReport')}}},[_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require(("@/assets/images/icon/report" + (_vm.reportRouts.includes(_vm.$route.name) === true ? '-active' : '') + ".png")),"alt":""}}),_c('p',{staticClass:"menu-title grey--text",class:{
				'text--darken-3 font-weight-medium':
					_vm.reportRouts.includes(_vm.$route.name) === true,
				'text--darken-1 font-weight-regular':
					_vm.reportRouts.includes(_vm.$route.name) === false,
			}},[_vm._v(" "+_vm._s(_vm.$t('학습리포트'))+" ")])]),_c('div',{staticClass:"foot-menu",on:{"click":function($event){_vm.gtagEvent('마이페이지'), _vm.goTo('MyPage')}}},[_c('img',{staticStyle:{"width":"30px"},attrs:{"src":require(("@/assets/images/icon/my" + (_vm.mypageRoutes.includes(_vm.$route.name) === true ? '-active' : '') + ".png")),"alt":""}}),_c('p',{staticClass:"menu-title grey--text",class:{
				'text--darken-3 font-weight-medium':
					_vm.mypageRoutes.includes(_vm.$route.name) === true,
				'text--darken-1 font-weight-regular':
					_vm.mypageRoutes.includes(_vm.$route.name) === false,
			}},[_vm._v(" "+_vm._s(_vm.$t('마이페이지'))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }