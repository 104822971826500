<template>
	<div>
		<v-row align="center" no-gutters class="pa-3">
			<v-col cols="4">
				<v-btn @click="goBack" icon><v-icon>mdi-arrow-left</v-icon></v-btn>
			</v-col>
			<v-col cols="4">
				<h1 class="text-center text-body-1 font-weight-regular">
					{{ $t('휴대폰 번호') }}
				</h1>
			</v-col>
		</v-row>
		<section class="pa-5">
			<h2 class="text-h6 mb-1 font-weight-bold">
				{{ $t('휴대폰 번호를 알려주세요.') }}
			</h2>
			<p class="text-body-2 mb-8 grey--text text--darken-3">
				{{ $t('서비스 안내, 수업 상담에 사용됩니다.') }}
			</p>

			<VerifyPhone
				class="mb-5"
				:dense="false"
				:originPhone="originPhone"
				:originCountryCode="originCountryCode"
				:allowDuplication="true"
				@verify="verify"
			/>
			<v-btn
				height="51"
				block
				x-large
				@click.native="changeProfile"
				color="grey darken-3 white--text"
				depressed
				:disabled="
					this.phone === null ||
					this.countryCode === null ||
					this.isVerified === false
				"
				>{{ $t('변경') }}</v-btn
			>
		</section>
	</div>
</template>

<script>
import VerifyPhone from '@/components/common/VerifyPhone'
import Api from '@/services/index'

export default {
	components: {
		VerifyPhone,
	},
	data: () => ({
		isLoading: false,
		phone: null,
		countryCode: null,
		isVerified: false,
		prevRouteName: null,
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		originPhone() {
			return this.$store.getters['member/phone']
		},
		originCountryCode() {
			return this.$store.getters['member/countryCode']
		},
	},
	methods: {
		goBack() {
			this.$router.push({ name: 'Setting' })
		},
		verify(boolean, numberObj) {
			this.isVerified = boolean
			if (boolean === false) {
				this.phone = null
				this.countryCode = null
			}
			if (boolean === true && numberObj) {
				this.phone = numberObj.phone
				this.countryCode = numberObj.countryCode
			}
		},
		async changeProfile() {
			try {
				this.isLoading = true
				let params = {
					phone: this.phone,
					countryCode: this.countryCode,
				}
				await Api.patch(`members/${this.memberId}`, params)
				alert(this.$t('전화번호가 업데이트 되었습니다.'))
			} catch (err) {
				alert(this.$t('전화번호 업데이트가 실패하였습니다. 다시 시도해주세요.'))
			} finally {
				this.isLoading = false
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
