var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"white"},[_c('v-row',{staticClass:"text-center tab-wrap",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',{staticClass:"\n\t\t\t\t\td-inline-block\n\t\t\t\t\ttext-center text-body-1\n\t\t\t\t\tfont-weight-bold\n\t\t\t\t\tpy-1\n\t\t\t\t\tmenu\n\t\t\t\t",class:{
					'primary--text': _vm.routeName === 'Plans',
					'gre--text text--darken-3': _vm.routeName !== 'Plans',
				},on:{"click":function($event){_vm.gtagClick('수강권'), _vm.goTo('Plans')}}},[_vm._v(" "+_vm._s(_vm.$t('plan.menu.plan'))+" ")])]),_c('v-col',{attrs:{"cols":"4"},on:{"click":function($event){_vm.gtagClick('수강후기'), _vm.goTo('Review')}}},[_c('p',{staticClass:"\n\t\t\t\t\td-inline-block\n\t\t\t\t\ttext-center text-body-1\n\t\t\t\t\tfont-weight-bold\n\t\t\t\t\tpy-1\n\t\t\t\t\tmenu\n\t\t\t\t",class:{
					'primary--text': _vm.routeName === 'Review',
					'gre--text text--darken-3': _vm.routeName !== 'Review',
				}},[_vm._v(" "+_vm._s(_vm.$t('plan.menu.review'))+" ")])]),_c('v-col',{attrs:{"cols":"4"},on:{"click":function($event){_vm.gtagClick('이벤트'), _vm.goTo('Event')}}},[_c('p',{staticClass:"\n\t\t\t\t\td-inline-block\n\t\t\t\t\ttext-center text-body-1\n\t\t\t\t\tfont-weight-bold\n\t\t\t\t\tpy-1\n\t\t\t\t\tmenu\n\t\t\t\t",class:{
					'primary--text': _vm.routeName === 'Event',
					'gre--text text--darken-3': _vm.routeName !== 'Event',
				}},[_vm._v(" "+_vm._s(_vm.$t('plan.menu.event'))+" ")])])],1),_c('Dialog',{attrs:{"dialogControl":_vm.dialog,"numberOfButton":1,"title":_vm.$t('아직 준비 중입니다^^ 조금만 기다려주세요.'),"actionColor":'grey lighten-1',"action":_vm.action,"actionText":_vm.$t('modal.button.confirm')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }