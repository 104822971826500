import store from '../store'

const authReject = async (to, from, next) => {
	const memberId = store.getters['auth/memberId']
	memberId ? next('/') : next()
}

const authOnly = async (to, from, next) => {
	const memberId = store.getters['auth/memberId']
	if (!memberId) {
		const goTo = { name: to.name, query: to.query, params: to.params }
		const redir = JSON.stringify(goTo)
		let query = { redir }
		store.commit('device/SET_RDIR', redir)
		if (to.query.utm_source) query.utm_source = to.query.utm_source
		if (to.query.utm_medium) query.utm_medium = to.query.utm_medium
		if (to.query.utm_campaign) query.utm_campaign = to.query.utm_campaign
		next({ name: 'SignIn', query })
		return
	} else {
		next()
	}
}

export default {
	authReject,
	authOnly,
}
