<template>
	<v-img src="@/assets/images/trials/top-bg.svg">
		<div class="pt-15 text-center white--text text-nls">
			<h1
				class="mb-2 title"
				v-html="
					$t('trial-report.header.title', { name: memberName || trialName })
				"
			></h1>
			<p class="text mb-9">
				{{
					trialMoment
						.clone()
						.tz(trialTimezone)
						.locale(trialLanguage)
						.format('MMMM Do (ddd) H:mm')
				}}
				({{ trialTimezone }})
			</p>
			<v-card width="300" rounded="xl" flat class="mx-auto py-7 px-6">
				<div
					class="d-flex align-center mb-4"
					v-for="item in list"
					:key="item.text"
				>
					<v-img max-width="41" class="mr-4" :src="item.img"></v-img>
					<p class="text2">
						{{ item.text }}
					</p>
				</div>
			</v-card>
		</div>
	</v-img>
</template>

<script>
export default {
	props: {
		trialName: {
			required: true,
		},
		trialMoment: {
			required: true,
		},
		trialTimezone: {
			required: true,
		},
		trialLanguage: {
			required: true,
		},
	},
	data() {
		return {
			list: [
				{
					text: this.$t('trial-report.header.list-1'),
					img: require('@/assets/images/trials/top-icon-01.svg'),
				},
				{
					text: this.$t('trial-report.header.list-2'),
					img: require('@/assets/images/trials/top-icon-02.svg'),
				},
				{
					text: this.$t('trial-report.header.list-3'),
					img: require('@/assets/images/trials/top-icon-03.svg'),
				},
				{
					text: this.$t('trial-report.header.list-4'),
					img: require('@/assets/images/trials/top-icon-04.svg'),
				},
			],
		}
	},
	computed: {
		memberName() {
			return this.$store.getters['member/name']
		},
	},
}
</script>

<style lang="scss" scoped></style>
