<template>
	<div id="audio">
		<div style="height: 25px">
			<div class="progress-wrap">
				<div
					@click="seek"
					class="player-progress"
					title="Time played : Total time"
				>
					<div class="default-line"></div>
					<div
						:style="{ width: `calc(${this.percentComplete}% + 2px)` }"
						class="player-seeker"
					></div>
					<div
						:style="{ left: this.percentComplete + '%' }"
						class="player-ball"
					></div>
				</div>
			</div>
			<div class="player-time">
				<div>
					{{ this.currentSeconds | convertTimeHHMMSS }}
				</div>
				<div>
					{{ this.durationSeconds | convertTimeHHMMSS }}
				</div>
			</div>
		</div>
		<div
			class="font-24 d-flex align-center justify-center"
			style="color: #404a59"
		>
			<!-- <div
				v-on:click.prevent="stop"
				title="Stop"
				class="px-3 py-2 hover-pointer"
			>
				<v-icon>mdi-stop</v-icon>
			</div> -->

			<div class="hover-pointer px-3" @click="backwardAudio">
				<i class="fa-solid fa-backward"></i>
			</div>
			<div
				class="hover-pointer px-3"
				@click.prevent="playing = !playing"
				title="Play/Pause"
			>
				<i v-if="!playing" class="fa-solid fa-circle-play"></i>
				<i v-else class="fa-solid fa-circle-pause"></i>
			</div>
			<div class="hover-pointer px-3" @click="forwardAudio">
				<i class="fa-solid fa-forward"></i>
			</div>
		</div>
		<audio
			:loop="innerLoop"
			ref="audiofile"
			:src="filePath"
			preload="auto"
			style="display: none"
		></audio>
	</div>
</template>

<script>
export default {
	props: {
		lessonDate: {
			require: true,
			type: String,
		},
		fileName: {
			require: true,
			type: String,
		},
		autoPlay: {
			type: Boolean,
			default: false,
		},
		loop: {
			type: Boolean,
			default: false,
		},
	},
	data: () => ({
		audio: undefined,
		currentSeconds: 0,
		durationSeconds: 0,
		innerLoop: false,
		loaded: false,
		playing: false,
		previousVolume: 35,
		showVolume: false,
		volume: 100,
	}),
	computed: {
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		filePath() {
			const regExp = /[{}[\]/?.,;:|)*~`!^\-+<>@#$%&\\=('"]/gi
			const filename = this.fileName.replace(regExp, '')
			return `https://tella-call.s3.ap-northeast-2.amazonaws.com/${this.lessonDate}/${this.memberId}/${filename}.mp3`
		},
		percentComplete() {
			return parseInt((this.currentSeconds / this.durationSeconds) * 100)
		},
		muted() {
			return this.volume / 100 === 0
		},
	},
	filters: {
		convertTimeHHMMSS(val) {
			let hhmmss = new Date(val * 1000).toISOString().substr(11, 8)

			return hhmmss.indexOf('00:') === 0 ? hhmmss.substr(3) : hhmmss
		},
	},
	watch: {
		playing(value) {
			if (value) {
				return this.audio.play()
			}
			this.audio.pause()
		},
		volume() {
			this.showVolume = false
			this.audio.volume = this.volume / 100
		},
	},
	created() {
		this.innerLoop = this.loop
	},
	mounted() {
		this.audio = this.$el.querySelectorAll('audio')[0]
		this.audio.addEventListener('timeupdate', this.update)
		this.audio.addEventListener('loadeddata', this.load)
		this.audio.addEventListener('pause', () => {
			this.playing = false
		})
		this.audio.addEventListener('play', () => {
			this.playing = true
		})
	},
	methods: {
		get() {},
		download() {
			this.stop()
			window.open(this.filePath, 'download')
		},
		load() {
			if (this.audio.readyState >= 2) {
				this.loaded = true
				this.durationSeconds = parseInt(this.audio.duration)
				// return this.playing = this.autoPlay
				this.playing = this.autoPlay
				return
			}

			throw new Error('Failed to load sound file.')
		},
		mute() {
			if (this.muted) {
				// return this.volume = this.previousVolume
				this.volume = this.previousVolume
			}

			this.previousVolume = this.volume
			this.volume = 0
		},
		seek(e) {
			if (!this.playing || e.target.tagName === 'SPAN') {
				return
			}

			const el = e.target.getBoundingClientRect()
			const seekPos = (e.clientX - el.left) / el.width

			this.audio.currentTime = parseInt(this.audio.duration * seekPos)
		},
		stop() {
			this.playing = false
			this.audio.currentTime = 0
		},
		update() {
			this.currentSeconds = parseInt(this.audio.currentTime)
		},
		forwardAudio() {
			this.audio.currentTime += 15
		},
		backwardAudio() {
			this.audio.currentTime -= 15
		},
	},
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

body {
	font-family: 'Nunito', sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

$player-bg: #00cca1;
$player-border-color: darken($player-bg, 12%);
$player-link-color: darken($player-bg, 75%);
$player-progress-color: $player-border-color;
$player-seeker-color: $player-link-color;
$player-text-color: $player-link-color;

// .player-wrapper {
//   align-items: center;
//   background-color: $player-bg;
//   background-image: linear-gradient(90deg, #fff 0, darken(#fff, 12%));
//   display: flex;
//   height: 100vh;
//   justify-content: center;
// }

// .player {
//   background-color: $player-bg;
//   border-radius: 5px;
//   // border: 1px solid $player-border-color;
//   // box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
//   color: $player-text-color;
//   display: inline-block;
//   line-height: 1.5625;
// }

// .player-controls {
//   display: flex;

//   > div {
//     border-right: 1px solid $player-border-color;

//     &:last-child {
//       border-right: none;
//     }

//     a {
//       color: $player-link-color;
//       display: block;
//       line-height: 0;
//       padding: 1em;
//       text-decoration: none;
//     }
//   }
// }

.player-progress {
	position: relative;
	cursor: pointer;
	height: 10px;
	min-width: 200px;
	.default-line {
		background-color: #f0f0f0;
		height: 2px;
	}
	.player-seeker {
		background-color: #404a59;
		height: 4px;
		bottom: 0;
		left: 0;
		position: absolute;
		top: -2px;
		transition: all 0.2s ease;
	}
	.player-ball {
		background-color: #404a59;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		bottom: 0;
		position: absolute;
		top: -4.5px;
		transition: all 0.2s ease;
	}
}

.player-time {
	font-size: 14px;
	color: #404a59;
	display: flex;
	justify-content: space-between;
}
// .player-progress,
// .player-seeker {
//   margin-top: 3px;
//   height: 4px;
//   transition: all 0.3s ease-in-out;
// }

// .progress-wrap:hover .player-progress,
// .progress-wrap:hover .player-seeker {
//   margin-top: 0px;
//   height: 10px;
// }
</style>
