<template>
	<div
		flat
		class="plan-card mb-3"
		:class="{
			'package-card': type === 'package',
			'talk-card': type === 'talk',
			'call-card': type === 'call',
			active: biggestBenefit === true || recommended === true,
		}"
	>
		<div
			:class="`${type}-recommend`"
			style="
				padding-top: 6px;
				padding-bottom: 5px;
				font-size: 14px;
				position: absolute;
				right: 0;
				top: 0;
				border-bottom-left-radius: 10px;
				min-width: 90px;
			"
			class="white--text text-center font-weight-bold"
			v-if="biggestBenefit === true || recommended === true"
		>
			{{
				biggestBenefit === true
					? $t('plan.plan-card.tag.maximum-benefit')
					: $t('plan.plan-card.tag.recommend')
			}}
		</div>

		<div class="pt-5 px-4 pb-4">
			<div class="plan-name font-weight-bold plan-title d-flex align-center">
				{{ title }}
			</div>
			<p class="mb-n3 text-body-1 grey--text text--darken-2">
				{{
					$t('order.info.text.month', { month: month === 12 ? '9+3' : month })
				}}
				<span v-if="month === 1">({{ $t('order.30days') }})</span>
			</p>
			<div class="text-right">
				<div class="mb-1 grey--text text--darken-3 plan-price">
					<span style="margin-right: 4px">{{
						$t('plan.plan-card.month')
					}}</span>
					{{ wonCommaFormat(Math.floor(realPricePerMonth)) }}
					<span style="margin-left: 2px" class="font-weight-bold">{{
						$t('order.currency.krw')
					}}</span>
				</div>

				<div
					class="
						real-price
						grey--text
						text--darken-1 text-decoration-line-through
					"
				>
					<div class="mb-1 px-1 text-body-2 d-inline-block mr-1 discount-chip">
						{{ Math.floor(discount) }}%
						{{ $t('plan.plan-card.discount') }}
					</div>

					{{
						$t('plan.plan-card.text.origin-price', {
							price: wonCommaFormat(originPrice),
						})
					}}
				</div>

				<div class="real-price grey--text text--darken-1">
					{{ $t('plan.bottom-action.title.pay-price') }}
					<span class="font-weight-bold grey--text text--darken-3"
						>{{ wonCommaFormat(realPrice) }}{{ $t('order.currency.krw') }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		planId: { required: true, type: Number },
		selected: { required: true, type: Boolean },
		type: { required: true, type: String },
		title: { required: true, type: String },
		biggestBenefit: { required: true, type: Boolean },
		recommended: { required: true, type: Boolean },
		monthString: { required: true, type: String },
		month: { required: true, type: Number },
		originPrice: { required: true, type: Number },
		realPrice: { required: true, type: Number },
		realPricePerMonth: { required: true, type: Number },
		discount: { required: true, type: Number },
	},
	methods: {
		wonCommaFormat: won => won.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
	},
}
</script>

<style lang="scss" scoped>
.plan-name {
	font-size: 20px;
}
.discount-chip {
	padding: 3px 5px;
	object-fit: contain;
	border-radius: 5px;
	background-color: rgb(252, 236, 235);
	color: #ff3a39;
	font-size: 15px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.21px;
}
.plan-price {
	font-size: 24px;
	font-weight: bold;
	letter-spacing: -0.75px;
	span {
		font-size: 18px;
	}
}
.real-price {
	font-size: 14px;
	letter-spacing: -0.34px;
}
.plan-card {
	width: 100%;
	position: relative;
	border-radius: 10px;
	border: solid 1px #dee0e5;
	overflow: hidden;
}
.plan-card.package-card.active {
	border: solid 2px #795eff;
}
.plan-card.package-card:hover {
	background-color: rgba(121, 94, 255, 0.08);
}

.plan-card.talk-card.active {
	border: solid 2px #2886ff;
}
.plan-card.talk-card:hover {
	background-color: rgba(40, 134, 255, 0.08);
}

.plan-card.call-card.active {
	border: solid 2px #00d3c2;
}
.plan-card.call-card:hover {
	background-color: rgba(0, 211, 194, 0.05);
}

.package-recommend {
	background-color: #795eff;
}
.talk-recommend {
	background-color: #2886ff;
}
.call-recommend {
	background-color: #00d3c2;
}
</style>

<style lang="scss">
.plan-card.package-card,
.plan-card.talk-card,
.plan-card.call-card {
	transition: 0.1s background-color ease-in-out;

	.v-btn.v-btn--block.v-btn--outlined.v-btn--plain.theme--light.v-size--large {
		transition: 0.1s ease-in-out;
		transition-property: color, background-color;
	}
}
.plan-card.package-card:hover {
	.v-btn.v-btn--block.v-btn--outlined.v-btn--plain.theme--light.v-size--large.package--text {
		color: #fff !important;
		background-color: #795eff !important;
	}
}
.plan-card.talk-card:hover {
	.v-btn.v-btn--block.v-btn--outlined.v-btn--plain.theme--light.v-size--large.talk--text {
		color: #fff !important;
		background-color: #2886ff !important;
	}
}
.plan-card.call-card:hover {
	.v-btn.v-btn--block.v-btn--outlined.v-btn--plain.theme--light.v-size--large.call--text {
		color: #fff !important;
		background-color: #00d3c2 !important;
	}
}
</style>
