<template>
	<div id="guide-wrap">
		<section class="pb-8">
			<div class="text-left">
				<p class="font-13 font-weight-bold mb-1">학습목표</p>
				<div class="font-13">
					{{ learningObjective }}
				</div>
			</div>
		</section>
		<div class="pb-5">
			<p class="font-13 font-weight-bold mb-2">표현카드</p>
			<v-img
				:src="`https://image.tella.co.kr/v1/express-card/${language}/${unit.id}.jpg`"
				alt
				min-height="305"
				style="border-radius: 10px !important; width: 100%; max-width: 350px"
			></v-img>
		</div>
		<div v-for="(script, i) in guide" :key="script.sequence" class="mb-6">
			<ImageContent
				:content="script.content"
				v-if="script.program_guide_component_id === 14"
			/>
			<Instruction
				:content="script.content"
				v-else-if="script.program_guide_component_id === 2"
			/>
			<Setting
				:content="script.content"
				v-else-if="script.program_guide_component_id === 11"
			/>
			<TutorLine
				:translate="translate"
				:content="script.content"
				:tutorTranslation="guide[i + 1] || null"
				v-else-if="script.program_guide_component_id === 6"
			/>
			<TutorQuestion
				:translate="translate"
				:content="script.content"
				:tutorTranslation="guide[i + 1] || null"
				v-else-if="script.program_guide_component_id === 12"
			/>
			<TargetExpression
				:content="script.content"
				v-else-if="script.program_guide_component_id === 3"
			/>
			<StudentLine
				:translate="translate"
				:content="script.content"
				:exampleAnswer="guide[i + 1] || null"
				v-else-if="script.program_guide_component_id === 4"
			/>
			<ReferenceAnswer
				:content="script.content"
				v-else-if="script.program_guide_component_id === 13"
			/>
		</div>
	</div>
</template>

<script>
/**
 * image 14
 * instruction 2
 * setting 11
 * tutor-line 17
 * tutor-question 12
 * tutor-translation 7
 * target-expression 3
 * student-line 4
 * example-answer 5
 * reference-answer 15
 * 14, 2, 11, 17, 12, 7, 3, 4, 5, 15
 */

// import Api from '@/services/index'
import ImageContent from './Image'
import Instruction from './Instruction'
import Setting from './Setting'
import TutorLine from './TutorLine'
import TutorQuestion from './TutorQuestion'
// import TutorTranslation from './TutorTranslation'
import TargetExpression from './TargetExpression'
import StudentLine from './StudentLine'
// import ExampleAnswer from './ExampleAnswer'
import ReferenceAnswer from './ReferenceAnswer'
// import TranslateButton from './TranslateButton'

export default {
	components: {
		ImageContent,
		Instruction,
		Setting,
		TutorLine,
		TutorQuestion,
		// TutorTranslation,
		TargetExpression,
		StudentLine,
		// ExampleAnswer,
		ReferenceAnswer,
		// TranslateButton,
	},
	props: {
		unit: {
			required: true,
		},
	},
	data: () => ({
		translate: false,
		notShowTransProgramIds: [33, 153, 120, 167],
		// guides: [],
	}),
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		guide() {
			const guides =
				this.unit && this.unit.guide
					? JSON.parse(this.unit.guide)
					: { ko: [], ja: [] }
			return guides[this.language].sort((a, b) => a.sequence - b.sequence)
		},

		learningObjective() {
			const learningObjectives =
				this.unit && this.unit.learningObjective
					? JSON.parse(this.unit.learningObjective)
					: { ko: [], ja: [] }
			return learningObjectives[this.language]
		},
	},
}
</script>

<style>
#guide-wrap p {
	font-size: 14px !important;
	line-height: 22px !important;
	margin-bottom: 0;
}

#guide-wrap .black-text {
	color: #1a1a1a !important;
}
#guide-wrap .grey-text {
	color: #808080 !important;
}
#guide-wrap .small-text,
#guide-wrap p.small-text {
	font-size: 12px !important;
	line-height: 18px !important;
}
</style>
