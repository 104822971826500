<template>
	<div>
		<section class="fixed-bar-wrap">
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">
						{{ $t('notice.title') }}
					</h1>
				</v-col>
			</v-row>
		</section>
		<section style="margin-top: 60px" v-if="language === 'ko'">
			<v-divider></v-divider>
			<ListItem
				class="cursor-pointer py-2 px-3"
				style="border-bottom: 1px solid #dadada"
				:list="list"
				v-for="list in notices"
				:key="list.id"
			/>

			<v-btn
				block
				tile
				depressed
				v-if="page < pageCount"
				@click="page++"
				:loading="isLoading"
				><v-icon>mdi-plus</v-icon> {{ $t('더 보기') }} ({{ page }}/{{
					pageCount
				}})</v-btn
			>
		</section>
		<iframe
			v-else
			style="
				margin-top: 60px;
				width: 100%;
				height: calc(var(--vh, 1vh) * 100 - 67px);
			"
			src="https://tellanoticejp.oopy.io"
			frameborder="0"
		></iframe>
	</div>
</template>

<script>
import Api from '@/services/index'
import ListItem from './ListItem'
const R = require('ramda')

export default {
	components: {
		ListItem,
	},
	data: () => ({
		isLoading: false,
		notices: [],
		page: 1,
		pageCount: 0,
	}),
	computed: {
		language() {
			return this.$store.getters['member/language']
		},
		id() {
			return Number(this.$route.params.id)
		},
		title() {
			return this.id === 12969 ? this.$t('친구 추천') : this.$t('공지사항')
		},
	},
	watch: {
		page() {
			this.getNotice()
		},
	},
	created() {
		this.getNotice()
	},
	methods: {
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			beforeName !== 'MyPage' || beforeName !== 'Notices'
				? this.$router.push({ name: 'MyPage' })
				: this.$router.go(-1)
		},
		async getNotice() {
			this.isLoading = true
			let query = `boards/page/${this.page}?board-type-id=1&sort-by=-id&limit=15`

			const res = await Api.get(query)
			this.notices = R.concat(this.notices, res.data)
			this.pageCount = parseInt(res.headers['x-page-count'])
			this.isLoading = false
		},
	},
}
</script>
