<template>
	<div v-if="isLoading === false">
		<!-- <PrintNotify /> -->
		<section>
			<v-row class="white py-2" no-gutters align="center">
				<v-col cols="2">
					<v-btn fab icon text small @click="goBack()">
						<v-icon>mdi-arrow-left</v-icon>
					</v-btn>
				</v-col>
				<v-col cols="8">
					<h1 class="text-center text-body-1">
						{{ lesson.dt.setLocale(language).toFormat('ff') }}
					</h1>
				</v-col>
				<v-col cols="2" class="text-right pr-3">
					<img
						@click="goToPrint()"
						class="hover-pointer"
						style="width: 22px"
						src="@/assets/icons/print.png"
						alt=""
					/>
				</v-col>
			</v-row>
		</section>
		<section>
			<div
				class="d-flex align-center pt-1 px-5"
				style="background-color: #fff; border-bottom: 1px solid #e2e6ed"
			>
				<div
					class="pa-1 tab-btn"
					:class="{ active: tab === 0 }"
					@click="tab = 0"
				>
					<h1 class="text-body-1">{{ $t('수업 로그') }}</h1>
				</div>
				<div
					v-if="lesson.program_id !== 110"
					class="pa-1 tab-btn ml-3"
					:class="{ active: tab === 3 }"
					@click="tab = 3"
				>
					<h1 class="text-body-1">{{ $t('표현') }}</h1>
				</div>

				<div
					v-if="lesson.lesson_type === 0"
					class="pa-1 tab-btn ml-3"
					:class="{ active: tab === 1 }"
					@click="tab = 1"
				>
					<h1 class="text-body-1">{{ $t('첨삭') }}</h1>
				</div>
				<div
					v-if="lesson.lesson_type === 1"
					class="pa-1 tab-btn ml-3"
					:class="{ active: tab === 4 }"
					@click="tab = 4"
				>
					<h1 class="text-body-1">피드백</h1>
				</div>
				<div
					v-if="lesson.lesson_type === 0"
					class="pa-1 tab-btn ml-3"
					:class="{ active: tab === 2 }"
					@click="tab = 2"
				>
					<h1 class="text-body-1">{{ $t('분석') }}</h1>
				</div>
			</div>
		</section>
		<section
			style="overflow-y: auto; height: calc(var(--vh, 1vh) * 100 - 105px)"
		>
			<div class="pa-5" v-show="tab === 0" style="background-color: #f2f4f6">
				<div
					style="position: fixed; bottom: 0; left: 0; width: 100%"
					v-if="lesson.lesson_type === 1"
				>
					<div
						style="max-width: 400px"
						class="call-audio-player-wrap white mx-auto"
					>
						<CallAudioPlayer
							:lessonDate="lesson.lesson_date"
							:fileName="lesson.call_mp3_file_name"
						/>
					</div>
				</div>
				<h1 class="font-20 font-weight-bold mb-7">{{ $t('수업 로그') }}</h1>
				<Logs :lessonDt="lesson.dt" :logs="logs" />
				<div v-if="lesson.lesson_type === 1" class="py-12"></div>
			</div>
			<div class="pa-5" v-show="tab === 3">
				<h1 class="font-20 font-weight-bold">{{ $t('표현') }}</h1>
				<p class="mb-7 font-14 font-weight-medium grey--text text--darken-1">
					{{ $t('수업에서 다룬 표현을 확인해보세요.') }}
				</p>
				<div class="mb-5" v-for="progress in progresses" :key="progress.id">
					<img
						style="width: 100%"
						:src="`https://image.tella.co.kr/v1/express-card/${language}/${progress.program_unit_id}.jpg`"
						alt=""
						class="mb-4"
					/>
				</div>
			</div>

			<div v-show="tab === 1">
				<div class="px-4 pt-5 pb-3 white d-flex align-center">
					<h1 class="font-20 font-weight-bold">{{ $t('첨삭 받은 문장') }}</h1>
					<div class="correction-count ml-2 white--text">
						{{ editings.length }}
					</div>
				</div>
				<Corrections
					:editings="editings"
					:pronounciations="pronounciations"
					:newExpressions="newExpressions"
					:callCorrections="callCorrections"
				/>
			</div>
			<div v-show="tab === 4">
				<div class="px-4 pt-5 pb-3 white d-flex align-center">
					<h1 class="font-20 font-weight-bold">피드백</h1>
					<div class="correction-count ml-2 white--text">
						{{
							pronounciations.length +
							newExpressions.length +
							callCorrections.length
						}}
					</div>
				</div>
				<Corrections
					v-if="lesson.lesson_type === 1"
					:editings="editings"
					:pronounciations="pronounciations"
					:newExpressions="newExpressions"
					:callCorrections="callCorrections"
				/>
			</div>
			<div v-show="tab === 2" class="pa-5">
				<h1 class="font-20 font-weight-bold">{{ $t('분석') }}</h1>
				<p class="mb-7 font-14 font-weight-medium grey--text text--darken-1">
					{{ $t('오늘 수업에서는 이만큼 이야기했어요.') }}
				</p>
				<Analyzed
					@goToCorrection="tab = 1"
					class="mb-12"
					:stat="stat"
					:progresses="progresses"
					:name="lesson.member_name"
				/>
			</div>
		</section>
		<RatingDialog :lesson="lesson" :issues="issues" :ratings="ratings" />
	</div>
</template>

<script>
import { DateTime } from 'luxon'
import Api from '@/services/index'
import { makeDatetime } from '@/helpers/datetime'
import Logs from './Logs'
import Corrections from './Corrections'
import RatingDialog from './RatingDialog'
import Analyzed from './Analyzed'
import CallAudioPlayer from './CallAudioPlayer'
// import PrintNotify from './PrintNotify'

const R = require('ramda')

export default {
	components: {
		Logs,
		CallAudioPlayer,
		Corrections,
		RatingDialog,
		Analyzed,
		// PrintNotify,
	},
	data: () => ({
		isLoading: false,
		year: DateTime.now().toFormat('yyyy'),
		lesson: null,
		tab: 0,
		stat: {},
		progresses: [],
		editings: [],
		logs: [],
		ratings: [],
		issues: [],
		dialog: false,
	}),
	computed: {
		id() {
			return this.$route.params.lessonId
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		language() {
			return this.$store.getters['member/language']
		},
		dateformat() {
			return this.$store.getters['member/dateformat']
		},
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		title() {
			return this.progresses.length > 0
				? `${this.progresses[0].program_name} | Unit ${this.progresses[0].unit}`
				: ''
		},
		pronounciations() {
			const filtered = R.pipe(
				R.filter(
					log => log.content.includes('ppp') && this.lesson.lesson_type === 1,
				),
				R.map(log => log.content),
			)(this.logs)

			return filtered.map(text => {
				let content = ''
				content = R.replace(/ppp {2,}: {2,}/, '', text)
				content = R.replace(/ppp:/, '', content)

				return content
			})
		},
		newExpressions() {
			const filtered = R.pipe(
				R.filter(
					log => log.content.includes('nnn') && this.lesson.lesson_type === 1,
				),
				R.map(log => log.content),
			)(this.logs)

			return filtered.map(text => {
				let content = ''
				content = R.replace(/nnn {2,}: {2,}/, '', text)
				content = R.replace(/nnn:/, '', content)

				return content
			})
		},
		callCorrections() {
			const filtered = R.pipe(
				R.filter(
					log => log.content.includes('>>>') && this.lesson.lesson_type === 1,
				),
				R.map(log => log.content),
			)(this.logs)

			return filtered.map(text => {
				let content = ''
				content = R.replace(/>>> {2,}: {2,}/, '', text)
				content = R.replace(/>>>:/, '', content)
				content = R.replace(/>>>/, '', content)

				return content
			})
		},
	},
	watch: {
		tab(val) {
			const eventTable = {
				0: 'click_review_menu_chatLog',
				1: 'click_review_menu_correction',
				2: 'click_review_menu_analysis',
				3: 'click_review_menu_expressCard',
				4: 'click_review_menu_feedback',
			}
			const eventName = eventTable[val]
			// eslint-disable-next-line no-undef
			amplitude.getInstance().logEvent(eventName, {
				lessonId: this.$route.params.lessonId,
			})
		},
	},
	created() {
		this.get()
	},
	methods: {
		async get() {
			try {
				this.isLoading = true
				const res = await Api.get(
					`lessons/${this.id}?member-id=${this.memberId}&status=analyzed`,
				)
				const { lesson, progresses, ratings, stats, logs, editings, issues } =
					res.data

				this.lesson = lesson
				this.lesson.dt = makeDatetime(
					this.lesson.lesson_date,
					this.lesson.lesson_time,
				)

				if (this.lesson.program_unit_id) {
					let unitQuery = `?active=1&sort_by=+unit&ids=${this.lesson.program_unit_id}&language=${this.language}`
					const unitResult = await Api.get(`programs/units${unitQuery}`)
					const unit = unitResult.data[0]
					this.lesson.program_name = unit.program_name
					this.lesson.unit = unit.unit
					const unitTitle = JSON.parse(unit.unit_title)
					this.lesson.unit_title = unitTitle[this.language]
				}
				/**
				 * 상태가 anlyzed가 아닌 경우 status로 필터링
				 * 로그가 안올라온경우
				 * 에디팅이 없는 경우 - 있을 수 있음
				 */
				this.logs = logs
				this.editings = editings
				this.stat = stats.length > 0 ? stats[0] : null
				this.progresses = progresses
				this.ratings = ratings
				this.issues = issues
				if (this.logs.length === 0) {
					throw new Error('no logs')
				}
				this.isLoading = false
				if (
					DateTime.now() < this.lesson.dt.plus({ hours: 24 }) &&
					this.lesson.dt < DateTime.now() &&
					this.ratings.length === 0
				)
					this.dialog = true
				// eslint-disable-next-line no-undef
				amplitude.getInstance().logEvent('view_review', {
					lessonId: this.$route.params.lessonId,
					lessonType: this.lesson.lesson_type === 0 ? 'talk' : 'call',
				})

				this.logs.forEach(l => {
					const editings = this.editings.filter(
						e => e.tutee_chat_log_id === l.id,
					)
					l.editings = editings
				})
			} catch (err) {
				const errorCode = err.message ? err.message : err.response.data.code
				let msg = this.$t(
					'알수 없는 에러가 발생했습니다.\n고객 센터로 문의주세요.',
				)
				if (errorCode === 'no logs')
					msg = this.$t('수업이 아직 업로드 되지 않았습니다.')
				if (errorCode === '40300') msg = this.$t('수업을 찾을 수 없습니다.')
				alert(msg)
				this.goBack()
			}
		},
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			if (beforeName) {
				this.$router.go(-1)
				return
			}

			this.$router.push({ name: 'ReviewLessonsAll' })
		},
		goToPrint() {
			// eslint-disable-next-line no-undef
			amplitude.getInstance().logEvent('click_review_pdf', {
				lessonId: this.id,
				lessonType: this.lesson.lessonType === 0 ? 'talk' : 'call',
			})
			this.$router.push({ name: 'LessonPrint', params: { lessonId: this.id } })
		},
		rate() {
			alert(
				'#todo: 화면 설계서 나오면 만들어야함. rating 페이지로 날렸으면. rating 완료시 보여지는 것. 그리고 수정 권한?',
			)
		},
	},
}
</script>

<style lang="scss" scoped>
.tab-btn {
	font-weight: normal;
	border-bottom: 3px solid #fff;
	h1 {
		color: #1a1a1a;
		font-weight: 800;
	}
}
.tab-btn:hover {
	cursor: pointer;
}
.tab-btn.active {
	color: #0074ff !important;
	font-weight: bold;
	border-color: #0074ff;
	h1 {
		color: #0074ff !important;
	}
}

.correction-count {
	font-weight: 700;
	font-size: 18px;
	background: #444444;
	border-radius: 20px;
	line-height: 15px;
	padding: 4px 5px;
}

.call-audio-player-wrap {
	max-width: 400px;
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
	padding: 30px 20px;
}
</style>
