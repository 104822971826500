<template>
	<div>
		<v-dialog v-model="dialog" width="350" @click:outside="goBack">
			<v-card class="rounded-lg px-3 py-4">
				<h1 class="mb-5 font-19 font-weight-bold">
					{{ $t('복습 노트에 담을 내용을 선택하세요.') }}
				</h1>
				<p class="grey--text text--darken-1 mb-3">{{ $t('포함 정보') }}</p>

				<div
					class="mb-2 d-flex align-center font-14"
					@click="showLogs = !showLogs"
				>
					<img
						v-if="showLogs === false"
						style="width: 20px; margin-right: 7px"
						src="@/assets/images/icon/check-grey-circle.png"
						alt=""
					/>
					<img
						v-if="showLogs === true"
						style="width: 20px; margin-right: 7px"
						src="@/assets/images/icon/check-active.png"
						alt=""
					/>
					{{ $t('수업 로그') }}
				</div>
				<div
					v-if="lesson && lesson.lesson_type === 0"
					class="mb-2 d-flex align-center font-14"
					@click="showCorrection = !showCorrection"
				>
					<img
						v-if="showCorrection === false"
						style="width: 20px; margin-right: 7px"
						src="@/assets/images/icon/check-grey-circle.png"
						alt=""
					/>
					<img
						v-if="showCorrection === true"
						style="width: 20px; margin-right: 7px"
						src="@/assets/images/icon/check-active.png"
						alt=""
					/>
					{{ $t('첨삭 문장') }}
				</div>
				<div
					v-if="lesson && lesson.lesson_type === 0"
					class="mb-2 d-flex align-center font-14"
					@click="showSelfTest = !showSelfTest"
				>
					<img
						v-if="showSelfTest === false"
						style="width: 20px; margin-right: 7px"
						src="@/assets/images/icon/check-grey-circle.png"
						alt=""
					/>
					<img
						v-if="showSelfTest === true"
						style="width: 20px; margin-right: 7px"
						src="@/assets/images/icon/check-active.png"
						alt=""
					/>
					{{ $t('셀프 테스트') }}
				</div>
				<div
					v-if="lesson && lesson.lesson_type === 1"
					class="mb-2 d-flex align-center font-14"
					@click="showTutorFeedback = !showTutorFeedback"
				>
					<img
						v-if="showTutorFeedback === false"
						style="width: 20px; margin-right: 7px"
						src="@/assets/images/icon/check-grey-circle.png"
						alt=""
					/>
					<img
						v-if="showTutorFeedback === true"
						style="width: 20px; margin-right: 7px"
						src="@/assets/images/icon/check-active.png"
						alt=""
					/>
					튜터 피드백
				</div>

				<v-row no-gutters class="mt-4">
					<v-col cols="6" class="pr-1">
						<v-btn
							block
							depressed
							large
							color="grey lighten-3"
							@click="goBack"
							>{{ $t('page.reviewList.button.취소') }}</v-btn
						>
					</v-col>
					<v-col cols="6" class="pl-1">
						<v-btn block depressed large color="primary" @click="preLoad">{{
							$t('미리보기')
						}}</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
		<div
			v-if="isLoading === false && isPrinting === true"
			class="print-lesson-wrap"
		>
			<Bar class="no-print" />
			<div
				class="no-print"
				style="position: fixed; bottom: 0; left: 0; width: 100%"
			>
				<div class="white px-4 pb-5" style="max-width: 400px; margin: 0 auto">
					<v-btn
						@click="print"
						class="mb-3"
						height="50"
						color="primary"
						depressed
						block
					>
						<span class="font-weight-bold font-16">{{
							$t('다운로드 / 인쇄')
						}}</span>
					</v-btn>
					<a :href="guideLink" target="_blank" style="text-decoration: unset">
						<p class="text-center print-info">
							<i class="mr-1 fa-regular fa-circle-question"></i>
							{{ $t('다운로드 / 인쇄 가이드 보기') }}
						</p>
					</a>
				</div>
			</div>
			<div class="lesson-info px-5 py-2 mb-5">
				<v-row no-gutters>
					<v-col cols="6">
						<div class="d-flex align-start">
							<div style="width: 60px" class="text-right font-weight-bold">
								DATE
							</div>
							<div style="width: calc(100% - 75px)" class="pl-3">
								{{
									lesson.dt
										.setLocale(language)
										.toFormat('yyyy. MM. dd. ccc H:mm')
								}}
								({{ timezone }})
							</div>
						</div>
					</v-col>
					<v-col cols="6">
						<div class="d-flex align-start">
							<div style="width: 60px" class="text-right font-weight-bold">
								TUTOR
							</div>
							<div style="width: calc(100% - 75px)" class="pl-3">
								{{ lesson.tutor_name_en }}
							</div>
						</div>
					</v-col>
					<v-col cols="12"> <v-divider class="my-2"></v-divider> </v-col>
					<v-col cols="12">
						<div class="d-flex align-start">
							<div style="width: 60px" class="text-right font-weight-bold">
								PROGRAM
							</div>
							<div style="width: calc(100% - 75px)" class="pl-3">
								{{ lesson.program_name }}<br />Unit {{ lesson.unit }}.
								{{ lesson.unit_title }}
							</div>
						</div>
					</v-col>
				</v-row>
			</div>

			<div class="mb-5 page-wrap" v-if="showLogs === true">
				<div class="d-flex align-center mb-3">
					<h1 class="font-14 font-weight-bold">{{ $t('수업로그') }}</h1>
					<v-divider vertical class="ml-5 mr-3"></v-divider>
					<div
						style="background: #f2f3f6; border-radius: 6px; width: 18px"
						class="font-weight-bold mr-1 text-center"
					>
						T
					</div>
					<div class="mr-3">Tutor</div>
					<div
						style="border: 1px solid #f2f3f6; border-radius: 6px; width: 18px"
						class="font-weight-bold mr-1 text-center"
					>
						M
					</div>
					<div>Me</div>
				</div>
				<div v-for="(log, i) in logs" :key="log.id" class="mb-2">
					<div class="d-flex align-start pl-2">
						<div class="d-flex align-center" style="width: 78px">
							<div
								v-if="
									log.type.toLowerCase() === 'tutor' &&
									(!logs[i - 1] || logs[i - 1].type.toLowerCase() !== 'tutor')
								"
								class="d-flex align-start"
							>
								<div
									style="background: #f2f3f6; border-radius: 6px; width: 18px"
									class="font-weight-bold mr-1 text-center type"
								>
									T
								</div>
								<div style="width: 60px" class="font-weight-bold text-truncate">
									{{ lesson.tutor_name_en }}
								</div>
							</div>
							<div
								v-else-if="
									log.type.toLowerCase() === 'tutee' &&
									(!logs[i - 1] || logs[i - 1].type.toLowerCase() !== 'tutee')
								"
								class="d-flex align-start"
							>
								<div
									style="
										border: 1px solid #f2f3f6;
										border-radius: 6px;
										width: 18px;
									"
									class="font-weight-bold mr-1 text-center type"
								>
									M
								</div>
								<div style="width: 60px" class="font-weight-bold text-truncate">
									{{
										lesson.member_name_en
											? lesson.member_name_en
											: lesson.member_name
									}}
								</div>
							</div>
						</div>
						<div
							class="pl-1"
							style="width: calc(100% - 78px)"
							v-html="log.content"
						></div>
					</div>
					<div
						v-if="log.editings.length > 0"
						style="margin-left: -1px; margin-right: -1px"
						class="editing-wrap mt-2 d-flex align-start"
					>
						<div
							style="border: 1px solid #f2f3f6; border-radius: 6px; width: 18px"
							class="font-weight-bold mr-1 text-center"
						>
							M
						</div>
						<div class="font-weight-bold text-truncate" style="width: 60px">
							{{
								lesson.member_name_en
									? lesson.member_name_en
									: lesson.member_name
							}}
						</div>
						<div class="pl-1" style="width: 100%">
							<CorrectionsInLog :editings="log.editings" />
						</div>
					</div>
				</div>
			</div>
			<div
				class="mb-5 page-wrap"
				v-if="showCorrection === true && lesson.lesson_type === 0"
			>
				<div class="d-flex align-center mb-1">
					<h1 class="font-14 font-weight-bold mr-2">
						{{ $t('첨삭 받은 문장') }}
					</h1>
					<div class="correction-count">{{ editings.length }}</div>
				</div>
				<p class="mb-3">
					{{ $t('첨삭 받은 문장을 모두 모아서 한 눈에 확인해보세요.') }}
				</p>

				<div
					class="
						correction-header
						d-flex
						align-center
						py-2
						text-center
						font-weight-bold
					"
				>
					<div style="width: 35px">No.</div>
					<div style="width: calc(100% - 150px)">
						{{ $t('내 문장 → 튜터 첨삭') }}
					</div>
					<div style="width: 115px">MEMO</div>
				</div>
				<div
					class="correction-body d-flex align-center"
					v-for="(editing, i) in editings"
					:key="`editing-${editing.id}`"
				>
					<div class="no-wrap text-center font-weight-bold">{{ i + 1 }}</div>
					<div class="content-wrap pa-2">
						<Correction
							:beforeContent="editing.tutee_chat_log_content"
							:afterContent="editing.tutor_chat_log_content"
						/>
					</div>
					<div class="memo-wrap"></div>
				</div>
			</div>
			<div
				class="mb-5 page-wrap"
				v-if="showSelfTest === true && lesson.lesson_type === 0"
			>
				<h1 class="font-14 font-weight-bold mb-1">{{ $t('셀프 테스트') }}</h1>
				<p class="mb-3">
					{{ $t('빈칸을 채우며 내가 학습한 내용을 기억하는지 확인해보세요.') }}
				</p>

				<div
					class="
						correction-header
						d-flex
						align-center
						py-2
						text-center
						font-weight-bold
					"
				>
					<div style="width: 35px">No.</div>
					<div style="width: calc(100% - 150px)">
						{{ $t('내 문장 → 튜터 첨삭') }}
					</div>
					<div style="width: 115px">MEMO</div>
				</div>
				<div
					class="correction-body d-flex align-center"
					v-for="(editing, i) in editings"
					:key="`editing-${editing.id}`"
				>
					<div class="no-wrap text-center font-weight-bold">{{ i + 1 }}</div>
					<div class="content-wrap pa-2">
						<SelfTest
							:beforeContent="editing.tutee_chat_log_content"
							:afterContent="editing.tutor_chat_log_content"
						/>
					</div>
					<div class="memo-wrap"></div>
				</div>
			</div>
			<div
				class="mb-5 page-wrap"
				v-if="showTutorFeedback === true && lesson.lesson_type === 1"
			>
				<div class="d-flex align-center mb-1">
					<h1 class="font-14 font-weight-bold mr-2">튜터 피드백</h1>
					<div class="correction-count">{{ tutorFeedbackCount }}</div>
				</div>
				<p class="mb-3">튜터에게 받은 피드백을 한 눈에 확인해보세요.</p>
				<div v-if="pronounciations.length > 0" class="mb-4">
					<h2 class="font-12 font-weight-bold mr-2 mb-2">발음</h2>
					<div
						class="
							correction-header
							d-flex
							align-center
							py-2
							text-center
							font-weight-bold
						"
					>
						<div style="width: 35px">No.</div>
						<div style="width: calc(100% - 150px)"></div>
						<div style="width: 115px">MEMO</div>
					</div>
					<div
						class="correction-body d-flex align-center"
						v-for="(p, i) in pronounciations"
						:key="`pronounciations-${i}`"
					>
						<div class="no-wrap text-center font-weight-bold">{{ i + 1 }}</div>
						<div class="content-wrap pa-2">
							{{ p }}
						</div>
						<div class="memo-wrap"></div>
					</div>
				</div>
				<div v-if="newExpressions.length > 0" class="mb-4">
					<h2 class="font-12 font-weight-bold mr-2 mb-2">추천 단어</h2>
					<div
						class="
							correction-header
							d-flex
							align-center
							py-2
							text-center
							font-weight-bold
						"
					>
						<div style="width: 35px">No.</div>
						<div style="width: calc(100% - 150px)"></div>
						<div style="width: 115px">MEMO</div>
					</div>
					<div
						class="correction-body d-flex align-center"
						v-for="(n, i) in newExpressions"
						:key="`pronounciations-${i}`"
					>
						<div class="no-wrap text-center font-weight-bold">{{ i + 1 }}</div>
						<div class="content-wrap pa-2">
							{{ n }}
						</div>
						<div class="memo-wrap"></div>
					</div>
				</div>
				<div v-if="callCorrections.length > 0">
					<h2 class="font-12 font-weight-bold mr-2 mb-2">첨삭</h2>
					<div
						class="
							correction-header
							d-flex
							align-center
							py-2
							text-center
							font-weight-bold
						"
					>
						<div style="width: 35px">No.</div>
						<div style="width: calc(100% - 150px)"></div>
						<div style="width: 115px">MEMO</div>
					</div>
					<div
						class="correction-body d-flex align-center"
						v-for="(c, i) in callCorrections"
						:key="`pronounciations-${i}`"
					>
						<div class="no-wrap text-center font-weight-bold">{{ i + 1 }}</div>
						<div class="content-wrap pa-2">
							{{ c }}
						</div>
						<div class="memo-wrap"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Api from '@/services/index'
import { makeDatetime } from '@/helpers/datetime'
import CorrectionsInLog from './CorrectionsInLog'
import Correction from './Correction'
import SelfTest from './SelfTest'
import Bar from './Bar'

export default {
	components: {
		CorrectionsInLog,
		Correction,
		SelfTest,
		Bar,
	},
	data: () => ({
		dialog: false,
		showLogs: true,
		showCorrection: true,
		showSelfTest: true,
		showTutorFeedback: true,
		lesson: null,
		logs: [],
		isLoading: true,
		isPrinting: false,
	}),
	computed: {
		id() {
			return this.$route.params.lessonId
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		timezone() {
			return this.$store.getters['member/timezone']
		},
		language() {
			return this.$store.getters['member/language']
		},
		guideLink() {
			return this.language === 'ja'
				? 'https://tella.notion.site/c193b48ce9864004b387a5b38ac34e13'
				: 'https://tella.notion.site/4d1f38f4b5fd44a1b04e50e093e95eb0'
		},
		pronounciations() {
			return this.logs
				.filter(l => l.content.includes('ppp') && this.lesson.lesson_type === 1)
				.map(l => {
					let content = ''
					content = l.content.replace(/ppp {2,}: {2,}/, '')
					content = content.replace(/ppp:/, '')
					return content
				})
		},
		newExpressions() {
			return this.logs
				.filter(l => l.content.includes('nnn') && this.lesson.lesson_type === 1)
				.map(l => {
					let content = ''
					content = l.content.replace(/nnn {2,}: {2,}/, '')
					content = content.replace(/nnn:/, '')
					return content
				})
		},
		callCorrections() {
			return this.logs
				.filter(l => l.content.includes('>>>') && this.lesson.lesson_type === 1)
				.map(l => {
					let content = ''
					content = l.content.replace(/>>> {2,}: {2,}/, '')
					content = content.replace(/>>>:/, '')
					content = content.replace(/>>>/, '')
					return content
				})
		},
		tutorFeedbackCount() {
			const p = this.pronounciations.length > 0 ? 1 : 0
			const n = this.newExpressions.length > 0 ? 1 : 0
			const c = this.callCorrections.length > 0 ? 1 : 0

			return p + n + c
		},
	},
	created() {
		this.get()
		const e = document.querySelector('#tella-web .v-application--wrap')
		e.classList.add('for-print-100height')
		const e2 = document.querySelector('#tella-web')
		e2.classList.add('for-print-touch-action')
		e2.classList.add('for-print-100height')
	},
	destroyed() {
		this.get()
		const e = document.querySelector('#tella-web .v-application--wrap')
		e.classList.remove('for-print-100height')
		const e2 = document.querySelector('#tella-web')
		e2.classList.remove('for-print-touch-action')
		e2.classList.remove('for-print-100height')
	},
	methods: {
		async get() {
			try {
				this.isLoading = true
				const res = await Api.get(
					`lessons/${this.id}?member-id=${this.memberId}&status=analyzed`,
				)
				const { lesson, progresses, ratings, stats, logs, editings, issues } =
					res.data

				this.lesson = lesson
				this.lesson.dt = makeDatetime(
					this.lesson.lesson_date,
					this.lesson.lesson_time,
				)

				document.title = this.lesson.dt
					.setLocale(this.language)
					.toFormat('ff')
					.substr(5)

				if (this.lesson.program_unit_id) {
					let unitQuery = `?active=1&sort_by=+unit&ids=${this.lesson.program_unit_id}&language=${this.language}`
					const unitResult = await Api.get(`programs/units${unitQuery}`)
					const unit = unitResult.data[0]
					this.lesson.program_name = unit.program_name
					this.lesson.unit = unit.unit
					const unitTitle = JSON.parse(unit.unit_title)
					this.lesson.unit_title = unitTitle[this.language]
				}
				/**
				 * 상태가 anlyzed가 아닌 경우 status로 필터링
				 * 로그가 안올라온경우
				 * 에디팅이 없는 경우 - 있을 수 있음
				 */
				this.logs = logs
				this.editings = editings
				this.stat = stats.length > 0 ? stats[0] : null
				this.progresses = progresses
				this.ratings = ratings
				this.issues = issues

				// const afterChatLogs = this.editings.map(e => e.tutor_chat_log_id)
				// this.logs = logs.filter(l => afterChatLogs.includes(l.id) === false)
				if (this.logs.length === 0) {
					throw new Error('no logs')
				}
				this.logs.forEach(l => {
					const editings = this.editings.filter(
						e => e.tutee_chat_log_id === l.id,
					)
					l.editings = editings
				})

				this.isLoading = false
				this.dialog = true
				// eslint-disable-next-line no-undef
				amplitude.getInstance().logEvent('view_review_pdfPreview', {
					lessonId: this.id,
					lessonType: this.lesson.lessonType === 0 ? 'talk' : 'call',
				})
				// this.print()
			} catch (err) {
				console.log(err)
				const errorCode = err.message ? err.message : err.response.data.code
				let msg = this.$t(
					'알수 없는 에러가 발생했습니다.\n고객 센터로 문의주세요.',
				)
				if (errorCode === 'no logs')
					msg = this.$t('수업이 아직 업로드 되지 않았습니다.')
				if (errorCode === '40300') msg = this.$t('수업을 찾을 수 없습니다.')
				alert(msg)
				// this.goBack()
			}
		},
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name

			if (beforeName !== this.$route.name) this.$router.go(-1)
			else {
				this.$router.push({
					name: 'LessonReview',
					params: { lessonId: this.id },
				})
			}
		},
		async preLoad() {
			this.dialog = false
			this.isPrinting = true
		},
		print() {
			const params =
				this.lesson.lesson_type === 0
					? {
							lessonId: this.id,
							lessonType: this.lesson.lessonType === 0 ? 'talk' : 'call',
							chatLog: this.showLogs,
							correction: this.showCorrection,
							selfTestCorrection: this.showSelfTest,
					  }
					: {
							lessonId: this.id,
							lessonType: this.lesson.lessonType === 0 ? 'talk' : 'call',
							chatLog: this.showLogs,
							tutorFeedback: this.showTutorFeedback,
					  }
			// eslint-disable-next-line no-undef
			amplitude.getInstance().logEvent('click_review_pdfDownload', params)
			window.print()
		},
	},
}
</script>

<style>
#tella-web.for-print-100height {
	height: 100% !important;
}
#tella-web.for-print-touch-action {
	touch-action: unset !important;
}
</style>
<style lang="scss" scoped>
.print-lesson-wrap {
	max-width: 400px;
	margin: 0 auto;
	font-size: 12px;
	padding: 80px 20px 130px 20px;
	color: #404a59;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
}
.page-wrap {
	page-break-after: always;
}
.page-wrap:last-child {
	page-break-after: avoid;
}
.lesson-info {
	background: #f2f3f6;
	border-radius: 10px 9px;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
}
.type {
	page-break-inside: avoid !important;
}

.editing-wrap {
	page-break-inside: avoid !important;
	border: 1px dashed #404a59;
	border-radius: 10px;
	padding: 10px;
}

.correction-count {
	background: #404a59;
	border-radius: 10px;
	padding: 1px 10px;
	color: #ffffff;
	-webkit-print-color-adjust: exact;
	print-color-adjust: exact;
	font-weight: 700;
}

.correction-header {
	border-top: 2px solid #e2e6ed;
	border-bottom: 2px solid #e2e6ed;
}

.correction-body {
	page-break-inside: avoid !important;
	border-bottom: 1px solid #e2e6ed;
	.no-wrap {
		width: 35px;
	}
	.content-wrap {
		width: calc(100% - 150px);
		border-right: 1px solid #e2e6ed;
		border-left: 1px solid #e2e6ed;
	}
	.memo-wrap {
		width: 115px;
	}
}
.print-info {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height, or 170% */

	letter-spacing: -0.534375px;

	color: #485363;
}
@media print {
	.no-print,
	.no-print * {
		display: none !important;
	}
	::-webkit-scrollbar {
		width: 0px;
		background: transparent;
	}

	.print-lesson-wrap {
		max-width: unset !important;
		padding: 10px;
		box-shadow: unset;
	}
	/* make scrollbar transparent */
}
</style>
