<template>
	<div
		v-if="program && isLoading === false"
		class="pb-16"
		style="width: 100%; max-width: 400px"
	>
		<v-img
			data-test="program-thumbnail"
			height="240"
			class="program-card"
			:src="`https://image.tella.co.kr/v1/program/thumbnail/landscape/${language}/${programId}.jpg`"
		>
			<v-btn small @click="goBack()" fab icon class="ml-1 mt-1">
				<v-icon class="white--text">mdi-arrow-left</v-icon>
			</v-btn>
		</v-img>
		<section
			class="px-4 pt-6 mt-n4 white"
			style="
				z-index: 3;
				position: relative;
				border-top-right-radius: 14px;
				border-top-left-radius: 14px;
			"
		>
			<h2
				data-test="description"
				class="mb-1 font-14 grey--text text--darken-1 font-weight-regular"
			>
				{{ program.description }}
			</h2>
			<h1 data-test="name" class="mb-2 font-20 font-weight-bold">
				{{ program.name }}
			</h1>
			<div class="d-flex align-center mb-4">
				<p data-test="level" class="chip font-13 mr-1">Lv {{ level }}</p>
				<p data-test="topic" class="chip font-13 mr-1">{{ program.topic }}</p>
				<p data-test="structure" class="chip font-13 mr-1">
					{{ program.structure }}
				</p>
			</div>
		</section>
		<ProgressBar
			v-if="completedProgresses.length > 0"
			data-test="progress-bar"
			:completedProgresses="completedProgresses"
			:totalUnitLength="units.length"
		/>

		<ProgramInfo
			data-test="program-info"
			:listOfUnits="program.listOfUnits"
			:lessonType="lessonType"
			:recommended="program.recommended"
			:showControllBtn="completedProgresses.length > 0"
		/>
		<v-divider
			style="border-width: 3px"
			class="grey lighten-2 mb-6"
		></v-divider>
		<UnitList
			:units="units"
			:selectedUnitId="selectedUnitId"
			@clickUnit="selectUnit"
			@clickGuide="selectGuide"
		/>
		<GuideDialog
			:originDialog="guideDialog"
			:unitId="guideUnitId"
			@goNext="goNext"
		/>
		<div
			class="white px-4 py-2"
			style="width: 100%; max-width: 400px; position: fixed; bottom: 0"
		>
			<v-btn
				:disabled="!selectedUnit"
				@click="goNext(selectedUnitId)"
				block
				depressed
				height="50"
				color="primary"
				><span class="font-16 font-weight-medium">다음</span></v-btn
			>
		</div>
	</div>
</template>

<script>
import studyApi from '@/services/study'
import ProgramInfo from './ProgramInfo'
import ProgressBar from './ProgressBar'
import UnitList from './UnitList'
import GuideDialog from './GuideDialog'

export default {
	components: {
		ProgramInfo,
		ProgressBar,
		UnitList,
		GuideDialog,
	},
	props: {
		nextLessons: {
			required: true,
			type: Array,
		},
		lessonType: {
			required: true,
			type: Number,
		},
	},
	data() {
		return {
			isLoading: false,
			program: null,
			progresses: [],
			units: [],
			selectedUnit: null,
			guideDialog: false,
			guideUnitId: null,
		}
	},
	computed: {
		userDevice() {
			return this.$store.getters['device/userDevice']
		},
		programId() {
			return parseInt(this.$route.params.programId)
		},
		language() {
			return this.$store.getters['member/language']
		},
		lessonTypeWord() {
			return this.lessonType === 1 ? 'call' : 'talk'
		},
		level() {
			let level
			if (this.program && this.program.programLevel) {
				const levelList = [
					...new Set(this.program.programLevel.split('|')),
				].sort()

				level =
					levelList.length > 1
						? `${levelList[0]}-${levelList[levelList.length - 1]}`
						: levelList[0]
			}
			return level
		},
		selectedUnitId() {
			return this.selectedUnit?.id
		},
		completedProgresses() {
			return this.progresses.filter(
				p => p.status === 0 && p.programId === this.programId,
			)
		},
	},
	created() {
		if (Number.isInteger(this.programId) === false || this.programId < 0) {
			this.$router.push({
				name: 'BookProgram',
				query: { 'lesson-type': this.lessonTypeWord },
			})
		} else {
			this.get()
		}
	},
	methods: {
		async get() {
			try {
				this.isLoading = true
				const result = await Promise.all([
					studyApi.getProgramDetails(this.programId),
					studyApi.getProgresses({ programId: this.programId }),
				])
				this.program = result[0]
				this.progresses = result[1]
				this.units = this.program.listOfUnits ?? []
				this.mappingUnits()

				this.isLoading = false
			} catch (err) {
				console.log('err in view')
			}
		},
		mappingUnits() {
			this.units.forEach(u => {
				const progressIndex = this.progresses.findIndex(
					p => p.programUnitId === u.id,
				)
				u.progress =
					progressIndex >= 0 ? this.progresses[progressIndex].status : -1
				const lessonsIndex = this.nextLessons.findIndex(
					p => p.programUnitId === u.id,
				)
				u.nextLesson = lessonsIndex
			})

			// console.log(this.progresses)
		},
		selectUnit(unit) {
			this.selectedUnit = unit
		},
		selectGuide(unitId) {
			this.guideDialog = !this.guideDialog
			this.guideUnitId = unitId
		},
		goBack() {
			const beforeName = this.$store.getters['device/fromRouter'].name
			if (!beforeName) {
				this.$router.push({
					name: 'BookProgram',
					query: { 'lesson-type': this.lessonTypeWord },
				})
				return
			}
			this.$router.go(-1)
		},
		goNext(unitId) {
			this.$router.push({
				name: 'ProgramChangeConfirm',
				params: {
					programId: this.programId,
					programUnitId: unitId,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.program-card {
	width: 100%;
}
.chip {
	border: 1px solid #eeeeee;
	border-radius: 20px;
	padding: 3px 10px 2px;
}
</style>
