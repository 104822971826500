<template>
	<div>
		<MenuBar :isLoading="isLoading" :routeName="'MonthlyReport'" />

		<!-- <section
			class="fixed-bar-wrap d-flex align-center pt-4 px-4"
			style="background-color: #fff"
		>
			<div
				@click="goTo('Lessons')"
				class="cursor-pointer pa-1"
				style="border-bottom: 4px solid #fff"
			>
				<h1 class="font-weight-regular grey--text text-body-1">
					{{ $t('수업 내역') }}
				</h1>
			</div>
			<div
				@click="goTo('Quest')"
				class="cursor-pointer pa-1 ml-3"
				style="border-bottom: 4px solid #fff"
			>
				<h1 class="font-weight-regular grey--text text-body-1">
					{{ $t('퀘스트') }}
				</h1>
			</div>
			<div class="pa-1 ml-3" style="border-bottom: 4px solid #000">
				<h1 class="font-weight-bold text-body-1">{{ $t('월별 리포트') }}</h1>
			</div>
		</section> -->
		<div
			v-if="isEmpty === false"
			class="pt-2"
			style="
				overflow-y: auto;
				height: calc(var(--vh, 1vh) * 100 - 125px);
				box-size: border-content;
			"
		>
			<section class="px-3 pb-3">
				<div class="mb-3 d-flex justify-end">
					<MonthPicker
						@selectMonth="selectMonth"
						@empty="empty"
						style="max-width: 155px"
					/>
				</div>
			</section>
			<Loader v-if="isLoading || selectedMonth === null" :height="300" />
			<section
				class="pb-3"
				v-if="isLoading === false && selectedMonth !== null"
			>
				<div class="px-3 mb-3">
					<h1
						class="text-h6 font-weight-bold"
						style="border-bottom: 1px solid #000"
					>
						{{ $t('%{selectedMonth}월 학습 활동 리포트', { selectedMonth }) }}
					</h1>
				</div>
				<div class="px-3 mb-7">
					<v-btn
						small
						rounded
						depressed
						@click="selectedType = 0"
						:outlined="selectedType !== 0"
						:color="selectedType === 0 ? 'primary' : 'grey'"
						height="25"
					>
						💬&nbsp; {{ $t('25분톡') }}
					</v-btn>
					<v-btn
						class="ml-1"
						small
						rounded
						depressed
						@click="selectedType = 1"
						:outlined="selectedType !== 1"
						:color="selectedType === 1 ? 'emerald white--text' : 'grey'"
						height="25"
					>
						📞&nbsp; {{ $t('15분콜') }}
					</v-btn>
				</div>
				<Overall
					class="px-3"
					:lessons="filteredLessons"
					:lessonType="selectedType"
					:progresses="progresses"
				/>
				<div class="my-11" style="background-color: #f4f4f4; height: 8px"></div>
				<div v-if="selectedType === 0">
					<Stat class="px-3" :stats="stats" />
					<ChartDialog
						:stats="stats"
						:selectedYear="selectedYear"
						:selectedMonth="selectedMonth"
					/>

					<div
						class="my-11"
						style="background-color: #f4f4f4; height: 8px"
					></div>
					<Length class="px-3" :stats="stats" />
					<div
						class="my-11"
						style="background-color: #f4f4f4; height: 8px"
					></div>
				</div>
				<div v-if="filteredLessons.length > 0">
					<Tutor
						class="px-3"
						:lessons="filteredLessons"
						:lessonType="selectedType"
						:ratings="ratings"
					/>
					<div
						class="my-11"
						style="background-color: #f4f4f4; height: 8px"
					></div>
				</div>
				<div v-if="selectedType === 0 && filteredLessons.length > 0">
					<Correction :lessons="filteredLessons" :lessonType="selectedType" />
					<div style="padding-bottom: 60px"></div>
				</div>
			</section>
		</div>
		<NoLesson class="text-center pt-16 mt-12" v-else />
	</div>
</template>

<script>
import moment from 'moment-timezone'
import Api from '@/services/index'
import { codeToMoment } from '@/helpers/date-format'
import MonthPicker from './MonthPicker'
import Overall from './Overall'
import Stat from './Stat'
import Length from './Length'
import Tutor from './Tutor'
import Correction from './Correction'
import ChartDialog from './ChartDialog'
import Loader from '@/components/common/Loader'
import NoLesson from './NoLesson'
import MenuBar from '@/components/common/LessonReportMenuBar'

const R = require('ramda')

export default {
	components: {
		Loader,
		NoLesson,
		MonthPicker,
		Overall,
		Stat,
		Length,
		Tutor,
		Correction,
		ChartDialog,
		MenuBar,
	},
	data() {
		return {
			isLoading: false,
			selectedMonth: null,
			selectedYear: null,
			firstDate: null,
			lastDate: null,
			lessons: [],
			stats: [],
			progresses: [],
			ratings: [],
			selectedType: 0,
			isEmpty: false,
		}
	},
	computed: {
		filteredLessons() {
			return R.filter(l => l.lesson_type === this.selectedType)(this.lessons)
		},
		memberId() {
			return this.$store.getters['auth/memberId']
		},
		timezone() {
			return this.$store.getters['member/timezone']
		},
		regDate() {
			return this.$store.getters['member/regDate']
		},
		lessonIdIn() {
			return this.lessons.length > 0
				? R.map(x => x.id)(this.lessons).toString()
				: null
		},
		gteLessonDatetime() {
			return moment
				.tz(this.firstDate, this.timezone)
				.startOf('day')
				.toISOString()
		},
		lteLessonDatetime() {
			return moment.tz(this.lastDate, this.timezone).endOf('day').toISOString()
		},
	},
	methods: {
		empty() {
			this.isEmpty = true
		},
		selectMonth(obj) {
			this.selectedMonth = obj.month
			this.selectedYear = obj.year
			const selectedMoment = moment(`${obj.year}-${obj.month}`, 'YYYY-M')
			this.firstDate = selectedMoment.startOf('month').format('YYYY-MM-DD')
			this.lastDate = selectedMoment.endOf('month').format('YYYY-MM-DD')

			this.get()
		},
		async get() {
			this.isLoading = true
			try {
				await this.getLessons()
			} catch (err) {
				alert(err)
			}

			if (this.lessons.length > 0) {
				try {
					await Promise.all([
						this.getStats(),
						this.getRatings(),
						this.getProgresses(),
					])
				} catch (err) {
					alert(err)
				}
			}

			this.isLoading = false
		},
		async getLessons() {
			let query = `lessons?member-id=${this.memberId}&status-not-in=cancelled,admin-cancelled&lte-lesson-datetime=${this.lteLessonDatetime}&gte-lesson-datetime=${this.gteLessonDatetime}`

			const res = await Api.get(query)

			res.data.forEach(lesson => {
				lesson.moment = codeToMoment(lesson.lesson_date, lesson.lesson_time).tz(
					this.timezone,
				)
			})
			this.lessons = res.data
		},
		async getStats() {
			let query = `lessons/stats?member-id=${this.memberId}&lesson-id-in=${this.lessonIdIn}&is-latest=1`

			const res = await Api.get(query)
			this.stats = res.data
		},
		async getRatings() {
			let query = `lesson-ratings?member-id=${this.memberId}&lesson-id-in=${this.lessonIdIn}`

			const res = await Api.get(query)
			this.ratings = res.data
		},
		async getProgresses() {
			let query = `progresses?member-id=${this.memberId}&lesson-id-in=${this.lessonIdIn}`

			const res = await Api.get(query)
			this.progresses = res.data
		},
		goTo(routeName) {
			if (this.isLoading === false) this.$router.push({ name: routeName })
		},
	},
}
</script>

<style scoped></style>
