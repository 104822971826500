import moment from 'moment-timezone'
import Api from '@/services/index'
import { STORE, LOAD, MAPPING_UNIT } from './mutations'
import { addMoment } from '@/helpers/date-format'

// import * as actions from './mutation-types'

export default {
	get: async ({ commit, getters, rootGetters }, needProgramUnit) => {
		commit(STORE, [])
		commit(LOAD, true)
		const memberId = rootGetters['auth/memberId']
		const language = rootGetters['member/language']
		const nowDatetime = encodeURIComponent(
			moment.tz('Asia/Seoul').toISOString(),
		)
		const endDatetime = encodeURIComponent(
			moment.tz('Asia/Seoul').add(13, 'days').endOf('day').toISOString(),
		)
		let query = `?member-id=${memberId}&gte-lesson-datetime=${nowDatetime}&status=appointed&lte-lesson-datetime=${endDatetime}`
		if (getters.selectedType === 0 || getters.selectedType === 1)
			query = `${query}&type=${getters.selectedType}`
		const res = await Api.get(`lessons${query}`)
		const result = addMoment(res.data)

		commit(STORE, result)

		const programUnitIds = getters.programUnitIds

		if (programUnitIds.length > 0 && needProgramUnit === true) {
			let unitQuery = `?active=1&sort_by=+unit&ids=${programUnitIds.join()}&language=${language}`
			const unitResult = await Api.get(`programs/units${unitQuery}`)
			const units = unitResult.data
			commit(MAPPING_UNIT, units)
		}

		commit(LOAD, false)
	},
}
