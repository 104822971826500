<template>
	<div class="pt-14 pb-10 white">
		<div class="mx-auto white" style="max-width: 300px">
			<h2 class="text-h2 mb-4 font-weight-bold primary--text">
				{{ $t('trial-report.diagnosis.영역별 진단') }}
			</h2>
			<div class="mb-8 d-flex align-center">
				<v-spacer></v-spacer>
				<div class="dot primary mr-1"></div>
				<div class="dot emerald mr-1"></div>
				<div class="dot purple mr-1"></div>
				<div class="dot orange mr-1"></div>
				<p class="text-body-2 grey--text text--darken-1 mr-4">
					{{ $t('trial-report.diagnosis.my-score') }}
				</p>
				<div class="dot grey mr-1"></div>
				<p class="text-body-2 grey--text text--darken-1">
					{{ $t('trial-report.diagnosis.average-score-of-level', { level }) }}
				</p>
			</div>
			<div style="position: relative">
				<div style="position: absolute; width: 100%; bottom: 7px">
					<div
						v-for="i in 6"
						:key="i"
						style="
							position: relative;
							z-index: 0;
							margin-bottom: 29px;
							max-width: 290px;
							margin-left: 10px;
							width: 100%;
							height: 1px;
							background-color: #e8e8e8;
						"
					>
						<p
							class="text-center text-body-2 grey--text text--darken-1"
							style="width: 10px; left: -15px; position: absolute; top: -9px"
						>
							{{ 10 - 2 * (i - 1) }}
						</p>
					</div>
				</div>

				<v-row
					style="position: relative; z-index: 1"
					class="pl-3 pr-1 text-center"
					justify="center"
					align="end"
					no-gutters
				>
					<v-col cols="3">
						<div class="mb-2 d-flex align-end justify-center">
							<div
								class="mx-1 rectangle gradient-primary"
								:style="`height: ${deliveryHeight}px`"
							></div>
							<div
								class="mx-1 rectangle grey lighten-1"
								:style="`height: ${scoresOfLevel.delivery * 15}px`"
							></div>
						</div>
						<p class="text-body-1 grey--text text--darken-2">
							{{ $t('trial-report.diagnosis.전달') }}
						</p></v-col
					>

					<v-col cols="3">
						<div class="mb-2 d-flex align-end justify-center">
							<div
								class="mx-1 rectangle gradient-emerald"
								:style="`height: ${comprehensionHeight}px`"
							></div>
							<div
								class="mx-1 rectangle grey lighten-1"
								:style="`height: ${scoresOfLevel.comprehension * 15}px`"
							></div>
						</div>
						<p class="text-body-1 grey--text text--darken-2">
							{{ $t('trial-report.diagnosis.이해') }}
						</p>
					</v-col>
					<v-col cols="3">
						<div class="mb-2 d-flex align-end justify-center">
							<div
								class="mx-1 rectangle gradient-purple"
								:style="`height: ${vocabularyHeight}px`"
							></div>
							<div
								class="mx-1 rectangle grey lighten-1"
								:style="`height: ${scoresOfLevel.vocabulary * 15}px`"
							></div>
						</div>
						<p class="text-body-1 grey--text text--darken-2">
							{{ $t('trial-report.diagnosis.어휘') }}
						</p>
					</v-col>
					<v-col cols="3">
						<div class="mb-2 d-flex align-end justify-center">
							<div
								class="mx-1 rectangle gradient-orange"
								:style="`height: ${grammarHeight}px`"
							></div>
							<div
								class="mx-1 rectangle grey lighten-1"
								:style="`height: ${scoresOfLevel.grammar * 15}px`"
							></div>
						</div>
						<p class="text-body-1 grey--text text--darken-2">
							{{ $t('trial-report.diagnosis.문법') }}
						</p>
					</v-col>
				</v-row>
			</div>
		</div>
	</div>
</template>

<script>
const R = require('ramda')

export default {
	props: {
		level: {
			required: true,
			type: Number,
		},
		deliveryScore: {
			required: true,
			type: Number,
		},
		comprehensionScore: {
			required: true,
			type: Number,
		},
		vocabularyScore: {
			required: true,
			type: Number,
		},
		grammarScore: {
			required: true,
			type: Number,
		},
	},
	computed: {
		deliveryHeight() {
			return (this.deliveryScore / 10) * 150
		},
		comprehensionHeight() {
			return (this.comprehensionScore / 10) * 150
		},
		vocabularyHeight() {
			return (this.vocabularyScore / 10) * 150
		},
		grammarHeight() {
			return (this.grammarScore / 10) * 150
		},
		scoresOfLevel() {
			const list = [
				{ level: 3, delivery: 4, comprehension: 4, vocabulary: 3, grammar: 3 },
				{ level: 4, delivery: 5, comprehension: 5, vocabulary: 4, grammar: 4 },
				{ level: 5, delivery: 6, comprehension: 6, vocabulary: 5, grammar: 5 },
				{ level: 6, delivery: 7, comprehension: 7, vocabulary: 6, grammar: 6 },
				{ level: 7, delivery: 8, comprehension: 8, vocabulary: 7, grammar: 7 },
			]
			return R.find(R.propEq('level', this.level))(list)
		},
	},
}
</script>

<style lang="scss" scoped>
.dot {
	width: 8px;
	height: 8px;
	border-radius: 4px;
}
.rectangle {
	width: 16px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}
.rectangle.grey {
	background-color: #dbe0e9 !important;
}
.gradient-primary {
	background-image: linear-gradient(to bottom, #0073ff, #00abff);
}
.gradient-emerald {
	background-image: linear-gradient(to bottom, #02caba, #05e7de);
}
.gradient-purple {
	background-image: linear-gradient(to bottom, #9350ff, #c588ff);
}
.gradient-orange {
	background-image: linear-gradient(to bottom, #ffa250, #ffcf88);
}
</style>
