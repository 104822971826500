var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"white pb-9"},[_c('div',{staticClass:"mx-auto",staticStyle:{"max-width":"300px"}},[_c('div',{staticClass:"d-flex align-center mb-4"},[_c('h2',{staticClass:"title3 title-color"},[_vm._v(" "+_vm._s(_vm.$t('trial-report.vocabulary'))+" ")]),_c('v-spacer'),_c('div',{staticClass:"rectangle"},[_c('div',{staticClass:"d-inline-block dot"}),_c('p',{staticClass:"d-inline-block sub-title2",staticStyle:{"color":"#8748ec"}},[_vm._v(" "+_vm._s(_vm.$t('trial-report.score', { score: _vm.vocabularyScore, }))+" ")])])],1),_c('p',{staticClass:"text default-color mb-5"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('v-row',{staticClass:"d-flex align-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"pa-4 rectangle mr-1"},[_c('p',{staticClass:"\n\t\t\t\t\t\t\tfont-weight-medium\n\t\t\t\t\t\t\ttext-body-1\n\t\t\t\t\t\t\tmb-6\n\t\t\t\t\t\t\tvoca-card-title\n\t\t\t\t\t\t\twhite-space-pre\n\t\t\t\t\t\t"},[_vm._v(" "+_vm._s(_vm.$t('trial-report.vocabulary.체험자님이 사용한 단어'))),_c('img',{staticClass:"ic_chat",attrs:{"src":require("@/assets/images/trials/ic-chat.svg")}})]),_c('h1',{staticClass:"text-right voca-card-h1",staticStyle:{"color":"#7533e0"},domProps:{"innerHTML":_vm._s(
							_vm.$t('trial-report.vocabulary.number-of-words', {
								numberOfWords: _vm.numberOfWords,
							})
						)}})])]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"pa-4 rectangle ml-1"},[_c('p',{staticClass:"\n\t\t\t\t\t\t\tfont-weight-medium\n\t\t\t\t\t\t\ttext-body-1\n\t\t\t\t\t\t\tmb-6\n\t\t\t\t\t\t\tvoca-card-title\n\t\t\t\t\t\t\twhite-space-pre\n\t\t\t\t\t\t"},[_vm._v(" "+_vm._s(_vm.$t('trial-report.vocabulary.average-word-title', { level: _vm.level }))+" "),_c('img',{staticClass:"ic_chat",attrs:{"src":require("@/assets/images/trials/ic-chat.svg")}})]),_c('h1',{staticClass:"text-right voca-card-h1",staticStyle:{"color":"#5d6571"},domProps:{"innerHTML":_vm._s(
							_vm.$t('trial-report.vocabulary.average-word-text', {
								averageNumberOfLevel: _vm.averageNumberOfLevel,
							})
						)}})])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }