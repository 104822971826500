<template>
	<div id="monthly-report-correction-select">
		<section class="px-5">
			<h2 class="mb-3 text-body-1 font-weight-bold">
				👀 {{ $t('이번 달 첨삭 문장 모아보기') }}
			</h2>
			<p
				class="mb-4 text-body-2 grey--text text--darken-2"
				v-html="
					$t(
						'첨삭 받은 문장을 눈으로 확인한 후, 원어민의 발음을 듣고 따라해보세요.',
					)
				"
			></p>
		</section>
		<section class="primary px-3">
			<v-select
				class="py-1"
				background-color="primary"
				v-model="lesson"
				return-object
				flat
				solo
				hide-details
				:items="analyzedLessons"
				single-line
				item-text="id"
			>
				<template v-slot:item="{ item }">
					<p class="text-body-2 white black--text text-center">
						{{ item.moment.format('ll') }} {{ item.moment.format('H:mm') }}
					</p>
				</template>
				<template v-slot:selection>
					<p class="white--text font-weight-bold text-body-1 mb-0 text-center">
						{{ lesson.moment.format('ll') }} {{ lesson.moment.format('H:mm') }}
					</p>
				</template>
				<template v-slot:append>
					<v-icon class="white--text">mdi-chevron-down</v-icon>
				</template>
			</v-select>
		</section>
		<Swiper :corrections="corrections" />
	</div>
</template>

<script>
import Swiper from './CorrectionSwiper'
import Api from '@/services/index'

const R = require('ramda')

export default {
	components: {
		Swiper,
	},
	props: {
		lessons: {
			required: true,
			type: Array,
		},
		lessonType: {
			required: true,
			type: Number,
		},
	},
	data() {
		return {
			lesson: null,
			corrections: [],
		}
	},
	computed: {
		lessonId() {
			return this.lesson ? this.lesson.id : null
		},
		analyzedLessons() {
			return R.filter(l => l.status === 'analyzed')(this.lessons)
		},
	},
	watch: {
		lessonId() {
			this.get()
		},
	},
	created() {
		this.lesson = this.analyzedLessons[0]
	},
	methods: {
		lessonDate(lesson) {
			return lesson.moment.format('lll')
		},
		async get() {
			this.isLoading = true
			const res = await Api.get(`/lessons/${this.lessonId}/corrections`)
			this.corrections = res.data
			this.isLoading = false
		},
	},
}
</script>

<style lang="scss">
.before-highlight {
	background-color: #feeeed;
}
.before {
	color: #b0433e;
}
.after-highlight {
	background: rgba(193, 233, 206, 0.5);
}
.after {
	color: #019b5b;
}
</style>
