<template>
	<div>
		<v-slide-group multiple show-arrows>
			<v-slide-item
				class="text-center"
				v-for="dateMoment in dates"
				:key="`button-${dateMoment.format('DD')}`"
			>
				<!-- v-slot="{ active, toggle }" -->
				<!-- <v-btn
					class="mx-2"
					:input-value="active"
					active-class="purple white--text"
					depressed
					rounded
					@click="toggle"
				>
					Options {{ n }}
				</v-btn> -->
				<div class="pr-1">
					<div
						class="text-caption"
						:class="{
							'primary--text': selectedDate === dateMoment.format('YYYY-MM-DD'),
						}"
					>
						{{ dateMoment.format('ddd') }}
					</div>
					<v-btn
						@click="selectDate(dateMoment.format('YYYY-MM-DD'))"
						:disabled="isTimeLoading"
						small
						fab
						depressed
						:text="selectedDate !== dateMoment.format('YYYY-MM-DD')"
						:color="
							selectedDate === dateMoment.format('YYYY-MM-DD')
								? 'primary'
								: null
						"
					>
						<div class="text-body-1 font-weight-bold">
							{{ dateMoment.format('DD') }}
						</div>
					</v-btn>
				</div>
			</v-slide-item>
		</v-slide-group>
		<!-- <div class="d-flex align-center text-center">
			<div
				class="date-btn"
				v-for="dateMoment in dates"
				:key="`button-${dateMoment.format('DD')}`"
			>
				<div>
					<div
						class="text-caption"
						:class="{
							'primary--text': selectedDate === dateMoment.format('YYYY-MM-DD'),
						}"
					>
						{{ dateMoment.format('ddd') }}
					</div>
					<v-btn
						@click="selectDate(dateMoment.format('YYYY-MM-DD'))"
						:disabled="isTimeLoading"
						small
						fab
						depressed
						:text="selectedDate !== dateMoment.format('YYYY-MM-DD')"
						:color="
							selectedDate === dateMoment.format('YYYY-MM-DD')
								? 'primary'
								: null
						"
					>
						<div class="text-body-1 font-weight-bold">
							{{ dateMoment.format('DD') }}
						</div>
					</v-btn>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
import moment from 'moment-timezone'

export default {
	computed: {
		isTimeLoading() {
			return this.$store.getters['book/time/isLoading']
		},
		selectedDate() {
			return this.$store.getters['book/time/selectedDate']
		},
		timezone() {
			return this.$store.getters['member/timezone']
		},
		language() {
			return this.$store.getters['member/language']
		},
		dates() {
			const dates = []
			const start = moment.tz(this.timezone).add(30, 'minutes')
			for (let i = 0; i < 14; i++) {
				dates.push(start.clone().add(i, 'days').locale(this.language))
			}
			return dates
		},
	},
	created() {
		this.selectDate(this.dates[0].format('YYYY-MM-DD'))
	},
	destroyed() {
		this.$store.commit('book/time/SELECT_DATE', null)
	},
	methods: {
		selectDate(date) {
			this.$store.commit('book/time/SELECT_DATE', date)
			this.$store.dispatch('book/time/getTimes')
		},
	},
}
</script>

<style lang="scss" scoped>
.date-btn {
	width: calc(100% / 7);
}
</style>
